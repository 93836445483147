import { HttpException } from '_utils_/Exceptions'
import { getCaseHistory } from '_api_/caseHistory.api'

export const mutationTypes = {
  clearState: 'clearState',
  setCaseHistory: 'setCaseHistory',
  setIsLoading: 'setIsLoading',
}

export const state = {
  isLoading: true,
  caseHistory: []
}

export const mutations = {
  [mutationTypes.setCaseHistory](state, payload) {
    state.caseHistory = payload
  },
  [mutationTypes.clearState](state) {
    state.isLoading = true
    state.caseHistory = []
  },
  [mutationTypes.setIsLoading](state, payload) {
    state.isLoading = payload
  }
}

export const actions = {
  async getCaseHistory({ commit }, payload) {
    commit(mutationTypes.clearState)
    commit(mutationTypes.setIsLoading, true)
    try {
      const caseHistory = await getCaseHistory(payload.id)
      commit(mutationTypes.setCaseHistory, caseHistory)
    } catch (error) {
      throw new HttpException(error)
    } finally {
      commit(mutationTypes.setIsLoading, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
