<template>
  <div
    :id="inputId"
    name="validated-credit-card-input"
    class="validated-credit-card-input"
  >
    <div class="validated-credit-card-input__card-number">
      <div
        v-if="isBrandAvailable"
        class="validated-credit-card-input__card-number__brand"
      >
        <img
          :src="getBrandImage()"
          alt="credit-card-brand"
        >
      </div>
      <div class="validated-credit-card-input__card-number__input">
        <validated-input
          ref="NumberProvider"
          v-model="number"
          name="validated-credit-card-input-number"
          :label="i18n('VALIDATED_CREDIT_CARD_INPUT__CARD_NUMBER__LABEL')"
          :rules="`required|integer|min:12|max:20${getErrorRule('number')}`"
          :max-length="maxLengths.cardNumber"
          :attrs="{ disabled: loading, inputmode: 'numeric', pattern: '[0-9]*' }"
          @blur="setValidNumber"
        />
      </div>
    </div>
    <div>
      <div>
        {{ i18n('VALIDATED_CREDIT_CARD_INPUT__EXPIRATION_DATE__LABEL') }}
      </div>
      <div class="validated-credit-card-input__expiration">
        <div class="validated-credit-card-input__expiration--month">
          <validated-input
            ref="MonthProvider"
            v-model="month"
            :name="expirationDateFieldsNames.month"
            :label="i18n('LABEL__DATE_MONTH')"
            :rules="getMonthRules"
            :max-length="maxLengths.month"
            :attrs="{ inputmode: 'numeric', disabled: loading, class: getInvalidExpirationDateClass, pattern: '[0-9]*' }"
            :error-msg-validator="shouldRenderErrorMsg"
            :formatter="getFormatter(month)"
            @blur="setValidMonth"
            @catch="setExpirationDateError"
          />
          <div
            v-if="showExpirationDateError"
            class="validated-credit-card-input__expiration--error"
          >
            <span
              name="dateErrorMsg"
              class="invalid-feedback d-block"
            >
              {{ i18n('ERROR__CREDIT_CARD_EXPIRED') }}
            </span>
          </div>
        </div>
        <div class="validated-credit-card-input__expiration--year">
          <validated-input
            ref="YearProvider"
            v-model="year"
            :name="expirationDateFieldsNames.year"
            :label="i18n('LABEL__DATE_YEAR')"
            :rules="getYearRules"
            :max-length="maxLengths.year"
            :attrs="{ disabled: loading, class: getInvalidExpirationDateClass, inputmode: 'numeric', pattern: '[0-9]*' }"
            :error-msg-validator="shouldRenderErrorMsg"
            :formatter="getFormatter(year)"
            @blur="setValidYear"
            @catch="setExpirationDateError"
          />
        </div>
        <div class="validated-credit-card-input__expiration--cvc">
          <div>
            <validated-input
              ref="CvcProvider"
              v-model="cvc"
              name="validated-credit-card-input-cvc"
              :label="i18n('LABEL__CVC')"
              :rules="`required|integer|min:3|max:4${getErrorRule('cvc')}`"
              :max-length="maxLengths.cvc"
              :attrs="{ disabled: loading, inputmode: 'numeric', pattern: '[0-9]*' }"
              :formatter="getFormatter(cvc)"
              @blur="setValidCvc"
            />
          </div>
          <b-tooltip
            type="is-light"
            class="validated-credit-card-input__expiration__image--tooltip"
          >
            <div
              id="validated-credit-card-cvc"
              class="validated-credit-card-input__expiration__image"
            >
              <img
                id="credit-card-question"
                :src="questionImageSrc"
                alt="credit-card-question"
              >
            </div>

            <template #content>
              <img
                id="credit-card-cvc"
                class="credit-card-cvc"
                :src="cvcImageSrc"
                alt="credit-card-cvc"
              >
            </template>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { getStripeToken } from '_api_/creditCard.api'
import ValidatedInput from '_atoms_/ValidatedInput'
import { ERROR_CODES, getCardBrand } from './constants'
import ExpirationDateFieldsProcessor from './expirationDateFieldsProcessor'
import { EXPIRATION_DATE_CREDIT_CARD_KEYWORD } from '_utils_/constants'
import { inputMaxLengths } from '_utils_/constants'
import validationInstance from '_utils_/ValidationInitializer'

let expirationDateProcessor

export default {
  name: 'ValidatedCreditCardInput',
  components: {
    ValidatedInput
  },
  props: {
    inputId: {
      type: String,
      required: false,
      default: 'validated-credit-card-input'
    },
  },
  data() {
    return {
      maxLengths: inputMaxLengths.fastTrack.creditCard,
      showExpirationDateError: false,
      number: '',
      month: '',
      year: '',
      cvc: '',
      isValidNumber: false,
      isValidMonth: false,
      isValidYear: false,
      isValidCvc: false,
      cardInfo: {},
      error: {}
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('CreateCase', ['isPaymentUpdate']),

    isBrandAvailable() {
      // TODO: this is a temporary fix, brand should go inside input text
      // and remove first part of the validation 'false &&'

      return false && (this.cardInfo.brand?.length > 0)
    },
    cvcImageSrc() {
      return require('_assets_/cvc.png')
    },
    questionImageSrc() {
      return require('_assets_/questionCircle.png')
    },
    expirationDateFieldsRules() {
      return expirationDateProcessor.getFieldsRules()
    },
    expirationDateFieldsNames() {
      return expirationDateProcessor.getFieldsNames()
    },
    getMonthRules () {
      return `${this.expirationDateFieldsRules.month}${this.getErrorRule('month')}`
    },
    getYearRules() {
      return `${this.expirationDateFieldsRules.year}${this.getErrorRule('year')}`
    },
    getInvalidExpirationDateClass() {
      return (this.showExpirationDateError) ? 'is-invalid' : ''
    },
  },
  watch: {
    number() {
      this.clear()
      this.emitTokenEvent()
    },
    month() {
      this.clear()
      this.emitTokenEvent()
    },
    year() {
      this.clear()
      this.emitTokenEvent()
    },
    cvc() {
      this.clear()
      this.emitTokenEvent()
    }
  },
  beforeMount() {
    expirationDateProcessor = new ExpirationDateFieldsProcessor(this.inputId)
  },
  mounted() {
    Object.values(this.expirationDateFieldsNames).forEach(
      (field) => this.error[field] = ''
    )
  },
  methods: {
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    ...mapActions('CreateCase', ['setPBCardInformation']),
    i18n,
    setValidNumber(element) {
      this.isValidNumber = element.flags.valid
    },
    setValidMonth(element) {
      this.isValidMonth = element.flags.valid
    },
    setValidYear(element) {
      this.isValidYear = element.flags.valid
    },
    setValidCvc(element) {
      this.isValidCvc = element.flags.valid
    },
    emitTokenEvent() {
      if(I18nConfig.isPaymentBrokerEnabled){
        const cardData = {
          number: this.number,
          month: this.month,
          year: this.year,
          cvc: this.cvc
        }
        this.setPBCardInformation(cardData)
      } else{
        this.$emit('update', this.getToken)
      }
    },
    async getToken() {
      let result = null
      const existingCardData = (Object.keys(this.cardInfo).length > 0)
      if (
        this.isValidNumber &&
        this.isValidMonth &&
        this.isValidYear &&
        !this.showExpirationDateError &&
        this.isValidCvc &&
        !existingCardData
      ) {
        this.setLoadingStatus(true)
        try {
          const data = {
            number: this.number,
            month: this.month,
            year: this.year
          }
          const card = await getStripeToken({
            ...data,
            cvc: this.cvc
          })
          if (card.id) {
            const { token, funding } = card
            result = {
              ...data,
              token,
              funding
            }
          }

          this.cardInfo = { ...result }
        } catch (error) {
          this.addErrorRules(error)
        }
        this.setLoadingStatus(false)
      } else if (existingCardData) {
        result = { ...this.cardInfo }
      }
      return result
    },
    getBrandImage() {
      if (this.isBrandAvailable) {
        const brand = getCardBrand(this.cardInfo.brand)
        const card = require('_assets_/cards/' + brand + '.png')

        return card
      }

      return ''
    },
    addErrorRules(error) {
      const errorData = ERROR_CODES[error.code] || ERROR_CODES['default']

      validationInstance.extendValidation({
        rule: `cardError-${errorData.type}`,
        options: {
          validate() {
            return false
          },
          message: i18n(errorData.msg)
        }
      })

      this.error = errorData
    },
    getErrorRule(type) {
      if (this.error?.type === type) {
        return `|cardError-${type}`
      }

      return ''
    },
    clear() {
      if (Object.keys(this.error).length > 0) {
        this.error = {}
      }

      if (Object.keys(this.cardInfo).length > 0) {
        this.cardInfo = {}
      }
    },
    shouldRenderErrorMsg(errorMsg) {
      return (errorMsg && !errorMsg.includes(EXPIRATION_DATE_CREDIT_CARD_KEYWORD))
    },
    shouldRenderExpirationDateErrorMsg() {
      const dateErrorMessages = Object.values(this.error)
      return (
        dateErrorMessages.length > 0 &&
        dateErrorMessages.every(error => error === EXPIRATION_DATE_CREDIT_CARD_KEYWORD)
      )
    },
    setExpirationDateError({ name, errors }) {
      this.$nextTick(() => {
        const hasErrors = (errors && errors.length > 0)
        this.isValidMonth = this.isValidYear = !hasErrors
        this.error[name] = hasErrors ? errors[0] : ''
        this.showExpirationDateError = this.shouldRenderExpirationDateErrorMsg()
      })
    },
    getFormatter(previousValue) {
      const inputRegex = RegExp(/^\d*$/)
      return value => {
        return inputRegex.test(value) ? value : previousValue
      }
    },
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.validated-credit-card-input {
  &__card-number {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &__brand {
      margin: 10px;
      width: 50px;

      & > img {
        height: 30px;
        width: 48px;
      }
    }

    &__input {
      width: 100%;
    }
  }

  &__expiration {
    display: flex;
    justify-content: flex-start;
    vertical-align: top;
    margin-top: 8px;

    @media screen and (max-width: $mobile) {
      display: block;
      margin: auto;

      &--error {
        margin-bottom: 10px;
      }
    }

    &--month, &--year {
      margin-right: 10px;

      @media screen and (min-width: $mobile) {
        width: 40%;
      }
    }

    &--cvc {
      display: flex;
      align-items: flex-start;

      @media screen and (min-width: $mobile) {
        width: 20%;
      }
    }

    &__image {

      & > img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }

      &--tooltip {
        margin: 10px;
        flex: 0 1 32px;
        align-self: center;

        .tooltip-content {
          width: 164px !important;
          height: 110px !important;

        }
      }
    }
  }
}
</style>
