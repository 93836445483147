import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getFeatures = async () => {
  try {
    const { data } = await api.get('/utils/features')
    return data
  } catch (error) {
    Logger.error('Features API - getFeatures', error)
  }
}

export { getFeatures }
