<template>
  <div class="team-member-form">
    <validation-observer ref="teamMemberForm">
      <div class="team-member-form__row">
        <validated-input
          v-model="teamMember.firstName"
          name="team-member-firstname"
          type="text"
          :max-length="maxLengths.firstName"
          :rules="customRules.firstName"
          :label="i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_FIRST_NAME')"
          class="team-member-form__row__input"
        />
        <validated-input
          v-model="teamMember.lastName"
          name="team-member-lastname"
          type="text"
          :max-length="maxLengths.lastName"
          :rules="customRules.lastName"
          :label="i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_LAST_NAME')"
          class="team-member-form__row__input"
        />
      </div>

      <div class="team-member-form__row row-select">
        <validated-select-input
          v-model="teamMember.role.id"
          name="team-member-role"
          :disabled="isRoleSelectionDisabled"
          :rules="customRules.role"
          :options="roleOptions"
          :attrs="{value: teamMember.role.id}"
          class="team-member-form__row__input"
          @change="onChangeRole($event)"
        />
        <validated-select-input
          v-if="showState && countryCodeIsNotGBorDE"
          v-model="teamMember.state"
          name="team-member-state"
          :disabled="isStateDisabled"
          :rules="customRules.state"
          :options="states"
          class="team-member-form__row__input"
        />
      </div>

      <div class="team-member-form__row">
        <validated-input
          v-model="teamMember.emailAddress"
          name="team-member-email-address"
          :disabled="isEmailDisabled"
          type="text"
          :max-length="maxLengths.emailAddress"
          :rules="getEmailAddressRules"
          :label="i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_EMAIL_ADDRESS')"
          class="team-member-form__row__input"
        />
        <validated-input
          v-model="teamMember.jobTitle"
          name="team-member-job-title"
          type="text"
          :max-length="maxLengths.jobTitle"
          :rules="customRules.jobTitle"
          :label="i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_JOB_TITLE')"
          class="team-member-form__row__input"
        />
      </div>

      <div class="team-member-form__row row-select">
        <validated-select-input
          v-if="showPractice"
          ref="teamMemberPractice"
          v-model="teamMember.practiceId"
          name="team-member-practice"
          :disabled="isPracticeSelectionDisabled"
          :rules="customRules.practice"
          :options="practiceOptions"
          :attrs="{value: teamMember.practiceId}"
          class="team-member-form__row__input"
        />
      </div>
      <div class="team-member-form__row row-select">
        <sdc-checkbox
          v-if="isEditable"
          v-model="teamMember.isSalesSpecialist"
          name="team-member-sales-specialist"
          @input="handleIsSalesSpecialist"
        >
          {{ i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_SALES_SPECIALIST') }}
        </sdc-checkbox>
      </div>
      <div class="team-member-form__row row-select">
        <sdc-checkbox
          v-if="isEditable"
          v-model="teamMember.isSmileGuide"
          name="team-member-smile-guide"
          @input="handleIsSmileGuide"
        >
          {{ i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_SMILE_GUIDE') }}
        </sdc-checkbox>
      </div>

      <div class="team-member-form__footer">
        <sdc-button
          class="team-member-form__footer__button"
          type="primary"
          outlined
          @click="onCancel"
        >
          {{ i18n('OFFICE_TEAM__MODAL_CANCEL_BUTTON') }}
        </sdc-button>

        <sdc-button
          type="primary"
          class="team-member-form__footer__button"
          :disabled="loading"
          @click="onSubmit"
        >
          {{ setSubmitButtonText }}
        </sdc-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import ValidatedInput from '_atoms_/ValidatedInput'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import { authConstants, inputMaxLengths } from '@/utils/constants'
import Location from '_utils_/Location'
import { TEAM_MEMBER_DDVC_STAFF_ROLE } from './constants'

export default {
  name: 'TeamMemberForm',
  components: {
    SdcButton,
    SdcCheckbox,
    ValidatedInput,
    ValidatedSelectInput,
    ValidationObserver
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    userName: { type: String, default: ''}
  },
  data() {
    return {
      states: [],
      teamMember : {
        firstName: '',
        lastName: '',
        emailAddress: '',
        jobTitle: '',
        role: {
          id: null,
          text: ''
        },
        practiceId: '',
        state: '',
        isSalesSpecialist: false,
        isSmileGuide: false
      },
      customRules: {
        firstName: 'required',
        lastName: 'required',
        jobTitle: 'required',
        role: 'required',
        practice: 'required',
        state: 'required'
      },
      maxLengths: inputMaxLengths.officeTeamMember,
    }
  },
  computed:{
    ...mapGetters('Auth', ['isSuperuser', 'isDdvcStaff']),
    ...mapGetters('Practices', ['getPractices', 'isLoaded']),
    ...mapGetters('Users', ['getUserInfo']),
    ...mapGetters('Roles', ['getByName', 'getById']),
    ...mapState('Roles', ['roles']),
    ...mapState('HttpRequest', ['loading']),
    showState() {
      return this.isSuperuser && !this.isSuperUserSelected && this.teamMember.role !== ''
      && this.teamMember.role.text !== TEAM_MEMBER_DDVC_STAFF_ROLE
    },
    showPractice() {
      return this.isSuperuser && !this.isSuperUserSelected && this.teamMember.state !== ''
      && this.teamMember.role.text !== TEAM_MEMBER_DDVC_STAFF_ROLE
    },
    isSuperUserSelected(){
      return this.teamMember.role.text === authConstants.roles.superuser ||
             this.teamMember.role.text === authConstants.roles.ddvcStaff
    },
    roleOptions() {
      const options = this.roles.map(role => ({
        value: role.id,
        text: this.getRoleLabel(role.name)
      })).sort((roleA, roleB) => roleA.text.localeCompare(roleB.text, ...Location.localeCompareConfiguration))
      options.unshift({ value: '', text: i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_ROLE') })
      return options
    },
    isRoleSelectionDisabled() {
      return this.roles.length === 0 || this.isEdit
    },
    isEmailDisabled() {
      return this.isEdit || !this.teamMember.role.id
    },
    isStateDisabled() {
      return this.isEdit
    },
    isEditable(){
      return this.isSuperuser && this.isEdit
    },
    countryCodeIsNotGBorDE() {
      return Location.countryCode !== 'GB' && Location.countryCode !== 'DE'
    },
    practiceOptions() {
      const { role } = this.teamMember
      const { executive, dsoDoctor, practiceDoctor } = authConstants.roles

      const onlyIndividual = ![executive, dsoDoctor].includes(role.text)
      const onlyDSO = role.text !== practiceDoctor

      const options = this.getPractices
        .filter(
          item =>
            item.state === this.teamMember.state &&
            (item.isIndividual ? onlyIndividual : onlyDSO)
        )
        .map(item => ({
          value: item.id,
          text: item.name,
          countryCode: item.countryCode,
          state: item.state
        }))
        .sort((practiceA, practiceB) =>
          practiceA.text.localeCompare(
            practiceB.text,
            ...Location.localeCompareConfiguration
          )
        )
      options.unshift({
        value: '',
        text: i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_LABEL_PRACTICE')
      })

      if (this.isDdvcStaff) {
        return options.filter(p => p.countryCode === Location.countryCode)
      }

      return options
    },
    isPracticeSelectionDisabled() {
      return this.getPractices.length === 0 || this.isEdit
    },
		getEmailAddressRules() {
      return `required|emailExtended:${this.isSuperUserSelected}`
    },
    setSubmitButtonText() {
      return this.isEdit ?
        i18n('OFFICE_TEAM__MODAL_EDIT_BUTTON') :
        i18n('OFFICE_TEAM__MODAL_SUBMIT_BUTTON')
    },
    isEdit() {
      return !!this.userName
    },
  },
  watch: {
    'teamMember.role.id': function(idRole) {
      const role = this.getById(idRole)
      this.teamMember.role.text = role.name
    }
  },
  beforeMount() {
    // TODO: improve this component: assignments and usage of objects
    if (!this.isEdit) {
      return
    }

    const userExistant = this.getUserInfo(this.userName)
    this.teamMember = userExistant
    this.teamMember.emailAddress = userExistant.userName
    this.teamMember.role = {
      id: this.getByName(userExistant.roleNames[0])?.id,
      text: userExistant.roleNames[0]
    }

    if (userExistant.practices && userExistant.practices.length) {
      this.teamMember.state = userExistant.practices[0].state
      this.teamMember.practiceId = userExistant.practices[0].id
    }
    else if (userExistant.practice) {
      this.teamMember.state = userExistant.practice.state
      this.teamMember.practiceId = userExistant.practice.id
    }
  },
  mounted() {
    this.requestPractices()
    this.states = this.getStates()
  },
  methods: {
    i18n,
    ...mapMutations('Users', ['clearUser']),
    ...mapActions('Users', ['createUser','requestUsers', 'updateUser']),
    ...mapActions('Practices', ['requestPractices']),
    ...mapActions('EmailNotification', ['requestEmailNotifications']),
    getRoleLabel(roleName) {
      return i18n(`ROLE_${roleName}__LABEL`) || roleName
    },
    handleIsSmileGuide(value) {
      if (value && this.teamMember.isSalesSpecialist) {
        this.teamMember.isSalesSpecialist = false
      }
    },
    handleIsSalesSpecialist(value) {
      if (value && this.teamMember.isSmileGuide) {
        this.teamMember.isSmileGuide = false
      }
    },
    async onSubmit() {
      const form = this.$refs.teamMemberForm

      const success = await form.validate()
      if (!success) return

      const payload = {
        userName: this.teamMember.emailAddress,
        firstName: this.teamMember.firstName,
        lastName: this.teamMember.lastName,
        jobTitle: this.teamMember.jobTitle,
        role: this.teamMember.role.id,
        isSuperUser: this.isSuperUserSelected,
        isActive: true,
        isSmileGuide: this.teamMember.isSmileGuide,
        isSalesSpecialist: this.teamMember.isSalesSpecialist
      }

      const practiceIdSelected = this.isSuperUserSelected ? null : this.teamMember.practiceId

      const process = this.isEdit ? this.updateUser : this.createUser

      const userResult = await process({ payload, practiceIdSelected })

      const supportPhoneNumber = I18nConfig.supportPhoneNumber

      const title = userResult.error ?
        i18n('OFFICE_TEAM__TITLE_WARNING') :
        i18n('OFFICE_TEAM__TITLE')

      let message = ''
      if (userResult.error) {
        message = i18n(userResult.errorCode, [supportPhoneNumber])
      } else {
        message = this.isEdit ? i18n('OFFICE_TEAM__EDIT_TEAM_MEMBER_SUCCESS') : i18n('OFFICE_TEAM__ADD_TEAM_MEMBER_SUCCESS')
      }

      this.toast({
        msg: message,
        title: title
      })

      this.requestUsers()
      this.requestEmailNotifications()
      this.onCancel()
    },
    onCancel() {
      this.closeModal()
      this.clearUser()
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    onChangeRole(event){
      this.teamMember.practiceId = ''

      if (typeof event.text !== 'undefined') {
        this.$refs.teamMemberForm.validate()
      } else {
        this.$refs.teamMemberForm.reset()
      }
    },
    getStates() {
      if (!I18nConfig.hasRegions) {
        return []
      }
      const regions = I18nConfig.regions.map((item) => ({
        value: item.shortCode,
        text: item.localizedName?.[this.languageCode] || item.name
      }))
      regions.unshift({ value: '', text: i18n('OFFICE_TEAM__TEAM_MEMBER_FORM_STATE') })
      return regions
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.team-member-form{

  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &__input {
      width: 100%;

      @media screen and (min-width: $mobile) {
        width: 49%;
      }
    }
  }

  &__footer {

    // TODO: Refactor all modal buttons to use a shared style

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-normal;
      font-family: 'Sofia Pro Bold';
      margin: 8px;
      padding: 15px 30px;
    }
  }

  .row-select {
    margin-bottom: 1rem;
  }
}
</style>
