<template>
  <section id="login-card">
    <h2 class="login-title">
      {{ i18n('LOGIN__TITLE') }}
    </h2>

    <validation-observer>
      <form
        class="login-form"
        @submit.prevent="onSubmit"
      >
        <validated-input
          ref="username"
          v-model="authData.username"
          name="email"
          type="email"
          rules="required|email"
          :label="i18n('LOGIN__LABEL_EMAIL')"
        />

        <validated-input
          v-model="authData.password"
          name="password"
          type="password"
          rules="required"
          :label="i18n('LOGIN__LABEL_PASSWORD')"
        />

        <sdc-button
          expanded
          class="login-submit"
          type="primary"
          @click="onSubmit"
        >
          <span v-if="!loggingIn">{{ i18n('LOGIN__TITLE') }}</span>
          <span v-else>{{ i18n('LOGIN__WAIT') }}</span>
        </sdc-button>

        <div class="login-reset-password">
          <a :href="resetPasswordUrl()">
            {{ i18n('LOGIN__RESET') }}
          </a>
        </div>
      </form>
    </validation-observer>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import ValidatedInput from '_atoms_/ValidatedInput'
import { i18n } from '_utils_/i18n'
import Location from '_utils_/Location'
import {
  localeConstants,
  snackbarDefaultSettings
} from '@/utils/constants'
import { getCurrentLocale } from '_api_/geolocation.api'
import SdcButton from '_atoms_/SdcButton'

export default {
  name: 'LoginForm',
  components: {
    SdcButton,
    ValidationObserver,
    ValidatedInput
  },
  data() {
    return {
      authData: {},
      loggingIn: false
    }
  },
  computed: {
    ...mapState('Auth', ['loginError'])
  },
  async beforeMount() {
    if( this.$route.query.locale === null) {
      const locale = await getCurrentLocale()
      const localeStored = window.localStorage.getItem('locale')

      await Location.setLocale(locale)
      if (locale !== localeStored) {
        this.redirect(locale)
      }
    }
  },
  methods: {
    ...mapActions('Auth', ['logIn']),
    ...mapActions('Users', ['requestUserByName']),
    i18n,
    toastLoginError() {
      const { msg, title } = this.loginError
      this.$sdcToast.open({title, content: msg})
    },
    resetPasswordUrl(){
      return Location.buildRouteUrl('reset-password',localeConstants.defaultCountryCode, Location.locale)
    },
    async onSubmit() {
      const { username, password } = this.authData
      this.loggingIn = true

      await this.logIn({ username, password })

      this.loggingIn = false

      if (!this.loginError.status) {
        this.requestUserByName(username)
        this.$router.push({ name: 'home' }).catch(() => {})
        return false
      }
      this.toastLoginError()
    },
    redirect(localeItem) {
      window.location.href = Location.buildUrl(localeItem)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, "tablet");

#login-card {
  @media screen and (min-width: $tablet) {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.04);
    padding: 60px;
  }

  margin-top: 40px;
}

.login-form {
  display: flex;
  flex-direction: column;

  .button {
    margin-top: 1rem;
  }
}

.login-title {
  margin-bottom: 20px;
  font-size: 3rem;
  font-family: $font-family-black;
  font-weight: $font-weight-normal;
}

.login-reset-password {
  margin-top: 10px;
  text-align: center;
}
</style>
