<template>
  <div class="scan-intake-history-files">
    <sdc-title level="4">
      {{ i18n('SCAN_INTAKE_HISTORY_SECTION__TITLE') }}
    </sdc-title>
    <div class="scan-intake-history-files__container">
      <scan-intake-history-table
        :history-list="historyList"
        @on-asset-download="handleOnClickAsset"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import {
  ASSET_TYPES,
  AUTHORIZATION_SCOPES
} from '_utils_/scanIntakeConstants'
import SdcTitle from '_atoms_/SdcTitle'
import ScanIntakeHistoryTable from '_molecules_/ScanIntakeHistoryTable'
import { snackbarDefaultSettings } from '@/utils/constants'

export default {
  name: 'ScanIntakeHistoryFiles',
  components: {
    ScanIntakeHistoryTable,
    SdcTitle
  },
  props: {
    historyList: {
      type: Array,
      required: true
    }
  },
  methods: {
    i18n,
    ...mapActions('ScanIntake', ['getAssetUrl']),
    async handleOnClickAsset(scan) {
      const req = {
        ...scan,
        authorizationScope: AUTHORIZATION_SCOPES.private,
        assetType: ASSET_TYPES.scan3d,
      }
      const response = await this.getAssetUrl(req)
      if(response.error) {
        this.toast({
          msg: i18n('ERROR__DEFAULT', [I18nConfig.supportPhoneNumber]),
          title: i18n('ERROR_SCAN__DOWNLOAD_URL__TITLE')
        })
      } else {
        this.downloadFile(response.assetUrl)
      }
    },
    downloadFile(url) {
      const link = document.createElement('a')
      link.href = url
      link.download = true
      link.click()
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    }
  }
}
</script>

<style lang="scss">
.scan-intake-history-files {

  &__container {
    margin-bottom: 2rem;
  }

}
</style>
