<template>
  <validation-observer ref="caseFlowSelectorForm">
    <form>
      <div class="case-flow-selector__type">
        <div class="case-flow-selector__type__control">
          <sdc-radio-button
            v-model="selectedOrderType"
            name="submit-initial-impression-type"
            rules="required"
            :options="submitOptions"
            :disabled="loading"
          />
        </div>
        <div class="case-flow-selector__type__icons">
          <sdc-tooltip
            name="tooltip-midcourse"
            class="case-flow-selector__type__icons__mcc"
            :title="i18n('CREATE_MIDCOURSE_CORRECTION__TITLE')"
            :text="i18n('CASE_FLOW_SELECTOR_MODAL__TOOLTIP_CONTENT_MIDCOURSE_CORRECTION')"
          />
          <sdc-tooltip
            name="tooltip-refinement"
            :title="i18n('CREATE_REFINEMENT_CORRECTION__TITLE')"
            :text="i18n('CASE_FLOW_SELECTOR_MODAL__TOOLTIP_CONTENT_REFINEMENT')"
          />
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { i18n } from '_utils_/i18n'
import { mapState } from 'vuex'
import { createCaseConstants } from '_utils_/constants'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import { ValidationObserver } from 'vee-validate'
import SdcTooltip from '@/components/atoms/SdcTooltip'

export default {
  name: 'CaseFlowSelectorForm',
  components: {
    SdcTooltip,
    SdcRadioButton,
    ValidationObserver
  },
  data() {
    return {
      selectedOrderType: this.$attrs.value || createCaseConstants.flowType.CASE,
      submitOptions: [
        {
          value: createCaseConstants.flowType.CASE,
          text: i18n('CASE_CREATE__FLOW_TYPE_CASE')
        },
        {
          value: createCaseConstants.flowType.MIDCOURSE_CORRECTION,
          text: i18n('CASE_CREATE__FLOW_TYPE_MIDCOURSE_CORRECTION')
        },
        {
          value: createCaseConstants.flowType.REFINEMENT,
          text: i18n('CASE_CREATE__FLOW_TYPE_REFINEMENT')
        }
      ]
    }
  },
  computed:{
    ...mapState('HttpRequest', ['loading']),
  },
  methods: {
    i18n,
    validate() {
      return this.$refs.caseFlowSelectorForm.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, "mobile");

.case-flow-selector{

  &__type {
    display: flex;
    justify-content: space-between;

    &__icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      &__mcc {
        margin-bottom: 22px;
      }
    }

    @media screen and (max-width: $mobile) {
      max-width: 100%;
    }
  }
}
</style>
