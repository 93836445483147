import { CasesException } from '_utils_/Exceptions'
import moment from 'moment'
import { getCases } from '_api_/cases.api'
import { casesConstants, CASE_SOURCE_TEXT } from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '@/utils/i18n'

const {
  tableRequestState
} = casesConstants

export const mutationTypes = {
  setCases: 'setCases',
  setMetadata: 'setMetadata',
  setTableRequestState: 'setTableRequestState',
  changeStartDateSort: 'changeStartDateSort',
  setLocalSortBy: 'setLocalSortBy',
  resetFilters: 'resetFilters',
  setLoadingCases: 'setLoadingCases',
  setCaseStatusFilter: 'setCaseStatusFilter',
  setCaseDoctorFilter: 'setCaseDoctorFilter',
  setPracticeFilter: 'setPracticeFilter',
  clearCases: 'clearCases',

}

export const state = {
  cases: [],
  metadata: {},
  tableRequestState,
  localSortBy: 'creation_date',
  loadingCases:true,
  caseStatusFilter: '',
  caseDoctorFilter: '',
  practiceFilter:''
}

export const getters = {
  tableData: ({ cases }) => {
    const dateFormat = I18nConfig.dateFormat

    return cases.map((item) => ({
      number: item.caseNumber,
      name: item.patientName,
      creation_date: moment(item.assignmentDate).format(dateFormat),
      source: i18n(CASE_SOURCE_TEXT[item.source] || CASE_SOURCE_TEXT.default),
      status: item.status,
      id: item.id,
      impressionMode: item.impressionMode
    }))
  },
  getCaseNumbers: ({ cases }) => cases.map(item => item.caseNumber),
  paginationData: ({ metadata }) => {
    const data = metadata
    delete data.sortedColumn
    delete data.sortedDirection
    return data
  }
}

export const mutations = {
  [mutationTypes.setCases](state, payload) {
    state.cases = payload.cases
  },
  [mutationTypes.setMetadata](state, payload) {
    state.metadata = {...state.metadata, ...payload.metadata}
  },
  [mutationTypes.setTableRequestState](state, payload) {
    state.tableRequestState = {...state.tableRequestState, ...payload.params}
  },
  [mutationTypes.setLocalSortBy](state, payload) {
    state.localSortBy = payload
  },
  [mutationTypes.resetFilters](state) {
    state.tableRequestState = tableRequestState
  },
  [mutationTypes.setLoadingCases](){
    state.loadingCases = !state.loadingCases
  },
  [mutationTypes.setCaseStatusFilter](state, payload){
    state.caseStatusFilter = payload
  },
  [mutationTypes.setCaseDoctorFilter](state, payload){
    state.caseDoctorFilter = payload
  },
  [mutationTypes.setPracticeFilter](state, payload){
    state.practiceFilter = payload
  },
  [mutationTypes.clearCases](state) {
    state.cases = []
  },
}

export const actions = {
  async requestCases({ commit, dispatch }, payload = { params: tableRequestState }) {
    dispatch('AppProcess/setLoadingStatus', true, { root: true })
    commit(mutationTypes.setLoadingCases)
    try {
      const response = await getCases(payload.params)
      const { cases, metadata } = response
      commit(mutationTypes.setTableRequestState, { params: payload.params })
      commit(mutationTypes.setMetadata, { metadata })
      commit(mutationTypes.setCases, { cases })
      commit(mutationTypes.setLoadingCases)
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    } catch (error) {
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
      throw new CasesException(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
