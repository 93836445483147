<template>
  <div class="sdc-add-file">
    <div
      :class="getClass"
    >
      <sdc-button
        class="sdc-add-file__add__button"
        type="primary"
        @click="onTriggerSelectFile"
      >
        <smile-icon
          icon="plus"
          custom-class="is-size-4"
        />

        <span>{{ i18n('CASE_ADDITIONAL_FILES___UPLOAD__ADD-FILE__BUTTON__TEXT') }}</span>
      </sdc-button>
      <div class="sdc-add-file__add__description">
        <span>
          {{ description }}
        </span>
      </div>
    </div>

    <div
      v-if="hasFileBeenAdded"
      class="sdc-add-file__replace"
    >
      <div class="sdc-add-file__replace__file-name">
        <sdc-image
          name="file-icon"
          class="sdc-add-file__replace__file-name__icon"
          :src="fileIcon"
        />
        <span>{{ file.name }}</span>
      </div>
      <sdc-button
        class="sdc-add-file__replace__button"
        type="primary"
        @click="onTriggerSelectFile"
      >
        <span>{{ i18n('SCAN_INTAKE_SECTION__UPLOAD__REPLACE-FILE__BUTTON__TEXT') }}</span>
      </sdc-button>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import SdcButton from '_atoms_/SdcButton'
import SdcImage from '_atoms_/SdcImage'

export default {
  name: 'SdcAddfile',
  components: {
    SdcButton,
    SdcImage
  },
  props: {
    file: {
      type: [File, Object],
      required: true
    },
    onTriggerSelectFile: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      description: ''
    }
  },
  computed: {
    hasFileBeenAdded() {
      return !!this.file?.name?.length
    },
    fileIcon() {
      return require('_assets_/icon-file.svg')
    },
    getClass() {
      return {
        'sdc-add-file__add': true,
        'sdc-add-file__add--hidden': this.hasFileBeenAdded
      }
    }
  },
  mounted() {
    this.description = i18n(
      'CASE_ADDITIONAL_FILES_SECTION__UPLOAD__ADD-FILE__DESCRIPTION',
      I18nConfig.additionalFilesAllowedTypes.map(t => `.${t}`)
    )
  },
  methods: {
    i18n,
  }
}

</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-add-file{
  &__add{
    margin-top: 8px;
    visibility: visible;

    &--hidden {
      visibility: hidden;
      height: 0;
    }

    &__description {
      margin-top: 12px;
      color: $gray-600;
      font-weight: $font-weight-normal;
    }

    &__button {
      color: $primary;
      background-color: $white;
      border-color: $primary;
      font-weight: bold;
      border: solid 2px;

      &:focus {
        box-shadow: none;
        color: $primary;
        background-color: $white;
      }

      &:hover {
        color: $primary;
        background-color: $white;
      }

      svg {
        font-size: 100%;
        margin-right: 4px;
      }
    }
  }

  &__replace {
    &__file-name {
      margin-top: 12px;
      color: $gray-600;
      font-weight: $font-weight-bold;
      word-break: break-all;
      &__icon {
        margin-right: 8px;
      }
    }

    &__button {
      height: 38px;
      margin-top: 24px;
      color: $primary;
      background-color: $white;
      border-color: $gray-88;
      padding: 0px 20px 0px 20px;
      font-weight: $font-weight-bolder;
    }
  }
}
</style>
