const TABLE_FIELDS = [
  { key: 'date', label: 'SCAN_INTAKE_SECTION__UPLOAD__DATE__TITLE', thClass: 'sdc-table__header-column', sortable: false, sortDirection: 'desc' },
  { key: 'side', label: 'SCAN_INTAKE_SECTION__UPLOAD__NAME__TITLE', thClass: 'sdc-table__header-column', sortable: false },
]

const FIELDS_TO_SORT = {
  sortBy: 'date',
  sortDesc: 'date'
}

export {
  TABLE_FIELDS,
  FIELDS_TO_SORT
}
