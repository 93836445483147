import { extend, configure } from 'vee-validate'

const ruleKeys = []

configure({
  classes: {
    passed: 'is-passed'
  }
})

const extendValidation = ({ rule, options }) => {
  if (ruleKeys.includes(rule)) return
  ruleKeys.push(rule)
  extend(rule, options)
}

const initializeValidations = (validations = []) => {
  validations.forEach(validation => extendValidation(validation))
}

export default {
  extendValidation,
  initializeValidations
}
