import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'
import Location from '@/utils/Location'


const apiController = '/commerce/'

const createDraftOrder = async (request) => {
  try {
    const response = await api.post(`${apiController}draft-order`, request)
    return response.data
  } catch (error) {
    Logger.error('Commerce API - create draft order', error)
    throw error
  }
}

const createOrder = async (request) => {
  try {
    const response = await api.post(`${apiController}order`, request, {
      headers: {
        locale: Location.locale
      }
    })
    return response.data
  } catch (error) {
    Logger.error('Commerce API - create order', error)
    throw error
  }
}

const updateCustomer = async (request) => {
  try {
    const response = await api.put(`${apiController}customer`, request)
    return response.data
  } catch (error) {
    Logger.error('Commerce API - update customer', error)
    throw error
  }
}

/**
 * @param {{ email: String }} request
 *
 * @returns {Promise<{
 *  customerId: String,
 *  paymentBrokerAccountId: String,
 *  hasPurchased: Boolean
 * }>}
 */
const getOrCreateCustomer = async (request) => {
  try {
    const { data } = await api.post(
      `${apiController}customer/get-or-create`,
      request
    )
    return data
  } catch (error) {
    Logger.error('Commerce API - get or craete customer', error)
    throw error
  }
}

const updateBillingAddress = async (request) => {
  try {
    const response = await api.put(`${apiController}billing-address`, request)
    return response.data
  } catch (error) {
    Logger.error('Commerce API - update billing address', error)
    throw error
  }
}

/**
 *
 * @param {{
 *  cartId: String,
 *  discountCode: String,
 *  isAffiliate: Boolean,
 *  totalAmount: Number
 * }} request
 * @returns {Promise<{
 *  discountAmount: Number,
 *  discountCode: String,
 *  messages: String[]
 * }>}
 */
const applyDiscount = async (request) => {
  try {
    const {
      data: {
        discount_amount: discountAmount,
        discount_code: discountCode,
        messages
      }
    } = await api.put(`${apiController}discount`, request)
    return { discountAmount, discountCode, messages }
  } catch (error) {
    Logger.error('Commerce API - apply discount', error)
    throw error
  }
}

const updateCart = async (request) => {
  try {
    const response = await api.put(`${apiController}cart`, request)
    return response.data
  } catch (error) {
    Logger.error('Commerce API - update cart', error)
    throw error
  }
}

const removeDiscount = async (request) => {
  try {
    const response = await api.post(`${apiController}remove-discount`, request)
    return response.data
  } catch (error) {
    Logger.error('Commerce API - remove discount', error)
    throw error
  }
}

export {
  applyDiscount,
  createDraftOrder,
  createOrder,
  getOrCreateCustomer,
  removeDiscount,
  updateBillingAddress,
  updateCart,
  updateCustomer
}
