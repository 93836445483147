import {
  createEmailNotification,
  getEmailNotifications,
  deleteEmailNotification,
  updateEmailNotifications
} from '_api_/emailNotification.api'
import { EMAIL_NOTIFICATION_ERROR_CODES, PRACTICE_ERROR_CODES, errorTypes } from '_utils_/constants'

export const mutationTypes = {
  setEmailNotificationItems: 'setEmailNotificationItems',
  clearState: 'clearState',
  loadError: 'loadError'
}

export const state = {
  loaded: false,
  emailNotificationItems: {
  }
}

export const mutations = {
  [mutationTypes.setEmailNotificationItems](state, payload) {
    state.emailNotificationItems = {
      ...state.emailNotificationItems,
      ...payload
    }
  },
  [mutationTypes.clearState](state) {
    state.emailNotificationItems = {
    }
  }
}

export const actions = {
  async requestEmailNotifications({ state, commit, rootGetters }) {
    const practiceId = rootGetters['Auth/currentPracticeId']
    const boId = rootGetters['Auth/boId']
    if (state.loaded) {
      return
    }
    try {
      const emailNotifications = await getEmailNotifications(boId, practiceId)
      commit(mutationTypes.setEmailNotificationItems, emailNotifications)
    } catch (error) {
      commit(mutationTypes.loadError, {
        status: error.status
      })
    }
  },
  async createNewEmailNotifications({ dispatch , rootGetters }, payload) {
    try {
      payload.boId = rootGetters['Auth/boId']
      payload.practiceId = rootGetters['Auth/currentPracticeId']

      const result = await createEmailNotification(payload)
      dispatch('clear')
      return result
    } catch (err) {
      const isBadRequest = err?.response?.status === 400
      const badRequestErrorCode = err?.response?.data?.errorCode
      let errorType
      if(isBadRequest) {
        const errorTypeOptions = {
          [EMAIL_NOTIFICATION_ERROR_CODES.duplicatedEmailNotification]: errorTypes.duplicatedEmailNotification,
          [EMAIL_NOTIFICATION_ERROR_CODES.inactiveUser]: errorTypes.inactiveUser
        }

        errorType = errorTypeOptions[badRequestErrorCode] || errorTypes.addEmailNotificationError
      }
      return {
        error: {
          type: errorType
        }
      }
    }
  },
  async removeEmailNotification({ dispatch, rootGetters }, emailAddress) {
    try {
      let payload = {
        boId: rootGetters['Auth/boId'],
        practiceId: rootGetters['Auth/currentPracticeId'],
        emailAddress
      }

      const result = await deleteEmailNotification(payload)
      dispatch('clear')
      dispatch('requestEmailNotifications')
      return result
    } catch (error) {
      return error?.response?.data?.errorCode
    }
  },
  async updateEmailNotifications({ dispatch , rootGetters }, payload) {
    try {
      const practiceId = rootGetters['Auth/currentPracticeId']
      const boId = rootGetters['Auth/boId']

      const result = await updateEmailNotifications(boId, practiceId, payload)
      dispatch('clear')
      return result
    } catch (err) {
      const isBadRequest = err?.response?.status === 400
      const badRequestErrorCode = err?.response?.data?.errorCode
      let errorType
      if(isBadRequest) {
        const errorTypeOptions = {
          [EMAIL_NOTIFICATION_ERROR_CODES.duplicatedEmailNotification]: errorTypes.duplicatedEmailNotification,
          [PRACTICE_ERROR_CODES.practiceNotFound]: errorTypes.practiceNotFound,
          [EMAIL_NOTIFICATION_ERROR_CODES.emailNotValid]: errorTypes.emailNotValid
        }

        errorType = errorTypeOptions[badRequestErrorCode] || errorTypes.updateEmailNotificationError
      }
      return {
        error: {
          type: errorType
        }
      }
    }
  },
  clear({ commit }) {
    commit(mutationTypes.clearState)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
