/* eslint-disable no-console */
import { sdcInstance as sdcAPI } from './sdc.config.api'
import Logger from '@/utils/Logger'

const SECTION_TYPES = {
  haveAny: 0,
  allThatApply: 1,
}

const QUESTION_TYPES = {
  radio: 'Radiogroup',
  tooth: 'ToothGroup'
}

const filterMedicalQuestions = (data) => {
  return data.map(({
    title,
    subtitle,
    sectionType,
    medicalQuestions
  }) => {
    return {
      title,
      subtitle,
      type: sectionType,
      questions: medicalQuestions.map(({
          name: questionName,
          title: questionTitle,
          subtitle: questionSubtitle,
          questionType,
          subquestions,
          hasDetails,
          isVisible
      }) => {
        let subvalues = subquestions

        if (questionType === QUESTION_TYPES.tooth) {
          subvalues = [{
            name: `${questionName}_teeth`,
            type: QUESTION_TYPES.tooth,
            value: {
              upper: [],
              lower: []
            }
          }]
        } else if (subquestions !== null) {
          subvalues = subquestions.map(({
            name: subquestionName,
            title: subquestionTitle,
            questionType: subquestionType,
            possibleValues
          }) => {
            return {
              name: subquestionName,
              title: subquestionTitle,
              type: subquestionType,
              possibleValues,
              value: (subquestionType === QUESTION_TYPES.radio) ? '' : []
            }
          })
        }

        return {
          name: questionName,
          title: questionTitle,
          subtitle: questionSubtitle,
          type: questionType,
          hasDetails,
          isVisible,
          subquestions: subvalues,
          detailValue: '',
          value: false
        }
      })
    }
  })
}

/**
 * Fetches the medical questions structure for create case view - step 2
 *
 * @returns {Object[]}
 */
const getMedicalQuestions = async () => {
  try {
    const response = await sdcAPI.get('/medicalquestionnaire')
    const filteredQuestions = filterMedicalQuestions(response.data)

    return {
      haveAnyData: filteredQuestions[SECTION_TYPES.haveAny],
      allThatApplyData: filteredQuestions[SECTION_TYPES.allThatApply]
    }
  } catch (error) {
    Logger.error('MedicalQuestions API - getMedicalQuestions', error)
    throw error
  }
}

/**
 * Fetches the number of aligners of a patient - Questions step 3 (midlecourse correction)
 *
 * @returns {Object[]}
 */
 const getAlignersPatient = async patientId => {
  try {
    const response = await sdcAPI.get(`/patient/${patientId}/aligner`)
    return response.data
  } catch (error) {
    Logger.error('MedicalQuestions API - getAlignersPatient', error)
    throw error
  }
}

export {
  getMedicalQuestions,
  getAlignersPatient
}
