<template>
  <div
    id="account"
    class="account"
  >
    <div class="account__container">
      <sdc-title level="1">
        {{ i18n('ACCOUNT__TITLE') }}
      </sdc-title>
      <office-team class="account__container__section" />
      <dental-service-organization
        v-if="isSuperuser"
        class="account__container__section"
      />
      <practices class="account__container__section" />
      <email-notifications
        v-if="!isSuperuser"
        class="account__container__section"
      />
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import OfficeTeam from '_organisms_/OfficeTeam'
import Practices from '_organisms_/Practices'
import EmailNotifications from '_organisms_/EmailNotifications'
import DentalServiceOrganization from '_organisms_/DentalServiceOrganization'
import { mapGetters } from 'vuex'

export default {
  name: 'Account',
  components: {
    SdcTitle,
    OfficeTeam,
    Practices,
    EmailNotifications,
    DentalServiceOrganization
  },
  computed: {
    ...mapGetters('Auth', ['isSuperuser']),
  },
  methods: {
    i18n
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');
$desktop: map-get($sdc-breakpoints, 'desktop');

.account {
  display: flex;
  justify-content: center;
  @media screen and (min-width: $mobile) {
    margin: 20px 20px 0 20px;
  }

  &__container{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    width: 100%;
    @media screen and (min-width: $desktop) {
      width: 70%;
    }

    &__section {
      margin-bottom: 20px;
    }

    & > .sdc-title {
      margin-bottom: 20px;
    }
  }
}
</style>
