export default {
  primary: 'is-primary',
  link: 'is-ghost',
  warning: 'is-warning'
}

export const SIZES = {
  large: 'is-large',
  medium: 'is-medium',
  small: 'is-small',
  default: 'default'
}
