<template>
  <div class="container">
    <SdcLoading
      v-if="isOrdering"
      :title="i18n('LOADING__CREATE_ORDER_TITLE')"
    />
    <section
      :class="[
        'is-flex',
        'is-justify-content-space-between'
      ]"
    >
      <div class="is-flex is-align-items-baseline">
        <LegalTooltip
          name="payment-info"
          text-key="PAYMENT__LEGAL__TEXT"
        >
          <SdcTitle level="2">
            {{ i18n('PAYMENT__TITLE') }}
          </SdcTitle>
        </LegalTooltip>
      </div>
      <SdcButton
        type="link"
        data-test="cancel-button"
        @click="onSkipPayment"
      >
        {{ i18n('LABEL__CANCEL') }}
      </SdcButton>
    </section>
    <section class="section-panel-rounded">
      <div class="section-panel-rounded-content">
        <FastTrackCareExperience
          v-if="showCareExperience"
          v-model="careLevelSelection"
          hide-prices
          @input="updateExperienceOptions"
        />
        <SdcSmilePay
          v-if="showSmilePayUI"
          :is-loading="isLoadingFinancingOptions"
          :financing-price="financePrice"
          :financing-product-sku="financingProductSKU"
          :price="price"
          :product-sku="productSKU"
          :region="region"
          :discount="discountAmount"
        />
        <FastTrackDiscountCode
          v-if="isAnySmilePayPlanSelected"
          ref="discountCodeComponent"
          :show-communication-message="isSmilePayPaymentPlanSelected"
          @onChange="onDiscountCodeApplied"
          @remove="onRemoveDiscountCode"
        />
        <SdcWallet
          v-if="showWalletUI"
          :payment-broker-account-id="paymentBrokerAccountId"
        />
        <ValidationObserver v-slot="{ invalid }">
          <FastTrackBillingAddress
            v-if="paymentRequired"
            :model="billingAddress"
            @input="onBillingAddressChange"
          />
          <PatientConsent
            v-if="paymentRequired"
            ref="patientConsentComponent"
            :value="patientConsent"
            :disable-get-smile-pay-terms="disableGetSmilePayTerms"
            :application-id="applicationId"
            :title="i18n('PATIENT_CONSENT__TITLE')"
            :show-esign-consent="showEsignConsent"
            @input="onPatientConsentChange"
          />
          <div
            :class="[
              'is-flex',
              'is-align-items-center',
              'is-justify-content-end'
            ]"
          >
            <SdcButton
              v-if="paymentRequired"
              type="primary"
              :disabled="invalid || !canOrder"
              data-test="order-button"
              @click="beginOrder"
            >
              {{ i18n('PAYMENT__ORDER_BUTTON') }}
            </SdcButton>
            <SdcButton
              v-else
              class="mt-5"
              type="primary"
              :disabled="!canContinue"
              data-test="continue-button"
              @click="onSkipPayment"
            >
              {{ i18n('PAYMENT__CONTINUE_BUTTON') }}
            </SdcButton>
          </div>
        </ValidationObserver>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { CARE_LEVEL } from '@/utils/constants'
import { i18n } from '@/utils/i18n'
import I18nConfig from '@/utils/I18nConfig'
import {
  NOTPURCHASINGYET,
  SINGLEPAY,
  SMILEPAY
} from '@/store/modules/payment.store'
import LegalTooltip from '@/components/molecules/LegalTooltip'
import SdcButton from '@/components/atoms/SdcButton'
import SdcLoading from '@/components/atoms/SdcLoading'
import SdcTitle from '@/components/atoms/SdcTitle'
import SdcSmilePay from '@/components/molecules/SdcSmilePay'
import SdcWallet from '@/components/molecules/SdcWallet'
import FastTrackCareExperience from '@/components/molecules/FastTrackCareExperience'
import FastTrackDiscountCode from '@/components/molecules/FastTrackDiscountCode'
import FastTrackBillingAddress from '@/components/molecules/FastTrackBillingAddress'
import PatientConsent from '@/components/molecules/PatientConsent'

export default {
  name: 'Payment',
  components: {
    LegalTooltip,
    SdcButton,
    SdcLoading,
    SdcTitle,
    SdcSmilePay,
    SdcWallet,
    FastTrackCareExperience,
    FastTrackDiscountCode,
    FastTrackBillingAddress,
    PatientConsent,
    ValidationObserver
  },
  provide() {
    return {
      verifyDiscountCode: this.onVerifyDiscountCode
    }
  },
  props: {
    caseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      careLevelSelection: CARE_LEVEL.standard,
      isOrdering: false
    }
  },
  computed: {
    ...mapState('CreateCase', [
      'careLevel',
      'personalInfo',
      'shippingAddress'
    ]),
    ...mapState('Payment', [
      'billingAddress',
      'cartId',
      'discountAmount',
      'financePrice',
      'financingChanged',
      'financingProductSKU',
      'isLoadingFinancingOptions',
      'patientConsent',
      'paymentBrokerAccountId',
      'paymentMethodId',
      'paymentRequired',
      'price',
      'productSKU',
      'smilePayPlan',
      'orderNumber'
    ]),
    ...mapGetters('Payment', [
      'applicationId',
      'isCarePlusEnabled',
      'isDiscountCodeSet',
      'financingSelected'
    ]),
    ...mapGetters('CreateCase', ['smilePayApplicationPayload']),
    showSmilePayUI() {
      return this.price !== null
    },
    showWalletUI() {
      return !!(
        this.paymentBrokerAccountId
        && this.paymentRequired
      )
    },
    region() {
      return this.shippingAddress.state
    },
    canOrder() {
      return !this.isOrdering && this.paymentRequired
    },
    canContinue() {
      return this.cartId && (!this.financingChanged || this.financingSelected)
    },
    disableGetSmilePayTerms() {
      return !this.applicationId || !this.isSmilePayPaymentPlanSelected
    },
    isAnySmilePayPlanSelected() {
      return this.financingSelected
    },
    isSmilePayPaymentPlanSelected() {
      return this.smilePayPlan.paymentPlan === SMILEPAY
    },
    showCareExperience() {
      return this.isCarePlusEnabled
    },
    showEsignConsent() {
      return I18nConfig.showEsignConsent && this.isSmilePayPaymentPlanSelected
    }
  },
  async beforeMount() {
    this.careLevelSelection = this.careLevel
    window.addEventListener('message', this.onMessage)
    await this.loadFinancingOptions(this.careLevelSelection)
    await this.createCustomer()
    this.setPatientConsent(false)
  },
  destroyed() {
    window.removeEventListener('message', this.onMessage)
    this.clearPaymentStore()
    this.resetCreateCaseFlow()
  },
  methods: {
    i18n,
    ...mapActions('Payment', [
      'applyDiscountCode',
      'clearPaymentStore',
      'createAlignerOrder',
      'createApplication',
      'updateApplication',
      'createDraftOrder',
      'createOrder',
      'eSignApplication',
      'loadFinancingOptions',
      'removeDiscountCode',
      'setPaymentMethodId',
      'setSmilePayPlan',
      'skipPayment',
      'submitPaymentBillingAddress',
      'updateCommerceCart',
      'updateCustomer'
    ]),
    ...mapActions('CreateCase', ['resetCreateCaseFlow']),
    ...mapMutations('CaseDetails', ['setOrderStatus']),
    ...mapMutations('Payment', [
      'setBillingAddress',
      'setDiscountAmount',
      'setPatientConsent'
    ]),
    async createCustomer() {
      try {
        await this.updateCustomer()
      } catch {
        this.$sdcToast.open({
          title: i18n('COMMERCE_CUSTOMER_CREATION__ERROR_TITLE'),
          content: i18n('COMMERCE_CUSTOMER_CREATION__ERROR_MESSAGE')
        })
      }
    },
    async beginOrder() {
      this.isOrdering = true
      await this.submitPaymentBillingAddress()
      window.postMessage({ eventType:'wallet-ui-select-payment-method' })
    },
    onPatientConsentChange(patientConsent) {
      this.setPatientConsent(patientConsent)
    },
    onSkipPayment() {
      this.skipPayment(this.caseId)
      this.goToCase()
    },
    async onVerifyDiscountCode(discountCode, isAffiliate) {
      return await this.applyDiscountCode({ discountCode, isAffiliate })
    },
    goToCase() {
      this.$router.push({
        name: 'case-details',
        params: {
          id: this.caseId,
          safeToLeaveRoute: true
        }
      })
    },
    async updateExperienceOptions(careLevel) {
      this.loadFinancingOptions(careLevel)
      this.setSmilePayPlan(NOTPURCHASINGYET)
      if (this.isDiscountCodeSet) {
        this.$refs.discountCodeComponent?.removeCode()
      }
    },
    async updateCart() {
      const cart = {
        cartId: this.cartId,
        productSKU: this.productSKU,
      }

      if (!!this.smilePayPlan.financingProvider) {
        cart.loanApplicationId = this.applicationId
        cart.financeKind = this.smilePayPlan.financingProvider.toLowerCase()
      }

      await this.updateCommerceCart(cart)
    },
    onBillingAddressChange(billingAddress) {
      this.setBillingAddress(billingAddress)
    },
    onDiscountCodeApplied({ discountAmount }) {
      if (discountAmount) {
        this.setDiscountAmount(discountAmount)
      }
    },
    onRemoveDiscountCode() {
      this.removeDiscountCode()
    },
    onPaymentMethodFailure() {
      this.isOrdering = false
    },
    async onPaymentSuccess() {
      try {
        await this.createOrder()
        if (this.isSmilePayPaymentPlanSelected) {
          await this.eSignApplication()
        }
        await this.createAlignerOrder(this.caseId)
        this.setOrderStatus('success')
      } catch {
        this.setOrderStatus('error')
      } finally {
        this.goToCase()
      }
    },
    onPaymentFailure() {
      this.isOrdering = false
      this.$sdcToast.open({
        content: i18n('COMMERCE_CUSTOMER_PAYMENT_FAILURE__ERROR_MESSAGE', [I18nConfig.supportPhoneNumber]),
        indefinite: true,
        type: 'is-danger'
      })
    },
    async onPaymentMethodChange({ isPaymentMethodSelected, paymentMethod }) {
      this.setPaymentMethodId(paymentMethod?.paymentMethodId ?? null)
      if (!isPaymentMethodSelected) {
        this.isOrdering = false
        return
      }

      if (!this.orderNumber) {
        await this.createDraftOrder()
      }

      const paymentAmount = this.smilePayPlan.paymentPlan === SINGLEPAY
        ? this.smilePayPlan.amount
        : this.smilePayPlan.downPayment
      const paymentMetadata = {
        "cartId": this.cartId,
        "orderNumber": this.orderNumber,
        "source": "Partner-Portal",
        "host": "partner-portal",
        "applicationGuid": this.applicationId
      }

      window.postMessage(
        {
          eventType: 'wallet-ui-make-payment',
          detail: {
            amount: paymentAmount,
            paymentId: uuidv4(),
            metadata: paymentMetadata
          }
        }
      )
    },
    async onSelectedPaymentPlan(e) {
      const lastPaymentPlanSelected = this.smilePayPlan.paymentPlan
      this.setSmilePayPlan(e)
      this.setPatientConsent(false)

      const changingToOrFromNotPurchasingYet = [e.paymentPlan, lastPaymentPlanSelected]
        .includes(NOTPURCHASINGYET.paymentPlan)
      if (this.isDiscountCodeSet && changingToOrFromNotPurchasingYet) {
        this.$refs.discountCodeComponent?.removeCode()
      }

      if (e.paymentPlan === SMILEPAY) {
        const { patient } = this.smilePayApplicationPayload
        const app = {
          caseId: this.caseId,
          amount: e.amount,
          months: e.months,
          downPayment: e.downPayment,
          businessEntity: e.businessEntity,
          patient,
        }

        if (!!this.applicationId) {
          await this.updateApplication(app)
          this.$refs.patientConsentComponent?.getTerms()
        } else {
          await this.createApplication(app)
        }
      }

      await this.updateCart()
    },
    onMessage({ data }) {
      switch (data.eventType) {
        case 'wallet-ui-payment-method-failure':
          this.onPaymentMethodFailure()
          break
        case 'wallet-ui-payment-success':
          this.onPaymentSuccess()
          break
        case 'wallet-ui-payment-failure':
          this.onPaymentFailure()
          break
        case 'wallet-ui-payment-method-change':
          this.onPaymentMethodChange(data)
          break
        case 'SelectedPaymentPlanEvent':
          this.onSelectedPaymentPlan(data)
          break
      }
    }
  }
}
</script>
