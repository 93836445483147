<template>
  <main-template>
    <card>
      <template slot="titleSection">
        <sdc-title
          level="2"
          class="card__title"
        >
          {{ i18n('STAFF_TERMS__TITLE') }}
        </sdc-title>
      </template>
      <template slot="descriptionSection">
        <p class="card__description mt-1">
          {{ i18n('STAFF_TERMS__DESCRIPTION') }}
        </p>
      </template>
    </card>
  </main-template>
</template>

<script>
import Card from '_atoms_/Card'
import { i18n } from '_utils_/i18n'
import MainTemplate from '_templates_/MainTemplate'
import SdcTitle from '_atoms_/SdcTitle'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StaffTerms',
  components: {
    Card,
    MainTemplate,
    SdcTitle
  },
  computed:{
    ...mapGetters('Auth', ['boId', 'currentPracticeId', 'practices']),
  },
  async mounted() {
    await this.fetchAuthInfo()

    if(!this.boId || !this.currentPracticeId
      || this.practices.filter(prac => prac.id === this.currentPracticeId && prac.needsToBeSigned).length === 0) {
      this.$router.push({ name: 'home', params: { safeToLeaveRoute: true }})
    }
  },
  methods: {
    ...mapActions('Auth', ['fetchAuthInfo']),
    i18n
  }
}
</script>
