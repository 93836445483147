<template>
  <validation-provider
    :id="name"
    v-slot="{ errors }"
    ref="sdcPhotoUploadProvider"
    :name="name"
    rules="required"
    tag="div"
  >
    <div :class="getClass(errors[0])">
      <sdc-loading
        v-if="isUploading"
        type="element"
      />
      <div
        v-if="!isUploading"
        class="sdc-photo-upload__container"
      >
        <div class="sdc-photo-upload__photo">
          <div class="sdc-photo-upload__thumbnail">
            <sdc-image
              :name="`${name}-image`"
              :src="imageSrc"
              class-name="sdc-photo-upload__thumbnail__image"
            />
          </div>
          <div class="sdc-photo-upload__button">
            <input
              :id="`${name}-file`"
              type="file"
              :name="`${name}`"
              class="sdc-photo-upload__button__file"
              :accept="fileTypes"
              @change="onChange"
            >
            <label
              :for="`${name}-file`"
              class="sdc-photo-upload__button__label"
            >
              <smile-icon
                v-if="!isFileAvailable"
                icon="upload"
                custom-class="is-size-4"
              />
              <span>{{ btnText }}</span>
            </label>
          </div>
        </div>
        <div :class="getPlaceholderClass()">
          {{ placeholder }}
        </div>
      </div>
    </div>
    <span
      v-if="errors[0] && !isUploading"
      class="invalid-feedback d-block"
    >{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { truncate } from 'lodash'
import { i18n } from '_utils_/i18n'
import SdcImage from '_atoms_/SdcImage'
import SdcLoading from '_atoms_/SdcLoading'
import { ACCEPT_TYPES, MAX_SIZE, TYPES_WHITELIST } from './constants'

export default {
  name: 'SdcPhotoUpload',
  components: {
    SdcImage,
    SdcLoading,
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    handleUpload: {
      type: Function,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      isUploading: false,
      uploadedFile: this.$attrs.value || {},
      localFilename: this.$attrs.value || ''
    }
  },
  computed: {
    btnText() {
      const key = this.isFileAvailable ? 'LABEL__REPLACE' : 'LABEL__UPLOAD'

      return i18n(key)
    },
    placeholder() {
      const config = { length: 20, omission: ' [...]' }

      return (this.isFileAvailable) ?
        truncate(this.localFilename, config) :
        i18n('SDC_PHOTO_UPLOAD__PLACEHOLDER')
    },
    fileTypes() {
      return ACCEPT_TYPES.join(',')
    },
    shouldBlock() {
      return this.isDragging
    },
    isFileAvailable() {
      return !!this.localFilename
    }
  },
  watch: {
    localFilename(newVal) {
      this.$emit('input', newVal)
    }
  },
  mounted() {
    const provider = this.$refs.sdcPhotoUploadProvider

    if (this.isAdvancedUpload()) {
      this.setDraggableEvents()
    }

    provider.syncValue(this.localFilename)
  },
  methods: {
    i18n,
    getClass(error) {
      return {
        'sdc-photo-upload': true,
        'sdc-photo-upload--dragging': this.isDragging || this.isUploading || this.isFileAvailable,
        'sdc-photo-upload--invalid': error && !this.isUploading
      }
    },
    getPlaceholderClass() {
      return {
        'sdc-photo-upload__placeholder': true,
        'text-primary': !this.isFileAvailable
      }
    },
    isAdvancedUpload() {
      const div = document.createElement('div')

      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window && 'FileReader' in window
    },
    setDraggableEvents() {
      // Vainilla JS cannot get passed multiple events on addEventListener but one by one
      // Old function() syntax is necessary here for the implicit binds
      // Binds are required to apply the prevention and stopPropagation
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
        .forEach( function( evt ) {
          this.$el.addEventListener(evt, function(e) {
            e.preventDefault()
            e.stopPropagation()
        }.bind(this), false)
      }.bind(this))

      this.setDragInOutEvents()
      this.$el.addEventListener('drop', this.onDrop)
    },
    setDragInOutEvents() {
      const dragLeaveEvents = ['dragleave', 'dragend']
      const dragInEvents = ['dragenter', 'dragover']

      dragInEvents.forEach(ev => this.$el.addEventListener(ev, () => this.isDragging = true))
      dragLeaveEvents.forEach(ev => this.$el.addEventListener(ev, () => this.isDragging = false))
    },
    async onDrop(event) {
      await this.upload(event.dataTransfer.files[0])
    },
    async onChange(event) {
      await this.upload(event.target.files[0])
    },
    async upload(file) {
      if (!file || this.isUploading) return

      const provider = this.$refs.sdcPhotoUploadProvider
      this.isUploading = true

      if (!TYPES_WHITELIST.includes(file.type)) {
        this.$emit(
          'error',
          i18n('ERROR__WRONG_UPLOAD_FORMAT'),
          i18n('ERROR__WRONG_UPLOAD_FORMAT_TITLE')
        )
        this.isUploading = false
        await provider.validate()
        return
      }

      if (file && file.name.length > 0) {
        if (file.size >= MAX_SIZE) {
          this.$emit('error', i18n('ERROR__MAX_FILE_SIZE'))
          this.isUploading = false
          await provider.validate()
          return
        }

        const resultUpload = await this.handleUpload(file)

        if (!resultUpload) {
          await provider.validate()
        } else {
          await provider.validate(file.name)
          this.uploadedFile = file
          this.localFilename = file.name
        }

        this.isUploading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$mini-mobile: map-get($sdc-breakpoints, 'mini-mobile');

.sdc-photo-upload {
  border: 2px dashed $gray-88;
  padding: 20px;
  @media screen and (max-width: $mini-mobile) {
    padding: 4px;
  }
  height: 100%;

  &--dragging {
    border-style: solid;
  }

  &--invalid {
    border: 2px solid $danger;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__photo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      display: block;
      margin: auto;
    }
  }

  &__thumbnail {
    text-align: center;

    &__image {
      height: 120px;
      width: 120px;
    }
  }

  &__button {
    color: $white;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;

    @media screen and (max-width: 1200px) {
      margin-top: 11px;
    }

    &__file {
      display: none;
    }

    &__label {
      background-color: $primary;
      height: 40px;
      width: 126px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0;

      svg {
        margin-right: 11px;
      }
    }
  }

  &__placeholder {
    font-size: $font-size-sm;
    text-align: left;
    margin-top: 20px;
    color: $secondary;

    @media screen and (max-width: 320px) {
      font-size: $font-size-xs;
    }
  }
}
</style>
