/* eslint-disable no-console */
import Logger from '@/utils/Logger'
class ErrorException extends Error {
  constructor(type, message, stack) {
    super(message, stack)

    this.name = type
    this.message = message
    this.stack = stack
  }

  throwError() {
    if (process.env.NODE_ENV === 'development') {
      Logger.error(`${this.name} - ${this.message}`, this.stack)
    }
  }
}

export default ErrorException
