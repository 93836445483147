import { getRoles } from '_api_/roles.api'
import ErrorException from '@/utils/Exceptions/ErrorException'

export const mutationTypes = {
  setRoles: 'setRoles',
  clearRoles: 'clearRoles',
  loadError: 'loadError'
}

export const state = {
  roles : [],
  loadError: {
    status: null
  }
}

export const mutations = {
  [mutationTypes.setRoles](state, payload) {
    state.roles = payload
  },
  [mutationTypes.clearRoles](state) {
    state.roles = []
  },
  [mutationTypes.loadError](state, payload) {
    state.loadError = payload
  }
}

export const getters = {
  getByName: (state) => (name) => {
    return state.roles.find(p => p.name === name)
  },
  getById: (state) => (id) => {
    return state.roles.find(p => p.id === id)
  }
}

export const actions = {
  async requestRoles({ commit }) {
    try {
      const roles = await getRoles()
      commit(mutationTypes.setRoles, roles)
    } catch (error) {
      commit(mutationTypes.loadError, {
        status: error.status
      })
      throw new ErrorException('RolesException', error)
    }
  },
  clear({ commit}) {
    commit(mutationTypes.clearRoles)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
