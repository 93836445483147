/* eslint-disable no-console */
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

/**
 * Get the appointments list from the API
 *
 * @param {Object} params
 * @prop {Number} PageNumber
 * @prop {Number} PageSize
 * @prop {String} SearchQuery
 * @prop {String} SortColumn
 * @prop {String} SortDirection
 * @param {Object} params
 * @param {String} storeNumber Store number
 * @returns {Object} {appointments, metadata}
 * @prop {Array} appointments
 * @prop {Object} metadata
 */
const getAppointments = async (params, storeNumber = null) => {
  try {
    const store = storeNumber ? `/${storeNumber}` : ''
    const response = await api.get(`/appointments${store}`, { params })
    const { appointments, metadata } = response.data
    return { appointments, metadata }
  } catch (error) {
    Logger.error('Appointments API - getAppointments', error)
    throw error
  }
}

export {
  getAppointments
}
