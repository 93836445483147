/**
 * Axios Interceptor types
 */
export const INTERCEPTOR_TYPES = {
  request: 'request',
  response: 'response'
}

/**
 * @param {Object} axiosInstance
 * @param {Object[]} axiosInterceptors
 * @param {string} type - 'request'|'response'
 */
export const useInterceptors = (axiosInstance, interceptors, type = INTERCEPTOR_TYPES.request) => {
  interceptors.forEach(interceptor => axiosInstance.interceptors[type].use(
    interceptor,
    error => Promise.reject(error)
  ))
  // Return function to eject/unsuscribe interceptors
  return () => {
    interceptors.forEach(interceptor => axiosInstance.interceptors[type].eject(interceptor))
  }
}
