<template>
  <sdc-modal
    id="location-modal"
    ref="location-modal"
    name="location-modal"
    :title-props="titleProps"
    hide-footer
    show-close-button
  >
    <div
      v-for="continent in continents"
      :key="continent.code"
    >
      <div class="continent-container__title">
        <sdc-title level="3">
          {{ i18n(`LOCATION_MODAL__CONTINENT_${continent.code}`) }}
        </sdc-title>
      </div>
      <div class="continent-container__country-container">
        <template v-for="country in continent.countries">
          <div
            v-for="localeItem in country.locales"
            :key="localeItem"
            class="continent-container__country-container__control"
            @click="redirect(localeItem)"
          >
            <div class="continent-container__country-container__control__header">
              <div class="continent-container__country-container__control__header__title">
                <sdc-title level="6">
                  {{ i18n(`LABEL__COUNTRY_${country.code.toUpperCase()}`) }}
                </sdc-title>
              </div>
              <div class="continent-container__country-container__control__header__flag">
                <img
                  class="continent-container__country-container__control__header__flag__img"
                  :alt="country.code"
                  :src="getSrcCountryFlag(country.code)"
                >
              </div>
            </div>
            <div class="continent-container__country-container__control__locale">
              {{ getLocaleLanguageLabel(localeItem) }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import SdcModal from '_molecules_/SdcModal'
import { localeConstants } from '_utils_/constants'
import Location from '_utils_/Location'

export default {
  name: 'LocationModal',
  components: {
    SdcTitle,
    SdcModal
  },
  data() {
    return {
      continents: [],
      titleProps: {
        text: 'LOCATION_MODAL__SELECT_LOCATION'
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['location-modal']
    }
  },
  mounted() {
    Location.loadCountries().then(() => {
      this.continents = Location.continents
    })
  },
  methods: {
    i18n,
    focus() {
      this.modalRef.focus()
    },
    open() {
      this.modalRef.open()
    },
    close() {
      this.modalRef.close()
    },
    getSrcCountryFlag(countryCode){
      return require('_assets_/flags/flag-'+ countryCode.toUpperCase() + '.svg') || ''
    },
    getLocaleLanguageLabel(localeItem){
      return i18n(
        `LOCATION_MODAL__LOCALE_${localeItem
          .toUpperCase()
          .replace(localeConstants.SEPARATOR,'_')
        }_LANGUAGE`
      )
    },
    redirect(localeItem) {
      this.close()
      window.location.href = Location.buildUrl(localeItem)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.continent-container {
  display: flex;
  flex-direction: column;

  &__title {
    text-align: left;
  }

  &__country-container {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    &__control {
      height: 80px;
      border: 2px solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 12px ;
      margin-right: 20px;
      cursor: pointer;
      @media screen and (max-width: $mobile) {
        width: 100%;
      }
      &__header {
        display: flex;
        flex-direction: row;
        height: 18px;
        width: 100%;
        &__title {
          text-align: left;
          font-size: 16px;
          width: 250px;
          @media screen and (max-width: $mobile) {
            width: 100%;
          }
        }
        &__flag {
          text-align: right;
          &__img {
            width: 26.67px;
            height: 20px;
          }
        }
      }
      &__locale {
        text-align: left;
        color: $gray-600;
        font-size: 16px;
      }
    }
  }
}
</style>
