<template>
  <div>
    <sdc-modal
      ref="create-case-by-appointment-modal"
      name="create-case-by-appointment-modal"
      size="small"
      hide-header
      hide-footer
    >
      <create-case-by-appointment-form
        @onCancel="onCancel"
        @onContinue="onContinue"
      />
    </sdc-modal>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal/index.vue'
import CreateCaseByAppointmentForm from '_molecules_/CreateCaseByAppointmentForm/index.vue'

export default {
  name: 'CreateCaseByAppointmentModal',
  components: {
    CreateCaseByAppointmentForm,
    SdcModal,
  },
  data() {
    return {
    }
  },
  computed: {
    modalRef() {
      return this.$refs['create-case-by-appointment-modal']
    }
  },
  methods: {
    i18n,
    openModal() {
      this.modalRef.open()
    },
    onCancel() {
      this.modalRef.close()
    },
    onContinue() {
      this.modalRef.close()
    }
  }
}

</script>


<style lang="scss" scoped>
@import '_theme_/_variables';

.appointment_status_case_button {
  &__open {
    margin: 0;
    padding: 5px 8px 7px !important;
    font-weight: $font-weight-light;
    font-size: $font-size-sm * 0.75;
    line-height: 1;
    border-radius: 8px !important;
    border: 0;
  }
}

</style>
