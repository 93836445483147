<template>
  <sdc-modal
    ref="cancel-modal"
    name="cancel-modal"
    class="cancel-modal"
    size="small"
    hide-header
    @onConfirm="confirm"
    @onCancel="close"
  >
    <section>
      <slot />
    </section>
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal'

export default {
  name: 'CancelModal',
  components: {
    SdcModal
  },
  data() {
    return {
      buttonProps: {
        cancelProps: {
          text: 'LABEL__YES'
        },
        confirmProps: {
          text: 'LABEL__NO'
        }
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['cancel-modal']
    },
  },
  methods: {
    i18n,
    confirm(closeModal) {
      this.$emit('ok')
      closeModal()
    },
    close() {
      this.$emit('cancel')
    },
    open() {
      this.modalRef.open()
    }
  },
}
</script>
