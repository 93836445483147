import ErrorException from './ErrorException'

class UtilsException extends ErrorException {
  constructor({ message, stack }) {
    super('UtilsException', message, stack)

    this.throwError()
  }
}

export default UtilsException
