import ImageBlobReduce from 'image-blob-reduce'

const DEFAULT_MAX_IMAGE_SIZE = 1280

/**
 * It will use this value to compare the width or height, depending
 * on which is bigger, to see if it has exceeded the max value. If
 * the max value has been exceeded it will scale the image down.
 * @async
 * @param {Blob} image Image you want to modify
 * @param {Object.<string, string|number|boolean>} [options]
 * @param {number} [options.maxSize=DEFAULT_MAX_IMAGE_SIZE] Value is in pixels
 * @returns {Promise<Blob>}
 */
const reduce = async (image, { maxSize = DEFAULT_MAX_IMAGE_SIZE } = {}) => {
  const reduce = ImageBlobReduce()
  return await reduce.toBlob(image, { max: maxSize })
}


/**
 *
 * It will open a popup to allow print directly the image
 *
 * @param {String} image Image url/data
 */
const printImage = (imageSrc) => {
  const image = new Image(500)

  const doc = '<title>title</title>'
  const winPrint = window.open('', '_blank', 'left=0,top=0,width=800,height=500,toolbar=0,scrollbars=0,status=0')
  winPrint.document.write(doc)
  winPrint.document.documentElement.appendChild(image)
  image.onload = () => {
    winPrint.print()
    winPrint.close() //This close the label window after an action (cancel or download)
  }

  winPrint.onafterprint = () => winPrint.document.close() //This avoid close the label window.

  image.src = imageSrc
}

export { reduce, printImage }
