<template>
  <div
    id="fast-track-insurance"
    class="fast-track-insurance"
  >
    <sdc-title level="4">
      {{ i18n('FAST_TRACK_INSURANCE__TITLE') }}
    </sdc-title>
    <div class="fast-track-insurance__subtitle">
      {{ i18n('FAST_TRACK_INSURANCE__SUBTITLE') }}
      <sdc-tooltip
        name="fast-track-insurance-tooltip"
        class="fast-track-insurance-tooltip"
        :text="i18n('FAST_TRACK_INSURANCE__TOOLTIP_TEXT')"
        position="is-bottom"
        multiline
      />
    </div>
    <sdc-button
      id="fast-track-insurance-button"
      type="primary"
      outlined
      @click="handleClickCheckEligibility"
    >
      {{ i18n('FAST_TRACK_INSURANCE__CHECK_BUTTON_LABEL') }}
    </sdc-button>
  </div>
</template>
<script>
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import SdcTooltip from '_atoms_/SdcTooltip'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'

export default {
  name: 'FastTrackInsurance',
  components: {
    SdcTitle,
    SdcButton,
    SdcTooltip
  },
  methods: {
    i18n,
    handleClickCheckEligibility() {
      const insuranceUrl = I18nConfig.insuranceUrl
      if(insuranceUrl) {
        window.open(insuranceUrl, '_blank')
      }
    }
  }
}
</script>
<style lang="scss">
.fast-track-insurance {
  &__subtitle {
    margin-bottom: 20px;
  }
  &-tooltip {
    display: inline-block;
  }
}
</style>
