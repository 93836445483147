import ErrorException from '@/utils/Exceptions/ErrorException'
import { getPhotoUploadURL, uploadPhoto, getPhotoURL } from '_api_/photos.api'
import * as Images from '_utils_/images'

const mutationTypes = {
  clearPatientPhotos: 'clearPatientPhotos',
  setPatientPhoto: 'setPatientPhoto',
  setQueuedUpload: 'setQueuedUpload',
  removeQueuedUpload: 'removeQueuedUpload'
}

const state = {
  queuedUploads: [],
  patientPhotos: {
    upper: {
      url: '',
      file: {},
      filename: ''
    },
    lower: {
      url: '',
      file: {},
      filename: ''
    },
    straight: {
      url: '',
      file: {},
      filename: ''
    }
  }
}

const mutations = {
  [mutationTypes.clearPatientPhotos](state) {
    state.patientPhotos = {
      upper: {
        url: '',
        file: {},
        filename: ''
      },
      lower: {
        url: '',
        file: {},
        filename: ''
      },
      straight: {
        url: '',
        file: {},
        filename: ''
      }
    }
  },
  [mutationTypes.setPatientPhoto](state, { data, photoType }) {
    state.patientPhotos[photoType].url = data.url
    state.patientPhotos[photoType].file = data.file
    state.patientPhotos[photoType].filename = data.file.name
  },
  [mutationTypes.setQueuedUpload](state, uploadItem) {
    state.queuedUploads.push(uploadItem)
  },
  [mutationTypes.removeQueuedUpload](state, uploadItem) {
    state.queuedUploads = state.queuedUploads.filter(item => item !== uploadItem)
  }
}

const actions = {
  async uploadPatientPhoto({ commit, state }, payload) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })

    let response = true

    const { name, type, file, uuid } = payload
    const photoTypeMap = {
      upper: 'UpperOpen',
      lower: 'LowerOpen',
      straight: 'StraightOnClosed'
    }

    commit(mutationTypes.setQueuedUpload, type)

    try {
      const photoType = photoTypeMap[type]
      const blob_image = await Images.reduce(file)
      const urlPayload = {
        PhotoFileName: name,
        PhotoSize: blob_image.size,
        photoType: photoType,
        uid: uuid
      }
      const url = await getPhotoUploadURL(urlPayload)

      await uploadPhoto({ url, photo: blob_image })

      const photoUrl = await getPhotoURL({ uuid, photoType })

      commit('setPatientPhoto', {
        data: {
          url: photoUrl,
          file
        },
        photoType: type
      })
    } catch (error) {
      response = null
      throw new ErrorException('PhotosException', error)
    }

    commit(mutationTypes.removeQueuedUpload, type)

    if (state.queuedUploads.length === 0) {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
    }

    return response
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  keysToPersist: '*'
}
