<template>
  <section class="view-margin">
    <correction-container />
    <cancel-modal ref="cancelModal">
      {{ i18n('CREATE_CASE__MODAL_TEXT') }}
    </cancel-modal>
  </section>
</template>

<script>
import CorrectionContainer from '_templates_/CorrectionContainer'
import CancelModal from '_molecules_/CancelModal'
import { mapMutations } from 'vuex'
import { i18n } from '_utils_/i18n'
export default {
  name: 'CreateCorrection',
  components: {
    CorrectionContainer,
    CancelModal
  },
  beforeRouteLeave({ params }, _, next) {
    if (params.safeToLeaveRoute === true) return next()
    this.$refs.cancelModal.open()
    this.$refs.cancelModal.$on('ok', () => {
      this.clearState()
      this.clearPatientPhotos()
      this.clearInfo()
      next()
    })
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['clearState']),
    ...mapMutations('InformationForm', ['clearInfo']),
    ...mapMutations('Photos', ['clearPatientPhotos']),
  }
}
</script>
