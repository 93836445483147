<template>
  <div
    :id="inputId"
    class="validated-date-input"
  >
    <div class="validated-date-input--fields-container">
      <div>
        <validated-input
          v-model="firstModel"
          v-bind="firstDate"
          :formatter="getFormatter(firstModel)"
          :attrs="{ inputmode: 'numeric', class: getInvalidDateClass(), pattern: '[0-9]*' }"
          :max-length="maxLengths.month"
          :error-msg-validator="shouldRenderErrorMsg"
          :disabled="disabled"
          @blur="fillDate('firstModel', $event)"
          @catch="setGroupError"
        />
      </div>
      <div>
        <validated-input
          v-model="secondModel"
          v-bind="secondDate"
          :formatter="getFormatter(secondModel)"
          :attrs="{ inputmode: 'numeric', class: getInvalidDateClass(), pattern:'[0-9]*' }"
          :max-length="maxLengths.day"
          :error-msg-validator="shouldRenderErrorMsg"
          :disabled="disabled"
          @blur="fillDate('secondModel', $event)"
          @catch="setGroupError"
        />
      </div>
      <div>
        <validated-input
          v-model="date.year"
          :name="fieldsNames.year"
          :formatter="getFormatter(date.year)"
          :rules="fieldsRules.year"
          :placeholder="i18n('LABEL__DATE_YEAR')"
          :max-length="maxLengths.year"
          :attrs="{ inputmode: 'numeric', class: getInvalidDateClass(), pattern: '[0-9]*' }"
          :error-msg-validator="shouldRenderErrorMsg"
          :disabled="disabled"
          @catch="setGroupError"
        />
      </div>
    </div>

    <div
      v-if="showGroupError"
      name="dateErrorMsg"
      class="invalid-feedback d-block"
    >
      {{ groupErrorMessage }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DateFieldsProcessor from './dateFieldsProcessor'
import ValidatedInput from '_atoms_/ValidatedInput'
import { dateErrorTypeKeywords, inputMaxLengths } from '_utils_/constants'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { i18nConstants } from '_utils_/constants'
import groupErrorMessages from './groupErrorMessages'

const DEFAULT_DATE = moment()
let processor

/**
 ** Props
 ** @prop inputId String Id of the date element
 ** @prop customRules Object Rules applied for each key (day, month, year) -> isMinor:@day,@month|required
 ** @prop date Object Model for date values
 */
export default {
  name: 'ValidatedDateInput',
  components: {
    ValidatedInput
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    customRules: {
      type: Object,
      default: () => ({
        day: '',
        month: '',
        year: ''
      })
    },
    disabled:{
      type:Boolean,
      default:false
    },
    value: {
      type: Object,
      default: () => ({
        day: DEFAULT_DATE.format('DD'),
        month: DEFAULT_DATE.format('MM'),
        year: DEFAULT_DATE.format('YYYY')
      }),
      validator(prop) {
        return (
          typeof prop.day !== 'undefined' &&
          typeof prop.month !== 'undefined' &&
          typeof prop.year !== 'undefined'
        )
      }
    }
  },
  data() {
    return {
      defaultDateInputOrder: i18nConstants.defaultDateInputOrder,
      maxLengths: inputMaxLengths.dates,
      showGroupError: false,
      groupDateErrors: {},
      groupErrorMessage: '',
      date: { ...this.value },
      firstModel: '',
      secondModel: '',
    }
  },
  computed: {
    fieldsRules() {
      return processor.getFieldsRules()
    },
    fieldsNames() {
      return processor.getFieldsNames()
    },
    firstDate(){
      return I18nConfig.dateInputOrder.join() === this.defaultDateInputOrder.join() ? this.monthProps() : this.dayProps()
    },
    secondDate(){
      return I18nConfig.dateInputOrder.join() === this.defaultDateInputOrder.join() ? this.dayProps() : this.monthProps()
    }
  },
  watch: {
    firstModel(newVal) {
      const date = this.date
      this.firstModel = newVal
      if(I18nConfig.dateInputOrder.join() === this.defaultDateInputOrder.join()){
        date.month = this.firstModel
      } else{
        date.day = this.firstModel
      }
    },
    secondModel(newVal) {
      const date = this.date
      this.secondModel = newVal
      if(I18nConfig.dateInputOrder.join() === this.defaultDateInputOrder.join()){
        date.day =  this.secondModel
      } else{
        date.month =  this.secondModel
      }
    },
    date: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  beforeMount() {
    const date = this.date
    this.firstModel = I18nConfig.dateInputOrder?.join() === this.defaultDateInputOrder?.join() ? date.month : date.day
    this.secondModel = I18nConfig.dateInputOrder?.join() === this.defaultDateInputOrder?.join() ? date.day : date.month
    processor = new DateFieldsProcessor(this.inputId, this.customRules)

    groupErrorMessages.load()
  },
  mounted() {
    Object.values(this.fieldsNames).forEach(
      field => (this.groupDateErrors[field] = '')
    )
  },
  methods: {
    i18n,
    monthProps(){
      return {
        name: this.fieldsNames.month,
        rules: this.fieldsRules.month,
        placeholder: i18n('LABEL__DATE_MONTH')
      }
    },
    dayProps(){
      return{
        name: this.fieldsNames.day,
        rules: this.fieldsRules.day,
        placeholder: i18n('LABEL__DATE_DAY')
      }
    },
    shouldRenderErrorMsg(error) {
      return error && !this.isGroupError(error)
    },
    shouldRenderErrorGroupMsg() {
      const dateErrorMessages = Object.values(this.groupDateErrors)

      return (
        dateErrorMessages.length > 0 &&
        dateErrorMessages.every(this.isGroupError)
      )
    },
    getInvalidDateClass() {
      return this.shouldRenderErrorGroupMsg() ? 'is-invalid' : ''
    },
    setGroupError({ name, errors }) {
      this.$nextTick(() => {
        this.groupDateErrors[name] = (errors && errors.length > 0) ? errors[0] : ''
        this.showGroupError = this.shouldRenderErrorGroupMsg()
        if(this.showGroupError) this.setGroupErrorMessage()
      })
    },
    fillDate(dateModel, { value }) {
      if (value.length === 1 && value !== '0') this[dateModel] = `0${value}`
    },
    getFormatter(previousValue) {
      const inputRegex = RegExp(/^\d*$/)
      return value => {
        return inputRegex.test(value) ? value : previousValue
      }
    },
    isGroupError(error = '') {
      if (error.length <= 0) return false

      return JSON.stringify(dateErrorTypeKeywords).includes(error)
    },
    setGroupErrorMessage() {
      const groupDateErrors = Object.values(this.groupDateErrors)
      let selectedErrorType

      for (const errorKey in dateErrorTypeKeywords) {
        const errorMessageType = dateErrorTypeKeywords[errorKey]

        if (groupDateErrors.every(error => error === errorMessageType)) {
          selectedErrorType = errorMessageType
          break
        }
      }
      this.groupErrorMessage = groupErrorMessages.get(selectedErrorType)
    }
  }
}
</script>
<style lang="scss">
.validated-date-input {
  margin-bottom: 1rem;
  width: 100%;

  &--fields-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      width: 32%;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control.is-invalid {
    padding-right: 0;
  }
}
</style>
