import moment from 'moment'
import {
  alpha_dash,
  required,
  email,
  digits,
  integer,
  min,
  max,
  max_value,
  min_value
} from 'vee-validate/dist/rules'
import { ageLimit } from '_utils_/dateHelpers'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { EXPIRATION_DATE_CREDIT_CARD_KEYWORD, MAX_NUMBER_YEARS, dateErrorTypeKeywords, EMAIL_TEAM_MEMBER_VALIDATION_REGEX, SDC_EMAIL_DOMAIN } from '_utils_/constants'

export const validationRules = () => [
  {
    rule: 'required',
    options: {
      ...required,
      message: i18n('ERROR__INPUT_REQUIRED')
    }
  },
  {
    rule: 'digits',
    options: {
      ...digits,
      message: i18n('ERROR__INPUT_DIGITS')
    }
  },
  {
    rule: 'integer',
    options: {
      ...integer,
      message: i18n('ERROR__INPUT_INTEGER')
    }
  },
  {
    rule: 'short-required',
    options: {
      ...required,
      message: i18n('ERROR__INPUT_SHORT_REQUIRED')
    }
  },
  {
    rule: 'email',
    options: {
      ...email,
      message: i18n('ERROR__INPUT_EMAIL')
    }
  },
  {
    rule: 'min',
    options: {
      ...min,
      message: i18n('ERROR__INPUT_DIGITS')
    }
  },
  {
    rule: 'max',
    options: {
      ...max,
      message: i18n('ERROR__INPUT_DIGITS')
    }
  },
  {
    rule: 'maxValue',
    options: {
      ...max_value,
      message: i18n('ERROR__INVALID_VALUE')
    }
  },
  {
    rule: 'minValue',
    options: {
      ...min_value,
      message: i18n('ERROR__INVALID_VALUE')
    }
  },
  {
    rule: 'alphaDash',
    options: {
      ...alpha_dash,
      message: i18n('ERROR__INVALID_VALUE')
    }
  },
  {
    rule: 'isOneHundredYearsOldOrLess',
    options: {
      params: ['day', 'month', 'year'],
      validate(value, { day, month, year }) {
        const limitAgeAllowed = 100
        if (year < limitAgeAllowed) {
          return false
        }
        const inputDate = new Date(year, month - 1, day)
        return ageLimit(inputDate, limitAgeAllowed + 1)
      },
      message: dateErrorTypeKeywords.isOneHundredOrLess
    }
  },
  {
    rule: 'isGuardianMinor',
    options: {
      params: ['day', 'month', 'year'],
      validate(value, { day, month, year }) {
        const inputDate = new Date(year, month - 1, day)

        return !ageLimit(inputDate, I18nConfig.guardianAge)
      },
      message: dateErrorTypeKeywords.isGuardianMinor
    }
  },
  {
    rule: 'requiredBoolean',
    options: {
      validate(value) {
        return value === true
      },
      message: i18n('ERROR__INPUT_REQUIRED')
    }
  },
  {
    rule: 'requiredRetainer',
    options: {
      ...required,
      message: i18n('ERROR__RETAINER_REQUIRED')
    }
  },
  {
    rule: 'requiredTerms',
    options: {
      ...required,
      message: i18n('ERROR__INPUT_REQUIRED')
    }
  },
  {
    rule: 'requiredTermsBoolean',
    options: {
      validate(value) {
        return value === true
      },
      message: i18n('ERROR__INPUT_REQUIRED')
    }
  },
  {
    rule: 'requiredTeeth',
    options: {
      validate(value) {
        return value.upper.length > 0 || value.lower.length > 0
      },
      message: i18n('ERROR__TEETH_REQUIRED')
    }
  },
  {
    rule: 'requiredCreditCard',
    options: {
      ...required,
      message: i18n('ERROR__STRIPE_CREDIT_CARD')
    }
  },
  {
    rule: 'isExistingGuardianEmail',
    options: {
      validate() {
        return i18n('CREATE_CASE__EXISTING_EMAIL')
      }
    }
  },
  {
    rule: 'isExistingEmail',
    options: {
      validate() {
        return i18n('ERROR__EXISTING_EMAIL')
      }
    }
  },
  {
    rule: 'isInvalidEmail',
    options: {
      validate() {
        return i18n('ERROR__INPUT_EMAIL')
      }
    }
  },
  {
    rule: 'isMidcourseNotAvailable',
    options: {
      validate(value, args) {
        return i18n(`ERROR__INPUT_EMAIL_MIDCOURSE_CORRECTION_${args}`)
      }
    }
  },
  {
    rule: 'isRefinementNotAvailable',
    options: {
      validate(value, args) {
        return i18n(`ERROR__INPUT_EMAIL_REFINEMENT_${args}`)
      }
    }
  },
  {
    rule: 'invalidDiscountCode',
    options: {
      validate(value) {
        return i18n('ERROR__DISCOUNT_CODE', [value])
      }
    }
  },
  {
    rule: 'isValidExpirationDate',
    options: {
      params: ['month', 'year'],
      validate(_, { month, year }) {
        const firstDayMonth = 1
        const formatDate = 'YYYY-MM-DD'
        const currentDate = moment()
          .set('date', firstDayMonth)
          .format(formatDate)
        const creditcardExpirationDate = moment(
          `${year}-${month}-0${firstDayMonth}`,
          formatDate,
          true
        )
        if (!creditcardExpirationDate.isValid()) return false
        return creditcardExpirationDate.isSameOrAfter(currentDate)
      },
      message: EXPIRATION_DATE_CREDIT_CARD_KEYWORD
    }
  },
  {
    rule: 'isMaxYear',
    options: {
      params: ['month', 'year'],
      validate(_, { month, year }) {
        const expirationDate = moment(`${year}-${month}`, 'YYYY-MM', true)
        const maxDate = moment().add(MAX_NUMBER_YEARS, 'years')
        return expirationDate.isBefore(maxDate)
      },
      message: i18n('ERROR__CREDIT_CARD_MAX_YEAR')
    }
  },
  {
    rule: 'isValidDate',
    options: {
      params: ['day', 'month', 'year'],
      validate(_, { day, month, year }) {
        return moment(`${month}-${day}-${year}`, 'M-D-YYYY', true).isValid()
      },
      message: dateErrorTypeKeywords.date
    }
  },
  {
    rule: 'isInvalidPhone',
    options: {
      validate() {
        return i18n('ERROR__TELEPHONE_INPUT')
      }
    }
  },
  {
    rule: 'isDuplicatedDSOName',
    options: {
      validate() {
        return i18n('OFFICE_BUSINESS_ORGANIZATION__ADD_DSO_WARNING')
      }
    }
  },
  {
    rule: 'isInvalidStoreNumber',
    options: {
      validate() {
        return i18n('PRACTICES__EDIT_PRACTICE_NOT_VALID_STORE_NUMBER')
      }
    }
  },
  {
    rule: 'emailExtended',
    options: {
      params: ['isSuperUserSelected'],
      validate(value, { isSuperUserSelected }) {
        const re = EMAIL_TEAM_MEMBER_VALIDATION_REGEX
        const validEmail = re.test(value)

        if (value.indexOf(SDC_EMAIL_DOMAIN) > -1) {
          return validEmail && isSuperUserSelected === 'true'
        }

        return validEmail
      },
      message: i18n('ERROR__INPUT_EMAIL')
    }
  },
  {
    rule: 'minCustom',
    options: {
      params: ['minLength'],
      validate(value) {
        return value.length >= 10
      },
      message: (field, params, data) => {
        return i18n('ERROR__INVALID_MIN_NUMBER', [params.minLength])
      }
    }
  }
]

export const i18nValidationRules = () => ([
  {
    rule: 'underMinimumAge',
    options: {
      params: ['day', 'month', 'year'],
      validate(value, { day, month, year }) {
        const inputDate = new Date(year, month - 1, day)

        return !ageLimit(inputDate, I18nConfig.minimumAge)
      },
      message: dateErrorTypeKeywords.underMinimumAge
    }
  }
])
