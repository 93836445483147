<template>
  <main-template>
    <Card>
      <template slot="titleSection">
        <sdc-title
          level="2"
          class="card__title"
        >
          {{ i18n('SENT_EMAIL_TITLE') }}
        </sdc-title>
      </template>
      <template slot="descriptionSection">
        <p class="card__description mt-1">
          {{ i18n('SENT_EMAIL_DESCRIPTION') }}
        </p>
      </template>
      <template slot="contentSection">
        <resend-password />
      </template>
    </Card>
  </main-template>
</template>

<script>
import Card from '@/components/atoms/Card'
import { i18n } from '_utils_/i18n'
import MainTemplate from '_templates_/MainTemplate'
import ResendPassword from '_molecules_/ResendPassword'
import SdcTitle from '_atoms_/SdcTitle'

export default {
  name: 'SentEmail',
  components: {
    Card,
    MainTemplate,
    ResendPassword,
    SdcTitle
  },
  methods: {
    i18n
  }
}
</script>
