<template>
  <div>
    <b-navbar v-if="displayNavBar">
      <template #brand>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/' }"
        >
          <sdc-image
            name="sdc-logo"
            alt-text="sdc-logo"
            :src="logoImageSrc"
          />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item
          class="doctor-title"
          tag="router-link"
          :to="{ path: '/home' }"
        >
          {{ i18n('MENU__PARTNER_PORTAL') }}
        </b-navbar-item>
      </template>
      <template #end>
        <template v-if="isLogged">
          <b-navbar-item
            v-if="canCreateCase"
            tag="div"
            class="navbar-create-container"
          >
            <sdc-button
              class="navbar-create"
              @click="openFlowSelectorModal"
            >
              {{ i18n('MENU__CREATE_CASE') }}
            </sdc-button>
          </b-navbar-item>
          <dropdown-menu :only-sign-out-option="canSeeOnlySignOutOption" />
        </template>
        <b-navbar-item
          v-else
          tag="div"
        >
          <a
            href="#"
            target="_self"
            @click="openLocationModal"
          >
            <img
              class="country-flag"
              :alt="countryCode"
              :src="countryFlag"
            >
          </a>
        </b-navbar-item>
      </template>
    </b-navbar>
    <case-flow-selector-modal ref="flowSelectorModal" />
    <location-modal ref="locationModal" />
  </div>
</template>

<script>
import SdcImage from '_atoms_/SdcImage'
import DropdownMenu from '_atoms_/DropdownMenu'
import { i18n } from '_utils_/i18n'
import LocationModal from '_molecules_/LocationModal'
import CaseFlowSelectorModal from '_molecules_/CaseFlowSelectorModal'
import { mapGetters } from 'vuex'
import Location from '_utils_/Location'
import SdcButton from '_atoms_/SdcButton'

export default {
  name: 'NavBar',
  components: {
    SdcButton,
    SdcImage,
    DropdownMenu,
    LocationModal,
    CaseFlowSelectorModal
  },
  data() {
    return {
      loggedOutPages: [
        'login',
        'reset-password',
        'sent-email'
      ],
      showCreateCaseButton: false
    }
  },
  computed: {
    ...mapGetters('Auth', ['canCreateCases', 'canBypassSignValidation']),
    ...mapGetters('ErrorStatus', ['isErrorStatusPresent']),
    logoImageSrc() {
      return require('_assets_/sdc-logo.svg')
    },
    isLogged() {
      return !this.loggedOutPages.includes(this.$route.name)
    },
    countryCode() {
      return Location.countryCode
    },
    countryFlag() {
      return require('_assets_/flags/flag-' + this.countryCode.toUpperCase() + '.svg')
    },
    displayNavBar() {
      return !this.isErrorStatusPresent
    },
    canCreateCase() {
      return this.canCreateCases && this.showCreateCaseButton && !this.canSeeOnlySignOutOption
    },
    canSeeOnlySignOutOption() {
      return !this.canBypassSignValidation
    }
  },
  watch:{
    $route(to) {
      this.showCreateCaseButton = !['create-case', 'create-correction', 'create-refinement'].includes(to.name)
    }
  },
  methods: {
    i18n,
    openLocationModal() {
      this.$refs.locationModal.open()
    },
    openFlowSelectorModal() {
      this.$refs.flowSelectorModal.open()
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.navbar {
  height: 88px;

  .navbar-item {
    img {
      max-height: $navbar-item-img-max-height;
    }

    &.navbar-create-container {
      display: flex;
    }
  }
}

.navbar-create {
  width: 142px !important;
  height: 40px !important;
  line-height: $font-size-base !important;
  font-family: $font-family-bold !important;
  font-weight: $font-weight-normal !important;
  flex-grow: 1;
}

a {
  &.doctor-title.navbar-item {
    color: $primary !important;
    font-weight: $font-weight-normal !important;
  }
  font-family: $font-family-black;
  font-size: $font-size-md;
}

.country-flag {
  height: 30px;
  width: 45px;
}
</style>
