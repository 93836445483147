<template>
  <dynamic-table
    name="appointments"
    title="MENU__APPOINTMENTS"
    :search-props="searchProps"
    :filters="filterProps"
    :table-props="tableProps"
    :paginator-props="paginatorProps"
    @onSearch="onSearch"
    @onClearSearch="onClearSearch"
    @onSort="onTableSort"
    @onPageChanged="onPageChanged"
  >
    <template #currentStatus="data">
      <appointment-status-case-button
        v-if="data.item.currentStatus === 'SCHED'"
        :status-id="data.item.currentStatus"
        :email="data.item.email"
        :full-name="data.item.fullName"
        :case-uuid="data.item.caseUuid"
      />
      <appointments-status-label
        v-else
        :status-id="data.item.currentStatus"
      />
    </template>
  </dynamic-table>
</template>

<script>
import { i18n } from '_utils_/i18n'
import DynamicTable from '_templates_/DynamicTable'
import AppointmentStatusCaseButton from '_molecules_/AppointmentStatusCaseButton'
import AppointmentsStatusLabel from '_molecules_/AppointmentsStatusLabel'
import { TABLE_FIELDS, COLUMNS_TO_SORT } from './constants'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'AppointmentsList',
  components: {
    AppointmentStatusCaseButton,
    AppointmentsStatusLabel,
    DynamicTable
  },
  data() {
    return {
      currentPage: 1
    }
  },
  computed: {
    ...mapState('Appointments', ['tableRequestState', 'localSortBy', 'selectedPractice']),
    ...mapGetters('Auth', ['isSuperuser', 'currentPracticeId', 'hasPractices']),
    ...mapGetters('Practices', ['sortedPractices', 'getPractices']),
    ...mapGetters('Appointments', ['tableData', 'paginationData']),
    searchProps() {
      return {
        placeholder: 'APPOINTMENTS__LIST__SEARCH_APPOINTMENTS_LABEL',
        maxLengthOnOverlap: 24
      }
    },
    filterProps() {
      const practices = [
        {
          value: '',
          text: i18n('SUBMIT__SUBMITTING_PRACTICE_SELECT_PRACTICE'),
          disabled: true
        },
        ...this.sortedPractices.filter((practice) => practice.value.storeNumber !== null)
      ]

      return [{
        options: practices,
        visible: this.isSuperuser,
        selectedValue: this.selectedPractice,
        onSelect: this.onPracticeSelected,
        disabled: false
      }]
    },
    tableProps() {
      return {
        items: this.tableData,
        fields: TABLE_FIELDS,
        sortBy: this.localSortBy,
        sortDesc: this.sortDesc,
        enableSelection: false
      }
    },
    paginatorProps() {
      return {
        totalItems: this.totalAppointments,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage
      }
    },
    sortDesc() {
      return this.tableRequestState.SortDirection === 'desc'
    },
    totalAppointments() {
      return this.paginationData.totalRecords || 0
    },
    itemsPerPage() {
      return this.tableRequestState.PageSize || 0
    }
  },
  async mounted() {
    await this.retrievePractices()

    let selectedPractice = { storeNumber: null }

    if (!this.isSuperuser && this.hasPractices) {
      selectedPractice = this.getPractices[0]

      if (!selectedPractice.storeNumber) {
        this.$router.push({ name: 'error-appointments', replace: true }).catch(()=>{})
      }
    } else if (this.isSuperuser) {
      selectedPractice = this.selectedPractice
    }

    this.onPracticeSelected(selectedPractice)
  },
  methods: {
    ...mapMutations('Appointments', [
      'setLocalSortBy',
      'setCurrentStoreNumber',
      'setSelectedPractice'
    ]),
    ...mapActions('Appointments', ['requestAppointments']),
    ...mapActions('Practices', ['retrievePractices']),
    i18n,
    onSearch(searchQuery) {
      const payload = {
        params: {
          ...this.tableRequestState,
          PageNumber: 1,
          SearchQuery: searchQuery
        }
      }
      this.requestAppointments(payload)
    },
    onClearSearch() {
      const payload = {
        params: {
          PageNumber: 1,
          ...this.tableRequestState,
          SearchQuery: ''
        }
      }
      this.requestAppointments(payload)
    },
    async onTableSort({ sortBy, sortDesc }) {
      if (sortBy !== '') {
        const payload = {
          params: {
            ...this.tableRequestState,
            SortColumn: COLUMNS_TO_SORT[sortBy],
            SortDirection: sortDesc ? 'desc' : 'asc'
          }
        }

        this.setLocalSortBy(sortBy)
        await this.requestAppointments(payload)
      }
    },
    async onPageChanged(page) {
      const payload = {
        params: {
          ...this.tableRequestState,
          PageNumber: page
        }
      }
      await this.requestAppointments(payload)
      this.currentPage = page
    },
    async onPracticeSelected(selectedPractice) {
      if (this.isSuperuser) this.setSelectedPractice(selectedPractice)
      this.setCurrentStoreNumber(selectedPractice?.storeNumber)
      this.tableRequestState.SearchQuery = ''
      await this.onPageChanged(1)
    }
  }
}
</script>
