<template>
  <div
    id="fast-track-care-experience"
    class="fast-track-care-experience"
  >
    <sdc-title level="4">
      {{ i18n('FAST_TRACK_CARE_EXPERIENCE__TITLE') }}
    </sdc-title>
    <sdc-radio-button
      v-model="dataValue"
      name="fast-track-care-experience-radio"
      class="fast-track-care-experience__radio"
      type="card"
      :options="alignerOptions"
      :has-border="true"
    />
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'
import { CARE_LEVEL } from '_utils_/constants'
import { formatCurrency } from '_utils_/currencyHelpers'
import { mapMutations } from 'vuex'
import { getCarePrice } from '_api_/utils.api'

export default {
  name: 'FastTrackCareExperience',
  components: {
    SdcTitle,
    SdcRadioButton
  },
  props: {
    hidePrices: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataValue: this.$attrs.value,
      alignerOptions: [],
      carePricesValues: {}
    }
  },
  computed: {
    currency() {
      return I18nConfig.currency
    },
  },
  watch: {
    dataValue(newVal) {
      this.setCareLevel(newVal)
      this.$emit('input', newVal)
    }
  },
  mounted() {
    this.loadAlignerOptions()
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setCareLevel']),
    async getFormattedCarePrice() {
      const price = await getCarePrice()
      return {
        standard: {
          total: `${this.currency}${formatCurrency(price.standard.total)}`,
          month: `${this.currency}${formatCurrency(price.standard.month)}`,
        },
        carePlus: {
          total: `${this.currency}${formatCurrency(price.carePlus.total)}`,
          month: `${this.currency}${formatCurrency(price.carePlus.month)}`,
        }
      }
    },
    async loadAlignerOptions() {
      this.carePricesValues = await this.getFormattedCarePrice()
      this.alignerOptions = [
        {
          text: this.formatRadioText({
            title: `<span>${i18n('FAST_TRACK_CARE_EXPERIENCE_STANDARD__TITLE')}</span>`,
            price: `<span>${this.i18n('FAST_TRACK_CARE_EXPERIENCE_STANDARD__PRICE',
              [ this.carePricesValues.standard.total, this.carePricesValues.standard.month ] )}</span>`,
            description: i18n('FAST_TRACK_CARE_EXPERIENCE_STANDARD__DESCRIPTION'),
          }),
          active: true,
          value: CARE_LEVEL.standard
        },
        {
          text: this.formatRadioText({
            title: `<span>${i18n('FAST_TRACK_CARE_EXPERIENCE_CAREPLUS__TITLE')}</span>`,
            price: `<span>${this.i18n('FAST_TRACK_CARE_EXPERIENCE_CAREPLUS__PRICE',
              [ this.carePricesValues.carePlus.total, this.carePricesValues.carePlus.month ] )}</span>`,
            description: i18n('FAST_TRACK_CARE_EXPERIENCE_CAREPLUS__DESCRIPTION'),
          }),
          active: false,
          value: CARE_LEVEL.carePlus
        },
        // For the pilot iteration, do not show “Undecided” as a preference option in the UI
        /*
        {
          text: this.formatRadioText({
            title: `<span>${i18n('FAST_TRACK_CARE_EXPERIENCE_UNDECIDED__TITLE')}</span>`,
            price: `<span></span>`,
            description: i18n('FAST_TRACK_CARE_EXPERIENCE_UNDECIDED__DESCRIPTION'),
          }),
          active: false,
          value: CARE_LEVEL.undecided
        }
         */
      ];
    },
    formatRadioText({ title, price, description }) {
      return `<div class="fast-track-care-experience__radio-option">
        <div class="fast-track-care-experience__radio-option--title">${title}</div>
        <div class="fast-track-care-experience__radio-option--price">${this.hidePrices ? '' : price}</div>
        <div class="fast-track-care-experience__radio-option--description">${description}</div>
      </div>`
    }
  }
}
</script>

<style lang="scss">
@import "_theme_/_variables";
$desktop: map-get($sdc-breakpoints, "desktop");

.fast-track-care-experience {

  &__radio {

    &-option {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      &--title {
        font-size: $font-size-md;
        font-family: $font-family-black;

        order: 1;
        flex: 0 0 70%;
        align-self: flex-start;
      }

      &--price {
        font-size: $font-size-md;

        order: 2;
        flex: 0 0 30%;
        align-self: flex-end;
      }
      &--description {
        font-size: $font-size-sm;

        order: 3;
        flex: 0 1 70%;
        align-self: flex-start;
      }
    }
  }
}

</style>
