import ErrorException from './ErrorException'

class CasesException extends ErrorException {
  constructor({ message, stack }) {
    super('CasesException', message, stack)

    this.throwError()
  }
}

export default CasesException
