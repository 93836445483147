<template>
  <div>
    <SdcTitle level="4">
      {{ i18n('PAYMENT__SMILE_PAY__TITLE') }}
    </SdcTitle>
    <SdcLoading
      v-if="isLoading"
      type="element"
    />
    <SmilePayUI
      v-if="!isLoading"
      :country="countryCode"
      :currency="currencyCode"
      :env="env"
      :financing-price="financingPrice"
      :financing-product-s-k-u="financingProductSku"
      :price="price"
      :product-s-k-u="productSku"
      :region="region"
      :discount="discount"
      show-deferred-payment
    />
  </div>
</template>

<script>
import { SmilePayUI } from '@smiledirectclub/sdc.smilepay.ui'
import { i18n } from '@/utils/i18n';
import I18nConfig from '@/utils/I18nConfig'
import Locale from '@/utils/Location'
import SdcTitle from '@/components/atoms/SdcTitle'
import SdcLoading from '@/components/atoms/SdcLoading'

export default {
  name: 'SdcSmilePay',
  components: {
    SmilePayUI,
    SdcTitle,
    SdcLoading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    financingPrice: {
      type: Number,
      default: 0
    },
    financingProductSku: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: 0
    },
    productSku: {
      type: String,
      default: ''
    },
    region: {
      type: String,
      default: ''
    },
    discount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      countryCode: Locale.countryCode,
      currencyCode: I18nConfig.currencyCode,
      env: process.env.VUE_APP_PAYMENT_ENV
    }
  },
  methods: {
    i18n
  }
}
</script>
