import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

class Logger {
  constructor() {
    if (Logger.instance) {
      return Logger.instance
    }

    const options = {
      clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
      env: process.env.VUE_APP_MODE || process.env.NODE_ENV,
      site: 'datadoghq.com',
      service: 'office-direct',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      trackInteractions: true
    }
    datadogLogs.init(options)
    datadogLogs.logger.setHandler(['http'])
    Logger.instance = this
  }

  info(message, messageContext = {}) {
    datadogLogs.logger.log(message, messageContext, 'info')
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(message, messageContext)
    }
  }

  error(message, errorObj, messageContext = {}) {
    datadogLogs.logger.log(message, messageContext, 'error', errorObj)
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(message, errorObj, messageContext)
    }
  }

  warning(message, messageContext = {}) {
    datadogLogs.logger.log(message, messageContext, 'warn')
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(message, messageContext)
    }
  }

  startRealUserMonitoring() {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DD_RUM_APP_ID,
      clientToken: process.env.VUE_APP_DD_RUM_CLIENT_TOKEN,
      env: process.env.VUE_APP_MODE || process.env.NODE_ENV,
      site: 'datadoghq.com',
      service: 'office-direct',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input'
    })

    datadogRum.startSessionReplayRecording()
  }
}

const instance = new Logger()
export default instance
