<template>
  <div class="create-case-by-appointment-form">
    <sdc-title level="4">
      {{ i18n('CREATE_CASE_BY_APPOINTMENT_FORM__TITLE') }}
    </sdc-title>
    <div class="create-case-by-appointment-form__row">
      <sdc-radio-button
        v-model="emailAppointment"
        name="order-retake-types"
        class="create-case-by-appointment-form__limit-sdc-radio-button"
        :options="todayScheduledAppointmentsSelect"
      />
    </div>
    <div class="create-case-by-appointment-form__footer">
      <sdc-button
        class="create-case-by-appointment-form__footer__button"
        type="primary"
        outlined
        data-test="cancel-button"
        @click="onCancel"
      >
        {{ i18n('CREATE_CASE_BY_APPOINTMENT_FORM__CANCEL_BUTTON') }}
      </sdc-button>
      <sdc-button
        class="create-case-by-appointment-form__footer__button practice-form__footer__button"
        type="primary"
        :disabled="showLoader"
        data-test="continue-button"
        @click="onContinue"
      >
        {{ i18n('CREATE_CASE_BY_APPOINTMENT_FORM__CONTINUE_BUTTON') }}
      </sdc-button>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcButton from '_atoms_/SdcButton'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import SdcRadioButton from '_atoms_/SdcRadioButton/index.vue'
import SdcTitle from '_atoms_/SdcTitle/index.vue'

export default {
  name: 'CreateCaseByAppointmentForm',
  components: {
    SdcTitle,
    SdcRadioButton,
    SdcButton
  },
  data() {
    return {
      todayScheduledAppointmentsSelect: [],
      emailAppointment: null,
      showLoader: false
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('CreateCase', ['personalInfo', 'guardianInfo']),
    ...mapGetters('InformationForm', ['isCaseResumed']),
    ...mapState('Appointments', ['todayScheduledAppointments'])
  },
  mounted() {
    this.prepareAppointments()
  },
  methods: {
    i18n,
    ...mapActions('InformationForm', ['checkExistingEmail','savePatientInformation']),
    ...mapActions('Appointments', ['requestTodayAppointments']),
    ...mapMutations('CreateCase', ['setPersonalInfo']),
    ...mapMutations('InformationForm', ['setPatientInfo','setPatientGuardianInfo']),
    prepareAppointments() {
      this.todayScheduledAppointmentsSelect = this.todayScheduledAppointments
        .sort((itemA, itemB) => itemA.fullName.localeCompare(itemB.fullName))
        .map((itemX, indexX) => ({
          text: this.todayScheduledAppointments
              .find((itemY, indexY) =>
                  itemY.fullName.toUpperCase() === itemX.fullName.toUpperCase()
                  && indexX !== indexY)
            ? `${itemX.fullName} - ${itemX.email}`
            : itemX.fullName,
          value: itemX.email
        }))
      this.todayScheduledAppointmentsSelect.unshift({
        text: this.i18n('CREATE_CASE_BY_APPOINTMENT_FORM__SELECT_APPOINTMENT'),
        value: null
      })
    },
    async onContinue() {
      this.showLoader = true
      if (this.emailAppointment != null) {
        this.setPersonalInfo({data: {...this.personalInfo, emailAddress: this.emailAppointment}})
        this.setPatientGuardianInfo(this.guardianInfo)
        this.setPatientInfo(this.personalInfo)

        await this.checkExistingEmail()

        if (this.isCaseResumed) {
          await this.savePatientInformation()
        }
      }

      await this.$router.push({
        name: 'create-case',
        params: {
          safeToLeaveRoute: true
        }
      })

      this.$emit('onContinue')
      this.showLoader = false
    },
    onCancel() {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.create-case-by-appointment-form {
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  &__limit-sdc-radio-button {
    max-height: 50vh;
    overflow-y: auto;
    width: 100%;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-light;
      font-family: $font-family-bold;
      margin: 8px;
      padding: 15px 30px;
    }
  }
}

</style>
