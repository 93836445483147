import { sdcInstanceProvider as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const createEmailNotification = async (payload) => {
  try {
    const response = await api.post('/emailnotificationsettings', payload)
    return response.data
  } catch (error) {
    Logger.error('EmailNotification API - createEmailNotification', error)
    throw error
  }
}

const deleteEmailNotification = async (payload) => {
  try {
     const response = await api.delete('/emailnotificationsettings', { params: payload })
     return response.data
  } catch (error) {
    Logger.error('EmailNotification API - deleteEmailNotification', error)
    throw error
  }
}

const getEmailNotifications = async (boId, practiceId) => {
  try {
    const response = await api.get(`/emailnotificationsettings/${boId}/${practiceId}`)
    const data = await response.data
    return data
  } catch (error) {
    Logger.error('EmailNotification API - getEmailNotifications', error)
    throw error
  }
}

const updateEmailNotifications = async (boId, practiceId, payload) => {
  try {
    const response = await api.put(`/emailnotificationsettings/${boId}/${practiceId}`, payload)
    return response.data
  } catch (error) {
    Logger.error('EmailNotification API - updateEmailNotifications', error)
    throw error
  }
}

export {createEmailNotification, getEmailNotifications, deleteEmailNotification, updateEmailNotifications}
