<template>
  <div
    name="question-chief-complaint"
    class="question-chief-complaint"
  >
    <sdc-title level="4">
      <LegalTooltip
        name="questionnaire-legal-tooltip"
        text-key="QUESTION_LEGAL__TEXT"
      >
        {{ title }}
      </LegalTooltip>
    </sdc-title>
    <sdc-textarea
      v-model="dataValue"
      name="question-chief-complaint-textarea"
      :placeholder="placeholder"
      :rules="sdcTextareaRules"
    />
  </div>
</template>

<script>
import LegalTooltip from '@/components/molecules/LegalTooltip'
import SdcTitle from '_atoms_/SdcTitle'
import SdcTextarea from '_atoms_/SdcTextarea'

export default {
  name: 'QuestionReasonComplaint',
  components: {
    LegalTooltip,
    SdcTitle,
    SdcTextarea
  },
  props: {
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataValue: this.$attrs.value || ''
    }
  },
  computed: {
    sdcTextareaRules() {
      return 'required'
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
.question-chief-complaint {
  margin-bottom: 40px;
}
</style>
