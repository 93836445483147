import publicIp from 'public-ip'
import { sdcInstanceNoAuth as api } from './sdc.config.api'
import { DEFAULTLOCATION, GET_IP_ALTERNATIVE_SERVICE } from '_utils_/constants'
import Logger from '@/utils/Logger'

const getCurrentLocale = async() => {
  const currentIp = await publicIp.v4({
    fallbackUrls: [
      GET_IP_ALTERNATIVE_SERVICE
    ]
  })
  return getLocaleFromApi(currentIp)
}

const getLocaleFromApi = async(currentIp) => {
  try {
    const response = await api.get(`/geolocations/${currentIp}`)
    return response.data.locale
  } catch (error) {
    Logger.error('Geolocation API - getLocaleFromApi', error)
    return DEFAULTLOCATION
  }
}

export {
  getCurrentLocale
}
