<template>
  <sdc-modal
    ref="no-doctors-modal"
    name="no-doctors-modal"
    class="no-doctors-modal"
    size="extra_large"
    footer-class="border-top-0"
    :can-cancel="false"
    :button-props="buttonProps"
    @onCancel="cancel"
    @onConfirm="verifyTeam"
  >
    {{ i18n('CREATE_CASE__NO_DOCTORS_MODAL__TEXT') }}
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal'

export default {
  name: 'NoDoctorsModal',
  components: {
    SdcModal
  },
  data() {
    return {
      buttonProps: {
        confirmProps: {
          text: 'CREATE_CASE__NO_DOCTORS_MODAL__VERIFY'
        },
        cancelProps: {
          text: 'LABEL__CANCEL'
        }
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['no-doctors-modal']
    },
  },
  methods: {
    i18n,
    verifyTeam(close) {
      close()
      this.$router.push({ name: 'account', params: { safeToLeaveRoute: true }})
    },
    cancel() {
      this.$router.push({ name: 'home', params: { safeToLeaveRoute: true }})
    },
    open() {
      this.modalRef.open()
    }
  },
}
</script>
