var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.isOrdering)?_c('SdcLoading',{attrs:{"title":_vm.i18n('LOADING__CREATE_ORDER_TITLE')}}):_vm._e(),_c('section',{class:[
      'is-flex',
      'is-justify-content-space-between'
    ]},[_c('div',{staticClass:"is-flex is-align-items-baseline"},[_c('LegalTooltip',{attrs:{"name":"payment-info","text-key":"PAYMENT__LEGAL__TEXT"}},[_c('SdcTitle',{attrs:{"level":"2"}},[_vm._v(" "+_vm._s(_vm.i18n('PAYMENT__TITLE'))+" ")])],1)],1),_c('SdcButton',{attrs:{"type":"link","data-test":"cancel-button"},on:{"click":_vm.onSkipPayment}},[_vm._v(" "+_vm._s(_vm.i18n('LABEL__CANCEL'))+" ")])],1),_c('section',{staticClass:"section-panel-rounded"},[_c('div',{staticClass:"section-panel-rounded-content"},[(_vm.showCareExperience)?_c('FastTrackCareExperience',{attrs:{"hide-prices":""},on:{"input":_vm.updateExperienceOptions},model:{value:(_vm.careLevelSelection),callback:function ($$v) {_vm.careLevelSelection=$$v},expression:"careLevelSelection"}}):_vm._e(),(_vm.showSmilePayUI)?_c('SdcSmilePay',{attrs:{"is-loading":_vm.isLoadingFinancingOptions,"financing-price":_vm.financePrice,"financing-product-sku":_vm.financingProductSKU,"price":_vm.price,"product-sku":_vm.productSKU,"region":_vm.region,"discount":_vm.discountAmount}}):_vm._e(),(_vm.isAnySmilePayPlanSelected)?_c('FastTrackDiscountCode',{ref:"discountCodeComponent",attrs:{"show-communication-message":_vm.isSmilePayPaymentPlanSelected},on:{"onChange":_vm.onDiscountCodeApplied,"remove":_vm.onRemoveDiscountCode}}):_vm._e(),(_vm.showWalletUI)?_c('SdcWallet',{attrs:{"payment-broker-account-id":_vm.paymentBrokerAccountId}}):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.paymentRequired)?_c('FastTrackBillingAddress',{attrs:{"model":_vm.billingAddress},on:{"input":_vm.onBillingAddressChange}}):_vm._e(),(_vm.paymentRequired)?_c('PatientConsent',{ref:"patientConsentComponent",attrs:{"value":_vm.patientConsent,"disable-get-smile-pay-terms":_vm.disableGetSmilePayTerms,"application-id":_vm.applicationId,"title":_vm.i18n('PATIENT_CONSENT__TITLE'),"show-esign-consent":_vm.showEsignConsent},on:{"input":_vm.onPatientConsentChange}}):_vm._e(),_c('div',{class:[
            'is-flex',
            'is-align-items-center',
            'is-justify-content-end'
          ]},[(_vm.paymentRequired)?_c('SdcButton',{attrs:{"type":"primary","disabled":invalid || !_vm.canOrder,"data-test":"order-button"},on:{"click":_vm.beginOrder}},[_vm._v(" "+_vm._s(_vm.i18n('PAYMENT__ORDER_BUTTON'))+" ")]):_c('SdcButton',{staticClass:"mt-5",attrs:{"type":"primary","disabled":!_vm.canContinue,"data-test":"continue-button"},on:{"click":_vm.onSkipPayment}},[_vm._v(" "+_vm._s(_vm.i18n('PAYMENT__CONTINUE_BUTTON'))+" ")])],1)]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }