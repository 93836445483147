<template>
  <validated-input
    ref="validatedEmailInputProvider"
    v-model="dataValue"
    :name="name"
    type="email"
    :rules="rules"
    :label="label"
    :max-length="maxLength"
    :disabled="disabled"
    autocomplete="off"
    @blur="onBlur"
    @input="resetEmailValidation"
  />
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ValidatedInput from '_atoms_/ValidatedInput'

export default {
  name: 'ValidatedEmailInput',
  components: {
    ValidatedInput
  },
  props: {
    name: { type: String, required: true },
    rules: { type: String, default: '' },
    label: { type: String, required: true },
    maxLength: {type: Number, default: 0},
    disabled: {type: Boolean, required:true},
    onBlurEmailCheck: { type: Function, default: () => {} },
    value: { type: String, required: false, default: '' }
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    ...mapMutations('InformationForm', ['setExistingEmailStatus','setInvalidEmailStatus', 'clearErrorCodeEmailValidation']),
    ...mapActions('InformationForm', ['clearFinanceAgreementId']),
    async onBlur(emailField) {
      if (emailField.flags.valid && !this.disabled) {
        this.clearFinanceAgreementId()
        // setTimeout implemented to avoid conflicts with other events
        setTimeout(() => {
          this.onBlurEmailCheck()
        }, 500)
      }
    },
    resetEmailValidation() {
      this.setExistingEmailStatus(false)
      this.setInvalidEmailStatus(false)
      this.clearErrorCodeEmailValidation()
    }
  }
}
</script>
