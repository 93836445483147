<template>
  <question
    disable-medical-patient-consent
    :model="model"
    :reason-complaint="reasonComplaint"
  />
</template>

<script>
import { mapState } from 'vuex'
import Question from '_organisms_/Question'
import { i18n } from '_utils_/i18n'

export default {
  name: 'CreateCaseQuestion',
  components: {
      Question
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('CreateCase', ['questionInfo']),
    reasonComplaint() {
      return { title:  i18n('QUESTION_CHIEF_COMPLAINT__TITLE'), placeholder: i18n('QUESTION_CHIEF_COMPLAINT__TEXTAREA_PLACEHOLDER')  }
    }
  },
  methods: {
    i18n
  }
}
</script>
