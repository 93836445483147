<template>
  <section class="sdc-card">
    <slot name="titleSection" />
    <slot name="descriptionSection" />
    <slot name="contentSection" />
  </section>
</template>

<script>

export default {
  name: 'Card'
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, "tablet");

.sdc-card {
  box-shadow: none;

  @media screen and (min-width: $tablet) {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.04);
    padding: 60px;
  }

  margin-top: 40px;
  border: none;

  &__title {
    font-size: 2.25rem;
    font-weight: 900;
    line-height: 41px;
    letter-spacing: -0.5px;
    margin-bottom: 12px;
  }

  &__description {
    color: $gray-600;
    margin-bottom: 20px;
  }
}
</style>
