function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

async function sleep(fn, interval, ...args) {
    await timeout(interval)
    return fn(...args)
}

export {
  sleep
}