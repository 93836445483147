import ErrorException from './ErrorException'
import ToastException from './ToastException'
import { i18n } from '_utils_/i18n'

class CaseDetailsException extends ErrorException{
  constructor({ message, stack }, isToastFired = true) {
    super('CaseDetailsException', message, stack)

    this.isToastFired = isToastFired

    this.throwError()
  }

  throwError() {
    super.throwError()

    if (this.isToastFired) {
      throw new ToastException(i18n('CASE_DETAILS__TITLE'))
    }
  }
}

export default CaseDetailsException
