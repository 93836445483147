<template>
  <sdc-modal
    id="remove-team-member-modal"
    ref="removeTeamMemberModal"
    :title-props="titleProps"
    name="removeTeamMemberModal"
    hide-footer
  >
    <remove-team-member-form
      :close-modal="close"
      :user-name="userName"
      :practices="practices"
    />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { mapActions, mapState, mapGetters } from 'vuex'
import SdcModal from '_molecules_/SdcModal'
import RemoveTeamMemberForm from '_molecules_/RemoveTeamMemberForm'

export default {
  name: 'RemoveTeamMemberModal',
  components: {
    SdcModal,
    RemoveTeamMemberForm
  },
  props:{
    userName: { type: String, default: ''},
    practices: { type: Array, required: true }
  },
  data() {
    return {
      titleProps: {
        default: null
      }
    }
  },
  computed: {
    ...mapGetters('Cases', ['getCaseNumbers']),
    ...mapState('Cases', ['tableRequestState']),
    doctorPractice() {
      return this.practices[0]
    },
    modalRef() {
      return this.$refs['removeTeamMemberModal']
    }
  },
  methods: {
    i18n,
    ...mapActions('Doctors', ['loadByPractice']),
    ...mapActions('Cases', ['requestCases']),
    ...mapActions('Practices', ['requestPractices']),
    async loadCases() {
      const payload = {
        params: {
          ...this.tableRequestState,
          PageNumber: 1,
          SubmittingDoctorUserName: this.userName
        }
      }
      await this.requestCases(payload)
    },
    async loadDoctors() {
      if (this.getCaseNumbers.length) {
        await this.requestPractices()
        await this.loadByPractice({
          boId: this.doctorPractice?.boId,
          practiceId: this.doctorPractice?.id,
          forceLoad: true
        })
      }
    },
    close() {
      this.modalRef.close()
    },
    async open() {
      await this.loadCases()
      await this.loadDoctors()
      this.modalRef.open()
    },
    setModalTittle() {
      return i18n('OFFICE_TEAM__MODAL_TITLE_REMOVE')
    }
  }
}
</script>

<style lang="scss">
@import "_theme_/_variables.scss";
$mobile: map-get($sdc-breakpoints, "mobile");

#remove-team-member-modal {
  .modal-content {
    @media screen and (max-width: $mobile) {
      padding: 0px !important;
    }
  }
  .modal-xl {
    @media screen and (max-width: $mobile) {
      max-width: 100%;
    }
  }
}

</style>
