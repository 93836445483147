<template>
  <section
    :id="id"
    class="treatment-plan"
  >
    <SmileMediaCard>
      <template #card-content>
        <SdcCollapse
          :open="open"
          @open="$emit('open')"
        >
          <template #trigger>
            <div class="is-flex">
              <div class="information-column">
                <div
                  :id="`${id}-treatment-type`"
                  class="mb-4"
                >
                  <label>{{ i18n('TREATMENT_PLAN__TYPE_LABEL') }}</label>
                  <span>{{ treatmentType }}</span>
                </div>
                <div
                  :id="`${id}-state`"
                  class="mb-4"
                >
                  <label>{{ i18n('TREATMENT_PLAN__STATUS_LABEL') }}</label>
                  <span>{{ state }}</span>
                </div>
                <div
                  :id="`${id}-date-created`"
                  class="mb-4"
                >
                  <label>{{ i18n('TREATMENT_PLAN__DATE_CREATED_LABEL') }}</label>
                  <span>{{ formattedDateCreated }}</span>
                </div>
              </div>
              <div class="information-column">
                <div
                  :id="`${id}-product`"
                  class="mb-4"
                >
                  <label>{{ i18n('TREATMENT_PLAN__PRODUCT_LABEL') }}</label>
                  <span>{{ product }}</span>
                </div>
                <div
                  :id="`${id}-provider`"
                  class="mb-4"
                >
                  <label>{{ i18n('TREATMENT_PLAN__PROVIDER_LABEL') }}</label>
                  <span>{{ provider }}</span>
                </div>
              </div>
              <div
                :class="[
                  'information-column',
                  'is-justify-content-flex-end',
                  'mb-4'
                ]"
              >
                <a
                  :id="`${id}-plan-viewer-link`"
                  :href="viewerLink"
                  rel="noreferrer noopener"
                  target="_blank"
                  @click.stop
                >
                  <span>{{ i18n('TREATMENT_PLAN__PLAN_VIEWER_LABEL') }}</span>
                </a>
              </div>
            </div>
          </template>
          <SdcTitle
            class="mb-2"
            level="5"
          >
            {{ i18n('TREATMENT_PLAN__PRESCRIPTIONS_TITLE') }}
          </SdcTitle>
          <SmileMediaCard
            v-for="(p, index) in sortedPrescriptions"
            :key="index"
          >
            <template #card-content>
              <Prescription
                :id="`${id}-prescription-${index}`"
                v-bind="p"
              />
            </template>
          </SmileMediaCard>
        </SdcCollapse>
      </template>
    </SmileMediaCard>
  </section>
</template>
<script>
import { i18n } from '@/utils/i18n'
import { convertUtcToBrowserTime } from '@/utils/dateHelpers'
import SdcCollapse from '@/components/atoms/SdcCollapse'
import SdcTitle from '@/components/atoms/SdcTitle'
import Prescription from './Prescription.vue'

export default {
  name: 'TreatmentPlan',
  components: {
    SdcCollapse,
    SdcTitle,
    Prescription
  },
  props: {
    id: {
      type: String,
      required: true
    },
    dateCreated: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    prescriptions: {
      type: Array,
      required: true
    },
    state: {
      type: String,
      required: true
    },
    treatmentType: {
      type: String,
      required: true
    },
    viewerLink: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDateCreated() {
      return convertUtcToBrowserTime(this.dateCreated)
    },
    product() {
      return [
        ...new Set(
          this.prescriptions.map(p => p.productDescription)
        )
      ].join(', ')
    },
    provider() {
      if (!this.sortedPrescriptions.length) {
        return ''
      }

      return this.sortedPrescriptions[0].providerName
    },
    sortedPrescriptions() {
      return [...this.prescriptions]
        .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        .reverse()
    }
  },
  methods: {
    i18n
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

label {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $gray-600;
  margin-bottom: 2px;
  display: block;
}

.collapse-content {
  .smile-media-card:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.information-column {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
</style>