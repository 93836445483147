<template>
  <section
    id="sdc-table"
    class="sdc-table"
  >
    <b-table
      :data="items"
      :loading="loading"
      :hoverable="enableSelection"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :row-class="() => enableSelection && 'selectable-row'"
      icon-pack="fe"
      backend-sorting
      @select="onRowSelected"
      @sort="onSort"
    >
      <template #empty>
        <div v-if="!loading">
          {{ i18n('TEXT__NO_RESULTS_FOUND') }}
        </div>
      </template>

      <b-table-column
        v-for="c in columns"
        v-slot="props"
        :key="c.field"
        :field="c.field"
        :label="c.label"
        :sortable="c.sortable"
        header-class="table-header"
        cell-class="table-td"
      >
        <template
          v-if="scopedSlots[c.field]"
        >
          <slot
            v-bind="{ item: { ...props.row } }"
            :name="c.field"
          />
        </template>

        <template v-else>
          {{ props.row[c.field] }}
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { i18n } from '_utils_/i18n'

export default {
  name: 'SdcTable',
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
    },
    sortBy: {
      type: String,
      required: true
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    enableSelection: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sortField: this.sortBy,
      sortOrder: this.sortDesc ? 'desc' : 'asc',
      defaultSortOrder: 'desc',
      selectedRow: null
    }
  },
  computed: {
    scopedSlots() {
      return this.$scopedSlots
    },
    columns() {
      return this.fields.map(({ key, label, sortable }) => ({
        sortable,
        field: key,
        label: i18n(label)
      }))
    }
  },
  methods: {
    i18n,
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.$emit('onSort', { sortBy: field, sortDesc: order === 'desc' })
    },
    onRowSelected(selectedRow) {
      if (this.enableSelection) {
        this.$emit('onRowSelected', selectedRow)
      }
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

  .sdc-table {
    border-bottom: 2px solid $light-gray;
    margin-bottom: 1rem;
    table {
      table-layout: fixed;

      tr {
        &.selectable-row {
          cursor: pointer;
          color: $black;
        }
      }
    }

    .table-header {
      font-size: $font-size-sm !important;
      color: $gray-600;
      font-weight: $font-weight-normal;
    }
    .table-td {
      padding: 0.85rem 0;
      font-size: 1rem;
    }
  }
</style>
