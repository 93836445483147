import { sdcInstanceProvider as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getBusinessOrganizations = async params => {
  try {
    const response = await api.get('/business-organizations', { params })
    return response.data
  } catch (error) {
    Logger.error('BusinessOrganizations API getBusinessOrganizations ', error)
    throw error
  }
}

/**
 * @param {Object} params - full case data map
 * @returns {Object} response
 */
const postBusinessOrganization = async params => {
  try {
    const response = await api.post('/business-organizations', params)
    return response.data
  } catch (error) {
    Logger.error('BusinessOrganizations API postBusinessOrganization ', error)
    throw error
  }
}

export {
  getBusinessOrganizations,
  postBusinessOrganization
}
