<template>
  <validation-observer ref="practiceForm">
    <div ref="errorMessage">
      <sdc-message
        v-if="showAddressWarningMessage"
        type="warning"
        :icon-path="infoIconPath"
        :text-message="i18n('PRACTICES__ADD_PRACTICE_ERROR_ADDRESS')"
      >
        <sdc-button
          type="warning"
          @click="setAcceptInvalidAddress"
        >
          {{ i18n('PRACTICE_MODAL__ALERT_BUTTON') }}
        </sdc-button>
      </sdc-message>
    </div>
    <form>
      <validated-select-input
        v-model="selectedBoId"
        name="boId"
        rules=""
        :options="businessOrganizationOptions"
        :disabled="isEditDSODisabled"
      />
      <div ref="practiceNameEl">
        <validated-input
          v-model="practice.name"
          name="name"
          type="text"
          :max-length="maxLengths.practiceName"
          :rules="customRules.name"
          :label="i18n('PRACTICE_FORM__PRACTICE_NAME')"
        />
      </div>

      <div ref="billingEntityEl">
        <validated-input
          v-if="shouldShowBillingEntity"
          v-model="practice.billingEntity"
          name="billingEntity"
          type="text"
          :max-length="maxLengths.billingEntity"
          :rules="customRules.billingEntity"
          :label="i18n('PRACTICE_FORM__BILLING_ENTITY')"
        />
      </div>
      <div class="practice-form__address">
        <div class="practice-form__address__control">
          <validated-input
            v-model="practice.streetAddress1"
            name="streetAddress1"
            type="text"
            :max-length="maxLengths.streetAddress1"
            :rules="customRules.street1"
            :label="i18n('PRACTICE_FORM__STREET')"
          />
        </div>
        <div class="practice-form__address__control">
          <validated-input
            v-model="practice.streetAddress2"
            name="streetAddress2"
            type="text"
            :max-length="maxLengths.streetAddress2"
            rules=""
            :label="i18n('PRACTICE_FORM__STREET_OPTIONAL')"
          />
        </div>
        <div
          class="practice-form__address__control"
          :class="getAddressControl()"
        >
          <validated-input
            v-model="practice.city"
            name="city"
            type="text"
            :max-length="maxLengths.city"
            :rules="customRules.city"
            :label="i18n('PRACTICE_FORM__CITY')"
          />
        </div>
        <div
          v-if="hasStates"
          class="practice-form__address__control"
        >
          <label class="label">{{ i18n('CREATE_CASE__SHIPPING_LABEL_STATE') }}</label>
          <validated-select-input
            v-model="practice.state"
            name="state"
            rules="required"
            :options="states"
          />
        </div>
        <div class="practice-form__address__control">
          <validated-input
            v-model="practice.zipCode"
            name="zipCode"
            type="text"
            :max-length="maxLengths.zipcode"
            :rules="customRules.zipCode"
            :label="i18n('PRACTICE_FORM__ZIPCODE')"
          />
        </div>
        <div class="practice-form__address__control">
          <validated-input
            :value="countryName"
            :disabled="isEditCountryDisabled"
            name="countryName"
            type="text"
            rules=""
            :label="i18n('PRACTICE_FORM__COUNTRY')"
          />
        </div>
        <div
          class="
            practice-form__address__control
            practice-form__address__control--phone
          "
        >
          <label class="label">{{ i18n('CREATE_CASE__PERSONAL_INFO_PHONE_NUMBER') }}</label>
          <validated-phone-input
            v-model="practice.phoneNumber"
            input-id="practice-phone"
            class="form-group practice-form__address__control--phone"
          />
        </div>
        <div class="practice-form__address__control">
          <validated-input
            v-model="practice.webPage"
            name="webpage"
            type="text"
            :max-length="maxLengths.webPage"
            rules=""
            :label="i18n('PRACTICE_FORM__WEBSITE')"
          />
        </div>
        <div
          v-if="practiceId"
          class="practice-form__row"
        >
          <div class="practice-form__address__control">
            <validated-input
              v-model="practice.storeNumber"
              name="storeNumber"
              type="text"
              :rules="customStoreNumberRules"
              :max-length="maxLengths.storeNumber"
              :label="i18n('PRACTICE_FORM__STORENUMBER')"
            />
          </div>
          <div class="practice-form__selector__type__icons">
            <sdc-tooltip
              name="tooltip-refinement"
              :title="i18n('PRACTICES__EDIT_PRACTICE_TOOLTIP_STORE_NUMBER_TITTLE')"
              :text="i18n('PRACTICES__EDIT_PRACTICE_TOOLTIP_STORE_NUMBER_MESSAGE')"
            />
          </div>
        </div>
        <div
          v-if="shouldShowTermsAndConditions"
          class="practice-form__address__control"
        >
          <sdc-checkbox
            v-model="practice.contractSignRequested"
            name="request-terms-conditions"
            :disabled="disableTermsAndConditions"
          >
            {{ setTermsAndConditionsMsg() }}
          </sdc-checkbox>
        </div>
        <div
          class="practice-form__address__control"
        >
          <sdc-checkbox
            v-model="practice.carePlusEnabled"
            name="carePlusEnabled"
            :disabled="disableCarePlus"
          >
            {{ i18n('PRACTICE_FORM__CARE_PLUS_PRACTICE_LABEL') }}
          </sdc-checkbox>
        </div>
      </div>
      <div class="practice-form__footer">
        <sdc-button
          class="practice-form__footer__button"
          type="primary"
          outlined
          @click="onCancel"
        >
          {{ i18n('PRACTICE_MODAL__CANCEL_BUTTON') }}
        </sdc-button>

        <sdc-button
          class="practice-form__footer__button"
          type="primary"
          :disabled="loading"
          @click="onSubmit"
        >
          {{ setSubmitButtonText() }}
        </sdc-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcMessage from '_atoms_/SdcMessage'
import ValidatedInput from '_atoms_/ValidatedInput'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import ValidatedPhoneInput from '_molecules_/ValidatedPhoneInput'
import { inputMaxLengths } from '_utils_/constants'
import Location from '_utils_/Location'
import { zipCodeChecking, validAddressChecking } from '_utils_/addressHelper'
import { errorTypes } from '_store_/modules/practices.store'
import SdcTooltip from '_atoms_/SdcTooltip'
import { TERMS_CONTRACT_SIGN_STATUS } from '_utils_/constants'
import { localeConstants } from '@/utils/constants'

export default {
  name: 'PracticeForm',
  components: {
    SdcButton,
    SdcCheckbox,
    SdcMessage,
    ValidatedInput,
    ValidatedSelectInput,
    ValidatedPhoneInput,
    ValidationObserver,
    SdcTooltip
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    practiceId: { type: String, default: '' }
  },
  data() {
    return {
      states: [],
      acceptInvalidAddress: false,
      showAddressWarningMessage: false,
      maxLengths: inputMaxLengths.practiceInfo,
      practice: {
        boId: '',
        name: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        webPage: '',
        contractSignRequested: false,
        contractSignStatus:'',
        billingEntity:'',
        carePlusEnabled:false
      },
      customRules: {
        name: 'required',
        street1: 'required',
        city: 'required',
        state: 'required',
        zipCode: 'required',
        billingEntity: 'required'
      }
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('Auth', ['authUser']),
    ...mapGetters('Auth', ['boId', 'boName', 'isSuperuser']),
    ...mapGetters('BusinessOrganizations', [
      'getDSOBusinessOrganizations',
      'areBusinessOrganizationsLoaded'
    ]),
    ...mapGetters('Practices', ['getById']),
    infoIconPath() {
      return require('_assets_/icon-info.svg')
    },
    isEditDSODisabled() {
      return this.isEdit()
    },
    isEditCountryDisabled() {
      // For the time being, this is always readonly
      return true
    },
    businessOrganizationOptions() {
      if (this.isSuperuser) {
        // If we are allowed to select a BO, get the entire list of BOs to choose from
        const options = this.getDSOBusinessOrganizations
          .map((item) => ({
            value: item.id.toLowerCase(),
            text: item.name || '',
          }))
          .sort((boA, boB) =>
            boA.text.localeCompare(
              boB.text,
              ...Location.localeCompareConfiguration
            )
          )
        options.unshift({
          value: '',
          text: i18n('PRACTICE_FORM__DSO_OPTIONAL')
        })
        return options
      } else {
        // If we are not, simply return the boId and boName from our account
        return [
          {
            value: this.boId,
            text: this.boName || i18n('PRACTICE_FORM__DSO_OPTIONAL')
          },
          { value: '', text: i18n('PRACTICE_FORM__DSO_OPTIONAL') }
        ]
      }
    },
    hasStates() {
      return this.states.length > 0
    },
    countryName() {
      return i18n(`LABEL__COUNTRY_${this.countryCode}`)
    },
    countryCode() {
      return Location.countryCode
    },
    countries() {
      return Location.countries
    },
    isDefaultCountry() {
      return this.countryCode === localeConstants.defaultCountryCode
    },
    shouldShowBillingEntity() {
      return this.isSuperuser && this.isDefaultCountry
    },
    languageCode() {
      return Location.languageCode
    },
    shouldShowTermsAndConditions() {
      return (
        I18nConfig.showTermsAndConditions && this.isSuperuser
      )
    },
    selectedBoId: {
      get() {
        return this.practice.boName ? this.practice.boId : ''
      },
      set(boId) {
        this.practice.boId = boId
        return boId
      }
    },
    disableTermsAndConditions() {
      const isEditingPractice = this.isEdit()
      const isCreatingDsoPractice = (!isEditingPractice && this.practice.boId.length > 0)
      const isDsoPractice = !this.practice.isIndividual
      const isNonDsoPracticeWithContractSigned = this.practice.isIndividual &&
                                                 this.practice.contractSignStatus === TERMS_CONTRACT_SIGN_STATUS.signed

      return isCreatingDsoPractice || (isEditingPractice && (isDsoPractice || isNonDsoPracticeWithContractSigned))
    },
    disableCarePlus(){
        return !this.isSuperuser;
    },
    isValidStoreNumber() {
      return /^S(\d{4,5})$/g.test(this.practice.storeNumber)
    },
    customStoreNumberRules() {
     return !this.isValidStoreNumber ? 'isInvalidStoreNumber' : ''
    }
  },
  beforeMount() {
    if (this.isEdit()) {
      this.practice = JSON.parse(JSON.stringify(this.getById(this.practiceId)))
    }
  },
  mounted() {
    if (this.isSuperuser) {
      // Only fetch BO list if we are allowed to select a BO
      this.requestBusinessOrganizations()
    } else {
      // Otherwise, default the form to use the user's associated BoId
      this.practice.boId = this.boId
    }
    this.states = this.getStates()
  },
  methods: {
    i18n,
    ...mapActions('BusinessOrganizations', ['requestBusinessOrganizations']),
    ...mapActions('Practices', ['createPractice', 'updatePractice']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    getRoleLabel(roleName) {
      return i18n(`ROLE_${roleName}__LABEL`) || roleName
    },
    async onSubmit() {
      const form = this.$refs.practiceForm
      const success = await form.validate()
      if (!this.validateNameAndBillingEntity()) return
      if (!success) return
      const addressValid = await this.validateAddress()
      if (!addressValid) return
      if (!this.shouldShowBillingEntity) this.practice.billingEntity = ''
      const practiceIdToUpdate = this.practiceId
      const payload = {
        ...this.practice,
        countryCode: this.countryCode,
      }
      const practiceResult = !this.isEdit()
        ? await this.createPractice(payload)
        : await this.updatePractice({ payload, practiceIdToUpdate })

      this.toast({
        msg: this.getPracticeResultMessage(practiceResult),
        title: i18n('PRACTICES__TITLE')
      })
      if (!practiceResult.error) {
        this.onCancel()
      }
    },
    getPracticeResultMessage(practiceResult) {
      if (practiceResult.error) {
        return this.getErrorMessage(practiceResult.error.type)
      }
      return this.isEdit()
        ? i18n('PRACTICES__EDIT_PRACTICE_SUCCESS')
        : i18n('PRACTICES__ADD_PRACTICE_SUCCESS')
    },
    getErrorMessage(errorType) {
      if (errorType === errorTypes.addPracticeDuplicateError) {
        return i18n('PRACTICES__ADD_PRACTICE_ERROR_DUPLICATE')
      }
      if (errorType === errorTypes.addInactivePracticeError) {
        return i18n('PRACTICES__ADD_PRACTICE_ERROR_INACTIVE')
      }
      if (errorType === errorTypes.addPracticeStoreNumber) {
        return i18n('PRACTICES__ADD_STORE_NUMBER_DUPLICATE')
      }

      const errorMsg = this.isEdit() ? 'EDIT' : 'ADD'

      return i18n(`PRACTICES__${errorMsg}_PRACTICE_ERROR_DEFAULT`, [
        I18nConfig.supportPhoneNumber
      ])
    },
    onCancel() {
      this.closeModal()
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    getStates() {
      if (!I18nConfig.hasRegions) {
        this.practice.state = I18nConfig.defaultRegion
        return []
      }
      const regions = I18nConfig.regions.map((item) => ({
        value: item.shortCode,
        text: item.localizedName?.[this.languageCode] || item.name
      }))
      regions.unshift({ value: '', text: i18n('PRACTICE_FORM__STATE') })
      return regions
    },
    async validateAddress() {
      const form = this.$refs.practiceForm
      const { streetAddress1, streetAddress2, city, state, zipCode } =
        this.practice
      const payload = {
        streetAddress1,
        streetAddress2,
        city,
        state,
        zipCode,
        countryCode: Location.countryCode
      }
      this.setLoadingStatus(true)
      const isValidZipcode = await zipCodeChecking(payload)
      const isValidAddress = await validAddressChecking(payload)
      this.setLoadingStatus(false)
      if (isValidZipcode.error) {
        form.setErrors({
          // For Zipcode errors the API sends back examples of proper zipCodes
          zipCode: isValidZipcode.error
        })
        return
      }
      if (!isValidAddress && !this.acceptInvalidAddress) {
        this.showAddressWarningMessage = true
        this.$refs.errorMessage.scrollIntoView({ behavior: 'smooth' })
        return
      }
      return true
    },
    setAcceptInvalidAddress() {
      this.acceptInvalidAddress = true
      this.showAddressWarningMessage = false
    },
    isEdit() {
      return !!this.practiceId
    },
    setSubmitButtonText() {
      return this.isEdit()
        ? i18n('PRACTICE_MODAL__SUBMIT__EDIT__BUTTON')
        : i18n('PRACTICE_MODAL__SUBMIT_BUTTON')
    },
    getAddressControl() {
      return {
        'practice-form__address__control': true,
        'practice-form__address__control--full': !this.hasStates
      }
    },
    setTermsAndConditionsMsg(){
      return this.practiceId ?
      i18n('PRACTICE_FORM__REQUEST_TERMS_CONDITIONS_LABEL') :
      i18n('PRACTICE_FORM__REQUEST_TERMS_CONDITIONS_NEW_PRACTICE_LABEL')
    },
    validateNameAndBillingEntity(){
      if(!this.practice.name) {
        this.$refs.practiceNameEl.scrollIntoView({ behavior: 'smooth' })
        return false
      }
      if(this.shouldShowBillingEntity && !this.practice.billingEntity) {
        this.$refs.billingEntityEl.scrollIntoView({ behavior: 'smooth' })
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.practice-form {
  &__row {
    display: flex;
    justify-content: inherit;
    width: 100%;
  }
  &__address,
  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &__address {
    justify-content: space-between;
    &__control {
      width: 100%;
      &--phone {
        display: block;
      }
      @media screen and (min-width: $mobile) {
        width: 49%;
        &--full {
          width: 100%;
        }
      }
    }
  }
  &__selector__type__icons {
    width: 49%;
    display: flex;
    align-items: center;
    height: 56px !important;
  }
  &__footer {
    // TODO: Refactor all modal buttons to use a shared style
    justify-content: flex-end;
    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }
    &__button {
      font-weight: $font-weight-normal;
      font-family: "Sofia Pro Bold";
      margin: 8px;
      padding: 15px 30px;
    }
  }
}
</style>
