import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

/**
 *
 * @param {Object} shipmentData - Shipment Data map.
 * @returns {Object}
 */
const createShipment = async shipmentData => {
  try {
    const response = await api.post('/shippinglabel', shipmentData)
    return response.data
  } catch (error) {
    Logger.error('Shipment API - createShipment', error)
    throw error
  }
}

/**
 * @param {String} caseId - Case uuid
 * @returns {Object}
 */
const getShipmentInfo = async caseId => {
  try {
    const response = await api.get(`/shippinglabel/${caseId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

const getAvailableHours = async () => {
  try {
    const response = await api.get('/pickup/available-hours')
    return response.data
  } catch (error) {
    Logger.error('Shipment API - getAvailableHours', error)
    throw error
  }
}

/**
 * @param {Object} pickup - Pickup data object.
 * @returns {Object}
 */
 const createPickup = async (pickup) => {
  try {
    const response = await api.post('/pickup', pickup)
    return response.data
  } catch (error) {
    Logger.error('Shipment API - createPickup', error)
    throw error
  }
}

/**
 * @async
 * @param {Object} payload
 * @param {string} payload.practiceId
 * @param {string} payload.practiceName
 * @param {string} payload.caseId
 * @param {string} payload.email
 * @param {string} payload.RecipientRole
 * @param {string} payload.RecipientLast
 * @returns {Promise<Object>}
 */
const sendShipmentEmailLabel = async payload => {
  try {
    const response = await api.post('/shippinglabel/email', payload)
    return response.data
  } catch (error) {
    Logger.error('Shipment API - sendShipmentEmailLabel', error)
    throw error
  }
}

/**
 *
 * @param {String} pickupId - Pickup uuid
 * @returns {Boolean}
 */
const cancelPickup = async (pickupId) => {
  try {
    await api.post(`/pickup/${pickupId}/cancel`)
    return true
  } catch (error) {
    Logger.error('Shipment API - cancelPickup', error)
    return false
  }
}

export { createShipment, getShipmentInfo, getAvailableHours, createPickup, sendShipmentEmailLabel, cancelPickup  }
