/* eslint-disable no-console */
import { HttpException } from '_utils_/Exceptions'
import { sdcInstance as api } from './sdc.config.api'
import { sdcInstanceWithLongRetries as apiWithLongRetries } from './sdc.config.api'
import Logger from '@/utils/Logger'

export const PAYMENT_HEADERS = {
  XStripeToken: 'X-Stripe-Token',
  XFastTrack: 'X-Fast-Track',
}

export const FAST_TRACK_HEADER_VALUES = {
  singlePay: 'single_pay',
  smilePay: 'smile_pay',
  smileNow: 'smile_now',
  notFastTrack: 'not_fast_track'
}

/**
 * Get the cases list from the API
 *
 * @param {Object} params
 * @prop {Number} PageNumber
 * @prop {Number} PageSize
 * @prop {String} SearchQuery
 * @prop {String} SortColumn
 * @prop {String} SortDirection
 *
 * @returns {Object} {cases, metadata}
 * @prop {Array} cases
 * @prop {Object} metadata
 */
const getCases = async params => {
  try {
    const response = await api.get('/cases', { params })
    const { cases, metadata } = response.data
    return { cases, metadata }
  } catch (error) {
    Logger.error('Case API - getCases', error)
    throw error
  }
}
/**
 * Get a single case by ID
 *
 * @param {String} id
 * @returns {Case} payload
 * @throws {Error} When request fails
 */
const getCase = async id => {
  try {
    const response = await apiWithLongRetries.get(`/case/${id}`)
    const {
      patient,
      caseNumber,
      status,
      source,
      order,
      impressionMode,
      reasonCode
    } = response.data
    const { firstName, lastName, dateOfBirth } = patient

    return {
      patientId: patient.id,
      firstName,
      lastName,
      dateOfBirth,
      caseNumber,
      status,
      source,
      order,
      impressionMode,
      reasonCode
    }
  } catch (error) {
    throw new HttpException(error)
  }
}

/**
 * Get a patient's guardian info by patient ID
 *
 * @param {String} patientId
 * @returns {Object} payload
 * @prop {String} firstName
 * @prop {String} lastName
 * @prop {String} dateOfBirth - ISO standar date string
 */
const getGuardianInfo = async patientId => {
  try {
    const response = await api.get(`/patient/${patientId}`)
    const { guardian } = response.data

    return (guardian) ? {
      firstName: guardian.firstName,
      lastName: guardian.lastName,
      dateOfBirth: guardian.dateOfBirth
    } : null
  } catch (error) {
    throw new HttpException(error)
  }
}

/**
 * Edit a single case by ID
 *
 * @param {Object} editData - id, patientData
 * @returns {Object} response
 * @prop {String} firstName
 * @prop {String} lastName
 * @prop {String} dateOfBirth - ISO standar date string
 * @prop {String} patientId - alphanumeric patientId
 * @prop {Object} guardian - Guardian information
 */
const editCase = async ({ id, patientData }) => {
  try {
    const response = await api.put(`/patient/${id}`, patientData)

    const {
      firstName,
      lastName,
      dateOfBirth,
      patientId,
      guardian
    } = response.data

    return {
      firstName,
      lastName,
      dateOfBirth,
      patientId,
      guardian
    }
  } catch (error) {
    Logger.error('Case API - editCase', error)
    throw error
  }
}

/**
 * @param {Object} caseData - full case data map
 * @returns {Object} response
 */
const createCase = async (caseData) => {
  try {
    const response = await api.post('/case', caseData)
    return response.data
  } catch (error) {
    Logger.error('Case API - createCase', error)
    throw error
  }
}

/**
 * @param {Object} caseId
 * @param {Object} caseData - full case data map
 * @returns {Object} response
 */
const updateCase = async (caseId, caseData) => {
  try {
    const response = await api.put(`/case/${caseId}`, caseData)
    Logger.info('Case API - update case executed')
    return response.data
  } catch (error) {
    Logger.error('Case API - update case error', error)
    throw error
  }
}

const sendCardToken = async ({ caseId, token, fastTrackOption }) => {
  try {
    const headers = {
      [PAYMENT_HEADERS.XStripeToken]:
        typeof token === 'undefined' ? null : token,
      [PAYMENT_HEADERS.XFastTrack]: FAST_TRACK_HEADER_VALUES[fastTrackOption]
    }

    const response = await api.post(`/case/${caseId}/cardtoken`, {}, { headers })
    Logger.info('Case API - sendCardToken executed')
    return response.data
  } catch (error) {
    Logger.error('Case API - sendCardToken', error)
    throw error
  }
}


/**
 * @param {Object} caseId
 * @param {Object} caseData - full case data map
 * @returns {Object} response
 */
const updateCaseMidcourseCorrectionOrRefinement = async (caseId, caseData) => {
  try {
    const response = await api.put(`/case/${caseId}/midcourse-refinement`, caseData)
    return response.data
  } catch (error) {
    Logger.error('Case API - updateCaseMidcourseCorrectionOrRefinement', error)
    throw error
  }
}

/**
 * @param {number} caseId
 * @returns {Object} An object containing the product eligibility and preference
 */
 const getProductEligibility = async (caseId) => {
  try {
    const response = await api.get(`/case/${caseId}/product-eligibility`)
    return response.data
  } catch (error) {
    Logger.error('Case API - getProductEligibility', error)
    throw error
  }
}

/**
 * Align order case by ID
 * @param {String} caseId
 * @param {Object} body
 * @returns {Object} response object
 */
const createAlignerOrder = async (caseId, body) => {
  try {
    const response = await api.post(`/case/${caseId}/aligner-order`, body)
    return response
  } catch(error) {
    Logger.error('Case API - createAlignerOrder', error)
    throw error
  }
}

const skipAlignerOrder = async (caseId, body) => {
  try {
    await api.post(`/case/${caseId}/skip-aligner-order`, body)
  } catch (error) {
    Logger.error(error)
    throw error
  }
}

export {
  createAlignerOrder,
  createCase,
  editCase,
  getCase,
  getCases,
  getGuardianInfo,
  getProductEligibility,
  sendCardToken,
  skipAlignerOrder,
  updateCase,
  updateCaseMidcourseCorrectionOrRefinement
}
