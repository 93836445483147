<template>
  <b-select
    :id="name"
    v-model="selected"
    :name="name"
    class="sdc-select"
    :disabled="disabled"
    :required="required"
    :state="state"
    :expanded="true"
    size="is-large"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </b-select>
</template>

<script>

export default {
  name: 'SdcSelect',
  props: {
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    selected: {
      get() {
        return this.$attrs.value || ''
      },
      set(newVal) {
        this.$emit('input', newVal)
        this.$emit('onSelect', newVal)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.sdc-select {
  margin-bottom: 0.75rem;
}
</style>
