<template>
  <b-tab-item
    :value="value"
    :label="label"
  >
    <slot />
  </b-tab-item>
</template>
<script>
export default {
  name: 'SdcTabItem',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>