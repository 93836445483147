import ErrorException from './ErrorException'

class GlobalException {
  constructor() {
    window.addEventListener('error', this._callErrorException)
    window.onerror = this._callErrorException
    window.addEventListener('unhandledrejection', this._callErrorException)
  }

  _callErrorException(error) {
    if (error.preventDefault) {
      error.preventDefault()

      const errorException = new ErrorException('GlobalException')

      if (error.reason) {
        const { message, stack } = error.reason

        errorException.message = message
        errorException.stack = stack
      }

      errorException.throwError()
    }
  }

  getVueErrorHandler() {
    return this._callErrorException
  }
}

const instance = new GlobalException()

export default instance
