<template>
  <div
    name="stepper"
    class="stepper"
  >
    <div class="stepper-steps">
      <template v-for="(step, key) in innerSteps">
        <div
          :key="`step-${key}`"
          class="stepper-step"
        >
          <div
            :key="`step-upper-${key}`"
            class="stepper-upper"
          >
            <hr
              :key="`step-divider-start-${key}`"
              :class="getStepDividerClass(key)"
            >
            <div
              :key="`step-item-${key}`"
              :class="getStepItemClass(key)"
            >
              <span v-show="!isCompletedStep(key)">{{ key + 1 }}</span>
              <sdc-image
                v-show="isCompletedStep(key)"
                name="stepper-icon-check"
                :src="iconCheckSrc"
                alt-text="check-mark"
              />
            </div>
            <hr
              :key="`step-divider-end-${key}`"
              :class="getStepDividerClass(key, lastStep)"
            >
          </div>
          <div
            :key="`step-lower-${key}`"
            class="d-none d-md-block"
            :class="getStepTitleClass(key)"
          >
            {{ step.title }}
          </div>
        </div>
      </template>
    </div>
    <div class="section-panel-rounded-content">
      <!-- TODO: Review this functionality to preserve components as it affects lifecycle methods -->
      <template v-if="cached">
        <keep-alive>
          <component
            :is="steps[localCurrentStep].component"
            :model="componentModel"
          />
        </keep-alive>
      </template>
      <template v-else>
        <component
          :is="steps[localCurrentStep].component"
          :model="componentModel"
        />
      </template>
      <!-- end TODO -->
    </div>
    <div class="stepper-actions">
      <span v-if="showBackButton">
        <sdc-button
          class="stepper-actions_back"
          outlined
          :disabled="isProcessingAction"
          @click="goPreviousStep"
        >
          {{ i18n('STEPPER__BACK_BUTTON') }}
        </sdc-button>
      </span>
      <span v-if="!isLastStep">
        <sdc-button
          class="stepper-actions_next"
          type="primary"
          :disabled="isProcessingAction"
          @click="checkValidation(goNextStep)"
        >
          {{ i18n('STEPPER__NEXT_BUTTON') }}
        </sdc-button>
      </span>
      <span v-if="isLastStep">
        <sdc-button
          class="stepper-actions_submit"
          type="primary"
          :disabled="isProcessingAction"
          @click="checkValidation(submit)"
        >
          <template v-if="showSubmitButton">
            {{ i18n('STEPPER__SUBMIT_BUTTON') }}
          </template>
          <template v-if="showPaymentSubmitButton">
            {{ i18n('STEPPER__PAYMENT_SUBMIT_BUTTON') }}
          </template>
        </sdc-button>
      </span>
    </div>
  </div>
</template>

<script>
// TODO: REFACTOR TO SPLIT IN SMALLER COMPONENTS
// TODO: NEEDS REFACTOR, BUSINESS LOGIC MIXED, IMPORTANT!
import { i18n } from '_utils_/i18n'
import { mapMutations, mapState, mapActions } from 'vuex'
import SdcButton from '_atoms_/SdcButton'
import SdcImage from '_atoms_/SdcImage'

/**
 * Props:
 * @title: String, required
 * @component VueObject, required
 * validate: Function, optional
 */

export default {
  name: 'Stepper',
  components: {
    SdcButton,
    SdcImage
  },
  props: {
    steps: {
      type: Array,
      required: true,
      validator(prop) {
        return prop.every((step) => (step.title && step.component && step.name))
      }
    },
    handleSubmit: {
      type: Function,
      default: () => {}
    },
    cached: { //temporal prop to preserve components for walletUI. needs to be reviewed
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCurrentStep: 0,
      isValidating: false,
      innerSteps: []
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('CreateCase', ['isPaymentUpdate']), // MUST BE REMOVED
    ...mapState('Stepper', ['currentStep']),
    iconCheckSrc() {
      return require('_assets_/icon-check.svg')
    },
    componentModel() {
      return this.steps[this.localCurrentStep].model || {}
    },
    lastStep() {
      return this.innerSteps.length - 1
    },
    isFirstStep() {
      return this.localCurrentStep === 0
    },
    isLastStep() {
      return this.localCurrentStep === this.lastStep
    },
    showBackButton() {
      return !this.isFirstStep && !(this.isLastStep && this.isPaymentUpdate)
    },
    showSubmitButton(){
      return !this.isPaymentUpdate
    },
    showPaymentSubmitButton(){
      return this.isPaymentUpdate
    },
    isProcessingAction() {
      return this.isValidating || this.loading
    }
  },
  beforeMount() {
    this.innerSteps = [...this.steps].map((step, key) => ({
      ...step,
      completed: (key < this.currentStep)
    }))
    this.localCurrentStep = this.currentStep
  },
  mounted() {
    this.emitCurrentStep()
  },
  beforeDestroy() {
    this.resetCurrentStep()
  },
  methods: {
    ...mapMutations('Stepper', ['saveCurrentStep', 'resetCurrentStep']),
    ...mapActions('AppProcess', ['setLoadingStatus']),
    i18n,
    getStepTitleClass(key) {
      const isActiveStep = this.isActiveStep(key)

      return {
        'stepper-title': true,
        'stepper-title_active': isActiveStep,
        'stepper-title_inactive': !isActiveStep
      }
    },
    getStepItemClass(key) {
      const isActiveStep = this.isActiveStep(key)

      return {
        'stepper-item': true,
        'stepper-item_active': isActiveStep,
        'stepper-item_inactive': !isActiveStep,
        'stepper-item_completed': this.isCompletedStep(key)
      }
    },
    getStepDividerClass(key, boundaryStep = 0) {
      return {
        'stepper-divider': true,
        'stepper-divider_hidden': key === boundaryStep
      }
    },
    goNextStep() {
      this.focusOnTop()
      if (this.localCurrentStep < this.lastStep) {
        this.innerSteps[this.localCurrentStep].completed = true
        this.localCurrentStep += 1
      }

      if (this.innerSteps[this.localCurrentStep].ignore) {
        this.goNextStep()
      }

      this.emitCurrentStep()
    },
    goPreviousStep() {
      this.focusOnTop()
      if (this.localCurrentStep > 0) {
        this.localCurrentStep -= 1
      }

      this.innerSteps[this.localCurrentStep].completed = false
      if (this.innerSteps[this.localCurrentStep].ignore) {
        this.goPreviousStep()
      }

      this.emitCurrentStep()
    },
    async submit() {
      this.setLoadingStatus(true)
      if (this.handleSubmit) {
        await this.handleSubmit()
      }
      this.resetCurrentStep()
      this.setLoadingStatus(false)
    },
    isCompletedStep(step) {
      return this.innerSteps[step].completed
    },
    isActiveStep(key) {
      return key <= this.localCurrentStep
    },
    getButtonProps(type) {
      const props = {
        back: {
        },
        next: {
        },
        submit: {
        }
      }

      return {
        disabled: this.loading,
        ...props[type]
      }
    },
    async checkValidation(nextFunction) {
      this.isValidating = true
      const currentStep = this.innerSteps[this.localCurrentStep]

      if (currentStep.validate) {
        await currentStep.validate({
          model: this.componentModel,
          next: nextFunction
        })

        this.isValidating = false
        return
      }

      await nextFunction()
      this.isValidating = false
    },
    focusOnTop() {
      window.scrollTo(0, 0)
    },
    emitCurrentStep() {
      this.saveCurrentStep(this.localCurrentStep)
      this.$emit('setCurrentStep', this.steps[this.localCurrentStep].name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$grey-stepper: #E0E0E0;

.stepper {
  background: transparent;

  &-steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: $font-weight-bold;
  }

  &-step {
    flex: 1 1 0;
  }

  &-upper {
    display: flex;
    align-items: center;
  }

  &-item {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;

    img {
      height: 20px;
      width: 20px;
    }

    &_active {
      border: 2px solid $primary;
      color: $primary;
    }

    &_inactive {
      border: 2px solid $grey-stepper;
      color: $grey-stepper;
    }

    &_completed {
      background: $primary;
    }
  }

  &-divider {
    display: flex;
    flex-grow: 1;
    height: 0;
    border: 1px solid $grey-stepper;

    &_hidden {
      visibility: hidden;
    }
  }

  &-title {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: $font-size-xs;
    line-height: $font-size-xs;
    text-align: center;

    &_active {
      color: $primary;
    }

    &_inactive {
      color: $grey-stepper;
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    button {
      font-weight: $font-weight-normal;
      font-family: $font-family-bold;
      border-radius: 28px;
      box-sizing: border-box;
      font-size: $font-size-md;
      height: 56px;
      line-height: $font-size-md;
      margin-left: 10px;
    }

    &_submit {
      width: 183px;
    }

    &_next {
      width: 156px;
    }

    &_back {
      width: 122px;
    }
  }
}
</style>
