<template>
  <section class="order-retake">
    <div :class="['is-flex', 'is-flex-direction-column']">
      <sdc-title level="4">
        {{ i18n(`ORDER_RETAKE__TITLE_${previousOrderType}`) }}
      </sdc-title>
      <sdc-message
        v-if="hasReason"
        type="info"
        :icon-path="infoImgSrc"
        :text-message="reasonsText"
      />
  
      <div>
        <sdc-button @click="openRetakeModal">
          {{ i18n('ORDER_RETAKE__SUBMIT') }}
        </sdc-button>
      </div>
    </div>

    <sdc-modal
      ref="order-retake-submit-modal"
      name="order-retake-submit-modal"
      size="md"
      v-bind="modalProps"
      @onConfirm="onConfirm"
    >
      <sdc-radio-button
        v-model="selectedOrderType"
        name="order-retake-types"
        :options="retakeOrderTypes"
        rules="required"
      />
    </sdc-modal>
  </section>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import SdcMessage from '_atoms_/SdcMessage'
import SdcButton from '_atoms_/SdcButton'
import SdcModal from '_molecules_/SdcModal'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import { IMPRESSION_MODES } from '_utils_/constants'
import { ORDER_TYPES } from './constants'
import { i18n } from '_utils_/i18n'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'


const getRetakeOrderTypes = (code) => [
  {
    value: { type: `improd${code}rtk`, mode: IMPRESSION_MODES.physical },
    text: i18n('ORDER_RETAKE__TYPE_PHYSICAL')
  },
  {
    value: { type: `scekod${code}rtk`, mode: IMPRESSION_MODES.scanner },
    text: i18n('ORDER_RETAKE__TYPE_SCANNER')
  },
  {
    value: { type: `scekod${code}rtk`, mode: IMPRESSION_MODES.manual },
    text: i18n('ORDER_RETAKE__TYPE_MANUAL')
  }
]

export default {
  name: 'OrderRetake',
  components: {
    SdcTitle,
    SdcMessage,
    SdcButton,
    SdcModal,
    SdcRadioButton
  },
  data() {
    return {
      modalProps: {
        titleProps: {
          text: 'ORDER_RETAKE__MODAL_TITLE'
        },
        buttonProps: {
          confirmProps: {
            text: 'ORDER_RETAKE__MODAL_SUBMIT'
          },
          cancelProps: {
            text: 'ORDER_RETAKE__MODAL_CANCEL'
          }
        }
      },
      selectedOrderType: ''
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details']),
    ...mapGetters('CaseDetails', ['isMcc', 'isRef']),
    infoImgSrc() {
      return require('_assets_/icon-info.svg')
    },
    modalRef() {
      return this.$refs['order-retake-submit-modal']
    },
    reasonsText() {
      return i18n('ORDER_RETAKE__REASON', [ this.retakeReason ])
    },
    hasReason() {
      return this.retakeReason
    },
    previousOrderType() {
      return this.details.impressionMode === IMPRESSION_MODES.physical ? ORDER_TYPES.IMPRESSION : ORDER_TYPES.SCAN
    },
    retakeReason() {
      return this.details.reasonCode ? i18n(`ORDER_RETAKE__REASON_${this.details.reasonCode}`) : ''
    },
    retakeOrderTypes() {
      const code = this.isMcc ? 'mcc' : this.isRef ? 'ref' : ''
      return getRetakeOrderTypes(code)
    }
  },
  mounted() {
    this.selectedOrderType = this.retakeOrderTypes[0].value
  },
  methods: {
    i18n,
    ...mapActions('CaseDetails', ['createRetakeOrder', 'getPatientDetails']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    async onConfirm(close) {
      this.setLoadingStatus(true)
      const payload = {
        caseId: this.$route.params.id,
        orderType: this.selectedOrderType.type,
        impressionMode: this.selectedOrderType.mode
      }
      await this.createRetakeOrder(payload)

      this.setLoadingStatus(false)
      const id = this.$route.params.id
      this.getPatientDetails({ id })
      close()
    },
    openRetakeModal() {
      this.modalRef.open()
    },
  }
}
</script>
