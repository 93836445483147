import { sdcInstance as api } from './sdc.config.api'
import { DEFAULT_TIMEOUT } from '_utils_/constants'
import Logger from '@/utils/Logger'

/**
 * Check if the given zipcode is valid
 *
 * @param {Object} shippingData
 * @prop {String} shippingData.city
 * @prop {String} shippingData.streetAddress1
 * @prop {String} shippingData.countryCode
 * @prop {String} shippingData.state
 * @prop {String} shippingData.zipCode
 *
 * @returns {object}
 */
const checkZipCode = async (shippingData) => {
  try {
    const response = await api.post('/address/exists', shippingData, {
      timeout: DEFAULT_TIMEOUT
    })
    return response.data
  } catch (error) {
    Logger.error('Address API - checkZipCode', error)
    if (!error.response) {
      return {
        exists: false,
        isUnhandled: true
      }
    }
    return error.response.data
  }
}

const checkValidAddress = async (addressData) => {
  try {
    const response = await api.post('/address/valid', addressData, {
      timeout: DEFAULT_TIMEOUT
    })
    Logger.info('Address API - checkValidAddress executed')
    return response.data
  } catch (error) {
    Logger.error('Address API - checkValidAddress', error)
    if (!error.response) {
      return {
        isValid: false,
        isUnhandled: true
      }
    }
    return error.response.data
  }
}

const getRegionFromZipcode = async (countryCode, zipCode) => {
  try {
    const response = await api.get(`/address/region/${countryCode}/${zipCode}`)
    Logger.info('Address API - getRegionFromZipcode executed')
    return response.data.region
  } catch (error) {
    Logger.error('Address API - getRegionFromZipcode', error)
    return null
  }
}

export {
  checkZipCode,
  checkValidAddress,
  getRegionFromZipcode
}
