<template>
  <sdc-modal
    id="business-organization-modal"
    ref="businessOrganizationModal"
    name="business-organization-modal"
    :title-props="titleProps"
    hide-footer
    size="medium"
  >
    <business-organization-form :close-modal="close" />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal'
import BusinessOrganizationForm from '_molecules_/BusinessOrganizationForm'
export default {
  name: 'BusinessOrganizationModal',
  components: {
    SdcModal,
    BusinessOrganizationForm
  },
  data() {
    return {
      titleProps: {
        text: 'OFFICE_BUSINESS_ORGANIZATION__MODAL_TITLE'
      }
    }
  },
  methods: {
    i18n,
    close() {
      this.$refs['businessOrganizationModal'].close()
    },
    open() {
      this.$refs['businessOrganizationModal'].open()
    }
  }
}
</script>

<style lang="scss">

</style>
