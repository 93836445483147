<template>
  <img
    ref="sdcImageRef"
    :name="name"
    :src="imageSrc"
    :class="className"
    :alt="altText"
    @click="onClick"
    @load="onLoad"
    @error="onError"
  >
</template>
<script>
import Location from '_utils_/Location'

export default {
  name: 'SdcImage',
  props: {
    name: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    altText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loadImageSucceed: true
    }
  },
  computed: {
    languageCode() {
      return Location.languageCode
    },
    isSrcAvailable() {
      return this.src.length > 0
    },
    imageSrc() {
      return (this.isSrcAvailable) ?
        this.src :
        require(`_assets_/no-image-found-${this.languageCode}.png`)
    }
  },
  methods: {
    onClick() {
      if (this.loadImageSucceed) {
        this.$emit('click',this.$el, this.src)
      }
    },
    onLoad() {
      if (this.isSrcAvailable) {
        this.$emit('load')
        this.loadImageSucceed = true
      }
    },
    onError() {
      this.$refs['sdcImageRef'].src = require(`_assets_/no-image-found-${this.languageCode}.png`)
      this.loadImageSucceed = false
      this.$emit('error')
    },
  }
}
</script>
