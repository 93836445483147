<template>
  <div>
    <div
      v-if="activeStepIndex === 0"
      class="remove-team-member-form__row"
    >
      {{ i18n('OFFICE_TEAM__EMPTY_MEMBER_DISCLAIMER') }}
    </div>
    <div class="remove-team-member-form">
      <validation-observer
        v-if="activeStepIndex === 1"
        ref="removeTeamMemberForm"
      >
        <div class="remove-team-member-form__row">
          {{ i18n('OFFICE_TEAM__REMOVE_CASES_LABEL') }}
        </div>
        <div class="remove-team-member-form__row">
          <span
            v-for="(caseNumber, index) in assignedCases"
            :key="index"
          >
            {{ caseNumber }}
          </span>
        </div>
        <div class="remove-team-member-form__row">
          {{ i18n('OFFICE_TEAM__REMOVE_DOCTOR_SELECT') }}
        </div>
        <div class="remove-team-member-form__main__dropdown">
          <validated-select-input
            v-model="selectedDoctor"
            name="doctors-list"
            :value="selectedDoctor"
            :rules="'required'"
            :options="getDoctorsOption"
          />
        </div>
        <div v-if="doctorsOptionEmpty">
          <span class="text-danger">
            {{ i18n('OFFICE_TEAM__REMOVE_USER_PRACTICES_NOT_ALLOWED') }}
          </span>
        </div>
      </validation-observer>
      <div class="remove-team-member-form__footer">
        <sdc-button
          class="remove-team-member-form__footer__button team-member-form__footer__button"
          type="alternative"
          :disabled="shouldDisableRemoveTeamMemberButton"
          @click="removeTeamMember"
        >
          {{ getSubmitButtonText }}
        </sdc-button>
        <sdc-button
          class="remove-team-member-form__footer__button"
          type="primary"
          @click="onCancel"
        >
          {{ getCancelButtonText }}
        </sdc-button>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: NEEDS REVIEW
import { i18n } from '_utils_/i18n'
import { mapActions, mapState, mapGetters } from 'vuex'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import { snackbarDefaultSettings } from '@/utils/constants'

export default {
  name: 'RemoveTeamMemberForm',
  components: {
    SdcButton,
    ValidatedSelectInput,
    ValidationObserver
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    userName: { type: String, default: '' },
    practices: { type: Array, required: true }
  },
  data() {
    return {
      selectedDoctor:'',
      modalShow: false,
      activeStepIndex: 0
    }
  },
  computed: {
    ...mapGetters('Cases', ['getCaseNumbers']),
    ...mapState('Users', ['userRemoved']),
    ...mapState('HttpRequest', ['loading']),
    ...mapState('Doctors', ['doctorsByPracticeId']),
    assignedCases() {
      return this.getCaseNumbers
    },
    doctorPractice() {
      return this.practices[0]
    },
    practiceDoctors() {
      return (
        this.assignedCases.length &&
          this.doctorsByPracticeId[
            `${this.doctorPractice.boId}:${this.doctorPractice.id}`
          ].doctors || []
      )
    },
    isCurrentFormValid() {
      return this.activeStepIndex === 0 || this.selectedDoctor !== ''
    },
    getDoctorsOption() {
      return this.practiceDoctors
        .filter(
          doctor =>
            doctor.userName.toLowerCase() !== this.userName.toLowerCase()
        )
        .map(doctor => ({
          text: doctor.fullName,
          value: doctor.userName
        }))
    },
    shouldDisableRemoveTeamMemberButton() {
      return this.loading || !this.isCurrentFormValid
    },
    doctorsOptionEmpty() {
      return this.getDoctorsOption.length === 0
    },
    getSubmitButtonText() {
      return this.activeStepIndex === 0
        ? i18n('LABEL__YES')
        : i18n('LABEL__CONFIRM')
    },
    getCancelButtonText() {
      return this.activeStepIndex === 0
        ? i18n('LABEL__NO')
        : i18n('LABEL__CANCEL')
    }
  },
  methods: {
    i18n,
    ...mapActions('Users', ['deleteUser']),
    async onSubmit() {
      const success =
        !this.assignedCases.length ||
        (await this.$refs.removeTeamMemberForm.validate())

      if (!success) return
      await this.deleteUser({
        userName: this.userName,
        reassignTo: this.selectedDoctor
      })
      this.toast()
    },
    removeTeamMember() {
      if (this.assignedCases.length && this.activeStepIndex === 0) {
        this.nextStep()
      }
      else {
        this.onSubmit()
      }
    },
    nextStep() {
      this.activeStepIndex++
    },
    onCancel() {
      this.closeModal()
    },
    toast() {
      this.$sdcToast.open({title: this.userRemoved.userName, content: this.userRemoved.msg})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.remove-team-member-form {
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px auto;
      span{
        margin: 5px 0px;
      }

    &__input {
      width: 100%;

      @media screen and (min-width: $mobile) {
        width: 49%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-normal;
      font-family: "Sofia Pro Bold";
      margin: 8px;
      padding: 15px 30px;
    }
  }
}
</style>
