import moment from 'moment'
import { getContinentsAndCountries } from '_api_/countries.api'
import { localeConstants,
  COUNTRIES,
  COUNTRY_URL_PATTERN } from '_utils_/constants'
import { setLanguage } from '_utils_/i18n'
import { getInitialLocale } from './localeHelpers'
import store from '_store_'
import { mutationTypes } from '_store_/modules/locale.store'
class Location {
  constructor() {
    if (!Location.instance) {
      this._config = {
        continents: [...localeConstants.defaultContinent],
        locale: localeConstants.default
      }
      Location.instance = this
    }

    return Location.instance
  }

  getItem(key) {
    return this._config[key] || null
  }

  async loadCountries() {
    try {
      this._config.continents = await getContinentsAndCountries()
      return true
    } catch (error) {
      return error.response
    }
  }

  setLocale(locale = null) {
    if (!locale) locale = getInitialLocale()

    this._config.locale = locale

    window.localStorage.setItem(localeConstants.storageName, locale)
    moment.locale(locale)
    setLanguage(locale)
    store.commit(`Locale/${mutationTypes.changeLocale}`, { locale })
  }

  buildUrl(localeItem) {
    const countryCode = localeItem.split(localeConstants.SEPARATOR)[1]
    const domainsPerCountry = JSON.parse(process.env.VUE_APP_COUNTRIES_PUBLIC_DOMAIN.replace(/\\"/g, '"')) ///tf/aws/main.tf
    const protocol = window.location.protocol

    return `${protocol}//${domainsPerCountry[countryCode]}?${localeConstants.queryParamName}=${localeItem}`
  }

  buildRouteUrl(route, countryCodeForUrl, localeItem) {
    const domainsPerCountry = JSON.parse(process.env.VUE_APP_COUNTRIES_PUBLIC_DOMAIN.replace(/\\"/g, '"')) ///tf/aws/main.tf
    const protocol = window.location.protocol

    return `${protocol}//${domainsPerCountry[countryCodeForUrl]}/${route}?${localeConstants.queryParamName}=${localeItem}`
  }

  redirectToLocaleDomain() {
    const match = window.location.hostname.match(COUNTRY_URL_PATTERN)

    if (match) {
      const urlLocale = match.length === 2 ? this.getDefaultLocaleFromUrlDomain(match[1]) : localeConstants.default
      const urlCountryCode = this.getCountryCodeFromLocale(urlLocale)

      if(this.countryCode !== urlCountryCode) {
        window.location.href = this.buildRouteUrl('login', this.countryCode, this.locale)
        return true
      }
    }

    return false
  }

  getDefaultLocaleFromUrlDomain(urlCountry) {
    if (COUNTRIES.has(urlCountry)) {
      const locales = COUNTRIES.get(urlCountry).locale
      return locales.items[locales.defaultIndex]
    }

    return localeConstants.default
  }

  getCountryCodeFromLocale(locale) {
    return locale.split(localeConstants.SEPARATOR)[1]
  }

  get continents() {
    return this.getItem('continents')
  }

  get locale() {
    return this.getItem('locale')
  }

  get countryCode() {
    return this.getItem('locale')?.split(localeConstants.SEPARATOR)[1]
  }

  get languageCode() {
    return this.getItem('locale')?.split(localeConstants.SEPARATOR)[0]
  }

  get countries() {
    const countryCodes = this.getItem('continents')?.map(
      (continent) => continent.countries.map((country) => country.code)
    )

    return Array.prototype.concat(...countryCodes)
  }

  get localeCompareConfiguration() {
    // This is the sort configuration used in all front-end sorting. It is locale-based and case insensitive.
    return [this.locale, { sensitivity: 'accent' }]
  }
}

const instance = new Location()

export default instance
