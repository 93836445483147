<template>
  <div>
    <div
      v-if="!isPaymentBrokerEnabled"
      class="fast-track-payment-info"
    >
      <sdc-title
        level="4"
        margin-level="2"
        class="fast-track-payment-info__title"
      >
        {{ i18n('FAST_TRACK_PAYMENT_INFO__TITLE') }}
      </sdc-title>
      <div class="fast-track-payment-info__subtitle">
        {{ i18n('FAST_TRACK_PAYMENT_INFO__SUBTITLE') }}
      </div>
      <div class="fast-track-payment-info__card-name">
        <validated-input
          v-model="creditCardName"
          name="fast-track-payment-info-card-name"
          :label="i18n('FAST_TRACK_PAYMENT_INFO__CARD_NAME')"
          rules="required"
          :max-length="maxLengths.nameOnCard"
        />
      </div>
      <div class="fast-track-payment-info__expiration">
        <validated-credit-card-input
          name="fast-track-payment-info-credit-card"
          @update="getTokenMethod"
        />
      </div>
    </div>
    <div
      v-if="isPaymentBrokerEnabled"
      class="fast-track-payment-info"
    >
      <sdc-title
        level="4"
        margin-level="2"
        class="fast-track-payment-info__title"
      >
        {{ i18n('FAST_TRACK_PAYMENT_INFO__TITLE') }}
      </sdc-title>
      <div class="fast-track-payment-info__subtitle">
        {{ i18n('PAYMENT_BROKER_SUBTITLE') }}
      </div>
      <sdc-radio-button
        v-model="pbMethod"
        name="fast-track-payment-broker-payment-method-radio"
        class="fast-track-payment-method__radio"
        rules="required"
        type="card"
        :options="getOptions()"
        :has-border="true"
        :place-holder-enabled="true"
      />
      <div hidden="true">
        <div id="creditDiv">
          <div
            v-if="pbMethod === 'credit-card'"
            class="payment-broker__payment-option"
          >
            <div>
              <validated-input
                v-model="creditCardName"
                name="fast-track-payment-info-card-name"
                :label="i18n('FAST_TRACK_PAYMENT_INFO__CARD_NAME')"
                rules="required"
                :max-length="maxLengths.nameOnCard"
              />
            </div>
            <div class="fast-track-payment-info__expiration">
              <validated-credit-card-input
                name="fast-track-payment-info-credit-card"
                @update="getTokenMethod"
              />
            </div>
            <label class="card-saved-label">
              {{ i18n('FAST_TRACK_PAYMENT_CARD_SAVED') }}
            </label>
          </div>
        </div>
        <div id="sepaDiv">
          <div
            v-if="pbMethod === 'sepa'"
            class="payment-broker__payment-option"
          >
            <validated-input
              v-model="sepaName"
              name="payment-broker-sepa-name"
              :label="i18n('PAYMENT_BROKER_SEPA_NAME')"
              rules="required"
              :max-length="patientMaxLengths.firstName"
            />
            <validated-input
              v-model="sepaLastName"
              name="payment-broker-sepa-last-name"
              :label="i18n('PAYMENT_BROKER_SEPA_LAST_NAME')"
              rules="required"
              :max-length="patientMaxLengths.lastName"
            />
            <validated-input
              v-model="sepaEmail"
              name="payment-broker-sepa-email"
              :label="i18n('PAYMENT_BROKER_SEPA_EMAIL')"
              rules="required|email"
              :max-length="patientMaxLengths.emailAddress"
            />
            <validated-input
              v-model="sepaIban"
              name="payment-broker-sepa-iban"
              :label="i18n('PAYMENT_BROKER_SEPA_IBAN')"
              rules="required"
            />
            <sdc-checkbox
              v-model="mandateAccepted"
              name="accept-mandate"
              rules="requiredTerms|requiredTermsBoolean"
            >
              {{ i18n('PAYMENT_BROKER_SEPA_MANDATE') }}
            </sdc-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import ValidatedInput from '_atoms_/ValidatedInput'
import ValidatedCreditCardInput from '_molecules_/ValidatedCreditCardInput'
import { inputMaxLengths } from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'FastTrackPaymentInfo',
  components: {
    SdcTitle,
    ValidatedInput,
    ValidatedCreditCardInput,
    SdcRadioButton,
    SdcCheckbox
  },
  data() {
    return {
      maxLengths: inputMaxLengths.fastTrack.creditCard,
      patientMaxLengths: inputMaxLengths.patientInfo,
      creditCardName: this.$attrs.value,
      isPaymentBrokerEnabled: I18nConfig.isPaymentBrokerEnabled,
      pbMethod:'',
      sepaName:'',
      sepaLastName:'',
      sepaEmail:'',
      sepaIban:'',
      mandateAccepted: false
    }
  },
  watch:{
    creditCardName(newVal) {
      this.$emit('input', newVal.toUpperCase())
    },
    pbMethod() {
     this.setPaymentBrokerPaymentMethod()
    },
    sepaName() {
      this.emitSepaEvent()
    },
    sepaLastName() {
      this.emitSepaEvent()
    },
    sepaEmail() {
      this.emitSepaEvent()
    },
    sepaIban() {
      this.emitSepaEvent()
    },
    mandateAccepted() {
      this.emitSepaEvent()
    }
  },
  mounted() {
        let creditDiv = document.getElementById('creditDiv')
        let depaDiv = document.getElementById('sepaDiv')
        if (creditDiv !== null) {
          document.getElementById('ph-fast-track-payment-broker-payment-method-radio-0').appendChild(creditDiv)
        }
        if (depaDiv !== null) {
          document.getElementById('ph-fast-track-payment-broker-payment-method-radio-1').appendChild(depaDiv)
        }
  },
  methods: {
    ...mapMutations('CreateCase', ['setPBPaymentMethod']),
    ...mapActions('CreateCase', ['setPBSepaformation']),
    i18n,
    getTokenMethod(tokenMethod) {
      this.$emit('update', tokenMethod)
    },
    setPaymentBrokerPaymentMethod(){
      this.setPBPaymentMethod(this.pbMethod)
    },
    emitSepaEvent() {
      const sepaData = {
        email: this.sepaEmail,
        firstName: this.sepaName,
        lastName: this.sepaLastName,
        iban: this.sepaIban,
        acceptMandate: this.mandateAccepted
      }
      this.setPBSepaformation(sepaData)
    },
    getOptions() {

      return [
        {
          text: this.formatRadioText({
            left: `
              ${i18n('PAYMENT_BROKER_CREDIT_CARD_TITLE',)}
            `,
            right: `
            <div class="fast-track-payment-method__card-brands">
              <img
              src="${this.getBrandImage('visa')}"
              alt="credit-card-brand"
              />
              <img
              src="${this.getBrandImage('amex')}"
              alt="credit-card-brand"
              />
              <img
              src="${this.getBrandImage('discover')}"
              alt="credit-card-brand"
              />
              <img
              src="${this.getBrandImage('mastercard')}"
              alt="credit-card-brand"
              />
            </div>`
          }),
          value:'credit-card'
        },

        {
          text: this.formatRadioText({
            left: `
              ${i18n('PAYMENT_BROKER_SEPA_TITLE',)}
            `,
            right: ''
          }),
          value:'sepa'
        },

      ]
    },
    formatRadioText({ left, right }) {
      return `<div class="fast-track-payment-method__radio-option">
        <div class="fast-track-payment-method__radio-option--left">${left}</div>
        <div class="fast-track-payment-method__radio-option--right">${right}</div>
      </div>`
    },
    getBrandImage(brand) {
      const card = require('_assets_/cards/' + brand + '.png')
      return card
    },
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.fast-track-payment-info {
  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__card-number {
    margin-bottom: 40px;
  }

  &__card-name {
    input {
      text-transform: uppercase;
    }
  }
}
.payment-broker{
  &__payment-option {
    margin: 25px;
  }
}
.fast-track-payment-method{
  &__card-brands {
      width: 310px;
      & > img {
        height: 30px;
        width: 48px;
      }
  }
}
.card-saved-label {
  font-size: $font-size-sm;
  line-height: $line-height-lg;
  font-weight: $font-weight-normal;
  letter-spacing: 0;
  text-align: left;
}
</style>
