import { MAX_NUMBER_YEARS } from '_utils_/constants'

//https://stripe.com/docs/error-codes
const ERROR_CODES = {
  'card_declined': {
    msg: 'ERROR__CREDIT_CARD_DECLINED',
    type: 'number'
  },
  'card_decline_rate_limit_exceeded': {
    msg: 'ERROR__CREDIT_CARD_DECLINED',
    type: 'number'
  },
  'expired_card': {
    msg: 'ERROR__CREDIT_CARD_EXPIRED',
    type: 'number'
  },
  'incorrect_cvc': {
    msg: 'ERROR__INVALID_VALUE',
    type: 'cvc'
  },
  'invalid_cvc': {
    msg: 'ERROR__INVALID_VALUE',
    type: 'cvc'
  },
  'incorrect_number': {
    msg: 'ERROR__CREDIT_CARD_INVALID',
    type: 'number'
  },
  'invalid_characters': {
    msg: 'ERROR__CREDIT_CARD_INVALID',
    type: 'number'
  },
  'invalid_card_type': {
    msg: 'ERROR__CREDIT_CARD_NOT_SUPPORTED',
    type: 'number'
  },
  'invalid_expiry_month': {
    msg: 'ERROR__CREDIT_CARD_EXPIRED_MONTH',
    type: 'month'
  },
  'invalid_expiry_year': {
    msg: 'ERROR__CREDIT_CARD_EXPIRED_YEAR',
    type: 'year'
  },
  'invalid_number': {
    msg: 'ERROR__CREDIT_CARD_INVALID',
    type: 'number'
  },
  'default': {
    msg: 'ERROR__CREDIT_CARD_DEFAULT',
    type: 'number'
  }
}

const getCardBrand = (brand) => {
  const brands = {
    'American Express': 'amex',
    'Diners Club': 'diners',
    'Discover': 'discover',
    'JCB': 'jcb',
    'MasterCard': 'mastercard',
    'Visa': 'visa'
  }

  return brands[brand] || null
}


export {
  ERROR_CODES,
  getCardBrand,
  MAX_NUMBER_YEARS
}
