import SdcToast from '_atoms_/SdcToast'

export default {
  install(Vue) {
    Vue.prototype.$sdcToast = Vue.observable({
      open(params) {
        if (typeof Vue.prototype.$buefy == 'undefined') {
          throw new Error('SdcToast Component was not loaded correctly')
        } else {
          SdcToast.open(params, Vue.prototype.$buefy)
        }
      }
    })
  }
}
