import { GuestAuthClient, FinanceClient } from '@smiledirectclub/sdc.pfi-core-external'

let financeServiceClient = null

const getGuestAuthClient = () => {
  const url = new URL('okta/token', process.env.VUE_APP_SDC_API_BASE_URL)
  return new GuestAuthClient({ url: url.toString() })
}

const getFinanceServiceClient = () => {
  if (!financeServiceClient) {
    const guestAuth = getGuestAuthClient()
    financeServiceClient = new FinanceClient(
      process.env.VUE_APP_PAYMENT_ENV,
      guestAuth
    )
  }

  return financeServiceClient
}

export { getFinanceServiceClient, getGuestAuthClient }
