<template>
  <information-form
    id="guardian-info"
    v-model="model"
    name="guardian-info"
    :title="i18n(title)"
    :subtitle="i18n('CREATE_CASE__GUARDIAN_INFO_EMAIL')"
    :custom-rules="customRules"
    :render-sms="renderSms"
    :email-always-disabled="emailDisabled"
    :disabled-fields="canGuardianBeResumed"
    :enabled-date-of-birth="enabledDateOfBirth"
    :email-field-rules="emailFieldRules"
    :on-blur-email-check="validateGuardianEmail"
    legal-name-key="INFORMATION_FORM__LEGAL_NAME_GUARDIAN__TEXT"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InformationForm from '_molecules_/InformationForm'
import { createCaseConstants } from '_utils_/constants'
import { i18n } from '_utils_/i18n'

const { initialDates } = createCaseConstants

export default {
  name: 'GuardianInfo',
  components: { InformationForm },
  props: {
    renderSms: {
      type: Boolean,
      required: true
    },
    title:{
      type: String,
      default: ''
    },
    emailDisabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      initialDates,
      customRules: {
        firstName: 'required',
        lastName: 'required',
        emailAddress: 'required|email',
        dateOfBirth: {
          day: 'isGuardianMinor:@day,@month,@year',
          month: 'isGuardianMinor:@day,@month,@year',
          year: 'isGuardianMinor:@day,@month,@year'
        }
      },
      model: this.$attrs.value || ''
    }
  },
  computed: {
    ...mapState('InformationForm', ['canPatientBeResumed','canGuardianBeResumed','isPatientGuardian', 'isExistingEmail', 'isInvalidEmail']),
    enabledDateOfBirth() {
      return this.canGuardianBeResumed
    },
    emailFieldRules() {
      const isExistingEmailRule = this.isExistingEmail ? '|isExistingGuardianEmail' : ''
      const isInvalidEmailRule = this.isInvalidEmail ? '|isInvalidEmail' : ''
      return `${this.customRules.emailAddress}${isExistingEmailRule}${isInvalidEmailRule}`
    }
  },
  watch: {
    model(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n,
    ...mapActions('InformationForm', ['checkExistingGuardianEmail']),
    validateGuardianEmail() {
      if (!this.canGuardianBeResumed) {
        this.checkExistingGuardianEmail()
      }
    }
  }
}
</script>
