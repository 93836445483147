<template>
  <sdc-badge class="case-status-label">
    <div :class="getStatusCircleClass" />
    {{ i18n(`CASE_STATUS__${statusId}`) }}
  </sdc-badge>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcBadge from '_atoms_/SdcBadge'

export default {
  name: 'CaseStatusLabel',
  components: {
    SdcBadge
  },
  props: {
    statusId: {
      type: String,
      required: true
    }
  },
  computed: {
    getStatusCircleClass() {
      return {
        'case-status-label__circle': true,
        [`case-status-label__circle--status-${this.statusId}`]: true
      }
    }
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss" scoped>
@import './_constants';

.case-status-label {

  &__circle {
    position: relative;
    top: 1px;
    margin-right: 2px;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;

    @each $status, $color in $status-colors {
      &--status-#{$status} {
        background-color: $color;
      }
    }
  }
}
</style>
