import { getFeatures } from '_api_/features.api'

export const mutationTypes = {
  setFeatures: 'setFeatures'
}

export const state = {
  caseHistorySection: false,
  useNewDiscountVulcanEndpoint: false,
  useNewCheckoutForAllPractices: false
}

export const mutations = {
  [mutationTypes.setFeatures](state, payload) {
    for (const key in payload) {
      state[key] = !!payload[key]
    }
  }
}

export const actions = {
  async loadFeatures({ commit }) {
    const features = await getFeatures()
    commit(mutationTypes.setFeatures, features)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
