import Location from '_utils_/Location'
import { getRegionFromZipcode } from '_api_/address.api'
import { zipCodeChecking } from '_utils_/addressHelper'

export const actions = {
  async isValidZipcode({ dispatch }, addressData) {
    let result

    dispatch('HttpRequest/setLoadingStatus', true, { root: true })

    result = await zipCodeChecking({
      ...addressData,
      countryCode: Location.countryCode
    })

    dispatch('HttpRequest/setLoadingStatus', false, { root: true })

    return result
  },
  async getRegionFromZipcode({ dispatch }, zipCode) {
    let region

    dispatch('HttpRequest/setLoadingStatus', true, { root: true })

    try {
      region = await getRegionFromZipcode(Location.countryCode, zipCode)
    } catch (error) {
      region = null
    }

    dispatch('HttpRequest/setLoadingStatus', false, { root: true })

    return region
  }
}

export default {
  namespaced: true,
  actions
}
