<template>
  <div class="paginator">
    <div class="paginator__status">
      {{ i18n('PAGINATOR__STATUS', [startItem, finalItem, totalItems]) }}
    </div>
    <div class="paginator__actions">
      <template v-for="(button, key) in buttons">
        <paginator-button
          :key="key"
          v-bind="getPaginatorButtonProps(button)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import PaginatorButton from '@/components/atoms/PaginatorButton'

export default {
  name: 'Paginator',
  components: {
    PaginatorButton
  },
  props: {
    totalItems: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
    currentPage: { type: Number, required: true }
  },
  data() {
    return {
      buttons: ['first', 'previous', 'next', 'last']
    }
  },
  computed: {
    totalPages() {
      var incompletePage = (this.totalItems % this.itemsPerPage > 0) ? 1 : 0
      return Math.trunc(this.totalItems / this.itemsPerPage) + incompletePage
    },
    hasPreviousPage() {
      return this.currentPage > 1
    },
    hasNextPage() {
      return this.currentPage < this.totalPages
    },
    startItem() {
      if (this.totalItems === 0) return 0

      return (((this.currentPage - 1) * this.itemsPerPage) + 1) || 0
    },
    finalItem() {
      const lastItemPage = this.startItem + (this.itemsPerPage - 1)

      return (lastItemPage > this.totalItems) ?
        this.totalItems :
        lastItemPage
    },
    hasResults() {
      return this.totalPages > 0
    }
  },
  methods: {
    i18n,
    changePage(page) {
      this.$emit('pageChanged', page)
    },
    moveFirst() {
      if (this.hasResults && !this.isFirstPage()) {
        this.changePage(1)
      }
    },
    movePrevious() {
      if (this.hasPreviousPage) {
        this.changePage(this.currentPage - 1)
      }
    },
    moveNext() {
      if (this.hasNextPage) {
        this.changePage(this.currentPage + 1)
      }
    },
    moveLast() {
      if (this.hasResults && !this.isLastPage()) {
        this.changePage(this.totalPages)
      }
    },
    isFirstPage() {
      return (this.currentPage === 1)
    },
    isLastPage() {
      return (this.currentPage === this.totalPages)
    },
    shouldShowBackActive() {
      return (this.totalItems > 0 && !this.isFirstPage())
    },
    shouldShowForwardActive() {
      return (this.totalItems > 0 && !this.isLastPage())
    },
    getPaginatorButtonProps(type) {
      const self = this
      const types = {
        first: {
          name: 'first',
          click: self.moveFirst,
          altText: 'First Page',
          active: self.shouldShowBackActive(),
        },
        previous: {
          name: 'prev',
          click: self.movePrevious,
          altText: 'Previous Page',
          active: self.shouldShowBackActive(),
        },
        next: {
          name: 'next',
          click: self.moveNext,
          altText: 'Next Page',
          active: self.shouldShowForwardActive(),
        },
        last: {
          name: 'last',
          click: self.moveLast,
          altText: 'Last Page',
          active: self.shouldShowForwardActive(),
        }
      }

      return types[type]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, "tablet");

.paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  &__actions {
    min-width: 210px;
  }

  &__status {
    margin-right: 4px;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column-reverse;
    &__status {
      margin-right: 0;
      margin-top: 10px;
    }
  }
}
</style>
