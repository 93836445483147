<template>
  <div
    id="submit-additional-info"
    class="submit-additional-info"
  >
    <h4 class="submit-additional-notes__title">
      {{ title }}
    </h4>
    <sdc-textarea
      v-model="dataValue"
      name="submit-additional-info"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import SdcTextarea from '_atoms_/SdcTextarea'

export default {
  name: 'SubmitAdditionalInfo',
  components: {
    SdcTextarea
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dataValue: this.$attrs.value
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.submit-additional-info {
  width: 100%;
  @media screen and (min-width: $mobile) {
    width: 49%;
  }
  margin-top: 40px;

  &__title {
    margin-bottom: 18px;
  }
}
</style>
