export const mutationTypes = {
  setLoadingStatus: 'setLoadingStatus',
  setHttpError: 'setHttpError'
}

export const state = {
  loading: false,
  httpError: {}
}

export const mutations = {
  [mutationTypes.setLoadingStatus](state, payload) {
    state.loading = payload
  },
  [mutationTypes.setHttpError](state, payload) {
    state.httpError = {
      ...state.httpError,
      [payload.type]: payload
    }
  }
}

export const actions = {
  setLoadingStatus({ commit }, payload) {
    commit(mutationTypes.setLoadingStatus, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
