<template>
  <validation-observer ref="email-notification-form">
    <sdc-modal
      ref="email-notification-internal-modal"
      name="email-notification-modal"
      size="xl"
      v-bind="modalProps"
      @onConfirm="onSubmit"
    >
      <validated-email-input
        v-model="emailNotificationAddress"
        :max-length="maxLengths.emailAddress"
        name="email-notification-address"
        :label="i18n('EMAIL_NOTIFICATIONS__EMAIL_LABEL')"
        :rules="getEmailAddressRules"
        :disabled="false"
        class="email-notification-modal__form__email-input"
      />
      <sdc-checkbox
        v-for="item in emailNotificationSettings"
        :key="item.key"
        v-model="item.value"
        :disabled="getConfigurationCheckboxRequired(item.key)"
        :name="item.key"
      >
        {{ getConfigurationCheckboxLabel(item.key) }}
      </sdc-checkbox>
    </sdc-modal>
  </validation-observer>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { ValidationObserver } from 'vee-validate'
import ValidatedEmailInput from '_molecules_/ValidatedEmailInput'
import {
  inputMaxLengths,
  EMAIL_NOTIFICATION_SETTINGS,
  EMAIL_NOTIFICATION_TOAST_MESSAGES
} from '@/utils/constants'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'EmailNotificationsModal',
  components: {
    SdcModal,
    SdcCheckbox,
    ValidationObserver,
    ValidatedEmailInput
  },
  props: {
    emailAddress: {
      type: String,
      default: null,
    },
		settings: {
			type: Object,
			default: null
		}
  },
  data() {
    return {
      modalProps: {
        titleProps: {
          level: '3',
          text: ''
        },
        buttonProps: {
          confirmProps: {
            text: ''
          },
          cancelProps: {
            text: 'EMAIL_NOTIFICATIONS__MODAL_CANCEL_BUTTON'
          }
        }
      },
      emailNotificationSettings: [
        { value: true, key: 'scanMissing'},
        { value: true, key: 'newEvaluationAppointmentBooked'},
        { value: true, key: 'alignerPurchased'},
        { value: true, key: 'scanRejected'},
        { value: true, key: 'reminderToSendBackImpressionsorScan'},
        { value: true, key: 'impressionRejected'},
        { value: true, key: 'uniqualifiedCases'}
      ],
      maxLengths: inputMaxLengths.patientInfo,
      emailNotificationAddress: '',
      oldEmailNotificationAddress: ''
    }
  },
  computed: {
    ...mapState(['EmailNotification'], ['emailNotificationItems']),
    modalRef() {
      return this.$refs['email-notification-internal-modal']
    },
    validatorRef() {
      return this.$refs['email-notification-form']
    },
    getEmailAddressRules() {
      return 'required|emailExtended'
    },
    isEditEmailNotification() {
      return this.emailAddress !== null
    }
  },
  mounted() {
    this.modalProps.titleProps.text = this.getModalTitle()
    this.modalProps.buttonProps.confirmProps.text = this.getModalButtonText()
  },
  methods: {
    ...mapActions('EmailNotification',['createNewEmailNotifications', 'requestEmailNotifications', 'updateEmailNotifications']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    i18n,
    getModalTitle() {
      return this.isEditEmailNotification ? 'EMAIL_NOTIFICATIONS__MODAL_EDIT_TITLE' : 'EMAIL_NOTIFICATIONS__MODAL_ADD_TITLE'
    },
    getModalButtonText() {
      return this.isEditEmailNotification ? 'EMAIL_NOTIFICATIONS__MODAL_EDIT_BUTTON' : 'EMAIL_NOTIFICATIONS__MODAL_ADD_BUTTON'
    },
    open() {
      if(this.settings) {
        this.emailNotificationSettings.forEach((item) => {
          item.value = this.settings[item.key]
        })
      }

      this.emailNotificationAddress = this.isEditEmailNotification ? this.emailAddress : ''

      this.oldEmailNotificationAddress = this.emailNotificationAddress

      if(!this.isEditEmailNotification) {
        this.emailNotificationSettings.forEach(item => item.value = true)
      }

      this.modalRef.open()
    },
    async onSubmit(close) {
      await this.setLoadingStatus(true)
      const form = this.validatorRef
      const success = await form.validate()

      const settings = this.emailNotificationSettings.map(({value, key}) => {
        return { key, value }
      })

      const payload = {
        email: this.emailNotificationAddress,
        settings
      }

      if(success) {
        let result
        if(!this.isEditEmailNotification) {
          result = await this.createNewEmailNotifications(payload)

          if(!result.error) {
            this.toast({ msg: i18n('EMAIL_NOTIFICATIONS__CREATED_MESSAGE'), title: i18n('EMAIL_NOTIFICATIONS__CREATED_TITLE') })
          }
        } else {
          payload.oldEmail = this.oldEmailNotificationAddress

          result = await this.updateEmailNotifications(payload)

          if(!result.error) {
            this.toast({ msg: i18n('EMAIL_NOTIFICATIONS__UPDATED_MESSAGE'), title: i18n('EMAIL_NOTIFICATIONS__UPDATED_TITLE') })
          }
        }

        if(!result.error) {
          this.requestEmailNotifications()
          close()
          return
        }
        this.manageError(result.error.type)
      }

      this.setLoadingStatus(false)
    },
    manageError(errorType) {
      const toastMessage = EMAIL_NOTIFICATION_TOAST_MESSAGES.get(errorType)
      const message = i18n(toastMessage.msg)
      const title = i18n(toastMessage.title)
      this.toast({ msg: message, title })
    },
    getConfigurationCheckboxLabel(name) {
      return i18n(EMAIL_NOTIFICATION_SETTINGS.get(name).label)
    },
    getConfigurationCheckboxRequired(name) {
      return EMAIL_NOTIFICATION_SETTINGS.get(name).required
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    }
  }
}
</script>
