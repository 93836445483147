import { ERROR_PERMISSIONS_CODES, LOADING_STATES } from '_utils_/constants'
import { createShipment, getShipmentInfo, createPickup as createShipmentPickup, sendShipmentEmailLabel, cancelPickup } from '_api_/shipment.api'
import { checkZipCode as checkAddressExists } from '_api_/address.api'
import { getPracticeDataByCaseId } from '_api_/practices.api'

export const mutationTypes = {
  setShipmentInformation: 'setShipmentInformation',
  setLoadingStatus: 'setLoadingStatus',
  setPracticeData: 'setPracticeData',
  setError: 'setError',
  clearShipmentInformation: 'clearShipmentInformation',
  resetPickup: 'resetPickup'
}
export const state = {
  shipmentInformation: {
    id: '',
    shippingLabelUrl: '',
    pickup: null,
    trackingUrl: '',
    trackingCode: '',
    trackingStatus: ''
  },
  practiceData: null,
  selectedScheduleDay: {},
  //TODO: Review this state and the display logic we implement with it
  shippingLoadingStatus: LOADING_STATES.idle,
  getPracticeDataLoadingStatus: LOADING_STATES.idle,
  createPickupLoadingStatus: LOADING_STATES.idle,
  createPickupError: null
}

export const mutations = {
  [mutationTypes.clearShipmentInformation](state) {
    state.shipmentInformation = null
  },
  [mutationTypes.setShipmentInformation](state, { id, shippingLabelUrl, pickup = null, trackingUrl, trackingCode, trackingStatus }) {
    state.shipmentInformation = {
      id,
      shippingLabelUrl,
      pickup,
      trackingUrl,
      trackingCode,
      trackingStatus
    }
  },
  [mutationTypes.setLoadingStatus](state, { type, status }) {
    state[`${type}LoadingStatus`] = status
  },
  [mutationTypes.setError](state, { type, status }) {
    state[`${type}Error`] = status
  },
  [mutationTypes.setPracticeData](state, payload) {
    state.practiceData = payload
  },
  [mutationTypes.setAvailableDays](state, payload) {
    state.availableDays = [...payload]
  },
  [mutationTypes.resetPickup](state) {
    state.shipmentInformation.pickup = null
  }
}

export const actions = {
         async createShipment({ commit }, payload) {
           commit(mutationTypes.setLoadingStatus, {
             type: 'shipping',
             status: LOADING_STATES.pending
           })
           try {
             const shipmentInformation = await createShipment(payload)
             commit(mutationTypes.setShipmentInformation, {
               id: shipmentInformation.id,
               shippingLabelUrl: shipmentInformation.shippingLabelUrl
             })
             commit(mutationTypes.setLoadingStatus, {
               type: 'shipping',
               status: LOADING_STATES.resolved
             })
           } catch (err) {
             commit(mutationTypes.setLoadingStatus, {
               type: 'shipping',
               status: LOADING_STATES.rejected
             })
             return {
               error: true,
               errorCode: err?.response?.data?.errorCode
             }
           }
         },

         async createPickup({ commit, state }, payload) {
           commit(mutationTypes.setLoadingStatus, {
             type: 'createPickup',
             status: LOADING_STATES.pending
           })
           try {
             const {
               trackingUrl,
               trackingCode,
               trackingStatus
             } = state.shipmentInformation
             const response = await createShipmentPickup(payload)

             commit(mutationTypes.setShipmentInformation, {
               id: response.shipment.id,
               shippingLabelUrl: response.shipment.shippingLabelUrl,
               pickup: response,
               //keep these two values as they were
               trackingUrl,
               trackingCode,
               trackingStatus
             })
             commit(mutationTypes.setLoadingStatus, {
               type: 'createPickup',
               status: LOADING_STATES.resolved
             })
           } catch (err) {
             const error = {
               errorCode: err?.response?.data?.errorCode,
               errorCategory: err?.response?.data?.errorCategory,
               errorStatus: err?.response?.status,
               type:
                 err?.response?.status === 403
                   ? ERROR_PERMISSIONS_CODES.forbidden
                   : null
             }
             commit(mutationTypes.setLoadingStatus, {
               type: 'createPickup',
               status: LOADING_STATES.rejected
             })
             commit(mutationTypes.setError, {
               type: 'createPickup',
               status: error
             })
             return { error }
           }
         },

         async getCaseShipment({ commit }, payload) {
           commit(mutationTypes.clearShipmentInformation)
           commit(mutationTypes.setLoadingStatus, {
             type: 'shipping',
             status: LOADING_STATES.pending
           })
           try {
             const shipmentInformation = await getShipmentInfo(payload)
             commit(mutationTypes.setShipmentInformation, {
               id: shipmentInformation.id,
               shippingLabelUrl: shipmentInformation.shippingLabelUrl,
               pickup: shipmentInformation.pickup,
               trackingUrl: shipmentInformation.trackingUrl,
               trackingCode: shipmentInformation.trackingCode,
               trackingStatus: shipmentInformation.trackingStatus
             })
             commit(mutationTypes.setLoadingStatus, {
               type: 'shipping',
               status: LOADING_STATES.resolved
             })
           } catch (err) {
             commit(mutationTypes.setLoadingStatus, {
               type: 'shipping',
               status: LOADING_STATES.rejected
             })
             return {
               error: {
                 type:
                   err?.response?.status === 403
                     ? ERROR_PERMISSIONS_CODES.forbidden
                     : null
               }
             }
           }
         },
         async sendShipmentEmail({ commit }, payload) {
           commit(mutationTypes.setLoadingStatus, {
             type: 'send',
             status: LOADING_STATES.pending
           })
           try {
             const response = await sendShipmentEmailLabel(payload)
             commit(mutationTypes.setLoadingStatus, {
               type: 'send',
               status: LOADING_STATES.resolved
             })
             return response
           } catch (err) {
             commit(mutationTypes.setLoadingStatus, {
               type: 'send',
               status: LOADING_STATES.rejected
             })
             return {
               error: {
                 type:
                   err?.response?.status === 403
                     ? ERROR_PERMISSIONS_CODES.forbidden
                     : null
               }
             }
           }
         },
         async getPracticeData({ commit }, caseId) {
           commit(mutationTypes.setLoadingStatus, {
             type: 'getPracticeData',
             status: LOADING_STATES.pending
           })
           try {
             const response = await getPracticeDataByCaseId(caseId)
             const existsResponse = await checkAddressExists(response)
             if (existsResponse.errors) throw new Error()
             commit(mutationTypes.setPracticeData, response)
             commit(mutationTypes.setLoadingStatus, {
               type: 'getPracticeData',
               status: LOADING_STATES.resolved
             })
           } catch (error) {
             return commit(mutationTypes.setLoadingStatus, {
               type: 'getPracticeData',
               status: LOADING_STATES.rejected
             })
           }
         },
         async cancelPickup({ commit }, pickupId) {
           commit(mutationTypes.setLoadingStatus, {
             type: 'create',
             status: LOADING_STATES.pending
           })
           try {
             const result = await cancelPickup(pickupId)
             if (result) {
               commit(mutationTypes.resetPickup)
             }
             commit(mutationTypes.setLoadingStatus, {
               type: 'create',
               status: LOADING_STATES.resolved
             })
           } catch (err) {
             commit(mutationTypes.setLoadingStatus, {
               type: 'create',
               status: LOADING_STATES.rejected
             })
             return {
               error: {
                 type:
                   err?.response?.status === 403
                     ? ERROR_PERMISSIONS_CODES.forbidden
                     : null
               }
             }
           }
         }
       }

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
