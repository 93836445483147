<template>
  <section class="scan-intake-history-table">
    <sdc-table
      name="scan-intake-history-table"
      v-bind="tableProps"
    >
      <template #date="data">
        {{ getFormatedDate(data.item.date) }}
      </template>
      <template #side="data">
        <a
          href=""
          class="scan-intake-history-table__file-link"
          target="__blank"
          @click="handleAssetClick(data.item, $event)"
        >
          <sdc-image
            name="file-icon"
            class="scan-intake-history-table__file-icon"
            :src="fileIcon"
          />
          {{ data.item.fileName }}
        </a>
      </template>
    </sdc-table>
  </section>
</template>

<script>
import { convertUtcToBrowserTime } from '_utils_/dateHelpers'
import { i18n } from '_utils_/i18n'
import { HISTORY_DATE_STRING_FORMAT } from '_utils_/scanIntakeConstants'
import SdcImage from '_atoms_/SdcImage'
import SdcTable from '_molecules_/SdcTable'
import {
  TABLE_FIELDS,
  FIELDS_TO_SORT
} from './constants'

export default {
  name: 'ScanIntakeHistoryTable',
  components: {
    SdcImage,
    SdcTable
  },
  props: {
    historyList: {
      type: Array,
      required: true
    }
  },
  computed: {
    fileIcon() {
      return require('_assets_/icon-file.svg')
    },
    tableProps() {
      return {
        fields: TABLE_FIELDS,
        items: this.historyList,
        sortBy: FIELDS_TO_SORT.sortBy,
        sortDesc: true,
        enableSelection: false
      }
    }
  },
  methods: {
    i18n,
    getFormatedDate(date) {
      return convertUtcToBrowserTime(date, HISTORY_DATE_STRING_FORMAT)
    },
    handleAssetClick(file, event) {
      event.preventDefault()
      this.$emit('on-asset-download', file)
    },
    hasFile(file) {
      return (file !== null && file.fileName)
    }
  },
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.scan-intake-history-table {
  .sdc-table {
    tr, td, th {
      border-bottom: 0 !important;
      border-top: 0 !important;
      padding-top: 0;
    }

    th {
      font-size: $font-size-base;
      color: $black;
    }
  }

  &__file-icon {
    margin-right: .25rem;
    margin-left: -.25rem;
  }
  &__file-link {
    align-items: center;
    color: $gray-600;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    text-decoration: none;
    margin-bottom: .5rem;
    > .b-icon {
      margin-right: .75rem;
    }
    &:hover {
      color: $purple;
      text-decoration: none;
      > .b-icon {
        fill: $purple;
      }
    }
  }
}
</style>
