import store from '_store_'
import ErrorException from './ErrorException'

class HttpException extends ErrorException{
  constructor(error) {
    const { status } = error?.response

    super('HttpException', status, error)

    this.httpStatusCode = status

    this.throwError()
  }

  throwError() {
    super.throwError()

    const _self = this

    const httpMethods = {
      404: _self.throwNotFoundError,
      403: _self.throwForbiddenError
    }

    httpMethods[_self.httpStatusCode]() || null
  }

  throwNotFoundError() {
    store.commit('ErrorMgr/setNotFoundError')
  }

  throwForbiddenError() {
    store.commit('ErrorMgr/setForbiddenError')
  }
}

export default HttpException
