<template>
  <div
    :id="`practice-item-${id}`"
    class="practice-item"
  >
    <div class="practice-item__name">
      {{ name }}
    </div>
    <div class="practice-item__buttons">
      <sdc-button
        v-if="canEditPractices"
        type="link"
        class="practice-item__action-button"
        @click="showModal"
      >
        {{ i18n('PRACTICES__EDIT') }}
      </sdc-button>
      <sdc-button
        v-if="isSuperuser"
        type="link"
        class="practice-item__action-button"
        @click="showRemoveModal"
      >
        {{ i18n("PRACTICES__REMOVE") }}
      </sdc-button>
    </div>
    <div
      v-if="shouldShowBillingEntity"
      class="practice-item__billing-entity"
    >
      {{ i18n('PRACTICES__LABEL_BILLING_ENTITY') }}
      {{ billingEntity }}
    </div>
    <div
      v-if="shouldShowBoName"
      class="practice-item__bo-name"
    >
      {{ boName }}
    </div>
    <div class="practice-item__address">
      <div>
        {{ streetAddress1 }}
      </div>
      <div>
        {{ streetAddress2 }}
      </div>
      <div>{{ city }} {{ state }} {{ zipCode }}</div>
      <div>
        {{ countryName }}
      </div>
    </div>
    <div class="practice-item__phone-number">
      {{ phoneNumber }}
    </div>
    <div
      v-if="fullWebPageUrl"
      class="practice-item__web-page"
    >
      <a
        :href="fullWebPageUrl"
        target="_blank"
      >
        {{ webPage }}
      </a>
    </div>
    <div
      v-if="shouldShowSignRequestedArea"
      class="practice-item__signed-terms"
    >
      <div>
        {{ i18n('PRACTICES__LABEL_SIGN_REQUESTED') }}
        {{ showIfSignWasRequested }}
      </div>
      <div v-if="shouldShowSignRequestedByField">
        {{ i18n('PRACTICES__LABEL_SIGN_REQUESTED_BY') }}
        {{ contractSignRequestedBy }}
      </div>
      <div>
        {{ i18n('PRACTICES__LABEL_SIGN_STATUS') }}
        {{ showPracticeStatusMessage }}
      </div>
    </div>
    <div
      v-if="shouldShowPracticeIdInDetail"
      class="practice-item__practice-id"
    >
      {{ i18n('PRACTICES__LABEL_PRACTICE_ID') }}
      {{ id }}
    </div>
    <practice-modal
      ref="practiceModal"
      :practice-id="id"
    />
    <remove-practice-modal
      ref="removePracticeModal"
      :practice-id="id"
      :practice-name="name"
    />
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { TERMS_CONTRACT_SIGN_STATUS } from '_utils_/constants'
import PracticeModal from '_molecules_/PracticeModal'
import { mapGetters } from 'vuex'
import Location from '_utils_/Location'
import { convertToCentralTime } from '_utils_/dateHelpers'
import RemovePracticeModal from '_molecules_/RemovePracticeModal'
import SdcButton from '_atoms_/SdcButton'
import {
  localeConstants,
  snackbarDefaultSettings
} from '@/utils/constants'

export default {
  name: 'PracticeItem',
  components: {
    PracticeModal,
    RemovePracticeModal,
    SdcButton
  },
  props: {
    id: { type: String, default: '' },
    name: { type: String, default: '' },
    boName: { type: String, default: '' },
    isIndividual: { type: Boolean, default: true },
    streetAddress1: { type: String, default: '' },
    streetAddress2: { type: String, default: '' },
    city: { type: String, default: '' },
    state: { type: String, default: '' },
    zipCode: { type: String, default: '' },
    countryCode: { type: String, default: '' },
    phoneNumber: { type: String, default: '' },
    webPage: { type: String, default: '' },
    storeNumber: { type: String, default: '' },
    contractSignRequested: { type: Boolean },
    contractSignStatus: { type: String, default: '' },
    contractSignedBy: { type: String, default: '' },
    contractSignRequestedBy: { type: String, default: '' },
    contractSignedDate: { type: String, default: '' },
    billingEntity: { type: String, default: '' }
  },
  data() {
    return {
      modalShow: false
    }
  },
  computed: {
    ...mapGetters('Auth', ['canEditPractices', 'isSuperuser']),
    countryName() {
      return this.countryCode
        ? i18n(`LABEL__COUNTRY_${this.countryCode.toUpperCase()}`)
        : ''
    },
    fullWebPageUrl() {
      return this.webPage ? `http://${this.webPage}` : null
    },
    shouldShowBoName() {
      return !this.isIndividual && this.boName !== ''
    },
    shouldShowSignRequestedArea() {
      return (
        I18nConfig.showTermsAndConditions &&
        this.isSuperuser &&
        this.contractSignRequested !== null &&
        !this.boName
      )
    },
    shouldShowSignRequestedByField() {
      return this.contractSignRequested
    },
    shouldShowPracticeIdInDetail() {
      return this.isSuperuser && this.isDefaultCountry
    },
    isDefaultCountry() {
      return this.countryCode === localeConstants.defaultCountryCode
    },
    showIfSignWasRequested() {
      return this.contractSignRequested
        ? i18n('PRACTICES__LABEL_SIGN_REQUESTED_POSITIVE')
        : i18n('PRACTICES__LABEL_SIGN_REQUESTED_NEGATIVE')
    },
    showPracticeStatusMessage() {
      var prefixKey = 'PRACTICES__LABEL_SIGN_STATUS_'
      var message = i18n(prefixKey.concat(this.contractSignStatus))

      if (this.contractSignStatus === TERMS_CONTRACT_SIGN_STATUS.signed) {
        const date = this.contractSignedDate
        message += ` ${i18n('PRACTICES__LABEL_SIGN_STATUS_SIGNED_ON')}
                     ${date ? this.formatDate(date) : ''}`
      }
      return message
    },
    shouldShowBillingEntity() {
      return this.isSuperuser && this.isDefaultCountry && this.billingEntity
    }
  },
  methods: {
    i18n,
    showModal() {
      this.countryCode === Location.countryCode
        ? this.$refs.practiceModal.open()
        : this.toast({
            msg: i18n('PRACTICES__EDIT_PRACTICE_NOT_ALLOWED'),
            title: i18n('PRACTICES__TITLE')
          })
    },
    showRemoveModal() {
      this.$refs.removePracticeModal.open()
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    formatDate(date) {
      return `${convertToCentralTime(date).format('MM/DD/YYYY hh:mm A')} (CT)`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop: map-get($sdc-breakpoints, 'desktop');

.practice-item {
  border: 2px dashed $gray-88;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  min-height: 270px;
  word-wrap: break-word;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    min-height: 300px;
  }

  &__name {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
  }
  &__bo-name {
    color: $gray-600;
    font-size: $font-size-sm;
    padding-top: 12px;
  }
  &__address {
    padding-top: 20px;
    color: $gray-600;
    font-size: $font-size-sm;
  }
  &__phone-number {
    padding-top: 12px;
    color: $gray-600;
    font-size: $font-size-sm;
  }
  &__billing-entity {
    padding-top: 12px;
    color: $gray-600;
    font-size: $font-size-sm;
  }
  &__web-page {
    padding-top: 12px;
    color: $gray-600;
    font-size: $font-size-sm;
    text-decoration: underline;
  }
  &__signed-terms {
    padding-top: 20px;
    color: $gray-600;
    font-size: $font-size-sm;
  }
  &__practice-id {
    padding-top: 10px;
    color: $gray-600;
    font-size: $font-size-sm;
  }
  &__buttons {
     margin-top: 10px;
     justify-content: left;
     display: flex;
  }
  &__action-button {
    margin-right: 14px;
    border-radius: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-family: "Sofia Pro Bold";
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    line-height: 1.125rem;
    color: $indigo;
    display: block;
    outline: none;
    &:hover {
     text-decoration: none;
    }
  }
}
</style>
