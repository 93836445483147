import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

import VuexPersistence from 'vuex-persist'

/**
 * Each store should define a keysToPersist property object inside
 * object definition to be exported in order to be persisted.
 * keysToPersist will receive only two values:
 *   - '*' : String - will persist all state variables
 *   - [...]: Array - will only persist the specified state variables
 */
const getPersistedModules = (stores = {}) => {
  const persistedStores = {}
  Object.keys(stores).forEach((store) => {
    const keysToPersist = modules[store]?.keysToPersist || null
    let temporalStore = null

    if (keysToPersist === '*') {
      temporalStore = {
        ...stores[store]
      }
    } else if (keysToPersist?.length > 0) {
      temporalStore = {}

      keysToPersist.forEach((persistedKey) => {
        temporalStore[persistedKey] = stores[store][persistedKey]
      })
    }

    if (temporalStore !== null) {
      persistedStores[store] = JSON.parse(JSON.stringify(temporalStore))
    }
  })
  return persistedStores
}

const vuexStorage = new VuexPersistence({
  key: 'pp-cc',
  storage: window.sessionStorage,
  reducer: getPersistedModules
})

Vue.use(Vuex)

// Note: to use modules into componets, use the filename capitalized
// e.g: Module1
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexStorage.plugin],
  modules
})
