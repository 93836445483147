<template>
  <section class="additional-files">
    <sdc-loading
      v-if="loading"
      type="element"
    />
    <sdc-case-additional-files-upload
      v-if="showUploadSection"
      @onFilesUploaded="onFilesUploaded"
    />
  </section>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { mapMutations, mapActions, mapState } from 'vuex'
import SdcLoading from '_atoms_/SdcLoading'
import SdcCaseAdditionalFilesUpload from '_molecules_/SdcCaseAdditionalFilesUpload'

export default {
  name: 'AdditionalFiles',
  components: {
    SdcLoading,
    SdcCaseAdditionalFilesUpload
  },
  data() {
    return {
      isEnabledUploadFiles: false
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('CaseDetails', ['details']),
    showAdditionalFilesBtn() {
      return !this.isEnabledUploadFiles
    },
    showUploadSection() {
      return (
        this.isEnabledUploadFiles || !this.loading
      )
    }
  },
  destroyed() {
    this.clearFiles()
    this.setAddingFilesStatus(false)
  },
  methods: {
    i18n,
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    ...mapMutations('CaseUploadAdditionalFiles', ['clearFiles', 'setAddingFilesStatus']),
    ...mapActions('CaseDetails', ['getPatientDetails']),
    enableUploadFiles() {
      this.isEnabledUploadFiles = true
    },
    onFilesUploaded() {
      this.setLoadingStatus(false)
      this.isEnabledUploadFiles = true
      this.getPatientDetails({
        id: this.$route.params.id,
        shouldSleepRequest: true
      })
    }
  }
}

</script>
