import { removePipes } from '_utils_/stringHelpers'

const getChiefComplaintSubmittingDoctor = (submitInfo, questionInfo) => {
  // FIXME: temporary workaround from EP-1013
  return `${submitInfo.submittingDoctor.fullName} | ${removePipes(questionInfo.chiefComplaintValue)}`
}

export {
  getChiefComplaintSubmittingDoctor
}
