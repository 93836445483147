<template>
  <div class="email-form">
    <LegalTooltip
      :name="`${name}-email`"
      text-key="EMAIL_FORM__LEGAL__TEXT"
    >
      <sdc-title level="4">
        {{ title }}
      </sdc-title>
    </LegalTooltip>
    <div
      v-if="hasSubtitle"
      class="email-form__subtitle"
    >
      {{ subtitle }}
    </div>
    <slot name="Message" />
    <validated-email-input
      v-model="model.emailAddress"
      :max-length="maxLengths.emailAddress"
      :name="`${name}-email`"
      :label="label"
      :disabled="disabled"
      :rules="emailFieldRules"
      :on-blur-email-check="onBlurEmailCheck"
    />
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import ValidatedEmailInput from '_molecules_/ValidatedEmailInput'
import { inputMaxLengths } from '_utils_/constants'
import LegalTooltip from '@/components/molecules/LegalTooltip'

export default {
  name: 'EmailForm',
  components: {
    ValidatedEmailInput,
    SdcTitle,
    LegalTooltip
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    disabled:{
      type:Boolean,
      required: false
    },
    emailFieldRules: {
      type: String,
      required: true
    },
    onBlurEmailCheck: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      maxLengths: inputMaxLengths.patientInfo,
      model: this.$attrs.value
    }
  },
  computed: {
    hasSubtitle() {
      return this.subtitle.length > 0
    }
  },
  watch: {
    model(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.email-form {
  display: flex;
  flex-direction: column;

  &__subtitle {
    font-size: $font-size-md;
    line-height: 27px;
    margin-bottom: 20px;
    color: $gray-600;
  }
}
</style>
