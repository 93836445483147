import languages from './languages'
import { localeConstants } from '_utils_/constants'
const LANG_SEPARATOR = '_'
const defaultLocale = formatLanguage(localeConstants.default)
let currentLocale = defaultLocale

export const setLanguage = (lang) => {
  currentLocale = formatLanguage(lang)
}

export const i18n = (
  key,
  params = [],
  { useDefaultLocaleIfNotFound } = { useDefaultLocaleIfNotFound: true }
) => {
  if (!languages[currentLocale]) {
    currentLocale = defaultLocale
  }

  if (!key || key.length === 0) return ''

  let keyText = languages[currentLocale][key]

  if (!keyText) {
    if (!useDefaultLocaleIfNotFound) {
      return ''
    }

    keyText = languages[defaultLocale][key] || ''
  }

  if (!params?.length) return keyText

  params.forEach((param, index) => {
    keyText = keyText.replace(new RegExp(`%${index + 1}`, 'g'), param)
  })

  keyText = keyText.replace(
    new RegExp('%arr%', 'g'),
    toUserFriendlyOptionsString(params)
  )

  return keyText
}

function formatLanguage(lang) {
  return lang.replace(localeConstants.SEPARATOR, LANG_SEPARATOR)
}

export function toUserFriendlyOptionsString(array) {
  if (!array?.length) {
    return ''
  }

  if (array.length === 1) {
    return `${array[0]}`
  }

  const or = (languages[currentLocale] || languages[defaultLocale])[
    'LOWERCASE_OR'
  ]
  return `${array.slice(0, -1).join(', ')}, ${or} ${array.slice(-1)}`
}
