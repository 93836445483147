export default class expirationDateFieldsProcessor {
  constructor(fieldGenericId) {
    this.inputId = fieldGenericId
    this.fieldRules = {
      month: '',
      year: ''
    }

    this.setCustomRules()
  }

  getFieldsNames() {
    return {
      month: `${this.inputId}-month`,
      year: `${this.inputId}-year`,
    }
  }

  getFieldsRules() {
    return {...this.fieldRules}
  }

  setCustomRules() {
    const intitializeCustomRules = { month: '', year: '' }
    Object.keys(this.fieldRules).forEach(field => {
      this.fieldRules[field] = this.getCustomRules(intitializeCustomRules[field], field)
    })
  }

  getCustomRules(rules, type) {
    const { month, year } = this.getFieldsNames()
    const isValidDateRule = `isValidExpirationDate:@${month},@${year}`
    const isMaxYearRule = `isMaxYear:@${month},@${year}`
    const BASE_RULES = {
      month: `required|integer|digits:2|minValue:1|maxValue:12|${isValidDateRule}`,
      year: `required|integer|digits:4|${isValidDateRule}|${isMaxYearRule}`
    }
    const rule = (rules && rules.length !== 0) ?
      `|${this.processRules(rules)}` : ''

    return `${BASE_RULES[type]}${rule}`
  }

  processRules(rules) {
    const { month, year } = this.getFieldsNames()
    const processedRules = rules.split('|').map(rule => {
      let [ruleName, fields] = rule.split(':')
      let replacedFields = ''

      if (fields) {
        replacedFields = ':' + fields.replace('@month', `@${month}`)
          .replace('@year', `@${year}`)
      }

      return `${ruleName}${replacedFields}`
    })

    return processedRules.join('|')
  }
}
