<template>
  <section class="scan-intake">
    <div>
      <sdc-loading
        v-if="isLoading"
        type="element"
      />
      <scan-intake-history-files
        v-if="showHistorySection"
        :history-list="historyList"
      />
      <sdc-button
        v-if="showAdditionalFilesBtn"
        @click="enableUploadFiles"
      >
        {{ i18n('SCAN_INTAKE_HISTORY_SECTION__ADD__BUTTON') }}
      </sdc-button>
      <scan-intake-upload-files
        v-if="showUploadSection"
        @onFilesUploaded="onFilesUploaded"
      />
    </div>
  </section>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { mapMutations, mapActions, mapState } from 'vuex'
import SdcButton from '_atoms_/SdcButton'
import SdcLoading from '_atoms_/SdcLoading'
import ScanIntakeHistoryFiles from '_molecules_/ScanIntakeHistoryFiles'
import ScanIntakeUploadFiles from '_molecules_/ScanIntakeUploadFiles'
import { retakeScanValidation } from '_utils_/scansHelper'

export default {
  name: 'ScanIntake',
  components: {
    SdcButton,
    SdcLoading,
    ScanIntakeHistoryFiles,
    ScanIntakeUploadFiles
  },
  data() {
    return {
      isEnabledUploadFiles: false
    }
  },
  computed: {
    ...mapState('ScanIntake', ['historyList', 'isLoading']),
    ...mapState('CaseDetails', ['details', 'caseId']),
    showHistorySection() {
      const { order, status } = this.details

      return (
        !this.isLoading &&
        this.historyList.length > 0 &&
        !retakeScanValidation(order.productCode, status)
      )
    },
    showAdditionalFilesBtn() {
      return !this.isEnabledUploadFiles && this.showHistorySection
    },
    showUploadSection() {
      const { order, status } = this.details

      return (
        this.isEnabledUploadFiles ||
        (!this.isLoading &&
          (this.historyList.length === 0 || retakeScanValidation(order.productCode, status))
        )
      )
    }
  },
  async mounted() {
    this.validateClearHistoryList()

    await this.getScanHistory(this.details.caseNumber)
  },
  destroyed() {
    this.clearAddedFiles()
    this.setAddingFiles(false)
    this.validateClearHistoryList()
  },
  methods: {
    i18n,
    ...mapMutations('ScanIntake', [
        'clearAddedFiles',
        'setAddingFiles',
        'clearHistoryList'
      ]),
    ...mapActions('ScanIntake', ['getScanHistory']),
    ...mapActions('CaseDetails', ['getPatientDetails']),
    enableUploadFiles() {
      this.isEnabledUploadFiles = true
    },
    onFilesUploaded() {
      this.isEnabledUploadFiles = false
      this.$emit('onFilesUploaded')
    },
    validateClearHistoryList() {
      if (this.$route.params.id !== this.caseId) {
        this.clearHistoryList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_section-panel';

.scan-intake {
  @extend .section-panel-plain;
  padding: 2.5rem;
}
</style>

