import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

export const getCaseHistory = async ( uuid ) => {
  try {
    const response = await api.get(`/case/${uuid}/history`)
    return response.data
  } catch (error) {
    Logger.error('CaseHistory API getCaseHistory', error)
    throw error
  }
}
