export const mutationTypes = {
  saveCurrentStep: 'saveCurrentStep',
  resetCurrentStep: 'resetCurrentStep'
}

export const state = {
  currentStep: 0
}

export const mutations = {
  [mutationTypes.saveCurrentStep](state, payload) {
    state.currentStep = payload
  },
  [mutationTypes.resetCurrentStep](state) {
    state.currentStep = 0
  }
}

export const getters = {}

export const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  keysToPersist: '*'
}
