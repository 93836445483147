<template>
  <div
    :id="`sdc-checkbox-group-${name}`"
    class="sdc-checkbox-group"
  >
    <validation-provider
      v-slot="{ errors }"
      ref="sdcCheckboxGroupProvider"
      :name="`${name}-0`"
      :rules="rules"
    >
      <template v-for="(option, key) in options">
        <smile-checkbox
          :id="`${name}-${key}`"
          :key="key"
          v-model="newValues"
          :name="`${name}-${key}`"
          :native-value="option.value"
          :disabled="option.disabled || null"
          @input="setValue"
        >
          {{ option.text }}
        </smile-checkbox>
      </template>
      <span
        v-if="errors[0]"
        class="invalid-feedback d-block"
      >{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { SmileCheckbox } from 'sdc-component-library'

export default {
  name: 'SdcCheckboxGroup',
  components: {
    SmileCheckbox,
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newValues: []
    }
  },
  computed: {
    dataValue() {
      return this.$attrs.value || []
    }
  },
  mounted() {
    const currentValue = this.$attrs.value
    this.$refs.sdcCheckboxGroupProvider.syncValue(currentValue)
    this.newValues = currentValue
  },
  methods: {
    setValue(selectedValues) {
      this.validate(selectedValues)
      this.$emit('input', selectedValues)
    },
    validate(value) {
      const provider = this.$refs.sdcCheckboxGroupProvider

      provider.syncValue(value)
      provider.validate()
    }
  }
}
</script>

