<template>
  <b-tooltip
    :label="message"
    type="is-dark"
  >
    <button
      :id="`paginator-button-${name}`"
      :name="name"
      :class="getButtonClass()"
      @click="click"
    >
      <img
        :src="imgSrc"
        :alt="altText"
      >
    </button>
  </b-tooltip>
</template>

<script>
import { i18n } from '_utils_/i18n'

export default {
  name: 'PaginatorButton',
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
      required: true
    },
    altText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      message: i18n(`PAGINATOR__TOOLTIP_${this.name.toUpperCase()}_BUTTON`)
    }
  },
  computed: {
    imgSrc() {
      const iconType = this.active ? 'purple' : 'gray'

      return require(`_assets_/icon-${this.name}-page-${iconType}.svg`)
    }
  },
  methods: {
    i18n,
    getButtonClass() {
      return {
        'paginator-button': true,
        'paginator-button--inactive': !this.active,
        'paginator-button--active': this.active
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$active-color: #6816EB;

.b-tooltip {
  margin-left: 12px;

  .paginator-button {
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    outline: none;
    width: 40px;
    border: 2px solid $extra-light-gray;
    padding: 0;
    padding-left: 5px;

    img {
      display: flex;
      align-self: center;
    }

    &--active {
      color: $active-color;

      &:active {
        border: 2px solid $active-color;
      }

      @media (hover: hover) {
        &:hover {
          border: 2px solid $active-color;
        }
      }
    }

    &--inactive {
      color: $extra-light-gray;
      cursor: auto;
    }
  }
}
</style>
