<template>
  <validation-provider
    v-slot="{ errors }"
    ref="questionTeethImagesProvider"
    :name="getValidationName()"
    :rules="rules"
  >
    <div
      :id="`question-teeth-images-${name}`"
      class="question-teeth-images"
    >
      <div class="upper-teeth-section">
        <span class="upper-teeth-section__title">{{ i18n('SCAN_INTAKE_SECTION__UPLOAD__UPPER__TITLE') }}</span>
        <div class="upper-teeth-section__panel">
          <svg
            class="upper-teeth"
            type="upper"
            width="264"
            height="258"
            viewBox="0 0 264 258"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g value="18">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.3387 182.654H18.3844C11.6635 182.654 6.21484 188.102 6.21484 194.824V206.533C6.21484 213.255 11.6635 218.704 18.3844 218.704H24.3387C31.0596 218.704 36.509 213.255 36.509 206.533V194.824C36.509 188.102 31.0596 182.654 24.3387 182.654Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M18.546 197.174V198.714L20.086 198.098V205H21.808V195.732L18.546 197.174Z"
                fill="#666666"
              />
            </g>
            <g value="17">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.6609 140.278L23.7289 139.767C17.0326 139.191 11.136 144.151 10.5588 150.847L9.00553 168.879C8.42904 175.575 13.3889 181.472 20.086 182.049L26.018 182.56C32.7142 183.137 38.6108 178.175 39.1873 171.479L40.7413 153.448C41.3186 146.751 36.3572 140.855 29.6609 140.278Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M26.8349 166V164.362H23.7409L26.0089 161.772C26.5829 161.128 26.9889 160.316 26.9889 159.434C26.9889 157.796 25.6449 156.676 24.0349 156.676C22.4109 156.676 21.2069 157.712 20.9409 159.378L22.5369 159.672C22.6489 158.888 23.2229 158.342 23.9649 158.342C24.7489 158.342 25.2809 158.818 25.2809 159.434C25.2809 159.854 25.0429 160.26 24.7209 160.638L20.9829 165.118V166H26.8349Z"
                fill="#666666"
              />
            </g>
            <g value="16">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M35.8077 106.147L31.7153 105.65C25.0429 104.842 18.9785 109.594 18.1688 116.267L16.9842 126.04C16.1753 132.712 20.9289 138.777 27.6013 139.585L31.6937 140.083C38.3662 140.892 44.4313 136.138 45.2395 129.466L46.4248 119.694C47.2337 113.021 42.4801 106.956 35.8077 106.147Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M30.7907 122.926C32.0367 122.884 32.2187 123.864 32.2187 124.102C32.2187 124.9 31.5467 125.46 30.6787 125.46C30.0487 125.46 29.4607 124.984 29.1947 124.438C29.0687 124.508 27.8227 125.124 27.6967 125.194C28.1867 126.258 29.2787 127.042 30.5387 127.126C32.3167 127.252 33.8987 126.188 34.0667 124.522C34.1647 123.542 33.5067 122.394 32.5407 122.03C33.1987 121.652 33.6747 120.994 33.6747 120.238C33.6747 118.88 32.5967 117.76 31.0287 117.676C29.8387 117.606 28.7747 118.166 28.2567 119.034L29.6007 119.916C29.9647 119.454 30.4827 119.286 31.0147 119.356C31.6727 119.482 31.8267 120.056 31.8267 120.392C31.8267 120.63 31.6587 121.414 30.7907 121.4H29.8107V122.926H30.7907Z"
                fill="#666666"
              />
            </g>
            <g value="15">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M47.4353 77.0997V77.0997C41.0546 74.9877 34.1698 78.4482 32.0577 84.8289L30.1012 90.7386C27.9892 97.1193 31.4497 104.004 37.8312 106.116C44.2119 108.227 51.0967 104.767 53.2088 98.3862L55.1645 92.4773C57.2766 86.0958 53.816 79.211 47.4353 77.0997Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M41.1096 91.514L43.1396 88.322V91.514H41.1096ZM46.0656 93.012V91.514H44.8476V85.844H43.1816L38.6176 93.012H43.1396V95H44.8476V93.012H46.0656Z"
                fill="#666666"
              />
            </g>
            <g value="14">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M62.4248 51.6594V51.6594C56.3574 48.7684 49.0946 51.343 46.2029 57.4112L44.3633 61.272C41.4716 67.3395 44.047 74.603 50.1137 77.4932C56.1819 80.3849 63.4447 77.8111 66.3364 71.7428L68.176 67.8813C71.0677 61.8139 68.4923 54.5511 62.4248 51.6594Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M58.1798 65.864C58.1798 66.704 57.4518 67.446 56.2338 67.446C55.5478 67.446 54.8618 66.984 54.4978 66.312C54.3718 66.382 53.1818 67.138 53.0558 67.208C53.6998 68.37 54.9598 69.14 56.2338 69.14C58.4458 69.14 59.8878 67.796 59.8878 65.864C59.8878 63.876 58.5158 62.812 56.8498 62.812C56.2618 62.812 55.5338 62.98 55.1558 63.302L55.4778 61.426H59.3138V59.844H54.1058L53.3358 64.618L54.3158 65.206C54.9878 64.478 55.7858 64.282 56.4158 64.282C57.3958 64.282 58.1798 64.8 58.1798 65.864Z"
                fill="#666666"
              />
            </g>
            <g value="13">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M76.0081 33.3877H70.9412C66.3092 33.3877 62.5547 37.1422 62.5547 41.7742V46.8411C62.5547 51.4723 66.3092 55.2268 70.9412 55.2268H76.0081C80.64 55.2268 84.3946 51.4723 84.3946 46.8411V41.7742C84.3946 37.1422 80.64 33.3877 76.0081 33.3877Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M74.9893 45.102C74.9753 45.886 74.3593 46.488 73.5893 46.474C72.8333 46.474 72.2173 45.844 72.2173 45.06C72.2313 44.29 72.8753 43.674 73.6453 43.688C74.4153 43.702 75.0033 44.332 74.9893 45.102ZM73.9253 42.092C72.9593 42.092 72.2453 42.666 72.0773 43.128C71.9933 41.672 72.5393 40.286 73.8553 40.286C74.3313 40.286 74.7933 40.524 75.0873 41.126L76.6693 40.454C76.0253 39.054 74.9753 38.718 73.9113 38.676C70.6073 38.578 70.1313 42.12 70.3553 44.962C70.5093 46.894 71.8253 48.112 73.5053 48.14C75.3953 48.154 76.7253 46.922 76.7533 45.2C76.7673 43.254 75.3673 42.092 73.9253 42.092Z"
                fill="#666666"
              />
            </g>
            <g value="12">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M93.3489 15.6208H88.282C83.65 15.6208 79.8955 19.3754 79.8955 24.0074V29.0742C79.8955 33.7062 83.65 37.4608 88.282 37.4608H93.3489C97.9809 37.4608 101.735 33.7062 101.735 29.0742V24.0074C101.735 19.3754 97.9809 15.6208 93.3489 15.6208Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M87.8849 21.844V23.454H91.8749L88.2489 31H90.2089L94.5909 21.844H87.8849Z"
                fill="#666666"
              />
            </g>
            <g value="11">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M107.95 2.86243L119.841 1.85336C125.918 1.33844 131.136 6.13129 131.136 12.2303V22.9051C131.136 28.657 126.473 33.319 120.721 33.319H114.976C111.414 33.319 108.099 31.4987 106.188 28.493L100.042 18.8265C95.8441 12.2241 100.154 3.52359 107.95 2.86243Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M116.464 14.224C116.464 13.734 116.87 13.272 117.5 13.272C118.13 13.272 118.522 13.734 118.522 14.224C118.522 14.742 118.158 15.218 117.514 15.218C116.856 15.218 116.464 14.756 116.464 14.224ZM115.834 17.976C115.834 17.206 116.492 16.464 117.346 16.464H117.598C118.452 16.464 119.152 17.192 119.152 17.976C119.152 18.816 118.396 19.516 117.5 19.516C116.59 19.516 115.834 18.816 115.834 17.976ZM114.798 14.168C114.798 14.798 115.064 15.344 115.47 15.736C114.658 16.212 114.126 17.024 114.126 18.032C114.126 19.754 115.624 21.14 117.5 21.14C119.362 21.14 120.86 19.754 120.86 18.032C120.86 17.038 120.342 16.212 119.516 15.736C119.936 15.344 120.202 14.812 120.202 14.168C120.202 12.796 118.998 11.676 117.5 11.676C115.988 11.676 114.798 12.796 114.798 14.168Z"
                fill="#666666"
              />
            </g>
            <g value="21">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M154.326 2.86243L142.434 1.85336C136.357 1.33844 131.14 6.13129 131.14 12.2303V22.9051C131.14 28.657 135.802 33.319 141.554 33.319H147.3C150.861 33.319 154.176 31.4987 156.088 28.493L162.234 18.8265C166.432 12.2241 162.122 3.52359 154.326 2.86243Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M145.014 14.714C145.028 13.944 145.644 13.328 146.414 13.342C147.17 13.356 147.786 13.972 147.786 14.77C147.772 15.54 147.128 16.142 146.358 16.128C145.588 16.114 144.986 15.484 145.014 14.714ZM146.064 17.724C147.044 17.724 147.758 17.164 147.926 16.688C148.01 18.158 147.464 19.53 146.148 19.53C145.672 19.53 145.21 19.292 144.916 18.69L143.334 19.362C143.978 20.762 145.028 21.098 146.092 21.14C149.396 21.252 149.872 17.71 149.648 14.854C149.48 12.922 148.178 11.718 146.484 11.69C144.608 11.662 143.278 12.894 143.25 14.616C143.236 16.562 144.636 17.724 146.064 17.724Z"
                fill="#666666"
              />
            </g>
            <g value="22">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M168.927 15.6208H173.993C178.625 15.6208 182.38 19.3754 182.38 24.0074V29.0742C182.38 33.7062 178.625 37.4608 173.993 37.4608H168.927C164.295 37.4608 160.54 33.7062 160.54 29.0742V24.0074C160.54 19.3754 164.295 15.6208 168.927 15.6208Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M165.792 23.174V24.714L167.332 24.098V31H169.054V21.732L165.792 23.174ZM171.158 26.422C171.158 29.026 171.9 31.14 174.56 31.14C177.22 31.14 177.92 29.026 177.92 26.422C177.92 23.79 177.22 21.676 174.56 21.676C171.9 21.676 171.158 23.79 171.158 26.422ZM172.88 26.422C172.88 24.742 173.244 23.356 174.56 23.356C175.848 23.356 176.212 24.742 176.212 26.422C176.212 28.088 175.848 29.46 174.56 29.46C173.244 29.46 172.88 28.088 172.88 26.422Z"
                fill="#666666"
              />
            </g>
            <g value="23">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M186.267 33.3877H191.335C195.966 33.3877 199.721 37.1422 199.721 41.7742V46.8411C199.721 51.4723 195.966 55.2268 191.335 55.2268H186.267C181.636 55.2268 177.881 51.4723 177.881 46.8411V41.7742C177.881 37.1422 181.636 33.3877 186.267 33.3877Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M184.974 41.174V42.714L186.514 42.098V49H188.236V39.732L184.974 41.174ZM190.046 41.174V42.714L191.586 42.098V49H193.308V39.732L190.046 41.174Z"
                fill="#666666"
              />
            </g>
            <g value="24">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M199.85 51.6594V51.6594C205.917 48.7684 213.18 51.343 216.072 57.4112L217.912 61.272C220.803 67.3395 218.228 74.603 212.161 77.4932C206.093 80.3849 198.83 77.8111 195.939 71.7428L194.099 67.8813C191.207 61.8139 193.782 54.5511 199.85 51.6594Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M200.723 61.174V62.714L202.263 62.098V69H203.985V59.732L200.723 61.174ZM211.871 69V67.362H208.777L211.045 64.772C211.619 64.128 212.025 63.316 212.025 62.434C212.025 60.796 210.681 59.676 209.071 59.676C207.447 59.676 206.243 60.712 205.977 62.378L207.573 62.672C207.685 61.888 208.259 61.342 209.001 61.342C209.785 61.342 210.317 61.818 210.317 62.434C210.317 62.854 210.079 63.26 209.757 63.638L206.019 68.118V69H211.871Z"
                fill="#666666"
              />
            </g>
            <g value="25">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M214.84 77.0997V77.0997C221.222 74.9877 228.106 78.4482 230.218 84.8289L232.174 90.7386C234.286 97.1193 230.826 104.004 224.445 106.116C218.064 108.227 211.179 104.767 209.067 98.3862L207.111 92.4773C204.999 86.0958 208.459 79.211 214.84 77.0997Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M213.689 87.174V88.714L215.229 88.098V95H216.951V85.732L213.689 87.174ZM221.827 90.926C223.073 90.884 223.255 91.864 223.255 92.102C223.255 92.9 222.583 93.46 221.715 93.46C221.085 93.46 220.497 92.984 220.231 92.438C220.105 92.508 218.859 93.124 218.733 93.194C219.223 94.258 220.315 95.042 221.575 95.126C223.353 95.252 224.935 94.188 225.103 92.522C225.201 91.542 224.543 90.394 223.577 90.03C224.235 89.652 224.711 88.994 224.711 88.238C224.711 86.88 223.633 85.76 222.065 85.676C220.875 85.606 219.811 86.166 219.293 87.034L220.637 87.916C221.001 87.454 221.519 87.286 222.051 87.356C222.709 87.482 222.863 88.056 222.863 88.392C222.863 88.63 222.695 89.414 221.827 89.4H220.847V90.926H221.827Z"
                fill="#666666"
              />
            </g>
            <g value="26">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M226.468 106.147L230.56 105.65C237.233 104.842 243.298 109.595 244.107 116.267L245.291 126.04C246.1 132.712 241.348 138.777 234.674 139.586L230.583 140.083C223.91 140.892 217.845 136.138 217.036 129.466L215.852 119.694C215.042 113.021 219.795 106.956 226.468 106.147Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M223.819 119.174V120.714L225.359 120.098V127H227.081V117.732L223.819 119.174ZM231.146 123.514L233.176 120.322V123.514H231.146ZM236.102 125.012V123.514H234.884V117.844H233.218L228.654 125.012H233.176V127H234.884V125.012H236.102Z"
                fill="#666666"
              />
            </g>
            <g value="27">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M232.614 140.278L238.547 139.767C245.243 139.191 251.139 144.151 251.717 150.847L253.27 168.879C253.847 175.575 248.886 181.472 242.189 182.049L236.257 182.56C229.561 183.137 223.664 178.175 223.088 171.479L221.534 153.448C220.957 146.751 225.918 140.855 232.614 140.278Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M230.936 158.174V159.714L232.476 159.098V166H234.198V156.732L230.936 158.174ZM241.216 162.864C241.216 163.704 240.488 164.446 239.27 164.446C238.584 164.446 237.898 163.984 237.534 163.312C237.408 163.382 236.218 164.138 236.092 164.208C236.736 165.37 237.996 166.14 239.27 166.14C241.482 166.14 242.924 164.796 242.924 162.864C242.924 160.876 241.552 159.812 239.886 159.812C239.298 159.812 238.57 159.98 238.192 160.302L238.514 158.426H242.35V156.844H237.142L236.372 161.618L237.352 162.206C238.024 161.478 238.822 161.282 239.452 161.282C240.432 161.282 241.216 161.8 241.216 162.864Z"
                fill="#666666"
              />
            </g>
            <g value="28">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M237.937 182.654H243.891C250.612 182.654 256.061 188.102 256.061 194.824V206.533C256.061 213.255 250.612 218.704 243.891 218.704H237.937C231.216 218.704 225.767 213.255 225.767 206.533V194.824C225.767 188.102 231.216 182.654 237.937 182.654Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M234.546 197.174V198.714L236.086 198.098V205H237.808V195.732L234.546 197.174ZM244.574 202.102C244.56 202.886 243.944 203.488 243.174 203.474C242.418 203.474 241.802 202.844 241.802 202.06C241.816 201.29 242.46 200.674 243.23 200.688C244 200.702 244.588 201.332 244.574 202.102ZM243.51 199.092C242.544 199.092 241.83 199.666 241.662 200.128C241.578 198.672 242.124 197.286 243.44 197.286C243.916 197.286 244.378 197.524 244.672 198.126L246.254 197.454C245.61 196.054 244.56 195.718 243.496 195.676C240.192 195.578 239.716 199.12 239.94 201.962C240.094 203.894 241.41 205.112 243.09 205.14C244.98 205.154 246.31 203.922 246.338 202.2C246.352 200.254 244.952 199.092 243.51 199.092Z"
                fill="#666666"
              />
            </g>
          </svg>
          <span class="upper-teeth-section__lbls">
            <div class="upper-teeth-section__lbls__right">{{ i18n('QUESTION_MEDICAL_SUBQUESTION__RIGHT') }}</div>
            <div class="upper-teeth-section__lbls__left">{{ i18n('QUESTION_MEDICAL_SUBQUESTION__LEFT') }}</div>
          </span>
        </div>
      </div>
      <div class="lower-teeth-section">
        <span class="lower-teeth-section__title">{{ i18n('SCAN_INTAKE_SECTION__UPLOAD__LOWER__TITLE') }}</span>
        <div>
          <span class="lower-teeth-section__lbls">
            <div class="lower-teeth-section__lbls__right">{{ i18n('QUESTION_MEDICAL_SUBQUESTION__RIGHT') }}</div>
            <div class="lower-teeth-section__lbls__left">{{ i18n('QUESTION_MEDICAL_SUBQUESTION__LEFT') }}</div>
          </span>
          <svg
            class="lower-teeth"
            type="lower"
            width="264"
            height="256"
            viewBox="0 0 264 256"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g value="38">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M232.792 66.5596L242.957 66.5596C249.661 66.5596 255.096 60.9214 255.096 53.9659L255.096 41.8486C255.096 34.8931 249.661 29.2549 242.957 29.2549L232.792 29.2549C226.087 29.2549 220.652 34.8931 220.652 41.8486L220.652 53.9659C220.652 60.9214 226.087 66.5596 232.792 66.5596Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M231.723 44.174V45.714L233.263 45.098V52H234.985V42.732L231.723 44.174ZM236.921 42.844V44.454H240.911L237.285 52H239.245L243.627 42.844H236.921Z"
                fill="#666666"
              />
            </g>
            <g value="37">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M231.723 110.409L238.468 110.938C246.082 111.535 252.786 106.402 253.442 99.4724L255.208 80.8137C255.864 73.8845 250.224 67.782 242.61 67.1855L235.865 66.6566C228.252 66.0593 221.547 71.1933 220.892 78.1225L219.125 96.7812C218.469 103.71 224.11 109.812 231.723 110.409Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M230.922 85.174V86.714L232.462 86.098V93H234.184V83.732L230.922 85.174ZM238.5 86.224C238.5 85.734 238.906 85.272 239.536 85.272C240.166 85.272 240.558 85.734 240.558 86.224C240.558 86.742 240.194 87.218 239.55 87.218C238.892 87.218 238.5 86.756 238.5 86.224ZM237.87 89.976C237.87 89.206 238.528 88.464 239.382 88.464H239.634C240.488 88.464 241.188 89.192 241.188 89.976C241.188 90.816 240.432 91.516 239.536 91.516C238.626 91.516 237.87 90.816 237.87 89.976ZM236.834 86.168C236.834 86.798 237.1 87.344 237.506 87.736C236.694 88.212 236.162 89.024 236.162 90.032C236.162 91.754 237.66 93.14 239.536 93.14C241.398 93.14 242.896 91.754 242.896 90.032C242.896 89.038 242.378 88.212 241.552 87.736C241.972 87.344 242.238 86.812 242.238 86.168C242.238 84.796 241.034 83.676 239.536 83.676C238.024 83.676 236.834 84.796 236.834 86.168Z"
                fill="#666666"
              />
            </g>
            <g value="36">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M226.485 145.727L231.138 146.241C238.724 147.077 245.62 142.16 246.54 135.255L247.887 125.142C248.807 118.237 243.403 111.961 235.816 111.125L231.163 110.611C223.576 109.774 216.681 114.693 215.761 121.598L214.414 131.71C213.495 138.614 218.899 144.89 226.485 145.727Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M224.99 124.174V125.714L226.53 125.098V132H228.252V122.732L224.99 124.174ZM232.051 125.714C232.065 124.944 232.681 124.328 233.451 124.342C234.207 124.356 234.823 124.972 234.823 125.77C234.809 126.54 234.165 127.142 233.395 127.128C232.625 127.114 232.023 126.484 232.051 125.714ZM233.101 128.724C234.081 128.724 234.795 128.164 234.963 127.688C235.047 129.158 234.501 130.53 233.185 130.53C232.709 130.53 232.247 130.292 231.953 129.69L230.371 130.362C231.015 131.762 232.065 132.098 233.129 132.14C236.433 132.252 236.909 128.71 236.685 125.854C236.517 123.922 235.215 122.718 233.521 122.69C231.645 122.662 230.315 123.894 230.287 125.616C230.273 127.562 231.673 128.724 233.101 128.724Z"
                fill="#666666"
              />
            </g>
            <g value="35">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M215.015 175.785V175.785C222.27 177.971 230.098 174.39 232.499 167.787L234.723 161.672C237.125 155.069 233.19 147.945 225.935 145.759C218.68 143.574 210.853 147.155 208.451 153.758L206.227 159.873C203.826 166.476 207.76 173.6 215.015 175.785Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M219.117 165V163.362H216.023L218.291 160.772C218.865 160.128 219.271 159.316 219.271 158.434C219.271 156.796 217.927 155.676 216.317 155.676C214.693 155.676 213.489 156.712 213.223 158.378L214.819 158.672C214.931 157.888 215.505 157.342 216.247 157.342C217.031 157.342 217.563 157.818 217.563 158.434C217.563 158.854 217.325 159.26 217.003 159.638L213.265 164.118V165H219.117ZM220.909 160.422C220.909 163.026 221.651 165.14 224.311 165.14C226.971 165.14 227.671 163.026 227.671 160.422C227.671 157.79 226.971 155.676 224.311 155.676C221.651 155.676 220.909 157.79 220.909 160.422ZM222.631 160.422C222.631 158.742 222.995 157.356 224.311 157.356C225.599 157.356 225.963 158.742 225.963 160.422C225.963 162.088 225.599 163.46 224.311 163.46C222.995 163.46 222.631 162.088 222.631 160.422Z"
                fill="#666666"
              />
            </g>
            <g value="34">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M199.723 202.11V202.11C206.621 205.102 214.879 202.437 218.166 196.158L220.258 192.163C223.546 185.884 220.618 178.369 213.72 175.377C206.821 172.385 198.563 175.049 195.276 181.328L193.184 185.324C189.897 191.603 192.824 199.117 199.723 202.11Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M206.799 193V191.362H203.705L205.973 188.772C206.547 188.128 206.953 187.316 206.953 186.434C206.953 184.796 205.609 183.676 203.999 183.676C202.375 183.676 201.171 184.712 200.905 186.378L202.501 186.672C202.613 185.888 203.187 185.342 203.929 185.342C204.713 185.342 205.245 185.818 205.245 186.434C205.245 186.854 205.007 187.26 204.685 187.638L200.947 192.118V193H206.799ZM208.297 185.174V186.714L209.837 186.098V193H211.559V183.732L208.297 185.174Z"
                fill="#666666"
              />
            </g>
            <g value="33">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M184.859 222.611L192.961 222.611C197.58 222.611 201.325 218.726 201.325 213.933L201.325 208.689C201.325 203.897 197.58 200.011 192.961 200.011L184.859 200.011C180.24 200.011 176.494 203.897 176.494 208.689L176.494 213.933C176.494 218.726 180.24 222.611 184.859 222.611Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M188.048 216V214.362H184.954L187.222 211.772C187.796 211.128 188.202 210.316 188.202 209.434C188.202 207.796 186.858 206.676 185.248 206.676C183.624 206.676 182.42 207.712 182.154 209.378L183.75 209.672C183.862 208.888 184.436 208.342 185.178 208.342C185.962 208.342 186.494 208.818 186.494 209.434C186.494 209.854 186.256 210.26 185.934 210.638L182.196 215.118V216H188.048ZM195.622 216V214.362H192.528L194.796 211.772C195.37 211.128 195.776 210.316 195.776 209.434C195.776 207.796 194.432 206.676 192.822 206.676C191.198 206.676 189.994 207.712 189.728 209.378L191.324 209.672C191.436 208.888 192.01 208.342 192.752 208.342C193.536 208.342 194.068 208.818 194.068 209.434C194.068 209.854 193.83 210.26 193.508 210.638L189.77 215.118V216H195.622Z"
                fill="#666666"
              />
            </g>
            <g value="32">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M165.828 211.791L178.674 222.661C182.04 224.897 182.825 231.531 179.263 233.5L168.023 239.718C164.215 241.823 158.323 237.432 158.149 233.381L156.544 216.295C156.353 211.851 161.882 209.17 165.828 211.791Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M167.014 231V229.362H163.92L166.188 226.772C166.762 226.128 167.168 225.316 167.168 224.434C167.168 222.796 165.824 221.676 164.214 221.676C162.59 221.676 161.386 222.712 161.12 224.378L162.716 224.672C162.828 223.888 163.402 223.342 164.144 223.342C164.928 223.342 165.46 223.818 165.46 224.434C165.46 224.854 165.222 225.26 164.9 225.638L161.162 230.118V231H167.014ZM171.578 226.926C172.824 226.884 173.006 227.864 173.006 228.102C173.006 228.9 172.334 229.46 171.466 229.46C170.836 229.46 170.248 228.984 169.982 228.438C169.856 228.508 168.61 229.124 168.484 229.194C168.974 230.258 170.066 231.042 171.326 231.126C173.104 231.252 174.686 230.188 174.854 228.522C174.952 227.542 174.294 226.394 173.328 226.03C173.986 225.652 174.462 224.994 174.462 224.238C174.462 222.88 173.384 221.76 171.816 221.676C170.626 221.606 169.562 222.166 169.044 223.034L170.388 223.916C170.752 223.454 171.27 223.286 171.802 223.356C172.46 223.482 172.614 224.056 172.614 224.392C172.614 224.63 172.446 225.414 171.578 225.4H170.598V226.926H171.578Z"
                fill="#666666"
              />
            </g>
            <g value="31">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M148.458 214.714L156.944 232.737C159.095 236.48 156.474 243.025 151.867 243.537L140.955 244.752C136.412 245.259 132.683 239.529 133.662 235.461L136.089 216.223C137.415 210.71 145.584 209.713 148.458 214.714Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M143.644 236V234.362H140.55L142.818 231.772C143.392 231.128 143.798 230.316 143.798 229.434C143.798 227.796 142.454 226.676 140.844 226.676C139.22 226.676 138.016 227.712 137.75 229.378L139.346 229.672C139.458 228.888 140.032 228.342 140.774 228.342C141.558 228.342 142.09 228.818 142.09 229.434C142.09 229.854 141.852 230.26 141.53 230.638L137.792 235.118V236H143.644ZM147.397 232.514L149.427 229.322V232.514H147.397ZM152.353 234.012V232.514H151.135V226.844H149.469L144.905 234.012H149.427V236H151.135V234.012H152.353Z"
                fill="#666666"
              />
            </g>
            <g value="41">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M118.231 214.714L109.745 232.737C107.593 236.48 109.216 243.025 113.821 243.537L124.735 244.752C129.276 245.259 134.006 239.529 133.027 235.461L130.601 216.223C129.274 210.71 121.106 209.713 118.231 214.714Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M119.761 236V234.362H116.667L118.935 231.772C119.509 231.128 119.915 230.316 119.915 229.434C119.915 227.796 118.571 226.676 116.961 226.676C115.337 226.676 114.133 227.712 113.867 229.378L115.463 229.672C115.575 228.888 116.149 228.342 116.891 228.342C117.675 228.342 118.207 228.818 118.207 229.434C118.207 229.854 117.969 230.26 117.647 230.638L113.909 235.118V236H119.761ZM126.467 232.864C126.467 233.704 125.739 234.446 124.521 234.446C123.835 234.446 123.149 233.984 122.785 233.312C122.659 233.382 121.469 234.138 121.343 234.208C121.987 235.37 123.247 236.14 124.521 236.14C126.733 236.14 128.175 234.796 128.175 232.864C128.175 230.876 126.803 229.812 125.137 229.812C124.549 229.812 123.821 229.98 123.443 230.302L123.765 228.426H127.601V226.844H122.393L121.623 231.618L122.603 232.206C123.275 231.478 124.073 231.282 124.703 231.282C125.683 231.282 126.467 231.8 126.467 232.864Z"
                fill="#666666"
              />
            </g>
            <g value="42">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M102.86 211.791L88.0139 222.661C84.6475 224.897 83.8624 231.531 87.4247 233.5L98.6656 239.718C102.473 241.823 108.365 237.433 108.539 233.381L112.144 216.295C112.335 211.851 106.807 209.17 102.86 211.791Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M97.8222 231V229.362H94.7282L96.9962 226.772C97.5702 226.128 97.9762 225.316 97.9762 224.434C97.9762 222.796 96.6322 221.676 95.0222 221.676C93.3982 221.676 92.1942 222.712 91.9282 224.378L93.5242 224.672C93.6362 223.888 94.2102 223.342 94.9522 223.342C95.7362 223.342 96.2682 223.818 96.2682 224.434C96.2682 224.854 96.0302 225.26 95.7082 225.638L91.9702 230.118V231H97.8222ZM104.276 228.102C104.262 228.886 103.646 229.488 102.876 229.474C102.12 229.474 101.504 228.844 101.504 228.06C101.518 227.29 102.162 226.674 102.932 226.688C103.702 226.702 104.29 227.332 104.276 228.102ZM103.212 225.092C102.246 225.092 101.532 225.666 101.364 226.128C101.28 224.672 101.826 223.286 103.142 223.286C103.618 223.286 104.08 223.524 104.374 224.126L105.956 223.454C105.312 222.054 104.262 221.718 103.198 221.676C99.8944 221.578 99.4184 225.12 99.6424 227.962C99.7964 229.894 101.112 231.112 102.792 231.14C104.682 231.154 106.012 229.922 106.04 228.2C106.054 226.254 104.654 225.092 103.212 225.092Z"
                fill="#666666"
              />
            </g>
            <g value="43">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M81.8293 222.611L73.7276 222.611C69.1082 222.611 65.3633 218.726 65.3633 213.933L65.3633 208.689C65.3633 203.897 69.1082 200.011 73.7276 200.011L81.8293 200.011C86.4495 200.011 90.1944 203.897 90.1944 208.689L90.1944 213.933C90.1944 218.726 86.4495 222.611 81.8293 222.611Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M77.0478 216V214.362H73.9538L76.2218 211.772C76.7958 211.128 77.2018 210.316 77.2018 209.434C77.2018 207.796 75.8578 206.676 74.2478 206.676C72.6238 206.676 71.4198 207.712 71.1538 209.378L72.7498 209.672C72.8618 208.888 73.4358 208.342 74.1778 208.342C74.9618 208.342 75.4938 208.818 75.4938 209.434C75.4938 209.854 75.2558 210.26 74.9338 210.638L71.1958 215.118V216H77.0478ZM78.672 206.844V208.454H82.662L79.036 216H80.996L85.378 206.844H78.672Z"
                fill="#666666"
              />
            </g>
            <g value="44">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M66.9658 202.11V202.11C60.067 205.102 51.8094 202.437 48.5221 196.158L46.43 192.163C43.1427 185.884 46.07 178.369 52.9687 175.377C59.8674 172.385 68.125 175.049 71.4124 181.328L73.5044 185.324C76.7918 191.603 73.8645 199.117 66.9658 202.11Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M57.747 193V191.362H54.653L56.921 188.772C57.495 188.128 57.901 187.316 57.901 186.434C57.901 184.796 56.557 183.676 54.947 183.676C53.323 183.676 52.119 184.712 51.853 186.378L53.449 186.672C53.561 185.888 54.135 185.342 54.877 185.342C55.661 185.342 56.193 185.818 56.193 186.434C56.193 186.854 55.955 187.26 55.633 187.638L51.895 192.118V193H57.747ZM61.7512 186.224C61.7512 185.734 62.1572 185.272 62.7872 185.272C63.4172 185.272 63.8092 185.734 63.8092 186.224C63.8092 186.742 63.4452 187.218 62.8012 187.218C62.1432 187.218 61.7512 186.756 61.7512 186.224ZM61.1212 189.976C61.1212 189.206 61.7792 188.464 62.6332 188.464H62.8852C63.7392 188.464 64.4392 189.192 64.4392 189.976C64.4392 190.816 63.6832 191.516 62.7872 191.516C61.8772 191.516 61.1212 190.816 61.1212 189.976ZM60.0852 186.168C60.0852 186.798 60.3512 187.344 60.7572 187.736C59.9452 188.212 59.4132 189.024 59.4132 190.032C59.4132 191.754 60.9112 193.14 62.7872 193.14C64.6492 193.14 66.1472 191.754 66.1472 190.032C66.1472 189.038 65.6292 188.212 64.8032 187.736C65.2232 187.344 65.4892 186.812 65.4892 186.168C65.4892 184.796 64.2852 183.676 62.7872 183.676C61.2752 183.676 60.0852 184.796 60.0852 186.168Z"
                fill="#666666"
              />
            </g>
            <g value="45">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M51.6728 175.785V175.785C44.4178 177.971 36.5902 174.39 34.1887 167.787L31.9654 161.672C29.564 155.069 33.4986 147.945 40.7528 145.759C48.0085 143.574 55.8361 147.155 58.2368 153.758L60.4609 159.873C62.8623 166.476 58.9277 173.6 51.6728 175.785Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M43.8154 165V163.362H40.7214L42.9894 160.772C43.5634 160.128 43.9694 159.316 43.9694 158.434C43.9694 156.796 42.6254 155.676 41.0154 155.676C39.3914 155.676 38.1874 156.712 37.9214 158.378L39.5174 158.672C39.6294 157.888 40.2034 157.342 40.9454 157.342C41.7294 157.342 42.2614 157.818 42.2614 158.434C42.2614 158.854 42.0234 159.26 41.7014 159.638L37.9634 164.118V165H43.8154ZM47.3016 158.714C47.3156 157.944 47.9316 157.328 48.7016 157.342C49.4576 157.356 50.0736 157.972 50.0736 158.77C50.0596 159.54 49.4156 160.142 48.6456 160.128C47.8756 160.114 47.2736 159.484 47.3016 158.714ZM48.3516 161.724C49.3316 161.724 50.0456 161.164 50.2136 160.688C50.2976 162.158 49.7516 163.53 48.4356 163.53C47.9596 163.53 47.4976 163.292 47.2036 162.69L45.6216 163.362C46.2656 164.762 47.3156 165.098 48.3796 165.14C51.6836 165.252 52.1596 161.71 51.9356 158.854C51.7676 156.922 50.4656 155.718 48.7716 155.69C46.8956 155.662 45.5656 156.894 45.5376 158.616C45.5236 160.562 46.9236 161.724 48.3516 161.724Z"
                fill="#666666"
              />
            </g>
            <g value="46">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40.2023 145.727L35.5491 146.241C27.9633 147.077 21.0676 142.16 20.1479 135.255L18.8013 125.142C17.8808 118.238 23.2856 111.961 30.8722 111.125L35.5246 110.611C43.1112 109.774 50.0068 114.693 50.9266 121.598L52.2732 131.71C53.1929 138.614 47.7889 144.89 40.2023 145.727Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M32.0729 127.926C33.3189 127.884 33.5009 128.864 33.5009 129.102C33.5009 129.9 32.8289 130.46 31.9609 130.46C31.3309 130.46 30.7429 129.984 30.4769 129.438C30.3509 129.508 29.1049 130.124 28.9789 130.194C29.4689 131.258 30.5609 132.042 31.8209 132.126C33.5989 132.252 35.1809 131.188 35.3489 129.522C35.4469 128.542 34.7889 127.394 33.8229 127.03C34.4809 126.652 34.9569 125.994 34.9569 125.238C34.9569 123.88 33.8789 122.76 32.3109 122.676C31.1209 122.606 30.0569 123.166 29.5389 124.034L30.8829 124.916C31.2469 124.454 31.7649 124.286 32.2969 124.356C32.9549 124.482 33.1089 125.056 33.1089 125.392C33.1089 125.63 32.9409 126.414 32.0729 126.4H31.0929V127.926H32.0729ZM36.9435 127.422C36.9435 130.026 37.6855 132.14 40.3455 132.14C43.0055 132.14 43.7055 130.026 43.7055 127.422C43.7055 124.79 43.0055 122.676 40.3455 122.676C37.6855 122.676 36.9435 124.79 36.9435 127.422ZM38.6655 127.422C38.6655 125.742 39.0295 124.356 40.3455 124.356C41.6335 124.356 41.9975 125.742 41.9975 127.422C41.9975 129.088 41.6335 130.46 40.3455 130.46C39.0295 130.46 38.6655 129.088 38.6655 127.422Z"
                fill="#666666"
              />
            </g>
            <g value="47">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.9642 110.408L28.219 110.937C20.6056 111.535 13.9018 106.401 13.2454 99.4722L11.4797 80.8135C10.8233 73.8843 16.4637 67.7818 24.0772 67.1852L30.8224 66.6564C38.4359 66.0591 45.1396 71.193 45.796 78.1223L47.5625 96.781C48.2181 103.71 42.5777 109.812 34.9642 110.408Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M27.7546 88.926C29.0006 88.884 29.1826 89.864 29.1826 90.102C29.1826 90.9 28.5106 91.46 27.6426 91.46C27.0126 91.46 26.4246 90.984 26.1586 90.438C26.0326 90.508 24.7866 91.124 24.6606 91.194C25.1506 92.258 26.2426 93.042 27.5026 93.126C29.2806 93.252 30.8626 92.188 31.0306 90.522C31.1286 89.542 30.4706 88.394 29.5046 88.03C30.1626 87.652 30.6386 86.994 30.6386 86.238C30.6386 84.88 29.5606 83.76 27.9926 83.676C26.8026 83.606 25.7386 84.166 25.2206 85.034L26.5646 85.916C26.9286 85.454 27.4466 85.286 27.9786 85.356C28.6366 85.482 28.7906 86.056 28.7906 86.392C28.7906 86.63 28.6226 87.414 27.7546 87.4H26.7746V88.926H27.7546ZM32.3312 85.174V86.714L33.8712 86.098V93H35.5932V83.732L32.3312 85.174Z"
                fill="#666666"
              />
            </g>
            <g value="48">
              <path
                class="tooth"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.3612 66.5595L22.1951 66.5595C15.4913 66.5595 10.0566 60.9214 10.0566 53.9659L10.0566 41.8485C10.0566 34.893 15.4913 29.2549 22.1951 29.2549L32.3612 29.2549C39.0649 29.2549 44.5004 34.893 44.5004 41.8485L44.5004 53.9659C44.5004 60.9214 39.0649 66.5595 32.3612 66.5595Z"
                stroke="#B3B3B3"
                fill="#fff"
                stroke-width="2.5"
                stroke-linecap="round"
              />
              <path
                class="number"
                d="M23.0036 47.926C24.2496 47.884 24.4316 48.864 24.4316 49.102C24.4316 49.9 23.7596 50.46 22.8916 50.46C22.2616 50.46 21.6736 49.984 21.4076 49.438C21.2816 49.508 20.0356 50.124 19.9096 50.194C20.3996 51.258 21.4916 52.042 22.7516 52.126C24.5296 52.252 26.1116 51.188 26.2796 49.522C26.3776 48.542 25.7196 47.394 24.7536 47.03C25.4116 46.652 25.8876 45.994 25.8876 45.238C25.8876 43.88 24.8096 42.76 23.2416 42.676C22.0516 42.606 20.9876 43.166 20.4696 44.034L21.8136 44.916C22.1776 44.454 22.6956 44.286 23.2276 44.356C23.8856 44.482 24.0396 45.056 24.0396 45.392C24.0396 45.63 23.8716 46.414 23.0036 46.4H22.0236V47.926H23.0036ZM33.6562 52V50.362H30.5622L32.8302 47.772C33.4042 47.128 33.8102 46.316 33.8102 45.434C33.8102 43.796 32.4662 42.676 30.8562 42.676C29.2322 42.676 28.0282 43.712 27.7622 45.378L29.3582 45.672C29.4702 44.888 30.0442 44.342 30.7862 44.342C31.5702 44.342 32.1022 44.818 32.1022 45.434C32.1022 45.854 31.8642 46.26 31.5422 46.638L27.8042 51.118V52H33.6562Z"
                fill="#666666"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
    <span
      v-if="errors[0]"
      class="invalid-feedback d-block"
    >{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'QuestionTeethImages',
  components: {
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    validationField: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dataValue: this.$attrs.value || []
    }
  },
  mounted() {
    const provider = this.$refs.questionTeethImagesProvider
    this.setItemEvent()
    provider.syncValue(this.dataValue)
  },
  methods: {
    i18n,
    getValidationName() {
      return (this.validationField && this.rules.length > 0) ?
        this.validationField :
        this.name
    },
    updateValue(type, itemValue) {
      const provider = this.$refs.questionTeethImagesProvider

      let values = [...this.dataValue[type]]
      if (values.includes(itemValue)) {
        values = values.filter((value) => value !== itemValue)
      } else {
        values.push(itemValue)
      }

      this.dataValue[type] = values
      provider.validate(this.dataValue)
    },
    setItemEvent() {
      const teeth = [...document.querySelectorAll(`#question-teeth-images-${this.name} g`)]

      teeth.forEach((tooth) => {
        const itemValue = tooth.getAttribute('value')
        const type = tooth.parentElement.getAttribute('type')

        tooth.addEventListener('click', (event) => {
          event.preventDefault()
          const target = event.target
          var element = target.parentElement
          if(element.tagName === 'svg') {
            element = event.target
          }

          const svg = element.parentElement
          this.updateValue(svg.getAttribute('type'), element.getAttribute('value'))
          if(!element.classList.contains('highlighted')) {
            element.classList.add('highlighted')
          } else {
            element.classList.remove('highlighted')
          }
        })

        if(type !== null && this.dataValue[type].length > 0 && this.dataValue[type].includes(itemValue)) {
          tooth.classList.add('highlighted')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.question-teeth-images {
  display: flex;
  flex-wrap: wrap;
}

.upper-teeth-section {
  display: flex;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  padding: 15px;
  margin-right: 10px;
  position: relative;
  margin-top: 10px;
  svg {
    margin-top: 30px;
  }
  &__title {
    display: block;
    font-size: 18px;
    font-weight: 900;
    color: black;
    position: absolute;
  }
  &__lbls {
    display: flex;
    &__left {
      width: 50%;
      text-align: right;
      margin-right: 10px;
      margin-top: -30px;
      font-weight: $font-weight-bold;
      color: black;
    }
    &__right {
      width: 50%;
      margin-top: -30px;
      font-weight: $font-weight-bold;
      color: black;
    }
  }
}

.highlighted {
  .number {
    fill: $indigo;
  }
  .tooth {
    fill: $indigo;
    fill-opacity: 0.2;
    stroke: $indigo;
  }
}

.lower-teeth-section {
  display: flex;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  margin-top: 10px;
  svg {
    margin-top: -20px;
  }
  &__title {
    display: block;
    font-size: 18px;
    font-weight: 900;
    color: black;
    position: absolute;
  }
  &__lbls {
    display: flex;
    &__left {
      width: 50%;
      text-align: right;
      margin-top: 30px;
      margin-right: 10px;
      font-weight: $font-weight-bold;
      color: black;
    }
    &__right {
      width: 50%;
      margin-top: 30px;
      margin-left: 10px;
      font-weight: $font-weight-bold;
      color: black;
    }
  }
}
</style>
