<template>
  <validation-observer ref="observer">
    <form
      class="reset-password-form"
      @submit.prevent="onSubmit"
    >
      <validated-email-input
        v-model="emailAddress"
        name="reset-password-form-email"
        :max-length="maxLengths.emailAddress"
        rules="required|email"
        :disabled="false"
        :label="i18n('LOGIN__LABEL_EMAIL')"
      />
      <sdc-button
        type="primary"
        class="btn-block reset-password-form__submit"
        @click="onSubmit"
      >
        {{ i18n('LABEL__SUBMIT') }}
      </sdc-button>
    </form>
  </validation-observer>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { recoverPassword } from '_api_/utils.api.js'
import SdcButton from '_atoms_/SdcButton'
import ValidatedEmailInput from '_molecules_/ValidatedEmailInput'
import { ValidationObserver } from 'vee-validate'
import { inputMaxLengths } from '_utils_/constants'

export default {
  name: 'ResetPasswordForm',
  components: {
    SdcButton,
    ValidatedEmailInput,
    ValidationObserver
  },
  data() {
    return {
      emailAddress: '',
      maxLengths: inputMaxLengths.patientInfo
    }
  },
  methods: {
    i18n,
    onSubmit() {
      this.$refs.observer.validate().then((result) => {
        if (result) {
          this.sendEmail()
        }
      })
    },
    async sendEmail() {
      await recoverPassword(this.emailAddress)
      this.$router.push({
        name: 'sent-email',
        params: {
          emailAddress: this.emailAddress
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.reset-password-form {
  &__submit {
    height: 56px;
    border-radius: $h3-font-size;
    font-size: $font-size-md;
    line-height: $font-size-md;
  }
}
</style>
