export const MAX_SIZE = 52428800 // 50MB to B

export const ADDITIONAL_FILES_ERRORS_CODE = {
  uploadFiles: 'UPLOAD_FILES'
}
export const AUTHORIZATION_SCOPES = {
  private: 'private',
  public: 'public'
}

