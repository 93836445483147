import moment from 'moment'
import I18nConfig from '_utils_/I18nConfig'

export const formatDatesForSelect = (data = []) => {
  return data.map(item => {
    const text = moment.utc(item.day)
      .format(I18nConfig.weekDayDisplayFormat)
    return { value: item.day, text }
  })
}

export const formatHoursForSelect = (hours = []) =>
  hours.map(hour => ({value:  hour, text: moment.utc(hour).format(I18nConfig.timeFormat)}))

/**
 * Given an array of DateTime, we get all the available hours before the final date minus slack hours for the agenda.
 *
 * @param selectedDate {string} - Date selected by the user
 * @param listHoursAvailable {string[]} - List of possible hours
 * @param hoursBeforeEnd {number} - Slack hours for the agenda
 * @returns {string[]} - List of available hours
 */
export const getAvailableTimeFrom = (selectedDate, listHoursAvailable, hoursBeforeEnd) => {
  if (!listHoursAvailable.length) return []
  const now = new Date()

  const today = removeTimezone(selectedDate).toDateString() === now.toDateString() && now
  const endTime = removeTimezone(listHoursAvailable[listHoursAvailable.length - 1])
  endTime.setHours(endTime.getHours() - hoursBeforeEnd)

  return listHoursAvailable.filter(time => {
    const timeWithoutTimezone = removeTimezone(time)
    return timeWithoutTimezone <= endTime && (!today || timeWithoutTimezone > today)
  })
}

/**
 * Given an array of DateTime, we get all the available hours after the initial date plus slack hours for the agenda.
 *
 * @param selectedDate {string} - Date selected by the user
 * @param listHoursAvailable {string[]} - List of possible hours
 * @param hoursAfterStart {number} - Slack hours for the agenda
 * @returns {string[]} - List of available hours
 */
export const getAvailableTimeTo = (selectedDate, listHoursAvailable, hoursAfterStart) => {
  if (!listHoursAvailable.length) return []

  const initDate = removeTimezone(selectedDate)
  initDate.setHours(initDate.getHours() + hoursAfterStart)

  return listHoursAvailable.filter(time => removeTimezone(time) >= initDate)
}

export const filterDates = (dates, hoursBeforeEnd) => {
  if (!dates.length) return []
  const now = new Date()
  const firsDate = removeTimezone(dates[0].day)

  const firstDateIsToday = now.toDateString() === firsDate.toDateString()

  if (firstDateIsToday) {
    const endTime = removeTimezone(dates[0].hours[dates[0].hours.length - 1])
    endTime.setHours(endTime.getHours() - hoursBeforeEnd)

    const isNowLaterThanLastTimeOption = now > endTime
    if (isNowLaterThanLastTimeOption) {
      return dates.slice(1)
    }
  }
  return [...dates]
}

/**
 * This method removes the time zone from a date.
 * We all know that dates are always saved in UTC,
 * but when we are going to print them, it shows the date formatted with the time zone of the device.
 * That is a problem for those who bring dates with other time zones or with UTC to be manipulated and compared.
 * For example, if a date "2023-02-22T00:00:00:00+00:00" is shown, being in the "America/Bogota"
 * time zone when we go to print the date, it will be "2023-02-21T19:00:00:00-05:00".
 *
 * The problem is that if I want to compare dates regardless of time zones,
 * it would not work if I compare "2023-02-22T09:00:00:00:00+00:00" with "2023-02-22T09:00:00:00:00-05:00".
 * On the other hand, by removing the time zone (actually I modify it to match the time zone of the computer)
 * it can indeed be compared.
 *
 * @param date {Date | string} Date to remove the time zone
 * @param timeZone {string} Time zone in which the date has been created
 * @returns {Date}
 */
export const removeTimezone = (date, timeZone = "UTC") => {

  const dateStr = (typeof date === "string" ? new Date(date) : date)
    .toLocaleString("en", { "timeZone": timeZone })

  return new Date(dateStr)
}

function isValidDate(date) {
  return !isNaN(date.getTime());
}

/**
 * This method compares two dates, ignoring the time.
 * if date1 is greater than date2, it returns -1
 * if date1 is less than date2, it returns 1
 * if date1 is equal to date2, it returns 0
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export const compareDates = (date1, date2) => {
  if (!isValidDate(date1) || !isValidDate(date2)) {
    throw new Error('Invalid dates');
  }

  const units = ['getFullYear', 'getMonth', 'getDate'];

  for (const unit of units) {
    const value1 = date1[unit]();
    const value2 = date2[unit]();

    if (value1 > value2) {
      return -1;
    } else if (value1 < value2) {
      return 1;
    }
  }

  return 0;
}



