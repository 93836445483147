<template>
  <div
    id="app"
    :class="{ 'app-background-error': isErrorStatusPresent }"
  >
    <AppUpdater />
    <error-mgr />
    <div id="nav">
      <NavBar />
    </div>
    <SdcBanner />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import NavBar from '_organisms_/NavBar'
import AppUpdater from '_molecules_/AppUpdater'
import SdcBanner from '_molecules_/SdcBanner'
import ErrorMgr from '_templates_/ErrorMgr'
import Location from '_utils_/Location'
import Logger from '_utils_/Logger'
import { mapState, mapGetters, mapActions } from 'vuex'
import validationInstance from '_utils_/ValidationInitializer'
import { validationRules, i18nValidationRules } from '_utils_/validationRules'

export default {
  name: 'App',
  components: {
    NavBar,
    AppUpdater,
    SdcBanner,
    ErrorMgr
  },
  data() {
    return {
      authenticated: false,
      interactionDelayer: null,
      inactivityTimer: null,
    }
  },
  computed: {
    ...mapGetters('ErrorStatus', ['isErrorStatusPresent']),
    ...mapState('Locale', ['currentLocale', 'i18nConfigLoaded'])
  },
  watch: {
    currentLocale() {
      validationInstance.initializeValidations(validationRules())
    },
    i18nConfigLoaded() {
      validationInstance.initializeValidations(i18nValidationRules())
    }
  },
  async beforeMount() {
    Logger.startRealUserMonitoring()
    Location.setLocale()
    this.setInteractionListeners()
  },
  methods: {
    ...mapActions('AppVersion', ['resetLastInteraction']),
    setInteractionListeners() {
      ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchmove', 'resize']
      .forEach(event => window.addEventListener(event, () => this.resetLastInteraction()))
    },
  },
}
</script>

<style lang="scss">
@import '_theme_/_variables';
#app {
  min-height: 100vh;
}
#nav {
  background-color: #fff !important;
}
.app-background-error {
  background-color: $white;
}
</style>
