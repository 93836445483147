<template>
  <sdc-page class="case-details">
    <case-details-header />
    <case-details-patient />
    <MedicalInformation
      v-if="showMedicalInformation"
      :treatment-plans="treatmentPlans"
    />
    <order-retake
      v-if="caseRequiresRetake"
      class="case_details__order-retake"
    />
    <scan-intake
      v-if="isManualScanUpload"
      class="case-details__scan-intake"
      @onFilesUploaded="getCasePatientDetails"
    />
    <shipping-label
      v-if="isShippingEnabled"
      class="case-details__shipping-label-section"
    />
    <additional-files class="case-details__additional-files" />
    <case-history
      v-if="showCaseHistory"
      id="case-history"
    />
  </sdc-page>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import {
  IMPRESSION_MODES,
  CASE_STATUS,
  CASE_REASON,
  CASE_SUBMITTED_BANNER_STATUS
} from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'
import SdcPage from '_templates_/SdcPage'
import CaseDetailsHeader from '_molecules_/CaseDetailsHeader'
import AdditionalFiles from '_organisms_/AdditionalFiles'
import CaseDetailsPatient from '_organisms_/CaseDetailsPatient'
import CaseHistory from '_organisms_/CaseHistory'
import MedicalInformation from '@/components/organisms/MedicalInformation'
import OrderRetake from '_organisms_/OrderRetake'
import ScanIntake from '_organisms_/ScanIntake'
import ShippingLabel from '_organisms_/ShippingLabel'
import { APPOINTMENT_NOTE_TYPES_WITH_BULLETS } from './constants'

export default {
  name: 'CaseDetails',
  components: {
    AdditionalFiles,
    CaseDetailsHeader,
    CaseDetailsPatient,
    CaseHistory,
    MedicalInformation,
    OrderRetake,
    ScanIntake,
    SdcPage,
    ShippingLabel
  },
  data() {
    return {
      isAppointmentNoteRedirecting: false
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details', 'orderStatus']),
    ...mapState('Features', ['caseHistorySection']),
    ...mapGetters('CaseDetails', ['appointmentNoteByCase', 'hasAppointmentNote']),
    ...mapGetters('MedicalInformation', ['treatmentPlans']),
    isShippingEnabled() {
      return (
        I18nConfig.isShippingEnabled &&
        !this.caseRequiresRetake &&
        this.details?.impressionMode === IMPRESSION_MODES.physical
      )
    },
    isManualScanUpload() {
      return (
        this.details?.impressionMode === IMPRESSION_MODES.manual &&
        !this.caseRequiresRetake
      )
    },
    caseRequiresRetake() {
      return (
        this.details?.status === CASE_STATUS.impressionRejected ||
        this.details?.status === CASE_STATUS.scanRejected ||
        (this.details?.status === CASE_STATUS.requiresAction &&
          this.details?.reasonCode === CASE_REASON.needRescan)
      )
    },
    showCaseHistory() {
      return this.caseHistorySection && I18nConfig.enableCaseHistorySection
    },
    showMedicalInformation() {
      return !!this.treatmentPlans.length
    }
  },
  async mounted() {
    this.getCasePatientDetails()
    this.showAppointmentNote()
    this.showSuccessBanner()
    this.loadTreatmentPlans(this.$route.params.id)
  },
  destroyed() {
    this.clearState()
    this.clearTreatmentPlans()
    if (!this.isAppointmentNoteRedirecting) {
      this.setCaseId('')
      this.setAppointmentNoteByCase(null)
      this.setOrderStatus('')
      this.$sdcBanner.close()
    }
  },
  methods: {
    ...mapMutations('CaseDetails', [
      'setCaseId',
      'clearState',
      'setAppointmentNoteByCase',
      'setOrderStatus'
    ]),
    ...mapActions('CaseDetails', [
      'getPatientDetails',
      'getAppointmentNoteByCase'
    ]),
    ...mapActions('MedicalInformation', [
      'clearTreatmentPlans',
      'loadTreatmentPlans'
    ]),
    getCasePatientDetails() {
      this.getPatientDetails({
        id: this.$route.params.id,
        shouldSleepRequest: true
      })
    },
    showSuccessBanner() {
      if (this.orderStatus) {
        const hasErrors = this.orderStatus !== CASE_SUBMITTED_BANNER_STATUS.success
        this.$sdcBanner.open({
          text: i18n(
            `CASE_DETAILS__SUBMITTED_SUCCESSFULLY${hasErrors ? '_WITH_ERRORS' : ''}`
          ),
          type: hasErrors ? 'is-primary' : 'is-success'
        })
      }
    },
    async showAppointmentNote() {
      const caseId = this.$route.params.id
      await this.getAppointmentNoteByCase(caseId)

      if (this.hasAppointmentNote) {
        const { appointmentDate, type } = this.appointmentNoteByCase

        const { bullets, redirectPathName } = APPOINTMENT_NOTE_TYPES_WITH_BULLETS[type] || {}

        this.$sdcBanner.open({
          text: `${i18n('CASE_DETAILS__APPOINTMENT_NOTE__TEXT')}: ${appointmentDate}`,
          bullets,
          buttonText: i18n('CASE_DETAILS__APPOINTMENT_NOTE__BUTTON_TEXT'),
          buttonClick: () => {
            this.isAppointmentNoteRedirecting = true

            this.$router.push({
              name: redirectPathName,
              params: {
                redirectFromCaseDetails: true
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';
@import '_theme_/_section-panel';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop-sm: map-get($sdc-breakpoints, 'desktop-sm');

.case-details {
  @media screen and (min-width: $desktop-sm) {
    max-width: $desktop-sm !important;
  }

  >section {
    @extend .section-panel-plain;
    border-radius: 8px;
    padding: 40px;
    margin-top: 20px;
    display: flex;
  }
}
</style>
