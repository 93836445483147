<template>
  <sdc-modal
    id="send-shipping-label-modal"
    ref="send-shipping-label-modal"
    name="shipping-label-modal"
    :title-props="titleProps"
    hide-footer
  >
    <shipping-label-send-form
      :close-modal="close"
      :case-id="caseId"
    />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import ShippingLabelSendForm from '_molecules_/ShippingLabelSendForm'
import SdcModal from '_molecules_/SdcModal'

export default {
  name: 'ShippingLabelSendModal',
  components: {
    SdcModal,
    ShippingLabelSendForm,
  },
  props: {
    caseId: { type: String, default: '' },
  },
  data() {
    return {
      titleProps: {
        text: 'SHIPPING_LABEL_MODAL__TITLE'
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['send-shipping-label-modal']
    },
  },
  methods: {
    i18n,
    focus() {
      this.modalRef.focus()
    },
    open() {
      this.modalRef.open()
    },
    close() {
      this.modalRef.close()
    },
  },
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.send-shipping-label-modal {
  .modal-content {
    @media screen and (max-width: $mobile) {
      padding: 0px !important;
    }
  }
  .modal-xl {
    @media screen and (max-width: $mobile) {
      max-width: 100%;
    }
  }
}
</style>
