<template>
  <validation-provider
    id="validated-phone-input"
    v-slot="{ errors }"
    ref="phoneNumberProvider"
    :name="inputId"
    :rules="getRules()"
    class="validated-phone-input"
  >
    <div :class="{ 'is-false': (errors[0]) }">
      <vue-tel-input
        :id="inputId"
        v-model="phoneNumber"
        v-bind="vueTelInputProps"
        :default-country="countryCode"
        :preferred-countries="countries"
        autocomplete="nope"
        @input="checkNumber"
        @validate="checkNumber"
      />
    </div>
    <span
      v-if="errors[0]"
      class="invalid-feedback d-block"
    >{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { inputMaxLengths } from '_utils_/constants'
import { i18n } from '_utils_/i18n'
import { mapState } from 'vuex'
import Location from '_utils_/Location'

export default {
  name: 'ValidatedPhoneInput',
  components: {
    ValidationProvider
  },
  props: {
    inputId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      temporalPhoneNumber: '',
      isInvalidPhoneValue: false,
      vueTelInputProps: {
        autocomplete: 'off',
        disabled: false,
        disabledFetchingCountry: false,
        disabledFormatting: false,
        enabledCountryCode: false,
        enabledFlags: true,
        ignoredCountries: [],
        inputClasses: ['sdc-tel-input-control'],
        maxLen: inputMaxLengths.patientInfo.phoneNumber,
        mode: 'international',
        name: this.inputId,
        onlyCountries: [],
        placeholder: i18n('INPUT_TELEPHONE__LABEL'),
        required: false,
        wrapperClasses: ['sdc-tel-input-wrapper'],
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        },
        validCharactersOnly: true
      }
    }
  },
  computed: {
    ...mapState('Locale', ['currentLocale', 'notifyI18nLoadState']),
    countryCode() {
      return Location.countryCode
    },
    countries() {
      return Location.countries
    },
    phoneNumber: {
      get() {
        return this.$attrs.value || ''
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    getRules() {
      return {
        'required': true,
        'isInvalidPhone': this.isInvalidPhoneValue
      }
    },
    checkNumber: function(number, inputObject) {
      if(inputObject === undefined){
        this.isInvalidPhoneValue = !number.valid
      }else{
        this.isInvalidPhoneValue = !inputObject.isValid
        this.phoneNumber = number
      }
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.invalid-feedback {
  display: block;
  font-size: .75rem;
  margin-top: 0.25rem;
  color: #e91916;
}

.vti__dropdown-list.below {
  z-index: 999;
}

.sdc-tel-input-control {
  font-size: $font-size-base;
}
</style>
