<template>
  <div
    id="fast-track"
    name="fast-track"
    class="fast-track"
  >
    <div
      v-if="hasPurchased"
      class="fast-track__purchased"
    >
      <sdc-title level="4">
        {{ i18n('FAST_TRACK__PURCHASED__TITLE') }}
      </sdc-title>
      <p>
        {{ i18n('FAST_TRACK__PURCHASED__TEXT', [i18n('CUSTOMER_SUPPORT__PHONE')]) }}
      </p>
    </div>
    <create-case-step-form
      v-else
      ref="fastTrackForm"
    >
      <fast-track-payment-method
        v-model="fastTrackInfo.paymentMethod"
        class="fast-track__payment-method"
        :smile-now-terms-initial-value="fastTrackInfo.smileNowTermsAndConsentsAccepted"
        @termsAcceptedChanged="handleTermsAcceptedChanged"
      />
      <fast-track-discount-code
        v-if="(!isPaymentUpdate && !isCarePlus)"
        :discount-code="discountCode"
        :is-affiliate="isAffiliate"
        class="fast-track__discount-code"
        @onChange="onDiscountChange"
      />
      <fast-track-insurance v-if="showInsuranceEligibility" />
      <div ref="paymentInfoElement">
        <fast-track-payment-info
          v-if="isPaymentInformationRequired()"
          v-model="fastTrackInfo.creditCardInfo.name"
          @update="getTokenMethod"
        />
      </div>
      <fast-track-billing-address
        v-if="!isPaymentUpdate"
        :model="fastTrackInfo.billingAddress"
      />
      <fast-track-aligner-preference
        v-if="showAlignerPreference"
        v-model="fastTrackInfo.alignerPreference"
      />
      <sdc-checkbox
        v-if="showConsentSignatureCheckbox"
        v-model="questionInfo.insuranceConsentSigned"
        name="patient-signature-consent"
        rules="requiredTerms|requiredTermsBoolean"
        @input="signatureModalOpen"
      >
        {{ i18n('SIGN_INSURANCE_TERMS_CHECKBOX_TEXT') }}
      </sdc-checkbox>
      <signature-modal
        ref="signatureModal"
        :document-kind="insuranceConsentDocumentKind"
        :payment-method="fastTrackInfo.paymentMethod"
      />
      <patient-consent
        v-model="questionInfo.patientConsent"
        class="fast-track__patient-consent"
        :disable-get-smile-pay-terms="disableGetSmilePayTerms"
        :title="i18n('PATIENT_CONSENT__TITLE')"
      />
    </create-case-step-form>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { validateDiscountCode } from '_api_/utils.api'
import { fastTrackPaymentMethods, CARE_LEVEL } from '_utils_/constants'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import FastTrackPaymentMethod from '_molecules_/FastTrackPaymentMethod'
import FastTrackBillingAddress from '_molecules_/FastTrackBillingAddress'
import FastTrackPaymentInfo from '_molecules_/FastTrackPaymentInfo'
import FastTrackAlignerPreference from '_molecules_/FastTrackAlignerPreference'
import FastTrackDiscountCode from '_molecules_/FastTrackDiscountCode'
import FastTrackInsurance from '_molecules_/FastTrackInsurance'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'
import { isAnyAddressFieldCompleted } from '_utils_/addressHelper'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SignatureModal from '_molecules_/SignatureModal'
import PatientConsent from '_molecules_/PatientConsent'
import { DOCUMENT_KINDS } from '_utils_/constants'
import SdcTitle from '_atoms_/SdcTitle'

export default {
  name: 'FastTrack',
  components: {
    CreateCaseStepForm,
    FastTrackPaymentMethod,
    FastTrackPaymentInfo,
    FastTrackBillingAddress,
    FastTrackAlignerPreference,
    FastTrackDiscountCode,
    FastTrackInsurance,
    SdcCheckbox,
    SignatureModal,
    PatientConsent,
    SdcTitle
  },
  provide() {
    return {
      verifyDiscountCode: validateDiscountCode
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      getToken: null,
      discountCode: '',
      isAffiliate: false,
      insuranceConsentDocumentKind: DOCUMENT_KINDS.insuranceConsent
    }
  },
  computed: {
    ...mapState('CreateCase', ['fastTrackInfo', 'isPaymentUpdate', 'questionInfo']),
    ...mapState('InformationForm', ['usePracticeAddressAsShippingAddress']),
    showInsuranceEligibility() {
      return (
        !!I18nConfig.insuranceUrl
        && this.fastTrackInfo.paymentMethod === fastTrackPaymentMethods.NotFastTrack
        && !this.isCarePlus
      )
    },
    showAlignerPreference(){
      return (
        !I18nConfig.shouldShowCreateCasePreferenceStep
        && this.isAlignerPreferenceRequired()
        && !this.isPaymentUpdate
      )
    },
    showConsentSignatureCheckbox() {
      return I18nConfig.insuranceConsentFormEnabled
    },
    disableGetSmilePayTerms() {
      return this.fastTrackInfo.paymentMethod !== fastTrackPaymentMethods.SmilePay
    },
    hasPurchased() {
      return this.fastTrackInfo.hasPurchased
    },
    isCarePlus() {
      return this.fastTrackInfo.careLevel === CARE_LEVEL.carePlus
    }
  },
  beforeMount() {
    this.registerModel()
    this.setLoadingStatus(true)
    this.clearCreditCard()
    this.discountCode = this.fastTrackInfo.discountCode
    this.isAffiliate = this.fastTrackInfo.hasAffiliateCode
    this.setLoadingStatus(false)
    this.questionInfo.patientConsent = false
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setFastTrackInfo', 'clearCreditCard']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    ...mapActions('Address', ['getRegionFromZipcode', 'isValidZipcode']),
    handleTermsAcceptedChanged(value) {
      this.fastTrackInfo.smileNowTermsAndConsentsAccepted = value
    },
    onDiscountChange({ discountCode, isAffiliate }) {
      this.fastTrackInfo.discountCode = discountCode
      this.fastTrackInfo.hasAffiliateCode = isAffiliate
    },
    registerModel() {
      this.model.validate = this.validateForm
    },
    isAlignerPreferenceRequired() {
      return this.fastTrackInfo.paymentMethod !== fastTrackPaymentMethods.NotFastTrack && this.fastTrackInfo.paymentMethod !== ''
    },
    isPaymentInformationRequired() {
      return I18nConfig.isPaymentInformationRequiredForMethod(this.fastTrackInfo.paymentMethod)
    },
    getTokenMethod(tokenMethod) {
      this.getToken = tokenMethod
    },
    async validateForm(next) {
      if (this.hasPurchased) {
        const { billingAddress } = this.fastTrackInfo
        billingAddress.sameAsShipping = true
        this.setFastTrackInfo({ data: { billingAddress } })

        next()
        return
      }

      if (!(await this.$refs.fastTrackForm.validateForm())) {
        return
      }

      const {
        paymentMethod,
        creditCardInfo,
        smileNowTermsAndConsentsAccepted,
        billingAddress,
        alignerPreference,
        discountCode,
        hasAffiliateCode
      } = this.fastTrackInfo

      if (this.isPaymentInformationRequired() && !I18nConfig.isPaymentBrokerEnabled) {
        if (this.getToken === null) return

        const tokenInfo = await this.getToken()

        if (!tokenInfo || tokenInfo.token?.length === 0) {
          this.$refs.paymentInfoElement.scrollIntoView({ behavior: 'smooth' })
          return
        }

        creditCardInfo.card = { ...tokenInfo }
      }

      if (!billingAddress.sameAsShipping &&
        (!this.usePracticeAddressAsShippingAddress || isAnyAddressFieldCompleted(billingAddress))) {
        const isValidZipcode = await this.isValidZipcode(billingAddress)

        if (isValidZipcode.error) {
          if (billingAddress.city === '') {
            this.setBillingAddressCityFieldError(i18n('ERROR__INPUT_REQUIRED'))
          }
          if (billingAddress.streetAddress1 === '') {
            this.setBillingAddressStreetAddressFieldError(i18n('ERROR__INPUT_REQUIRED'))
          }
          this.setBillingAddressZipCodeFieldError(isValidZipcode.error) // The error comes from the API
          return
        }

        if (!I18nConfig.hasRegions) {
          const region = await this.getRegionFromZipcode(billingAddress.zipCode)

          if (!region) {
            this.setBillingAddressZipCodeFieldError(
              i18n('ERROR__ZIPCODE_NOT_FOUND', [i18n('CREATE_CASE__SHIPPING_LABEL_ZIPCODE')])
            )
            return
          }

          billingAddress.state = region
        }
      }

      this.setFastTrackInfo({
        data: {
          paymentMethod,
          smileNowTermsAndConsentsAccepted,
          billingAddress,
          creditCardInfo,
          alignerPreference,
          discountCode,
          hasAffiliateCode
        }
      })

      next()
    },
    setBillingAddressZipCodeFieldError(errorMsg) {
      this.$refs.fastTrackForm.$refs.createCaseStepForm.setErrors({
        'shipping-zip': errorMsg
      })
    },
    setBillingAddressCityFieldError(errorMsg) {
      this.$refs.fastTrackForm.$refs.createCaseStepForm.setErrors({
        'shipping-city': errorMsg
      })
    },
    setBillingAddressStreetAddressFieldError(errorMsg) {
      this.$refs.fastTrackForm.$refs.createCaseStepForm.setErrors({
        'shipping-street-address': errorMsg
      })
    },
    signatureModalOpen() {
      if (this.questionInfo.insuranceConsentSigned){
        this.questionInfo.insuranceConsentSigned = false
        this.$refs.signatureModal.open()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fast-track {
  &__discount-code,
  &__patient-consent {
    margin-top: 30px;
  }
}
</style>
