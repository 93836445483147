import { i18n } from '_utils_/i18n'
import {
  signIn,
  signOut,
  isUserLoggedIn,
  getTokenManager,
  getAuthInfo,
  getOktaGuestToken
} from '_api_/auth.api.js'
import { authConstants } from '../../utils/constants'
import Location from '_utils_/Location'
import { getI18nConfig } from '_api_/i18nConfig.api'

const persistStateToStorage = (key, state) => {
  window.localStorage.setItem(key, JSON.stringify(state))
}

const loadStateFromStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key))
}

const clearStateInStorage = (key) => {
  window.localStorage.removeItem(key)
}

export const mutationTypes = {
  setAuthInfoLoaded: 'setAuthInfoLoaded',
  setUserData: 'setUserData',
  setAuthInfo: 'setAuthInfo',
  clearAuthInfo: 'clearAuthInfo',
  setCurrentPracticeId: 'setCurrentPracticeId',
  loginError: 'loginError',
  setGuestToken: 'setGuestToken',
  setIsLoadingGuestToken: 'setIsLoadingGuestToken'
}

const state = {
  authUser: null,
  authInfo: {
    loaded: false,
    boId: null,
    boName: null,
    practices: [],
    roles: [],
    permissions: [],
    groups: []
  },
  currentPracticeId: null,
  loginError: {
    msg: null,
    title: null,
    status: null
  },
  guestToken: null,
  isLoadingGuestToken: false
}

const mutations = {
  [mutationTypes.setUserData](state, payload){
    state.authUser = payload
  },
  [mutationTypes.setAuthInfo](state, {
    boId,
    boName,
    practices,
    roles,
    permissions,
    groups
  }) {
    if (boId) {
      state.authInfo.boId = boId
    }
    if (boName) {
      state.authInfo.boName = boName
    }
    if (practices) {
      state.authInfo.practices = practices
    }
    state.authInfo.roles = roles
    state.authInfo.permissions = permissions
    state.authInfo.groups = groups
  },
  [mutationTypes.clearAuthInfo](state) {
    state.authInfo = {
      boId: null,
      boName: null,
      practices: [],
      roles: [],
      permissions: [],
      groups: []
    }
  },
  [mutationTypes.setCurrentPracticeId](state, payload) {
    state.currentPracticeId = payload
  },
  [mutationTypes.setAuthInfoLoaded](state, payload) {
    state.authInfo.loaded = payload
  },
  [mutationTypes.loginError](state, payload) {
    state.loginError = payload
  },
  [mutationTypes.setGuestToken](state, guestToken) {
    state.guestToken = guestToken
  },
  [mutationTypes.setIsLoadingGuestToken](state, payload) {
    state.isLoadingGuestToken = payload
  }
}

export const getters = {
  authInfoLoaded: (state) => {
    return state.authInfo.loaded
  },
  userData: (state) => {
    return state.authUser
  },
  userName: (state) => {
    return state.authUser?.login ? state.authUser.login.toLowerCase() : null
  },
  boId: (state) => {
    return state.authInfo.boId
  },
  boName: (state) => {
    return state.authInfo.boName
  },
  practices: (state) => {
    return state.authInfo.practices
  },
  hasPractices: (state) => {
    return state.authInfo.practices.length > 0
  },
  currentPracticeId: (state) => {
    return state.currentPracticeId
  },
  hasRoles: (state) => {
    return state.authInfo.roles.length > 0
  },
  roles: (state) => {
    return state.authInfo.roles
  },
  canViewCaseDetails: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canViewCaseDetails)
  },
  canCreateCases: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canCreateCases)
  },
  canCreateUser: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canCreateUserProfile)
  },
  canAddTeamMember: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canAddTeamMember)
  },
  canEditTeamMember: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canEditTeamMember)
  },
  canRemoveTeamMember: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canRemoveTeamMember)
  },
  canAddPractices: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canAddPractices)
  },
  canEditPractices: (state) => {
    return state.authInfo.permissions.includes(authConstants.permissions.canEditPractices)
  },
  isSuperuser: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.superuser) ||
           state.authInfo.roles.includes(authConstants.roles.ddvcStaff)
  },
  isDoctor: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.dsoDoctor) ||
           state.authInfo.roles.includes(authConstants.roles.practiceDoctor)
  },
  isDSODoctor: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.dsoDoctor)
  },
  isExecutive: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.executive)
  },
  isPracticeDoctor: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.practiceDoctor)
  },
  isDentalStaff: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.dentalStaff)
  },
  needsToBeSigned: (state) => {
    const practices = state.authInfo.practices
    return practices.length > 0 && practices[0].needsToBeSigned
  },
  canBypassSignValidation: (_, getters) => {
    return !(getters.needsToBeSigned && (getters.isPracticeDoctor || getters.isDentalStaff))
  },
  isDdvcStaff: (state) => {
    return state.authInfo.roles.includes(authConstants.roles.ddvcStaff)
  },
  guestToken: (state) => state.guestToken,
  isLoadingGuestToken: ({ isLoadingGuestToken }) => isLoadingGuestToken
}

const actions = {
  async logIn({ state, commit, dispatch }, { username, password }) {
    dispatch('clearState')
    try {
      const loginData = await signIn({ username, password })
      commit(mutationTypes.setUserData, { ...loginData.user.profile })
      persistStateToStorage(authConstants.storageKeyUserData, state.authUser)
      await dispatch('fetchAuthInfo')
      await dispatch('verifyRoleByCountry')
    } catch (error) {
      // This must be dispatched again in case a partial user state was committed during the try block
      dispatch('clearState')
      commit(mutationTypes.loginError, {
        msg: i18n('LOGIN__ERROR_MSG'),
        title: i18n('LOGIN__ERROR_TITLE'),
        status: error.status
      })
    }
  },

  logOut({ dispatch }) {
    signOut()
    dispatch('clearState')
  },

  async isUserLoggedIn({ commit }) {
    const response = await isUserLoggedIn()

    if (!response) {
      clearStateInStorage(authConstants.storageKeyUserData)
      commit(mutationTypes.setUserData, null)
    }

    return response
  },

  registerEvents({ dispatch }) {
    getTokenManager().off('error')
    getTokenManager().on('error', function (err) {
      if (err.errorCode === 'login_required') {
        // The Okta session has expired or was closed outside the application
        // The application should return to an unauthenticated state
        dispatch('logOut')
      }
    })
  },

  async verifyRoleByCountry({ state, commit, dispatch }) {
    const i18nConfig = await getI18nConfig(Location.countryCode)
    const locatedRoles = authConstants.locatedRoles
    const locatedCurrentRole =  state.authInfo.roles.filter(val => locatedRoles.includes(val))
    const locatedRolesAllowedInCurrentCountry = i18nConfig.locatedRolesAllowedInCurrentCountry ?
                                                i18nConfig.locatedRolesAllowedInCurrentCountry : []
    const includedRolesInCurrentCountry = val => locatedRolesAllowedInCurrentCountry.includes(val)
    const verifiedRole = locatedCurrentRole.length > 0 ?
                         locatedCurrentRole.filter(includedRolesInCurrentCountry).length > 0 : true
    if(!verifiedRole){
      dispatch('logOut')
      commit(mutationTypes.loginError, {
        msg: i18n('LOGIN__ERROR_MSG'),
        title: i18n('LOGIN__ERROR_TITLE'),
        status: 'invalid role for country'
      })
    }
  },

  async fetchAuthInfo({ state, commit }) {
    const authInfo = await getAuthInfo()
    commit(mutationTypes.setAuthInfo, authInfo)
    commit(mutationTypes.setAuthInfoLoaded, true)
    persistStateToStorage(authConstants.storageKeyAuthInfo, state.authInfo)
  },

  async loadState({ commit, dispatch, getters }) {
    // First check for userData
    if (getters.userData === null) {
      const userDataFromStorage = loadStateFromStorage(authConstants.storageKeyUserData)
      if (userDataFromStorage !== null) {
        commit(mutationTypes.setUserData, userDataFromStorage)
      } else {
        signOut()
      }
    }
    // Check for practice Id in localStorage, stored separately from authInfo.
    if (getters.currentPracticeId === null) {
      const currentPracticeIdFromStorage = loadStateFromStorage(authConstants.storageKeyCurrentPracticeId)
      if (currentPracticeIdFromStorage !== null) {
        commit(mutationTypes.setCurrentPracticeId, currentPracticeIdFromStorage)
      }
    }
    // Check for authInfo in storage if it is not already loaded in the store. Fallback to server if not in storage.
    if (!getters.authInfoLoaded) {
      const authInfoFromStorage = loadStateFromStorage(authConstants.storageKeyAuthInfo)
      if (authInfoFromStorage !== null) {
        commit(mutationTypes.setAuthInfo, authInfoFromStorage)
      } else {
        await dispatch('fetchAuthInfo')
      }
    }

    // If the user has practices AND the user has no assigned practice or one that is not in their allowed list
    // Update the current practice to the first practice in this user's practice list
    if (getters.hasPractices &&
       (getters.currentPracticeId === null || !getters.practices[0].id === getters.currentPracticeId)) {
      commit(mutationTypes.setCurrentPracticeId, getters.practices[0].id)
      persistStateToStorage(authConstants.storageKeyCurrentPracticeId, state.currentPracticeId)
    } else if (!getters.hasPractices && getters.currentPracticeId !== null) {
      // If the use has no practices, but they have a current practice, set the current practice to null
      commit(mutationTypes.setCurrentPracticeId, null)
      persistStateToStorage(authConstants.storageKeyCurrentPracticeId, state.currentPracticeId)
    }
  },

  clearStorageAuthInfo() {
    clearStateInStorage(authConstants.storageKeyAuthInfo)
  },

  clearState({ commit, dispatch }) {
    clearStateInStorage(authConstants.storageKeyUserData)
    clearStateInStorage(authConstants.storageKeyAuthInfo)
    clearStateInStorage(authConstants.storageKeyCurrentPracticeId)
    commit(mutationTypes.setAuthInfoLoaded, false)
    commit(mutationTypes.setUserData, null)
    commit(mutationTypes.clearAuthInfo)
    commit(mutationTypes.setCurrentPracticeId, null)
    dispatch('Doctors/clear', null, { root: true })
    dispatch('Practices/clear', null, { root: true })
    dispatch('Roles/clear', null, { root: true })
    dispatch('BusinessOrganizations/clear', null, { root: true })
    commit(mutationTypes.loginError, {
      msg: null,
      title: null,
      status: null
    })
  },

  async loadGuestToken({ commit }) {
    try {
      commit(mutationTypes.setIsLoadingGuestToken, true)
      const guestToken = await getOktaGuestToken()
      commit(mutationTypes.setGuestToken, guestToken)
    }
    finally {
      commit(mutationTypes.setIsLoadingGuestToken, false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
