import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

/**
 *
 * @param {String} caseId
 *
 * @returns {Promise<[{
 *  treatmentPlan: String,
 *  state: String,
 *  stateReason: String,
 *  isEligibleForNCA: Boolean,
 *  treatmentOption: String,
 *  dateCreated: Date,
 *  dateUpdated: Date,
 *  dateApproved: Date,
 *  primaryProviderName: String,
 *  viewerLink: String,
 *  prescriptions: [{
 *    treatmentPlanId: String,
 *    providerId: String,
 *    providerName: String,
 *    alignerCategory: String,
 *    productCode: String,
 *    productDescription: String,
 *    rxDate: String,
 *    state: String,
 *    stateReason: String,
 *    dateCreated: Date,
 *    dateUpdated: Date
 * }]
 * }]>}
 */
const getTreatmentPlans = async (caseId) => {
  try {
    const response = await api.get(`case/${caseId}/treatment-plans`)
    return response.data
  } catch (error) {
    Logger.error('Treatment Plan API - getTreatmentPlans', error)
    throw error
  }
}

export { getTreatmentPlans }
