import ErrorException from './ErrorException'

class CreateCaseException extends ErrorException {
  constructor({ message, stack }) {
    super('CreateCaseException', message, stack)

    this.throwError()
  }
}

export default CreateCaseException
