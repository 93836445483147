export const mutationTypes = {
  changeLocale: 'changeLocale',
  notifyI18nLoadState: 'notifyI18nLoadState',
}

export const state = {
  currentLocale: null,
  i18nConfigLoaded: null
}

export const mutations = {
  [mutationTypes.changeLocale](state, payload) {
    state.currentLocale = payload.locale
  },
  [mutationTypes.notifyI18nLoadState](state, payload) {
    state.i18nConfigLoaded = payload.status
  }
}

export default {
  namespaced: true,
  mutations,
  state
}