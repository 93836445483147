<template>
  <section>
    <sdc-modal
      ref="case-flow-selector-modal"
      name="case-flow-selector-modal"
      size="small"
      :title-props="titleProps"
      :button-props="buttonProps"
      @onConfirm="onSubmit"
    >
      <case-flow-selector-form ref="form" />
    </sdc-modal>

    <create-case-by-appointment-modal
      ref="create-case-by-appointment-modal"
    />
  </section>
</template>

<script>
import { i18n } from '_utils_/i18n'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import SdcModal from '_molecules_/SdcModal'
import CaseFlowSelectorForm from '_molecules_/CaseFlowSelectorForm'
import CreateCaseByAppointmentModal from '_molecules_/CreateCaseByAppointmentModal/index.vue'
import {appointmentsConstants} from '@/utils/constants'

export default {
  name: 'CaseFlowSelectorModal',
  components: {
    CreateCaseByAppointmentModal,
    CaseFlowSelectorForm,
    SdcModal
  },
  data() {
    return {
      titleProps: {
        text: 'CASE_FLOW_SELECTOR_MODAL__TITLE'
      },
      buttonProps: {
        confirmProps: {
          text: 'CASE_FLOW_SELECTOR_MODAL__SUBMIT_BUTTON'
        },
        cancelProps: {
          text: 'CASE_FLOW_SELECTOR_MODAL__CANCEL_BUTTON'
        }
      }
    }
  },
  computed: {
    ...mapGetters('Appointments', ['canCreateCaseWithAppointment']),
    modalRef() {
      return this.$refs['case-flow-selector-modal']
    },
    modalCaseAppointmentRef() {
      return this.$refs['create-case-by-appointment-modal']
    }
  },
  methods: {
    ...mapMutations('CreateCase', ['setFlowType']),
    ...mapActions('Appointments', ['requestTodayStatusScheduledAppointments']),
    i18n,
    async onSubmit(close) {
      if (!this.$refs.form.validate()) {
        return
      }

      close()
      const flowType = this.$refs.form.selectedOrderType
      this.setFlowType(flowType)

      if (await this.reviewPreConditions(flowType)) {
        await this.$router.push({name: flowType, params: {
          redirectFromCaseFlow: true
        }})
      }
    },
    open() {
      this.modalRef.open()
    },
    async reviewPreConditions(flow) {
      let canContinue = true

      if (flow === 'create-case') {
        await this.requestTodayStatusScheduledAppointments({ ...appointmentsConstants.tableRequestState })
        canContinue = !this.canCreateCaseWithAppointment
        if (this.canCreateCaseWithAppointment) {
          this.modalCaseAppointmentRef.openModal()
        }
      }

      return canContinue
    }
  },
}
</script>
