<template>
  <div
    id="error-information"
    class="error-information"
  >
    <div class="container">
      <sdc-title
        level="3"
        class="error-information__container__status-code"
      >
        {{ httpStatusCode }}
      </sdc-title>
      <sdc-title level="1">
        {{ title }}
      </sdc-title>
      <div class="error-information__container__text">
        {{ descriptionPart1 }}
        <a :href="redirectUrl">{{ redirect }}</a>
        {{ descriptionPart2 }}
        {{ phone }}
      </div>
    </div>
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'

export default {
  name: 'ErrorInformation',
  components: {
    SdcTitle
  },
  props: {
    httpStatusCode: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    descriptionPart1: {
      type: String,
      default: ''
    },
    descriptionPart2: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: ''
    },
    redirectUrl: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.error-information{
  display: flex;
  justify-content: center;
  margin: 20px 40px 0 40px;

  &__container {
    width: 50%;

    &__status-code {
      color: $gray-600;
    }

    &__text {
      font-size: 2.5vh;
      color: lighten($gray-600, 10);
    }
  }
}
</style>
