const TABLE_FIELDS =  [
  { key: 'appointmentDateCreated', label: 'APPOINTMENTS__LIST__TABLE_HEADER_CREATED__LABEL', thClass: 'sdc-table__header-column', sortable: true },
  { key: 'fullName', label: 'APPOINTMENTS__LIST__TABLE_HEADER_NAME__LABEL', thClass: 'sdc-table__header-column', sortable: true },
  { key: 'dateAppointment', label: 'APPOINTMENTS__LIST__TABLE_HEADER_DATE__LABEL',  thClass: 'sdc-table__header-column', sortable: true, sortDirection: 'desc' },
  { key: 'currentStatus', label: 'APPOINTMENTS__LIST__TABLE_HEADER_STATUS__LABEL', thClass: 'sdc-table__header-column', sortable: false },
]

const COLUMNS_TO_SORT = {
  appointmentDateCreated: 'appointment_date_created',
  dateAppointment: 'date_appointment',
  fullName: 'full_name'
}

export {
  TABLE_FIELDS,
  COLUMNS_TO_SORT
}
