import axios from 'axios'
import * as rax from 'retry-axios'

const getSdcInstance = baseURL => (
  axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  })
)

const sdcInstance = getSdcInstance(process.env.VUE_APP_SDC_API_BASE_URL)
const sdcInstanceWithRetries = getSdcInstance(process.env.VUE_APP_SDC_API_BASE_URL)
const sdcInstanceWithLongRetries = getSdcInstance(process.env.VUE_APP_SDC_API_BASE_URL)
const sdcInstanceOkta = getSdcInstance(process.env.VUE_APP_OKTA_DOMAIN)
const sdcInstanceNoAuth = getSdcInstance(process.env.VUE_APP_SDC_API_BASE_URL)
const sdcInstanceProvider = getSdcInstance(process.env.NODE_ENV === 'development' ?
                              process.env.VUE_APP_SDC_API_PROVIDER_BASE_URL : process.env.VUE_APP_SDC_API_BASE_URL)

 //0 - 1 - 2 - 4 - 8 seconds
sdcInstanceWithRetries.defaults.raxConfig = {
  instance: sdcInstanceWithRetries,
  retry: 5,
  httpMethodsToRetry: ['GET'],
  statusCodesToRetry: [ [404, 404]],
  backoffType: 'exponential'
}

rax.attach(sdcInstanceWithRetries)

 //4 - 4 - 4 - 4 - 4 seconds
sdcInstanceWithLongRetries.defaults.raxConfig = {
  instance: sdcInstanceWithLongRetries,
  retry: 5,
  retryDelay: 4000,
  httpMethodsToRetry: ['GET'],
  statusCodesToRetry: [ [404, 404]],
  backoffType: 'static'
}

rax.attach(sdcInstanceWithLongRetries)


export {
  sdcInstanceOkta,
  sdcInstance,
  sdcInstanceWithRetries,
  sdcInstanceWithLongRetries,
  sdcInstanceNoAuth,
  sdcInstanceProvider
}
