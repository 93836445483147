<template>
  <div
    name="patient-consent"
    class="patient-consent"
  >
    <sdc-title
      level="4"
      margin-level="2"
    >
      {{ title }}
    </sdc-title>
    <sdc-checkbox
      v-model="termsStatus"
      name="patient-consent"
      rules="requiredTerms|requiredTermsBoolean"
    >
      {{ i18n('PATIENT_CONSENT__AGREE') }}
      <template v-if="showEsignConsent">
        <a
          :key="getUrlId('ESIGN_CONSENT')"
          :href="esignConsentUrl"
          target="_blank"
        >{{ i18n('PATIENT_CONSENT__ESIGN_CONSENT') }}</a>
        <span :key="getCommaId('ESIGN_CONSENT')">{{ i18n('TEXT_COMMA') }} </span>
      </template>
      <template v-for="(url, key) in legalDocumentsUrls">
        <a
          :key="getUrlId(key)"
          :href="url"
          target="_blank"
        >{{ i18n (`PATIENT_CONSENT__${key}`) }}</a>
        <span :key="getCommaId(key)">{{ i18n('TEXT_COMMA') }} </span>
      </template>
      <template v-if="termsUrl">
        <span v-show="!hasSmilePayConditions"> {{ i18n('PATIENT_CONSENT__AND') }} </span>
        <a
          :href="termsUrl"
          target="_blank"
        >{{ i18n('PATIENT_CONSENT__TERMS') }}</a>
      </template>
      <template v-if="hasSmilePayConditions">
        <span v-show="!patientConsentError">
          <span>{{ i18n('TEXT_COMMA') }} {{ i18n('PATIENT_CONSENT__AND') }} </span>
          <a
            id="smilePay"
            :href="financeAgreementUrl"
            @click.prevent="openSmilepayConditions"
          >{{ i18n('PATIENT_CONSENT__SMILEPAY') }}</a>
        </span>
      </template>
      <span>{{ i18n('TEXT_PERIOD') }}</span>
    </sdc-checkbox>
    <sdc-checkbox
      v-if="showWaiverSignatureCheckbox"
      v-model="questionInfo.waiverSigned"
      name="patient-signature-consent"
      rules="requiredTerms|requiredTermsBoolean"
      @input="signatureModalOpen"
    >
      {{ i18n('SIGN_TERMS_CHECKBOX_TEXT') }}
    </sdc-checkbox>
    <signature-modal
      ref="signatureModal"
      :document-kind="waiverDocumentKind"
    />
  </div>
</template>
<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcTitle from '_atoms_/SdcTitle'
import SignatureModal from '_molecules_/SignatureModal'
import { createCaseConstants } from '_utils_/constants'
import { getPracticeDataByCaseId } from '_api_/practices.api'
import { DOCUMENT_KINDS, externalUrls } from '_utils_/constants'

export default {
  name: 'PatientConsent',
  components: {
    SdcCheckbox,
    SdcTitle,
    SignatureModal
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    disableGetSmilePayTerms: {
      type: Boolean,
      default: false
    },
    validLegalDocuments: {
      type: Array,
      default: () => []
    },
    applicationId: {
      type: String,
      default: ""
    },
    showEsignConsent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      termsStatus: this.$attrs.value || false,
      urlSmilePay: '',
      patientConsentError: true,
      practiceid: '',
      waiverDocumentKind: DOCUMENT_KINDS.confidentialityWaiver
    }
  },
  computed: {
    ...mapState('CreateCase', [
      'guardianInfo',
      'personalInfo',
      'shippingAddress',
      'questionInfo',
      'financeAgreementUrl',
      'caseId',
      'flowType'
    ]),
    ...mapState('Terms', [
      'casesQuestionError',
      'smilePayTermsPDF'
    ]),
    ...mapState('Auth', ['currentPracticeId']),
    ...mapGetters('CreateCase', ['smilePayApplicationPayload']),
    legalDocumentsUrls(){
      if(this.validLegalDocuments.length === 0) {
        return I18nConfig.legalDocumentsUrls
      }

      const toReturn = Object.entries(I18nConfig.legalDocumentsUrls).filter(item => this.validLegalDocuments.includes(item[0]))
      return Object.fromEntries(toReturn)
    },
    termsUrl() {
      return I18nConfig.termsUrl
    },
    hasSmilePayConditions() {
      return I18nConfig.hasSmilePayConditions && !this.disableGetSmilePayTerms
    },
    confidentialityWaiverEnabled(){
      return I18nConfig.waiverConsentFormEnabled
    },
    showWaiverSignatureCheckbox() {
      var showSignature = false
      if (!this.caseId) {
        showSignature = (
          this.confidentialityWaiverEnabled &&
          createCaseConstants.flowType.CASE
        )
      } else {
        this.getPracticeData(this.caseId)
        showSignature = (
          this.confidentialityWaiverEnabled &&
          this.currentPracticeId !== this.practiceid
        )
      }
      return showSignature
    },
    esignConsentUrl() {
      return externalUrls.esignConsent
    }
  },
  watch: {
    applicationId() {
      this.getTerms()
    },
    termsStatus(newVal) {
      this.$emit('input', newVal)
    },
    hasSmilePayConditions(newVal) {
      if (newVal && this.financeAgreementUrl.length === 0) {
        this.getTerms()
      } else if (newVal && this.applicationId) {
        this.createFinancePDF(this.applicationId)
      }
    },
    '$attrs.value': function(newVal) {
      this.termsStatus = newVal
    }
  },
  beforeMount() {
    if (this.hasSmilePayConditions) {
      this.getTerms()
    }
  },
  beforeDestroy() {
    window.URL.revokeObjectURL(this.financeAgreementUrl)
  },
  methods: {
    ...mapActions('Terms', ['getSmilePayTerms', 'setNewSmilePayTerms']),
    ...mapMutations('CreateCase', ['setFinanceAgreementId','setFinanceAgreementUrl']),
    signatureModalOpen() {
      let {waiverSigned} = this.questionInfo
      if (waiverSigned){
        waiverSigned = false
        this.$refs.signatureModal.open()
      }
    },
    async getTerms() {
      if (this.applicationId) {
        await this.setNewSmilePayTerms({ applicationId: this.applicationId })
        this.createFinancePDF(this.applicationId);
        return;
      }

      const patientData = this.smilePayApplicationPayload
      await this.createPDF(patientData)
    },
    async createPDF(patientData) {
      const smilePayTerms = await this.getSmilePayTerms({ patientData })

      if (!smilePayTerms) {
        return
      }

      const smilePayTermsId = smilePayTerms.id
      this.setFinanceAgreementId(smilePayTermsId)
      const blob = new Blob([this.smilePayTermsPDF], {
        type: 'application/pdf'
      })
      const url = window.URL.createObjectURL(blob)
      this.setFinanceAgreementUrl(url)

      this.patientConsentError = false
    },
    createFinancePDF(applicationId) {
      this.setFinanceAgreementId(applicationId)
      const byteArray = Uint8Array.from(
        window
          .atob(this.smilePayTermsPDF.content)
          .split('')
          .map((char) => char.charCodeAt(0))
      )
      const blob = new Blob([byteArray], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      this.setFinanceAgreementUrl(url)

      this.patientConsentError = false
    },
    async getPracticeData(caseId) {
      const response = await getPracticeDataByCaseId(caseId)
      this.practiceid = response.id
      },
    openSmilepayConditions() {
      const smilePayPdfWindow = window.open(this.financeAgreementUrl, '_blank')
      smilePayPdfWindow.addEventListener(
        'load',
        () => {
          setTimeout(() => {
            smilePayPdfWindow.document.title = 'SmilePayConditions.pdf'
          }, 1000)
        }
      )
    },
    getUrlId(id){
      return `${id}url`
    },
    getCommaId(id){
      return `${id}span`
    },
    i18n
  }
}
</script>
