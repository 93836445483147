<template>
  <div
    v-if="appHasNewVersion"
    class="app-updater"
  >
    <span>
      {{ i18n('APP_VERSION__NEW_VERSION_AVAILABLE__TEXT') }}
      <a
        class="app-updater__link"
        href="#"
        @click.prevent="confirmReload"
      >
        {{ i18n('APP_VERSION__CLICK_HERE__LINK') }}
      </a>
      {{ i18n('APP_VERSION__AVOID_ISSUES__TEXT') }}
      {{ timeToRefresh }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { i18n } from '_utils_/i18n'
import moment from 'moment'

const TIME_FORMAT = 'mm:ss'
const TIME_TO_STOP = '00:00'

export default {
  name: 'AppUpdater',
  data() {
    return {
      refreshCounter: `${process.env.VUE_APP_MAX_TIME_TO_FORCE_UI_UPDATE_IN_MINUTES}:00`,
    }
  },
  computed: {
    ...mapState('AppVersion', ['appHasNewVersion']),
    timeToRefresh() {
      return i18n('APP_VERSION__AUTO_REFRESH_TIME', [this.refreshCounter])
    }
  },
  watch: {
    appHasNewVersion(newVersionDetected) {
      if (newVersionDetected) {
        const refreshDueDate = moment().add(process.env.VUE_APP_MAX_TIME_TO_FORCE_UI_UPDATE_IN_MINUTES, 'minutes')
        const refreshTimer = setInterval(() => {
          this.refreshCounter = moment.utc(refreshDueDate.diff(new Date())).format(TIME_FORMAT)
          if (this.refreshCounter === TIME_TO_STOP) {
            clearInterval(refreshTimer)
            window.location.reload()
          }
        }, 1000)
      }
    }
  },
  methods: {
    i18n,
    confirmReload() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
.app-updater {
  padding: 01em;
  font-size: 14pt;
  background-color: #4A00D9;
  text-align: center;
  color: $white;

  &__link {
    color: $white;
    text-decoration: underline;
  }
}
</style>
