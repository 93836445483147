import axios from 'axios'
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getStripeToken = async ({ number, month, year, cvc }) => {
  try {
    const payload = {
      'card[number]': number,
      'card[exp_month]': month,
      'card[exp_year]': year,
      'card[cvc]': cvc
    }

    // We must encode the payload to make it a query
    // axios needs it for POST requests with a form
    // https://github.com/axios/axios/issues/1281
    const esc = encodeURIComponent
    const query = Object.keys(payload).map(k => `${esc(k)}=${esc(payload[k])}`).join('&')

    const response = await axios.post('https://api.stripe.com/v1/tokens', query, {
      headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_STRIPE_PUBLIC_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    })
    Logger.info('CreditCard API - getStripeToken executed')

    return { ...response.data.card, token: response.data.id }
  } catch (error) {
    Logger.error('CreditCard API - getStripeToken', error)
    throw error.response?.data.error || {}
  }
}

const sendPBCardInformation = async ({caseId, paymentInformationRequest}) => {
  try {
    const response = await api.post(`/case/${caseId}/paymentinformation`, paymentInformationRequest)
    Logger.info('CreditCard API - sendPBCardInformation executed')
    return response.data
  } catch (error) {
    Logger.error('CreditCard API - sendPBCardInformation', error)
    throw error
  }
}


export {
  getStripeToken,
  sendPBCardInformation
}
