const ERROR_CODES = {
  forbidden: 'forbidden',
  notfound: 'not found',
  toast: 'toast'
}

export const state = {
  errorType: '',
  errorMsg: {}
}

export const getters = {
  isNotFoundError({ errorType }) {
    return errorType === ERROR_CODES.notfound
  },
  isForbiddenError({ errorType }) {
    return errorType === ERROR_CODES.forbidden
  },
  isToastError({ errorType }) {
    return errorType === ERROR_CODES.toast
  }
}

export const mutationTypes = {
  clearErrorState: 'clearErrorState',
  setNotFoundError: 'setNotFoundError',
  setForbiddenError: 'setForbiddenError',
  setToastError: 'setToastError'
}

export const mutations = {
  [mutationTypes.clearErrorState](state) {
    state.errorType = ''
    state.errorMsg = {}
  },
  [mutationTypes.setNotFoundError](state) {
    state.errorType = ERROR_CODES.notfound
  },
  [mutationTypes.setForbiddenError](state) {
    state.errorType = ERROR_CODES.forbidden
  },
  [mutationTypes.setToastError](state, msg) {
    state.errorType = ERROR_CODES.toast
    state.errorMsg = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
