<template>
  <div class="shipping-label-pickup-form">
    <sdc-title
      level="5"
      margin-level="3"
    >
      {{ i18n('PICKUP_SCHEDULE_TITLE') }}
    </sdc-title>
    <div
      v-if="showEmptyDatesError"
      class="error"
    >
      <div class="shipping-label-pickup-form__error invalid-feedback d-block">
        <span>{{ i18n('PICKUP_SCHEDULE__ERROR_DATES') }}</span>
      </div>
    </div>
    <form
      v-if="!showEmptyDatesError"
      class="shipping-label-pickup-form__form"
    >
      <div>
        <sdc-loading
          v-if="createPickupLoading"
          type="modal"
          name="shipping-label-pickup-form-modal"
        />
        <validation-observer ref="pickupForm">
          <label class="select-label">
            {{ i18n('PICKUP_SCHEDULE__DATE_LABEL') }}
          </label>
          <div ref="pickupDateEl">
            <date-time-picker
              v-model="pickup.dateTime"
              :dates="availableDays"
            />
          </div>
          <div>
            <label class="select-label">
              {{ i18n('PICKUP_SCHEDULE__INSTRUCTIONS_LABEL') }}
            </label>
            <fieldset class="form-group">
              <sdc-textarea
                v-model="pickup.instructions"
                name="instructions"
                :max-length="50"
                :placeholder="i18n('PICKUP_SCHEDULE__INSTRUCTIONS_PLACEHOLDER')"
              />
            </fieldset>
          </div>

          <div class="shipping-label-pickup-form__address">
            <sdc-checkbox
              v-model="pickup.usePracticeAddress"
              name="use-practice-address"
              class="shipping-label-pickup-form__address__text"
            >
              {{ i18n('PICKUP_SCHEDULE__ADDRESS_CHECKBOX_LABEL') }}
            </sdc-checkbox>
          </div>

          <div
            v-if="showPracticeAddressError"
            class="shipping-label-pickup-form__practice_address_error shipping-label-pickup-form__error invalid-feedback d-block"
          >
            <span>{{ i18n('PICKUP_SCHEDULE__ERROR_PRACTICE_ADDRESS') }}</span>
          </div>

          <div
            v-if="showPracticeAddress"
            class="shipping-label-pickup-form__address"
          >
            <address-detail
              :address="practiceData"
              class="col shipping-label-pickup-form__practice_address shipping-label-pickup-form__address__text"
            />
          </div>
          <div
            v-if="!pickup.usePracticeAddress"
            ref="shippingAddressEl"
          >
            <shipping-address
              :title="'PICKUP_SCHEDULE__ADDRESS_SECTION_TITLE'"
              title-level="5"
              :model="pickup.pickupAddress"
              class="shipping-label-pickup-form__address"
            />
          </div>
          <sdc-button
            :disabled="isProcessing"
            class="mt-3 shipping-label-pickup-form__submit_button"
            @click="sendPickup"
          >
            {{ i18n('PICKUP_SCHEDULE__SUBMIT_BUTTON') }}
          </sdc-button>
        </validation-observer>
      </div>
    </form>
  </div>
</template>

<script>
import ShippingAddress from '_molecules_/ShippingAddress'
import { getAvailableHours } from '_api_/shipment.api'
import DateTimePicker from '_molecules_/DateTimePicker'
import AddressDetail from '_atoms_/AddressDetail'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import SdcTextarea from '_atoms_/SdcTextarea'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcLoading from '_atoms_/SdcLoading'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formatRequestForPickup } from '_utils_/shipmentHelper'
import { LOADING_STATES, PICKUP_ERROR_CODES } from '@/utils/constants'

export default {
  name: 'ShippingLabelPickupForm',
  components: {
    SdcButton,
    SdcTitle,
    SdcTextarea,
    SdcCheckbox,
    ValidationObserver,
    ShippingAddress,
    SdcLoading,
    AddressDetail,
    DateTimePicker
  },
  data() {
    return {
      defaultDate: '',
      availableDays: [],
      availableDaysLoadingStatus: LOADING_STATES.idle,
      isValidatingZipCode: false,
      pickup: {
        dateTime: {
          date: '',
          from: '',
          to: '',
        },
        instructions: '',
        usePracticeAddress: true,
        pickupAddress: {
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zipCode: ''
        }
      }
    }
  },
  computed: {
    ...mapState('Shipment', [
      'shipmentInformation',
      'createPickupLoadingStatus',
      'createPickupError',
      'practiceData',
      'getPracticeDataLoadingStatus'
    ]),
    ...mapGetters('Auth', ['userData']),
    showPracticeAddressError() {
      return this.pickup.usePracticeAddress &&
        this.getPracticeDataLoadingStatus === LOADING_STATES.rejected
    },
    showPracticeAddress() {
      return this.pickup.usePracticeAddress && this.practiceData
    },
    createPickupLoading() {
      return this.createPickupLoadingStatus === LOADING_STATES.pending
    },
    pickupDateRules() {
      return this.scheduleHours.length >= 2 ? 'required' : ''
    },
    showEmptyDatesError() {
      return (
        this.availableDaysLoadingStatus === LOADING_STATES.resolved
          || this.availableDaysLoadingStatus === LOADING_STATES.rejected
      ) && this.availableDays.length < 1
    },
    isProcessing() {
      return this.createPickupLoading || this.isValidatingZipCode
    }
  },
  watch: {
    createPickupLoadingStatus(newState) {
      if (newState === LOADING_STATES.rejected) {
        const messages = {
          [PICKUP_ERROR_CODES.invalidAddress]: 'PICKUP_SCHEDULE__ERROR_INVALID_ADDRESS',
          [PICKUP_ERROR_CODES.unavailableRates]: 'PICKUP_SCHEDULE__ERROR_NO_AVAILABLE_PICKUP'
        }

        const toastMessage = messages[this.createPickupError.errorCode] || 'PICKUP_SCHEDULE__ERROR_SCHEDULE_PICKUP'

        this.toast({
          msg: i18n(toastMessage, [I18nConfig.supportPhoneNumber]),
          title: i18n('PICKUP_SCHEDULE_TITLE')
        })
      }
    }
  },
  created() {
    getAvailableHours()
      .then((days) => {
        this.availableDays = days
        this.availableDaysLoadingStatus = LOADING_STATES.resolved
      })
      .catch(() => {
        this.availableDays = []
        this.availableDaysLoadingStatus = LOADING_STATES.rejected
      })
    this.getPracticeData(this.$route.params.id)
  },
  methods: {
    ...mapActions('Shipment', [
      'createPickup',
      'getPracticeData'
    ]),
    ...mapActions('Address', ['isValidZipcode']),
    prepareRequest() {
      const caseNumber = this.$route.params.id
      return formatRequestForPickup(
        this.pickup,
        this.userData,
        this.practiceData,
        this.shipmentInformation,
        caseNumber
      )
    },
    async sendPickup() {
      const { usePracticeAddress, pickupAddress } = this.pickup
      const form = this.$refs.pickupForm
      const success = await form.validate()

      let isValidZipCode = true

      if (!usePracticeAddress && pickupAddress.zipCode) {
        this.isValidatingZipCode = true
        const response = await this.isValidZipcode(pickupAddress)

        if (response.error && !response.isUnhandled) {
          this.setShippingFieldError(response.error)
          isValidZipCode = false
        }

        if (response.error && response.isUnhandled) {
          this.toast({
            msg: i18n('ERROR__PICKUP__ZIPCODE', [I18nConfig.supportPhoneNumber]),
            title: i18n('PICKUP_SCHEDULE_TITLE')
          })
          isValidZipCode = false
        }

        this.isValidatingZipCode = false
      }
      if(!success && this.isPickupDateError()){
        this.$refs.pickupDateEl.scrollIntoView({ behavior: 'smooth' })
        return
      }
      if (this.isShippingAddressError(success, usePracticeAddress, isValidZipCode)){
        this.$refs.shippingAddressEl.scrollIntoView({ behavior: 'smooth' })
        return
      }

      const req = this.prepareRequest()
      await this.createPickup(req)
    },
    setShippingFieldError(errorMsg) {
      this.$refs.pickupForm.setErrors({
        'shipping-zip': errorMsg
      })
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    isPickupDisabled(data) {
      return data.length < 2
    },
    isPickupDateError(){
      const { dateTime: { date, from, to}} = this.pickup
      return (date === "" || from === "" || to === "")
    },
    isShippingAddressError(success, usePracticeAddress, isValidZipCode){
      return (!success || (
        usePracticeAddress && this.getPracticeDataLoadingStatus === LOADING_STATES.rejected
      ) || !isValidZipCode)
    },
    i18n,
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.shipping-label-pickup-form {
  margin-top: 18px;
  &__address {
    margin-top: 25px;
    padding-left: 0;
    &__text {
      color: $secondary;
    }
  }
  &__error {
    margin-bottom: 24px;
  }
}
</style>
