<template>
  <div
    id="creation-container"
    class="container"
  >
    <sdc-loading
      v-if="submitingCase"
      :title="i18n('LOADING__CREATE_CASE_TITLE')"
    />
    <section class="creation-container--header">
      <sdc-title level="2">
        {{ i18n('CREATE_CASE__TITLE') }}
      </sdc-title>
      <sdc-button
        v-if="showCancelButton"
        type="link"
        :class="sdcButtonClass"
        @click="handleCancel"
      >
        {{ i18n('LABEL__CANCEL') }}
      </sdc-button>
      <no-doctors-modal ref="noDoctorsModal" />
    </section>
    <section class="section-panel-rounded">
      <stepper
        v-bind="stepperProps"
        @setCurrentStep="setStepperCurrentStep"
      />
    </section>
  </div>
</template>

<script>

import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Stepper from '_molecules_/Stepper'
import NoDoctorsModal from '_molecules_/NoDoctorsModal'
import SdcLoading from '_atoms_/SdcLoading'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import { CASE_RESPONSE_CODES, createCaseConstants, fastTrackPaymentMethods, IMPRESSION_MODES } from '_utils_/constants'
import { COMPONENTS } from './constants'

const getToastMessagekeys = (caseResult, supportPhoneNumber, impressionMode) =>  {
  if (caseResult[CASE_RESPONSE_CODES.error]) {
    return [
      supportPhoneNumber ? 'CREATE_CASE__SUBMIT_ERROR' : 'CREATE_CASE__SUBMIT__DEFAULT_ERROR'
    ]
  }
  const messages = []
  if (caseResult[CASE_RESPONSE_CODES.warning]) {
    messages.unshift(supportPhoneNumber ? 'CREATE_CASE__SUBMIT_WARNING' : 'CREATE_CASE__SUBMIT__DEFAULT_WARNING')
  } else {
    messages.unshift('CREATE_CASE__SUBMIT_SUCCESS')
  }
  if (I18nConfig.showSendPatientImpressionNotification && impressionMode === IMPRESSION_MODES.manual) messages.unshift('CREATE_CASE__SUBMIT__SEND_PATIENT_IMPRESSION')
  if (caseResult[CASE_RESPONSE_CODES.errorCardToken]) messages.unshift('CREATE_CASE__PAYMENT_ERROR')
  return messages
}

export default {
  name: 'CreationContainer',
  components: {
    SdcButton,
    Stepper,
    NoDoctorsModal,
    SdcLoading,
    SdcTitle
  },
  data() {
    return {
      stepperProps: {
        handleSubmit: this.handleSubmit,
        steps: []
      },
      sdcButtonClass: 'creation-container-header--cancel text-decoration-none',
      submitingCase: false,
      showCancelButton:false,
      currentStepName: ''
    }
  },
  computed: {
    ...mapState('CreateCase', ['caseId', 'caseError', 'fastTrackInfo', 'practiceId', 'isPaymentUpdate', 'submitInfo']),
    ...mapGetters('CreateCase', ['getDoctorsForCase']),
    ...mapGetters('Auth', ['hasPractices', 'isSuperuser']),
    ...mapGetters('Practices', ['getLocalPractices']),
    ...mapGetters('InformationForm', ['isCaseResumed']),
    ...mapGetters('Payment', ['isPaymentFeatureEnabled']),
    ...mapState('Features', ['useNewCheckoutForAllPractices'])
  },
  mounted() {
    this.setFlowType(createCaseConstants.flowType.CASE)
    this.init().then(() => {
      // If the case has a practice id, but the list of doctors is empty, warn the user to verify the team
      if (this.practiceId !== null && this.getDoctorsForCase.length === 0) {
        this.$refs.noDoctorsModal.open()
      }
    })
    this.setCancelButtonVisibility()
  },
  beforeMount() {
    this.stepperProps.steps = this.loadSteps()
  },
  destroyed() {
    this.clearData()
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['clearState', 'setFlowType', 'clearBoId', 'clearPracticeId', 'setPaymentUpdate']),
    ...mapMutations('InformationForm', ['clearInfo']),
    ...mapMutations('Photos', ['clearPatientPhotos']),
    ...mapActions('CreateCase', ['init', 'createCase', 'resetCreateCaseFlow']),
    ...mapActions('Terms', ['getSmilePayTerms']),
    async validateStep({ model, next }) {
      if (model.validate) {
        await model.validate(next)
      }
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    clearData() {
      this.clearState()
      this.clearBoId()
      this.clearPracticeId()
      this.clearInfo()
    },
    async handleSubmit() {
      this.submitingCase = true
      const caseResult = await this.createCase()
      this.submitingCase = false

      if (!this.isPaymentUpdate) {
        const supportPhoneNumber = I18nConfig.supportPhoneNumber
        getToastMessagekeys(caseResult, supportPhoneNumber,  this.submitInfo?.order.type).forEach(message => {
          this.toast({
            msg: i18n(message, [supportPhoneNumber]),
            title: i18n('CREATE_CASE__TITLE')
          })
        })
      }

      if (caseResult.error) {
        return false
      }

      if (caseResult.hasError) {
        this.savePaymentUpdate({
          isPaymentUpdate: true,
          caseId: caseResult.caseId,
          fastTrackOption: caseResult.fastTrackOption,
          patientUserId: caseResult.patientUserId
        })
        this.setCancelButtonVisibility()

        const msg = caseResult.isBadRequest
          ? i18n('CREATE_CASE__INVALID_PAYMENT_ERROR')
          : i18n('CREATE_CASE__SUBMIT__DEFAULT_WARNING')
        this.toast({
          msg,
          title: i18n('CREATE_CASE__TITLE')
        })

        return false
      }

      this.openAllianzPaymentSite()
      this.savePaymentUpdate({
        isPaymentUpdate: false,
        caseId: '',
        fastTrackOption: '',
        patientUserId: ''
      })

      this.$emit(
        'createCase',
        caseResult)
    },
    openAllianzPaymentSite() {
      if (this.fastTrackInfo.paymentMethod === fastTrackPaymentMethods.SmileNow) {
        const allianzPaymentUrl = I18nConfig.allianzPaymentUrl

        if (allianzPaymentUrl) window.open(allianzPaymentUrl, '_blank')
      }
    },
    handleCancel() {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    setCancelButtonVisibility() {
      this.showCancelButton = true
      this.showCancelButton = I18nConfig.hideCancelCaseCreationButtonOnSteps? !((I18nConfig.hideCancelCaseCreationButtonOnSteps.includes(this.currentStepName)) || this.isPaymentUpdate) : !this.isPaymentUpdate
    },
    setStepperCurrentStep(step){
      this.currentStepName = step
      this.setCancelButtonVisibility()
    },
    savePaymentUpdate(data){
      this.setPaymentUpdate({ data })
    },
    loadSteps() {
      let newStepOrder = []
      const stepsOrder = this.isPaymentFeatureEnabled
        ? I18nConfig.newWorkflowOrder
        : I18nConfig.workflowOrder

      stepsOrder.forEach(item => {
        if (this.getSkipComponent(item)) {
          return
        }
        newStepOrder.push(
          {
            name: item,
            component: COMPONENTS[item].component,
            title: i18n(COMPONENTS[item].title),
            validate: this.validateStep
          })
      })
      return newStepOrder
    },
    getSkipComponent(componentName) {
      switch (componentName) {
        case 'CreateCaseEmail':
          return (this.isCaseResumed && this.$route.params.redirectFromAppointmentStatus)
        default:
          return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/components/_sdc-modal';

.creation-container {
  &--header {
    display: flex;
    justify-content: space-between;

    &--cancel {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
