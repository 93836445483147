<template>
  <information
    :model="model"
    :enabled-date-of-birth="enabledDateOfBirth"
    :check-email="checkExistingEmail"
    :check-email-guardian="checkExistingGuardianEmail"
    guardian-title="CREATE_CASE__GUARDIAN_INFO_TITLE"
    personal-title="CREATE_CASE__PERSONAL_INFO_TITLE"
    shipping-address-title="CREATE_CASE__SHIPPING_ADDRESS_TITLE"
  >
    <div
      v-if="canPatientBeResumed"
      class="resume-case__message-container"
    >
      <sdc-message
        :icon-path="infoIconPath"
        :text-message="i18n('CREATE_CASE__RESUME_CASE_MESSAGE')"
      />
    </div>
  </information>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Information from '_organisms_/Information'
import SdcMessage from '_atoms_/SdcMessage'
import { i18n } from '_utils_/i18n'
export default {
  name: 'CreateCaseInformation',
  components: {
    Information,
    SdcMessage
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('InformationForm', ['canPatientBeResumed', 'canGuardianBeResumed']),
    enabledDateOfBirth(){
      return this.canPatientBeResumed && this.canGuardianBeResumed
    },
    infoIconPath() {
      return require('_assets_/icon-info.svg')
    }
  },
  methods: {
    i18n,
    ...mapActions('InformationForm', ['checkExistingEmail', 'checkExistingGuardianEmail'])
  }
}
</script>
<style scoped>
  .resume-case__message-container{
    margin-top: 45px;
  }
</style>
