// Auth store persistence
const authConstants = {
  storageKeyUserData: 'auth-store-user-data',
  storageKeyAuthInfo: 'auth-store-auth-info',
  storageKeyCurrentPracticeId: 'auth-store-current-practice',
  permissions: {
    canCreateCases: 'CREATE_NEW_CASE',
    canViewCaseDetails: 'VIEW_CASE_DETAILS',
    canEditUserProfile: 'EDIT_USER_PROFILE',
    canCreateUserProfile: 'CREATE_USER_PROFILE',
    canAddTeamMember: 'ADD_TEAM_MEMBER',
    canAddPractices: 'ADD_PRACTICES',
    canEditPractices: 'EDIT_PRACTICES',
    canEditTeamMember: 'EDIT_TEAM_MEMBER',
    canRemoveTeamMember: 'REMOVE_TEAM_MEMBER'
  },
  roles: {
    dsoDoctor: 'DSO_DOCTOR',
    practiceDoctor: 'PRACTICE_DOCTOR',
    dentalStaff: 'DENTAL_STAFF',
    executive: 'EXECUTIVE',
    superuser: 'SUPERUSER',
    ddvcStaff: 'DDVC_STAFF'
  },
  locatedRoles: [ 'DDVC_STAFF' ]
}

// HTTP constants
const LOADING_STATES = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected'
}

// Nav constants
const externalUrls = {
  shopify: 'https://sdc-b2b.myshopify.com/',
  doctorReferal: 'https://smiledirectclub.com/office-direct/',
  termsAndConditions: 'https://smiledirectclub.com/partner-terms/',
  esignConsent: 'https://smiledirectclub.com/esign-consent/'
}

const inputMaxLengths = {
  patientInfo: {
    firstName: 30,
    lastName: 30,
    emailAddress: 128,
    phoneNumber: 20
  },
  dates: {
    day: 2,
    month: 2,
    year: 4
  },
  shippingAddress: {
    streetAddress1: 100,
    streetAddress2: 100,
    city: 30,
    zipCode: 20
  },
  fastTrack: {
    discountCode: 36,
    creditCard: {
      nameOnCard: 200,
      cardNumber: 16,
      month: 2,
      year: 4,
      cvc: 4
    }
  },
  officeTeamMember: {
    firstName: 30,
    lastName: 30,
    emailAddress: 128,
    jobTitle: 60
  },
  practiceInfo: {
    practiceName: 60,
    streetAddress1: 100,
    streetAddress2: 100,
    billingEntity: 200,
    city: 30,
    zipcode: 20,
    webPage: 100,
    storeNumber: 6
  }
}

// Cases constants
const tableRequestState = {
  PageNumber: 1,
  PageSize: 10,
  SearchQuery: '',
  SubmittingDoctorUserName: '',
  SortColumn: 'AssignmentDate',
  SortDirection: 'desc'
}

const casesConstants = {
  tableRequestState
}

const appointmentsConstants = {
  tableRequestState: {
    ...tableRequestState,
    SortColumn: 'appointment_date_created'
  }
}

const createCaseConstants = {
  flowType: {
    CASE: 'create-case',
    MIDCOURSE_CORRECTION: 'create-correction',
    REFINEMENT: 'create-refinement',
  },
  guardianInfoModel: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: ''
    },
    smsConsentAccepted: true
  },
  personalInfoModel: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: ''
    },
    smsConsentAccepted: true
  },
  shippingAddressModel: {
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zipCode: ''
  },
  signatureModel: {
    signed: false,
    signatureData: '',
    documentKind: '',
    fastTrackOption: ''
  },
  submitModel: {
    submittingDoctor: {
      userName: '',
      fullName: ''
    },
    caseNotes: '',
    order: {
      type: ''
    }
  },
  initialDates: {
    day: '',
    month: '',
    year: ''
  },
  questionInfoModel: {
    patientConsent: false
  }
}

const patientPhotos = {
  UpperOpen: 'UpperOpen',
  LowerOpen: 'LowerOpen',
  StraightOnClosed: 'StraightOnClosed'
}

const fastTrackPaymentMethods = {
  SinglePay: 'singlePay',
  SmilePay: 'smilePay',
  SmileNow: 'smileNow',
  NotFastTrack: 'notFastTrack'
}

const alignerPreferences = {
  traditional: 'STD',
  nighttime: 'SPM',
  undecided: null
}

const CARE_LEVEL = {
  standard: 'standard',
  carePlus: 'carePlus',
  undecided: null
}

const dateErrorTypeKeywords = {
  date: '%date%',
  invalidBirthChange: '%invalid_birth_change%',
  underMinimumAge: '%under_minimum_age%',
  isOneHundredOrLess: '%is_one_hundred_or_less%',
  isGuardianMinor: '%is_guardian_minor%'
}

const EXPIRATION_DATE_CREDIT_CARD_KEYWORD = '%expiration_date_credit_card%'

// value in minutes
const APP_VERSION_CHECK_INTERVAL = 10

const COUNTRIES = new Map([
  ['au', { locale: { defaultIndex: 0, items: ['en-AU'] } }],
  ['uk', { locale: { defaultIndex: 0, items: ['en-GB'] } }],
  ['us', { locale: { defaultIndex: 0, items: ['en-US'] } }],
  ['de', { locale: { defaultIndex: 0, items: ['de-DE'] } }],
  ['ca', { locale: { defaultIndex: 0, items: ['en-CA', 'fr-CA'] } }],
  ['es', { locale: { defaultIndex: 0, items: ['es-ES'] } }]
])

const COUNTRY_URL_PATTERN = new RegExp(
  `^.*?com?(?:[.]((?:${Array.from(COUNTRIES.keys()).join('|')}))|)`
)

const localeConstants = {
  default: 'en-US',
  storageName: 'locale',
  queryParamName: 'locale',
  defaultCountryCode: 'US',
  SEPARATOR: '-',
  defaultContinent: [{
    code: 'NA',
    name: 'North America',
    countries: [{
      code: 'US',
      name: 'United States',
      locales: COUNTRIES.get('us').locale.items,
      domain: 'com'
    }]
  }],
  list: [].concat(...[...COUNTRIES.values()].map(x => x.locale.items))
}

const i18nConstants = {
  storageName: 'i18n',
  defaultDateInputOrder: ['month', 'day', 'year']
}

const CASE_RESPONSE_CODES = {
  error: 'error',
  warning: 'warning',
  errorCardToken: 'errorCardToken',
  success: 'success'
}

const errorRedirectUrls = {
  statusNotFound: '/home',
  statusForbidden: '/home'
}

// TODO: this will be managed by ErrorMgr store
const ERROR_PERMISSIONS_CODES = {
  forbidden: 'forbidden',
  notfound: 'not found'
}

const userQueries = {
  doctorQuery: {
    role: 'Doctor'
  },
  allUsersQuery: {
    role: 'All'
  }
}

const MAX_NUMBER_YEARS = 20

const IMPRESSION_TYPES = {
  physical: 'improd',
  scanner: 'scekod',
  scannerRetake: 'scekodrtk'
}

const IMPRESSION_MODES = {
  physical: 'physical',
  scanner: 'scanner',
  manual: 'manual'
}

const CASE_STATUS = {
  impressionRejected: 'IMPRJC',
  scanRejected: 'SCNRJC',
  waitingScan: 'WAITSCAN-MANUAL',
  waitingScanDevice: 'WAITSCAN',
  waitingMaterials: 'WAITMAT',
  waitingLabel: 'WAITLABEL',
  waitingMaterialsShipping: 'WAITMATSHIP',
  requiresAction: 'REQACTION',
  new: 'NEW',
  scanReceived: 'SCNRCV',
  scanApproved: 'SCNAPP',
  unqualified: 'UNQUAL',
  waitingAlignerPurchase: 'WAITALGNPURCH',
  canceled: 'CANCEL',
  waitingReferral: 'WAITREFERRAL',
  referral: 'REFERRAL'
}

const IMPRESSION_TYPE_WHITE_LIST = [
  CASE_STATUS.new,
  CASE_STATUS.waitingLabel,
  CASE_STATUS.waitingMaterialsShipping,
  CASE_STATUS.waitingScan,
  CASE_STATUS.waitingScanDevice,
  CASE_STATUS.impressionRejected,
  CASE_STATUS.scanRejected,
  CASE_STATUS.unqualified,
  CASE_STATUS.requiresAction,
  CASE_STATUS.waitingReferral,
  CASE_STATUS.referral
]

const CASE_REASON = {
  needRescan: 'NEEDRESCAN',
}

const PRACTICE_ERROR_CODES = {
  duplicatePracticeName: 'DUPLICATE_PRACTICE_NAME',
  inactivePractice: 'INACTIVE_PRACTICE',
  practiceNotFound: 'Practice not found'
}

const PICKUP_ERROR_CODES = {
  invalidAddress: 'INVALID_ADDRESS',
  unavailableRates: 'UNAVAILABLE_PICKUP_RATES'
}

const TERMS_CONTRACT_SIGN_STATUS = {
  signed: 'SIGNED',
}

const SHIPPING_ERROR_CODES = {
  invalidAddress: 'INVALID_ADDRESS',
  addressError: 'ADDRESS_VERIFICATION_ERROR'
}

const FILE_UPLOAD_ERRORS_CODE = {
  maxFileSize: 'MAX_FILE_SIZE',
  minFileSize: 'MIN_FILE_SIZE',
  wrongUploadFormat: 'WRONG_UPLOAD_FORMAT',
  fileUpload: 'FILE_UPLOAD',
  manyFiles: 'MANY_FILES',
  fileNameTooLong: 'LONG_FILE_NAME'
}

const DEFAULT_TIMEOUT = 8000 // 8 seconds

const timeZoneConstants = {
  centralTime: 'America/Chicago' //IANA time zone identifiers for Central Time
}

const correctionRefinementTypes = {
  upper: 'UPPER',
  lower: 'LOWER',
  upperlower: 'BOTH'
}

const correctionReason = {
  warped: 'WARP',
  tootight: 'TIGHT',
  tooloose: 'LOOSE',
  gapping: 'GAP',
  nottracking: 'TRACKING',
  shortlong: 'COVERAGE'
}

const refinementReason = {
  noncompliance: 'NONCOMPLIANCE',
  nomatch: 'NO_MATCH',
  nothappy: 'NOT_HAPPY',
  issues: 'ISSUES'
}

const BUSINESS_ORGANIZATIONS_ERROR_CODES = {
  duplicateBOName: 'DomainValidationError',
  inactiveBO: 'EXISTING_INACTIVE_BO'
}

const EligibilityType = {
  Eligible: 'Eligible',
  NotEligible: 'Not_Eligible',
  Undeterminated: 'Undeterminated',
}

const ProductKind = {
  NightTime: 'Nighttime',
  Standard: 'Standard'
}

const EMAIL_NOTIFICATION_SETTINGS = new Map([
  ['reminderToSendBackImpressionsorScan', { required: false, label: 'EMAIL_NOTIFICATIONS__IMPRESSIONS_REMINDER' }],
  ['impressionRejected', { required: false, label: 'EMAIL_NOTIFICATIONS__IMPRESSIONS_REJECTED' }],
  ['scanRejected', { required: false, label: 'EMAIL_NOTIFICATIONS__SCAN_REJECTED' }],
  ['scanMissing', { required: false, label: 'EMAIL_NOTIFICATIONS__SCAN_MISSING' }],
  ['newEvaluationAppointmentBooked', { value: true, required: false, label: 'EMAIL_NOTIFICATIONS__APPOINTMENT_BOOKED' }],
  ['alignerPurchased', { required: false, label: 'EMAIL_NOTIFICATIONS__ALIGNER_PURCHASED' }],
  ['uniqualifiedCases', { required: false, label: 'EMAIL_NOTIFICATIONS__UNQUALIFIED_CASES' }],
])

const EMAIL_NOTIFICATION_ERROR_CODES = {
  duplicatedEmailNotification: 'Email already registered.',
  inactiveUser: 'INACTIVE_USER',
  emailNotValid: 'Email not valid'
}

const errorTypes = {
  duplicatedEmailNotification: 'duplicatedEmailNotification',
  inactiveUser: 'inactiveUser',
  addEmailNotificationError: 'addEmailNotificationError',
  updateEmailNotificationError: 'updateEmailNotificationError',
  practiceNotFound: 'practiceNotFound',
  emailNotValid: 'emailNotValid'
}

const EMAIL_NOTIFICATION_TOAST_MESSAGES = new Map([
  ['duplicatedEmailNotification', { msg: 'EMAIL_NOTIFICATIONS__DUPLICATED_EMAIL_MESSAGE', title: 'EMAIL_NOTIFICATIONS__DUPLICATED_EMAIL_TITLE' }],
  ['practiceNotFound', { msg: 'EMAIL_NOTIFICATIONS__PRACTICE_NOT_FOUND_MESSAGE', title: 'EMAIL_NOTIFICATIONS__PRACTICE_NOT_FOUND_TITLE' }],
  ['emailNotValid', { msg: 'EMAIL_NOTIFICATIONS__EMAIL_NOT_VALID_MESSAGE', title: 'EMAIL_NOTIFICATIONS__EMAIL_NOT_VALID_TITLE' }],
  ['updateEmailNotificationError', { msg: 'EMAIL_NOTIFICATIONS__ERROR_MESSAGE', title: 'EMAIL_NOTIFICATIONS__ERROR_TITLE' }],
])

const EMAIL_NOTIFICATION_SETTINGS_CASES = [
  {
    emailAddress: 'myemail@gmail.com',
    settings: {
      'scanMissing': true,
      'newEvaluationAppointmentBooked': true,
      'alignerPurchased': false,
      'scanRejected': true,
      'reminderToSendBackImpressionsorScan': false,
      'impressionRejected': false,
      'uniqualifiedCases': true
    }
  },
  {
    emailAddress: null,
    settings: null
  },
  {
    emailAddress: 'wrong_format_email@h',
    settings: {
      'scanMissing': false,
      'newEvaluationAppointmentBooked': true,
      'alignerPurchased': false,
      'scanRejected': false,
      'reminderToSendBackImpressionsorScan': false,
      'impressionRejected': false,
      'uniqualifiedCases': true
    }
  },
]

const DOCUMENT_KINDS = {
  confidentialityWaiver: 'CONFIDENTIALITYWAIVER',
  insuranceConsent: 'INSURANCECONSENTFORM',
  fastrackConsent: 'FASTRACKCONSENTFORM',
  xRay:'XRAY',
  other: 'OTHER',
  checkinPhoto: 'PNCHECKINPHOTO'
}

const DEFAULTLOCATION = 'en-US'

const GET_IP_ALTERNATIVE_SERVICE = 'https://ifconfig.co/ip'

const EMAIL_TEAM_MEMBER_VALIDATION_REGEX = /^(([^<>()#�?�!&[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const SDC_EMAIL_DOMAIN = '@smiledirectclub.com'

const SMS_UPDATED_CONSET_COUNTRIES = {
  canadaEn: 'en-CA',
  canadaFr: 'fr-CA'
}

const snackbarDefaultSettings = {
  duration: 5000,
  position: 'is-top-right',
  type: 'is-white'
}

const CASE_SUBMITTED_BANNER_STATUS = {
  success: 'success',
  error: 'error'
}

const CASE_SOURCE_TEXT = {
  'Practice': 'CASE_STATUS__PRACTICE__TEXT',
  default: 'CASE_STATUS__DEFAULT__TEXT'
}

const APPOINTMENT_NOTE_TYPES = {
  MCC: {
    redirectPathName: 'create-correction'
  },
  REF: {
    redirectPathName: 'create-refinement'
  },
  PhotoVisit: {
    redirectPathName: 'appointment-note'
  },
  NonPhotoVisit: {
    redirectPathName: 'appointment-note'
  }
}

export {
  snackbarDefaultSettings,
  appointmentsConstants,
  EXPIRATION_DATE_CREDIT_CARD_KEYWORD,
  dateErrorTypeKeywords,
  APP_VERSION_CHECK_INTERVAL,
  CASE_RESPONSE_CODES,
  COUNTRIES,
  COUNTRY_URL_PATTERN,
  authConstants,
  externalUrls,
  casesConstants,
  createCaseConstants,
  patientPhotos,
  fastTrackPaymentMethods,
  alignerPreferences,
  CARE_LEVEL,
  localeConstants,
  i18nConstants,
  inputMaxLengths,
  errorRedirectUrls,
  userQueries,
  MAX_NUMBER_YEARS,
  ERROR_PERMISSIONS_CODES,
  IMPRESSION_TYPES,
  PRACTICE_ERROR_CODES,
  TERMS_CONTRACT_SIGN_STATUS,
  LOADING_STATES,
  SHIPPING_ERROR_CODES,
  PICKUP_ERROR_CODES,
  DEFAULT_TIMEOUT,
  timeZoneConstants,
  correctionRefinementTypes,
  correctionReason,
  refinementReason,
  FILE_UPLOAD_ERRORS_CODE,
  IMPRESSION_MODES,
  BUSINESS_ORGANIZATIONS_ERROR_CODES,
  EligibilityType,
  ProductKind,
  CASE_STATUS,
  CASE_REASON,
  DEFAULTLOCATION,
  GET_IP_ALTERNATIVE_SERVICE,
  SDC_EMAIL_DOMAIN,
  EMAIL_TEAM_MEMBER_VALIDATION_REGEX,
  EMAIL_NOTIFICATION_ERROR_CODES,
  EMAIL_NOTIFICATION_SETTINGS,
  errorTypes,
  EMAIL_NOTIFICATION_TOAST_MESSAGES,
  EMAIL_NOTIFICATION_SETTINGS_CASES,
  DOCUMENT_KINDS,
  SMS_UPDATED_CONSET_COUNTRIES,
  IMPRESSION_TYPE_WHITE_LIST,
  CASE_SUBMITTED_BANNER_STATUS,
  CASE_SOURCE_TEXT,
  APPOINTMENT_NOTE_TYPES
}
