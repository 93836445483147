import { sdcInstance as sdcAPI, sdcInstanceOkta, sdcInstanceNoAuth } from './sdc.config.api'
import { CARE_LEVEL } from '@/utils/constants'
import { UtilsException }  from '_utils_/Exceptions'
import Logger from '@/utils/Logger'
import Location from '@/utils/Location'

const recoverPassword = async (username) => {
  try {
    const response = await sdcInstanceOkta.post('/api/v1/authn/recovery/password', {
      username,
      factorType: 'EMAIL'
    })
    return response.data
  } catch (error) {
    Logger.error('Utils API - recoverPassword', error)
    throw error
  }
}

/**
 * Check if the given email exists in the database
 *
 * @param {{
 *  emailAddress: String,
 *  firstName: String,
 *  lastName: String,
 *  dateOfBirth: String
 * }}
 * @returns {Promise<{
 *  caseCanBeResumed: Boolean,
 *  caseUuid: String,
 *  discountCode: String,
 *  financeAgreementId: String,
 *  hasAffiliateCode: Boolean,
 *  patientUuid: String,
 *  photoAssessmentUuid: String,
 *  userExists: Boolean
 * }>}
 */
const checkExistingEmail = async ({
  emailAddress,
  firstName,
  lastName,
  dateOfBirth
}) => {
  try {
    const { data } = await sdcAPI.post('/patient/exists', {
      emailAddress,
      firstName,
      lastName,
      dateOfBirth
    })

    return data
  } catch (error) {
    throw new UtilsException(error)
  }
}

const getPaymentValues = async (region, careLevel = CARE_LEVEL.standard) => {
  try {
    const response = await sdcAPI.get(`smilepay/product/pricing/${region}/${careLevel}`)
    return response.data
  } catch (error) {
    Logger.error('Utils API - getPaymentValues', error)
    throw error
  }
}

const getMaintenanceStatus = async () => {
  try {
    const response = await sdcInstanceNoAuth.get('utils/maintenance')
    return response.data
  } catch (error) {
    Logger.error('Utils API - getMaintenanceStatus', error)
    throw error
  }
}

const validateDiscountCode = async (code, isAffiliate) => {
  try {
    const response = await sdcAPI.get(`discount/${code}?isAffiliate=${isAffiliate}`, {
      headers: {
        locale: Location.locale
      }
    })

    return response.data
  } catch (error) {
    Logger.error('Utils API - validateDiscountCode', error)
    throw error
  }
}


/**
 *
 * @returns {Object} An object containing the price of the product
 */
const getCarePrice = async () => {
  try {
    const price = {
      standard: {
        total: 2250,
        month: 89
      },
      carePlus: {
        total: 3900,
        month: 115,
        downPayment: 500,
        downPaymentMonths: 36
      }
    }
    return {
      standard: {
        total: price.standard.total,
        month: price.standard.month,
      },
      carePlus: {
        total: price.carePlus.total,
        month: price.carePlus.month,
        downPayment: price.carePlus.downPayment,
        downPaymentMonths: price.carePlus.downPaymentMonths

      }
    }
  } catch (error) {
    Logger.error('Utils API - getCarePrice', error)
    throw error
  }
}

export {
  checkExistingEmail,
  getCarePrice,
  getMaintenanceStatus,
  getPaymentValues,
  recoverPassword,
  validateDiscountCode
}
