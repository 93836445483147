<template>
  <div class="patient-shipping-address">
    <div class="mb-2">
      <div>
        <sdc-checkbox
          v-if="showPracticeAddressAsShippingAddressCheckbox"
          v-model="practiceAddressAsShippingAddress"
          name="use-practice-address"
          class="patient-shipping-address__address__text"
          @input="handlePracticeAddressCheckboxChange"
        >
          {{ i18n('USE_PRACTICE_ADDRESS_AS_SHIPPING_ADDRESS') }}
        </sdc-checkbox>
      </div>
    </div>
    <div
      v-if="usePracticeAddressAsShippingAddress"
      class="mb-4"
    >
      <address-detail
        :address="shippingAddress"
        class="col patient-shipping-address__practice_address patient-shipping-address__address__text"
      />
    </div>
    <div
      v-if="!isAddressValid && usePracticeAddressAsShippingAddress"
      class="patient-shipping-address__practice_address_error patient-shipping-address__error invalid-feedback d-block"
    >
      <span>{{ i18n('ERROR__INVALID_ADDRESS') }}</span>
    </div>
    <div>
      <shipping-address
        v-if="!usePracticeAddressAsShippingAddress"
        :title="title"
        title-level="4"
        :model="shippingAddress"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ShippingAddress from '_molecules_/ShippingAddress'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import AddressDetail from '_atoms_/AddressDetail'
import { i18n } from '_utils_/i18n'

export default {
  name: 'PatientShippingAddress',
  components: { SdcCheckbox, AddressDetail, ShippingAddress },
  props: {
    isAddressValid: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      practiceAddress: Object
    }
  },
  computed: {
    ...mapState('CreateCase', [
      'shippingAddress',
    ]),
    ...mapState('InformationForm', [
      'showPracticeAddressAsShippingAddressCheckbox',
      'usePracticeAddressAsShippingAddress'
    ]),
    practiceAddressAsShippingAddress:{
      get(){
        return this.usePracticeAddressAsShippingAddress
      },
      set(value){
        this.setUsePracticeAddressAsShippingAddress(value)
      }
    }
  },
  async created() {
    this.practiceAddress = await this.getPracticeAddress()
    this.setStoreNumber({data: this.practiceAddress.storeNumber})
    if (this.usePracticeAddressAsShippingAddress) {
      this.setShippingAddress({data: this.practiceAddress})
    }
  },
  methods: {
      i18n,
    ...mapActions('Practices', [
      'getPracticeAddress'
    ]),
    ...mapMutations('InformationForm', [
      'setUsePracticeAddressAsShippingAddress'
    ]),
    ...mapMutations('CreateCase', [
      'setShippingAddress', 'setStoreNumber'
    ]),
    handlePracticeAddressCheckboxChange(value){
      if(value){
          this.setShippingAddress({data: this.practiceAddress})
      }
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.patient-shipping-address {
  margin-top: 36px;
  &__address {
    &__text {
      color: $secondary;
    }
  }
  &__error {
    margin-bottom: 24px;
  }
}
</style>
