<template>
  <photos
    :model="model"
  />
</template>

<script>
import Photos from '_organisms_/Photos'

export default {
  name: 'MidcoursePhotos',
  components: {
      Photos
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  }
}
</script>
