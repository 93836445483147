<template>
  <div class="info-form">
    <div>
      <LegalTooltip
        name="information-form-names"
        :text-key="legalNameKey"
      >
        <sdc-title level="4">
          {{ title }}
        </sdc-title>
      </LegalTooltip>
      <div
        v-if="hasSubtitle"
        class="info-form-subtitle"
      >
        {{ subtitle }}
      </div>
    </div>

    <!-- Names Info -->
    <section class="field-container horizontal-unless-mobile">
      <validated-input
        v-model="model.firstName"
        :name="`${name}-firstname`"
        type="text"
        :rules="customRules.firstName"
        :max-length="maxLengths.firstName"
        :label="i18n('INFORMATION_FORM__LABEL_FIRSTNAME')"
        :disabled="disabledFields"
      />
      <div class="info-form__lastname">
        <validated-input
          v-model="model.lastName"
          :name="`${name}-lastname`"
          type="text"
          :rules="customRules.lastName"
          :max-length="maxLengths.lastName"
          :label="i18n('INFORMATION_FORM__LABEL_LASTNAME')"
          :disabled="disabledFields"
        />
      </div>
    </section>

    <!-- Personal Data -->
    <section class="field-container horizontal-unless-mobile">
      <validated-email-input
        v-model="model.emailAddress"
        :max-length="maxLengths.emailAddress"
        :name="`${name}-email`"
        :label="i18n('INFORMATION_FORM__LABEL_EMAIL')"
        :disabled="emailIsDisabled"
        :rules="emailFieldRules"
        :on-blur-email-check="onBlurEmailCheck"
      />
      <div>
        <label class="input-label">{{ i18n('CREATE_CASE__PERSONAL_INFO_PHONE_NUMBER') }}</label>
        <validated-phone-input
          v-model="model.phoneNumber"
          :input-id="`${name}-phone`"
        />
      </div>
    </section>
    <section class="field-container horizontal-unless-mobile">
      <div>
        <LegalTooltip
          name="information-form-date"
          text-key="INFORMATION_FORM__LEGAL_DOB__TEXT"
          position="top"
          full-width
        >
          <label class="input-label">
            {{ i18n('CREATE_CASE__PERSONAL_INFO_BIRTH') }}
          </label>
          <validated-date-input
            v-model="model.dateOfBirth"
            :input-id="`${name}-date`"
            :custom-rules="customRules.dateOfBirth"
            :disabled="enabledDateOfBirth"
          />
        </LegalTooltip>
      </div>
      <div>
        <sdc-sms-consent
          v-if="renderSms"
          v-model="model.smsConsentAccepted"
          :name="`${name}-sms`"
          :label="i18n('SDC_SMS_CONSENT__LABEL')"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import ValidatedInput from '_atoms_/ValidatedInput'
import SdcTitle from '_atoms_/SdcTitle'
import ValidatedDateInput from '_molecules_/ValidatedDateInput'
import ValidatedPhoneInput from '_molecules_/ValidatedPhoneInput'
import SdcSmsConsent from '_molecules_/SdcSmsConsent'
import ValidatedEmailInput from '_molecules_/ValidatedEmailInput'
import { inputMaxLengths } from '_utils_/constants'
import LegalTooltip from '@/components/molecules/LegalTooltip'

export default {
  name: 'InformationForm',
  components: {
    ValidatedDateInput,
    ValidatedInput,
    ValidatedPhoneInput,
    ValidatedEmailInput,
    SdcSmsConsent,
    SdcTitle,
    LegalTooltip
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    customRules: {
      type: Object,
      default() {
        return {
          firstName: '',
          lastName: '',
          emailAddress: '',
          dateOfBirth: {
            month: '',
            day: '',
            year: ''
          }
        }
      }
    },
    renderSms: {
      type: Boolean,
      required: true
    },
    emailAlwaysDisabled: {
      type: Boolean,
      required: false
    },
    disabledFields:{
      type:Boolean,
      default: false
    },
    enabledDateOfBirth:{
      type:Boolean,
      default:false
    },
    emailFieldRules: {
      type: String,
      required: false,
      default: ''
    },
    onBlurEmailCheck: {
      type: Function,
      default: () => {}
    },
    legalNameKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      maxLengths: inputMaxLengths.patientInfo,
      model: this.$attrs.value || {}
    }
  },
  computed: {
    hasSubtitle() {
      return this.subtitle.length > 0
    },
    emailIsDisabled() {
      return this.emailAlwaysDisabled || this.disabledFields
    }
  },
  watch: {
    model(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables.scss';

$mobile: map-get($sdc-breakpoints, "mobile");

.input-label {
  color: $black;
  display: block;
  font-size: .75rem;
  font-weight: $font-weight-bold;
  margin-bottom: 0.25rem;
  margin-top: 0.2rem;
}

.info-form {
  &-subtitle {
    font-size: $font-size-md;
    line-height: 27px;
    margin-bottom: 20px;
    color: $gray-600;
  }
}
</style>
