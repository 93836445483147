import store from '_store_'
import { mutationTypes } from '_store_/modules/banner.store'
import SdcBanner from '_molecules_/SdcBanner'

export default {
  install(Vue) {
    Vue.component(SdcBanner.name, SdcBanner)
    Vue.prototype.$sdcBanner = {
      open(params) {
        store.commit(`Banner/${mutationTypes.setBannerParams}`, params)
      },
      close() {
        store.commit(`Banner/${mutationTypes.clearState}`)
      }
    }
  }
}
