const countryDialCodes = [
    { fullName: 'Afghanistan', dialCode: '93', isoCode2: 'AF', isoCode3: 'AFG' },
    { fullName: 'Albania', dialCode: '355', isoCode2: 'AL', isoCode3: 'ALB' },
    { fullName: 'Algeria', dialCode: '213', isoCode2: 'DZ', isoCode3: 'DZA' },
    { fullName: 'American Samoa', dialCode: '1684', isoCode2: 'AS', isoCode3: 'ASM' },
    { fullName: 'Andorra', dialCode: '376', isoCode2: 'AD', isoCode3: 'AND' },
    { fullName: 'Angola', dialCode: '244', isoCode2: 'AO', isoCode3: 'AGO' },
    { fullName: 'Anguilla', dialCode: '1264', isoCode2: 'AI', isoCode3: 'AIA' },
    { fullName: 'Antarctica', dialCode: '672', isoCode2: 'AQ', isoCode3: 'ATA' },
    { fullName: 'Antigua and Barbuda', dialCode: '1268', isoCode2: 'AG', isoCode3: 'ATG' },
    { fullName: 'Argentina', dialCode: '54', isoCode2: 'AR', isoCode3: 'ARG' },
    { fullName: 'Armenia', dialCode: '374', isoCode2: 'AM', isoCode3: 'ARM' },
    { fullName: 'Aruba', dialCode: '297', isoCode2: 'AW', isoCode3: 'ABW' },
    { fullName: 'Australia', dialCode: '61', isoCode2: 'AU', isoCode3: 'AUS' },
    { fullName: 'Austria', dialCode: '43', isoCode2: 'AT', isoCode3: 'AUT' },
    { fullName: 'Azerbaijan', dialCode: '994', isoCode2: 'AZ', isoCode3: 'AZE' },
    { fullName: 'Bahamas', dialCode: '1242', isoCode2: 'BS', isoCode3: 'BHS' },
    { fullName: 'Bahrain', dialCode: '973', isoCode2: 'BH', isoCode3: 'BHR' },
    { fullName: 'Bangladesh', dialCode: '880', isoCode2: 'BD', isoCode3: 'BGD' },
    { fullName: 'Barbados', dialCode: '1246', isoCode2: 'BB', isoCode3: 'BRB' },
    { fullName: 'Belarus', dialCode: '375', isoCode2: 'BY', isoCode3: 'BLR' },
    { fullName: 'Belgium', dialCode: '32', isoCode2: 'BE', isoCode3: 'BEL' },
    { fullName: 'Belize', dialCode: '501', isoCode2: 'BZ', isoCode3: 'BLZ' },
    { fullName: 'Benin', dialCode: '229', isoCode2: 'BJ', isoCode3: 'BEN' },
    { fullName: 'Bermuda', dialCode: '1441', isoCode2: 'BM', isoCode3: 'BMU' },
    { fullName: 'Bhutan', dialCode: '975', isoCode2: 'BT', isoCode3: 'BTN' },
    { fullName: 'Bolivia', dialCode: '591', isoCode2: 'BO', isoCode3: 'BOL' },
    { fullName: 'Bosnia and Herzegovina', dialCode: '387', isoCode2: 'BA', isoCode3: 'BIH' },
    { fullName: 'Botswana', dialCode: '267', isoCode2: 'BW', isoCode3: 'BWA' },
    { fullName: 'Brazil', dialCode: '55', isoCode2: 'BR', isoCode3: 'BRA' },
    { fullName: 'British Indian Ocean Territory', dialCode: '246', isoCode2: 'IO', isoCode3: 'IOT' },
    { fullName: 'British Virgin Islands', dialCode: '1284', isoCode2: 'VG', isoCode3: 'VGB' },
    { fullName: 'Brunei', dialCode: '673', isoCode2: 'BN', isoCode3: 'BRN' },
    { fullName: 'Bulgaria', dialCode: '359', isoCode2: 'BG', isoCode3: 'BGR' },
    { fullName: 'Burkina Faso', dialCode: '226', isoCode2: 'BF', isoCode3: 'BFA' },
    { fullName: 'Myanmar', dialCode: '95', isoCode2: 'MM', isoCode3: 'MMR' },
    { fullName: 'Burundi', dialCode: '257', isoCode2: 'BI', isoCode3: 'BDI' },
    { fullName: 'Cambodia', dialCode: '855', isoCode2: 'KH', isoCode3: 'KHM' },
    { fullName: 'Cameroon', dialCode: '237', isoCode2: 'CM', isoCode3: 'CMR' },
    { fullName: 'Canada', dialCode: '1', isoCode2: 'CA', isoCode3: 'CAN' },
    { fullName: 'Cape Verde', dialCode: '238', isoCode2: 'CV', isoCode3: 'CPV' },
    { fullName: 'Cayman Islands', dialCode: '1345', isoCode2: 'KY', isoCode3: 'CYM' },
    { fullName: 'Central African Republic', dialCode: '236', isoCode2: 'CF', isoCode3: 'CAF' },
    { fullName: 'Chad', dialCode: '235', isoCode2: 'TD', isoCode3: 'TCD' },
    { fullName: 'Chile', dialCode: '56', isoCode2: 'CL', isoCode3: 'CHL' },
    { fullName: 'China', dialCode: '86', isoCode2: 'CN', isoCode3: 'CHN' },
    { fullName: 'Christmas Island', dialCode: '61', isoCode2: 'CX', isoCode3: 'CXR' },
    { fullName: 'Cocos Islands', dialCode: '61', isoCode2: 'CC', isoCode3: 'CCK' },
    { fullName: 'Colombia', dialCode: '57', isoCode2: 'CO', isoCode3: 'COL' },
    { fullName: 'Comoros', dialCode: '269', isoCode2: 'KM', isoCode3: 'COM' },
    { fullName: 'Republic of the Congo', dialCode: '242', isoCode2: 'CG', isoCode3: 'COG' },
    { fullName: 'Democratic Republic of the Congo', dialCode: '243', isoCode2: 'CD', isoCode3: 'COD' },
    { fullName: 'Cook Islands', dialCode: '682', isoCode2: 'CK', isoCode3: 'COK' },
    { fullName: 'Costa Rica', dialCode: '506', isoCode2: 'CR', isoCode3: 'CRI' },
    { fullName: 'Croatia', dialCode: '385', isoCode2: 'HR', isoCode3: 'HRV' },
    { fullName: 'Cuba', dialCode: '53', isoCode2: 'CU', isoCode3: 'CUB' },
    { fullName: 'Curacao', dialCode: '599', isoCode2: 'CW', isoCode3: 'CUW' },
    { fullName: 'Cyprus', dialCode: '357', isoCode2: 'CY', isoCode3: 'CYP' },
    { fullName: 'Czech Republic', dialCode: '420', isoCode2: 'CZ', isoCode3: 'CZE' },
    { fullName: 'Denmark', dialCode: '45', isoCode2: 'DK', isoCode3: 'DNK' },
    { fullName: 'Djibouti', dialCode: '253', isoCode2: 'DJ', isoCode3: 'DJI' },
    { fullName: 'Dominica', dialCode: '1767', isoCode2: 'DM', isoCode3: 'DMA' },
    { fullName: 'Dominican Republic', dialCode: '1', isoCode2: 'DO', isoCode3: 'DOM' },
    { fullName: 'East Timor', dialCode: '670', isoCode2: 'TL', isoCode3: 'TLS' },
    { fullName: 'Ecuador', dialCode: '593', isoCode2: 'EC', isoCode3: 'ECU' },
    { fullName: 'Egypt', dialCode: '20', isoCode2: 'EG', isoCode3: 'EGY' },
    { fullName: 'El Salvador', dialCode: '503', isoCode2: 'SV', isoCode3: 'SLV' },
    { fullName: 'Equatorial Guinea', dialCode: '240', isoCode2: 'GQ', isoCode3: 'GNQ' },
    { fullName: 'Eritrea', dialCode: '291', isoCode2: 'ER', isoCode3: 'ERI' },
    { fullName: 'Estonia', dialCode: '372', isoCode2: 'EE', isoCode3: 'EST' },
    { fullName: 'Ethiopia', dialCode: '251', isoCode2: 'ET', isoCode3: 'ETH' },
    { fullName: 'Falkland Islands', dialCode: '500', isoCode2: 'FK', isoCode3: 'FLK' },
    { fullName: 'Faroe Islands', dialCode: '298', isoCode2: 'FO', isoCode3: 'FRO' },
    { fullName: 'Fiji', dialCode: '679', isoCode2: 'FJ', isoCode3: 'FJI' },
    { fullName: 'Finland', dialCode: '358', isoCode2: 'FI', isoCode3: 'FIN' },
    { fullName: 'France', dialCode: '33', isoCode2: 'FR', isoCode3: 'FRA' },
    { fullName: 'French Polynesia', dialCode: '689', isoCode2: 'PF', isoCode3: 'PYF' },
    { fullName: 'Gabon', dialCode: '241', isoCode2: 'GA', isoCode3: 'GAB' },
    { fullName: 'Gambia', dialCode: '220', isoCode2: 'GM', isoCode3: 'GMB' },
    { fullName: 'Georgia', dialCode: '995', isoCode2: 'GE', isoCode3: 'GEO' },
    { fullName: 'Germany', dialCode: '49', isoCode2: 'DE', isoCode3: 'DEU' },
    { fullName: 'Ghana', dialCode: '233', isoCode2: 'GH', isoCode3: 'GHA' },
    { fullName: 'Gibraltar', dialCode: '350', isoCode2: 'GI', isoCode3: 'GIB' },
    { fullName: 'Greece', dialCode: '30', isoCode2: 'GR', isoCode3: 'GRC' },
    { fullName: 'Greenland', dialCode: '299', isoCode2: 'GL', isoCode3: 'GRL' },
    { fullName: 'Grenada', dialCode: '1473', isoCode2: 'GD', isoCode3: 'GRD' },
    { fullName: 'Guam', dialCode: '1671', isoCode2: 'GU', isoCode3: 'GUM' },
    { fullName: 'Guatemala', dialCode: '502', isoCode2: 'GT', isoCode3: 'GTM' },
    { fullName: 'Guernsey', dialCode: '441481', isoCode2: 'GG', isoCode3: 'GGY' },
    { fullName: 'Guinea', dialCode: '224', isoCode2: 'GN', isoCode3: 'GIN' },
    { fullName: 'Guinea-Bissau', dialCode: '245', isoCode2: 'GW', isoCode3: 'GNB' },
    { fullName: 'Guyana', dialCode: '592', isoCode2: 'GY', isoCode3: 'GUY' },
    { fullName: 'Haiti', dialCode: '509', isoCode2: 'HT', isoCode3: 'HTI' },
    { fullName: 'Honduras', dialCode: '504', isoCode2: 'HN', isoCode3: 'HND' },
    { fullName: 'Hong Kong', dialCode: '852', isoCode2: 'HK', isoCode3: 'HKG' },
    { fullName: 'Hungary', dialCode: '36', isoCode2: 'HU', isoCode3: 'HUN' },
    { fullName: 'Iceland', dialCode: '354', isoCode2: 'IS', isoCode3: 'ISL' },
    { fullName: 'India', dialCode: '91', isoCode2: 'IN', isoCode3: 'IND' },
    { fullName: 'Indonesia', dialCode: '62', isoCode2: 'ID', isoCode3: 'IDN' },
    { fullName: 'Iran', dialCode: '98', isoCode2: 'IR', isoCode3: 'IRN' },
    { fullName: 'Iraq', dialCode: '964', isoCode2: 'IQ', isoCode3: 'IRQ' },
    { fullName: 'Ireland', dialCode: '353', isoCode2: 'IE', isoCode3: 'IRL' },
    { fullName: 'Isle of Man', dialCode: '441624', isoCode2: 'IM', isoCode3: 'IMN' },
    { fullName: 'Israel', dialCode: '972', isoCode2: 'IL', isoCode3: 'ISR' },
    { fullName: 'Italy', dialCode: '39', isoCode2: 'IT', isoCode3: 'ITA' },
    { fullName: 'Ivory Coast', dialCode: '225', isoCode2: 'CI', isoCode3: 'CIV' },
    { fullName: 'Jamaica', dialCode: '1876', isoCode2: 'JM', isoCode3: 'JAM' },
    { fullName: 'Japan', dialCode: '81', isoCode2: 'JP', isoCode3: 'JPN' },
    { fullName: 'Jersey', dialCode: '441534', isoCode2: 'JE', isoCode3: 'JEY' },
    { fullName: 'Jordan', dialCode: '962', isoCode2: 'JO', isoCode3: 'JOR' },
    { fullName: 'Kazakhstan', dialCode: '7', isoCode2: 'KZ', isoCode3: 'KAZ' },
    { fullName: 'Kenya', dialCode: '254', isoCode2: 'KE', isoCode3: 'KEN' },
    { fullName: 'Kiribati', dialCode: '686', isoCode2: 'KI', isoCode3: 'KIR' },
    { fullName: 'Kosovo', dialCode: '383', isoCode2: 'XK', isoCode3: 'XKX' },
    { fullName: 'Kuwait', dialCode: '965', isoCode2: 'KW', isoCode3: 'KWT' },
    { fullName: 'Kyrgyzstan', dialCode: '996', isoCode2: 'KG', isoCode3: 'KGZ' },
    { fullName: 'Laos', dialCode: '856', isoCode2: 'LA', isoCode3: 'LAO' },
    { fullName: 'Latvia', dialCode: '371', isoCode2: 'LV', isoCode3: 'LVA' },
    { fullName: 'Lebanon', dialCode: '961', isoCode2: 'LB', isoCode3: 'LBN' },
    { fullName: 'Lesotho', dialCode: '266', isoCode2: 'LS', isoCode3: 'LSO' },
    { fullName: 'Liberia', dialCode: '231', isoCode2: 'LR', isoCode3: 'LBR' },
    { fullName: 'Libya', dialCode: '218', isoCode2: 'LY', isoCode3: 'LBY' },
    { fullName: 'Liechtenstein', dialCode: '423', isoCode2: 'LI', isoCode3: 'LIE' },
    { fullName: 'Lithuania', dialCode: '370', isoCode2: 'LT', isoCode3: 'LTU' },
    { fullName: 'Luxembourg', dialCode: '352', isoCode2: 'LU', isoCode3: 'LUX' },
    { fullName: 'Macau', dialCode: '853', isoCode2: 'MO', isoCode3: 'MAC' },
    { fullName: 'Macedonia', dialCode: '389', isoCode2: 'MK', isoCode3: 'MKD' },
    { fullName: 'Madagascar', dialCode: '261', isoCode2: 'MG', isoCode3: 'MDG' },
    { fullName: 'Malawi', dialCode: '265', isoCode2: 'MW', isoCode3: 'MWI' },
    { fullName: 'Malaysia', dialCode: '60', isoCode2: 'MY', isoCode3: 'MYS' },
    { fullName: 'Maldives', dialCode: '960', isoCode2: 'MV', isoCode3: 'MDV' },
    { fullName: 'Mali', dialCode: '223', isoCode2: 'ML', isoCode3: 'MLI' },
    { fullName: 'Malta', dialCode: '356', isoCode2: 'MT', isoCode3: 'MLT' },
    { fullName: 'Marshall Islands', dialCode: '692', isoCode2: 'MH', isoCode3: 'MHL' },
    { fullName: 'Mauritania', dialCode: '222', isoCode2: 'MR', isoCode3: 'MRT' },
    { fullName: 'Mauritius', dialCode: '230', isoCode2: 'MU', isoCode3: 'MUS' },
    { fullName: 'Mayotte', dialCode: '262', isoCode2: 'YT', isoCode3: 'MYT' },
    { fullName: 'Mexico', dialCode: '52', isoCode2: 'MX', isoCode3: 'MEX' },
    { fullName: 'Micronesia', dialCode: '691', isoCode2: 'FM', isoCode3: 'FSM' },
    { fullName: 'Moldova', dialCode: '373', isoCode2: 'MD', isoCode3: 'MDA' },
    { fullName: 'Monaco', dialCode: '377', isoCode2: 'MC', isoCode3: 'MCO' },
    { fullName: 'Mongolia', dialCode: '976', isoCode2: 'MN', isoCode3: 'MNG' },
    { fullName: 'Montenegro', dialCode: '382', isoCode2: 'ME', isoCode3: 'MNE' },
    { fullName: 'Montserrat', dialCode: '1664', isoCode2: 'MS', isoCode3: 'MSR' },
    { fullName: 'Morocco', dialCode: '212', isoCode2: 'MA', isoCode3: 'MAR' },
    { fullName: 'Mozambique', dialCode: '258', isoCode2: 'MZ', isoCode3: 'MOZ' },
    { fullName: 'Namibia', dialCode: '264', isoCode2: 'NA', isoCode3: 'NAM' },
    { fullName: 'Nauru', dialCode: '674', isoCode2: 'NR', isoCode3: 'NRU' },
    { fullName: 'Nepal', dialCode: '977', isoCode2: 'NP', isoCode3: 'NPL' },
    { fullName: 'Netherlands', dialCode: '31', isoCode2: 'NL', isoCode3: 'NLD' },
    { fullName: 'Netherlands Antilles', dialCode: '599', isoCode2: 'AN', isoCode3: 'ANT' },
    { fullName: 'New Caledonia', dialCode: '687', isoCode2: 'NC', isoCode3: 'NCL' },
    { fullName: 'New Zealand', dialCode: '64', isoCode2: 'NZ', isoCode3: 'NZL' },
    { fullName: 'Nicaragua', dialCode: '505', isoCode2: 'NI', isoCode3: 'NIC' },
    { fullName: 'Niger', dialCode: '227', isoCode2: 'NE', isoCode3: 'NER' },
    { fullName: 'Nigeria', dialCode: '234', isoCode2: 'NG', isoCode3: 'NGA' },
    { fullName: 'Niue', dialCode: '683', isoCode2: 'NU', isoCode3: 'NIU' },
    { fullName: 'Northern Mariana Islands', dialCode: '1670', isoCode2: 'MP', isoCode3: 'MNP' },
    { fullName: 'North Korea', dialCode: '850', isoCode2: 'KP', isoCode3: 'PRK' },
    { fullName: 'Norway', dialCode: '47', isoCode2: 'NO', isoCode3: 'NOR' },
    { fullName: 'Oman', dialCode: '968', isoCode2: 'OM', isoCode3: 'OMN' },
    { fullName: 'Pakistan', dialCode: '92', isoCode2: 'PK', isoCode3: 'PAK' },
    { fullName: 'Palau', dialCode: '680', isoCode2: 'PW', isoCode3: 'PLW' },
    { fullName: 'Palestine', dialCode: '970', isoCode2: 'PS', isoCode3: 'PSE' },
    { fullName: 'Panama', dialCode: '507', isoCode2: 'PA', isoCode3: 'PAN' },
    { fullName: 'Papua New Guinea', dialCode: '675', isoCode2: 'PG', isoCode3: 'PNG' },
    { fullName: 'Paraguay', dialCode: '595', isoCode2: 'PY', isoCode3: 'PRY' },
    { fullName: 'Peru', dialCode: '51', isoCode2: 'PE', isoCode3: 'PER' },
    { fullName: 'Philippines', dialCode: '63', isoCode2: 'PH', isoCode3: 'PHL' },
    { fullName: 'Pitcairn', dialCode: '64', isoCode2: 'PN', isoCode3: 'PCN' },
    { fullName: 'Poland', dialCode: '48', isoCode2: 'PL', isoCode3: 'POL' },
    { fullName: 'Portugal', dialCode: '351', isoCode2: 'PT', isoCode3: 'PRT' },
    { fullName: 'Puerto Rico', dialCode: '1', isoCode2: 'PR', isoCode3: 'PRI' },
    { fullName: 'Qatar', dialCode: '974', isoCode2: 'QA', isoCode3: 'QAT' },
    { fullName: 'Reunion', dialCode: '262', isoCode2: 'RE', isoCode3: 'REU' },
    { fullName: 'Romania', dialCode: '40', isoCode2: 'RO', isoCode3: 'ROU' },
    { fullName: 'Russia', dialCode: '7', isoCode2: 'RU', isoCode3: 'RUS' },
    { fullName: 'Rwanda', dialCode: '250', isoCode2: 'RW', isoCode3: 'RWA' },
    { fullName: 'Saint Barthelemy', dialCode: '590', isoCode2: 'BL', isoCode3: 'BLM' },
    { fullName: 'Samoa', dialCode: '685', isoCode2: 'WS', isoCode3: 'WSM' },
    { fullName: 'San Marino', dialCode: '378', isoCode2: 'SM', isoCode3: 'SMR' },
    { fullName: 'Sao Tome and Principe', dialCode: '239', isoCode2: 'ST', isoCode3: 'STP' },
    { fullName: 'Saudi Arabia', dialCode: '966', isoCode2: 'SA', isoCode3: 'SAU' },
    { fullName: 'Senegal', dialCode: '221', isoCode2: 'SN', isoCode3: 'SEN' },
    { fullName: 'Serbia', dialCode: '381', isoCode2: 'RS', isoCode3: 'SRB' },
    { fullName: 'Seychelles', dialCode: '248', isoCode2: 'SC', isoCode3: 'SYC' },
    { fullName: 'Sierra Leone', dialCode: '232', isoCode2: 'SL', isoCode3: 'SLE' },
    { fullName: 'Singapore', dialCode: '65', isoCode2: 'SG', isoCode3: 'SGP' },
    { fullName: 'Sint Maarten', dialCode: '1721', isoCode2: 'SX', isoCode3: 'SXM' },
    { fullName: 'Slovakia', dialCode: '421', isoCode2: 'SK', isoCode3: 'SVK' },
    { fullName: 'Slovenia', dialCode: '386', isoCode2: 'SI', isoCode3: 'SVN' },
    { fullName: 'Solomon Islands', dialCode: '677', isoCode2: 'SB', isoCode3: 'SLB' },
    { fullName: 'Somalia', dialCode: '252', isoCode2: 'SO', isoCode3: 'SOM' },
    { fullName: 'South Africa', dialCode: '27', isoCode2: 'ZA', isoCode3: 'ZAF' },
    { fullName: 'South Korea', dialCode: '82', isoCode2: 'KR', isoCode3: 'KOR' },
    { fullName: 'South Sudan', dialCode: '211', isoCode2: 'SS', isoCode3: 'SSD' },
    { fullName: 'Spain', dialCode: '34', isoCode2: 'ES', isoCode3: 'ESP' },
    { fullName: 'Sri Lanka', dialCode: '94', isoCode2: 'LK', isoCode3: 'LKA' },
    { fullName: 'Saint Helena', dialCode: '290', isoCode2: 'SH', isoCode3: 'SHN' },
    { fullName: 'Saint Kitts and Nevis', dialCode: '1869', isoCode2: 'KN', isoCode3: 'KNA' },
    { fullName: 'Saint Lucia', dialCode: '1758', isoCode2: 'LC', isoCode3: 'LCA' },
    { fullName: 'Saint Martin', dialCode: '590', isoCode2: 'MF', isoCode3: 'MAF' },
    { fullName: 'Saint Pierre and Miquelon', dialCode: '508', isoCode2: 'PM', isoCode3: 'SPM' },
    { fullName: 'Saint Vincent and the Grenadines', dialCode: '1784', isoCode2: 'VC', isoCode3: 'VCT' },
    { fullName: 'Sudan', dialCode: '249', isoCode2: 'SD', isoCode3: 'SDN' },
    { fullName: 'Suriname', dialCode: '597', isoCode2: 'SR', isoCode3: 'SUR' },
    { fullName: 'Svalbard and Jan Mayen', dialCode: '47', isoCode2: 'SJ', isoCode3: 'SJM' },
    { fullName: 'Swaziland', dialCode: '268', isoCode2: 'SZ', isoCode3: 'SWZ' },
    { fullName: 'Sweden', dialCode: '46', isoCode2: 'SE', isoCode3: 'SWE' },
    { fullName: 'Switzerland', dialCode: '41', isoCode2: 'CH', isoCode3: 'CHE' },
    { fullName: 'Syria', dialCode: '963', isoCode2: 'SY', isoCode3: 'SYR' },
    { fullName: 'Taiwan', dialCode: '886', isoCode2: 'TW', isoCode3: 'TWN' },
    { fullName: 'Tajikistan', dialCode: '992', isoCode2: 'TJ', isoCode3: 'TJK' },
    { fullName: 'Tanzania', dialCode: '255', isoCode2: 'TZ', isoCode3: 'TZA' },
    { fullName: 'Thailand', dialCode: '66', isoCode2: 'TH', isoCode3: 'THA' },
    { fullName: 'Togo', dialCode: '228', isoCode2: 'TG', isoCode3: 'TGO' },
    { fullName: 'Tokelau', dialCode: '690', isoCode2: 'TK', isoCode3: 'TKL' },
    { fullName: 'Tonga', dialCode: '676', isoCode2: 'TO', isoCode3: 'TON' },
    { fullName: 'Trinidad and Tobago', dialCode: '1868', isoCode2: 'TT', isoCode3: 'TTO' },
    { fullName: 'Tunisia', dialCode: '216', isoCode2: 'TN', isoCode3: 'TUN' },
    { fullName: 'Turkey', dialCode: '90', isoCode2: 'TR', isoCode3: 'TUR' },
    { fullName: 'Turkmenistan', dialCode: '993', isoCode2: 'TM', isoCode3: 'TKM' },
    { fullName: 'Turks and Caicos Islands', dialCode: '1649', isoCode2: 'TC', isoCode3: 'TCA' },
    { fullName: 'Tuvalu', dialCode: '688', isoCode2: 'TV', isoCode3: 'TUV' },
    { fullName: 'United Arab Emirates', dialCode: '971', isoCode2: 'AE', isoCode3: 'ARE' },
    { fullName: 'Uganda', dialCode: '256', isoCode2: 'UG', isoCode3: 'UGA' },
    { fullName: 'United Kingdom', dialCode: '44', isoCode2: 'GB', isoCode3: 'GBR' },
    { fullName: 'Ukraine', dialCode: '380', isoCode2: 'UA', isoCode3: 'UKR' },
    { fullName: 'Uruguay', dialCode: '598', isoCode2: 'UY', isoCode3: 'URY' },
    { fullName: 'United States', dialCode: '1', isoCode2: 'US', isoCode3: 'USA' },
    { fullName: 'Uzbekistan', dialCode: '998', isoCode2: 'UZ', isoCode3: 'UZB' },
    { fullName: 'Vanuatu', dialCode: '678', isoCode2: 'VU', isoCode3: 'VUT' },
    { fullName: 'Vatican', dialCode: '379', isoCode2: 'VA', isoCode3: 'VAT' },
    { fullName: 'Venezuela', dialCode: '58', isoCode2: 'VE', isoCode3: 'VEN' },
    { fullName: 'Vietnam', dialCode: '84', isoCode2: 'VN', isoCode3: 'VNM' },
    { fullName: 'U.S. Virgin Islands', dialCode: '1340', isoCode2: 'VI', isoCode3: 'VIR' },
    { fullName: 'Wallis and Futuna', dialCode: '681', isoCode2: 'WF', isoCode3: 'WLF' },
    { fullName: 'Western Sahara', dialCode: '212', isoCode2: 'EH', isoCode3: 'ESH' },
    { fullName: 'Yemen', dialCode: '967', isoCode2: 'YE', isoCode3: 'YEM' },
    { fullName: 'Zambia', dialCode: '260', isoCode2: 'ZM', isoCode3: 'ZMB' },
    { fullName: 'Zimbabwe', dialCode: '263', isoCode2: 'ZW', isoCode3: 'ZWE' }
]

export {
    countryDialCodes
}