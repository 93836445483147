import mutationTypes from './helper/mutationTypes'
import { formatMedicalQuestions } from './helper/medicalQuestions'
import { getChiefComplaintSubmittingDoctor } from './helper/chiefComplaint'
import { getGuardianInfo } from './helper/guardianInfo'
import { createCase, sendCardToken, updateCase, updateCaseMidcourseCorrectionOrRefinement, getProductEligibility } from '_api_/cases.api'
import { sendPBCardInformation } from '_api_/creditCard.api'
import { fastTrackPaymentMethods, CASE_RESPONSE_CODES, IMPRESSION_MODES,IMPRESSION_TYPES  } from '_utils_/constants'
import { getFormattedDate } from '_utils_/dateHelpers'
import { isAnyAddressFieldCompleted } from '_utils_/addressHelper'
import I18nConfig from '_utils_/I18nConfig'
import CreateCaseException from '@/utils/Exceptions/CreateCaseException'

export default {
  async init({ dispatch, rootGetters, commit }) {
    commit(mutationTypes.startCreatingCase, true)
    dispatch('setSubmittingPractice', {
      boId: rootGetters['Auth/boId'],
      id: rootGetters['Auth/currentPracticeId']
    })
    await dispatch('loadPracticeDoctors')
  },
  setSubmittingPracticeById({ dispatch, rootGetters }, practiceId) {
    dispatch(
      'setSubmittingPractice',
      practiceId ? rootGetters['Practices/getById'](practiceId) : null
    )
  },
  async setSubmittingPractice({ commit }, payload) {
    commit(mutationTypes.setSubmittingDoctor, null)
    if (payload && payload.boId && payload.id) {
      commit(mutationTypes.setBoId, payload.boId)
      commit(mutationTypes.setPracticeId, payload.id)
    } else {
      commit(mutationTypes.setPracticeId, null)
    }
  },
  async loadPracticeDoctors({ state, dispatch }) {
    if (state.boId && state.practiceId) {
      await dispatch(
        'Doctors/loadByPractice',
        {
          boId: state.boId,
          practiceId: state.practiceId
        },
        {
          root: true
        }
      )
    }
  },
  selectDefaultDoctor({ getters, rootGetters, dispatch }) {
    if (getters['getDoctorsForCase'].length === 1) {
      dispatch(
        'setSubmittingDoctorByUsername',
        getters['getDoctorsForCase'][0]?.userName
      )
    } else if (rootGetters['Auth/isDoctor']) {
      dispatch('setSubmittingDoctorByUsername', rootGetters['Auth/userName'])
    }
  },
  setSubmittingDoctorByUsername({ commit, state, rootGetters }, username) {
    const doctor = rootGetters['Doctors/getByUsername']({
      boId: state.boId,
      practiceId: state.practiceId,
      username
    })
    commit(mutationTypes.setSubmittingDoctor, doctor)
  },
  // TODO: REFACTOR THIS ACTION AS IT'S BIG
  async createCase({ commit, state, rootState }) {
    let createCaseResult = {}
    let caseId = ''
    let fastTrackOption = ''
    let patientUserId = ''
    let paymentToken = ''
    const isCaseUpdating = state.caseId !== null && !state.isPaymentUpdate
    const fastTrackInfo = {
      ...state.fastTrackInfo
    }

    fastTrackOption = fastTrackInfo.paymentMethod
    if (state.caseId === null || isCaseUpdating) {
      try {
        const boId = state.boId
        const practiceId = state.practiceId
        const storeNumber = state.storeNumber
        const personalInfo = {
          ...state.personalInfo
        }
        const questionInfo = {
          ...state.questionInfo
        }
        const photosInfo = {
          ...state.photosInfo
        }
        const impressionType =
          state.submitInfo.order.type === IMPRESSION_MODES.physical
            ? IMPRESSION_TYPES.physical
            : IMPRESSION_TYPES.scanner
        const impressionMode = state.submitInfo.order.type
        const submitInfo = {
          ...state.submitInfo,
          order: {
            type: impressionType
          }
        }

        const medicalQuestions = formatMedicalQuestions({
          ...questionInfo.medicalQuestions
        })

        personalInfo.dateOfBirth = getFormattedDate(personalInfo.dateOfBirth)

        const usePracticeAddressAsShippingAddress =
          rootState.InformationForm.usePracticeAddressAsShippingAddress

        let guardianInfo = getGuardianInfo(state)

        const chiefComplaintSubmittingDoctor =
          getChiefComplaintSubmittingDoctor(submitInfo, questionInfo)

        const billingAddress =
          fastTrackInfo.billingAddress.sameAsShipping ||
          isAnyAddressFieldCompleted(fastTrackInfo.billingAddress)
            ? fastTrackInfo.billingAddress
            : null

        const caseData = {
          patient: {
            ...personalInfo,
            guardian: guardianInfo,
            shippingAddress: state.shippingAddress,
            billingAddress: billingAddress
          },
          questions: {
            chiefComplaint: chiefComplaintSubmittingDoctor,
            patientAnswers: medicalQuestions
          },
          photos: {
            tempCaseUuid: photosInfo.patientPhotosUid,
            isClosedStraightNaturalBite: photosInfo.isClosedStraightNaturalBite
          },
          terms: {
            financeAgreementId: questionInfo.financeAgreementId,
            accepted: questionInfo.patientConsent,
            signatures: questionInfo.signatures
          },
          payment: {
            paymentMethod: fastTrackOption,
            cardNumber: fastTrackInfo.creditCardInfo.card.number,
            expirationMonth: fastTrackInfo.creditCardInfo.card.month,
            expirationYear: fastTrackInfo.creditCardInfo.card.year,
            cardType: fastTrackInfo.creditCardInfo.card.funding
          },
          ...submitInfo,
          discountCode: fastTrackInfo.discountCode,
          hasAffiliateCode: fastTrackInfo.hasAffiliateCode,
          alignerPreference: fastTrackInfo.alignerPreference,
          careLevel: fastTrackInfo.careLevel,
          practice: {
            boId,
            practiceId,
            storeNumber
          },
          impressionMode,
          usePracticeAddressAsShippingAddress
        }

        if (fastTrackInfo.hasPurchased) {
          delete caseData.terms
          delete caseData.payment
        }

        if (isCaseUpdating) {
          const patientId = state.patientId

          caseData.patient.id = patientId
          caseData.questions.id = patientId
        }

        const response = !isCaseUpdating
          ? await createCase(caseData)
          : await updateCase(state.caseId, caseData)

        caseId = isCaseUpdating ? state.caseId : response.id
        patientUserId = response.user_uuid
        paymentToken = fastTrackInfo.creditCardInfo.card.token
        if (response.warnings) {
          createCaseResult = {
            [CASE_RESPONSE_CODES.warning]: true
          }
        }
        commit(
          'Terms/clearState',
          {},
          {
            root: true
          }
        )
        createCaseResult = {
          ...createCaseResult,
          id: caseId
        }
      } catch (error) {
        createCaseResult = {
          [CASE_RESPONSE_CODES.error]: true
        }
      }
    } else {
      caseId = state.caseId
      patientUserId = state.patientUserId
    }
    if (
      state.PBPaymentMethod === 'sepa' &&
      fastTrackOption !== fastTrackPaymentMethods.NotFastTrack
    ) {
      let fastTrack =
        'SEPA_' +
        fastTrackOption
          .replace(/[A-Z]/g, (letter) => `_${letter}`)
          .toUpperCase()
      fastTrack =
        fastTrack === 'SEPA_SINGLE_PAY' ? 'SEPA_FULLPAY' : 'SEPA_SMILEPAY'
      try {
        let pbResponse = await sendPBCardInformation({
          caseId,
          paymentInformationRequest: {
            SepaInformation: {
              Email: this.sepaEmail,
              FirstName: this.sepaFirstName,
              LastName: this.sepaLastName,
              Iban: this.sepaIban,
              AcceptMandate: this.sepaAcceptMandate
            },
            FastTrack: fastTrack,
            PBCustomerId: patientUserId
          }
        })
        createCaseResult = {
          id: caseId,
          PaymentBrokerMethodId: pbResponse.PaymentBrokerMethodId
        }
      } catch (err) {
        const isBadRequest = err?.response?.status === 400
        const badRequestErrorCode = err?.response?.data?.errorCode

        return {
          badRequestErrorCode,
          isBadRequest,
          hasError: true,
          caseId,
          fastTrackOption,
          patientUserId
        }
      }
    } else {
      const isValidSendCardToken =
        caseId.length > 0 &&
        fastTrackOption.length > 0 &&
        fastTrackOption !== fastTrackPaymentMethods.NotFastTrack
      if (isValidSendCardToken) {
        if (I18nConfig.isPaymentBrokerEnabled) {
          let fastTrack = fastTrackOption
            .replace(/[A-Z]/g, (letter) => `_${letter}`)
            .toUpperCase()
          try {
            let pbResponse = await sendPBCardInformation({
              caseId,
              paymentInformationRequest: {
                CardInformation: {
                  CardNumber: this.cardNumber,
                  ExpirationMonth: this.cardMonth,
                  ExpirationYear: this.cardYear,
                  CardVerificationValue: this.cardCvc
                },
                FastTrack: fastTrack,
                PBCustomerId: patientUserId
              }
            })
            createCaseResult = {
              id: caseId,
              PaymentBrokerMethodId: pbResponse.PaymentBrokerMethodId
            }
          } catch (err) {
            const isBadRequest = err?.response?.status === 400
            const badRequestErrorCode = err?.response?.data?.errorCode

            return {
              badRequestErrorCode,
              isBadRequest,
              hasError: true,
              caseId,
              fastTrackOption,
              patientUserId
            }
          }
        } else {
          try {
            await sendCardToken({
              caseId,
              token: paymentToken,
              fastTrackOption
            })
          } catch (error) {
            createCaseResult = {
              [CASE_RESPONSE_CODES.errorCardToken]: true,
              id: caseId
            }
          }
        }
      } else if (fastTrackOption === fastTrackPaymentMethods.NotFastTrack) {
        createCaseResult = {
          id: caseId
        }
      } else {
        // wait until photo assessments is being processed
        await new Promise((r) => setTimeout(r, 1500))
      }
    }

    return createCaseResult
  },
  async setPBCardInformation(event, data) {
    this.cardNumber = data.number
    this.cardMonth = data.month
    this.cardYear = data.year
    this.cardCvc = data.cvc
  },
  async setPBSepaformation(event, data) {
    this.sepaEmail = data.email
    this.sepaFirstName = data.firstName
    this.sepaLastName = data.lastName
    this.sepaIban = data.iban
    this.sepaAcceptMandate = data.acceptMandate
  },
  async updateCaseMidcourseCorrectionOrRefinement({ dispatch, commit, state }) {
    let updateCaseResult = {}
    const caseId = state.caseId

    try {
      const flowType = state.flowType
      const boId = state.boId
      const practiceId = state.practiceId
      const personalInfo = {
        ...state.personalInfo
      }
      const questionInfo = {
        ...state.questionInfo
      }
      const photosInfo = {
        ...state.photosInfo
      }
      const impressionType =
        state.submitInfo.order.type === IMPRESSION_MODES.physical
          ? IMPRESSION_TYPES.physical
          : IMPRESSION_TYPES.scanner
      const impressionMode = state.submitInfo.order.type
      const submitInfo = {
        ...state.submitInfo,
        order: {
          type: impressionType
        }
      }
      const medicalQuestions = formatMedicalQuestions({
        ...questionInfo.medicalQuestions
      })
      const correctionRefinementQuestions = {
        ...questionInfo.correctionRefinementQuestions
      }

      personalInfo.dateOfBirth = getFormattedDate(personalInfo.dateOfBirth)

      const guardianInfo = getGuardianInfo(state)

      const chiefComplaintSubmittingDoctor = getChiefComplaintSubmittingDoctor(
        submitInfo,
        questionInfo
      )

      const caseData = {
        flowType,
        patient: {
          ...personalInfo,
          guardian: guardianInfo,
          shippingAddress: state.shippingAddress
        },
        questions: {
          chiefComplaint: chiefComplaintSubmittingDoctor,
          patientAnswers: medicalQuestions
        },
        photos: {
          tempCaseUuid: photosInfo.patientPhotosUid,
          isClosedStraightNaturalBite: photosInfo.isClosedStraightNaturalBite
        },
        terms: {
          accepted: questionInfo.patientConsent
        },
        ...submitInfo,
        practice: {
          boId,
          practiceId
        },
        impressionMode,
        correctionRefinementQuestions,
        alignerPreference: state.alignerPreference
      }

      const patientId = state.patientId

      caseData.patient.id = patientId
      caseData.questions.id = patientId

      const response = await updateCaseMidcourseCorrectionOrRefinement(
        caseId,
        caseData
      )

      commit(
        'Terms/clearState',
        {},
        {
          root: true
        }
      )

      if (response.warnings) {
        updateCaseResult = {
          [CASE_RESPONSE_CODES.warning]: true
        }
      }

      updateCaseResult = {
        ...updateCaseResult,
        id: caseId
      }
    } catch (error) {
      updateCaseResult = {
        [CASE_RESPONSE_CODES.error]: true
      }
      // wait until photo assessments is being processed
      await new Promise((r) => setTimeout(r, 1500))
    }

    return updateCaseResult
  },
  async getAlignerEligibilityAndPreference({ dispatch }, caseUuid) {
    dispatch('AppProcess/setLoadingStatus', true, {
      root: true
    })
    const result = await getProductEligibility(caseUuid)
    dispatch('AppProcess/setLoadingStatus', false, {
      root: true
    })
    return result
  },
  resetCreateCaseFlow({ commit }) {
    try {
      commit('clearState')
      commit('InformationForm/clearInfo', {}, { root: true })
      commit('Photos/clearPatientPhotos', {}, { root: true })
    } catch (error) {
      throw new CreateCaseException(error)
    }
  }
}
