<template>
  <div class="remove-practice-form">
    <div class="remove-practice-form__row">
      {{ i18n('PRACTICE__MODAL__REMOVE__DISCLAIMER') }}
    </div>
    <div class="remove-practice-form__footer">
      <sdc-button
        class="remove-practice-form__footer__button"
        type="primary"
        outlined
        @click="onCancel"
      >
        {{ i18n('LABEL__NO') }}
      </sdc-button>
      <sdc-button
        class="remove-practice-form__footer__button practice-form__footer__button"
        type="primary"
        :disabled="loading"
        @click="onSubmit"
      >
        {{ i18n('LABEL__YES') }}
      </sdc-button>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcButton from '_atoms_/SdcButton'
import { mapState, mapActions } from 'vuex'
import { snackbarDefaultSettings } from '@/utils/constants'

export default {
  name: 'RemovePracticeForm',
  components: {
    SdcButton
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    practiceId: { type: String, default: ''},
    practiceName: { type: String, default: ''}
  },
  data() {
    return {
      modalShow: false
    }
  },
  computed: {
    ...mapState('Practices', ['practiceRemoved']),
    ...mapState('HttpRequest', ['loading'])
  },
  methods: {
    i18n,
    ...mapActions('Practices', ['deletePractice']),
    async onSubmit() {
      await this.deletePractice({
        practiceId: this.practiceId
      })
      this.toast()
    },
    onCancel() {
      this.closeModal()
    },
    toast() {
      this.$sdcToast.open({title: this.practiceName, content: this.practiceRemoved.msg})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.remove-practice-form {
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &__input {
      width: 100%;

      @media screen and (min-width: $mobile) {
        width: 49%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-family-bold;
      font-family: "Sofia Pro Bold";
      margin: 8px;
      padding: 15px 30px;
    }
  }
}

</style>
