<template>
  <email
    :label="i18n('CREATE_MIDCOURSE_CORRECTION_CASE__LABEL_PATIENT_EMAIL')"
    :title="i18n('CREATE_MIDCOURSE_CORRECTION_CASE__PATIENT_EMAIL')"
    :check-email="checkPatientEmailForMidcourseCorrection"
    :email-field-rules="emailFieldRules"
    :model="model"
  >
    <template slot="Message">
      <sdc-message
        :icon-path="infoIconPath"
        :text-message="i18n('CREATE_MIDCOURSE_CORRECTION_CASE__INFORMATION_MESSAGE')"
      />
    </template>
  </email>
</template>

<script>
import Email from '_organisms_/Email'
import { mapActions, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'
import SdcMessage from '_atoms_/SdcMessage'

export default {
  name: 'MidcourseEmail',
  components: {
    Email,
    SdcMessage
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      customRules: {
        emailAddress: 'required|email'
      }
    }
  },
  computed: {
    ...mapState('InformationForm', ['isInvalidEmail', 'errorCodeEmailValidation']),
    infoIconPath() {
      return require('_assets_/icon-info.svg')
    },
    emailFieldRules() {
      if(this.errorCodeEmailValidation.length > 0) {
        const hasErrorCodeMcc = this.errorCodeEmailValidation === 'INVALID_EMAIL' ? '|isInvalidEmail' : `|isMidcourseNotAvailable:${this.errorCodeEmailValidation}`
        return `${this.customRules.emailAddress}${hasErrorCodeMcc}`
      } else {
        return this.customRules.emailAddress
      }
    }
  },
  methods: {
    ...mapActions('InformationForm', ['checkPatientEmailForMidcourseCorrection']),
    i18n
  }
}
</script>
