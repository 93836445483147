import { getPractices, createPractice, updatePractice, patchPractice, getPracticeData, getPracticeDataByCaseId, deletePractice } from '_api_/practices.api'
import { PRACTICE_ERROR_CODES } from '_utils_/constants'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import Location from '_utils_/Location'
import { authConstants } from '../../utils/constants'
import AuthStore from '_store_/modules/auth.store'
import PracticesException from '@/utils/Exceptions/PracticesException'

export const errorTypes = {
  addPracticeDuplicateError: 'addPracticeDuplicateError',
  addInactivePracticeError: 'addInactivePracticeError',
  addPracticeStoreNumber:'addPracticeStoreNumber',
  addPracticeError: 'addPracticeError',
  getPracticeData: 'getPracticeData'
}

export const mutationTypes = {
  setPractices: 'setPractices',
  setLocalPractices: 'setLocalPractices',
  loadError: 'loadError',
  clearPractices: 'clearPractices',
  removePractice: 'removePractice',
  setIsLoading: 'setIsLoading'
}

export const state = {
  loaded: false,
  practices: [],
  localPractices: [],
  loadError: null,
  practiceRemoved: {
    practiceId: '',
    msg: null,
    status: null
  },
  isLoading: false
}

export const mutations = {
  [mutationTypes.setPractices](state, payload) {
    //TODO: Insert a timeout to clean the practices after a certain time
    state.practices = payload.filter(({ name }) => name?.length > 0)
    state.loaded = true
  },
  [mutationTypes.setLocalPractices](state, payload) {
    state.localPractices = payload.filter(({ name }) => name?.length > 0)
  },
  [mutationTypes.loadError](state, payload) {
    state.loadError = payload
    state.loaded = false
  },
  [mutationTypes.clearPractices](state) {
    state.practices = []
    state.loaded = false
    state.loadError = null
  },
  [mutationTypes.removePractice](state, payload) {
    state.practiceRemoved = payload
    if(payload.status === 204) {
      const index = state.practices
        .findIndex(practice => practice.id === payload.practiceId)
      state.practices.splice(index, index > -1 ? 1 : 0)
    }
  },
  [mutationTypes.setIsLoading](state, payload) {
    state.isLoading = payload
  }
}

export const getters = {
  isLoaded: (state) => {
    return state.loaded
  },
  getPractices: (state) => {
    return state.practices
  },
  getLocalPractices: (state) => {
    return state.localPractices
  },
  getById: (state) => (id) => {
    return state.practices.find(p => p.id === id)
  },
  sortedPractices: (state) => {
    return state.practices.map(item => ({
      value: {
        id: item.id,
        storeNumber: item.storeNumber
      },
      text: item.name
    })).sort((practiceA, practiceB) => practiceA.text.localeCompare(practiceB.text, ...Location.localeCompareConfiguration))
  }
}

export const actions = {
  async requestPractices({ state, commit }) {
    if (state.loaded) {
      return
    }
    try {
      commit(mutationTypes.setIsLoading, true)
      let practices = await getPractices()
      if(AuthStore.state.authInfo.roles.includes(authConstants.roles.ddvcStaff)) {
        practices = practices.filter(
          ({ countryCode }) => countryCode === Location.countryCode
        )
      }
      commit(mutationTypes.setPractices, practices)
    } catch (error) {
      commit(mutationTypes.loadError, {
        status: error.status
      })
    } finally {
      commit(mutationTypes.setIsLoading, false)
    }
  },

  async requestPracticesByCountryCode({ commit }, payload) {
    try {
      let practices = await getPractices()
      practices = practices.filter(({ countryCode }) => countryCode === payload)
      commit(mutationTypes.setLocalPractices, practices)
      if (practices.length === 1) {
        commit('Appointments/setCurrentStoreNumber', practices[0].storeNumber, { root: true })
      }
    } catch (error){
      commit(mutationTypes.loadError, {
        status: error.status
      })
      throw new PracticesException(error)
    }
  },

  async retrievePractices({ dispatch }) {
    dispatch('AppProcess/setLoadingStatus', true, { root: true })
    await dispatch('requestPractices')
    dispatch('AppProcess/setLoadingStatus', false, { root: true })
  },

  async createPractice({ dispatch }, payload) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      const result = await createPractice(payload)
      dispatch('clear')
      dispatch('requestPractices')
      return result
    } catch (err) {
      const isBadRequest = err?.response?.status === 400
      const badRequestErrorCode = err?.response?.data?.errorCode

      let errorType = errorTypes.addPracticeError
      if (isBadRequest) {
        if (badRequestErrorCode === PRACTICE_ERROR_CODES.duplicatePracticeName) errorType = errorTypes.addPracticeDuplicateError
        else if (badRequestErrorCode === PRACTICE_ERROR_CODES.inactivePractice) errorType = errorTypes.addInactivePracticeError
      }

      return {
        error: {
          type: errorType
        }
      }
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },

  async patchPractice({ dispatch }, {payload, practiceIdToUpdate}) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      const result = await patchPractice(practiceIdToUpdate, payload)
      dispatch('clear')
      dispatch('requestPractices')
      return result
    } catch (err) {
      return {
        error: {
          type: errorTypes.addPracticeError
        }
      }
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },

  async updatePractice({ dispatch }, {payload, practiceIdToUpdate}) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      const result = await updatePractice(practiceIdToUpdate, payload)
      dispatch('clear')
      dispatch('requestPractices')
      return result
    } catch (err) {
      let errorType
      if(err?.response?.status === 400 && err.response.data.errorCode === 'DUPLICATE_PRACTICE_NAME'){
        errorType =  errorTypes.addPracticeDuplicateError
      } else if(err?.response?.status === 400 && err.response.data.errorCode === 'DUPLICATE_PRACTICE_STORE_NUMBER'){
        errorType = errorTypes.addPracticeStoreNumber
      } else {
        errorType = errorTypes.addPracticeError
      }
      return {
        error: {
          type: errorType
        }
      }
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },

  async getPracticeAddressByCaseId(_, payload) {
    try {
      const result = await getPracticeDataByCaseId(payload)
      return result
    } catch (err) {
      return {
        error: {
          type: errorTypes.getPracticeData
        }
      }
    }
  },

  async getPracticeAddress({ rootGetters }) {
    const isSuperUser = rootGetters['Auth/isSuperuser']
    if(isSuperUser) return
    const practiceId = rootGetters['Auth/currentPracticeId']
    const boId = rootGetters['Auth/boId']
    try {
      const result = await getPracticeData(practiceId, boId)
      return result
    } catch (err) {
      return {
        error: {
          type: errorTypes.getPracticeData
        }
      }
    }
  },

  async deletePractice({ dispatch, commit }, { practiceId }) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      await  deletePractice(practiceId)
      commit(mutationTypes.removePractice, {
        practiceId: practiceId,
        msg: i18n('PRACTICE__DELETE_SUCCESS'),
        status: 204
      })
    } catch (error) {
      commit(mutationTypes.removePractice, {
        practiceId: practiceId,
        msg: i18n('ERROR__PRACTICE_NOT_DELETED',[ I18nConfig.supportPhoneNumber ]),
        status: error.status
      })
      throw new PracticesException(error)
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },

  clear({ commit }) {
    commit(mutationTypes.clearPractices)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
