<template>
  <div>
    <SdcTitle level="4">
      {{ i18n('PAYMENT__WALLET__TITLE') }}
    </SdcTitle>
    <WalletUI
      :payment-broker-account-i-d="paymentBrokerAccountId"
      :locale="locale"
      :country-code="countryCode"
      :currency-code="currencyCode"
      :env="env"
      :auth-client="getGuestAuthClient()"
      :merchant-initiated="false"
    />
  </div>
</template>

<script>
import { WalletUI } from '@smiledirectclub/sdc.wallet.ui'
import { i18n } from '@/utils/i18n';
import I18nConfig from '@/utils/I18nConfig'
import Locale from '@/utils/Location'
import SdcTitle from '@/components/atoms/SdcTitle'
import { getGuestAuthClient } from '@/api/finance.service.client'

export default {
  name: 'SdcWallet',
  components: {
    WalletUI,
    SdcTitle
  },
  props: {
    paymentBrokerAccountId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      countryCode: Locale.countryCode,
      currencyCode: I18nConfig.currencyCode,
      env: process.env.VUE_APP_PAYMENT_ENV,
      locale: Locale.locale
    }
  },
  methods: {
    i18n,
    getGuestAuthClient
  }
}
</script>
