import { CaseDetailsException } from '_utils_/Exceptions'
import { getCase, editCase, getGuardianInfo } from '_api_/cases.api'
import { createRetakeOrder, editImpressionMode } from '_api_/orders.api'
import {
  getPhotoAssets,
  getAppointmentNoteByCaseId,
  createAppointmentNote
} from '_api_/casesDetails.api'
import { sleep } from '_utils_/timerHelpers'
import I18nConfig from '_utils_/I18nConfig'
import { formatDate } from '_utils_/dateHelpers'

const MILISECONDS_TO_REQUEST = 3000

export const mutationTypes = {
  clearState: 'clearState',
  setPatientDetails: 'setPatientDetails',
  setPhotoAssets: 'setPhotoAssets',
  setOrder: 'setOrder',
  setIsLoading: 'setIsLoading',
  setCaseId: 'setCaseId',
  setAppointmentNoteByCase: 'setAppointmentNoteByCase',
  setAppointmentSummary: 'setAppointmentSummary',
  setOrderStatus: 'setOrderStatus'
}

export const state = {
  isLoading: true,
  details: {
    patientId: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    caseNumber: '',
    status: '',
    source: '',
    guardian: null,
    order: {
      uuid: '00000000-0000-0000-0000-000000000000',
      number: null,
      productCode: null,
      productCategory: null,
      productPrice: 0,
      productCurrency: null,
      isMcc: false,
      isRef: false,
      isRetake: false
    },
    impressionMode: '',
    reasonCode: ''
  },
  images: [],
  caseId: '',
  appointmentNote: null,
  appointmentSummary: '',
  orderStatus: ''
}

export const getters = {
  appointmentNoteByCase({ appointmentNote }) {
    const { dateFormat, timeFormat } = I18nConfig

    return {
      ...appointmentNote,
      appointmentDate: appointmentNote && `
        ${formatDate(appointmentNote.appointmentDate, dateFormat)}
        ${formatDate(appointmentNote.appointmentDate, timeFormat)}
      `
    }
  },
  hasAppointmentNote({ appointmentNote }) {
    return !!appointmentNote
  },
  isPhotoVisitAppointmentNote({ appointmentNote }) {
    return appointmentNote && appointmentNote.type === 'PhotoVisit'
  },
  isMcc({ details }) {
    return details.order.isMcc
  },
  isRef({ details }) {
    return details.order.isRef
  },
  isRetake({ details }) {
    return details.order.isRetake
  }
}

export const mutations = {
  [mutationTypes.setPatientDetails](state, payload) {
    state.details = {
      ...state.details,
      ...payload
    }
  },
  [mutationTypes.setOrder](state, payload) {
    state.details.order.id = payload
  },
  [mutationTypes.setOrder](state, payload) {
    state.details.order.id = payload
  },
  [mutationTypes.clearState](state) {
    state.isLoading = true
    state.details = {
      patientId: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      caseNumber: '',
      status: '',
      source: '',
      guardian: null,
      order: null,
      impressionMode: '',
      reasonCode: ''
    }
    state.images = []
  },
  [mutationTypes.setIsLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.setCaseId](state, payload) {
    state.caseId = payload
  },
  [mutationTypes.setAppointmentNoteByCase](state, payload) {
    state.appointmentNote = payload
  },
  [mutationTypes.setAppointmentSummary](state, summary) {
    state.appointmentSummary = summary
  },
  [mutationTypes.setOrderStatus](state, payload) {
    state.orderStatus = payload
  }
}

export const actions = {
  getPatientDetails({ commit }, payload) {
    commit(mutationTypes.clearState)
    commit(mutationTypes.setIsLoading, true)

    const requestTimeout = payload?.shouldSleepRequest
      ? MILISECONDS_TO_REQUEST
      : 0

    sleep(async () => {
      try {
        const patientDetails = await getCase(payload.id)
        const guardianInfo = await getGuardianInfo(patientDetails.patientId)
        commit(mutationTypes.setPatientDetails, patientDetails)
        commit(mutationTypes.setCaseId, payload.id)
        if (guardianInfo) {
          patientDetails.guardian = guardianInfo
        }
      } catch (error) {
        throw new CaseDetailsException(error)
      } finally {
        commit(mutationTypes.setIsLoading, false)
      }
    }, requestTimeout)
  },
  // eslint-disable-next-line no-unused-vars
  async getPhotoAssets({ commit }, payload) {
    try {
      const photoAssets = await getPhotoAssets(payload.id)
      return photoAssets
    } catch (error) {
      throw new CaseDetailsException(error, false)
    }
  },
  async editPatientDetails({ commit }, payload) {
    try {
      const response = await editCase(payload)

      commit(mutationTypes.setPatientDetails, response)
    } catch (error) {
      throw new CaseDetailsException(error)
    }
  },
  async createRetakeOrder({ commit }, payload) {
    try {
      const response = await createRetakeOrder(payload)
      commit(mutationTypes.setOrder, response)
    } catch (error) {
      throw new CaseDetailsException(error)
    }
  },
  async editImpressionMode({ commit }, payload) {
    try {
      const response = await editImpressionMode(payload)
      commit(mutationTypes.setOrder, response)
    } catch (error) {
      throw new CaseDetailsException(error)
    }
  },
  async getAppointmentNoteByCase({ commit, dispatch }, payload) {
    dispatch('HttpRequest/setLoadingStatus', true, { root: true })

    try {
      const response = await getAppointmentNoteByCaseId(payload)
      commit(mutationTypes.setAppointmentNoteByCase, response)
    } catch (error) {
      commit(mutationTypes.setAppointmentNoteByCase, null)
      return false
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },
  async createAppointmentNoteByCase({ dispatch, state }, caseUuid) {
    dispatch('AppProcess/setLoadingStatus', true, {
      root: true
    })
    try {
      const result = await createAppointmentNote(caseUuid, {
        summary: state.appointmentSummary
      })

      return result
    } catch (error) {
      return null
    } finally {
      dispatch('AppProcess/setLoadingStatus', false, {
        root: true
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  keysToPersist: '*'
}
