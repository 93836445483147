import { sdcInstanceNoAuth as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getContinentsAndCountries = async params => {
  try {
    const response = await api.get('/countries', { params })
    return response.data
  } catch (error) {
    Logger.error('Countries API - getContinentsAndCountries', error)
    throw error
  }
}

export {
  getContinentsAndCountries
}
