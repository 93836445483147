<template>
  <div id="create-case-step-form">
    <validation-observer ref="createCaseStepForm">
      <slot :validate-form="validateForm" />
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { dateErrorTypeKeywords } from '_utils_/constants'

export default {
  name: 'CreateCaseStepForm',
  components: {
    ValidationObserver
  },
  methods: {
    async validateForm() {
      const stepForm = this.$refs.createCaseStepForm

      const result = await stepForm.validate()

      if (!result) {

        // This setTimeout was included because of an error on vee-validate
        // Remove once we get a better way to catch these errors
        // Please check: https://github.com/logaretm/vee-validate/issues/2248
        setTimeout(() => {
          this.setErrorFieldFocus(stepForm.errors)
        }, 200)

        return false
      }

      return true
    },
    setErrorFieldFocus(errors) {
      if (!errors || errors.length <= 0) return

      let errorFieldName = null

      for (const errorField in errors) {
        if (errors[errorField].length > 0 && !Object.values(dateErrorTypeKeywords).includes(errors[errorField][0])) {
          errorFieldName = errorField
          break
        }
      }

      if (errorFieldName !== null) {
        this.$nextTick(() => {
          this.$refs.createCaseStepForm.$el.querySelector(`*[name="${errorFieldName}"]`).focus()
        })
      }
    }
  }
}
</script>
