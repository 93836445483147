<template>
  <div
    :id="name"
    :name="name"
    class="photos-photo-container"
  >
    <sdc-title
      level="5"
      margin-level="1"
    >
      {{ title }}
    </sdc-title>
    <div class="photos-photo-container__subtitle">
      {{ subtitle }}
    </div>
    <sdc-photo-upload
      v-model="photo.filename"
      :name="`${name}-photo`"
      :image-src="photo.src"
      class="photos-photo-container__photo"
      :handle-upload="onUpload"
      @error="onError"
    />
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcPhotoUpload from '_molecules_/SdcPhotoUpload'
import SdcTitle from '_atoms_/SdcTitle'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PhotosPhotoContainer',
  components: {
    SdcPhotoUpload,
    SdcTitle
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    photo: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator(prop) {
        return ['lower', 'straight', 'upper'].includes(prop)
      }
    },
    isAdditionalFile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('CreateCase', ['photosInfo'])
  },
  methods: {
    ...mapActions('Photos', ['uploadPatientPhoto']),
    ...mapActions('CaseUploadAdditionalFiles', ['addAdditionalFile']),
    toast(msg, title) {
      this.$sdcToast.open({title, content: msg})
    },
    async onUpload(uploadedFile) {
      if(this.isAdditionalFile) {
        await this.addAdditionalFile({
          file: uploadedFile,
          caseId: this.$route.params.id
        })
      }

      const uploadedPhoto = await this.uploadPatientPhoto({
        name: uploadedFile.name,
        size: uploadedFile.size,
        type: this.type,
        file: uploadedFile,
        uuid: this.photosInfo.patientPhotosUid
      })

      if (!uploadedPhoto) {
        this.toast(
          i18n('ERROR__PHOTO_UPLOAD'),
          this.title
        )

        return false
      }

      return true
    },
    onError(text, title) {
      this.toast(
        text,
        (title) ? title : this.title
      )
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$medium-mobile: map-get($sdc-breakpoints, 'medium-mobile');
$mobile: map-get($sdc-breakpoints, 'mobile');
$tablet: map-get($sdc-breakpoints, 'tablet');

.photos-photo-container {
  padding: 18px;

  &__subtitle {
    color: $gray-600;
    font-size: $font-size-xs;
    height: 4rem;

    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      height: 5rem;
    }

    @media screen and (max-width: $medium-mobile) {
      height: 6rem;
    }
  }

  &__photo {
    height: 18rem;
    margin-top: 10px;

    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      height: 21rem;
    }

    @media screen and (max-width: $mobile) {
      height: 22rem;
    }
  }
}
</style>
