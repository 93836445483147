import CreateCaseInformation from '_organisms_/CreateCaseInformation'
import CreateCaseQuestion from '_organisms_/CreateCaseQuestion'
import CreateCasePreference from '_organisms_/CreateCasePreference'
import CreateCaseEmail from '_organisms_/CreateCaseEmail'
import CreateCaseSubmit from '_organisms_/CreateCaseSubmit'
import Photos from '_organisms_/Photos'
import FastTrack from '_organisms_/FastTrack'

export const COMPONENTS = {
    'CreateCaseEmail': {
        component: CreateCaseEmail,
        title: 'CREATE_CASE_EMAIL__STEP'
    },
    'CreateCaseInformation': {
        component: CreateCaseInformation,
        title: 'CREATE_CASE_INFORMATION__STEP'
    },
    'CreateCaseQuestion': {
        component : CreateCaseQuestion,
        title: 'CREATE_CASE_QUESTION__STEP'
    },
    'CreateCasePreference': {
        component: CreateCasePreference,
        title: 'CREATE_CASE_ALIGNMENT_PREFERENCE__STEP'
    },
    'CreateCasePhotos': {
      component: Photos,
      title: 'CREATE_CASE_PHOTOS__STEP'
    },
    'CreateCaseFastTrack': {
        component : FastTrack,
        title: 'CREATE_CASE_FAST_TRACK__STEP'
    },
    'CreateCaseSubmit': {
        component : CreateCaseSubmit,
        title: 'CREATE_CASE_SUBMIT__STEP'
    }
}
