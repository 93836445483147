<template>
  <div class="shipping-label-pickup">
    <div class="shipping-label-pickup__title-commands">
      <sdc-title
        level="5"
        margin-level="1"
      >
        {{ message }}
      </sdc-title>
      <div>
        <sdc-button
          v-if="isPickupScheduled"
          type="link"
          class="shipping-label-pickup__title-commands__cancel-button"
          @click="confirmCancelPickup"
        >
          {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__CANCEL') }}
        </sdc-button>
      </div>
    </div>
    <div class="shipping-label-pickup__row-container">
      <div class="shipping-label-pickup__row-container__label">
        {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__STATUS') }}
      </div>
      <div class="badge badge-light shipping-label-pickup__row-container__value--status">
        {{ pickupStatus }}
      </div>
    </div>
    <div class="shipping-label-pickup__row-container">
      <div class="shipping-label-pickup__row-container__label">
        {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__DATE') }}
      </div>
      <div class="shipping-label-pickup__row-container__value">
        {{ formatDate(pickup.minDateTime, i18nConfig.weekDayDisplayFormat) }}
      </div>
    </div>
    <div class="shipping-label-pickup__row-container">
      <div class="shipping-label-pickup__row-container__label">
        {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__TIME') }}
      </div>
      <div class="shipping-label-pickup__row-container__value">
        {{ pickupDateRange }}
      </div>
    </div>
    <div class="shipping-label-pickup__row-container">
      <div class="shipping-label-pickup__row-container__label">
        {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__SPECIAL_INSTUCTIONS') }}
      </div>
      <div class="shipping-label-pickup__row-container__value">
        <div class="shipping-label-pickup__row-container__value--instructions">
          <span>{{ pickup.instructions }}</span>
        </div>
      </div>
    </div>
    <div class="shipping-label-pickup__row-container">
      <div class="shipping-label-pickup__row-container__label">
        {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__ADDRESS') }}
      </div>
      <div class="shipping-label-pickup__row-container__value">
        <AddressDetail :address="pickup.address" />
      </div>
    </div>
    <cancel-modal ref="cancelModal">
      {{ i18n('SHIPPING_LABEL_PICKUP_DETAILS__CANCEL_CONFIRMATION') }}
    </cancel-modal>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import i18nConfig from '_utils_/I18nConfig'
import { formatDate } from '_utils_/dateHelpers'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import AddressDetail from '_atoms_/AddressDetail'
import CancelModal from '_molecules_/CancelModal'
import { mapActions } from 'vuex'
export default {
  name: 'ShippingLabelPickupDetails',
  components: {
    SdcTitle,
    SdcButton,
    AddressDetail,
    CancelModal
  },
  props: {
    pickup: {
      type: Object,
      required: true,
      validator: function (val) {
        return typeof val.status === 'string'
          && typeof val.minDateTime === 'string'
          && typeof val.maxDateTime === 'string'
          && typeof val.instructions === 'string'
          && typeof val.address === 'object'
      }
    }
  },
  computed: {
    message() {
      if (this.isPickupCompleted) {
        return i18n('SHIPPING_LABEL_PICKUP_DETAILS__MESSAGE_COMPLETED')
      }

      return i18n('SHIPPING_LABEL_PICKUP_DETAILS__MESSAGE')
    },
    pickupDateRange() {
      return `${formatDate(this.pickup.minDateTime, i18nConfig.timeFormat)}
        ${i18n('SHIPPING_LABEL_PICKUP_DETAILS_TIME__END__LABEL')}
        ${formatDate(this.pickup.maxDateTime, i18nConfig.timeFormat)}`
    },
    pickupStatus() {
      const { status } = this.pickup
      return i18n(`SHIPPING_LABEL_PICKUP__STATUS__${status ? status.toUpperCase() : 'UNKNOWN'}`)
    },
    isPickupScheduled() {
      return this.pickup.status === 'scheduled'
    },
    isPickupCompleted() {
      return this.pickup.status === 'completed'
    }
  },
  beforeCreate() {
    this.i18nConfig = i18nConfig
  },
  methods: {
    i18n,
    formatDate,
    ...mapActions('Shipment', ['cancelPickup']),
    confirmCancelPickup() {
      const cancelModal = this.$refs.cancelModal
      cancelModal.$off('ok')
      cancelModal.$on('ok', () => {
        this.cancelPickup(this.pickup.id)
      })
      cancelModal.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.shipping-label-pickup {
  &__row-container {
    margin-top: 20px;
    &__label {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $gray-600;
    }
    &__value{
      font-size: $font-size-lg;
      color: $black;
      margin-top: 8px;
      &--status {
        margin-top: 8px;
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
      }
      &--instructions {
        background: #f8f9fa;
        border-radius: 8px;
        padding: 12px;
        min-height: 54px;
        font-size: $font-size-md;
        color: $secondary;
        overflow-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
  &__title-commands {
    display: flex;
    justify-content: space-between;

    &__cancel-button {
      font-weight: $font-weight-bold;
      padding-top: 0;
      padding-bottom: 0;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        text-decoration: none;
        box-shadow: none;
      }
    }
  }
}

@media screen and (min-width: $mobile) {
  .shipping-label-pickup {
    padding: 36px;
    border: 2px solid $gray-88;
    border-radius: 4px;
  }
}
</style>
