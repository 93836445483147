/* eslint-disable no-console */
import { sdcInstance as sdcAPI } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getPatientInfo = async (patientUuid) => {
  try {
    const response = await sdcAPI.get(`/patient/${patientUuid}`)
    return response.data
  } catch (error) {
    Logger.error('Patient API - getPatientInfo', error)
    throw error
  }
}

const getPatientEmailAvailableForMidcourseCorrectionOrRefinement = async (patientEmail) => {
  try {
    const response = await sdcAPI.get(`/patient/${patientEmail}/available/midcourse-refinement`)
    return response.data
  } catch (error) {
    Logger.error('Patient API - getPatientEmailAvailableForMidcourseCorrectionOrRefinement', error)
    throw error
  }
}
export {
  getPatientInfo,
  getPatientEmailAvailableForMidcourseCorrectionOrRefinement
}
