<template>
  <sdc-modal
    id="team-member-modal"
    ref="teamMemberModal"
    name="teamMemberModal"
    class="sdc-modal"
    size="xl"
    :title-props="titleProps"
    hide-footer
  >
    <team-member-form
      :close-modal="close"
      :user-name="userName"
    />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import TeamMemberForm from '@/components/molecules/TeamMemberForm'
import SdcModal from '_molecules_/SdcModal'


export default {
  name: 'TeamMemberModal',
  components: {
    SdcModal,
    TeamMemberForm
  },
  props:{
    userName: { type: String, default: ''}
  },
  computed: {
    titleProps() {
      return {
        text: this.userName.length > 0
          ? 'OFFICE_TEAM__MODAL_TITLE_EDIT'
          : 'OFFICE_TEAM__MODAL_TITLE'
      };
    },
    modalRef() {
      return this.$refs['teamMemberModal']
    }
  },
  methods: {
    i18n,
    close() {
      this.modalRef.close()
    },
    open() {
      this.modalRef.open()
    }
  }
}
</script>
