<template>
  <sdc-modal
    id="signature-modal"
    ref="signature-modal"
    name="signature-modal"
    :title-props="titleProps"
    size="extra_large"
    hide-footer
  >
    <signature-form
      ref="signature-form"
      :close-modal="close"
      :document-kind="documentKind"
      :payment-method="paymentMethod"
    />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal'
import SignatureForm from '_molecules_/SignatureForm'

export default {
  name: 'SignatureModal',
  components: {
    SdcModal,
    SignatureForm
  },
  props:{
    documentKind:{
      type: String,
      required: true
    },
    paymentMethod:{
      type: String,
      default: ''
    },
  },
  data() {
    return {
      titleProps: {
        text: this.getText(this.documentKind)
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['signature-modal']
    },
    formRef() {
      return this.$refs['signature-form']
    },
    modalTitle() {
      return this.getText(this.documentKind)
    }
  },
  methods: {
    i18n,
    shown() {
      this.modalRef.focus()
    },
    open() {
      this.modalRef.show()
    },
    close() {
      this.modalRef.hide()
    },
    getText(kind){
      var texts={
        [this.documentKind]: 'SIGN_TERMS_MODAL_TITLE'
      }
      return texts[kind]
    }
  },
}
</script>

<style lang="scss">
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');
$desktop: map-get($sdc-breakpoints, 'desktop');

.signature-modal {
  .modal-content {
    @media screen and (max-width: $mobile) {
      padding: 0px !important;
    }
  }
  .modal-md {
    @media screen and (max-width: $mobile) {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: $desktop){
  .modal-dialog, .modal-xl {
    max-width: 500px !important;
  }
}
</style>
