<template>
  <sdc-page class="appointment">
    <sdc-title level="2">
      {{ i18n('CASE_DETAILS__APPOINTMENT__TITLE') }}
    </sdc-title>
    <purpose-of-visit />
    <card>
      <template slot="contentSection">
        <validation-observer ref="appointmentForm">
          <photos
            v-if="isPhotoVisitAppointmentNote"
            :model="appointmentPhotos"
            is-additional-file
          />
          <submit-appointment-summary
            v-model="appointmentSummary"
            :placeholder="i18n('CASE_DETAILS__APPOINTMENT__SUMMARY_PLACEHOLDER')"
            :title="i18n('SUBMIT__APPOINTMENT_SUMMARY_TITLE')"
          />
        </validation-observer>
        <div class="appointment__footer">
          <sdc-button
            class="appointment__footer__submit_button"
            type="primary"
            :disabled="loading"
            @click="onSubmit"
          >
            {{ i18n('CASE_DETAILS__APPOINTMENT__FINISH_BUTTON_TEXT') }}
          </sdc-button>
          <sdc-button
            class="appointment__footer__back_button"
            type="primary"
            outlined
            @click="onBack"
          >
            {{ i18n('CASE_DETAILS__APPOINTMENT__BACK_BUTTON_TEXT') }}
          </sdc-button>
          <sdc-modal
            ref="appointmentModal"
            name="appointment-finish-modal"
            v-bind="appointmentModalProps"
            @onConfirm="onConfirm"
          >
            {{ i18n('CASE_DETAILS__APPOINTMENT_MODAL__BODY_TEXT') }}
          </sdc-modal>
        </div>
      </template>
    </card>
  </sdc-page>
</template>

<script>
import Card from '_atoms_/Card'
import Photos from '_organisms_/Photos'
import PurposeOfVisit from '_molecules_/PurposeOfVisit'
import SdcButton from '_atoms_/SdcButton'
import SdcModal from '_molecules_/SdcModal'
import SdcPage from '_templates_/SdcPage'
import SdcTitle from '_atoms_/SdcTitle'
import SubmitAppointmentSummary from '_molecules_/SubmitAppointmentSummary'
import { DOCUMENT_KINDS } from '_utils_/constants'
import { ValidationObserver } from 'vee-validate'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { i18n } from '_utils_/i18n'

export default {
  name: 'AppointmentNote',
  components: {
    Card,
    Photos,
    PurposeOfVisit,
    SdcButton,
    SdcModal,
    SdcPage,
    SdcTitle,
    ValidationObserver,
    SubmitAppointmentSummary
  },
  data() {
    return {
      appointmentSummary: '',
      appointmentPhotos: {},
      appointmentModalProps: {
        titleProps: {
          text: 'CASE_DETAILS__APPOINTMENT_MODAL__TITLE'
        },
        buttonProps: {
          confirmProps: {
            text: 'CASE_DETAILS__APPOINTMENT_MODAL__SUBMIT_BUTTON_TEXT'
          },
          cancelProps: {
            text: 'CASE_DETAILS__APPOINTMENT_MODAL__CANCEL_BUTTON_TEXT'
          }
        }

      }
    }
  },
  computed: {
    ...mapState('CaseDetails', ['appointmentNote', 'caseId']),
    ...mapState('Photos', ['patientPhotos']),
    ...mapState('HttpRequest', ['loading']),
    ...mapGetters('CaseDetails', ['hasAppointmentNote', 'isPhotoVisitAppointmentNote']),
  },
  mounted() {
    // as appointment note comes only from case-details, extra request is no needed
    if(!this.hasAppointmentNote) {
      this.returnToCaseDetails()
    }
  },
  destroyed() {
    this.setAppointmentNoteByCase(null)
    this.setAppointmentSummary('')
  },
  methods: {
    i18n,
    ...mapMutations('Photos', ['clearPatientPhotos']),
    ...mapMutations('CaseDetails', ['setAppointmentSummary', 'setAppointmentNoteByCase']),
    ...mapActions('CaseDetails', ['createAppointmentNoteByCase']),
    ...mapActions('CaseUploadAdditionalFiles', ['uploadFile']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    async onSubmit() {
      const form = this.$refs.appointmentForm
      this.success = await form.validate()
      if (!this.success) return

      this.$refs.appointmentModal.open()
    },
    async onBack() {
      this.clearPatientPhotos()
      this.returnToCaseDetails()
    },
    returnToCaseDetails() {
      this.$router.push({ name: 'case-details', params: { id: this.caseId }})
    },
    async onConfirm() {
      this.setLoadingStatus(true)
      this.setAppointmentSummary(this.appointmentSummary)
      if (this.isPhotoVisitAppointmentNote) {
        for (const type of Object.keys(this.patientPhotos)) {
          await this.uploadFile({
            caseId: this.caseId,
            filename: this.patientPhotos[type].filename,
            kind: DOCUMENT_KINDS.checkinPhoto
          })
        }
      }

      await this.createAppointmentNoteByCase(this.caseId)
      this.returnToCaseDetails()
      this.clearPatientPhotos()
      this.setLoadingStatus(false)
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';
@import '_theme_/_section-panel';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop-sm: map-get($sdc-breakpoints, 'desktop-sm');

.appointment {
  &__summary_title {
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 60px;
    &__submit_button {
      margin-left: 20px;
    }
  }
  @media screen and (min-width: $desktop-sm) {
    max-width: $desktop-sm !important;
  }
}
</style>
