import Location from '_utils_/Location'
import { cleanPhoneNumber } from '_utils_/stringHelpers'

export const formatRequestForPickup = (values, authUser, practiceAddress, shipmentInfo, caseNumber) => {

  let pickupAddress = {
    ...values.pickupAddress,
    lastName: authUser?.lastName,
    firstName: authUser?.firstName,
    phoneNumber: cleanPhoneNumber(practiceAddress.phoneNumber),
    countryCode: Location.countryCode,
    company: practiceAddress.name,
  }

  if(values.usePracticeAddress) {
    const {
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipCode,
    } = practiceAddress
    pickupAddress = {
      ...pickupAddress,
      streetAddress1,
      streetAddress2,
      city,
      state,
      zipCode
    }
  }
  const { dateTime: { from, to}, instructions, usePracticeAddress} = values
  return {
    shipmentId: shipmentInfo.id,
    dateStart: from,
    dateEnd: to,
    instructions: instructions,
    reference: caseNumber,
    usePracticeAddress: usePracticeAddress,
    pickupAddress
  }
}