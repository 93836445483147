<template>
  <section class="sdc-page">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'SdcPage'
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop: map-get($sdc-breakpoints, 'desktop');

.sdc-page {
  margin: auto;
  max-width: $desktop;
  padding-top: 20px !important;

  @media screen and (min-width: $tablet) {
    width: 100%;
    padding: 60px;
  }

  @media screen and (max-width: $tablet) {
    padding: 20px;
  }
}
</style>
