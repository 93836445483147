/* eslint-disable no-console */
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'
import axios from 'axios'

const getPhotoUploadURL = async ({ PhotoFileName, PhotoSize, uid, photoType }) => {
  try {
    const url = `/case/${uid}/tempphoto/${photoType}`
    const response = await api.put(url, { PhotoSize, PhotoFileName })
    return response.data
  } catch (error) {
    Logger.error('Photos API - getPhotoUploadURL', error)
    throw error
  }
}

const uploadPhoto = async ({ photo, url }) => {
  try {
    const response = await axios.put(url, photo)
    return response.status
  } catch (error) {
    Logger.error('Photos API - uploadPhoto', error)
    throw error
  }
}

const getPhotoURL = async ({ uuid, photoType }) => {
  try {
    const url = `/case/${uuid}/tempphoto/${photoType}?t=${new Date().getTime()}`
    const response = await api.get(url)
    return response.data.photoUrl
  } catch (error) {
    Logger.error('Photos API - getPhotoURL', error)
    throw error
  }
}

export {
  getPhotoUploadURL,
  uploadPhoto,
  getPhotoURL
}
