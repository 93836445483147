import {
  createCaseConstants,
  fastTrackPaymentMethods,
  alignerPreferences,
  CARE_LEVEL
} from '_utils_/constants'
const {
  personalInfoModel,
  guardianInfoModel,
  shippingAddressModel,
  submitModel,
  signatureModel
} = createCaseConstants

export default {
  boId: null,
  flowType: null,
  practiceId: null,
  storeNumber: null,
  caseId: null,
  patientId: null,
  personalInfo: {
    ...personalInfoModel
  },
  guardianInfo: JSON.parse(JSON.stringify(guardianInfoModel)),
  shippingAddress: {
    ...shippingAddressModel
  },
  submitInfo: JSON.parse(JSON.stringify(submitModel)),
  questionInfo: {
    chiefComplaintValue: '',
    medicalQuestions: {
      haveAnyData: {},
      allThatApplyData: {}
    },
    financeAgreementId: null,
    patientConsent: false,
    waiverSigned: false,
    insuranceConsentSigned: false,
    signatures: {
      waiverSignature: { ...signatureModel },
      insuranceSignature: { ...signatureModel },
      fastrackSignature: { ...signatureModel }
    },
    correctionRefinementQuestions: {
      type: '',
      reasonType: '',
      alignerStep: '',
      alignerWeek: ''
    }
  },
  photosInfo: {
    patientPhotosUid: null,
    isClosedStraightNaturalBite: false
  },
  fastTrackInfo: {
    paymentMethod: fastTrackPaymentMethods.NotFastTrack,
    smileNowTermsAndConsentsAccepted: false,
    alignerPreference: alignerPreferences.traditional,
    careLevel: CARE_LEVEL.standard,
    carePlusEnabled: false,
    creditCardInfo: {
      name: '',
      card: {}
    },
    billingAddress: {
      sameAsShipping: null,
      ...shippingAddressModel
    },
    discountCode: '',
    hasAffiliateCode: false,
    hasPurchased: false
  },
  financeAgreementUrl: '',
  isCreatingCase: false,
  isPaymentUpdate: false,
  PBPaymentMethod: '',
  patientUserId: '',
  alignerPreference: alignerPreferences.traditional,
  careLevel: CARE_LEVEL.standard
}
