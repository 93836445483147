<template>
  <section class="case-details-patient-photos">
    <sdc-gallery :images="photoAssets" />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import SdcGallery from '_molecules_/SdcGallery'

export default {
  name: 'CaseDetailsPatientPhotos',
  components: {
    SdcGallery
  },
  data() {
    return{
      photoAssets: null
    }
  },
  async mounted() {
    const id = this.$route.params.id
    await this.loadPhotoAssets(id)
  },
  methods: {
    ...mapActions('CaseDetails', ['getPhotoAssets']),
    async loadPhotoAssets( id ) {
      const photos = await this.getPhotoAssets({id})
      this.photoAssets = (photos?.length > 0) ?
        photos.map(({url}) => url) :
        []
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

$desktop-sm: map-get($sdc-breakpoints, "desktop-sm");

.case-details-patient-photos {
  height: 248px;

  @media screen and (min-width: $desktop-sm) {
    height: 400px;
  }

  & div {
    border: 1px solid red;
  }
}
</style>
