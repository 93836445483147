<template>
  <form
    class="sdc-search-box"
    @submit.prevent="search"
  >
    <b-field class="sdc-search-box__input">
      <smile-input
        v-model="searchQuery"
        class="search"
        name="search"
        :placeholder="placeholder"
        :disabled="disabled"
        :icon-right="rightIcon"
        icon-right-clickable
        @icon-right-click="clearSearch"
      />
    </b-field>
  </form>
</template>

<script>
import { SmileInput } from 'sdc-component-library'

export default {
  name: 'SdcSearchBox',
  components: {
    SmileInput
  },
  props: {
    placeholder: { type: String, required: true },
    maxLengthOnOverlap: { type: Number, required: true },
    disabled: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchQuery: this.searchText || '',
    }
  },
  computed: {
    rightIcon() {
      return this.searchQuery ? 'x' : 'search'
    }
  },
  watch: {
    searchText: function(val) {
      this.searchQuery = val
    }
  },
  methods: {
    /**
     * Search value on submit (enter press)
     */
    search() {
      this.$emit('search', this.searchQuery)
    },
    /**
     * Clears the search value on click
     */
    clearSearch() { 
     if(this.searchQuery)
     {
      this.searchQuery = ''
      this.$emit('clearSearch')
     }
    },
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
.search > input{
  color: $black !important;
}
.fe-x {
 color: $primary !important;
 font-size: 1.2rem; 
}
</style>
