import {
  localeConstants,
  COUNTRIES
} from '_utils_/constants'

const formatLocale = (currentLocale) => { // make sure country code is in upper case, eg en-US
  const items = currentLocale.split(localeConstants.SEPARATOR)
  return `${items[0]}${localeConstants.SEPARATOR}${items[1].toUpperCase()}`
}

const getValidLocale = (currentLocale) => {
  currentLocale = formatLocale(currentLocale)
  return  localeConstants.list.includes(currentLocale) ? currentLocale : localeConstants.default
}

const getLocale = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const queryParamLocale = urlParams.get(localeConstants.queryParamName)

  if (queryParamLocale) {
    return queryParamLocale
  }

  if (localStorage.getItem(localeConstants.storageName)) {
    return localStorage.getItem(localeConstants.storageName)
  }

  const countrySuffix = window.location.hostname.split('.').pop()
  if (countrySuffix && countrySuffix.length === 2 && COUNTRIES.has(countrySuffix)) {
    const locale = COUNTRIES.get(countrySuffix).locale
    return locale.items[locale.defaultIndex]
  }

  if (window.navigator.userLanguage?.includes(localeConstants.SEPARATOR)) {
    return window.navigator.userLanguage
  }

  if (window.navigator.language?.includes(localeConstants.SEPARATOR)) {
    return window.navigator.language
  }
  return localeConstants.default
}

const getInitialLocale = () => {
  return getValidLocale(getLocale())
}

export {
  getInitialLocale
}
