<template>
  <main-template
    id="login"
  >
    <login-form />
  </main-template>
</template>

<script>
import LoginForm from '_molecules_/LoginForm'
import MainTemplate from '_templates_/MainTemplate'
export default {
  name: 'Login',
  components: {
    LoginForm,
    MainTemplate
  }
}
</script>
