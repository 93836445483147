const cleanPhoneNumber = (phone = '') => {
  return phone.replace(/\D/g,'').slice(-10)
}

const inputMaxLength = (string, maxLength) => {
  return String(string).substring(0, maxLength)
}

const removePipes = (string) =>{
  return string.replace('|', '')
}

String.prototype.format = function(){
  var args = arguments
  return this.replace(/\{(\d+)\}/g, function(a, num){
    return args[num] || a
  })
}

export {
  inputMaxLength,
  cleanPhoneNumber,
  removePipes
}
