<template>
  <div
    :class="[
      'sdc-radio-card-content',
      'card card-content-padding',
      'justify-center',
      'mt-5',
      'has-border',
      {
        'selected': selected,
      },
    ]"
  >
    <smile-media-card
      class="sdc-radio-card"
    >
      <template #card-content>
        <div
          :class="[
            'is-flex',
            'is-align-items-center',
            'is-justify-content-space-between'
          ]"
        >
          <slot />
        </div>
      </template>
    </smile-media-card>
  </div>
</template>

<script>
export default {
  name: 'SdcRadioButtonCard',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '_theme_/variables';

  .selected {
    border-color: $primary;
  }
  .sdc-radio-card {
    cursor: pointer;
  }
</style>
