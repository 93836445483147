<template>
  <div id="information-step">
    <create-case-step-form ref="informationForm">
      <slot />
      <form>
        <div ref="personalInfoElement">
          <personal-info
            v-model="personalInfo"
            :render-sms="renderPersonalInfoSms"
            :title="personalTitle"
            :enabled-date-of-birth="enabledDateOfBirth"
          />
        </div>
        <div ref="guardianInfoElement">
          <guardian-info
            v-if="isPatientMinor"
            v-model="guardianInfo"
            :render-sms="renderGuardianInfoSms"
            :title="guardianTitle"
            :email-disabled="isPatientMinor && canPatientBeResumed"
          />
        </div>
        <patient-shipping-address
          :model="shippingAddress"
          :is-address-valid="isAddressValid"
          :title="shippingAddressTitle"
        />
        <patient-consent
          v-if="isPaymentFeatureEnabled"
          v-model="questionInfo.patientConsent"
          class="information__patient-consent"
          disable-get-smile-pay-terms
          :title="i18n('PATIENT_CONSENT__TITLE')"
        />
      </form>
    </create-case-step-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import PersonalInfo from '_molecules_/PersonalInfo'
import GuardianInfo from '_molecules_/GuardianInfo'
import PatientShippingAddress from '_molecules_/PatientShippingAddress'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'
import { ageLimit, formatStandardDate, isValidDate } from '_utils_/dateHelpers'
import PatientConsent from '_molecules_/PatientConsent'

export default {
  name: 'Information',
  components: {
    CreateCaseStepForm,
    GuardianInfo,
    PersonalInfo,
    PatientShippingAddress,
    PatientConsent
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    enabledDateOfBirth: {
      type: Boolean,
      default: false
    },
    checkEmail: {
      type: Function,
      default: () => {}
    },
    checkEmailGuardian: {
      type: Function,
      default: () => {}
    },
    guardianTitle: {
      type: String,
      required: true
    },
    personalTitle: {
      type: String,
      required: true
    },
    shippingAddressTitle: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      renderPersonalInfoSms: true,
      renderGuardianInfoSms: false,
      isAddressValid: true,
      isPatientMinor: false
    }
  },
  computed: {
    ...mapGetters('Payment', ['isPaymentFeatureEnabled']),
    ...mapState('InformationForm', [
      'isExistingEmail',
      'isInvalidEmail',
      'patientInfo',
      'patientGuardianInfo',
      'canPatientBeResumed',
      'canGuardianBeResumed',
      'usePracticeAddressAsShippingAddress'
    ]),
    ...mapState('CreateCase', ['personalInfo', 'guardianInfo', 'shippingAddress', 'questionInfo'])
  },
  watch: {
    'personalInfo.dateOfBirth': {
      handler(dateOfBirth) {
        this.setIfIsPatientMinor(dateOfBirth)
      },
      deep: true
    },
    isPatientMinor(isMinor) {
      this.setGuardianStatus(isMinor)

      this.renderPersonalInfoSms = !isMinor
      this.renderGuardianInfoSms = isMinor

      if (isMinor && this.canPatientBeResumed && this.guardianInfo.emailAddress === '') {
        // Email minor and guardian cannot be different
        this.setEmailGuardianInfo(this.personalInfo.emailAddress)
      }
    }
  },
  beforeMount() {
    this.registerModel()
    this.setPatientGuardianInfo(this.guardianInfo)
    this.setPatientInfo(this.personalInfo)
    this.setIfIsPatientMinor(this.personalInfo.dateOfBirth)
    this.questionInfo.patientConsent = false
  },
  methods: {
    ...mapMutations('CreateCase', [
      'setPersonalInfo',
      'setGuardianInfo',
      'setEmailGuardianInfo',
      'setShippingAddress',
      'clearGuardianInfo',
      'setPatientConsent',
      'setFinanceAgreementUrl',
      'setFastTrackInfo'
    ]),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    ...mapMutations('InformationForm', [
      'setGuardianStatus',
      'setPatientGuardianInfo',
      'setPatientInfo',
      'setEmailPatientGuardianInfo'
    ]),
    ...mapActions('Address', ['getRegionFromZipcode', 'isValidZipcode']),
    ...mapActions('InformationForm', [
      'loadOrCreateCommerceCustomer'
    ]),
    registerModel() {
      this.model.validate = this.validateForm
    },
    async validateForm(next) {
      const isUnderMinimumAge = ageLimit(this.personalInfo.dateOfBirth, I18nConfig.minimumAge)
      if (isUnderMinimumAge) {
        this.$refs.personalInfoElement.scrollIntoView({ behavior: 'smooth' })
      }

      if (this.isPatientMinor) {
        this.$refs.guardianInfoElement.scrollIntoView({ behavior: 'smooth' })
      }

      const formResult = await this.$refs.informationForm.validateForm()
      if (!formResult) {
        return
      }

      if (!this.canGuardianBeResumed) {
        if (this.isPatientMinor && this.guardianInfo.dateOfBirth.year) {
          await this.checkEmailGuardian()
          this.setGuardianInfo({ data: this.guardianInfo })
        } else {
          this.clearGuardianInfo()
          await this.checkEmail()
        }

        if (this.isExistingEmail || this.isInvalidEmail) {
          return
        }
      }

      const isValidZipcode = await this.isValidZipcode(this.shippingAddress)
      if (isValidZipcode.error) {
        this.setShippingFieldError(isValidZipcode.error)
        return
      }

      const shippingAddress = this.shippingAddress
      if (!I18nConfig.hasRegions) {
        const region = await this.getRegionFromZipcode(this.shippingAddress.zipCode)
        if (!region) {
          this.setShippingFieldError(
            i18n('ERROR__ZIPCODE_NOT_FOUND', [i18n('CREATE_CASE__SHIPPING_LABEL_ZIPCODE')])
          )
          return
        }

        shippingAddress.state = region
      }

      await this.loadOrCreateCommerceCustomer()
      this.setPersonalInfo({ data: this.personalInfo })
      this.setShippingAddress({ data: shippingAddress })
      this.setPatientConsent({ data: this.isPaymentFeatureEnabled && this.questionInfo.patientConsent })
      this.setFinanceAgreementUrl('')

      next()
    },
    setShippingFieldError(errorMsg) {
      this.$refs.informationForm.$refs.createCaseStepForm.setErrors({
        'shipping-zip': errorMsg
      })
      this.isAddressValid = false
    },
    setIfIsPatientMinor(event) {
      if (!isValidDate(event)) {
        return
      }

      const date = formatStandardDate(event)

      const isUnderMinimumAge = ageLimit(date, I18nConfig.minimumAge)
      if (isUnderMinimumAge) {
        return
      }
      this.isPatientMinor = ageLimit(date, I18nConfig.patientLegalAge)
    },
    i18n
  }
}
</script>

<style lang="scss" scoped>
.information {
  &__patient-consent {
    margin-top: 30px;
  }
}
</style>
