import axios from 'axios'
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'
import { HttpException } from '_utils_/Exceptions'

export const uploadFile = async ({ caseId, filename, kind }) => {
  try {
    const url = `case/${caseId}/assets`
    const response = await api.post(url, {
      filename,
      kind
    })
    return response.id
  } catch (error) {
    Logger.error('AdditionalFiles API uploadFile', error)
    throw error
  }
}

const getAssetsFileSignedUrl = async (fileData) => {
  try {
    const response = await api.put('assets/asset-upload-url', fileData)
    return response.data
  } catch (error) {
    Logger.error('AdditionalFiles API getAdditionalFileSignedUrl', error)
    throw error
  }
}

export const addAdditionalFile = async (file, caseId) => {
  const { name: fileName } = file
  try {
    const signedUrl = await getAssetsFileSignedUrl({
      caseId,
      fileName
    })
    await axios.put(signedUrl, file)
    return file
  } catch (error) {
    Logger.error('AdditionalFiles API AddAdditionalFile', error)
    throw error
  }
}

export const getFiles = async id => {
  try {
    const response = await api.get(`/case/${id}/assets`)
    return response.data
  } catch (error) {
    Logger.error('AdditionalFiles API getFiles', error)
    throw new HttpException(error)
  }
}
