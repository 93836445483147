<template>
  <validation-observer ref="signatureForm">
    <div class="signature_form__document">
      <p class="signature_form__document__text1">
        {{ termsText1 }}
      </p>
    </div>
    <div class="signature_form__signature_container">
      <div class="signature_form__signature_container__pad">
        <vue-signature-pad
          id="signaturePad"
          ref="signaturePad"
          :options="signOptions"
        />
      </div>
      <div>
        {{ i18n('SIGN_TERMS_SIGN_HERE') }}
      </div>
    </div>
    <div class="signature_form__footer">
      <sdc-button
        id="shipping-label-send-form-submit"
        class="signature_form__footer__button"
        type="primary"
        @click="onSubmit"
      >
        {{ i18n('LABEL__OK') }}
      </sdc-button>
    </div>
  </validation-observer>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapMutations,mapState,mapActions,mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import { formatDate } from '_utils_/dateHelpers'
import { DOCUMENT_KINDS } from '_utils_/constants'

export default {
  name: 'SignatureForm',
  components: {
    SdcButton,
    ValidationObserver
  },
  props: {
    documentKind:{
      type: String,
      required: true
    },
    paymentMethod:{
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    signatureOptions: {
      penColor: '#00f'
    },
    practiceAddress: Object,
    confidentialityWaiverKind: DOCUMENT_KINDS.confidentialityWaiver,
    insuranceConsentKind: DOCUMENT_KINDS.insuranceConsent,
    fastrackConsentKind:DOCUMENT_KINDS.fastrackConsent
  }),
  computed: {
    ...mapState('CreateCase', [
      'personalInfo',
      'shippingAddress',
      'questionInfo'
    ]),
    ...mapGetters('Auth', ['currentPracticeId']),
    ...mapGetters('Practices', ['getById']),
    languageCode() {
      return Location.languageCode
    },
    birthDay(){
      return formatDate(this.personalInfo.dateOfBirth, I18nConfig.dateFormat)
    },
    termsText1(){
      const {firstName,lastName} = this.personalInfo
      const {streetAddress1, streetAddress2, zipCode, city, state} = this.shippingAddress
      const {Name, streetAddress1:streetAddress1p, streetAddress2:streetAddress2p, zipCode:zipCodep, city:cityp, state:statep } = this.practiceAddress
      const unfilteredParams = [
          `${firstName} ${lastName}`,
          `${streetAddress1} ${streetAddress2} ${zipCode} ${city} ${state}`,
          `${this.birthDay}`,
          `${Name}`,
          `${streetAddress1p} ${streetAddress2p} ${zipCodep} ${cityp} ${statep}`
          ]
      let params = unfilteredParams.map(function(x){
        return x.replace(/null/g, '').replace(/undefined/g, '')
      })
      let keyText = this.getText(this.documentKind)
      params.forEach((param, index) => {
        keyText = keyText.replace(new RegExp(`%${index + 1}`, 'g'), param)
      })
      return keyText
    },
    signOptions(){
      return {
        ...this.signatureOptions,
        onBegin: this.onBegin
      }
    }
  },
  async created() {
    this.practiceAddress = await this.getPracticeAddress()
  },
  mounted() {
    this.signatureOptions.onBegin = () => {
      this.$refs.signaturePad.resizeCanvas()
    }
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setSignatures']),
    ...mapActions('Practices', ['getPracticeAddress']),
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    async onSubmit() {
      let { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      if(!isEmpty){
        let signatures = this.questionInfo.signatures
        if(this.documentKind === this.confidentialityWaiverKind){
          signatures.waiverSigned = true
          signatures.waiverSignature ={
            signed: true,
            signatureData: data,
            documentKind: this.confidentialityWaiverKind
          }
        }
        if(this.documentKind === this.insuranceConsentKind){
          signatures.insuranceConsentSigned = true
          signatures.insuranceSignature ={
            signed: true,
            signatureData: data,
            documentKind: this.insuranceConsentKind,
          }
          signatures.fastrackSignature ={
            signed: true,
            signatureData: data,
            documentKind: this.fastrackConsentKind,
            fastTrackOption: this.paymentMethod
          }
        }
        this.setSignatures(signatures)
        this.closeModal()
      }
    },
    onCancel() {
      this.closeModal()
    },
    getText(kind){
      var texts={
        [this.confidentialityWaiverKind]: I18nConfig.waiverText,
        [this.insuranceConsentKind]: I18nConfig.insuranceText
      }
      return texts[kind]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.signature_form {
  &__signature_container,
  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  &__signature_container {
    justify-content: center;
    border-radius: 8px !important;
    border: 2px solid  $gray-88 !important;
    height: 230px;
    width: 100%;
    &__pad {
      width: 100%;
      height: 200px;
    }
  }
  &__footer {
    justify-content: flex-end;
    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }
    &__button {
      font-weight: $font-weight-normal;
      font-family: 'Sofia Pro Bold';
      margin: 8px;
      padding: 15px 30px;
    }
  }
}
</style>
