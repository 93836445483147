<template>
  <card
    v-if="hasAppointmentNote"
    class="purpose_visit__card"
  >
    <template slot="titleSection">
      <sdc-title
        level="3"
        class="purpose_visit__title"
      >
        {{ i18n('PURPOSE_OF_VISIT__TITLE') }}
      </sdc-title>
    </template>
    <template slot="contentSection">
      <div class="purpose_visit__body">
        {{ appointmentNoteByCase.purpose }}
      </div>
    </template>
  </card>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { mapGetters } from 'vuex'
import SdcTitle from '_atoms_/SdcTitle'
import Card from '_atoms_/Card'


export default {
  name: 'PurposeOfVisit',
  components: {
    Card,
    SdcTitle,
  },
  data() {
    return {
      purposeOfVisit: '',
    }
  },
  computed: {
    ...mapGetters('CaseDetails', ['hasAppointmentNote', 'appointmentNoteByCase']),
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss" scoped>
.purpose_visit {
  &__card {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__title {
      margin-top: 0px;
      margin-bottom: 20px;
  }
  &__body {
      margin-top: 10px;
  }
}
</style>
