<template>
  <error-information v-bind="getErrorInformationProps()" />
</template>

<script>
import { i18n } from '_utils_/i18n'
import { errorRedirectUrls } from '_utils_/constants'
import ErrorInformation from '_organisms_/ErrorInformation'

export default {
  name: 'ErrorNotFound',
  components: {
    ErrorInformation
  },
  methods:{
    getErrorInformationProps() {
      return {
        httpStatusCode: 404,
        title: i18n('ERROR_NOT_FOUND__TITLE'),
        descriptionPart1: i18n('ERROR_NOT_FOUND__DESCRIPTION_PART_1'),
        descriptionPart2: i18n('ERROR_NOT_FOUND__DESCRIPTION_PART_2'),
        redirect: i18n('ERROR_NOT_FOUND__REDIRECT'),
        redirectUrl: errorRedirectUrls.statusNotFound,
        phone: i18n('ERROR_NOT_FOUND__PHONE')
      }
    }
  }
}
</script>