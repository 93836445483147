<template>
  <div
    v-if="hasPaymentValues"
    id="fast-track-payment-method"
    name="fast-track-payment-method"
    class="fast-track-payment-method"
  >
    <sdc-title level="4">
      {{ i18n('FAST_TRACK_PAYMENT_METHOD__TITLE') }}
    </sdc-title>
    <div class="fast-track-payment-info__subtitle">
      {{ i18n('FAST_TRACK_PAYMENT_METHOD__MEANS_DESCRIPTION') }}
    </div>
    <sdc-radio-button
      v-model="dataValue"
      name="fast-track-payment-method-radio"
      class="fast-track-payment-method__radio"
      type="card"
      rules="required"
      :options="getOptions()"
      :has-border="true"
    />
    <sdc-checkbox
      v-if="showSmileNowTerms"
      v-model="smileNowTermsValue"
      name="smilenow-consent"
      rules="requiredTerms|requiredTermsBoolean"
      class="fast-track-payment-method__terms-and-consents"
    >
      {{ i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_NOW_CONSENT__TEXT') }}
    </sdc-checkbox>
    <div class="invalid-payment__message-container">
      <sdc-message
        v-if="showPaymentWarning"
        id="fast-track-payment-method-warning-message"
        type="warning"
        :icon-path="infoIconPath"
        :text-message="i18n('CREATE_CASE__INVALID_PAYMENT_WARNING')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { getCarePrice, getPaymentValues } from '_api_/utils.api'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import SdcTitle from '_atoms_/SdcTitle'
import { formatCurrency } from '_utils_/currencyHelpers'
import { fastTrackPaymentMethods, CARE_LEVEL } from '_utils_/constants'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import Location from '_utils_/Location'
import SdcMessage from '_atoms_/SdcMessage'

export default {
  name: 'FastTrackPaymentMethod',
  components: {
    SdcRadioButton,
    SdcTitle,
    SdcCheckbox,
    SdcMessage
  },
  props: {
    smileNowTermsInitialValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataValue: this.$attrs.value,
      paymentValues: {},
      carePriceValues: {},
      smileNowTermsValue: this.smileNowTermsInitialValue
    }
  },
  computed: {
    ...mapState('CreateCase', ['isPaymentUpdate', 'questionInfo', 'fastTrackInfo']),
    showPaymentWarning(){
      return this.isPaymentUpdate
    },
    hasPaymentValues() {
      return Object.keys(this.paymentValues).length > 0
    },
    currency() {
      return I18nConfig.currency
    },
    showSmileNowTerms() {
      return I18nConfig.showTermsAndConsentsByPaymentMethod(fastTrackPaymentMethods.SmileNow) &&
        this.dataValue === fastTrackPaymentMethods.SmileNow
    },
    infoIconPath() {
      return require('_assets_/icon-info.svg')
    },
    isCarePlus() {
      return this.fastTrackInfo.careLevel === CARE_LEVEL.carePlus
    }
  },
  watch: {
    dataValue(newVal) {
      this.smileNowTermsValue = false
      this.$emit('input', newVal)
      this.updatePaymentMethod(newVal)
      this.questionInfo.patientConsent = false
    },
    smileNowTermsValue(newVal) {
      this.$emit('termsAcceptedChanged', newVal)
    }
  },
  async mounted() {
    this.paymentValues = await getPaymentValues(Location.countryCode)
    this.carePriceValues = await getCarePrice()
    this.setLoadingStatus(false)
  },
  methods: {
    ...mapMutations('CreateCase', ['setSignatures']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    i18n,
    getOptions() {
      const {
        price,
        pricePlan,
        downPayment,
        monthlyPayment,
        months
      } = {...this.paymentValues}

      return [
        ...(I18nConfig.singlePayEnabled ? [{
          text: this.formatRadioText({
            left: `
              ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SINGLE_PAY__PART_1',)}
              <span class="fast-track-payment-method__radio-option--lighter">
                ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SINGLE_PAY__PART_2', this.isCarePlus ?
                [this.currency, formatCurrency(this.carePriceValues.carePlus.total)] :
                [this.currency, formatCurrency(price)])}
              </span>
            `,
            right: this.i18n('FAST_TRACK_PAYMENT_METHOD__SINGLE_PAY__PART_3')
          }),
          value: fastTrackPaymentMethods.SinglePay
        }]:[]),
        ...(I18nConfig.smilePayEnabled? [{
          text: this.formatRadioText({
            left: `
              ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_PAY__PART_1')}
              <span class="fast-track-payment-method__radio-option--lighter">
                ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_PAY__PART_2', this.isCarePlus ?
                [this.currency, this.carePriceValues.carePlus.month] :
                [this.currency, monthlyPayment])}
              </span>
            `,
            right:
            `<span class="fast-track-payment-method__radio-option__smile-pay-info">
              ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_PAY__PART_3',
              this.isCarePlus ?
              [
                this.currency,
                formatCurrency(this.carePriceValues.carePlus.downPayment),
                this.carePriceValues.carePlus.downPaymentMonths,
                formatCurrency(this.carePriceValues.carePlus.month),
                formatCurrency(pricePlan)
              ]
              :
              [
                this.currency,
                formatCurrency(downPayment),
                months,
                formatCurrency(monthlyPayment),
                formatCurrency(pricePlan)
              ])}
            </span>`
          }),
          value: fastTrackPaymentMethods.SmilePay
        }]:[]),
        ...(I18nConfig.smileNowEnabled? [{
          text: this.formatRadioText({
            left: `
              ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_NOW__PART_1')}
              <span class="fast-track-payment-method__radio-option--lighter">
                ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_NOW__PART_2', [this.currency, monthlyPayment])}
              </span>
            `,
            right:
            `<span class="fast-track-payment-method__radio-option__smile-pay-info">
              ${this.i18n('FAST_TRACK_PAYMENT_METHOD__SMILE_NOW__PART_3',
              [
                this.currency,
                formatCurrency(downPayment),
                months,
                formatCurrency(monthlyPayment),
                formatCurrency(pricePlan)
              ])}
            </span>`
          }),
          value: fastTrackPaymentMethods.SmileNow
        }]:[]),
        ...(I18nConfig.notFastTrackEnabled? [{
          text: this.formatRadioText({
            left: this.i18n('FAST_TRACK_PAYMENT_METHOD__NOT_FAST_TRACK__PART_1'),
            right: this.i18n('FAST_TRACK_PAYMENT_METHOD__NOT_FAST_TRACK__PART_2')
          }),
          value: fastTrackPaymentMethods.NotFastTrack
        }]:[])
      ]
    },
    formatRadioText({ left, right }) {
      return `<div class="fast-track-payment-method__radio-option">
        <div class="fast-track-payment-method__radio-option--left">${left}</div>
        <div class="fast-track-payment-method__radio-option--right">${right}</div>
      </div>`
    },
    updatePaymentMethod(paymentMethod){
      let signatures = this.questionInfo.signatures
      signatures.fastrackSignature.fastTrackOption = paymentMethod
      this.setSignatures(signatures)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$tablet: map-get($sdc-breakpoints, 'tablet');
$mobile: map-get($sdc-breakpoints, 'mobile');

.fast-track-payment-method {
  &__radio {
    label {
      display: block;
      width: 100%;
    }
  }

  &__radio-option {
    display: flex;
    @media screen and (max-width: $mobile) {
      display: inline-block;
    }

    justify-content: space-between;
    align-items: baseline;

    &--left {
      font-weight: $font-weight-bold;
      font-size: $font-size-md;
      min-width: 210px;
      @media screen and (max-width: $mobile) {
        font-size: 1.1rem;
        min-width: unset;
      }
    }

    &--right {
      font-size: $font-size-sm;
      align-self: center;
      text-align: right;
      @media screen and (max-width: $mobile) {
        text-align: left;
      }
    }

    &--lighter {
      font-weight: $font-weight-normal;
    }
  }
  &__terms-and-consents {
    margin-top: 12px;
    display: block;
  }
}
.invalid-payment__message-container{
  margin-top: 30px;
}

</style>
