const POSITION_TYPES = {
  'top': 'is-top',
  'left': 'is-left',
  'bottom': 'is-bottom',
  'right': 'is-right',
  default: 'is-right'
}

export {
  POSITION_TYPES
}
