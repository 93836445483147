<template>
  <div class="email-notification-item">
    <div class="email-notification-item__name">
      {{ emailAddress }}
    </div>
    <div class="email-notification-item__action">
      <sdc-button
        id="email-notification-item-edit"
        type="link"
        class="email-notification-item__action-button"
        @click="showModal"
      >
        {{ i18n('EMAIL_NOTIFICATIONS__EDIT') }}
      </sdc-button>
      <sdc-button
        id="email-notification-item-remove"
        type="link"
        class="email-notification-item__action-button"
        @click="openRemoveModal"
      >
        {{ i18n("EMAIL_NOTIFICATIONS__REMOVE") }}
      </sdc-button>
    </div>
    <div :id="`emailSetting-${emailAddress}`">
      <sdc-checkbox
        v-for="(value, name) in settings"
        :key="name"
        v-model="settings[name]"
        :name="`${emailAddress}-checkbox-${name}`"
        :disabled="true"
      >
        {{ getEmailNotificationLabel(name) }}
      </sdc-checkbox>
    </div>
    <sdc-modal
      :ref="getRemoveModalRefandName"
      :name="getRemoveModalRefandName"
      :is-static="true"
      size="lg"
      v-bind="removeModalProps"
      @onConfirm="onRemoveConfirm"
    >
      {{ i18n('EMAIL_NOTIFICATIONS__REMOVE_MODAL_TEXT') }}
    </sdc-modal>
    <email-notification-modal
      id="email-notification-item-modal"
      ref="email-notification-modal"
      :email-address="emailAddress"
      :settings="settings"
    />
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcButton from '_atoms_/SdcButton'
import SdcModal from '_molecules_/SdcModal'
import EmailNotificationModal from '_molecules_/EmailNotificationsModal'
import {
  EMAIL_NOTIFICATION_SETTINGS,
  snackbarDefaultSettings
} from '@/utils/constants'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'EmailNotificationItem',
  components: {
    SdcCheckbox,
    SdcButton,
    SdcModal,
    EmailNotificationModal
  },
  props: {
    emailAddress: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modalShow: false,
      removeModalProps: {
        titleProps: {
          //TODO: There's probably a more elegant way of hiding a small cross at the top right of a modal but this will do in such short time.
          text: ' '
        },
        buttonProps: {
          confirmProps: {
            text: 'EMAIL_NOTIFICATIONS__REMOVE_MODAL_YES'
          },
          cancelProps: {
            text: 'EMAIL_NOTIFICATIONS__REMOVE_MODAL_NO'
          }
        }

      }
    }
  },
  computed: {
    removeNotificationModalRef() {
      return this.$refs[`remove-notification-modal-${this.emailAddress}`]
    },
    getRemoveModalRefandName() {
      return `remove-notification-modal-${this.emailAddress}`
    }
  },
  methods: {
    ...mapActions('EmailNotification', ['removeEmailNotification']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    getEmailNotificationLabel(notificationEventName) {
      return i18n(EMAIL_NOTIFICATION_SETTINGS.get(notificationEventName).label)
    },
    i18n,
    openRemoveModal() {
      this.removeNotificationModalRef.open()
    },
    async onRemoveConfirm() {
      this.setLoadingStatus(true)
      let result = await this.removeEmailNotification(this.emailAddress)
      this.setLoadingStatus(false)
      if(!result.error) {
        this.toast({ msg: 'EMAIL_NOTIFICATIONS__REMOVE_SUCCESS_MESSAGE', title: 'EMAIL_NOTIFICATIONS__REMOVE_SUCCESS_TITLE' })
        return
      }
      this.toast({ msg: 'EMAIL_NOTIFICATIONS__ERROR_MESSAGE', title: 'EMAIL_NOTIFICATIONS__ERROR_TITLE'})
      this.removeNotificationModalRef.disableRightButton = false
    },
    toast({ msg, title }) {
      this.$sdcToast.open({ title: this.i18n(title), content: this.i18n(msg) })
    },
    showModal() {
      this.$refs['email-notification-modal'].open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "_theme_/_variables";

$tablet: map-get($sdc-breakpoints, "tablet");
$desktop: map-get($sdc-breakpoints, "desktop");

.email-notification-item {
  border: 2px dashed $gray-88;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  min-height: 270px;
  word-wrap: break-word;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    min-height: 300px;
  }

  &__name {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
  }

  &__action {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding: 0;
    &-button {
      margin-right: 14px;
      border-radius: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      font-family: $font-family-bold;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      line-height: $line-height-md;
      color: $indigo;
      display: block;
      outline: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
