<template>
  <section
    v-if="!isLoading"
    id="case-details-patient-info"
    class="case-details-patient-info"
  >
    <div class="case-details-patient-info__name-section">
      <div class="case-details-patient-info__name-section__name">
        <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_NAME') }}</label>
        <span>{{ name }}</span>
      </div>
      <div class="case-details-patient-info__name-section__edit">
        <sdc-button
          v-if="!isDdvcStaff"
          class="case-details-patient-info__name-section__edit__button"
          type="link"
          @click="openModal"
        >
          {{ i18n('CASE_DETAILS__PATIENT_DETAILS_EDIT_INFO_BTN') }}
        </sdc-button>
      </div>
    </div>
    <div class="case-details-patient-info__dob-section">
      <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_DATE_OF_BIRTH') }}</label>
      <span>{{ formatedBirth }}</span>
    </div>
    <div class="case-details-patient-info__case-section">
      <div class="case-details-patient-info__case-section__number">
        <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_CASE_NUMBER') }}</label>
        <span>{{ details.caseNumber }}</span>
      </div>
      <div class="case-details-patient-info__case-section__status">
        <div class="case-details-patient-info__case-section__status__type">
          <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_CASE_STATUS') }}</label>
          <case-status-label :status-id="details.status" />
        </div>
        <div
          v-if="shouldShowReason"
          class="case-details-patient-info__case-section__status__reason"
        >
          <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_CASE_REASON') }}</label>
          <span>{{ reasonText }}</span>
        </div>
      </div>
    </div>
    <div class="case-details-patient-info__source-section">
      <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_SOURCE') }}</label>
      <span>{{ source }}</span>
    </div>
    <div class="case-details-patient-info__impression-section">
      <label>{{ i18n('CASE_DETAILS__PATIENT_DETAILS_IMPRESSION_TYPE') }}</label>
      <span>{{ formatedImpressionMode }}</span>
    </div>
    <sdc-modal
      ref="edit-details-modal"
      name="edit-details"
      size="xl"
      hide-footer
      :title-props="titleProps"
    >
      <case-details-edit-case :close-modal="closeModal" />
    </sdc-modal>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { i18n } from '_utils_/i18n'
import { formatDate } from '_utils_/dateHelpers'
import I18nConfig from '_utils_/I18nConfig'
import { CASE_STATUS, CASE_SOURCE_TEXT } from '_utils_/constants'
import SdcButton from '_atoms_/SdcButton'
import SdcModal from '_molecules_/SdcModal'
import CaseStatusLabel from '_molecules_/CaseStatusLabel'
import CaseDetailsEditCase from '_molecules_/CaseDetailsEditCase'


export default {
  name: 'CaseDetailsPatientInfo',
  components: {
    SdcButton,
    SdcModal,
    CaseStatusLabel,
    CaseDetailsEditCase
  },
  computed: {
    ...mapState('CaseDetails', ['details', 'isLoading']),
    ...mapGetters('Auth', ['isDdvcStaff']),
    name() {
      return this.details ?
        `${this.details.firstName} ${this.details.lastName}` :
        null
    },
    formatedBirth() {
      const { dateOfBirth } = this.details
      return (dateOfBirth) ?
        formatDate(dateOfBirth, I18nConfig.dateFormat) : ''
    },
    formatedImpressionMode() {
      const { impressionMode } = this.details
      return (impressionMode) ?
        i18n(`CASE_DETAILS__IMPRESSION_TYPE_${impressionMode.toUpperCase()}`) : ''
    },
    reasonText() {
      const { reasonCode, status } = this.details
      const reason = i18n(`CASE_DETAILS__PATIENT_DETAILS_CASE_REASON_${reasonCode}`)

      if (!reason && status === CASE_STATUS.requiresAction) {
        return i18n('CASE_DETAILS__PATIENT_DETAILS_CASE_REASON_OTHER')
      }

      return reason
    },
    shouldShowReason() {
      return this.reasonText.length > 0
    },
    titleProps() {
      return {
        text: 'CASE_DETAILS__PATIENT_DETAILS_EDIT_DETAILS'
      }
    },
    source() {
      return i18n(CASE_SOURCE_TEXT[this.details.source] || CASE_SOURCE_TEXT.default)
    }
  },
  methods: {
    i18n,
    openModal() {
      this.$refs['edit-details-modal'].open()
    },
    closeModal() {
      this.$refs['edit-details-modal'].close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, "mobile");
$tablet: map-get($sdc-breakpoints, "tablet");
$desktop: map-get($sdc-breakpoints, "desktop");

.case-details-patient-info {
  width: 100%;

  label {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $gray-600;
    margin-bottom: 2px;
    display: block;
  }

  & > div {
    margin-bottom: 20px;
  }

  &__name-section {
    display: flex;
    justify-content: space-between;

    &__name {
      padding-right: 50px;
      @media screen and (max-width: $mobile) {
        overflow-wrap: break-word;
      }

      & > span {
        font-family: $font-family-bold;
        font-weight: $font-weight-normal;
        font-size: $h4-font-size;
        line-height: $h3-font-size;
      }
    }

    &__edit {
      min-width: 78px;

      &__button {
        font-family: $font-family-bold;
        font-size: $font-size-xs !important;
        font-weight: $font-weight-normal;
        line-height: $font-size-md;
        color: $indigo;
        display: block;
        outline: none;
        cursor: pointer;
        @media screen and (max-width: $mobile) {
          text-align: center;
        }
        &:hover,
        &:active,
        &:focus {
          text-decoration: none !important;
          color: $indigo;
          box-shadow: none !important;
        }
      }
    }
  }

  &__dob-section {
    & > span {
      font-family: $font-family-base;
      font-weight: $font-weight-normal;
      font-size: $font-size-md;
      color: $black;
    }
  }

  &__case-section {
    display: flex;

    @media screen and (max-width: $desktop) {
      flex-direction: column;
    }

    & > div {
      width: 50%;

      @media screen and (max-width: $desktop) {
        width: 100%;
      }
    }

    &__number {
      margin-right: 40px;

      @media screen and (max-width: $tablet) {
        margin-bottom: 20px;
      }

      @media screen and (max-width: $desktop) {
        margin-bottom: 20px;
      }
    }

    &__status__type {
      margin-bottom: 20px;
    }
  }
}
</style>
