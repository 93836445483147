export const mutationTypes = {
  setForbiddenStatus: 'setForbiddenStatus',
  setNotFoundStatus: 'setNotFoundStatus'
}

export const state = {
  isForbiddenPage: false,
  isNotFoundPage: false,
}

export const getters = {
  isErrorStatusPresent({ isForbiddenPage, isNotFoundPage }) {
    return isNotFoundPage || isForbiddenPage
  }
}

export const mutations = {
  [mutationTypes.setForbiddenStatus](state, newStatus) {
    state.isForbiddenPage = newStatus
  },
  [mutationTypes.setNotFoundStatus](state, newStatus) {
    state.isNotFoundPage = newStatus
  }
}

export const actions = {
  setForbiddenStatus({ commit }, newStatus) {
    commit(mutationTypes.setForbiddenStatus, newStatus)
  },
  setNotFoundStatus({ commit }, newStatus) {
    commit(mutationTypes.setNotFoundStatus, newStatus)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
