<template>
  <div class="sdc-additional-files-upload-container">
    <sdc-additional-files-upload
      v-slot="{ triggerSelectFile }"
      :name="`additional`"
      :max-size="MAX_SIZE"
      :accept="ACCEPT_TYPES"
      :handle-upload="handleSelectFile"
      :show-error-messages="false"
      @error="handleError"
    >
      <sdc-add-file
        class="sdc-additional-files-upload-container__add-file"
        :on-trigger-select-file="triggerSelectFile"
        :file="file"
      />
    </sdc-additional-files-upload>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { addAdditionalFile } from '_api_/caseUploadAdditionalFiles.api'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { MAX_SIZE } from '_utils_/caseAdditionalFilesConstants'
import SdcAdditionalFilesUpload from '_molecules_/SdcAdditionalFilesUpload'
import SdcAddFile from '_molecules_/SdcAddFile'

export default {
  name: 'SdcAdditionalFilesUploadContainer',
  components: {
    SdcAddFile,
    SdcAdditionalFilesUpload
  },
  props: {
    file: {
      type: [File, Object],
      required: true
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details'])
  },
  created() {
    this.MAX_SIZE = MAX_SIZE
    this.ACCEPT_TYPES = I18nConfig.additionalFilesAllowedTypes
    this.mounted = true
  },
  beforeDestroy() {
    this.mounted = false
  },
  methods: {
    ...mapMutations('CaseUploadAdditionalFiles', ['selectNewFile']),
    i18n,
    handleError(errorCode, params) {
      this.$emit('error', errorCode, params)
    },
    async handleSelectFile(file) {
      const caseId = this.$route.params.id
      try {
        await addAdditionalFile(file, caseId)
        if (!this.mounted) {
          return
        }

        this.selectNewFile(file)
      } catch(error) {
        this.selectNewFile({})
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sdc-additional-files-upload-container {
  &__add-file {
    margin: 40px;
  }
}
</style>
