<template>
  <section
    v-if="shouldShowCard"
    class="shipping-label "
  >
    <sdc-loading
      v-if="isShippingLoading"
      type="element"
    />
    <section v-else>
      <sdc-title level="4">
        {{ i18n('SHIPPING_LABEL__TITLE') }}
      </sdc-title>
      <div class="shipping-label__text">
        {{ i18n('SHIPPING_LABEL__INFO_TEXT') }}
      </div>
      <span
        v-if="!isLabelGenerated"
        class="shipping-label__warning__wrapper"
      >
        <img
          id="shipping-label-info"
          :src="infoImgSrc"
          alt="shipping-label-info"
        >
        <div class="shipping-label__warning__text">
          {{ i18n('SHIPPING_LABEL__WARNING__PART_1') }}
          <router-link to="/account">
            {{ i18n('SHIPPING_LABEL__WARNING__LINK_TEXT') }}
          </router-link>
          {{ i18n('SHIPPING_LABEL__WARNING__PART_2') }}
        </div>
      </span>
      <div class="shipping-label__btn-container">
        <sdc-button
          v-if="!isLabelGenerated"
          @click="generateLabel"
        >
          {{ i18n('SHIPPING_LABEL__GENERATE_LABEL') }}
        </sdc-button>
        <div v-if="isLabelGenerated">
          <sdc-button
            id="btn-print-shipping-label"
            :data-url="shipmentInformation.shippingLabelUrl"
            @click="printLabel"
          >
            {{ i18n('SHIPPING_LABEL__PRINT_LABEL') }}
          </sdc-button>
          <sdc-button @click="emailLabel">
            {{ i18n('SHIPPING_LABEL__EMAIL_LABEL') }}
          </sdc-button>
        </div>
      </div>
      <div
        v-if="isLabelGenerated"
        class="shipping-label__tracking-info-container"
      >
        <span class="shipping-label__tracking-info">
          <sdc-image
            name="shipmentTrackingImg"
            :src="shipmentImgSrc"
          />
          {{ i18n('SHIPPING_LABEL__TRACKING_TEXT') }}
          <a
            :href="trackingUrl"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ shipmentInformation.trackingCode }}
          </a>
        </span>
        <span
          v-if="isTrackingStatusAvailable"
          class="shipping-label__tracking-info"
        >
          {{ i18n('SHIPPING_LABEL__TRACKING_STATUS_TEXT') }}
          <a :href="trackingUrl">
            {{ trackingStatus }}
          </a>
        </span>
      </div>
      <div v-if="isLabelGenerated">
        <shipping-label-pickup-form v-if="!isPickupScheduled" />
        <shipping-label-pickup-details
          v-if="isPickupScheduled"
          :pickup="shipmentInformation.pickup"
          class="pickup-details-wrapper"
        />
      </div>
      <shipping-label-send-modal
        ref="shippingLabelSendModal"
        :case-id="$route.params.id"
      />
    </section>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { i18n } from '_utils_/i18n'
import { printImage } from '_utils_/images'
import { IMPRESSION_MODES, LOADING_STATES, SHIPPING_ERROR_CODES } from '@/utils/constants'
import I18nConfig from '_utils_/I18nConfig'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import SdcLoading from '_atoms_/SdcLoading'
import SdcImage from '_atoms_/SdcImage'
import ShippingLabelPickupForm from '_molecules_/ShippingLabelPickupForm'
import ShippingLabelPickupDetails from '_molecules_/ShippingLabelPickupDetails'
import ShippingLabelSendModal from '_molecules_/ShippingLabelSendModal'

export default {
  name: 'ShippingLabel',
  components: {
    SdcTitle,
    SdcButton,
    ShippingLabelPickupForm,
    ShippingLabelPickupDetails,
    ShippingLabelSendModal,
    SdcLoading,
    SdcImage
  },
  data() {
    return {
      isCardVisible: false,
      shippingMode: ''
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details']),
    ...mapState('Shipment', ['shipmentInformation', 'shippingLoadingStatus']),
    ...mapState('Auth', ['authInfo', 'currentPracticeId']),
    isLabelGenerated() {
      return !!this.shipmentInformation?.id
    },
    infoImgSrc() {
      return require('_assets_/icon-info.svg')
    },
    shipmentImgSrc() {
      return require('_assets_/icon-shipping.svg')
    },
    shouldShowCard() {
      return this.details?.impressionMode === IMPRESSION_MODES.physical
    },
    isPickupScheduled() {
      return this.shipmentInformation?.pickup
    },
    isShippingLoading() {
      return this.shippingLoadingStatus === LOADING_STATES.pending
    },
    trackingUrl() {
      return this.shipmentInformation.trackingUrl
    },
    trackingStatus() {
      return this.shipmentInformation?.trackingStatus
    },
    isTrackingStatusAvailable() {
      return this.trackingStatus?.length > 0
    }
  },
  mounted() {
    this.getShipment()
  },
  methods: {
    ...mapActions('Shipment', ['getCaseShipment', 'createShipment']),
    ...mapActions('CaseDetails', ['getPatientDetails']),
    async generateLabel() {
      let shipmentForm = {
        caseId: this.$route.params.id,
        caseNumber: this.details.caseNumber,
        orderId: this.details.order.uuid,
        orderNumber: this.details.order.number,
        productCode: this.details.order.productCode,
        productPrice: this.details.order.productPrice,
        productCurrency: this.details.order.productCurrency,
        productType: this.details.order.productCategory,
        patientFirstName: this.details.firstName,
        patientLastName: this.details.lastName
      }
      const result = await this.createShipment(shipmentForm)

      if (result?.error) {
        this.handleGenerateLabelErrors(result.errorCode)
        return
      }

      await this.getShipment()
      await this.getPatientDetails({ id: this.$route.params.id })
      this.toast(
        i18n('SHIPPING_LABEL__GENERATE_LABEL__SUCCESS'),
        i18n('TEXT__SUCCESS')
      )
    },
    handleGenerateLabelErrors(errorCode) {
      const supportNumber = I18nConfig.supportPhoneNumber
      const errorMessages = {
        [SHIPPING_ERROR_CODES.invalidAddress]: i18n('ERROR__INVALID_ADDRESS'),
        [SHIPPING_ERROR_CODES.addressError]: i18n(
          'ERROR__SHIPPING_ADDRESS_ERROR',
          [supportNumber]
        )
      }

      const errorMessage =
        errorMessages[errorCode] ||
        i18n('ERROR__SHIPPING_CREATION_ERROR', [supportNumber])

      this.toast(
        errorMessage,
        i18n('SHIPPING_LABEL__GENERATE_LABEL')
      )
    },
    printLabel() {
      if (this.shipmentInformation.shippingLabelUrl) {
        printImage(this.shipmentInformation.shippingLabelUrl)
        return
      }

      this.showErrorToast('PRINT')
    },
    emailLabel() {
      if (this.shipmentInformation.shippingLabelUrl) {
        this.$refs.shippingLabelSendModal.open()
        return
      }

      this.showErrorToast('EMAIL')
    },
    showErrorToast(type) {
      const supportPhoneNumber = I18nConfig.supportPhoneNumber
      const errorMessage = i18n(
        supportPhoneNumber
          ? `ERROR__SHIPPING_LABEL__${type}`
          : `ERROR__SHIPPING_LABEL__${type}__DEFAULT`,
        [supportPhoneNumber]
      )
      this.toast(
        errorMessage,
        i18n(`ERROR__SHIPPING_LABEL__${type}__TITLE`)
      )
    },
    toast(message = '', title = '') {
      this.$sdcToast.open({title, content: message})
    },
    setShippingMode(shippingMode) {
      this.shippingMode = shippingMode
    },
    async getShipment() {
      const id = this.$route.params.id
      await this.getCaseShipment(id)
    },
    i18n
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.pickup-details-wrapper {
  margin-top: 18px;
}
.shipping-label {
  &__text {
    line-height: $line-height-md;
    font-weight: $font-weight-normal;
    font-size: $font-size-md;
    color: $secondary;
  }
  &__tracking-info {
    line-height: $line-height-lg;
    font-weight: $font-weight-bold;
    font-size: $font-size-md;
    color: $black;
    margin-right: 12px;
  }
  &__warning {
    &__wrapper {
      display: flex;
      background: $light;
      border-radius: 8px;
      padding: 12px;
      margin-top: 12px;
    }
    &__text {
      font-size: $font-size-sm;
      padding-left: 10px;
      color: $secondary;
    }
  }

  &__btn-container {
    display: flex;
    button {
      margin: {
        right: 20px;
        top: 20px;
      }
    }
    margin-bottom: 18px;
  }

  &__tracking-info-container {
    display: flex;
  }
}
</style>
