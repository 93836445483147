<template>
  <span />
</template>
<script>
import { snackbarDefaultSettings } from '@/utils/constants'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ErrorMgr',
  computed: {
    ...mapGetters('ErrorMgr', ['isNotFoundError', 'isForbiddenError', 'isToastError']),
    ...mapState('ErrorMgr', ['errorMsg'])
  },
  watch: {
    isNotFoundError(newValue) {
      this.checkRedirection('error-not-found', newValue)
    },
    isForbiddenError(newValue) {
      this.checkRedirection('error-forbidden', newValue)
    },
    isToastError(newValue) {
      if (newValue) {
        const { message, title } = this.errorMsg
        this.$sdcToast.open({title, content: message})
        this.clearErrorState()
      }
    }
  },
  mounted() {
    this.clearErrorState()
  },
  methods: {
    ...mapMutations('ErrorMgr', ['clearErrorState']),
    checkRedirection(routeName, isAllowedToRedirect = true) {
      if (isAllowedToRedirect) {
        this.$router.push({ name: routeName })
      }
    }
  }
}
</script>
