<template>
  <validation-observer
    ref="editForm"
    v-slot="{ reset }"
  >
    <form
      @submit.stop.prevent="onSubmit"
      @reset.prevent="reset"
    >
      <section>
        <div class="case-details-edit__patient-name">
          <validated-input
            v-model="patient.firstName"
            name="patient-firstname"
            type="text"
            :max-length="maxLengths.firstName"
            rules="required"
            :label="i18n('CASE_DETAILS_EDIT_CASE__FIRST_NAME__LABEL')"
          />
          <validated-input
            v-model="patient.lastName"
            name="patient-lastname"
            type="text"
            :max-length="maxLengths.lastName"
            rules="required"
            :label="i18n('CASE_DETAILS__EDIT_DETAILS_LABEL_LAST_NAME')"
          />
        </div>
        <div class="case-details-edit__patient-date">
          <h5>{{ i18n('CASE_DETAILS__EDIT_DETAILS_DATE_OF_BIRTH_TITLE') }}</h5>
          <validated-date-input
            v-model="dateOfBirth"
            input-id="patient-date"
            :previous-date="previousDate"
            :custom-rules="customRules.dateOfBirth"
          />
        </div>
 
        <sdc-message
          v-if="showWarningChangeImpressionType"
          type="warning"
          class="case-details-edit__warning"
          :icon-path="infoImgSrc"
          :text-message="i18n('IMPRESSION_TYPE__WARNING_MESSAGE')"
        /> 

        <submit-initial-impression
          v-if="showImpressionType"
          v-model="impressionType"
          class="case-details-edit__impressions"
        /> 

        <div class="text-right case-details-edit__buttons">
          <sdc-button
            type="primary"
            outlined
            size="lg"
            class="mr-2 sdc-modal-close is-outlined"
            @click="onCancel"
          >
            {{ i18n('CASE_DETAILS__EDIT_DETAILS_CANCEL_BTN') }}
          </sdc-button>

          <sdc-button
            type="primary"
            size="large"
            class="sdc-modal-submit"
            :disabled="isLoading"
            @click="onSubmit"
          >
            {{ i18n('CASE_DETAILS__EDIT_DETAILS_SAVE_BTN') }}
          </sdc-button>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapState, mapActions } from 'vuex'
import { isInvalidBirthChange } from '_utils_/dateHelpers'
import { i18n } from '_utils_/i18n'
import { inputMaxLengths, dateErrorTypeKeywords } from '_utils_/constants'
import ValidatedInput from '_atoms_/ValidatedInput'
import ValidatedDateInput from '_molecules_/ValidatedDateInput'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import validationInstance from '_utils_/ValidationInitializer'
import SubmitInitialImpression from '_molecules_/SubmitInitialImpression'
import SdcMessage from '_atoms_/SdcMessage'
import SdcButton from '_atoms_/SdcButton'
import { IMPRESSION_TYPE_WHITE_LIST } from '_utils_/constants'

let previousDate

export default {
  name: 'EditCase',
  components: {
    ValidatedInput,
    ValidatedDateInput,
    ValidationObserver,
    SubmitInitialImpression,
    SdcMessage,
    SdcButton
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      maxLengths: inputMaxLengths.patientInfo,
      previousDate: null,
      patient: {
        patientId: '',
        firstName: '',
        lastName: ''
      },
      customRules: {
        firstName: 'required',
        lastName: 'required',
        emailAddress: 'required|email',
        dateOfBirth: {
          year: 'isInvalidBirthChange:@day,@month,@year|underMinimumAge:@day,@month,@year'
        }
      },
      dateOfBirth: null,
      impressionType: this.$attrs.value || '',
      isLoading: false
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details']),
    storeBirth() {
      const { dateOfBirth } = this.details
      const formattedDate = moment(dateOfBirth)
      return {
        year: formattedDate.format('YYYY'),
        month: formattedDate.format('MM'),
        day: formattedDate.format('DD')
      }
    },
    showImpressionType() {
     return (
        IMPRESSION_TYPE_WHITE_LIST.includes(this.details.status)
      )
    },
    showWarningChangeImpressionType() {
      return this.impressionType !== this.details.impressionMode
    },
    infoImgSrc() {
      return require('_assets_/icon-info.svg')
    }
  },
  watch: {
    impressionType() {
      this.showWarningChangeImpressionType
    }
  },
  beforeMount() {
    this.patient.firstName = this.details.firstName
    this.patient.lastName = this.details.lastName
    this.impressionType = this.details.status
    const tempDate = moment(cloneDeep(this.details.dateOfBirth))

    this.previousDate = {
      year: tempDate.format('YYYY'),
      month: tempDate.format('MM'),
      day: tempDate.format('DD')
    }
    previousDate = this.previousDate

    validationInstance.extendValidation({
      rule: 'isInvalidBirthChange',
      options: {
        params: ['day', 'month', 'year'],
        validate(value, { day, month, year }) {
          return isInvalidBirthChange(previousDate, { day, month, year })
        },
        message: dateErrorTypeKeywords.invalidBirthChange
      }
    })

    this.customRules = {
      ...this.customRules,
      dateOfBirth: {
        day: 'isInvalidBirthChange:@day,@month,@year|underMinimumAge:@day,@month,@year',
        month: 'isInvalidBirthChange:@day,@month,@year|underMinimumAge:@day,@month,@year',
        year: 'isInvalidBirthChange:@day,@month,@year|underMinimumAge:@day,@month,@year'
      }
    }
    this.dateOfBirth = this.storeBirth
    this.impressionType = this.details.impressionMode
  },
  methods: {
    i18n,
    ...mapActions('CaseDetails', ['editPatientDetails', 'editImpressionMode', 'getPatientDetails']),
    clean() {
      const form = this.$refs.editForm
      this.$nextTick(() => {
        this.patient.firstName = ''
        this.patient.lastName = ''
        this.guardianInfo = {
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '',
          dateOfBirth: {
            day: '',
            month: '',
            year: ''
          }
        }
        form.reset()
      })
    },
    async onSubmit() {
      this.isLoading = true
      const form = this.$refs.editForm
      const success = await form.validate()

      if (!success) {
        this.isLoading = false
        return
      }
      const year = this.dateOfBirth.year
      const month = this.dateOfBirth.month
      const day = this.dateOfBirth.day
      const dateOfBirth = `${year}-${month}-${day}`

      let payload = {
        id: this.details.patientId,
        patientData: {
          patientId: this.details.patientId,
          firstName: this.patient.firstName || this.details.firstName,
          lastName: this.patient.lastName || this.details.lastName,
          dateOfBirth
        }
      }

      await this.editPatientDetails(payload)

      if(this.details.impressionMode !== this.impressionType) {
        let payloadImpressionMode = {
          caseId: this.$route.params.id,
          newImpressionMode: this.impressionType
        }

        await this.editImpressionMode(payloadImpressionMode)
      }
      form.reset()
      this.getPatientDetails({
        id: this.$route.params.id,
        shouldSleepRequest: true
      })
      this.closeModal()
      this.clean()
    },
    onCancel() {
      this.closeModal()
      this.clean()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

.case-details-edit{
  &__warning {
    margin-bottom: 0;
  }
  &__buttons{
    display: flex;
    justify-content: flex-end;
    margin-top:30px;

    & > button {
      font-size: $font-size-md;
      font-weight: $font-weight-bolder;
      margin-right: 0.5rem;
    }
  }
  &__patient-name{
    display: flex;
    gap: 0.75rem;

    & > span {
      width: 50%;
    }
  }
  &__patient-date{
    width: 50%;
  }
}

</style>
