import { sdcInstanceProvider as api } from './sdc.config.api'
import { sdcInstance as caseServiceApi } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getPractices = async params => {
  try {
    const response = await api.get('/practices', { params })
    return response.data
  } catch (error) {
    Logger.error('Practices API - getPractices', error)
    throw error
  }
}

const createPractice = async practiceData => {
  try {
    const response = await api.post('/practices', practiceData)
    return response.data
  } catch (error) {
    Logger.error('Practices API - createPractice', error)
    throw error
  }
}

const updatePractice = async (practiceId, practiceData) => {
  try {
    const response = await api.put(`/practices/${practiceId}`, practiceData)
    return response.data
  } catch (error) {
    Logger.error('Practices API - updatePractice', error)
    throw error
  }
}

const patchPractice = async (practiceId, practiceData) => {
  try {
    const response = await api.patch(`/practices/${practiceId}`, practiceData)
    return response.data
  } catch (error) {
    Logger.error('Practices API - patchPractice', error)
    throw error
  }
}

const getPracticeData = async (practiceId, boId) => {
  const url = `/practices/${practiceId}/${boId}`
  try {
    const response = await api.get(url)
    return response.data
  } catch (error) {
    Logger.error('Practices API - getPracticeData', error)
    throw error
  }
}

const getPracticeDataByCaseId = async (caseId) => {
  const url = `/case/${caseId}/practice`
  try {
    const response = await caseServiceApi.get(url)
    return response.data
  } catch (error) {
    Logger.error('Practices API - getPracticeDataByCaseId', error)
    throw error
  }
}

const deletePractice = async (practiceId) => {
  try {
    const response = await api.delete(`/practices/${practiceId}`)
    return response.data
  } catch (error) {
    Logger.error('Practices API - deletePractice', error)
    throw error
  }
}

export {
  getPractices,
  createPractice,
  patchPractice,
  updatePractice,
  getPracticeData,
  getPracticeDataByCaseId,
  deletePractice
}
