<template>
  <main-template>
    <card>
      <template slot="titleSection">
        <sdc-title
          level="2"
          class="card__title"
        >
          {{ i18n('RESET_PASSWORD_TITLE') }}
        </sdc-title>
      </template>
      <template slot="descriptionSection">
        <p class="card__description mt-1">
          {{ i18n('RESET_PASSWORD_DESCRIPTION') }}
        </p>
      </template>
      <template slot="contentSection">
        <reset-password-form />
      </template>
    </card>
  </main-template>
</template>

<script>
import Card from '_atoms_/Card'
import { i18n } from '_utils_/i18n'
import MainTemplate from '_templates_/MainTemplate'
import ResetPasswordForm from '_molecules_/ResetPasswordForm'
import SdcTitle from '_atoms_/SdcTitle'

export default {
  name: 'ResetPassword',
  components: {
    Card,
    MainTemplate,
    ResetPasswordForm,
    SdcTitle
  },
  methods: {
    i18n
  }
}
</script>
