const TABLE_FIELDS = [
  {
    key: 'number',
    label: 'CASES_LIST_TABLE_HEADER_CASE_ID_LABEL',
    sortable: true
  },
  { key: 'name', label: 'CASES_LIST_TABLE_HEADER_NAME_LABEL', sortable: true },
  {
    key: 'creation_date',
    label: 'CASES_LIST_TABLE_HEADER_CREATION_DATE_LABEL',
    sortable: true,
    sortDirection: 'desc'
  },
  {
    key: 'source',
    label: 'CASES_LIST_TABLE_HEADER_SOURCE_LABEL',
    sortable: false
  },
  {
    key: 'status',
    label: 'CASES_LIST_TABLE_HEADER_STATUS_LABEL',
    sortable: false
  }
]

const CASE_STATUS_GROUPS = [
  { key: 'CANCEL', statuses: ['CANCEL'] },
  { key: 'CMPLT', statuses: ['CMPLT'] },
  { key: 'ENDTREATMENT', statuses: ['ENDTREATMENT'] },
  { key: 'IMPAPP', statuses: ['IMPAPP'] },
  { key: 'IMPRCV', statuses: ['IMPRCV'] },
  { key: 'IMPRJC', statuses: ['IMPRJC'] },
  { key: 'NEEDCLEAR', statuses: ['NEEDCLEAR'] },
  { key: 'NEW', statuses: ['NEW'] },
  { key: 'RCVDCLEAR', statuses: ['RCVDCLEAR'] },
  { key: 'REFERRAL', statuses: ['REFERRAL'] },
  { key: 'REQACTION', statuses: ['REQACTION'] },
  { key: 'SCNAPP', statuses: ['SCNAPP'] },
  { key: 'SCNRCV', statuses: ['SCNRCV'] },
  { key: 'SCNRJC', statuses: ['SCNRJC'] },
  { key: 'TREATMENT', statuses: ['TREATMENT', 'WAITRETAINPURCH', 'WAITRETAINSHIP'] },
  { key: 'WAITALGNPURCH', statuses: ['TREATMENTEXP', 'WAITALGNPURCH'] },
  { key: 'UNQUAL', statuses: ['UNQUAL'] },
  { key: 'WAITALGNSHIP', statuses: ['WAITALGNSHIP'] },
  { key: 'WAITEVAL', statuses: ['WAITEVAL', 'WAITMAT'] },
  { key: 'WAITMATRT', statuses: ['WAITMATRT'] },
  { key: 'WAITREFERRAL', statuses: ['WAITREFERRAL'] },
  { key: 'WAITSCAN', statuses: ['WAITSCAN'] },
  { key: 'WAITSCAN-MANUAL', statuses: ['WAITSCAN-MANUAL'] },
  { key: 'WAITLABEL', statuses: ['WAITLABEL']},
  { key: 'WAITMATSHIP', statuses: ['WAITMATSHIP']}
]

const COLUMNS_TO_SORT = {
  number: 'casenumber',
  name: 'patientname',
  creation_date: 'AssignmentDate',
  status: 'status',
}

export {
  CASE_STATUS_GROUPS,
  TABLE_FIELDS,
  COLUMNS_TO_SORT
}
