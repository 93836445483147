import { uploadFiles } from '_api_/scanIntake.api'
import { CATEGORIES } from '_utils_/scanIntakeConstants'
import { LOADING_STATES } from '_utils_/constants'
import { getScanHistory, getAssetUrl as getScanAssetUrl } from '_api_/scanIntake.api'
import { ScanIntakeException } from '@/utils/Exceptions'

const SCAN_HISTORY_MAX_NUMBER_OF_ATTEMPTS = 10
const SCAN_HISTORY_MILISECONDS_TO_RETRY = 3000
let scanHistoryTimeoutId

export const mutationTypes = {
  setAddedFile: 'setAddedFile',
  setLoadingStatus: 'setLoadingStatus',
  setAddingFiles: 'setAddingFiles',
  setGettingHistory: 'setGettingHistory',
  clearAddedFiles: 'clearAddedFiles',
  setHistoryList: 'setHistoryList',
  clearHistoryList: 'clearHistoryList',
  setIsLoading: 'setIsLoading'
}

const addedFilesInitialValue = {
  [CATEGORIES.upper]: {},
  [CATEGORIES.lower]: {}
}

export const errorTypes = {
  getAssetUrl: 'getAssetUrl',
}

const state = {
  isLoading: true,
  historyList: [],
  addedFiles: JSON.parse(JSON.stringify(addedFilesInitialValue)),
  uploadFilesLoadingStatus: LOADING_STATES.idle,
  isAddingFiles: false,
  isGettingHistory: false
}

const mutations = {
  [mutationTypes.setIsLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.setAddedFile](state, { file, category }) {
    state.addedFiles[category] = file
  },
  [mutationTypes.setLoadingStatus](state, { type, status }) {
    state[`${type}LoadingStatus`] = status
  },
  [mutationTypes.clearAddedFiles](state) {
    state.addedFiles = JSON.parse(JSON.stringify(addedFilesInitialValue))
  },
  [mutationTypes.clearHistoryList](state) {
    state.historyList = []
  },
  [mutationTypes.setHistoryList](state, { list }) {
    state.historyList = list
  },
  [mutationTypes.setAddingFiles](state, isAddingFiles) {
    state.isAddingFiles = isAddingFiles
  },
  [mutationTypes.setGettingHistory](state, isGettingHistory) {
    state.isGettingHistory = isGettingHistory
  }
}

const actions = {
  async uploadFiles({ commit, state }, { caseNumber, firstName, lastName }) {
    commit(mutationTypes.setLoadingStatus, { type: 'uploadFiles', status: LOADING_STATES.pending })
    try {
      await uploadFiles({ caseNumber, firstName, lastName }, state.addedFiles)
      commit(mutationTypes.clearAddedFiles)
      commit(mutationTypes.setLoadingStatus, { type: 'uploadFiles', status: LOADING_STATES.resolved })
    } catch(error) {
      commit(mutationTypes.setLoadingStatus, { type: 'uploadFiles', status: LOADING_STATES.rejected })
      throw new ScanIntakeException(error)
    }
  },
  async getScanHistory( { commit, state }, caseNumber ) {
    let executionCounter = 0

    const finishScanHistory = () => {
      clearTimeout(scanHistoryTimeoutId)
      commit(mutationTypes.setIsLoading, false)
    }

    const checkScanHistory = async () => {
      commit(mutationTypes.setIsLoading, true)

      try {
        const list = await getScanHistory(caseNumber)
        executionCounter += 1

        if (list.length > state.historyList.length) {
          commit(mutationTypes.setHistoryList, { list })
          finishScanHistory()
        } else if (executionCounter === SCAN_HISTORY_MAX_NUMBER_OF_ATTEMPTS) {
          finishScanHistory()
        } else {
          checkScanHistory()
        }
      } catch (error) {
        commit(mutationTypes.setHistoryList, { list: [] })
        throw new ScanIntakeException(error)
      } finally {
        commit(mutationTypes.setIsLoading, false)
      }
    }

    scanHistoryTimeoutId = setTimeout(checkScanHistory, SCAN_HISTORY_MILISECONDS_TO_RETRY)
  },
  async getAssetUrl( { commit }, payload) {
    commit(mutationTypes.setIsLoading, true)
    try {
      const result = await getScanAssetUrl(payload.scanType, payload)
      return result
    } catch (err) {
      return {
        error: {
          type: errorTypes.getAssetUrl
        }
      }
    } finally {
      commit(mutationTypes.setIsLoading, false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
