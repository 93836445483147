<template>
  <validation-observer ref="observer">
    <form
      class="consent-terms-form"
      @submit.prevent="onSubmit"
    >
      <fieldset class="form-group">
        <sdc-checkbox
          v-model="accepted"
          name="accept-terms"
          rules="requiredTerms|requiredTermsBoolean"
        >
          {{ i18n('CONSENT_TERMS__AGREEMENT') }}
          <a
            :href="urls.termsAndConditions"
            target="_blank"
          >{{ i18n('CONSENT_TERMS__AND_CONDITIONS') }}</a>
        </sdc-checkbox>
      </fieldset>
      <sdc-button
        type="primary"
        class="btn-block consent-terms-form__submit"
        @click="onSubmit"
      >
        {{ i18n('LABEL__SUBMIT') }}
      </sdc-button>
    </form>
  </validation-observer>
</template>

<script>
// TODO: REFACTOR
import { i18n } from '_utils_/i18n'
import {
  externalUrls,
  TERMS_CONTRACT_SIGN_STATUS
} from '@/utils/constants'
import SdcButton from '_atoms_/SdcButton'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

function currentPracticeDoesNotNeedSign(practices, currentPracticeId) {
  return practices.filter(prac => prac.id === currentPracticeId && prac.needsToBeSigned).length === 0
}

export default {
  name: 'ConsentTermsForm',
  components: {
    SdcButton,
    SdcCheckbox,
    ValidationObserver
  },
  data() {
    return {
      accepted: {
        type: Boolean,
        default: false,
        required: true
      },
      urls: externalUrls,
    }
  },
  computed:{
    ...mapGetters('Auth', ['boId', 'currentPracticeId', 'practices']),
  },
  mounted() {
    if(!this.boId || !this.currentPracticeId
      || currentPracticeDoesNotNeedSign(this.practices, this.currentPracticeId)) {
      this.redirectToHome()
    }
  },
  methods: {
    ...mapActions('Practices', ['patchPractice']),
    ...mapActions('Auth', ['fetchAuthInfo']),
    i18n,
    onSubmit() {
      this.$refs.observer.validate().then((result) => {
        if (result) {
          this.acceptTerms()
        }
      })
    },
    redirectToHome() {
      this.$router.push({ name: 'home', params: { safeToLeaveRoute: true }})
    },
    async acceptTerms() {
      if(this.accepted) {
        let payload = {
          boId: this.boId,
          contractSignStatus: TERMS_CONTRACT_SIGN_STATUS.signed
        }

        const practiceIdToUpdate = this.currentPracticeId
        let practiceResult = await this.patchPractice({payload, practiceIdToUpdate})

        if (practiceResult.error) {
          this.toast({
            msg: this.getErrorMessage(),
            title: i18n('CONSENT_TERMS__AND_CONDITIONS')
          })
        }

        if (!practiceResult.error) {
          await this.fetchAuthInfo()
          this.redirectToHome()
        }
      }
    },
    getErrorMessage() {
      return i18n('CONSENT_TERMS__ERROR')
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content:msg})
    },
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.consent-terms-form {
  &__submit {
    height: 56px;
    border-radius: $h3-font-size;
    font-size: $font-size-md;
    line-height: $font-size-md;
  }
}
</style>
