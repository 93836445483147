<template>
  <validation-provider
    v-slot="{ errors }"
    ref="sdcCheckboxProvider"
    :name="name"
    :rules="rules"
    slim
  >
    <smile-checkbox
      :id="name"
      v-model="dataValue"
      :name="name"
      class="sdc-checkbox"
      :disabled="disabled"
    >
      <slot />
    </smile-checkbox>
    <span
      v-if="errors[0]"
      class="invalid-feedback d-block"
    >{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { SmileCheckbox } from 'sdc-component-library'

export default {
  name: 'SdcCheckbox',
  components: {
    ValidationProvider,
    SmileCheckbox
  },
  props: {
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    dataValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.$attrs.value
      }
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.checkbox {
  padding-left: 0 !important;
}
</style>
