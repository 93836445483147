<template>
  <div
    id="sdc-loading"
    name="sdc-loading"
    class="sdc-loading"
  >
    <div
      v-if="isElement"
      class="sdc-loading__loader"
    />
    <sdc-modal
      v-if="isModal"
      ref="sdcLoading"
      :name="name"
      :can-cancel="false"
      size="small"
      hide-footer
      hide-header
    >
      <div class="sdc-loading__modal__loader">
        <div class="sdc-loading__loader" />
      </div>
      <div
        v-if="isTitleAvailable"
        class="sdc-loading__modal__text"
      >
        <div class="sdc-loading__title">
          {{ title }}
        </div>
        <div>
          {{ i18n('LOADING__WAITING_MSG') }}
        </div>
        <div>
          {{ i18n('LOADING__DO_NOT_REFRESH_MSG') }}
        </div>
      </div>
    </sdc-modal>
  </div>
</template>
<script>
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal';

export default {
  name: 'SdcLoading',
  components: {
    SdcModal
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'modal',
      validator(prop) {
        return ['modal', 'element'].includes(prop)
      }
    },
    name: {
      type: String,
      default: 'sdc-modal-default-name'
    }
  },
  computed: {
    isModal() {
      return this.type === 'modal'
    },
    isElement() {
      return this.type === 'element'
    },
    isTitleAvailable() {
      return this.title.length > 0
    }
  },
  mounted() {
    if (this.isModal) this.$refs.sdcLoading.open()
  },
  methods: {
    i18n
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.sdc-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  &__loader {
    font-size: 6px;
    position: relative;
    border-top: 1em solid rgba(85, 0, 255, 0.3);
    border-right: 1em solid rgba(85, 0, 255, 0.3);
    border-bottom: 1em solid $indigo;
    border-left: 1em solid rgba(85, 0, 255, 0.3);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: sdcload 850ms infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation: sdcload 850ms infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;

    &::after {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
    }
  }

  @-webkit-keyframes sdcload {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes sdcload {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &__modal-dialog {
    max-width: 17rem !important;
    margin: auto !important;
  }

  &__modal {
    &__loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: $font-size-xs;
      margin-bottom: 24px;

      div {
        text-align: center;
      }
    }
  }

  &__title {
    font-weight: $font-weight-bolder;
  }
}
</style>
