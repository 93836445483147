<template>
  <b-collapse
    :open="open"
    @open="$emit('open')"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
    <slot />
  </b-collapse>
</template>
<script>
export default {
  name: 'SdcCollapse',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>