<template>
  <smile-notification
    :active="active"
    :type="type"
  >
    <div
      id="sdc-banner"
      class="sdc-banner"
    >
      <div class="sdc-banner__text">
        <span>{{ text }}</span>
        <ul v-if="hasBullets">
          <li
            v-for="(bullet, key) in bullets"
            :key="key"
          >
            {{ bullet }}
          </li>
        </ul>
      </div>
      <sdc-button
        v-if="buttonText"
        type="primary"
        size="medium"
        outlined
        inverted
        @click="onButtonClick"
      >
        {{ buttonText }}
      </sdc-button>
    </div>
  </smile-notification>
</template>
<script>
import SdcButton from '_atoms_/SdcButton'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { SmileNotification } from 'sdc-component-library'

export default {
  name: 'SdcBanner',
  components: {
    SdcButton,
    SmileNotification
  },
  computed: {
    ...mapState('Banner', ['active', 'text', 'buttonText', 'buttonClick', 'bullets', 'type']),
    ...mapGetters('Banner', ['hasBullets'])
  },
  methods: {
    ...mapMutations('Banner', ['clearState']),
    onButtonClick() {
      if (this.buttonClick) {
        this.buttonClick()
      }

      this.clearState()
    },
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    padding-left: 20px;

    & > span {
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
    }
    & > ul {
      list-style: disc;
      text-align: left;
      padding: revert;
    }
  }
}
</style>
