<template>
  <error-information
    v-bind="getProps()"
  />
</template>

<script>
import { i18n } from '_utils_/i18n'
import ErrorInformation from '_organisms_/ErrorInformation'

export default {
  name: 'ErrorAppointments',
  components: {
    ErrorInformation
  },
  methods:{
    getProps() {
      return {
        descriptionPart1: i18n('ERROR_APPOINTMENTS__DESCRIPTION_PART_1')
      }
    }
  }
}
</script>
