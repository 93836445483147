export default {
  setBoId: 'setBoId',
  setFlowType: 'setFlowType',
  setPracticeId: 'setPracticeId',
  clearGuardianInfo: 'clearGuardianInfo',
  clearBoId: 'clearBoId',
  clearPracticeId: 'clearPracticeId',
  clearState: 'clearState',
  resetPersonalInfo: 'resetPersonalInfo',
  setGuardianInfo: 'setGuardianInfo',
  setEmailGuardianInfo: 'setEmailGuardianInfo',
  setNaturalBite: 'setNaturalBite',
  setPatientPhotosUid: 'setPatientPhotosUid',
  setPersonalInfo: 'setPersonalInfo',
  setQuestionInfo: 'setQuestionInfo',
  setSignatures: 'setSignatures',
  setPaymentUpdate: 'setPaymentUpdate',
  setPatientConsent: 'setPatientConsent',
  setSmsConsent: 'setSmsConsent',
  setShippingAddress: 'setShippingAddress',
  setStoreNumber: 'setStoreNumber',
  setSubmitInfo: 'setSubmitInfo',
  clearFinanceAgreementId: 'clearFinanceAgreementId',
  setFinanceAgreementId: 'setFinanceAgreementId',
  setFastTrackInfo: 'setFastTrackInfo',
  setAlignerPreference: 'setAlignerPreference',
  setCareLevel: 'setCareLevel',
  setSubmittingDoctor: 'setSubmittingDoctor',
  clearCreditCard: 'clearCreditCard',
  startCreatingCase: 'startCreatingCase',
  setFinanceAgreementUrl: 'setFinanceAgreementUrl',
  setResumeCaseInfo: 'setResumeCaseInfo',
  setPBPaymentMethod: 'setPBPaymentMethod',
  setPendingAppointmentInfo: 'setPendingAppointmentInfo',
  setHasPurchased: 'setHasPurchased'
}
