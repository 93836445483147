import moment  from 'moment-timezone'
import I18nConfig from '_utils_/I18nConfig'
import { timeZoneConstants } from '_utils_/constants'

const formatStandardDate = ({ day, year, month }) => {
  return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')
}

const isValidDate = ({ day, year, month }) => {
  return moment(`${month}-${day}-${year}`, 'MM-DD-YYYY', true).isValid()
}

const ageLimit = (inputDate, limit) => {
  const currentDate = moment()
  const diff = moment(currentDate).diff(inputDate, 'year')
  return diff < limit
}

const isInvalidBirthChange = (oldDate, newDate) => {
  const formattedOldDate = moment(`${oldDate.year}-${oldDate.month}-${oldDate.day}`, 'YYYY-MM-DD')
  const formattedNewDate = moment(`${newDate.year}-${newDate.month}-${newDate.day}`, 'YYYY-MM-DD')
  const oldDateIsMinor = ageLimit(formattedOldDate, I18nConfig.patientLegalAge)
  const newDateIsMinor = ageLimit(moment(formattedNewDate, 'YYYY-MM-DD'), I18nConfig.patientLegalAge)

  return oldDateIsMinor === newDateIsMinor
}

function getFormattedDate(date) {
  if (!date) return

  return `${date.year}-${date.month}-${date.day}`
}

/**
 * @param {string} dateString String to be parsed by moment
 * @param {string} stringFormat format string to be parsed by moment format
 * @returns {string} formated date
 */
function formatDate(dateString, stringFormat) {
  return moment(dateString).format(stringFormat)
}

function convertToCentralTime(date) {
 return  moment(date).tz(timeZoneConstants.centralTime)
}

const convertUtcToBrowserTime = (string, format = null) => moment.utc(string, format)
  .local()
  .format(I18nConfig.dateTimeFormat)

export {
  ageLimit,
  isInvalidBirthChange,
  formatStandardDate,
  isValidDate,
  getFormattedDate,
  formatDate,
  convertToCentralTime,
  convertUtcToBrowserTime
}
