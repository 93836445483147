import { checkZipCode, checkValidAddress } from '_api_/address.api'
import { i18n } from '_utils_/i18n'

const extractZipCodeSuggestions = errors => {
  if (!Array.isArray(errors)) return null
  const error = errors.filter(({ field }) => field === 'zipCode')[0]
  return error.message
}

async function zipCodeChecking(addressData) {

  const checkedZipcode = await checkZipCode(addressData)
  const { exists, isUnhandled } = checkedZipcode

  if(!exists && isUnhandled) {
    return { error: true, isUnhandled }
  }

  if (!exists) {
    const suggestions = extractZipCodeSuggestions(checkedZipcode.errors)
    const error = `${i18n('ERROR__ZIPCODE_INVALID')}
      ${suggestions ? i18n('ERROR__ZIPCODE_INVALID_SUGGESTIONS', [suggestions] ) : ''}`
    return { error }
  }

  return true
}

const isAnyAddressFieldCompleted = function (address) {
  return address.zipCode !== ''
    || address.streetAddress1 !== ''
    || address.city !== ''
}

async function validAddressChecking(addressData) {
  const chekedValidAddress = await checkValidAddress(addressData)
  return chekedValidAddress.isValid
}

export {
  zipCodeChecking,
  validAddressChecking,
  isAnyAddressFieldCompleted
}
