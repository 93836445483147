<template>
  <section
    :id="id"
    class="prescription is-flex"
  >
    <div class="prescription__information-column">
      <div
        :id="`${id}-product`"
        class="mb-4"
      >
        <label>{{ i18n('PRESCRIPTION__PRODUCT_LABEL') }}</label>
        <span>{{ productDescription }}</span>
      </div>
      <div :id="`${id}-date-created`">
        <label>{{ i18n('PRESCRIPTION__DATE_CREATED_LABEL') }}</label>
        <span>{{ formattedDateCreated }}</span>
      </div>
    </div>
    <div class="prescription__information-column">
      <div
        :id="`${id}-provider`"
        class="mb-4"
      >
        <label>{{ i18n('PRESCRIPTION__PROVIDER_LABEL') }}</label>
        <span>{{ providerName }}</span>
      </div>
      <div :id="`${id}-state`">
        <label>{{ i18n('PRESCRIPTION__STATUS_LABEL') }}</label>
        <span>{{ state }}</span>
      </div>
    </div>
  </section>
</template>
<script>
import { i18n } from '@/utils/i18n'
import { convertUtcToBrowserTime } from '@/utils/dateHelpers'

export default {
  name: 'Prescription',
  props: {
    id: {
      type: String,
      required: true
    },
    dateCreated: {
      type: String,
      required: true
    },
    productDescription: {
      type: String,
      required: true
    },
    providerName: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDateCreated() {
      return convertUtcToBrowserTime(this.dateCreated)
    }
  },
  methods: {
    i18n
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

label {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $gray-600;
  margin-bottom: 2px;
  display: block;
}

.prescription__information-column {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
</style>