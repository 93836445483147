<template>
  <section class="case-details-patient">
    <sdc-loading
      v-if="isLoading"
      type="element"
    />
    <template v-if="!isLoading">
      <div class="case-details-patient__info-section">
        <case-details-patient-info />
      </div>
      <div class="case-details-patient__photos-section">
        <case-details-patient-photos />
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import SdcLoading from '_atoms_/SdcLoading'
import CaseDetailsPatientInfo from '_molecules_/CaseDetailsPatientInfo'
import CaseDetailsPatientPhotos from '_molecules_/CaseDetailsPatientPhotos'

export default {
  name: 'CaseDetailsPatient',
  components: {
    SdcLoading,
    CaseDetailsPatientInfo,
    CaseDetailsPatientPhotos
  },
  computed: {
    ...mapState('CaseDetails', ['isLoading']),
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
@import '_theme_/_section-panel';

$mobile: map-get($sdc-breakpoints, 'mobile');
$tablet: map-get($sdc-breakpoints, 'tablet');

.case-details-patient {
  @media screen and (max-width: $tablet) {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }

  &__info-section, &__photos-section {
    width: 50%;
    align-self: flex-start;

    @media screen and (max-width: $tablet) { // review size with desktop depending on content
      width: 100%;
    }
  }

  &__photos-section {
    margin-left: 40px;

    @media screen and (max-width: $tablet) { // review size with desktop depending on content
      margin-left: 0;
      width: 45%;
    }

    @media screen and (max-width: $mobile) { // review size with desktop depending on content
      margin-left: 0;
      width: 100%;
    }
  }
}
</style>
