<template>
  <div
    name="submit-initial-impression"
    class="submit-initial-impression"
  >
    <sdc-title level="4">
      {{ i18n('SUBMIT_IMPRESSION__TITLE') }}
    </sdc-title>
    <sdc-radio-button
      v-model="initialImpressionType"
      name="submit-initial-impression-type"
      :options="submitOptions"
      rules="required"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import SdcTitle from '@/components/atoms/SdcTitle'
import { i18n } from '_utils_/i18n'

export default {
  name: 'SubmitInitialImpression',
  components: {
    SdcRadioButton,
    SdcTitle
  },
  data() {
    return {
      initialImpressionType: this.$attrs.value || '',
      submitOptions: [
        {
          value: 'physical',
          text: this.i18n('SUBMIT_IMPRESSION__OPTION_PHYSICAL_IMPRESSIONS')
        },
        {
          value: 'manual',
          text:  this.i18n('SUBMIT_IMPRESSION__OPTION_MANUALLY_UPLOADED_SCANS')
        },
        {
          value: 'scanner',
          text: this.i18n('SUBMIT_IMPRESSION__OPTION_SCANS_FROM_SCANNER')
        }
      ]
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading'])
  },
  watch: {
    initialImpressionType(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n
  }
}
</script>
