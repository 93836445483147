<template>
  <div
    id="question"
    name="question"
  >
    <create-case-step-form ref="questionForm">
      <question-reason-complaint
        v-model="questionInfo.chiefComplaintValue"
        :title="reasonComplaint.title"
        :placeholder="reasonComplaint.placeholder"
      />
      <slot />
      <template v-if="showMedicalQuestions">
        <question-medical-questions
          id="question-medical-questions-have-any"
          :data="haveAnyData"
          :patient-name="patientName"
        />
        <question-medical-questions
          id="question-medical-questions-all-that-apply"
          :data="allThatApplyData"
          :patient-name="patientName"
        />
      </template>
      <patient-consent
        v-if="!disableMedicalPatientConsent"
        v-model="questionInfo.patientConsent"
        :disable-get-smile-pay-terms="disableGetSmilePayTerms"
        :valid-legal-documents="validLegalDocuments"
        :title="i18n('PATIENT_CONSENT__TITLE')"
      />
    </create-case-step-form>
    <notification-modal
      ref="notificationModal"
      :title-props="titleProps"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import QuestionReasonComplaint from '_molecules_/QuestionReasonComplaint'
import QuestionMedicalQuestions from '_molecules_/QuestionMedicalQuestions'
import PatientConsent from '_molecules_/PatientConsent'
import { getMedicalQuestions } from '_api_/medicalQuestions.api'
import NotificationModal from '_molecules_/NotificationModal'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'

export default {
  name: 'Question',
  components: {
    CreateCaseStepForm,
    QuestionReasonComplaint,
    QuestionMedicalQuestions,
    PatientConsent,
    NotificationModal,
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    reasonComplaint: {
      type: Object,
      required: true,
    },
    disableMedicalQuestions: {
      type: Boolean,
      default: false
    },
    disableMedicalPatientConsent: {
      type: Boolean,
      default: false
    },
    disableGetSmilePayTerms: {
      type: Boolean,
      default: false
    },
    validLegalDocuments: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
       titleProps: {
          text: 'CREATE_CASE__PASS_PRACTITIONER_NOTIFICATION_MODAL',
          level: '3'
       }
    }
  },
  computed: {
    ...mapState('CreateCase', ['personalInfo', 'questionInfo']),
    haveAnyData() {
      return this.questionInfo.medicalQuestions.haveAnyData || {}
    },
    allThatApplyData() {
      return this.questionInfo.medicalQuestions.allThatApplyData || {}
    },
    patientName() {
      return this.personalInfo?.firstName || ''
    },
    showMedicalQuestions() {
      if(this.disableMedicalQuestions) {
        return false
      }

      return this.hasMedicalQuestionsAvailable()
    },
  },
  beforeMount() {
    this.registerModel()
  },
  mounted() {
    if(!this.disableMedicalQuestions){
      this.setMedicalQuestionsModel()
    }
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setQuestionInfo']),
    registerModel() {
      this.model.validate = this.validateForm
    },
    hasMedicalQuestionsAvailable() {
      const {
        haveAnyData,
        allThatApplyData
      } = this.questionInfo.medicalQuestions

      return (
        Object.keys(haveAnyData).length > 0 &&
        Object.keys(allThatApplyData).length > 0
      )
    },
    setMedicalQuestionsModel() {
      if (!this.hasMedicalQuestionsAvailable()) {
        getMedicalQuestions().then((result) => {
          if (Object.keys(result).length > 0) {
            this.questionInfo.medicalQuestions.haveAnyData = result.haveAnyData
            this.questionInfo.medicalQuestions.allThatApplyData = result.allThatApplyData
          }
        })
      }
    },
    async validateForm(next) {
      const questionForm = this.$refs.questionForm
      const {
        chiefComplaintValue,
        medicalQuestions,
        patientConsent,
        waiverSigned
      } = this.questionInfo

      const formResult = await questionForm.validateForm()

      if (!formResult) {
        return
      }

      this.setQuestionInfo({
        data: {
          chiefComplaintValue,
          medicalQuestions,
          patientConsent,
          waiverSigned
        }
      })

      if(I18nConfig.showPassPractitionerNotification){
        const modal = this.$refs.notificationModal
        modal.open()
        modal.$on('onAccept', next)
      } else{
        next()
      }
    }
  }
}
</script>
