<template>
  <question
    :model="model"
    :disable-get-smile-pay-terms="true"
    :reason-complaint="reasonComplaint"
    :valid-legal-documents="['INFORMED_CONSENT']"
  >
    <question-correction-refinement
      ref="questionCorrectionRefinement"
      :model="questionInfo.correctionRefinementQuestions"
      :label-type="i18n('QUESTION_MIDCOURSE_CORRECTION__TYPE_OF_MIDCOURSE')"
      :label-reason="i18n('QUESTION_MIDCOURSE_CORRECTION__REASON_OF_MIDCOURSE')"
      :is-midcourse-correction="true"
      :patient-id="patientId"
    />
  </question>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Question from '_organisms_/Question'
import QuestionCorrectionRefinement from '_molecules_/QuestionCorrectionRefinement'
import { i18n } from '_utils_/i18n'

export default {
  name: 'MidcourseQuestion',
  components: {
      Question,
      QuestionCorrectionRefinement
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('CreateCase', ['questionInfo', 'patientId']),
    reasonComplaint() {
      return { title: i18n('QUESTION_MIDCOURSE_CORRECTION_COMPLAINT__TITLE'), placeholder: i18n('QUESTION_MIDCOURSE_CORRECTION_COMPLAINT__TEXTAREA_PLACEHOLDER')  }
    }
  },
  mounted() {
    this.setPatientConsent(false)
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setPatientConsent'])
  }
}
</script>
