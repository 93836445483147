<template>
  <div>
    <sdc-message
      v-if="showNoPracticeSelectedMessage"
      type="warning"
      :icon-path="infoIconPath"
      :text-message="i18n('CASES_LIST_PRACTICE_REQUIRED')"
    />
    <dynamic-table
      name="cases"
      title="MENU__CASES"
      :search-props="searchProps"
      :filters="filterProps"
      :table-props="tableProps"
      :paginator-props="paginatorProps"
      :show-clear-filters="true"
      @onSearch="onSearch"
      @onClearSearch="onClearSearch"
      @onClearFilters="onClearFilters"
      @onSort="onTableSort"
      @onRowSelected="onCaseSelect"
      @onPageChanged="onPageChanged"
    >
      <template #status="data">
        <case-status-label :status-id="data.item.status" />
      </template>
    </dynamic-table>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import DynamicTable from '_templates_/DynamicTable'
import CaseStatusLabel from '_molecules_/CaseStatusLabel'
import { CASE_STATUS_GROUPS, TABLE_FIELDS, COLUMNS_TO_SORT } from './constants'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import SdcMessage from '_atoms_/SdcMessage'
import Location from '_utils_/Location'

export default {
  name: 'Cases',
  components: {
    CaseStatusLabel,
    DynamicTable,
    SdcMessage
  },
  data() {
    return {
      showNoPracticeSelectedMessage: false,
      avoidMultipleRequestsWhenClearingFilters: true
    }
  },
  computed: {
    ...mapState('Locale', ['currentLocale']),
    ...mapState('Practices', ['localPractices']),
    ...mapState('AppProcess', ['loading']),
    ...mapState('Cases', ['tableRequestState', 'localSortBy', 'caseStatusFilter', 'caseDoctorFilter', 'practiceFilter']),
    ...mapGetters('Cases', ['tableData', 'paginationData']),
    ...mapState('Users', ['users']),
    ...mapGetters('Auth', ['boId', 'currentPracticeId', 'isSuperuser', 'isDdvcStaff']),
    searchProps() {
      return {
        placeholder: 'CASES_LIST_SEARCH_CASES_LABEL',
        maxLengthOnOverlap: 24,
        searchText: this.tableRequestState.SearchQuery,
      }
    },
    filterProps() {
      return [
        {
          options: this.practiceSelectOptions,
          visible: this.isSuperuser,
          disabled: false,
          required: true,
          onSelect: this.onFilterPracticeSelected,
          selectedValue: this.practiceFilter
        },
        {
          options: this.doctorSelectOptions,
          visible: true,
          disabled: false,
          onSelect: this.onFilterDoctorsSelected,
          selectedValue: this.caseDoctorFilter
        },
        {
          options: this.casesStatusSelectOptions,
          disabled: false,
          visible: true,
          onSelect: this.onStatusSelected,
          selectedValue: this.caseStatusFilter
        }
      ]
    },
    tableProps() {
      return {
        items: this.tableData,
        fields: TABLE_FIELDS,
        sortBy: this.localSortBy,
        sortDesc: this.sortDesc
      }
    },
    paginatorProps() {
      return {
        totalItems: this.totalCases,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.tableRequestState.PageNumber
      }
    },
    caseStatusGroups() {
      return CASE_STATUS_GROUPS
    },
    casesStatusSelectOptions() {
      const userStatuses = this.caseStatusGroups.map(statusGroup => {
        return {
          value: statusGroup.key,
          text: i18n(`CASE_STATUS__${statusGroup.key}`)
        }
      }).sort((boA, boB) =>
          boA.text.localeCompare(
            boB.text,
            ...Location.localeCompareConfiguration
          )
        )
      userStatuses.unshift({ value:'', text:i18n('CASES_LIST_STATUS_ALL_LABEL') })
      return userStatuses
    },
    getFilteredDoctors(){
      return this.users
        .filter(doctor => doctor.practices.every(p => p.countryCode === this.locale &&
          (this.practiceFilter !== '' ? p.id === this.practiceFilter : p.id)))
        .map(doctor => ({
          text: doctor.fullName,
          value: doctor.userName
        }))
    },
    getFilteredPractices(){
      const filteredPractices = this.localPractices
      return filteredPractices.map(practice => ({
        text: practice.name,
        value: practice.id
      })).sort((practiceA, practiceB) => practiceA.text.localeCompare(practiceB.text, ...Location.localeCompareConfiguration))
    },
    doctorSelectOptions() {
      return [
        {
          value: '',
          text: i18n('CASES_LIST_SUBMITTING_DOCTOR_LABEL')
        },
        ...this.getFilteredDoctors
      ]
    },
    practiceSelectOptions() {
      return [
        {
          value: '',
          text: i18n('SUBMIT__SUBMITTING_PRACTICE_SELECT_PRACTICE')
        },
        ...this.getFilteredPractices
      ]
    },
    sortDesc() {
      return this.tableRequestState.SortDirection === 'desc'
    },
    totalCases() {
      return this.paginationData.totalRecords || 0
    },
    itemsPerPage() {
      return this.tableRequestState.PageSize || 0
    },
    infoIconPath() {
      return require('_assets_/icon-info.svg')
    },
    locale() {
      return this.currentLocale.substring(3,5)
    }
  },
  async mounted() {
    this.requestPracticesByCountryCode(this.locale)
    if(!this.isDdvcStaff){
      await this.requestCases({ params: { ...this.tableRequestState }})
    } else{
      this.showNoPracticeSelectedMessage = true
    }
    await this.requestDoctors({
      boId: this.boId,
      practiceId: this.currentPracticeId
    })
  },
  methods: {
    ...mapMutations('Cases', ['setLocalSortBy', 'setCaseStatusFilter', 'setCaseDoctorFilter', 'setPracticeFilter', 'clearCases','resetFilters']),
    ...mapActions('Practices', ['requestPracticesByCountryCode']),
    ...mapActions('Cases', ['requestCases']),
    ...mapActions('Users', ['requestDoctors']),
    ...mapActions('AppProcess', ['setLoadingStatus']),
    i18n,
    async onSearch(searchQuery) {
      this.tableRequestState.SearchQuery = searchQuery
      this.tableRequestState.PageNumber = 1
      await this.requestCases({ params: { ...this.tableRequestState }})
    },
    async onFilterDoctorsSelected(submittingDoctorUserName){
      if (this.avoidMultipleRequestsWhenClearingFilters){
        this.setCaseDoctorFilter(submittingDoctorUserName)
        this.tableRequestState.SubmittingDoctorUserName = submittingDoctorUserName ? submittingDoctorUserName : null
        const payload = {
          params: {
            ...this.tableRequestState,
            PageNumber: 1,
            SubmittingDoctorUserName: submittingDoctorUserName
          }
        }
        this.applyFilterLogic(payload)
      }
    },
    async onStatusSelected(statusGroupKey) {
      if (this.avoidMultipleRequestsWhenClearingFilters){
        this.setCaseStatusFilter(statusGroupKey)
        this.tableRequestState.CaseStatuses = null
        if (statusGroupKey) {
          this.tableRequestState.CaseStatuses = this.caseStatusGroups.find(group=> group.key === statusGroupKey).statuses.join()
        }
        const payload = {
          params: {
            PageNumber: 1,
            ...this.tableRequestState,
          }
        }
        this.applyFilterLogic(payload)
      }
    },
    async onFilterPracticeSelected(practiceId) {
      if (this.avoidMultipleRequestsWhenClearingFilters){
        this.setPracticeFilter(practiceId)
        this.setCaseDoctorFilter('')
        const boId = practiceId === '' ?
              '' : this.localPractices.filter((practice) => practice.id === practiceId )[0]['boId']
        const payload = {
          params: {
            ...this.tableRequestState,
            PageNumber: 1,
            SubmittingDoctorUserName: '',
            practiceId,
            boId
          }
        }
        this.applyFilterLogic(payload)
      }
    },
    async applyFilterLogic (payload){
      if (this.isDdvcStaff && !payload.params.practiceId) {
        this.clearCases()
        this.showNoPracticeSelectedMessage = true
        this.tableRequestState.practiceId = ''
        this.tableRequestState.boId = ''
      } else{
        this.showNoPracticeSelectedMessage = false
        await this.requestCases(payload)
      }
    },
    async onClearSearch() {
      this.tableRequestState.SearchQuery = ''
      this.tableRequestState.PageNumber = 1
      await this.requestCases({ params: { ...this.tableRequestState }})
    },
    async onClearFilters() {
      this.avoidMultipleRequestsWhenClearingFilters = false
      this.resetFilters()
      this.setCaseStatusFilter('')
      this.setPracticeFilter('')
      this.setCaseDoctorFilter('')
      this.applyFilterLogic({ params: { ...this.tableRequestState }})
      this.avoidMultipleRequestsWhenClearingFilters = true
    },
    async onTableSort({ sortBy, sortDesc }) {
      if(sortBy !== ''){
        const payload = {
          params: {
            ...this.tableRequestState,
            SortColumn: COLUMNS_TO_SORT[sortBy],
            SortDirection: sortDesc ? 'desc' : 'asc'
          }
        }

        this.setLocalSortBy(sortBy)
        await this.requestCases(payload)
      }
    },
    onCaseSelect(selectedCase) {
      this.$router.push({ name: 'case-details', params: { id: selectedCase.id } })
    },
    async onPageChanged(page) {
      this.tableRequestState.PageNumber = page
      await this.requestCases({ params: { ...this.tableRequestState }})
    }
  }
}
</script>
