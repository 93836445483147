import { OktaAuth } from '@okta/okta-auth-js'
import { sdcInstanceProvider as api, sdcInstance } from './sdc.config.api'
import Logger from '@/utils/Logger'

/**
 * Okta initial configuration implementing
 * - Authentication Code Flow with PKCE
 * - Refresh Token Rotation with Custom Auto Renew
 */
const authClient = new OktaAuth({
  clientId: process.env.VUE_APP_CLIENT_ID,
  issuer:
    process.env.VUE_APP_OKTA_DOMAIN + process.env.VUE_APP_OKTA_AUTHORIZATION,
  redirectUri: window.location.origin + '/home',
  pkce: true,
  responseType: ['code', 'token', 'id_token'],
  scopes: ['offline_access', 'openid', 'email'],
  tokenManager: {
    autoRemove: false,
    autoRenew: false
  }
})

/**
 * This function extends Okta.signIn function, expose it to the api
 * and returns the response object
 *
 * @param {Object} { username, password }
 * @return {Object} response - the request data from Okta
 */
const signIn = async ({ username, password }) => {
  try {
    const signInResponse = await authClient.signIn({ username, password })

    const tokenResponse = await authClient.token.getWithoutPrompt({
      sessionToken: signInResponse.sessionToken,
      scopes: ['offline_access', 'openid', 'email']
    })

    authClient.tokenManager.setTokens(tokenResponse.tokens)

    return signInResponse
  } catch (error) {
    error.status = error.xhr?.status || 401
    Logger.error('Auth okta sign in error.', error)
    throw error
  }
}

/**
 * This function extends Okta.signOut function and expose it to the api,
 * signing out the user of the system
 */
const signOut = async () => {
  authClient.signOut({
    postLogoutRedirectUri: window.location.origin
  })
}

/**
 * Get most update authentication state refreshing tokens if needed
 * returns false if authentication is not possible without user intervention
 */
const isUserLoggedIn = async () => {
  const authState = authClient.authStateManager.getAuthState()
  if (!authState || !authState.isAuthenticated) {
    return await renewTokens()
  }
  return true
}

const getTokenManager = () => authClient.tokenManager

const getAuthInfo = async (params) => {
  try {
    const authResponse = await api.get('/auth', { params })
    const groups = await getGroups()
    return { ...authResponse.data, groups }
  } catch (error) {
    Logger.error('Auth API - getAuthInfo method', error)
    throw error
  }
}

/**
 * Token Expired Handler
 * Try renewing all tokens using refreshToken, logout if not possible
 */
authClient?.tokenManager?.on('expired', async function(key) {
  if (key !== 'accessToken') {
    return
  }
  const renewed = await renewTokens()
  if (!renewed) {
    signOut()
  }
})

/**
 * Renews tokens if valid refresh token available
 * Return true if token renewed, false if not possible
 */
const renewTokens = async () => {
  try {
    const tokens = await authClient.tokenManager.getTokens()

    //Support for old valid sessions
    //If no refresh token, get token using session
    //TODO: Remove this section (a month before this release all sessions will be refreshToken based)
    if (
      !tokens.refreshToken &&
      tokens.idToken &&
      authClient.session?.exists()
    ) {
      const response = await authClient.token.getWithoutPrompt({
        responseType: 'id_token, access_token'
      })
      authClient.tokenManager.setTokens(response.tokens)
      return true
    }

    const freshTokens = await authClient.token.renewTokensWithRefresh(
      {
        scopes: tokens.accessToken.scopes
      },
      tokens.refreshToken
    )

    authClient.tokenManager.setTokens(freshTokens)

    return true
  } catch (e) {
    authClient.tokenManager.clear()
    return false
  }
}

const getGroups = async () => {
  try {
    const aToken = await authClient.tokenManager.get('accessToken')
    return aToken.claims.odppGroups
  } catch (error) {
    Logger.error('Auth API - getGroups', error)
    return []
  }
}

const getOktaGuestToken = async () => {
  const response = await sdcInstance.get('/okta/token')
  return response.data.authToken
}

export {
  signIn,
  signOut,
  isUserLoggedIn,
  getTokenManager,
  getAuthInfo,
  getOktaGuestToken
}
