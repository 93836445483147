<template>
  <div
    id="practices"
    class="practices"
  >
    <section class="section-panel-rounded">
      <div class="practices__header">
        <sdc-title level="3">
          {{ i18n('PRACTICES__TITLE') }}
        </sdc-title>
        <sdc-button
          v-if="shouldShowAddPractice"
          type="primary"
          outlined
          class="practices__header__add-button"
          @click="showModal"
        >
          <smile-icon icon="plus" />
          <span>{{ i18n('PRACTICES__ADD_BUTTON') }}</span>
        </sdc-button>
      </div>
      <div
        v-if="isSuperuser"
        class="practices__search_section"
      >
        <sdc-search-box
          :placeholder="i18n('OFFICE_TEAM__SEARCH_PRACTICES')"
          :max-length-on-overlap="24"
          :disabled="isLoading"
          @search="onSearch"
          @clearSearch="onClearSearch"
        />
        <sdc-select
          v-model="stateFilter"
          name="practices-state-filter"
          :options="states"
          @onSelect="onStateSelect"
        />
      </div>
      <div
        v-if="practicesNotFound"
        class="practices__not-found"
      >
        {{ i18n('TEXT__NO_RESULTS_FOUND') }}
      </div>
      <div v-if="isLoading">
        <sdc-loading type="element" />
      </div>
      <template v-else>
        <div class="practices__list">
          <div
            v-for="practice in practicesPaginated"
            :key="practice.id"
            class="practices__list__item"
          >
            <practice-item v-bind="practice" />
          </div>
        </div>
        <div class="practices__pagination-container">
          <div>
            <paginator
              :total-items="totalPractices"
              :items-per-page="pageSize"
              :current-page="currentPage"
              @pageChanged="onPageChanged"
            />
          </div>
        </div>
      </template>
      <practice-modal ref="practiceModal" />
    </section>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import SdcLoading from '_atoms_/SdcLoading'
import SdcSearchBox from '_atoms_/SdcSearchBox'
import SdcSelect from '_atoms_/SdcSelect'
import Paginator from '_molecules_/Paginator'
import PracticeItem from '_molecules_/PracticeItem'
import PracticeModal from '_molecules_/PracticeModal'
import { mapActions, mapState, mapGetters } from 'vuex'
import Location from '_utils_/Location'

export default {
  name: 'Practices',
  components: {
    SdcTitle,
    SdcButton,
    SdcLoading,
    Paginator,
    PracticeItem,
    PracticeModal,
    SdcSearchBox,
    SdcSelect
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      queryFilter: '',
      stateFilter: '',
      states: []
    }
  },
  computed: {
    ...mapGetters('Auth', ['isSuperuser', 'canAddPractices']),
    ...mapState('Practices', ['practices', 'isLoading', 'loaded']),
    practicesSorted() {
      return [...this.filteredPractices].sort((practiceA, practiceB) =>
        practiceA.name.localeCompare(
          practiceB.name,
          ...Location.localeCompareConfiguration
        )
      )
    },
    practicesPaginated() {
      return this.practicesSorted
        .filter((_, i) => i >= this.pageSize * (this.currentPage - 1)) //Skip n
        .filter((_, i) => i < this.pageSize) //Take n
    },
    totalPractices() {
      return this.filteredPractices.length
    },
    practicesNotFound() {
      return !this.totalPractices && this.loaded
    },
    shouldShowAddPractice() {
      // At this time, the add practice workflow is disabled for executive role
      return this.isSuperuser
    },
    filteredPractices() {
      return this.isNotFilterEmpty ?
      this.practices.filter((practice) => practice.name.toUpperCase().includes(this.queryFilter) &&
        practice.state.toUpperCase().includes(this.stateFilter)):
      this.practices
    },
    isNotFilterEmpty() {
      return this.queryFilter !== '' || this.stateFilter !== ''
    },
  },
  async mounted() {
    await this.requestPractices()
    this.states = this.getStates()
  },
  methods: {
    i18n,
    ...mapActions('Practices', ['requestPractices']),
    getStates() {
      if (!I18nConfig.hasRegions) {
        return []
      }
      const regions = I18nConfig.regions.map((item) => ({
        value: item.shortCode,
        text: item.localizedName?.[Location.languageCode] || item.name
      }))
      regions.unshift({ value: '', text: this.i18n('PRACTICE_FORM__STATE') })
      return regions
    },
    showModal() {
      this.$refs.practiceModal.open()
    },
    onPageChanged(page) {
      this.currentPage = page
    },
    onSearch(queryFilter) {
      this.queryFilter = queryFilter.toUpperCase()
      this.currentPage = 1
    },
    onClearSearch() {
      this.queryFilter = ''
      this.currentPage = 1
    },
    onStateSelect(state) {
      this.stateFilter = state
    }
  },
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$mobile: map-get($sdc-breakpoints, 'mobile');

.practices {
  display: flex;
  justify-content: center;

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &__add-button {
      height: 42px;
      margin-top: 8px;
      border-color: $gray-88;
      padding: 0px 20px 0px 20px;
      font-weight: $font-weight-bolder;

      @media screen and (max-width: $mobile) {
        margin-bottom: 12px;
      }
    }
  }

  &__search_section {
    display: flex;
    justify-content: space-between;
    .sdc-search-box {
      width: 49%
    }
    .sdc-select {
      width: 49%
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 100%;
      @media screen and (min-width: $tablet) {
        width: 49%;
      }
    }
  }

  &__not-found {
    text-align: center;
    padding: 0rem 0.75rem 0.8rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 12px;
  }
}
</style>
