<template>
  <div class="business-organization-form">
    <validation-observer ref="businessOrganizationForm">
      <div class="business-organization-form__row">
        <validated-input
          v-model="businessOrganizationName"
          name="business-organization-firstname"
          type="text"
          :max-length="30"
          :rules="customRules"
          :label="i18n('OFFICE_BUSINESS_ORGANIZATION__ADD_DSO_LABEL_NAME')"
          class="business-organization-form__row__input"
        />
      </div>

      <div class="business-organization-form__footer">
        <sdc-button
          class="business-organization-form__footer__button"
          type="primary"
          outlined
          @click="onCancel"
        >
          {{ i18n('OFFICE_TEAM__MODAL_CANCEL_BUTTON') }}
        </sdc-button>

        <sdc-button
          class="business-organization-form__footer__button"
          type="primary"
          :disabled="loading"
          @click="onSubmit"
        >
          {{ setSubmitButtonText() }}
        </sdc-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { snackbarDefaultSettings } from '@/utils/constants'
import { mapActions, mapState, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import ValidatedInput from '_atoms_/ValidatedInput'

export default {
  name: 'BusinessOrganizationForm',
  components: {
    SdcButton,
    ValidatedInput,
    ValidationObserver
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      businessOrganizationName : '',
      success: false
    }
  },
  computed:{
    ...mapState('HttpRequest', ['loading']),
    ...mapGetters('BusinessOrganizations', ['getDSOBusinessOrganizations']),
    shouldValidateSameDSOName(){
      return (
        !this.success &&
        this.getDSOBusinessOrganizations.some(
          value =>
            value.name.toLowerCase() ===
            this.businessOrganizationName.toLowerCase()
        )
      )
    },
    customRules() {
      const existDSONameRule = this.shouldValidateSameDSOName
        ? 'isDuplicatedDSOName'
        : ''
      return `required|${existDSONameRule}`
    }
  },
  methods: {
    i18n,
    ...mapActions('BusinessOrganizations', ['createBusinessOrganization']),
    async onSubmit() {
      const form = this.$refs.businessOrganizationForm

      this.success = await form.validate()
      if (!this.success) return

      const payload = {
        name: this.businessOrganizationName,
        isDso: true,
        isActive: 1,
      }

      const boResult = await this.createBusinessOrganization(payload)
      const title = boResult.error ?
      i18n('OFFICE_TEAM__TITLE_WARNING') :
      i18n('OFFICE_TEAM__TITLE')

      const message = boResult.error ?
      i18n('OFFICE_BUSINESS_ORGANIZATION__ADD_DSO_WARNING'):
      i18n('OFFICE_BUSINESS_ORGANIZATION__ADD_DSO_SUCCESS')

      this.toast({ message, title })

      this.onCancel()
    },
    onCancel() {
      this.closeModal()
    },
    toast({ message, title }) {
      this.$sdcToast.open({title, content: message})
    },
    isEdit() {
      return !!this.businessOrganizationName
    },
    setSubmitButtonText(){
      return i18n('OFFICE_BUSINESS_ORGANIZATION__MODAL_SUBMIT_BUTTON')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, "mobile");

.business-organization-form{
  &__row {
    display: block;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &__input {
      width: 100%;

      @media screen and (min-width: $mobile) {
        width: 49%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-normal;
      font-family: 'Sofia Pro Bold';
      margin: 8px;
      padding: 15px 30px;
    }
  }
}

</style>
