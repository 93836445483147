<template>
  <div name="question-correction-refinement">
    <div>
      <sdc-title level="4">
        {{ labelType }}
      </sdc-title>
      <sdc-radio-button
        v-model="model.type"
        class="question-correction-refinement-type"
        name="question-correction-refinement-radio-type"
        :options="correctionRefinementType"
        rules="required"
      />
    </div>
    <div>
      <sdc-title level="4">
        {{ labelReason }}
      </sdc-title>
      <div class="question-correction-refinement__dropdown">
        <validated-select-input
          v-model="model.reasonType"
          name="question-correction-refinement-select-reason-type"
          rules="required"
          :options="reasonType()"
        />
      </div>
    </div>
    <div v-if="isMidcourseCorrection">
      <sdc-title level="4">
        {{ i18n('QUESTION_MIDCOURSE_CORRECTION__WHICH_ALIGNER_STEP') }}
      </sdc-title>
      <div class="question-correction-refinement__dropdown">
        <validated-select-input
          v-model="model.alignerStep"
          name="question-correction-refinement-select-step"
          rules="required"
          :options="correctionAlignerStep"
        />
      </div>
    </div>
    <div v-if="isMidcourseCorrection">
      <sdc-title level="4">
        {{ i18n('QUESTION_MIDCOURSE_CORRECTION__WHICH_ALIGNER_WEEK') }}
      </sdc-title>
      <div class="question-correction-refinement__dropdown">
        <validated-select-input
          v-model="model.alignerWeek"
          name="question-correction-refinement-select-week"
          rules="required"
          :options="correctionAlignerWeek"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import { getAlignersPatient } from '_api_/medicalQuestions.api'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import SdcTitle from '_atoms_/SdcTitle'
import { i18n } from '_utils_/i18n'
import { correctionRefinementTypes, correctionReason, refinementReason } from '_utils_/constants'

export default {
  name: 'QuestionCorrectionRefinement',
  components: {
    ValidatedSelectInput,
    SdcRadioButton,
    SdcTitle
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    labelType: {
      type: String,
      required: true
    },
    labelReason: {
      type: String,
      required: true
    },
    isMidcourseCorrection: {
      type: Boolean,
      required: true
    },
    patientId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      correctionRefinementType: [
        {
          value: correctionRefinementTypes.upper,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__UPPER_ONLY')
        },
        {
          value: correctionRefinementTypes.lower,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__LOWER_ONLY')
        },
        {
          value: correctionRefinementTypes.upperlower,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__UPPER_LOWER')
        }
      ],
      correctionReasonType: [
        {
          value: '',
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__SELECT_OPTION')
        },
        {
          value: correctionReason.warped,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__WARPED')
        },
        {
          value: correctionReason.tootight,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__TOO_TIGHT')
        },
        {
          value: correctionReason.tooloose,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__TOO_LOOSE')
        },
        {
          value: correctionReason.gapping,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__GAPPING')
        },
        {
          value: correctionReason.nottracking,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__NOT_TRACKING')
        },
        {
          value: correctionReason.shortlong,
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__TOO_LONG')
        }
      ],
      refinementReasonType: [
        {
          value: '',
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__SELECT_OPTION')
        },
        {
          value: refinementReason.noncompliance,
          text: i18n('QUESTION_REFINEMENT__DIDNT_WEAR')
        },
        {
          value: refinementReason.nomatch,
          text: i18n('QUESTION_REFINEMENT__DOESNT_MATCH')
        },
        {
          value: refinementReason.nothappy,
          text: i18n('QUESTION_REFINEMENT__NOT_HAPPY')
        },
        {
          value: refinementReason.issues,
          text: i18n('QUESTION_REFINEMENT__BITE_ISSUES')
        }
      ],
      correctionAlignerWeek: [
        {
          value: '',
          text: i18n('QUESTION_MIDCOURSE_CORRECTION__SELECT_WEEK')
        },
        {
          value: '1',
          text: '1'
        },
        {
          value: '2',
          text: '2'
        },
        {
          value: '3',
          text: '3'
        },
        {
          value: '4',
          text: '4'
        }
      ],
      correctionAlignerStep: []
    }
  },
  async mounted() {
    if(this.isMidcourseCorrection) {
      let alignerData = await getAlignersPatient(this.patientId)

      for (let alignerCount = 0; alignerCount <= alignerData.alignerNumber; alignerCount++) {
        let value = alignerCount.toString()
        this.correctionAlignerStep.push({ value: value, text: value})
      }

      this.correctionAlignerStep.unshift({
        value: '',
        text: i18n('QUESTION_MIDCOURSE_CORRECTION__SELECT_STEP')
      })
    }
  },
  methods: {
    i18n,
    reasonType() {
      return this.isMidcourseCorrection ? this.correctionReasonType : this.refinementReasonType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$desktop: map-get($sdc-breakpoints, 'desktop');

.question-correction-refinement {
  &__dropdown {
    @media screen and (min-width: $desktop) {
      width: 50% !important;
    }
  }
}
</style>
