<template>
  <div
    v-if="showAlignerOptions"
    id="fast-track-aligner-preference"
    class="fast-track-aligner-preference"
  >
    <sdc-title level="4">
      {{ i18n('FAST_TRACK_ALIGNER_PREFERENCES__TITLE') }}
    </sdc-title>
    <sdc-radio-button
      v-model="dataValue"
      name="fast-track-aligner-preference-radio"
      class="fast-track-aligner-preference__radio"
      type="card"
      :options="alignerOptions"
      :has-border="true"
    />
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import { i18n } from '_utils_/i18n'
import { alignerPreferences, EligibilityType, ProductKind } from '_utils_/constants'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'FastTrackAlignerPreference',
  components: {
    SdcTitle,
    SdcRadioButton
  },
  data() {
    return {
      dataValue: this.$attrs.value,
      alignerOptions: [],
      showAlignerOptions: true,
    }
  },
  computed: {
    ...mapState('CreateCase', ['caseId']),
    ...mapGetters('CreateCase', ['isMidcourseCorrectionOrRefinement']),
  },
  watch: {
    dataValue(newVal) {
      this.setAlignerPreference(newVal)
      this.$emit('input', newVal)
    }
  },
  mounted(){
    this.loadAlignerOptions()
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setAlignerPreference']),
    ...mapActions('CreateCase', ['getAlignerEligibilityAndPreference']),
    loadAlignerOptions() {
      const traditional = {
        text: this.formatRadioText({
          up: `<span>${i18n('FAST_TRACK_ALIGNER_PREFERENCES_TRADITIONAL__TITLE')}</span> - ${i18n('FAST_TRACK_ALIGNER_PREFERENCES_TRADITIONAL__PLAN')}`,
          down: i18n('FAST_TRACK_ALIGNER_PREFERENCES_TRADITIONAL__TEXT'),
        }),
        active: alignerPreferences.traditional === this.dataValue,
        value: alignerPreferences.traditional
      }
      const nightTime = {
        text: this.formatRadioText({
          up: `<span>${i18n('FAST_TRACK_ALIGNER_PREFERENCES_NIGHTTIME__TITLE')}</span> - ${i18n('FAST_TRACK_ALIGNER_PREFERENCES_NIGHTTIME__PLAN')}`,
          down: i18n('FAST_TRACK_ALIGNER_PREFERENCES_NIGHTTIME__TEXT'),
        }),
        active: alignerPreferences.nighttime === this.dataValue,
        value: alignerPreferences.nighttime
      }

      if (this.isMidcourseCorrectionOrRefinement) {
        this.setAlignerOptionsForMccOrRef(traditional, nightTime)
        return
      }

      this.alignerOptions.push(traditional, nightTime)
    },
    async setAlignerOptionsForMccOrRef(traditional, nightTime) {
      this.showAlignerOptions = false
      const eligibilityAndPreference = await this.getAlignerEligibilityAndPreference(this.caseId)
      if (eligibilityAndPreference.standard === EligibilityType.Eligible) {
        this.alignerOptions.push(traditional)
      }
      if (eligibilityAndPreference.nighttime === EligibilityType.Eligible) {
        this.alignerOptions.push(nightTime)
      }
      this.showAlignerOptions = this.alignerOptions.length > 1
      this.setAlignerPreferred(eligibilityAndPreference.preferred)
    },
    setAlignerPreferred(alignerPreferred) {
      this.dataValue = alignerPreferences.traditional
      if (this.alignerOptions.some(option => option.value === alignerPreferences.nighttime)) {
        this.dataValue = alignerPreferences.nighttime
      }
      if (this.alignerOptions.some(option => option.value === alignerPreferences.traditional)
        && alignerPreferred !== ProductKind.NightTime) {
        this.dataValue = alignerPreferences.traditional
      }
    },
    formatRadioText({ up, down }) {
      return `<div class="fast-track-aligner-preference__radio-option">
        <div class="fast-track-aligner-preference__radio-option--up">${up}</div>
        <div class="fast-track-aligner-preference__radio-option--down">${down}</div>
      </div>`
    }
  }
}
</script>

<style lang="scss">
@import "_theme_/_variables";
$desktop: map-get($sdc-breakpoints, "desktop");

.fast-track-aligner-preference {

  &__radio {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__radio-option {

    &--up {
      span {
        font-size: $font-size-md;
        font-family: $font-family-black;
      }
    }

    &--down {
      font-size: $font-size-sm;
    }
  }
}
</style>
