import store from '_store_'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'

class ToastException {
  constructor(
    toastTitle = '',
    errorMessageKey = 'ERROR__DEFAULT',
    extraKeyValues = [I18nConfig.supportPhoneNumber]
  ) {
    this.errorMessageKey = errorMessageKey
    this.extraKeyValues = extraKeyValues
    this.toastTitle = toastTitle

    this.throwError()
  }

  throwError() {
    store.commit('ErrorMgr/setToastError', {
      title: this.toastTitle,
      message: i18n(this.errorMessageKey, this.extraKeyValues)
    })
  }
}

export default ToastException
