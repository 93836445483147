<template>
  <information
    :model="model"
    :enabled-date-of-birth="true"
    guardian-title="CASE_MIDCOURSE_CORRECTION_GUARDIAN_INFO_TITLE"
    personal-title="CASE_MIDCOURSE_CORRECTION_PERSONAL_INFO_TITLE"
    shipping-address-title="CASE_MIDCOURSE_CORRECTION_SHIPPING_ADDRESS_INFO_TITLE"
  />
</template>

<script>
import Information from '_organisms_/Information'

export default {
  name: 'MidcourseInformation',
  components: {
      Information
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  }
}
</script>
