<template>
  <sdc-modal
    id="practice-modal"
    ref="practice-modal"
    name="practice-modal"
    :title-props="titleProps"
    size="extra_large"
    hide-footer
  >
    <practice-form
      :close-modal="close"
      :practice-id="practiceId"
    />
  </sdc-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import PracticeForm from '_molecules_/PracticeForm'
import SdcModal from '_molecules_/SdcModal'
export default {
  name: 'PracticeModal',
  components: {
    SdcModal,
    PracticeForm
  },
  props:{
    practiceId: { type: String, default: ''}
  },
  data() {
    return {
      titleProps: {
        text: this.setModalTittle()
      }
    }
  },
  computed: {
    modalRef() {
      return this.$refs['practice-modal']
    }
  },
  methods: {
    i18n,
    focus() {
      this.modalRef.focus()
    },
    open() {
      this.modalRef.open()
    },
    close() {
      this.modalRef.close()
    },
    setModalTittle() {
      return !this.practiceId
        ? 'PRACTICE_MODAL__TITLE'
        : 'PRACTICE_MODAL__TITLE__EDIT'
    }
  }
}
</script>
