<template>
  <validation-provider
    v-slot="{errors, valid}"
    :name="name"
    :rules="rules"
  >
    <sdc-select
      v-model="selected"
      :disabled="disabled"
      :name="name"
      :state="isValid(valid, errors)"
      :options="options"
    />
    <span
      v-if="!isValid(valid, errors)"
      class="invalid-feedback d-block"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import SdcSelect from '_atoms_/SdcSelect'

export default {
  name: 'ValidatedSelectInput',
  components: {
    SdcSelect,
    ValidationProvider
  },
  props: {
    name: { type: String, default: 'text' },
    rules: { type: String, default: 'required' },
    options: { type: Array, required: true },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    selected: {
      get() {
        return this.$attrs.value || ''
      },
      set(v) {
        this.$emit('change', v)
        this.$emit('input', v)
      }
    }
  },
  methods: {
    isValid(valid, errors) {
      return (!valid && errors.length) ? false : null
    }
  }
}
</script>
