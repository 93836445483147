<template>
  <div
    id="submitting-doctor"
    class="submitting-doctor"
  >
    <sdc-title level="4">
      {{ i18n('SUBMIT__SUBMITTING_DOCTOR_TITLE') }}
    </sdc-title>
    <validated-select-input
      :disabled="!hasDoctors"
      name="doctors-list"
      rules="required"
      :options="doctorOptions"
      :value="selectedValue"
      @input="setSubmittingDoctorByUsername"
    />
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import { mapGetters, mapActions, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import Location from '_utils_/Location'

export default {
  name: 'SubmitDoctorInfo',
  components: {
    SdcTitle,
    ValidatedSelectInput
  },
  computed: {
    ...mapState('Auth', ['authUser']),
    ...mapGetters('CreateCase', ['getSubmittingDoctorUserName', 'getDoctorsForCase']),
    ...mapState('CreateCase', ['practiceId']),
    hasDoctors() {
      return this.getDoctorsForCase.length > 0
    },
    selectedValue() {
      return this.getSubmittingDoctorUserName || ''
    },
    doctorOptions() {
      const options = this.getDoctorsForCase.map(item => ({
        value: item.userName,
        text: item.fullName
      })).sort((doctorA, doctorB) => doctorA.text.localeCompare(doctorB.text, ...Location.localeCompareConfiguration))
      options.unshift({ value: '', text: i18n('SUBMIT__SUBMITTING_DOCTOR_SELECT_DOCTOR') })
      return options
    }
  },
  watch: {
    practiceId() {
      this.updateDoctors()
    }
  },
  mounted() {
    this.updateDoctors()
  },
  destroyed() {
    this.clearDoctors()
  },
  methods: {
    i18n,
    ...mapActions('CreateCase', ['loadPracticeDoctors', 'selectDefaultDoctor', 'setSubmittingDoctorByUsername']),
    ...mapActions('Doctors', {
      clearDoctors: 'clear'
    }),
    async updateDoctors() {
      await this.loadPracticeDoctors()
      if (!this.submittingDoctorUserName) {
        this.selectDefaultDoctor()
      }
    }
  }
}
</script>
