import mutationTypes from './helper/mutationTypes'
import {
  createCaseConstants,
  fastTrackPaymentMethods,
  alignerPreferences,
  CARE_LEVEL
} from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import { v4 as uuidv4 } from 'uuid'

const {
  personalInfoModel,
  guardianInfoModel,
  shippingAddressModel,
  signatureModel,
  submitModel
} = createCaseConstants

export default {
  [mutationTypes.setBoId](state, payload) {
    state.boId = payload
  },
  [mutationTypes.setFlowType](state, payload) {
    state.flowType = payload
  },
  [mutationTypes.setPracticeId](state, payload) {
    state.practiceId = payload
  },
  [mutationTypes.setPersonalInfo](state, payload) {
    state.personalInfo = {
      ...state.personalInfo,
      ...payload.data,
      emailAddress: payload.data.emailAddress.toLowerCase()
    }
  },
  [mutationTypes.resetPersonalInfo](state, payload) {
    state.personalInfo.firstName = payload.data.firstName || ''
    state.personalInfo.lastName = payload.data.lastName || ''
    state.personalInfo.emailAddress = (payload.data.emailAddress || '').toLowerCase()
    state.personalInfo.phoneNumber = payload.data.phoneNumber || ''
    if (!state.personalInfo.dateOfBirth) {
      state.personalInfo.dateOfBirth = {}
    }

    state.personalInfo.dateOfBirth.day = payload.data.dateOfBirth?.day || ''
    state.personalInfo.dateOfBirth.month = payload.data.dateOfBirth?.month || ''
    state.personalInfo.dateOfBirth.year = payload.data.dateOfBirth?.year || ''
  },
  [mutationTypes.setGuardianInfo](state, payload) {
    state.guardianInfo = {
      ...state.guardianInfo,
      ...payload.data,
      emailAddress: payload.data.emailAddress.toLowerCase()
    }
  },
  [mutationTypes.setEmailGuardianInfo](state, payload) {
    if (!state.guardianInfo) {
      state.guardianInfo = {}
    }
    state.guardianInfo.emailAddress = payload.toLowerCase()
  },
  [mutationTypes.setShippingAddress](state, payload) {
    state.shippingAddress = {
      ...state.shippingAddress,
      ...payload.data
    }
  },
  [mutationTypes.setStoreNumber](state, payload) {
    state.storeNumber = payload.data
  },
  [mutationTypes.setSubmitInfo](state, payload) {
    state.submitInfo = {
      ...state.submitInfo,
      ...payload.data
    }
  },
  [mutationTypes.clearGuardianInfo](state) {
    state.guardianInfo = JSON.parse(JSON.stringify(guardianInfoModel))
  },
  [mutationTypes.clearBoId](state) {
    state.boId = null
  },
  [mutationTypes.clearPracticeId](state) {
    state.practiceId = null
  },
  [mutationTypes.clearState](state) {
    state.caseId = null
    state.patientId = null
    state.isPaymentUpdate = null
    state.patientUserId = null
    state.PBPaymentMethod = null

    state.personalInfo.firstName = personalInfoModel.firstName
    state.personalInfo.lastName = personalInfoModel.lastName
    state.personalInfo.emailAddress = personalInfoModel.emailAddress
    state.personalInfo.phoneNumber = personalInfoModel.phoneNumber
    if (!state.personalInfo.dateOfBirth) {
      state.personalInfo.dateOfBirth = {}
    }

    state.personalInfo.dateOfBirth.day = personalInfoModel.dateOfBirth.day
    state.personalInfo.dateOfBirth.month = personalInfoModel.dateOfBirth.month
    state.personalInfo.dateOfBirth.year = personalInfoModel.dateOfBirth.year

    state.guardianInfo = JSON.parse(JSON.stringify(guardianInfoModel))
    state.shippingAddress = {
      ...shippingAddressModel
    }
    state.questionInfo = {
      chiefComplaintValue: '',
      medicalQuestions: {
        haveAnyData: {},
        allThatApplyData: {}
      },
      financeAgreementId: null,
      patientConsent: false,
      signatures: {
        waiverSignature: { ...signatureModel },
        insuranceSignature: { ...signatureModel },
        fastrackSignature: { ...signatureModel }
      },
      waiverSigned: false,
      insuranceConsentSigned: false,
      correctionRefinementQuestions: {
        type: '',
        reasonType: '',
        alignerStep: '',
        alignerWeek: ''
      }
    }
    state.fastTrackInfo = {
      paymentMethod: '',
      smileNowTermsAndConsentsAccepted: false,
      alignerPreference: alignerPreferences.traditional,
      careLevel: CARE_LEVEL.standard,
      billingAddress: {
        sameAsShipping: null,
        ...shippingAddressModel
      },
      creditCardInfo: {
        name: '',
        card: {}
      },
      discountCode: '',
      hasAffiliateCode: false,
      hasPurchased: false
    }
    state.photosInfo = {
      patientPhotosUid: null,
      isClosedStraightNaturalBite: false
    }
    state.isCreatingCase = false
    state.financeAgreementUrl = ''
    state.submitInfo = JSON.parse(JSON.stringify(submitModel))
  },
  [mutationTypes.setPatientPhotosUid](state) {
    if (!state.photosInfo.patientPhotosUid) {
      state.photosInfo.patientPhotosUid = uuidv4()
    }
  },
  [mutationTypes.setQuestionInfo](state, payload) {
    state.questionInfo.chiefComplaintValue = payload.data.chiefComplaintValue
    state.questionInfo.medicalQuestions = payload.data.medicalQuestions
    state.questionInfo.patientConsent = payload.data.patientConsent
  },
  [mutationTypes.setSignatures](state, payload) {
    state.questionInfo.signatures.waiverSignature = payload?.waiverSignature
    state.questionInfo.signatures.insuranceSignature =
      payload?.insuranceSignature
    state.questionInfo.signatures.fastrackSignature = payload?.fastrackSignature
    state.questionInfo.waiverSigned = payload?.waiverSigned
    state.questionInfo.insuranceConsentSigned = payload?.insuranceConsentSigned
  },
  [mutationTypes.setPaymentUpdate](state, payload) {
    state.isPaymentUpdate = payload.data.isPaymentUpdate
    state.caseId = payload.data.caseId
    state.fastTrackInfo.paymentMethod = payload.data.fastTrackOption
    state.patientUserId = payload.data.patientUserId
  },
  [mutationTypes.setPatientConsent](state, payload) {
    state.questionInfo.patientConsent = payload.data
  },
  [mutationTypes.setNaturalBite](state, payload) {
    state.photosInfo.isClosedStraightNaturalBite = payload.data
  },
  [mutationTypes.setFinanceAgreementId](state, payload) {
    const id = state.questionInfo.financeAgreementId

    if (payload !== id && payload !== null) {
      state.questionInfo.financeAgreementId = payload
    }
  },
  [mutationTypes.clearFinanceAgreementId](state) {
    state.questionInfo.financeAgreementId = null
  },
  [mutationTypes.setFastTrackInfo](state, { data }) {
    const isPreferenceTiedToPayment =
      !I18nConfig.shouldShowCreateCasePreferenceStep
    let alignerPreference = data.alignerPreference || state.alignerPreference
    if (
      isPreferenceTiedToPayment &&
      data.paymentMethod === fastTrackPaymentMethods.NotFastTrack
    ) {
      alignerPreference = alignerPreferences.traditional
    }

    state.fastTrackInfo = {
      ...state.fastTrackInfo,
      ...data,
      alignerPreference
    }
  },
  [mutationTypes.setAlignerPreference](state, payload) {
    state.alignerPreference = payload
  },
  [mutationTypes.setCareLevel](state, payload) {
    state.careLevel = payload
  },
  [mutationTypes.clearCreditCard](state) {
    state.fastTrackInfo.creditCardInfo.card = {}
  },
  [mutationTypes.setSubmittingDoctor](state, payload) {
    state.submitInfo.submittingDoctor.fullName = payload?.fullName || null
    state.submitInfo.submittingDoctor.userName = payload?.userName || null
  },
  [mutationTypes.startCreatingCase](state, payload) {
    state.isCreatingCase = payload
  },
  [mutationTypes.setFinanceAgreementUrl](state, payload) {
    state.financeAgreementUrl = payload
  },
  [mutationTypes.setResumeCaseInfo](
    state,
    {
      caseId,
      assessmentId,
      patientId,
      hasAffiliateCode,
      discountCode,
      hasPurchased,
      carePlusEnabled
    }
  ) {
    state.caseId = caseId
    state.patientId = patientId
    state.photosInfo.patientPhotosUid = assessmentId
    state.fastTrackInfo.hasAffiliateCode = hasAffiliateCode
    state.fastTrackInfo.discountCode = discountCode
    state.fastTrackInfo.hasPurchased = hasPurchased
    state.fastTrackInfo.carePlusEnabled = carePlusEnabled
  },
  [mutationTypes.setHasPurchased](state, hasPurchased) {
    state.fastTrackInfo.hasPurchased = hasPurchased
  },
  [mutationTypes.setSmsConsent](state, payload) {
    if (payload.hasOwnProperty('prechecked')) {
      state.personalInfo.smsConsentAccepted = payload.prechecked
    }
    else {
      state.personalInfo.smsConsentAccepted = I18nConfig.smsTermsPrechecked
    }

    state.guardianInfo.smsConsentAccepted = I18nConfig.smsTermsPrechecked
  },
  [mutationTypes.setPBPaymentMethod](state, payload) {
    state.PBPaymentMethod = payload
  }
}
