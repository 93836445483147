import { getFormattedDate } from '_utils_/dateHelpers'

const getGuardianInfo = (state) => {
  let guardianInfo
  if (state.guardianInfo.firstName.length > 0) {
    guardianInfo = {
      ...state.guardianInfo
    }
    guardianInfo.dateOfBirth = getFormattedDate(guardianInfo.dateOfBirth)
  }
  return guardianInfo
}

export {
  getGuardianInfo
}