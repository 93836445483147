import { getBusinessOrganizations, postBusinessOrganization } from '_api_/businessOrganizations.api'
import { BUSINESS_ORGANIZATIONS_ERROR_CODES } from '_utils_/constants'

export const errorTypes = {
  addBODuplicateError: 'addBODuplicateError',
  addInactiveBOError: 'addInactiveBOError',
  addBOError: 'addBOError'
}

export const mutationTypes = {
  setBusinessOrganizations: 'setBusinessOrganizations',
  createBusinessOrganization: 'createBusinessOrganization',
  loadError: 'loadError',
  clearBusinessOrganizations: 'clearBusinessOrganizations'
}

export const state = {
  loaded: false,
  businessOrganizations: [],
  loadError: null
}

export const mutations = {
  [mutationTypes.setBusinessOrganizations](state, payload) {
    state.businessOrganizations = payload
    state.loaded = true
  },
  [mutationTypes.loadError](state, payload) {
    state.loadError = payload
    state.loaded = false
  },
  [mutationTypes.clearBusinessOrganizations](state) {
    state.businessOrganizations = []
    state.loaded = false
    state.loadError = null
  },
  [mutationTypes.createBusinessOrganization](state, payload){
    state.businessOrganizations = [...state.businessOrganizations, payload]
  }
}

export const getters = {
  areBusinessOrganizationsLoaded: (state) => {
    return state.loaded
  },
  getBusinessOrganizations: (state) => {
    return state.businessOrganizations
  },
  getDSOBusinessOrganizations: (state, getters) => {
    return getters.getBusinessOrganizations.filter(bo => bo.isDso)
  },
  getById: (state) => (id) => {
    return state.businessOrganizations.find(bo => bo.id === id)
  }
}

export const actions = {
  async requestBusinessOrganizations({ state, commit }) {
    if (state.loaded) {
      return
    }
    try {
      const result = await getBusinessOrganizations()
      commit(mutationTypes.setBusinessOrganizations, result)
    } catch (error){
      commit(mutationTypes.loadError, {
        status: error.status
      })
    }
  },
  clear({ commit }) {
    commit(mutationTypes.clearBusinessOrganizations)
  },
  async createBusinessOrganization({ dispatch, commit }, payload) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      const result = await postBusinessOrganization(payload)
      commit(mutationTypes.createBusinessOrganization, { id: result.id, ...payload})
      return result
    } catch (err) {
      const isBadRequest = err?.response?.status === 400
      const badRequestErrorCode = err?.response?.data?.errorCode

      let errorType = errorTypes.addBOError
      if (isBadRequest) {
        if (badRequestErrorCode === BUSINESS_ORGANIZATIONS_ERROR_CODES.duplicateBOName) errorType = errorTypes.addBODuplicateError
        else if (badRequestErrorCode === BUSINESS_ORGANIZATIONS_ERROR_CODES.inactiveBO) errorType = errorTypes.addInactiveBOError
      }

      return {
        error: {
          type: errorType,
          errorCode: badRequestErrorCode
        }
      }
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
