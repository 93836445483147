import axios from 'axios'
import store from '_store_'
import { mutationTypes } from '_store_/modules/appVersion.store'
import { generateHashContent } from '_utils_/hashContentHelper'
import { APP_VERSION_CHECK_INTERVAL } from '_utils_/constants'
import Logger from '@/utils/Logger'

const indexUrl = '/index.html'

let versionHash = null
let lastCheckTime = null

/**
 * Gets minutes since the last check for updates
 * @returns {Number} time in minutes
 */
const getTimeSinceLastcheck = () =>
  (new Date().getTime() - lastCheckTime) / 60000

export const checkAppVersion = async (config) => {
  if (
    store.state.AppVersion.appHasNewVersion ||
    (lastCheckTime && getTimeSinceLastcheck() < APP_VERSION_CHECK_INTERVAL)
  ) {
    return config
  }
  await triggerUpdateApp()
  return config
}

export async function triggerUpdateApp() {
  try {
    const response = await axios.get(indexUrl)
    const newHash = generateHashContent(response.data)
    if (versionHash === null) versionHash = newHash
    lastCheckTime = new Date().getTime()
    if (versionHash !== newHash) {
      store.commit(`AppVersion/${mutationTypes.markAsUpdated}`)
    }
  } catch (error) {
    Logger.error('checkAppVersion interceptor - triggerUpdateApp', error)
    // Don't block app if there is an error checking version
  }
}
