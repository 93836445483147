import { sdcInstanceProvider as api } from './sdc.config.api'
import { userQueries } from '_utils_/constants'
import Logger from '@/utils/Logger'

const CREATE_USER_ERROR_CODES = {
  'SuperUserAlreadyActive': 'ERROR__SUPERUSER_ALREADY_ACTIVE',
  'UserInAnotherPractice': 'ERROR__USER_IN_ANOTHER_PRACTICE',
  'UserAlreadyActive': 'ERROR__USER_ALREADY_ACTIVE',
  'UserNotCreated': 'ERROR__USER_NOT_CREATED',
  'UserNotUpdated': 'ERROR__USER_NOT_UPDATED'
}

const USER_ACTION_STATUS = {
  UserNotUpdated: 'UserNotUpdated'
}

const createUser = async (boId, practiceId, {
    userName,
    firstName,
    lastName,
    jobTitle,
    isSuperUser,
    isActive,
    role
}) => {
  try {
    const payload = {
      userName,
      firstName,
      lastName,
      jobTitle,
      isSuperUser,
      isActive,
      businessOrganization: {
        boId: boId,
        practices: [
          {
            practiceId,
            isActive,
            roles: [role]
          }
        ]
      }
    }

    const response = await api.post('/users', payload)
    return response.data
  } catch (error) {
    Logger.error('Users API - createUser', error)

    const errorCodeKey = error.response? error.response.data.errorCode : 'UserNotCreated'
    return {
      error: true,
      errorCode: CREATE_USER_ERROR_CODES?.[errorCodeKey]
    }
  }
}

const updateUser = async (boId, practiceId, {
    userName,
    firstName,
    lastName,
    jobTitle,
    isSuperUser,
    isActive,
    isSmileGuide,
    isSalesSpecialist,
    role
}) => {
  try {
    const payload = {
      userName,
      firstName,
      lastName,
      jobTitle,
      isSuperUser,
      isActive,
      isSmileGuide,
      isSalesSpecialist,
      businessOrganization: {
        boId: boId,
        practices: [
          {
            practiceId,
            isActive,
            roles: [role]
          }
        ]
      }
    }

    const response = await api.put(`/users/${payload.userName}`, payload)
    return response.data
  } catch (error) {
    Logger.error('Users API - updateUser', error)

    const errorCodeKey = error.response? error.response.data.errorCode : USER_ACTION_STATUS.UserNotUpdated
    return {
      error: true,
      errorCode: CREATE_USER_ERROR_CODES?.[errorCodeKey]
    }
  }
}

const deleteUser = async (userName, reassignTo) => {
  try {
    const response = await api.delete(`/users/${userName}?reassignTo=${encodeURIComponent(reassignTo)}`)
    return response.data
  } catch (error) {
    Logger.error('Users API - deleteUser', error)
    throw error
  }
}

const getUsers = async ({ params }) => {
  try {
    const response = await api.get('/users', { params } )
    return response.data
  } catch (error) {
    Logger.error('Users API - getUsers', error)
    throw error
  }
}

const getDoctors = async params => {
  return getUsers({ params: { ...userQueries.doctorQuery, ...params }})
}

const getAllUsers = async params => {
  return getUsers({ params: { ...userQueries.allUsersQuery, ...params }})
}

const getUserByName = async ( name ) => {
  try {
    const response = await api.get(`/users/${name}`)
    return response.data
  } catch (error) {
    Logger.error('Users API - GetByUserName', error)
    throw error
  }
}

export {
  getUsers,
  getDoctors,
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserByName
}
