<template>
  <div
    :id="name"
    class="sms-consent"
  >
    <sdc-checkbox
      v-model="dataValue"
      :name="`${name}-checkbox`"
    >
      <span
        v-if="isUpdatedSMSConsent()"
        class="sms-consent__bold-text"
      >
        {{ i18n('SDC_SMS_CONSENT__LABEL_PREFIX') }}
      </span>
      <span
        v-if="isUpdatedSMSConsent()"
      >
        {{ i18n('SDC_SMS_CONSENT__LABEL_CONNECTOR_ONE') }}
      </span>

      {{ i18n('SDC_SMS_CONSENT__LABEL') }}

      <span
        v-if="isUpdatedSMSConsent()"
        class="sms-consent__bold-text"
      >
        {{ i18n('SDC_SMS_CONSENT__LABEL_PREFIX') }}
      </span>

      <span
        v-if="isUpdatedSMSConsent()"
      >
        {{ i18n('SDC_SMS_CONSENT__LABEL_CONNECTOR_TWO') }}
      </span>

      <a
        :href="smsTermsUrl"
        target="_blank"
        class="sms-consent__underline-text"
      >
        {{ i18n('SDC_SMS_CONSENT__LABEL_LINK') }}
      </a>
    </sdc-checkbox>

    <div
      v-if="!dataValue"
      class="sms-consent__disclaimer"
    >
      {{ i18n('SDC_SMS_CONSENT__LABEL_DISCLAIMER') }}
    </div>
  </div>
</template>

<script>
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { DEFAULTLOCATION, SMS_UPDATED_CONSET_COUNTRIES } from '_utils_/constants'
import Location from '_utils_/Location'

export default {
  name: 'SdcSmsConsent',
  components: {
    SdcCheckbox
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataValue: this.$attrs.value
    }
  },
  computed: {
    smsTermsUrl() {
      return I18nConfig.smsTermsUrl
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n,
    isUpdatedSMSConsent() {
      return Location.locale === SMS_UPDATED_CONSET_COUNTRIES.canadaEn
        || Location.locale === DEFAULTLOCATION
        || Location.locale === SMS_UPDATED_CONSET_COUNTRIES.canadaFr
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.sms-consent {
  color: lighten($gray-600, 10);
  font-size: $font-size-sm;

  label.checkbox {
    padding-left: unset !important;
  }

  .custom-control-label {
    font-size: $font-size-sm
  }

  &__disclaimer {
    margin-top: 10px;
    color: $gold;
    background-color: $light-yellow;
    padding: 15px;
    border-radius: 8px;
  }
  &__bold-text {
    font-weight: bold;
  }
  &__underline-text {
    text-decoration: underline;
  }
}
</style>
