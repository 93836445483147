<template>
  <div id="shipping-address">
    <LegalTooltip
      name="shipping-address"
      text-key="SHIPPING_ADDRESS__LEGAL__TEXT"
    >
      <sdc-title v-bind="titleProps">
        {{ i18n(title) }}
      </sdc-title>
    </LegalTooltip>

    <validated-input
      v-model="model.streetAddress1"
      name="shipping-street-address"
      type="text"
      :rules="getRules()"
      :label="i18n('CREATE_CASE__SHIPPING_LABEL_STREET')"
      :max-length="maxLengths.streetAddress1"
    />

    <validated-input
      v-model="model.streetAddress2"
      name="shipping-street-optional"
      type="text"
      rules=""
      :label="i18n('CREATE_CASE__SHIPPING_LABEL_STREET_OPTIONAL')"
      :max-length="maxLengths.streetAddress2"
    />

    <validated-input
      v-model="model.city"
      name="shipping-city"
      type="text"
      :rules="getRules()"
      :label="i18n('CREATE_CASE__SHIPPING_LABEL_CITY')"
      :max-length="maxLengths.city"
    />

    <section class="field-container horizontal-unless-mobile">
      <div>
        <label class="state-input-label">{{ i18n('CREATE_CASE__SHIPPING_LABEL_STATE') }}</label>
        <validated-select-input
          v-if="hasStates"
          v-model="model.state"
          name="shipping-state"
          :rules="getRules()"
          :options="states"
        />
      </div>

      <validated-input
        v-model="model.zipCode"
        name="shipping-zip"
        :rules="getRules()"
        :label="i18n('CREATE_CASE__SHIPPING_LABEL_ZIPCODE')"
        :formatter="isAlphaNumericOrSpace"
        :max-length="maxLengths.zipCode"
        @blur="onBlur"
      />
    </section>
  </div>
</template>

<script>
import ValidatedInput from '_atoms_/ValidatedInput'
import SdcTitle from '_atoms_/SdcTitle'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import { i18n } from '_utils_/i18n'
import { inputMaxLengths } from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import location from '_utils_/Location'
import LegalTooltip from '@/components/molecules/LegalTooltip'

export default {
  name: 'ShippingAddress',
  components: {
    ValidatedInput,
    ValidatedSelectInput,
    SdcTitle,
    LegalTooltip
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    titleLevel: {
      type: String,
      default: '4'
    },
    titleMarginLevel: {
      type: String,
      default: '4'
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      states: [],
      maxLengths: inputMaxLengths.shippingAddress
    }
  },
  computed: {
    hasStates() {
      return this.states.length > 0
    },
    titleProps() {
      return {
        level: this.titleLevel,
        marginLevel: this.titleMarginLevel
      }
    }
  },
  watch: {
    model() {
      this.$emit('input', this.model)
    }
  },
  mounted() {
    this.states = this.getStates()
  },
  methods: {
    i18n,
    async onBlur(zipCode) {
      this.model.zipCode = zipCode.value.toUpperCase()
    },
    isAlphaNumericOrSpace(value) {
      // Space because GB has it, eg "M34 4AB"
      if (/^[A-Za-z0-9 ]+$/.test(value)) return value
      return value.slice(0, -1)
    },
    getStates() {
      if (!I18nConfig.hasRegions) {
        this.model.state = I18nConfig.defaultRegion
        return []
      }
      const regions = I18nConfig.regions
        .map(item => ({
          value: item.shortCode,
          text: item.localizedName?.[location.languageCode] || item.name
        }))
      regions.unshift({ value: '', text: i18n('CREATE_CASE__SHIPPING_LABEL_STATE') })
      return regions
    },
    getRules() {
      return this.required ? 'required' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

.state-input-label {
  color: $black;
  display: block;
  font-size: .75rem;
  font-weight: $font-weight-bold;
  margin-bottom: 0.25rem;
  margin-top: .1rem;
}

</style>
