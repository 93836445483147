import { LOADING_STATES } from '_utils_/constants'
import { uploadFile } from '_api_/caseUploadAdditionalFiles.api'
import { getFiles } from '_api_/caseUploadAdditionalFiles.api'
import { addAdditionalFile } from '_api_/caseUploadAdditionalFiles.api'
import { CaseDetailsException } from '_utils_/Exceptions'

export const mutationTypes = {
  selectNewFile: 'selectNewFile',
  setFiles: 'setFiles',
  setLoadingStatus: 'setLoadingStatus',
  setAddingFilesStatus: 'setAddingFilesStatus',
  clearFiles: 'clearFiles'
}

export const errorTypes = {
  getAssetUrl: 'getAssetUrl'
}

const state = {
  files: [],
  selectedFile: {},
  uploadFileLoadingStatus: LOADING_STATES.idle,
  isAddingFiles: false
}

const mutations = {
  [mutationTypes.selectNewFile](state, file) {
    state.selectedFile = file
  },
  [mutationTypes.setFiles](state, files) {
    state.files = files
  },
  [mutationTypes.setLoadingStatus](state, { type, status }) {
    state[`${type}LoadingStatus`] = status
  },
  [mutationTypes.clearFiles](state) {
    state.files = []
    state.selectedFile = {}
  },
  [mutationTypes.setAddingFilesStatus](state, isAddingFiles) {
    state.isAddingFiles = isAddingFiles
  }
}

const actions = {
  async uploadFile({ commit, state }, { caseId, filename, kind }) {
    commit(mutationTypes.setLoadingStatus, {
      type: 'uploadFile',
      status: LOADING_STATES.pending
    })
    try {
      await uploadFile({ caseId, filename, kind }, state.selectedFile)
      commit(mutationTypes.clearFiles)
      commit(mutationTypes.setLoadingStatus, {
        type: 'uploadFile',
        status: LOADING_STATES.resolved
      })
    } catch (error) {
      commit(mutationTypes.setLoadingStatus, {
        type: 'uploadFile',
        status: LOADING_STATES.rejected
      })
      throw new CaseDetailsException(error)
    }
  },
  async addAdditionalFile({ commit }, { file, caseId }) {
    try {
      await addAdditionalFile(file, caseId);
    } catch (error) {
      throw new CaseDetailsException(error)
    }
  },
  async getFiles({ commit, dispatch }, payload) {
    dispatch('AppProcess/setLoadingStatus', true, { root: true })
    commit(mutationTypes.clearFiles)
    try {
      const files = await getFiles(payload.id)
      commit(mutationTypes.setFiles, files)
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    } catch (error) {
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
      throw new CaseDetailsException(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
