<template>
  <smile-button
    ref="sdcButton"
    name="sdc-button"
    :button-styles="btnType"
    :button-size="btnSize"
    :expanded="expanded"
    :outlined="outlined"
    :inverted="inverted"
    @click="onClick"
  >
    <slot />
  </smile-button>
</template>

<script>
import { SmileButton } from 'sdc-component-library'
import TYPES, { SIZES } from './constants'
export default {
  name: 'SdcButton',
  components: {
    SmileButton
  },
  props: {
    type: {
      type: String,
      default: TYPES.primary
    },
    size: {
      type: String,
      default: SIZES.default
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      btnType: TYPES[this.type],
      btnSize: SIZES[this.size]
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.$refs.sdcButton);
    }
  }
}
</script>
