import axios from 'axios'
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'
import { ScanIntakeException } from '_utils_/Exceptions'

const getScanFileSignedUrl = async (fileData) => {
  try {
    const response = await api.put('scan/upload-url', fileData)
    return response.data
  } catch (error) {
    Logger.error('ScanIntake API - getScanFileSignedUrl', error)
    throw error
  }
}

const getFilenameByType = (assetUrl) => {
  const fileNameRegex = /(?:\/)[\w.]*(?=\?)/
  return (assetUrl.match(fileNameRegex)?.[0] || '').replace('/', '')
}

const scanHistoryMapper = (scanHistory = []) => (
  scanHistory.map(item => {
    return {
      ...item,
      fileName: (getFilenameByType(item.assetUrl)),
    }
  })
)

export const uploadFiles = async (
  { caseNumber, firstName, lastName },
  { upper, lower }
) => {
  try {
    const response = await api.post('scan', {
      caseNumber,
      firstName,
      lastName,
      upperFile: `${caseNumber}_${upper.name}`,
      lowerFile: `${caseNumber}_${lower.name}`
    })
    return response.id
  } catch (error) {
    Logger.error('ScanIntake API - uploadFiles', error)
    throw error
  }
}

export const addScanFile = async ({ file, category: type, caseNumber }) => {
  const { size, name: fileName } = file
  try {
    const signedUrl = await getScanFileSignedUrl({
      caseNumber,
      type,
      size,
      fileName
    })
    await axios.put(signedUrl, file)
    return file
  } catch (error) {
    Logger.error('ScanIntake API - addScanFile', error)
    throw error
  }
}

export const getScanHistory = async (caseNumber) => {
  try {
    const response = await api.get(`case/${caseNumber}/scan`)
    const scanHistory = scanHistoryMapper(response.data)
    return scanHistory
  } catch (error) {
    throw new ScanIntakeException(error)
  }
}

export const getAssetUrl = async (scanType, request) => {
  try {
    const response = await api.post(`asset/${scanType}`, request)
    return response.data
  } catch (error) {
    Logger.error('ScanIntake API - getAssetUrl', error)
    throw error
  }
}
