import { createAlignerOrder, skipAlignerOrder } from '@/api/cases.api'
import {
  applyDiscount,
  createDraftOrder,
  createOrder,
  removeDiscount,
  updateCart,
  updateCustomer,
  updateBillingAddress
} from '@/api/commerce.api'
import {
  createFinancingApplication,
  eSignApplication,
  updateApplication
} from '@/api/finance.api'
import { getPaymentValues } from '@/api/utils.api'
import { CARE_LEVEL } from '@/utils/constants'
import Location from '@/utils/Location'
import I18nConfig from '_utils_/I18nConfig'
import Logger from '@/utils/Logger'

export const mutationTypes = {
  clearStore: 'clearStore',
  setApplicationId: 'setApplicationId',
  setBillingAddress: 'setBillingAddress',
  setDiscountAmount: 'setDiscountAmount',
  setCareLevel: 'setCareLevel',
  setCartId: 'setCartId',
  setCommerceCustomerId: 'setCommerceCustomerId',
  setDiscountCode: 'setDiscountCode',
  setFinancingChanged: 'setFinancingChanged',
  setFinancingOptions: 'setFinancingOptions',
  setIsAffiliate: 'setIsAffiliate',
  setIsLoadingFinancingOptions: 'setIsLoadingFinancingOptions',
  setPatientConsent: 'setPatientConsent',
  setPaymentBrokerAccountId: 'setPaymentBrokerAccountId',
  setPaymentMethodId: 'setPaymentMethodId',
  setSmilePayPlan: 'setSmilePayPlan',
  setOrderNumber: 'setOrderNumber',
  setOrderId: 'setOrderId',
  setCreateCaseId: 'setCreateCaseId'
}

const DEFAULT_BILLING_ADDRESS = {
  sameAsShipping: true,
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  zipCode: ''
}
const NOTPURCHASINGYETPLAN = 'NOTPURCHASINGYET'
export const NOTPURCHASINGYET = { paymentPlan: NOTPURCHASINGYETPLAN }
export const SINGLEPAY = 'SINGLEPAY'
export const SMILEPAY = 'SMILEPAY'
const PLANS_THAT_REQUIRE_PAYMENT = { SINGLEPAY, SMILEPAY }
const DEFAULT_PAY_OPTION = { price: null, sku: null }
const API_FINANCING_OPTIONS = {
  [SINGLEPAY]: 'SinglePay',
  [SMILEPAY]: 'SmilePay'
}

export const state = {
  applicationIds: {},
  billingAddress: { ...DEFAULT_BILLING_ADDRESS },
  careLevel: CARE_LEVEL.standard,
  cartId: null,
  commerceCustomerId: null,
  createCaseId: null,
  discountAmount: 0,
  discountCode: null,
  financePrice: null,
  financingChanged: false,
  financingProductSKU: null,
  isAffiliate: false,
  isLoadingFinancingOptions: false,
  orderNumber: null,
  patientConsent: false,
  paymentBrokerAccountId: null,
  paymentMethodId: null,
  paymentRequired: false,
  price: null,
  productSKU: null,
  smilePayPlan: NOTPURCHASINGYET
}

export const getters = {
  applicationId: (state) => {
    if (!state.applicationIds) {
      return null
    }

    return state.applicationIds[state.careLevel]
  },
  financingSelected: (state) =>{
    return JSON.stringify(state.smilePayPlan) !== JSON.stringify(NOTPURCHASINGYET)
  },
  isDiscountCodeSet: (state) => {
    return state.discountCode || state.isAffiliate
  },
  isPaymentFeatureEnabled: (_state, getters, rootState, rootGetters) => {
    if (
      !I18nConfig.isNewCheckoutEnabled ||
      rootGetters['CreateCase/isMidcourseCorrectionOrRefinement']
    ) {
      return false
    }

    return !!(
      getters.isCarePlusEnabled ||
      rootState.Features.useNewCheckoutForAllPractices
    )
  },
  isCarePlusEnabled: (_state, _getters, _rootState, rootGetters) => {
    if (!rootGetters['Auth/isSuperuser'] ) {
      return !!(
        !!rootGetters['Auth/hasPractices'] &&
        !!rootGetters['Practices/getLocalPractices']?.length &&
        !!rootGetters['Practices/getLocalPractices'][0]?.carePlusEnabled
      )
    }
    
    let practiceId = rootGetters['CreateCase/practiceId']
    return !!(
      rootGetters['Practices/getLocalPractices']?.filter(practice => practice.id === practiceId)[0]?.carePlusEnabled
    )
  }
}

// TODO: improve clear store with an object already initialied for state
export const mutations = {
  [mutationTypes.clearStore](state) {
    state.applicationIds = {}
    state.billingAddress = { ...DEFAULT_BILLING_ADDRESS }
    state.careLevel = CARE_LEVEL.standard
    state.cartId = null
    state.commerceCustomerId = null
    state.createCaseId = null
    state.discountAmount = 0
    state.discountCode = null
    state.financePrice = null
    state.financingProductSKU = null
    state.isAffiliate = false
    state.isLoadingFinancingOptions = false
    state.paymentBrokerAccountId = null
    state.paymentMethodId = null
    state.paymentRequired = false
    state.price = null
    state.productSKU = null
    state.smilePayPlan = NOTPURCHASINGYET
    state.orderNumber = null
  },
  [mutationTypes.setApplicationId](state, applicationId) {
    state.applicationIds = {
      ...state.applicationIds,
      [state.careLevel]: applicationId
    }
  },
  [mutationTypes.setBillingAddress](state, payload) {
    state.billingAddress = payload
  },
  [mutationTypes.setDiscountAmount](state, payload) {
    state.discountAmount = payload
  },
  [mutationTypes.setCartId](state, payload) {
    state.cartId = payload
  },
  [mutationTypes.setCareLevel](state, payload) {
    state.careLevel = payload
  },
  [mutationTypes.setCommerceCustomerId](state, payload) {
    state.commerceCustomerId = payload
  },
  [mutationTypes.setDiscountCode](state, discountCode) {
    state.discountCode = discountCode
  },
  [mutationTypes.setIsAffiliate](state, payload) {
    state.isAffiliate = payload
  },
  [mutationTypes.setIsLoadingFinancingOptions](state, payload) {
    state.isLoadingFinancingOptions = payload
  },
  [mutationTypes.setFinancingChanged](state, payload) {
    state.financingChanged = payload
  },
  [mutationTypes.setFinancingOptions](state, payload) {
    const smilePay = payload?.smilePay || [DEFAULT_PAY_OPTION]
    const singlePay = payload?.singlePay || [DEFAULT_PAY_OPTION]

    state.financePrice = smilePay[0].price
    state.financingProductSKU = smilePay[0].sku
    state.price = singlePay[0].price
    state.productSKU = singlePay[0].sku
  },
  [mutationTypes.setPatientConsent](state, payload) {
    state.patientConsent = payload
  },
  [mutationTypes.setPaymentBrokerAccountId](state, payload) {
    state.paymentBrokerAccountId = payload
  },
  [mutationTypes.setSmilePayPlan](state, payload) {
    state.smilePayPlan = payload
    state.paymentRequired = !!PLANS_THAT_REQUIRE_PAYMENT[payload?.paymentPlan]
    if (!payload || payload.paymentPlan === NOTPURCHASINGYETPLAN) {
      state.paymentMethodId = null
    }
  },
  [mutationTypes.setPaymentMethodId](state, payload) {
    state.paymentMethodId = payload
  },
  [mutationTypes.setOrderNumber](state, payload) {
    state.orderNumber = payload
  },
  [mutationTypes.setOrderId](state, payload) {
    state.orderId = payload
  },
  [mutationTypes.setCreateCaseId](state, payload) {
    state.createCaseId = payload
  }
}

export const actions = {
  async applyDiscountCode(
    { commit, state: { cartId, smilePayPlan } },
    { discountCode, isAffiliate }
  ) {
    let request = {
      cartId,
      isAffiliate,
      discountCode
    }

    if (smilePayPlan.paymentPlan === SINGLEPAY) {
      request.totalAmount = smilePayPlan.amount
    }

    const result = await applyDiscount(request)
    commit(mutationTypes.setDiscountCode, discountCode)
    commit(mutationTypes.setIsAffiliate, isAffiliate)
    return result
  },
  clearPaymentStore({ commit }) {
    commit(mutationTypes.clearStore)
  },
  async createAlignerOrder({ state, getters }, caseId) {
    const body = {
      orderId: state.orderId,
      orderNumber: state.orderNumber,
      careLevelSelection: state.careLevel,
      discountCode: state.discountCode,
      billingAddress: state.billingAddress,
      financingOption: API_FINANCING_OPTIONS[state.smilePayPlan.paymentPlan],
      financeApplicationId: getters.applicationId
    }

    if (state.smilePayPlan.paymentPlan === SMILEPAY) {
      body.smilePayTermsAcceptance = state.patientConsent
    }

    await createAlignerOrder(caseId, body)
  },
  async updateApplication({getters: { applicationId }, state: { smilePayPlan }}, { amount, businessEntity, downPayment, months, patient }) {
    if (!applicationId || smilePayPlan.paymentPlan !== SMILEPAY) {
      return
    }

    await updateApplication(applicationId, {
      amount:(amount - downPayment) / 100,
      businessEntity, downPayment: downPayment / 100,
      months,
      patient
    })
  },
  async createApplication(
    {
      commit,
      state: {
        paymentBrokerAccountId,
        smilePayPlan: { creditProductId }
      }
    },
    app
  ) {
    const request = _createApplicationRequest(
      app,
      paymentBrokerAccountId,
      creditProductId
    )
    const applicationId = await createFinancingApplication(request)
    commit(mutationTypes.setApplicationId, applicationId)
  },
  async createDraftOrder({
    commit,
    getters: { applicationId },
    state: { cartId, billingAddress },
    rootState: {
      CreateCase: { shippingAddress }
    }
  }) {
    const orderNumber = await createDraftOrder({ id: cartId })
    commit(mutationTypes.setOrderNumber, orderNumber)

    if (!applicationId) {
      return
    }

    const { streetAddress1, streetAddress2, city, state, zipCode } =
      billingAddress.sameAsShipping ? shippingAddress : billingAddress

    const billingAddressFinance = {
      street_1: streetAddress1,
      street_2: streetAddress2,
      locality: city,
      region: state,
      postal_code: zipCode,
      country_code: Location.countryCode
    }
    await updateApplication(applicationId, orderNumber, billingAddressFinance)
  },
  async createOrder({ commit, state }) {
    const { orderNumber, id } = await createOrder({
      cartId: state.cartId,
      orderNumber: state.orderNumber,
      customerId: state.commerceCustomerId,
      financingType: API_FINANCING_OPTIONS[state.smilePayPlan.paymentPlan]
    })
    commit(mutationTypes.setOrderNumber, orderNumber)
    commit(mutationTypes.setOrderId, id)
  },
  async eSignApplication({ state: { careLevel, applicationIds } }) {
    await eSignApplication(applicationIds[careLevel])
  },
  async loadFinancingOptions({ commit }, careLevel) {
    try {
      commit(mutationTypes.setIsLoadingFinancingOptions, true)
      const financingOptions = await getPaymentValues(
        Location.countryCode,
        careLevel
      )
      commit(mutationTypes.setFinancingOptions, financingOptions)
      commit(mutationTypes.setCareLevel, careLevel)
    } finally {
      commit(mutationTypes.setIsLoadingFinancingOptions, false)
    }
  },
  async removeDiscountCode({ commit, state: { cartId } }) {
    await removeDiscount({ cartId })
    commit(mutationTypes.setDiscountCode, null)
    commit(mutationTypes.setDiscountAmount, 0)
  },
  setPaymentMethodId({ commit }, id) {
    commit(mutationTypes.setPaymentMethodId, id)
  },
  setSmilePayPlan({ commit }, plan) {
    commit(mutationTypes.setFinancingChanged, true)
    commit(mutationTypes.setSmilePayPlan, plan)
  },
  async skipPayment({ state, getters }, caseId) {
    await skipAlignerOrder(caseId, {
      customerId: state.commerceCustomerId,
      cartId: state.cartId,
      careLevelSelection: state.careLevel,
      financeApplicationId: getters.applicationId,
      isAffiliate: state.isAffiliate
    })
  },
  async updateCommerceCart(_, cart) {
    await updateCart(cart)
  },
  async updateCustomer({
    state: { commerceCustomerId: customerId, productSKU },
    commit,
    rootState: {
      CreateCase: {
        fastTrackInfo: { alignerPreference },
        shippingAddress: {
          city,
          state,
          streetAddress1: streetLine1,
          streetAddress2: streetLine2,
          zipCode: postalCode
        }
      }
    },
    rootGetters
  }) {
    const { patient } = rootGetters['CreateCase/smilePayApplicationPayload']
    const {
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber: phone
    } = patient.guardian || patient

    const customer = {
      customerId,
      email,
      firstName,
      lastName,
      country: Location.countryCode,
      currency: I18nConfig.currencyCode,
      alignerPreference,
      productSKU,
      shippingAddress: {
        countryCode: Location.countryCode,
        city,
        state,
        streetLine1,
        streetLine2,
        postalCode,
        firstName,
        lastName,
        phone
      }
    }
    const { cartId } = await updateCustomer(customer)
    commit(mutationTypes.setCartId, cartId)
  },
  async submitPaymentBillingAddress({ dispatch, state, rootState }) {
    dispatch('AppProcess/setLoadingStatus', true, { root: true })
    let billingAddress = { ...state.billingAddress }
    const customerId = state.commerceCustomerId
    try {
      const { shippingAddress, personalInfo, guardianInfo } =
        rootState?.CreateCase
      const patient = !!guardianInfo.firstName.length
        ? guardianInfo
        : personalInfo
      const { phoneNumber, firstName, lastName } = patient

      if (billingAddress.sameAsShipping) {
        billingAddress = {
          ...shippingAddress
        }
      }

      const {
        city,
        state: cityState,
        streetAddress1,
        streetAddress2,
        zipCode
      } = billingAddress

      await updateBillingAddress({
        customerId,
        shippingAddress: {
          countryCode: Location.countryCode,
          streetLine1: shippingAddress.streetAddress1,
          streetLine2: shippingAddress.streetAddress2,
          city: shippingAddress.city,
          state: shippingAddress.state,
          postalCode: shippingAddress.zipCode,
          phone: phoneNumber,
          firstName,
          lastName
        },
        billingAddress: {
          countryCode: Location.countryCode,
          streetLine1: streetAddress1,
          streetLine2: streetAddress2,
          city,
          state: cityState,
          postalCode: zipCode,
          phone: phoneNumber,
          firstName,
          lastName
        }
      })
    } catch (error) {
      Logger.error('Payment Store - submitPaymentBillingAddress', error)
      throw error
    } finally {
      dispatch('AppProcess/setLoadingStatus', true, { root: true })
    }
  }
}

/**
 *
 * @param {{
 *  amount: Number,
 *  businessEntity: String,
 *  downPayment: Number,
 *  months: Number,
 *  patient: {
 *    firstName: String,
 *    lastName: String,
 *    emailAddress: String,
 *    phoneNumber: String,
 *    dateOfBirth: String,
 *    guardian: {
 *      firstName: String,
 *      lastName: String,
 *      emailAddress: String,
 *      phoneNumber: String,
 *      dateOfBirth: String,
 *    }
 *  }}} application
 * @param {String} customer_id
 * @param {String} product_id
 * @returns
 */
function _createApplicationRequest(
  { amount, businessEntity, downPayment, months, patient },
  customer_id,
  product_id
) {
  const {
    firstName: patient_first_name,
    lastName: patient_last_name
  } = patient

  const {
    firstName: first_name,
    lastName: last_name,
    emailAddress: email,
    phoneNumber: phone,
    dateOfBirth: date_of_birth
  } = patient.guardian || patient

  const {
    shippingAddress: {
      streetAddress1: street_1,
      streetAddress2: street_2,
      city: locality,
      state: region,
      zipCode: postal_code
    }
  } = patient

  return {
    first_name,
    last_name,
    patient_first_name,
    patient_last_name,
    email,
    shipping_address: {
      street_1,
      street_2,
      locality,
      region,
      postal_code,
      country_code: Location.countryCode
    },
    phone: phone.replace('+', ''),
    customer_id,
    amount: (amount - downPayment) / 100,
    months,
    down_payment: downPayment / 100,
    business_entity: businessEntity,
    product_id,
    date_of_birth
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
  keysToPersist: [
    'cartId',
    'commerceCustomerId',
    'createCaseId',
    'paymentBrokerAccountId'
  ]
}
