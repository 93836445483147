<template>
  <sdc-badge class="appointments-status-label">
    {{ i18n(`APPOINTMENTS_STATUS__${statusId}`) }}
  </sdc-badge>
</template>

<script>
import SdcBadge from '_atoms_/SdcBadge'
import { i18n } from '_utils_/i18n'

export default {
  name: 'AppointmentsStatusLabel',
  components: {
    SdcBadge
  },
  props: {
    statusId: {
      type: String,
      required: true
    }
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.appointments-status-label {
  font-size: $font-size-sm;
}
</style>
