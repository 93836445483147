<template>
  <div
    :id="componentId"
    :name="componentId"
    class="dental-service-organization-item"
  >
    <div class="dental-service-organization-item__name">
      {{ dsoName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DentalServiceOrganizationItem',
  props: {
    id: { type: String, default: '' },
    dsoName: { type: String, default: '' }
  },
  computed: {
    componentId(){
      return `dental-service-organization-item-${this.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop: map-get($sdc-breakpoints, 'desktop');

.dental-service-organization-item{
  border: 2px dashed $gray-88;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  min-height: 70px;
  word-wrap: break-word;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    min-height: auto;
  }

  &__name {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
  }
}
</style>
