<template>
  <div
    id="office-team"
    class="office-team"
  >
    <section class="section-panel-rounded">
      <div class="office-team__header">
        <sdc-title level="3">
          {{ i18n('OFFICE_TEAM__TITLE') }}
        </sdc-title>
        <sdc-button
          v-if="canAddTeamMember"
          type="primary"
          outlined
          class="office-team__header__add-button"
          @click="showModal"
        >
          <smile-icon icon="plus" />
          <span>{{ i18n('OFFICE_TEAM__ADD_BUTTON') }}</span>
        </sdc-button>
      </div>
      <div
        v-if="isSuperuser"
        class="office-team__search_section"
      >
        <sdc-search-box
          :placeholder="i18n('OFFICE_TEAM__SEARCH_MEMBER')"
          :max-length-on-overlap="24"
          :disabled="isLoading"
          @search="onSearch"
          @clearSearch="onClearSearch"
        />
      </div>
      <div
        v-if="usersNotFound"
        class="office-team__not-found"
      >
        {{ i18n('TEXT__NO_RESULTS_FOUND') }}
      </div>
      <div v-if="isLoading">
        <sdc-loading type="element" />
      </div>
      <template v-else>
        <div class="office-team__list">
          <div
            v-for="user in usersPaginated"
            :key="user.userName"
            class="office-team__list__item"
          >
            <office-team-item v-bind="user" />
          </div>
        </div>
        <div class="office-team__pagination-container">
          <div>
            <paginator
              :total-items="totalUsers"
              :items-per-page="pageSize"
              :current-page="currentPage"
              @pageChanged="onPageChanged"
            />
          </div>
        </div>
      </template>
      <team-member-modal ref="teamMemberModal" />
    </section>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import SdcLoading from '_atoms_/SdcLoading'
import OfficeTeamItem from '_molecules_/OfficeTeamItem'
import TeamMemberModal from '_molecules_/TeamMemberModal'
import Paginator from '@/components/molecules/Paginator'
import SdcSearchBox from '_atoms_/SdcSearchBox'
import { mapActions, mapState, mapGetters } from 'vuex'

function filterTeamMemberUsers(users, localQueryFilter) {
  return users.filter(function(e) {
    let namefound,
      userNameFound = false,
      practiceNameFound = false
    if (e.practices[0]) {
      practiceNameFound = e.practices[0].name.toLowerCase().includes(localQueryFilter)
    }
    if (e.fullName) {
      namefound = e.fullName.toLowerCase().includes(localQueryFilter)
    }
    if (e.userName) {
      userNameFound = e.userName.toLowerCase().includes(localQueryFilter)
    }
    return namefound || userNameFound || practiceNameFound
  })
}

export default {
  name: 'OfficeTeam',
  components: {
    SdcTitle,
    SdcButton,
    SdcLoading,
    OfficeTeamItem,
    TeamMemberModal,
    Paginator,
    SdcSearchBox
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      queryFilter: ''
    }
  },
  computed: {
    ...mapGetters('Auth', ['canAddTeamMember', 'isSuperuser']),
    ...mapState('Users', ['users', 'isLoading']),
    usersPaginated() {
      return this.usersFiltered
        .filter((_, i) => i >= this.pageSize * (this.currentPage - 1)) //Skip n
        .filter((_, i) => i < this.pageSize) //Take n
    },
    usersFiltered() {
      var localQueryFilter = this.queryFilter
      return localQueryFilter
        ? filterTeamMemberUsers(this.users, localQueryFilter)
        : this.users
    },
    totalUsers() {
      return this.usersFiltered.length || 0
    },
    usersNotFound() {
      return this.totalUsers === 0 && this.queryFilter
    }
  },
  async mounted() {
    await this.requestUsers()
    await this.requestRoles()
  },
  methods: {
    i18n,
    ...mapActions('Users', ['requestUsers']),
    ...mapActions('Roles', ['requestRoles']),
    showModal() {
      this.$refs.teamMemberModal.open()
    },
    onPageChanged(page) {
      this.currentPage = page
    },
    onSearch(queryFilter) {
      if (
        typeof queryFilter !== 'undefined' &&
        queryFilter !== this.queryFilter
      ) {
        this.queryFilter = queryFilter.toLowerCase()
        this.currentPage = 1
      }
    },
    onClearSearch() {
      this.queryFilter = ''
      this.currentPage = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$mobile: map-get($sdc-breakpoints, 'mobile');

.office-team {
  display: flex;
  justify-content: center;

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &__add-button {
      height: 42px;
      margin-top: 8px;
      white-space: nowrap;
      border-color: $gray-88;
      padding: 0px 20px 0px 20px;
      font-weight: $font-weight-bolder;

      @media screen and (max-width: $mobile) {
        margin-bottom: 12px;
        height: auto;
        min-height: 42px;
      }
    }
  }

  &__search_section {
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 100%;

      @media screen and (min-width: $tablet) {
        width: 49%;
      }
    }
  }

  &__not-found {
    text-align: center;
    padding: 0rem 0.75rem 0.8rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 12px;
  }

  &__paginator-container {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
