<template>
  <div
    id="fast-track-billing-address"
    class="fast-track-billing-address"
  >
    <sdc-title level="4">
      {{ i18n('BILLING_ADDRESS__TITLE') }}
    </sdc-title>
    <sdc-checkbox
      v-model="model.sameAsShipping"
      name="show-billing-address"
    >
      {{ i18n('BILLING_ADDRESS__CHECKBOX_LABEL') }}
    </sdc-checkbox>
    <shipping-address
      v-if="!model.sameAsShipping"
      :required="!usePracticeAddressAsShippingAddress"
      :model="model"
      :title="i18n('BILLING_ADDRESS__TITLE')"
      @input="onShippingAddressChange"
    />
  </div>
</template>

<script>
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcTitle from '@/components/atoms/SdcTitle'
import ShippingAddress from '_molecules_/ShippingAddress'
import { i18n } from '_utils_/i18n'
import { mapState } from 'vuex'

export default {
  name: 'FastTrackBillingAddress',
  components: {
    ShippingAddress,
    SdcCheckbox,
    SdcTitle
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('InformationForm', [
    'usePracticeAddressAsShippingAddress'
    ])
  },
  created() {
    this.setBillingAddressCheckboxDefault()
  },
  methods: {
    i18n,
    onShippingAddressChange(shippingAddress) {
      this.$emit('input', shippingAddress)
    },
    setBillingAddressCheckboxDefault() {
      this.model.sameAsShipping = this.model.sameAsShipping ?? !this.usePracticeAddressAsShippingAddress
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.fast-track-billing-address {
  margin-top: 40px;
}
</style>
