import Location from '_utils_/Location'
import I18nConfig from '_utils_/I18nConfig'

/**
 * Given a value formats a number into a string according
 * to the locale and formatting options.
 * @param {number} value
 * @param {number} fractionDigits
 * @returns {string}
 */
const formatCurrency = (value) => {
  const currencyFormat = I18nConfig.customFormat
  const fractionDigits = value % 1 !== 0 ? 2 : 0
  const floatValue = parseFloat(value).toFixed(fractionDigits)

  return (currencyFormat) ?
    Intl.NumberFormat(Location.locale, currencyFormat).format(floatValue) :
    floatValue
}

const formatPaymentString = (value, formatString) => {
  const fractionDigits = value % 1 !== 0 ? 2 : 0
  const floatValue = parseFloat(value).toFixed(fractionDigits)
  let formattedValue = Intl.NumberFormat(Location.locale).format(floatValue)
  return formatString.format(formattedValue)
}

export {
  formatCurrency,
  formatPaymentString
}
