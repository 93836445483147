<template>
  <div
    :id="id"
    :name="id"
    class="question-medical-questions"
  >
    <sdc-title
      level="4"
      margin-level="3"
    >
      {{ i18n(`QUESTION_MEDICAL_QUESTIONS__${data.title.toUpperCase()}`, [
        formattedPatientName
      ]) }}
    </sdc-title>
    <div
      v-if="data.subtitle"
      class="question-medical-questions__subtitle"
    >
      {{ i18n(`QUESTION_MEDICAL_QUESTIONS__${data.title.toUpperCase()}__SUBTITLE`) }}
    </div>
    <div
      v-for="(question, key) in model"
      :key="`question-${key}`"
      class="question-medical-questions__item"
    >
      <sdc-checkbox
        v-model="question.value"
        :name="`${id}-checkbox-${key}`"
      >
        {{ i18n(`QUESTION_MEDICAL_QUESTIONS__${question.name.toUpperCase()}`) }}
      </sdc-checkbox>
      <div
        v-if="question.value &&
          question.hasDetails &&
          !question.subquestions"
        class="question-medical-questions__subquestion"
      >
        <sdc-textarea
          v-model="question.detailValue"
          rules="required"
          :classes="['sdc-textarea-questions']"
          :name="`${question.name}-details`"
          :placeholder="getQuestionPlaceholder(question)"
        />
      </div>
      <div
        v-else-if="question.value &&
          question.hasDetails &&
          question.subquestions"
        class="question-medical-questions__subquestion"
      >
        <div
          v-if="question.subtitle"
          class="question-medical-questions__subquestion--subtitle"
        >
          {{ i18n(`QUESTION_MEDICAL_QUESTIONS__${question.name.toUpperCase()}__SUBTITLE`) }}
        </div>
        <template v-for="(subquestion, subkey) in question.subquestions">
          <div
            v-if="subquestionTypes.includes(subquestion.type) &&
              hideSubQuestion(question.name, subquestion.type, subquestion.name)"
            :key="`qmq-${subquestion.type.toLowerCase()}-${key}-${subkey}`"
            :class="`question-medical-questions__${subquestion.type.toLowerCase()}`"
          >
            <div :class="`question-medical-questions__${subquestion.type.toLowerCase()}--label`">
              {{ i18n(`QUESTION_MEDICAL_QUESTIONS__${subquestion.name.toUpperCase()}`) }}
            </div>
            <sdc-radio-button
              v-if="subquestion.type === 'Radiogroup'"
              v-model="subquestion.value"
              :name="subquestion.name"
              :options="getOptions(subquestion.possibleValues)"
              type="button"
              :rules="getRules(subquestion.name)"
            />
            <sdc-checkbox-group
              v-else-if="subquestion.type === 'Checkgroup'"
              v-model="subquestion.value"
              :name="subquestion.name"
              :options="getOptions(subquestion.possibleValues)"
              :rules="getRules(subquestion.name)"
            />
            <question-teeth-images
              v-else
              v-model="subquestion.value"
              :name="`question-tooth-${subquestion.name}-${key}-${subkey}`"
              :rules="getRules(subquestion.type)"
              :validation-field="`${id}-checkbox-${key}`"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTextarea from '_atoms_/SdcTextarea'
import SdcTitle from '_atoms_/SdcTitle'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import SdcRadioButton from '_atoms_/SdcRadioButton'
import SdcCheckboxGroup from '_atoms_/SdcCheckboxGroup'
import QuestionTeethImages from '_molecules_/QuestionTeethImages'

const TEXT_DEFAULT_PLACEHOLDERS = [
  'HAS_BRIDGEWORK',
  'HAS_IMPLANT',
  'HAS_PRIMARY_TEETH',
  'FEELS_PAIN',
  'HAS_SORES',
  'HAS_SERIOUS_ISSUES',
  'HAS_HEAD_INJURY',
  'HAS_JAW_CLICKING',
  'HAS_LOOSENING',
  'HAS_ALLERGIES',
  'HAS_CARIES'
]

export default {
  name: 'QuestionMedicalQuestions',
  components: {
    SdcCheckbox,
    SdcCheckboxGroup,
    SdcRadioButton,
    SdcTextarea,
    SdcTitle,
    QuestionTeethImages
  },
  props: {
    id: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    patientName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      subquestionTypes: ['Radiogroup', 'Checkgroup', 'ToothGroup']
    }
  },
  computed: {
    formattedPatientName() {
      return this.patientName.toLowerCase()
        .replace(/\b\w/g, c => c.toUpperCase())
    },
    model() {
      return this.data.questions.filter(
        (question) => question.isVisible
      )
    }
  },
  methods: {
    i18n,
    getQuestionPlaceholder({ name }) {
      const formattedName = name.toUpperCase()

      return TEXT_DEFAULT_PLACEHOLDERS.includes(formattedName) ?
        i18n('QUESTION_MEDICAL_QUESTIONS__TEXTAREA_PLACEHOLDER') :
        i18n(`QUESTION_MEDICAL_QUESTIONS__${formattedName}__TEXTAREA_PLACEHOLDER`)
    },
    getOptions(options) {
      return options.map((option) => {
        return {
          text: this.i18n(`QUESTION_MEDICAL_SUBQUESTION__${option.replace(/ /g,'_').toUpperCase()}`),
          value: option
        }
      })
    },
    getRules(field) {
      const rules = {
        'has_bonded_retainer_on': 'requiredRetainer',
        'has_bonded_retainer_lower_removal': 'requiredRetainer',
        'has_bonded_retainer_upper_removal': 'requiredRetainer',
        'ToothGroup': 'requiredTeeth'
      }

      return rules[field]
    },
    hideSubQuestion(questionName, subquestionType, subQuestionName){
      if(questionName === 'has_bonded_retainer' && subquestionType === 'Radiogroup'){
        return this.hideBondedRetainerSubQuestion(questionName, subQuestionName)
      }
      return true
    },
    hideBondedRetainerSubQuestion(questionName, subQuestionName){
      const bondedRetainerQuestionIndex = 0
      const hasBondedRetainerOnSubQuestionIndex = 0
      const mapSubquestionWithArchBondedRetainer = new Map([
              [`${questionName}_lower_removal`, 'Lower arch'],
              [`${questionName}_upper_removal`, 'Upper arch']])
      const selectedArchBondedRetainer = mapSubquestionWithArchBondedRetainer.get(subQuestionName)
      const listOfArchesBondedRetainer = this.data.questions[bondedRetainerQuestionIndex].
                                          subquestions[hasBondedRetainerOnSubQuestionIndex].value
      return listOfArchesBondedRetainer.length > 0 &&
             listOfArchesBondedRetainer.includes(selectedArchBondedRetainer)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$desktop: map-get($sdc-breakpoints, 'desktop');

.sdc-textarea-questions {
  @media screen and (min-width: $desktop) {
    max-width: 50% !important;
  }
}

.question-medical-questions {
  margin-bottom: 40px;

  &__subtitle,
  &__checkbox,
  &__subquestion {
    color: $gray-600;
  }

  &__subtitle {
    font-size: $font-size-md;
    line-height: 27px;
    margin-bottom: 20px;
  }

  &__subquestion {
    margin-left: 33px;

    &--subtitle {
      margin-bottom: 12px;
    }
  }
  &__item {
    margin-bottom: 20px;
  }

  &__checkgroup {
    &--label {
      margin-bottom: 10px;
    }
  }

  &__radiogroup {
    &--label {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

</style>
