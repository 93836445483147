import { sdcInstance as api } from './sdc.config.api'
import { HttpException } from '_utils_/Exceptions'


/**
 * @param {Object} params
 * @prop {Uuid} caseId
 * @prop {string} impressionMode
 * @prop {string} orderType
 * @returns {Uuid} orderId
 */
const createRetakeOrder = (params) => {
  try {
    const requestBody = {
      type: params.orderType,
      impressionMode: params.impressionMode,
      source: 'office-direct'
    }

    const result = api.post(`case/${params.caseId}/order`, requestBody)

    return result
  } catch (e) {
    throw new HttpException(e)
  }
}


const editImpressionMode = ({ newImpressionMode, caseId }) => {
  try {
    const requestBody = {
      NewImpressionMode: newImpressionMode,
      source: 'office-direct'
    }

    const result = api.put(`case/${caseId}/order`, requestBody)
    return result
  } catch (e) {
    throw new HttpException(e)
  }
}


export {
  createRetakeOrder,
  editImpressionMode
}