<template>
  <section class="view-margin">
    <creation-container
      v-if="loaded"
      v-show="showCreationContainer"
      @createCase="onCaseCreated"
    />
    <Payment
      v-if="showPayment"
      :case-id="createCaseId"
    />
    <cancel-modal ref="cancelModal">
      {{ i18n('CREATE_CASE__MODAL_TEXT') }}
    </cancel-modal>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'
import CreationContainer from '_templates_/CreationContainer'
import CancelModal from '_molecules_/CancelModal'
import Payment from '@/components/organisms/Payment'

export default {
  name: 'CreateCase',
  components: {
    CreationContainer,
    CancelModal,
    Payment
  },
  beforeRouteLeave({ params }, _, next) {
    if (params.safeToLeaveRoute === true) {
      return next()
    }

    this.$refs.cancelModal.open()
    this.$refs.cancelModal.$on('ok', () => {
      this.clearState()
      this.clearBoId()
      this.clearPracticeId()
      this.clearPatientPhotos()
      this.clearInfo()
      this.clearPaymentStore()
      next()
    })
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('Payment', ['isPaymentFeatureEnabled']),
    ...mapState('Locale', ['currentLocale']),
    ...mapState('CreateCase', ['fastTrackInfo']),
    ...mapState('Payment', ['createCaseId']),
    showCreationContainer() {
      return !this.createCaseId
    },
    showPayment() {
      return !!this.createCaseId
    }
  },
  async beforeMount() {
    await this.requestPracticesByCountryCode(this.currentLocale.substring(3,5))
    this.loaded = true;

    if (this.$route.params.redirectFromCaseFlow) {
      this.clearState()
      this.clearPaymentStore()
    }
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['clearState', 'clearBoId', 'clearPracticeId']),
    ...mapMutations('CaseDetails', ['setOrderStatus']),
    ...mapMutations('InformationForm', ['clearInfo']),
    ...mapMutations('Photos', ['clearPatientPhotos']),
    ...mapMutations('Payment', ['setCreateCaseId']),
    ...mapActions('Practices', ['requestPracticesByCountryCode']),
    ...mapActions('Payment', ['clearPaymentStore']),
    ...mapActions('CreateCase', ['resetCreateCaseFlow']),
    onCaseCreated({ id }) {
      if (!this.isPaymentFeatureEnabled || this.fastTrackInfo.hasPurchased) {

        if(this.fastTrackInfo.hasPurchased){this.setOrderStatus('success')}

        this.resetCreateCaseFlow()

        this.$router.push({
          name: 'case-details',
          params: {
            id,
            safeToLeaveRoute: true
          }
        })

        return
      }

      this.setCreateCaseId(id)
    }
  }
}
</script>
