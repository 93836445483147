import { dateErrorTypeKeywords } from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import { i18n } from '_utils_/i18n'

const groupErrorMessages = new Map()

const load = () => {
  const supportPhoneNumber = I18nConfig.supportPhoneNumber

  groupErrorMessages.set(dateErrorTypeKeywords.date, i18n('ERROR__INVALID_DATE'))
  groupErrorMessages.set(dateErrorTypeKeywords.invalidBirthChange,
    i18n(
      supportPhoneNumber
        ? 'ERROR__INVALID_BIRTH_CHANGE'
        : 'ERROR__INVALID_BIRTH_CHANGE__DEFAULT',
      [supportPhoneNumber]
    )
  )
  groupErrorMessages.set(dateErrorTypeKeywords.underMinimumAge, i18n('ERROR__INPUT_UNDER_MINIMUM_AGE', [I18nConfig.minimumAge]))
  groupErrorMessages.set(dateErrorTypeKeywords.isOneHundredOrLess, i18n('ERROR__INPUT_IS_ONEHUNDRED_YEARS_OR_LESS'))
  groupErrorMessages.set(dateErrorTypeKeywords.isGuardianMinor, i18n('ERROR__INPUT_IS_GUARDIAN', [I18nConfig.guardianAge]))
}

const get = (errorKey) => groupErrorMessages.get(errorKey)

export default {
  load,
  get
}
