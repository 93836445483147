<template>
  <div
    id="submitting-practice"
    class="submitting-practice"
  >
    <sdc-title level="4">
      {{ i18n('SUBMIT__SUBMITTING_PRACTICE_TITLE') }}
    </sdc-title>
    <validated-select-input
      :disabled="disabled"
      name="practices-list"
      rules="required"
      :options="practiceOptions"
      :value="selectedValue"
      @input="setSubmittingPracticeById"
      @change="handlePracticeChange"
    />
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { i18n } from '_utils_/i18n'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'
import Location from '_utils_/Location'

export default {
  name: 'SubmitPracticeInfo',
  components: {
    SdcTitle,
    ValidatedSelectInput
  },
  computed: {
    ...mapGetters('Practices', ['getPractices', 'isLoaded']),
    ...mapState('CreateCase', ['practiceId']),
    disabled() {
      return !this.isLoaded
    },
    selectedValue() {
      return this.practiceId || ''
    },
    practiceOptions() {
      // FIXME: Use a getter from store to retrieve sorted practices. Like sortedPractices
      const options = this.getPractices.map(item => ({
        value: item.id,
        text: item.name
      })).sort((practiceA, practiceB) => practiceA.text.localeCompare(practiceB.text, ...Location.localeCompareConfiguration))
      options.unshift({ value: '', text: i18n('SUBMIT__SUBMITTING_PRACTICE_SELECT_PRACTICE') })
      return options
    }
  },
  mounted() {
    this.requestPractices()
  },
  methods: {
    i18n,
    ...mapActions('Practices', ['requestPractices']),
    ...mapActions('CreateCase', ['setSubmittingPracticeById']),
    ...mapMutations('CreateCase', ['setStoreNumber']),
    handlePracticeChange(value) {
      if (!value) {
        return
      }
      const currentPractice = this.getPractices.filter(item => item.id === value)
      const currentstoreNumber = currentPractice[0].storeNumber

      if (currentstoreNumber) {
        this.setStoreNumber({data: currentstoreNumber})
      }
    }
  }
}
</script>
