<template>
  <submit
    :model="model"
  />
</template>

<script>
import Submit from '_organisms_/Submit'

export default {
  name: 'RefinementSubmit',
  components: {
    Submit
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  }
}
</script>
