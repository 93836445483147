<template>
  <div>
    <div class="case-additional-upload-files">
      <sdc-title level="4">
        {{ i18n('CASE_ADDITIONAL_FILES_SECTION__TITLE') }}
      </sdc-title>
      <p v-if="description">
        {{ description }}
      </p>
      <div class="case-additional-upload-files__container">
        <validation-observer
          ref="caseAdditionalFileUploadForm"
          v-slot="{ failed }"
        >
          <form
            ref="caseAdditionalFileFormRef"
            class="case-additional-upload-files__container__form"
          >
            <div class="case-additional-upload-files__container__form__container">
              <sdc-additional-files-upload-container
                class="case-additional-upload-files__container__form__container__item"
                :file="selectedFile"
                @error="handleError"
              />
            </div>

            <div
              v-if="failed"
              class="invalid-feedback d-block"
            >
              <span>
                {{ invalidFormMessage }}
              </span>
            </div>

            <sdc-button
              class="case-additional-upload-files__container__form__button"
              :disabled="showLoader"
              @click="handleUploadFile"
            >
              <span>{{ i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__UPLOAD_FILES__BUTTON__TEXT') }}</span>
            </sdc-button>
          </form>
        </validation-observer>
        <sdc-loading
          v-if="showLoader"
          class="case-additional-upload-files__container__loader"
          type="element"
        />
      </div>
    </div>
    <div
      v-if="showUploadedFiles"
      class="case-additional-upload-files-table"
    >
      <sdc-title level="4">
        {{ i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__SECTION__TITLE') }}
      </sdc-title>
      <div class="case-additional-upload-files-table__table__header">
        <div
          v-for="({ key, label }) in columnHeaders"
          :key="key"
        >
          {{ label }}
        </div>
      </div>
      <div
        v-for="({ dateUploaded, fileName }) in files"
        :key="dateUploaded"
        class="case-additional-upload-files-table__table__body"
      >
        <div><span>{{ getFormatedDate(dateUploaded) }}</span></div>
        <div>
          <a
            class="case-additional-upload-files-table__table__body__file-link"
          >
            <sdc-image
              name="file-icon"
              class="case-additional-upload-files-table__table__body__file-icon"
              :src="fileIcon"
            />
            <div class="case-additional-upload-files-table__table__body__file-name">
              {{ fileName }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { i18n } from '_utils_/i18n'
import { ADDITIONAL_FILES_ERRORS_CODE } from '_utils_/caseAdditionalFilesConstants'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import SdcLoading from '_atoms_/SdcLoading'
import SdcAdditionalFilesUploadContainer from '_molecules_/SdcAdditionalFilesUploadContainer'
import { LOADING_STATES, snackbarDefaultSettings } from '@/utils/constants'
import I18nConfig from '_utils_/I18nConfig'
import { DOCUMENT_KINDS } from '_utils_/constants'
import SdcImage from '_atoms_/SdcImage'
import moment from 'moment'

const CASE_ADD_FILES_ERROR_PREFIX = 'CASE_ADDITIONAL_FILES_SECTION__UPLOAD__ADD-FILE__ERROR'
const CASE_UPLOAD_FILES_ERROR_PREFIX = 'CASE_ADDITIONAL_FILES_SECTION__UPLOAD__UPLOAD-FILES__ERROR'

export default {
  name: 'SdcCaseAdditionalFilesUpload',
  components: {
    SdcTitle,
    SdcButton,
    SdcAdditionalFilesUploadContainer,
    ValidationObserver,
    SdcLoading,
    SdcImage
  },
  data() {
    return {
      xRayDocumentKind: DOCUMENT_KINDS.xRay,
      otherDocumentKind: DOCUMENT_KINDS.other,
      hasFiles: false
    }
  },
  computed: {
    ...mapState('CaseUploadAdditionalFiles', ['files', 'selectedFile', 'uploadFileLoadingStatus']),
    ...mapState('CaseDetails', ['details']),
    showLoader() {
      return this.uploadFileLoadingStatus === LOADING_STATES.pending
    },
    fileIcon() {
      return require('_assets_/icon-file.svg')
    },
    showUploadedFiles() {
      return this.hasFiles
    }
  },
  watch: {
    uploadFileLoadingStatus(newStatus) {
      this.handleUploadStatus(newStatus)
    }
  },
  created() {
    this.columnHeaders = [
      { key: 'date', label: i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__DATE__TITLE') },
      { key: 'file', label: i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__FILE__TITLE') },
    ]
    this.description = i18n(
      'CASE_ADDITIONAL_FILES_SECTION__DESCRIPTION',
      null,
      { useDefaultLocaleIfNotFound: false }
    )
    this.invalidFormMessage = i18n(
      'CASE_ADDITIONAL_FILES_SECTION__UPLOAD__UPLOAD-FILES__ERROR__INVALID_FORM',
      I18nConfig.additionalFilesAllowedTypes.map(t => `.${t}`)
    )
  },
  async mounted() {
    await this.loadFiles()
  },
  methods: {
    ...mapActions('CaseUploadAdditionalFiles', ['uploadFile', 'getFiles']),
    i18n,
    async resetForm() {
      this.$refs.caseAdditionalFileFormRef.reset()
      requestAnimationFrame(() => {
        this.$refs.caseAdditionalFileUploadForm.reset()
        Object.values(this.$refs.caseAdditionalFileUploadForm.refs)
          .forEach(provider => {
            provider.value = ''
          })
      })
    },
    handleError(errorCode, params) {
      return this.toast(
        this.i18n(`${CASE_ADD_FILES_ERROR_PREFIX}__${errorCode}`, params),
        this.i18n(`${CASE_ADD_FILES_ERROR_PREFIX}__${errorCode}__TITLE`, params)
      )
    },
    handleUploadStatus(status) {
      if (status === LOADING_STATES.rejected) {
        this.toast(
          this.i18n(
            `${CASE_UPLOAD_FILES_ERROR_PREFIX}__${ADDITIONAL_FILES_ERRORS_CODE.uploadFiles}`,
            [I18nConfig.supportPhoneNumber]
          ),
          this.i18n(`${CASE_UPLOAD_FILES_ERROR_PREFIX}__${ADDITIONAL_FILES_ERRORS_CODE.uploadFiles}__TITLE`)
        )
      } else if (status === LOADING_STATES.resolved) {
        this.resetForm()
        this.toast(
          this.i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__UPLOAD-FILES__SUCCESS'),
          this.i18n('CASE_ADDITIONAL_FILES_SECTION__UPLOAD__UPLOAD-FILES__SUCCESS__TITLE')
        )
        this.$emit('onFilesUploaded')
      }
    },
    async handleUploadFile () {
      const formObserver = this.$refs.caseAdditionalFileUploadForm
      const success = await formObserver.validate()
      if (!success) return

      const fileType = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.'), this.selectedFile.name.length)
      const kind = this.getKind(fileType)

      await this.uploadFile({
				caseId: this.$route.params.id,
				filename: this.selectedFile.name,
				kind
			})
      await this.loadFiles()
    },
    toast(msg, title) {
      this.$sdcToast.open({title, content: msg})
    },
    getKind(kind){
          var texts={
            '.jpg': this.xRayDocumentKind,
            '.jpeg': this.xRayDocumentKind,
            '.png': this.xRayDocumentKind,
            '.pdf': this.otherDocumentKind,
            '.doc': this.otherDocumentKind,
            '.docx': this.otherDocumentKind,
            '.xray': this.xRayDocumentKind,
            '.dcm': this.xRayDocumentKind
          }
          return texts[kind.toLowerCase()]
    },
    async loadFiles(){
      await this.getFiles({ id:this.$route.params.id })
      this.hasFiles = !!this.files.length
    },
    getFormatedDate(input) {
       return (input) ?
        moment(input).format(I18nConfig.dateShortTimeFormat) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$tablet: map-get($sdc-breakpoints, 'tablet');

.case-additional-upload-files {
  &__container {
    position: relative;
    &__loader {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $white;
      opacity: 0.8;
    }
    &__form {
      &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__item {
          width: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $tablet) {
            width: 49%;
          }
        }
      }
      &__button {
        margin-top: 24px;
        padding: 10px 20px;
        svg {
          margin-right: 11px;
          top: -1px;
        }
      }
    }
  }
}

.case-additional-upload-files-table {
  &__table {
    &__body {
      margin-top: 0px;
      &__file-icon {
        margin-right: .25rem;
        margin-left: -.25rem;
        float: left;
      }
      &__file-link {
        align-items: center;
        color: $gray-600;
        font-weight: $font-weight-bold;
        font-size: 1rem;
        text-decoration: none;
        margin-bottom: .5rem;
        > .b-icon {
          margin-right: .75rem;
        }
        &:hover {
          color: $purple;
          text-decoration: none;
          > .b-icon {
            fill: $purple;
          }
        }
      }
    }
    &__header {
      font-weight: bold;
    }
    &__body {
      overflow-wrap: break-word;
    }
    &__header, &__body {
      display: flex;
      width: 80%;
      margin-left: 10%;
      justify-content: space-between;
      > div {
        width: 50%;
        margin-top: 8px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .case-additional-upload-files-table {
    &__table {
      &__body {
        margin-top: 20px;
        display: block;
      }
      &__header {
        font-weight: bold;
        display: none;
      }
      &__header, &__body {
        > div {
          width: 100%;
        }
      }
    }
  }
}
</style>
