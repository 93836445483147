/**
 * Makes a hash from a string content
 * @param {string} str - String to hash
 * @returns {number} - content hash
 */
export const generateHashContent = (str) => {
  const len = str.length
  let hash = 0
  if (len === 0) return hash
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
