<template>
  <section class="case-history">
    <div
      :class="[
        'is-flex',
        'is-flex-direction-column',
        'is-flex-grow-1'
      ]"
    >
      <sdc-title level="4">
        {{ i18n("CASE_HISTORY_TITLE") }}
      </sdc-title>
      <sdc-loading
        v-if="isLoading"
        type="element"
      />
      <template v-else>
        <case-history-item
          v-for="(caseHistory, index) in caseHistoryItems"
          :key="index"
          :status="caseHistory.status"
          :icon="caseHistory.icon"
          :date="caseHistory.date"
          :tracking-number="caseHistory.trackingNumber"
          :tracking-url="caseHistory.trackingUrl"
          :reason="caseHistory.reason"
        />
      </template>
    </div>
  </section>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import SdcLoading from '_atoms_/SdcLoading'
import CaseHistoryItem from '_molecules_/CaseHistoryItem'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { formatPaymentString } from '_utils_/currencyHelpers'
import { i18n } from '_utils_/i18n'
import icons from './icons.json'

export default {
  name: 'CaseHistory',
  components: { CaseHistoryItem, SdcTitle, SdcLoading },
  data() {
    return {
      caseHistoryItems: []
    }
  },
  computed: {
    ...mapState('CaseHistory', ['caseHistory', 'isLoading'])
  },
  async mounted() {
    const id = this.$route.params.id
    await this.loadCaseHistory(id)
  },
  methods: {
    ...mapActions('CaseHistory', ['getCaseHistory']),
    i18n,
    async loadCaseHistory( id ) {
      await this.getCaseHistory({id})
      this.caseHistoryItems = this.caseHistory.map((historyItem) => {
        let caseHistoryItem = {}
        if(historyItem.trackingInformation) {
          caseHistoryItem.trackingNumber = historyItem.trackingInformation.trackingNumber
          caseHistoryItem.trackingUrl = historyItem.trackingInformation.trackingUrl
        }

        caseHistoryItem.status = this.getStatus(historyItem),
        caseHistoryItem.icon = icons[historyItem.status],
        caseHistoryItem.date = moment(historyItem.date).format('MMM D, YYYY'),
        caseHistoryItem.reason = i18n(`CASE_HISTORY__REASON__${historyItem.reason}`, null, {
          useDefaultLocaleIfNotFound: false
        })

        return caseHistoryItem
      })
    },
    getStatus(historyItem) {
      if(historyItem.status === 'PAYMENT_SUBMITTED') {
        let paymentString = formatPaymentString(historyItem.amount.value, historyItem.amount.format)
        let status = i18n(`CASE_HISTORY_${historyItem.status}`)
        return `${status} (${paymentString})`
      }
      return i18n(`CASE_HISTORY_${historyItem.status}`)
    }
  }
}
</script>
