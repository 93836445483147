<template>
  <validation-provider
    v-slot="{ errors }"
    ref="sdcFileUploadProvider"
    class="upload-file-validation-provider"
    rules="required"
    tag="div"
  >
    <div :class="getClass(errors[0])">
      <div>
        <input
          :id="`file-${name}`"
          ref="inputRef"
          type="file"
          :accept="acceptedExtensions"
          :name="`${name}`"
          class="sdc-file-upload__input"
          @change="onChange"
        >
        <slot
          :is-uploading="isUploading"
          :trigger-select-file="handleInputTrigger"
        />
        <sdc-loading
          v-if="showLoader"
          class="sdc-file-upload__loader"
          type="element"
        />
      </div>
    </div>
    <span
      v-if="displayErrorMessages"
      class="invalid-feedback d-block"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { i18n } from '_utils_/i18n'
import { MAX_SIZE } from './constants'
import { FILE_UPLOAD_ERRORS_CODE } from '_utils_/constants'
import SdcLoading from '_atoms_/SdcLoading'

export default {
  name: 'SdcFileUpload',
  components: {
    ValidationProvider,
    SdcLoading
  },
  props: {
    name: {
      type: String,
      required: true
    },
    handleUpload: {
      type: Function,
      default: () => {},
      required: true
    },
    typesWhitelist: {
      type: Array,
      default: () => [],
      required: false
    },
    maxSize: {
      type: Number,
      default: () => MAX_SIZE,
      required: false
    },
    accept: {
      type: Array,
      required: true
    },
    showLoadingProgress: {
      type: Boolean,
      default: true,
      required: false
    },
    showErrorMessages: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      isDragging: false,
      isUploading: false,
      uploadedFile: this.$attrs.value || null
    }
  },
  computed: {
    shouldBlock() {
      return this.isDragging
    },
    isFileAvailable() {
      return this.uploadedFile?.name?.length > 0
    },
    showLoader() {
      return this.showLoadingProgress && this.isUploading
    },
    displayErrorMessages() {
      return this.showErrorMessages && this.errors[0] && !this.isUploading
    },
  },
  watch: {
    uploadedFile(newVal) {
      this.$emit('input', newVal)
    }
  },
  mounted() {
    const provider = this.$refs.sdcFileUploadProvider
    if (this.isAdvancedUpload()) {
      this.setDraggableEvents()
    }
    provider.syncValue(this.uploadedFile?.name)
  },
  created() {
    this.acceptedExtensions = this.accept.map(ext => `.${ext}`).join(',')
  },
  methods: {
    i18n,
    getClass(error) {
      return {
        'sdc-file-upload': true,
        'sdc-file-upload--dragging': this.isDragging || this.isUploading || this.isFileAvailable,
        'sdc-file-upload--invalid': error && !this.isUploading
      }
    },
    async markInputAsError(inputRef) {
      await this.$refs.sdcFileUploadProvider.validate()
      inputRef.value = ''
    },
    isAdvancedUpload() {
      const div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window && 'FileReader' in window
    },
    setDraggableEvents() {
      // Vainilla JS cannot get passed multiple events on addEventListener but one by one
      // Old function() syntax is necessary here for the implicit binds
      // Binds are required to apply the prevention and stopPropagation
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
        .forEach(evt => {
          this.$el.addEventListener(evt, e => {
            e.preventDefault()
            e.stopPropagation()
          })
        })

      this.setDragInOutEvents()
      this.$el.addEventListener('drop', this.onDrop)
    },
    setDragInOutEvents() {
      const dragLeaveEvents = ['dragleave', 'dragend']
      const dragInEvents = ['dragenter', 'dragover']

      dragInEvents.forEach(ev => this.$el.addEventListener(ev, () => this.isDragging = true))
      dragLeaveEvents.forEach(ev => this.$el.addEventListener(ev, () => this.isDragging = false))
    },
    handleInputTrigger() {
      this.$refs.inputRef.click()
    },
    async onDrop(event) {
      if (event.dataTransfer.files.length > 1) {
        const provider = this.$refs.sdcFileUploadProvider
        await provider.validate()
        this.$emit('error', FILE_UPLOAD_ERRORS_CODE.manyFiles )
        return
      }
      await this.upload(event.dataTransfer.files[0])
    },
    async onChange(event) {
      await this.upload(event.target.files[0])
    },
    async upload(file) {
      if (!file || this.isUploading) return

      const provider = this.$refs.sdcFileUploadProvider
      this.isUploading = true

      if (this.typesWhitelist?.length > 0 && !this.typesWhitelist.includes(file.type)) {
        this.$emit('error', FILE_UPLOAD_ERRORS_CODE.wrongUploadFormat, this.typesWhitelist)
        this.isUploading = false
        this.markInputAsError(this.$refs.inputRef)
        return
      }

      if (this.accept?.length > 0 && !this.accept.includes(file.name.split('.').pop())) {
        this.$emit('error', FILE_UPLOAD_ERRORS_CODE.wrongUploadFormat, this.typesWhitelist)
        this.isUploading = false
        this.markInputAsError(this.$refs.inputRef)
        return
      }

      if (file?.name?.length > 0) {
        let errorCode = ''
        if (file.size >= this.maxSize) {
          errorCode = FILE_UPLOAD_ERRORS_CODE.maxFileSize
        }

        if (!file.size) {
          errorCode = FILE_UPLOAD_ERRORS_CODE.minFileSize
        }

        if (errorCode) {
          this.$emit('error', errorCode)
          this.isUploading = false
          this.markInputAsError(this.$refs.inputRef)
          return
        }
      }

      try {
        await this.handleUpload(file)
        await provider.validate(file.name)
        this.uploadedFile = file

      } catch (error) {
        this.$emit('error', FILE_UPLOAD_ERRORS_CODE.fileUpload)
        await provider.validate()
      }

      this.isUploading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.upload-file-validation-provider{
  height: 100%;
}

.sdc-file-upload {
  border: 2px dashed $gray-88;
  position: relative;
  border-radius: .25rem;
  height: 100%;

  &--dragging {
    border-style: solid;
  }

  &--invalid {
    border: 2px solid $danger;
  }

  &__loader {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $white;
      opacity: 0.8;
  }

  &__input {
    display: none;
  }
}
</style>
