<template>
  <div
    :id="componentName"
    :name="componentName"
    class="sdc-tooltip"
  >
    <b-tooltip
      append-to-body
      type="is-light"
      :position="position"
      :multilined="multiline"
    >
      <smile-icon
        :id="iconId"
        ref="placeholder"
        :icon="iconType"
        class="sdc-tooltip__icon"
      />

      <template #content>
        <span>
          <span
            v-if="isTitleFilled"
            class="sdc-tooltip__content__title"
          >
            {{ title }}
            <br>
          </span>
          <span class="sdc-tooltip__content__text">{{ text }}</span>
        </span>
      </template>
    </b-tooltip>
  </div>
</template>

<script>

export default {
  name: 'SdcTooltip',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'help-circle'
    },
    position: {
      type: String,
      default: 'is-top',
      validator(value) {
        return [
          'is-bottom',
          'is-left',
          'is-right',
          'is-top'
        ].includes(value) > -1
      }
    },
    multiline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentName() {
      return `sdc-tooltip-${this.name}`
    },
    iconId() {
      return `${this.componentName}-icon`
    },
    isTitleFilled() {
      return this.title.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-tooltip {
  &__icon {
    cursor: pointer;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    color: $primary;
  }

  &__content {

    &__title{
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $black;
    }

    &__text{
      text-align: left;
      word-wrap: break-word;
      font-size: $font-size-sm;
    }
  }
}
</style>
