<template>
  <section
    id="home"
    class="view-margin"
  >
    <cases />
  </section>
</template>

<script>
import Cases from '_organisms_/Cases'

export default {
  name: 'Home',
  components: {
    Cases
  }
}
</script>
