<template>
  <div class="scan-intake-upload-files">
    <sdc-title level="4">
      {{ i18n('SCAN_INTAKE_SECTION__TITLE') }}
    </sdc-title>
    <div class="scan-intake-upload-files__container">
      <validation-observer
        ref="scanIntakeUploadForm"
        v-slot="{ failed }"
      >
        <form
          ref="scanIntakeFormRef"
          class="scan-intake-upload-files__container__form"
        >
          <div class="scan-intake-upload-files__container__form__container">
            <scan-intake-upload-file-container
              class="scan-intake-upload-files__container__form__container__item"
              :category="CATEGORIES.upper"
              :file="addedFiles[CATEGORIES.upper]"
              @error="handleError"
            />
            <scan-intake-upload-file-container
              class="scan-intake-upload-files__container__form__container__item"
              :category="CATEGORIES.lower"
              :file="addedFiles[CATEGORIES.lower]"
              @error="handleError"
            />
          </div>

          <div
            v-if="failed"
            class="invalid-feedback d-block"
          >
            <span>{{ i18n('SCAN_INTAKE_SECTION__UPLOAD__UPLOAD-FILES__ERROR__INVALID_FORM') }}</span>
          </div>

          <sdc-button
            class="scan-intake-upload-files__container__form__button"
            :disabled="showLoader"
            @click="handleUploadFiles"
          >
            <span>{{ i18n('SCAN_INTAKE_SECTION__UPLOAD__UPLOAD_FILES__BUTTON__TEXT') }}</span>
          </sdc-button>
        </form>
      </validation-observer>
      <sdc-loading
        v-if="showLoader"
        class="scan-intake-upload-files__container__loader"
        type="element"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { i18n } from '_utils_/i18n'
import { CATEGORIES, SCAN_INTAKE_ERRORS_CODE } from '_utils_/scanIntakeConstants'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import SdcLoading from '_atoms_/SdcLoading'
import ScanIntakeUploadFileContainer from '_molecules_/ScanIntakeUploadFileContainer'
import { LOADING_STATES, snackbarDefaultSettings } from '@/utils/constants'
import I18nConfig from '_utils_/I18nConfig'

const SCAN_INTAKE_ADD_FILES_ERROR_PREFIX = 'SCAN_INTAKE_SECTION__UPLOAD__ADD-FILE__ERROR'
const SCAN_INTAKE_UPLOAD_FILES_ERROR_PREFIX = 'SCAN_INTAKE_SECTION__UPLOAD__UPLOAD-FILES__ERROR'


export default {
  name: 'ScanIntakeUploadFiles',
  components: {
    SdcTitle,
    SdcButton,
    ScanIntakeUploadFileContainer,
    ValidationObserver,
    SdcLoading
  },
  computed: {
    ...mapState('ScanIntake', ['addedFiles', 'uploadFilesLoadingStatus']),
    ...mapState('CaseDetails', ['details']),
    showLoader() {
      return this.uploadFilesLoadingStatus === LOADING_STATES.pending
    },
  },
  watch: {
    uploadFilesLoadingStatus(newStatus) {
      this.handleUploadStatus(newStatus)
    }
  },
  created() {
    this.CATEGORIES = CATEGORIES
  },
  methods: {
    ...mapActions('ScanIntake', ['uploadFiles']),
    i18n,
    async resetForm() {
      this.$refs.scanIntakeFormRef.reset()
      requestAnimationFrame(() => {
        this.$refs.scanIntakeUploadForm.reset()
        Object.values(this.$refs.scanIntakeUploadForm.refs)
          .forEach(provider => {
            provider.value = ''
          })
      })
    },
    handleError(errorCode) {
      return this.toast(
        this.i18n(`${SCAN_INTAKE_ADD_FILES_ERROR_PREFIX}__${errorCode}`),
        this.i18n(`${SCAN_INTAKE_ADD_FILES_ERROR_PREFIX}__${errorCode}__TITLE`)
      )
    },
    handleUploadStatus(status) {
      if (status === LOADING_STATES.rejected) {
        this.toast(
          this.i18n(
            `${SCAN_INTAKE_UPLOAD_FILES_ERROR_PREFIX}__${SCAN_INTAKE_ERRORS_CODE.uploadFiles}`,
            [I18nConfig.supportPhoneNumber]
          ),
          this.i18n(`${SCAN_INTAKE_UPLOAD_FILES_ERROR_PREFIX}__${SCAN_INTAKE_ERRORS_CODE.uploadFiles}__TITLE`)
        )
      } else if (status === LOADING_STATES.resolved) {
        this.resetForm()
        this.toast(
          this.i18n('SCAN_INTAKE_SECTION__UPLOAD__UPLOAD-FILES__SUCCESS'),
          this.i18n('SCAN_INTAKE_SECTION__UPLOAD__UPLOAD-FILES__SUCCESS__TITLE')
        )
        this.$emit('onFilesUploaded')
      }
    },
    async handleUploadFiles () {
      const formObserver = this.$refs.scanIntakeUploadForm
      const success = await formObserver.validate()
      if (!success) return
      if (
        !this.addedFiles[CATEGORIES.upper].name || !this.addedFiles[CATEGORIES.lower].name
      ) return
      await this.uploadFiles(this.details)
    },
    toast(msg, title) {
      this.$sdcToast.open({title, content: msg})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$tablet: map-get($sdc-breakpoints, 'tablet');

.scan-intake-upload-files {
  &__container {
    position: relative;
    &__loader {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $white;
      opacity: 0.8;
    }
    &__form {
      &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__item {
          width: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: $tablet) {
            width: 49%;
          }
        }
      }
      &__button {
        margin-top: 24px;
        padding: 10px 20px;
        &:focus {
          box-shadow: none;
        }
        svg {
          margin-right: 11px;
          top: -1px;
        }
      }
    }
  }
}
</style>
