<template>
  <div class="date-time-picker">
    <validated-select-input
      v-model="pickup.date"
      name="date"
      class="date-time-picker__date__select"
      :rules="pickupDateRules"
      :options="availableDates"
      :disabled="isPickupDisabled(availableDates)"
      :required="pickupDateRules"
      @change="handleDate"
    />
    <div class="columns">
      <div class="column is-half">
        <label class="select-label">
          {{ i18n('PICKUP_SCHEDULE__TIME_EARLIEST_LABEL') }}
        </label>
        <validated-select-input
          v-model="pickup.from"
          name="from"
          class="date-time-picker__from__select"
          :rules="pickupDateRules"
          :options="availableFromHours"
          :disabled="isPickupDisabled(availableFromHours)"
          :required="pickupDateRules"
          @change="handleFrom"
        />
      </div>
      <div class="column is-half">
        <label class="select-label">
          {{ i18n('PICKUP_SCHEDULE__TIME_LATEST_LABEL') }}
        </label>
        <validated-select-input
          v-model="pickup.to"
          name="to"
          class="date-time-picker__to__select"
          :rules="pickupDateRules"
          :disabled="isPickupDisabled(availableEndHours)"
          :options="availableEndHours"
          :required="pickupDateRules"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import {
  formatDatesForSelect,
  formatHoursForSelect,
  getAvailableTimeFrom,
  getAvailableTimeTo,
  filterDates,
} from '_utils_/dateTimePickerHelper'
import ValidatedSelectInput from '_molecules_/ValidatedSelectInput'

const valueKeys = ['date', 'from', 'to']

export default {
  name: 'DateTimePicker',
  components: {
    ValidatedSelectInput
  },
  props: {
    value: {
      type: Object,
      required: true,
      validator: value => Object.keys(value)
        .every(key => valueKeys.includes(key))
    },
    dates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      restrictedHoursToSelectDateTime: 4,
      availableDates: [this.getInitialDateOption()],
      availableFromHours: [this.getInitialFromOption()],
      availableEndHours: [this.getInitialToOption()],
      pickup: { ...this.value }
    }
  },
  computed: {
    pickupDateRules() {
      return this.dates.length >= 1 ? 'required' : ''
    },
  },
  watch: {
    dates: {
      handler: function (newVal) {
        this.availableDates = [
          this.getInitialDateOption(),
          ...(formatDatesForSelect(filterDates(newVal, this.restrictedHoursToSelectDateTime)))
        ]
      },
      immediate: true
    },
    pickup: {
      handler: function ({from, to}) {
        if (!from || !to) return
        this.$emit('input', { ...this.pickup })
      },
      deep: true
    }
  },
  methods: {
    i18n,
    getInitialDateOption() {
      return {
        value: '',
        text: i18n('SHIPPING_LABEL_PICKUP__PICKUP_TIME__DATE__LABEL')
      }
    },
    getInitialFromOption() {
      return {
        value: '',
        text: i18n('SHIPPING_LABEL_PICKUP__PICKUP_TIME__START__LABEL')
      }
    },
    getInitialToOption() {
      return {
        value: '',
        text: i18n('SHIPPING_LABEL_PICKUP__PICKUP_TIME__END__LABEL')
      }
    },
    resetFromSelect() {
      this.availableFromHours = [this.getInitialFromOption()]
      this.pickup.from = ''
    },
    resetToSelect() {
      this.availableEndHours = [this.getInitialToOption()]
      this.pickup.to = ''
    },
    handleDate(value) {
      if (!value) {
        this.resetFromSelect()
        this.resetToSelect()
        return
      }
      const selectedDate = this.getSelectedDate(value)
      this.availableFromHours = [
        this.getInitialFromOption(),
        ...(formatHoursForSelect(
          getAvailableTimeFrom(
            value,
            selectedDate.hours,
            this.restrictedHoursToSelectDateTime
          )
        ))
      ]

      this.pickup.from = ''
      this.pickup.to = ''
    },
    handleFrom(value) {
      if (!value) return this.resetToSelect()
      const selectedDate = this.getSelectedDate(this.pickup.date)
      const startIndex = this.findTimeIndex(selectedDate.hours, value)

      this.availableEndHours = [
        this.getInitialToOption(),
        ...(
          value !== ''
            ? formatHoursForSelect(
              getAvailableTimeTo(
                value,
                selectedDate.hours,
                this.restrictedHoursToSelectDateTime)
            )
            : []
        )
      ]

      if (this.pickup.to) {
        const endIndex = this.findTimeIndex(selectedDate.hours, this.pickup.to)
        if (startIndex >= endIndex) this.pickup.to = ''
      }
    },
    isPickupDisabled(options) {
      return options.length < 2
    },
    findTimeIndex(datesArray, data) {
      return datesArray.findIndex(value => value === data)
    },
    getSelectedDate(selectedDate) {
      return this.dates.find(date => date.day === selectedDate)
    }
  },
}
</script>
