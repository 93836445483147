<template>
  <div
    id="fast-track-discount-code"
    class="fast-track-discount-code"
  >
    <sdc-title level="4">
      {{ i18n('FAST_TRACK_DISCOUNT_CODE__TITLE') }}
    </sdc-title>
    <sdc-checkbox
      v-if="isAffiliateCodeEnabled"
      v-model="isAffiliateData"
      name="affiliate-discount-code"
    >
      {{ i18n('FAST_TRACK_DISCOUNT_CODE__AFFILIATED_PRACTICE') }}
    </sdc-checkbox>
    <div
      v-if="!codeSuccessfullyApplied"
      class="fast-track-discount-code__wrapper"
    >
      <validated-input
        id="fast-track-discount-code-input"
        ref="FastTrackDiscountCodeInput"
        v-model="dataValue"
        name="fast-track-discount-code-input"
        class="fast-track-discount-code__wrapper__input"
        :disable-validation="true"
        :label="i18n('FAST_TRACK_DISCOUNT_CODE__PLACEHOLDER')"
        :max-length="maxLengths.fastTrack.discountCode"
        :formatter="removeSpaces"
        :rules="getRules()"
        :attrs="{ disabled: loading }"
        @keyup.enter="validateDiscountCode"
      />
      <div class="fast-track-discount-code__wrapper__apply-button_wrapper">
        <sdc-button
          type="primary"
          outlined
          class="fast-track-discount-code__wrapper__apply-button_wrapper__button"
          @click="validateDiscountCode"
        >
          {{ i18n('FAST_TRACK_DISCOUNT_CODE__APPLY-BUTTON__LABEL') }}
        </sdc-button>
      </div>
    </div>
    <div
      v-if="codeSuccessfullyApplied"
      class="fast-track-discount-code__message"
    >
      <div>
        <div class="fast-track-discount-code__message__header">
          <div class="fast-track-discount-code__message__header__title">
            {{ i18n('FAST_TRACK_DISCOUNT_CODE__CARD_TITLE') }}
          </div>
          <sdc-button
            type="link"
            class="fast-track-discount-code__message__header__remove-button text-decoration-none"
            @click="removeCode"
          >
            {{ i18n('FAST_TRACK_DISCOUNT_CODE__REMOVE-BUTTON__LABEL') }}
          </sdc-button>
        </div>
        <div
          v-if="!wasCreatedWithAffiliate"
          class="fast-track-discount-code__message__code"
        >
          {{ dataValue.toUpperCase() }}
        </div>
      </div>
      <p
        v-for="(message, key) in discountCodeMessages"
        :key="key"
        class="fast-track-discount-code__message__code-message"
      >
        {{ message }}
      </p>
      <p
        v-if="showCommunicationMessage"
        class="fast-track-discount-code__message__code-message"
      >
        {{ i18n('FAST_TRACK_DISCOUNT_CODE__COMMUNICATION_MESSAGE') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import SdcTitle from '_atoms_/SdcTitle'
import ValidatedInput from '_atoms_/ValidatedInput'
import SdcButton from '_atoms_/SdcButton'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { inputMaxLengths as maxLengths } from '_utils_/constants'
import { CARE_LEVEL } from '@/utils/constants'
import { NOTPURCHASINGYET } from '@/store/modules/payment.store'

export default {
  name: 'FastTrackDiscountCode',
  components: {
    SdcTitle,
    SdcButton,
    SdcCheckbox,
    ValidatedInput
  },
  inject: ['verifyDiscountCode'],
  props: {
    discountCode: {
      type: String,
      default: ''
    },
    showCommunicationMessage: {
      type: Boolean,
      default: false
    },
    isAffiliate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxLengths,
      dataValue: this.$attrs.value || '',
      discountCodeMessages: null,
      isValidDiscountCode: true,
      isAffiliateData: false,
      wasCreatedWithAffiliate: false,
      previousCode: '',
      codeSuccessfullyApplied: false
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('Payment', ['smilePayPlan', 'careLevel']),
    isAffiliateCodeEnabled() {
      return (
        !this.codeSuccessfullyApplied &&
        I18nConfig.hasAffiliateCode &&
        this.careLevel === CARE_LEVEL.standard &&
        this.smilePayPlan.paymentPlan === NOTPURCHASINGYET.paymentPlan
      )
    }
  },
  watch: {
    isAffiliateData(isAffiliate) {
      if (isAffiliate) {
        this.dataValue = ''
        this.validateDiscountCode()
      }

      this.$emit('onChange',
      {
        discountCode: this.dataValue,
        isAffiliate
      })
    },
    dataValue(newVal) {
      this.isValidDiscountCode = true
      this.$emit('onChange',
      {
        discountCode: newVal,
        isAffiliate: this.isAffiliateData
      })
    },
    'careLevel': function(){
      this.clearDiscountInformation()
    },
  },
  beforeMount() {
    this.dataValue = this.discountCode
    this.isAffiliateData = this.isAffiliate
  },
  mounted() {
    this.validateDiscountCode()
  },
  beforeDestroy() {
    if (!this.codeSuccessfullyApplied) {
      this.$emit('input', '')
    }
  },
  methods: {
    i18n,
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    async validateDiscountCode() {
      if ( (this.dataValue.length > 0 && this.previousCode !== this.dataValue) || this.isAffiliateData) {
        this.setLoadingStatus(true)
        try {
          this.wasCreatedWithAffiliate = this.isAffiliateData
          const result = await this.verifyDiscountCode(this.dataValue, this.isAffiliateData)
          this.discountCodeMessages = result.messages
          this.codeSuccessfullyApplied = true
          await this.$emit(
            'onChange',
            {
              discountAmount: result.discountAmount,
              discountCode: this.dataValue,
              isAffiliate: this.isAffiliateData,
              codeSuccessfullyApplied: this.codeSuccessfullyApplied
            })
        } catch (error) {
          this.isAffiliateData = false
          this.isValidDiscountCode = false
        }
        this.previousCode = this.dataValue
        this.setLoadingStatus(false)
      }
    },
    clearDiscountInformation() {
      if (this.codeSuccessfullyApplied) {
        this.removeCode()
      }
    },
    removeCode() {
      this.dataValue = ''
      this.codeSuccessfullyApplied = false
      this.previousCode= ''
      this.isAffiliateData = false
      this.isValidDiscountCode = true
      this.$emit('remove')
      this.$emit('input', '')
    },
    getRules() {
      let rules = 'alphaDash'
      if (!this.isValidDiscountCode) {
        rules = `${rules}|invalidDiscountCode`
      }
      return rules
    },
    removeSpaces(value) {
      return value.trim()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.fast-track-discount-code {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    align-items: center;
    @media screen and (max-width: $mobile) {
      display: block;
    }
    &__input {
      margin-bottom: 0;
      width: 100%;
    }
    &__apply-button_wrapper {
      text-align: center;
      &__button {
        width: 150px;
        height: 56px;
        margin-left: 20px;
        @media screen and (max-width: $mobile) {
          margin-left: 0;
        }
      }
    }
  }
  &__message {
    border: 2px solid $gray-500;
    border-radius: 5px;
    background-color: $gray-200;
    color: $secondary;
    padding: 20px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-content: baseline;
      &__title {
        color: $gray-500;
        font-weight: $font-weight-bold;
      }
      &__remove-button {
        padding: 0;
      }
    }
    &__code {
      font-weight: $font-weight-bolder;
      margin-bottom: 7px;
    }
    &__code-message {
      margin-top: 5px;
      margin-bottom: 12px;
    }
  }
}
</style>
