import { getFinanceServiceClient } from './finance.service.client'
import Logger from '@/utils/Logger'

const createFinancingApplication = async (application) => {
  try {
    const client = getFinanceServiceClient()
    const { application_id } = await client.createApplication(application)
    return application_id
  } catch (e) {
    Logger.error('Finance API - createFinancingApplication', e)
  }
}

const eSignApplication = async (applicationId) => {
  try {
    const client = getFinanceServiceClient()
    const data = await client.esign(applicationId)
    return data
  } catch (error) {
    Logger.error('Finance API - eSignApplication', error)
    throw error
  }
}

const updateApplication = async (applicationId, { orderNumber, billingAddress, amount, businessEntity, downPayment, months }) => {
  try {
    const client = getFinanceServiceClient()
    await client.updateApplication(applicationId, {
      order_number: orderNumber,
      billing_address: billingAddress,
      amount: amount,
      months: months,
      business_entity: businessEntity,
      down_payment: downPayment
    })
  } catch (e) {
    Logger.error('Finance API - updateApplication', e)
  }
}

export { createFinancingApplication, eSignApplication, updateApplication }
