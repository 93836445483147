<template>
  <div
    id="photos"
    name="photos"
    class="photos"
  >
    <create-case-step-form ref="photoForm">
      <sdc-title level="4">
        {{ i18n('PHOTOS__QUESTIONS_TITLE') }}
      </sdc-title>
      <div
        ref="photoCardElement"
        class="photos__container"
      >
        <template v-for="(_, photoType, key) in patientPhotos">
          <div :key="key">
            <photos-photo-container
              :name="`${photoType}-photo-container`"
              :title="getPhotoTitle(photoType)"
              :photo="getPhoto(photoType)"
              :subtitle="getPhotoSubtitle(photoType)"
              :type="photoType"
              :is-additional-file="isAdditionalFile"
            />
            <div
              v-if="isStraightPhoto(photoType)"
              class="photos__checkbox"
            >
              <sdc-checkbox
                v-model="photosInfo.isClosedStraightNaturalBite"
                name="natural-bite-checkbox"
                rules="requiredBoolean"
              >
                {{ i18n('PHOTO__UPLOAD_CHECKBOX_LABEL') }}
              </sdc-checkbox>
            </div>
          </div>
        </template>
      </div>
    </create-case-step-form>
  </div>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import PhotosPhotoContainer from '_molecules_/PhotosPhotoContainer'
import SdcCheckbox from '_atoms_/SdcCheckbox'
import { mapMutations, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'

export default {
  name: 'Photos',
  components: {
    PhotosPhotoContainer,
    SdcTitle,
    SdcCheckbox,
    CreateCaseStepForm
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    isAdditionalFile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titleConfig: {
        marginLevel: '1',
        level: '4'
      }
    }
  },
  computed: {
    ...mapState('CreateCase', ['photosInfo']),
    ...mapState('Photos', ['patientPhotos'])
  },
  beforeMount() {
    this.model.validate = this.validateModel
  },
  mounted() {
    this.setPatientPhotosUid()
    this.focusOnTop()
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setPatientPhotosUid', 'setNaturalBite']),
    getPhotoTitle(type) {
      return this.i18n(`PHOTO__UPLOAD_${type.toUpperCase()}_TITLE`)
    },
    getPhotoSubtitle(type) {
      return this.i18n(`PHOTO__UPLOAD_${type.toUpperCase()}_SUBTITLE`)
    },
    getPhoto(imageType) {
      const path = require(`../../../assets/photo-${imageType}-placeholder.svg`)
      const imagePath = this.patientPhotos[imageType].url
      const imageFile = this.patientPhotos[imageType].file
      const imageFileName = this.patientPhotos[imageType].filename

      const payload = {
        src: imagePath.length > 0 ? imagePath : path,
        file: imageFile,
        filename: imageFileName
      }

      return payload
    },
    isStraightPhoto(type) {
      return type === 'straight'
    },
    async validateModel(next) {
      const formResult = await this.$refs.photoForm.validateForm()

      if(!this.patientPhotos.upper.url){
        document.getElementById('upper-photo-container').scrollIntoView({ behavior: 'smooth' })
      }

      if(!this.patientPhotos.lower.url){
        document.getElementById('lower-photo-container').scrollIntoView({ behavior: 'smooth' })
      }

      if (!formResult) {
        return
      }

      this.setNaturalBite({ data: this.photosInfo.isClosedStraightNaturalBite })
      next()
    },
    focusOnTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');
$desktop: map-get($sdc-breakpoints, 'desktop');

.photos {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      width: 50%;

      @media screen and (max-width: $mobile) {
        width: 100%;
      }

      @media screen and (min-width: $desktop) {
        width: 33%;
      }
    }
  }

  &__checkbox {
    color: $secondary;
    font-size: $font-size-base;
    margin: 18px 18px 0 18px;
  }
}
</style>
