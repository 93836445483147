<template>
  <div class="case-history-item">
    <smile-icon
      :icon="icon"
      class="case-history-item__icon"
    />
    <div class="case-history-item__text">
      <span class="case-history-item__text__status">
        {{ status }}
      </span>
      <div
        v-if="reason"
        class="case-history-item__text__reason"
      >
        {{ reason }}
      </div>
      <div
        v-if="trackingNumber"
        class="case-history-item__tracking-info"
      >
        <div class="case-history-item__tracking-number-label">
          {{ i18n('SHIPPING_LABEL__TRACKING_TEXT_HISTORY') }}

          <a
            :href="trackingUrl"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ trackingNumber }}
          </a>
        </div>
      </div>
    </div>
    <div class="case-history-item__date">
      {{ date }}
    </div>
  </div>
</template>

<script>
// import { BIcon } from 'bootstrap-vue'
import { i18n } from '_utils_/i18n'

export default {
  name: 'HistoryItem',
  // components: { BIcon },
  props: {
    status: { type: String, required: true },
    icon: { type: String, required: true },
    date: { type: String, required: true },
    reason: { type: String, required: false, default: '' },
    trackingNumber: { type: String, required: false, default: '' },
    trackingUrl: { type: String, required: false, default: '' },
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

$mobile-medium: map-get($sdc-breakpoints, "medium-mobile");

.case-history-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: solid 2px $extra-light-gray;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 8px auto;
  width: 100%;
  align-items:flex-start;
  &__icon {
    font-size: $font-size-lg !important;
    color: $gray-500 !important;
    margin-right: 10px;
  }
  &__text {
    width: 60%;
    align-items: center;
    &__status {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      margin: 0;
    }
    &__reason {
      color: $gray-600;
      font-size: $font-size-sm;
    }
  }
  &__date {
    color: $gray-600;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  &__tracking-info {
    display: flex;
  }
  &__tracking-number-label {
    color: $gray-600;
    line-height: $line-height-lg;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
  @media screen and (max-width: $mobile-medium) {
    padding: 10px 10px;
    align-items: center;
    &__icon {
      font-size: 1.8rem;
    }
    &__text{
      &__status {
        font-size: 1.05rem;
      }
      &__reason {
        font-size: 1.05rem;
      }
    }
    &__date {
      font-size: 1.05rem;
    }
  }
}
.fe-x {
  color: $gray-500 !important;
  font-size: $font-size-md;
}
</style>
