import Vue from 'vue'
import { getDoctors } from '_api_/users.api'

const getPracticeKey = (boId, practiceId) => {
  return `${boId}:${practiceId}`
}

export const mutationTypes = {
  setDoctors: 'setDoctors',
  clearDoctors: 'clearDoctors',
  setDoctorsByPracticeId: 'setDoctorsByPracticeId',
  loadErrorByPracticeId: 'loadErrorByPracticeId'
}

export const state = {
  doctorsByPracticeId: Object.create(null),
  loadError: {
    status: null
  }
}

export const mutations = {
  [mutationTypes.setDoctorsByPracticeId](state, { boId, practiceId, doctors }) {
    Vue.set(state.doctorsByPracticeId, getPracticeKey(boId, practiceId), {
      loaded: true,
      loadError: null,
      doctors
    })
  },
  [mutationTypes.loadErrorByPracticeId](state, { boId, practiceId, error } ) {
    Vue.set(state.doctorsByPracticeId, getPracticeKey(boId, practiceId), {
      loaded: false,
      loadError: {
        error
      },
      doctors: []
    })
  },
  [mutationTypes.clearDoctors](state) {
    state.doctorsByPracticeId = Object.create(null)
  }
}

export const getters = {
  isLoadedByPractice: (state) => ({ boId, practiceId }) => {
    const practice = state.doctorsByPracticeId[getPracticeKey(boId, practiceId)]
    return practice && practice.loaded
  },
  listByPractice: (state) => ({ boId, practiceId }) => {
    const practice = state.doctorsByPracticeId[getPracticeKey(boId, practiceId)]
    return practice && practice.loaded ? practice.doctors : []
  },
  getByUsername: (state, getters) => ({ boId, practiceId, username }) => {
    return getters['listByPractice']({ boId, practiceId }).find(doctor => doctor.userName === username)
  }
}
export const actions = {
  async loadByPractice({ commit, getters }, { boId, practiceId, forceLoad = false }) {
    if (!forceLoad && getters['isLoadedByPractice']({ boId, practiceId })) {
      return
    }
    try {
      const doctors = await getDoctors({
        boId,
        practiceId
      })
      commit(mutationTypes.setDoctorsByPracticeId, { boId, practiceId, doctors })
    } catch (error){
      commit(mutationTypes.loadErrorByPracticeId, {
        boId,
        practiceId,
        error
      })
    }
  },
  clear({ commit}) {
    commit(mutationTypes.clearDoctors)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
