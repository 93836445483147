import { AppointmentsException } from '_utils_/Exceptions'
import { formatDate } from '_utils_/dateHelpers'
import { appointmentsConstants } from '_utils_/constants'
import I18nConfig from '_utils_/I18nConfig'
import { getAppointments } from '_api_/appointments.api'
import { compareDates, removeTimezone } from '@/utils/dateTimePickerHelper'

const {
  tableRequestState
} = appointmentsConstants

export const mutationTypes = {
  clearState: 'clearAppointmentsState',
  resetFilters: 'resetFilters',
  setAppointments: 'setAppointments',
  setCurrentStoreNumber: 'setCurrentStoreNumber',
  setLocalSortBy: 'setLocalSortBy',
  setMetadata: 'setMetadata',
  setSelectedPractice: 'setSelectedPractice',
  setTableRequestState: 'setTableRequestState',
  setTodayScheduledAppointments: 'setTodayScheduledAppointments'
}

export const state = {
  appointments: [],
  metadata: {},
  tableRequestState,
  localSortBy: 'appointmentDateCreated',
  currentStoreNumber: null,
  selectedPractice: null,
  todayScheduledAppointments: []
}

export const getters = {
  tableData: ({ appointments }) => {
    const dateFormat = I18nConfig.dateFormat
    const timeFormat = I18nConfig.timeFormat

    return appointments.map(item => ({
      ...item,
      appointmentDateCreated: formatDate(item.appointmentDateCreated, dateFormat),
      dateAppointment: `
        ${formatDate(item.dateAppointment, dateFormat)}
        ${formatDate(item.dateAppointment, timeFormat)}
      `
    }))
  },
  paginationData: ({ metadata }) => {
    const data = metadata

    delete data.sortedColumn
    delete data.sortedDirection

    return data
  },
  canCreateCaseWithAppointment: (state, getters, rootState) => {
    return !rootState.Auth.isSuperUser
      && !!state.currentStoreNumber
      && state.todayScheduledAppointments.length > 0
  }
}

export const mutations = {
  [mutationTypes.setAppointments](state, payload) {
    state.appointments = payload.appointments
  },
  [mutationTypes.setMetadata](state, payload) {
    state.metadata = {...state.metadata, ...payload.metadata}
  },
  [mutationTypes.setTableRequestState](state, payload) {
    state.tableRequestState = {...state.tableRequestState, ...payload.params}
  },
  [mutationTypes.setLocalSortBy](state, payload) {
    state.localSortBy = payload
  },
  [mutationTypes.setCurrentStoreNumber](state, payload) {
    state.currentStoreNumber = payload || null
  },
  [mutationTypes.setSelectedPractice](state, payload) {
    state.selectedPractice = payload
  },
  [mutationTypes.resetFilters](state) {
    state.tableRequestState = tableRequestState
  },
  [mutationTypes.setTodayScheduledAppointments](state, payload) {
    state.todayScheduledAppointments = payload
  },
  [mutationTypes.clearState](state) {
    state.appointments = []
    state.metadata = {}
    state.tableRequestState = tableRequestState
    state.localSortBy = 'appointmentDateCreated'
    state.currentStoreNumber = null
    state.selectedPractice = null
  }
}

export const actions = {
  async requestAppointments({ commit, dispatch, state }, payload = { params: tableRequestState }) {
    dispatch('AppProcess/setLoadingStatus', true, { root: true })
    try {
      const response = await getAppointments(payload.params, state.currentStoreNumber)
      const { appointments, metadata } = response

      commit(mutationTypes.setTableRequestState, { params: payload.params })
      commit(mutationTypes.setMetadata, { metadata })
      commit(mutationTypes.setAppointments, { appointments })
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    } catch (error) {
      commit(mutationTypes.clearState)
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
      throw new AppointmentsException(error)
    }
  },
  async requestTodayStatusScheduledAppointments({ commit, dispatch, state }, params) {
    try {
      dispatch('AppProcess/setLoadingStatus', true, {root: true})

      let collectionReply = []
      let response = []
      const today = new Date()

      if (state.currentStoreNumber) {
        do {
          response = await getAppointments(params, state.currentStoreNumber)
          const appointments = response.appointments.filter(
            (x) => compareDates(removeTimezone(new Date(x.date)), today) === 0
          )
          collectionReply = [...collectionReply, ...appointments]
          if (appointments.length < params.PageSize) {
            break
          }
          params.PageNumber++
        } while (params.PageNumber <= response.metadata.totalPages)
      }

      commit(mutationTypes.setTodayScheduledAppointments, collectionReply.filter(x => x.currentStatus === 'SCHED'))
    } catch (error) {
      commit(mutationTypes.setTodayScheduledAppointments, [])
    } finally {
      dispatch('AppProcess/setLoadingStatus', false, { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
