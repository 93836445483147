<template>
  <section class="legal-tooltip">
    <span :class="contentClassName">
      <slot />
    </span>
    <SdcTooltip
      class="legal-tooltip__tooltip"
      :name="tooltipName"
      :text="tooltipText"
      :position="tooltipPosition"
      multiline
    />
  </section>
</template>

<script>
import SdcTooltip from '@/components/atoms/SdcTooltip'
import { i18n } from '@/utils/i18n'
import { POSITION_TYPES } from './constants'

export default {
  name: 'LegalTooltip',
  components: {
    SdcTooltip
  },
  props: {
    name: {
      type: String,
      required: true
    },
    textKey: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: POSITION_TYPES['right']
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tooltipName() {
      return `legal-tooltip-${this.name}`
    },
    tooltipText() {
      return i18n(this.textKey)
    },
    tooltipPosition() {
      return POSITION_TYPES[this.position] || POSITION_TYPES.default
    },
    contentClassName() {
      return {
        'legal-tooltip__content': true,
        'legal-tooltip__content--full-width': this.fullWidth
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.legal-tooltip {
  &__content {
    display: inline-block;

    &--full-width {
      width: calc(100% - 30px);
    }
  }

  &__tooltip {
    display: inline-block;
    margin-left: 5px;
  }
}
</style>
