const formatMedicalQuestions = ({
  haveAnyData,
  allThatApplyData
}) => {
  const questions = [...haveAnyData.questions, ...allThatApplyData.questions]

  return questions.filter(({
    isVisible
  }) => isVisible).map(({
    name,
    value,
    detailValue,
    subquestions,
    type
  }) => {
    let details = detailValue

    if (value === true) {
      if (type === 'ToothGroup') {
        const subquestionsValue = subquestions[0].value

        details = [...subquestionsValue.upper, ...subquestionsValue.lower].join(',')
      } else if (name === 'has_bonded_retainer') {
        const upperBondedRetainerQuestionIndex = 1
        const lowerBondedRetainerQuestionIndex = 2
        const removalUpperType = (subquestions[upperBondedRetainerQuestionIndex].value === 'Yes') ? 'REMOVE' : 'REMAIN'
        const removalLowerType = (subquestions[lowerBondedRetainerQuestionIndex].value === 'Yes') ? 'REMOVE' : 'REMAIN'
        const teethAreas = subquestions[0].value.map((tooth) => {
          return tooth.includes('Upper') ? `UPPER-${removalUpperType}` : `LOWER-${removalLowerType}`
        })

        details = teethAreas.join(',')
      }
    } else {
      details = ''
    }

    return {
      questionKey: name,
      value,
      details
    }
  })
}

export {
  formatMedicalQuestions
}
