export const mutationTypes = {
  setBannerParams: 'setBannerParams',
  clearState: 'clearState'
}

export const state = {
  active: false,
  text: '',
  buttonText: '',
  buttonClick: null,
  bullets: [],
  type: 'is-primary'
}

export const getters = {
  hasBullets({ bullets }) {
    return !!bullets?.length
  }
}

export const mutations = {
  [mutationTypes.setBannerParams](
    state,
    { text, bullets, buttonText, buttonClick, type }
  ) {
    state.text = text || ''
    state.buttonText = buttonText || ''
    state.buttonClick = buttonClick || null
    state.bullets = bullets || []
    state.active = true
    state.type = type || 'is-primary'
  },
  [mutationTypes.clearState](state) {
    state.active = false
    state.text = ''
    state.buttonText = ''
    state.buttonClick = null
    state.bullets = []
    state.type = 'is-primary'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
