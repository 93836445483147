<template>
  <section class="sdc-gallery">
    <div class="sdc-gallery__main">
      <sdc-loading
        v-if="loading"
        type="element"
      />
      <sdc-image
        v-show="!loading"
        name="sdc-gallery-main-image"
        class="sdc-gallery__main__image"
        :src="selectedMainImage"
        @load="onLoad"
      />
    </div>
    <div class="sdc-gallery__thumbnails">
      <template v-for="(image, key) in images">
        <sdc-image
          :key="key"
          :name="`sdc-gallery-thumbnail-${key}`"
          :class="getThumbnailClass(key)"
          :src="image"
          @click="onClick"
        />
      </template>
    </div>
  </section>
</template>

<script>
import SdcImage from '_atoms_/SdcImage'
import SdcLoading from '_atoms_/SdcLoading'

export default {
  name: 'SdcGallery',
  components: {
    SdcImage,
    SdcLoading
  },
  props: {
    images: {
      type: Array,
      default: null, // if null, not found will be shown
    },
  },
  data() {
    return {
      loading: true,
      selectedMainImage: '',
      selectedImage: 'sdc-gallery-thumbnail-0'
    }
  },
  watch: {
    images() {
      this.setDefaultMainImage()
    }
  },
  mounted() {
    this.setDefaultMainImage()
  },
  methods: {
    onClick({ name }, source) {
      if (name !== this.selectedImage) {
        this.loading = true
        this.selectedMainImage = source
        this.selectedImage = name
      }
    },
    onLoad() {
      this.loading = false
    },
    setDefaultMainImage() {
      if (this.images === null) {
        this.loading = true
        return
      }

      if (this.images.length > 0) {
        this.selectedMainImage = this.images[0]
      } else {
        this.loading = false
      }
    },
    getThumbnailClass(itemKey) {
      return {
        'sdc-gallery__thumbnails__image': true,
        'sdc-gallery__thumbnails__image--selected': this.selectedImage.includes(itemKey)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$desktop: map-get($sdc-breakpoints, "desktop-sm");

.sdc-gallery {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &__main {
    width: 80%;
    margin-right: 20px;

    &__image {
      width: 100%;
      height: 100%;
    }
  }
  &__thumbnails {
    width: 51px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
      justify-content: space-between;
      width: 120px;
    }

    &__image {
      width: 51px;
      height: 51px;
      margin-bottom: 12px;
      cursor: pointer;

      @media screen and (min-width: $desktop) {
        width: 120px;
        height: 120px;
        margin-bottom: 0;
      }

      &--selected {
        opacity: 0.5;
      }
    }
  }
}
</style>
