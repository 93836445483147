<template>
  <header class="pl-2">
    <router-link
      :to="{path: '/home'}"
      append
    >
      <span class="icon-arrow-left text-primary" />
      {{ i18n('CASE_DETAILS__ALL_CASES_BTN') }}
    </router-link>
    <sdc-title level="2">
      {{ i18n('CASE_DETAILS__TITLE') }}
    </sdc-title>
  </header>
</template>

<script>
import SdcTitle from '_atoms_/SdcTitle'
import { i18n } from '_utils_/i18n'

export default {
  name: 'CaseDetailsHeader',
  components: {
    SdcTitle
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

header > a {
  font-family: $font-family-black;
  font-weight: $font-weight-normal;
}
</style>
