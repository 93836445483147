<template>
  <information-form
    id="personal-info"
    v-model="model"
    name="personal-info"
    :title="i18n(title)"
    :custom-rules="customRules"
    :render-sms="renderSms"
    :email-always-disabled="true"
    :disabled-fields="canPatientBeResumed"
    :enabled-date-of-birth="enabledDateOfBirth"
    legal-name-key="INFORMATION_FORM__LEGAL_NAME__TEXT"
  />
</template>

<script>
import { mapState } from 'vuex'
import InformationForm from '_molecules_/InformationForm'
import { createCaseConstants } from '_utils_/constants'
import { i18n } from '_utils_/i18n'

const { initialDates } = createCaseConstants

export default {
  name: 'PersonalInfo',
  components: {
    InformationForm
  },
  props: {
    enabledDateOfBirth: {
      type: Boolean,
      default: false
    },
    renderSms: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      initialDates,
      customRules: {
        firstName: 'required',
        lastName: 'required',
        emailAddress: 'required|email',
        dateOfBirth: {
          month: 'underMinimumAge:@day,@month,@year',
          day: 'underMinimumAge:@day,@month,@year',
          year: 'underMinimumAge:@day,@month,@year'
        }
      },
      model: this.$attrs.value || {}
    }
  },
  computed:{
    ...mapState('InformationForm', ['canPatientBeResumed'])
  },
  watch: {
    model(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    i18n
  }
}
</script>
