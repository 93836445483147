import { getTokenManager } from '_api_/auth.api.js'

export const addAuthHeaders = async (request) => {
  let [idToken, accessToken] = await Promise.all([
    getTokenManager().get('idToken'),
    getTokenManager().get('accessToken')
  ])

  request.headers['idToken'] = idToken.idToken
  request.headers['Authorization'] = `Bearer ${accessToken.accessToken}`

  return request
}
