/* eslint-disable no-console */
import Logger from '@/utils/Logger'
import {
  sdcInstanceWithRetries as apiWithRetries,
  sdcInstance
} from './sdc.config.api'

/**
 *
 * @param {uuid} CaseUuid
 * @returns
 */
const getPhotoAssets = async (uuid) => {
  try {
    const response = await apiWithRetries.get(
      `/case/${uuid}/photo-assets`
    )
    return response.data
  } catch (error) {
    Logger.error('CaseDetails API - getPhotoAssets', error)
    throw error
  }
}

const getAppointmentNoteByCaseId = async (uuid) => {
  try {
    const response = await sdcInstance.get(
      `/case/${uuid}/pending-appointment-note`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

/**
 * @param {number} caseId
 * @param {Object} appointmentData - object for creating appointment note
 * @returns {Object} Resource Created Response
 */
 const createAppointmentNote = async (caseId, appointmentData) => {
  try {
    const response = await sdcInstance.post(
      `/case/${caseId}/appointment-note`, appointmentData
    )
    return response.data
  } catch (error) {
    Logger.error('CaseDetails API - createAppointmentNote', error)
    throw error
  }
}

export { getPhotoAssets, getAppointmentNoteByCaseId, createAppointmentNote }
