<template>
  <sdc-modal
    id="notification-modal"
    ref="notification-modal"
    name="notification-modal"
    size="small"
    :title-props="titleProps"
    hide-footer
  >
    <slot />
    <div class="modal-footer">
      <sdc-button
        type="primary"
        outlined
        @click="confirm"
      >
        {{ i18n('LABEL__OK') }}
      </sdc-button>
    </div>
  </sdc-modal>
</template>

<script>
import SdcModal from '_molecules_/SdcModal'
import SdcButton from '_atoms_/SdcButton'
import { i18n } from '_utils_/i18n'

export default {
  name: 'NotificationModal',
  components: {
    SdcButton,
    SdcModal,
  },
  props: {
    titleProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    modalRef() {
      return this.$refs['notification-modal']
    }
  },
  methods: {
    i18n,
    confirm() {
      this.$emit('onAccept')
    },
    focus() {
      this.modalRef.focus()
    },
    open() {
      this.modalRef.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables.scss';
$mobile: map-get($sdc-breakpoints, 'mobile');

.modal-footer {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}
</style>
