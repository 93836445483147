import en_US from './lang/en-US.json'
import en_GB from './lang/en-GB.json'
import en_AU from './lang/en-AU.json'
import de_DE from './lang/de-DE.json'
import en_CA from './lang/en-CA.json'
import fr_CA from './lang/fr-CA.json'
import es_ES from './lang/es-ES.json'
import es_MX from './lang/es-MX.json'
export default {
  en_US,
  en_GB,
  en_AU,
  de_DE,
  en_CA,
  fr_CA,
  es_ES,
  es_MX
}
