import { getFinanceServiceClient } from './finance.service.client'
import { sdcInstance as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

/**
 * Get a terms's guardian info by patient data
 *
 * @param {Object} patientData
 * @returns {Object} payload
 * @prop {String} termsId
 */
const getSmilePayTerms = async (patientData) => {
  try {
    const response = await api.post('/smilepay/terms', patientData)
    return response.data
  } catch (error) {
    Logger.error('Terms API - getSmilePayTerms', error)
    throw error
  }
}

const getSmilePayTermsPdf = async (termsId) => {
  try {
    const response = await api.get(`/smilepay/terms/${termsId}`, { responseType: 'arraybuffer' })
    return response.data
  } catch (error) {
    Logger.error('Terms API - getSmilePayTermsPdf', error)
    throw error
  }
}

const createNewSmilePayTerms = async (termsId) => {
  try {
    const client = getFinanceServiceClient()
    const data = await client.createAgreement(termsId)
    return data
  } catch (error) {
    Logger.error('Terms API - createNewSmilePayTerms', error)
    throw error
  }
}

export {
  getSmilePayTerms,
  getSmilePayTermsPdf,
  createNewSmilePayTerms
}
