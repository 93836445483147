<template>
  <validation-provider
    v-slot="{ errors }"
    ref="sdcRadioButtonProvider"
    :name="name"
    :rules="rules"
  >
    <b-field v-if="isButton">
      <template
        v-for="(option, key) in options"
      >
        <b-radio-button
          :id="`${name}-${key}`"
          :key="key"
          v-model="radioButton"
          :native-value="option.value"
          :name="name"
        >
          <!-- eslint-disable -->
          <span v-html="option.text" />
          <!-- eslint-enable -->
        </b-radio-button>
      </template>
    </b-field>

    <template v-else-if="isCard">
      <sdc-radio-button-card
        v-for="(option, key) in options"
        :key="key"
        :selected="selected === option.value"
        @click.native="setRadioValue(option.value)"
      >
        <smile-radio
          :id="`${name}-${key}`"
          v-model="selected"
          :name="name"
          type="is-primary"
          class="pl-0"
          :disabled="disabled"
          :native-value="option.value"
          @input="setRadioValue(option.value)"
        >
          <!-- eslint-disable -->
          <span v-html="option.text" />
          <!-- eslint-enable -->
        </smile-radio>

        <template v-if="isPlaceHolderEnabled">
          <div
            :id="`ph-${name}-${key}`"
            :key="`ph-${name}-${key}`"
          />
        </template>
      </sdc-radio-button-card>
    </template>

    <template v-else-if="isRadio">
      <smile-field
        v-for="(option, key) in options"
        :key="key"
      >
        <smile-radio
          :id="`${name}-${key}`"
          v-model="selected"
          :name="name"
          type="is-primary"
          class="pl-0"
          :disabled="disabled"
          :native-value="option.value"
          @input="setRadioValue(option.value)"
        >
          <!-- eslint-disable -->
          <span v-html="option.text" />
          <!-- eslint-enable -->
        </smile-radio>
      </smile-field>
    </template>

    <span
      v-if="errors[0]"
      class="invalid-feedback d-block"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import SdcRadioButtonCard from './sdc-radio-button-card.vue'

export default {
  name: 'SdcRadioButton',
  components: {
    ValidationProvider,
    SdcRadioButtonCard
  },
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'radio',
      validator(prop) {
        return ['radio', 'button', 'card'].includes(prop)
      }
    },
    placeHolderEnabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: null
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasBorder:{
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: 'row' // row or column
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: this.value,
      radioButton: this.value
    }
  },
  computed: {
    isButton() {
      return this.type === 'button'
    },
    isCard() {
      return this.type === 'card'
    },
    isRadio() {
      return this.type === 'radio'
    },
    isPlaceHolderEnabled() {
      return this.placeHolderEnabled
    }
  },
  watch: {
    radioButton(newValue) {
      this.setRadioValue(newValue)
    }
  },
  mounted() {
    this.getValidationRadioButton()
  },
  updated() {
    this.getValidationRadioButton()
  },
  methods: {
    getValidationRadioButton() {
      const provider = this.$refs.sdcRadioButtonProvider
      provider.syncValue(this.$attrs.value)
    },
    setRadioValue(radioValue) {
      this.selected = radioValue
      const provider = this.$refs.sdcRadioButtonProvider
      provider.syncValue(radioValue)
      provider.validate()
      this.$emit('input', radioValue)
    }
  }
}
</script>
