export const MAX_SIZE = 50000000

export const ACCEPT_TYPES = [
  '.bmp',
  '.dib',
  '.gif',
  '.ico',
  '.jfif',
  '.jpeg',
  '.jpg',
  '.pip',
  '.pjpeg',
  '.png',
  '.svg',
  '.svgz',
  '.tif',
  '.tiff',
  '.webp',
  '.xbm'
]

export const TYPES_WHITELIST = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
  'image/pipeg',
  'image/x-xbitmap'
]
