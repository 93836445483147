export const mutationTypes = {
  setLoadingStatus: 'setLoadingStatus'
}

export const state = {
  loading: false
}

export const mutations = {
  [mutationTypes.setLoadingStatus](state, payload) {
    state.loading = payload
  }
}

export const actions = {
  setLoadingStatus({ commit }, payload) {
    commit(mutationTypes.setLoadingStatus, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}