import { sdcInstance as api } from '_api_/sdc.config.api'
import Logger from '@/utils/Logger'

const getI18nConfig = async (countryCode) => {
  try {
    const response = await api.get(`/i18nconfiguration/${countryCode}`)
    return response.data
  } catch (error) {
    Logger.error('I18nConfig API - getI18nConfig', error)
    throw error
  }
}

export {
  getI18nConfig
}
