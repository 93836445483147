import { sdcInstanceOkta, sdcInstance, sdcInstanceWithRetries, sdcInstanceWithLongRetries,
  sdcInstanceNoAuth, sdcInstanceProvider } from './sdc.config.api'
import { checkAppVersion, addAuthHeaders, addI18nHeaders, useInterceptors } from '_api_/interceptors'

// Add interceptors to axios instances
useInterceptors(sdcInstance, [addAuthHeaders, checkAppVersion, addI18nHeaders])
useInterceptors(sdcInstanceWithRetries, [addAuthHeaders, checkAppVersion, addI18nHeaders])
useInterceptors(sdcInstanceWithLongRetries, [addAuthHeaders, checkAppVersion, addI18nHeaders])
useInterceptors(sdcInstanceProvider, [addAuthHeaders, checkAppVersion, addI18nHeaders])

export {
  sdcInstance,
  sdcInstanceWithRetries,
  sdcInstanceWithLongRetries,
  sdcInstanceOkta,
  sdcInstanceNoAuth,
  sdcInstanceProvider
}
