<template>
  <div :class="className">
    <div class="sdc-message__content">
      <div class="sdc-message__content__icon">
        <sdc-image
          id="sdc-message-icon"
          :src="iconPath"
          name="sdc-message-icon"
        />
      </div>
      <div class="sdc-message__content__text">
        {{ textMessage }}
      </div>
    </div>
    <div
      v-if="isSlotUsed"
      class="sdc-message__footer"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import SdcImage from '_atoms_/SdcImage'
import { TYPES } from './constants'

export default {
  components: {
    SdcImage
  },
  props: {
    iconPath: {
      type: String,
      required: true,
      default: ''
    },
    textMessage: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'info'
    }
  },
  computed: {
    isSlotUsed() {
      return this.$slots.default
    },
    className() {
      return {
        'sdc-message': true,
        [`sdc-message--${this.type}`]: (TYPES.includes(this.type))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-message {
  border-radius: 7px;
  margin-bottom: 24px;
  padding: 10px;

  &--info {
    background: $gray-200;
  }

  &--warning {
    background: $light-yellow;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    &__icon {
      margin-right: 10px;
    }

    &__text{
      font-size: 1rem;
      font-weight: $font-weight-normal;
      color: $gray-600;
      line-height: 1.5;
    }
  }

  &__footer {
    padding-top: 10px;
    padding-left: 34px;
  }
}
</style>
