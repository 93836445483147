import { i18n } from '_utils_/i18n'
import { createUser, getAllUsers, updateUser, deleteUser, getDoctors, getUserByName } from '_api_/users.api'
import Location from '_utils_/Location'
import I18nconfig from '_utils_/I18nConfig'
import ErrorException from '@/utils/Exceptions/ErrorException'

export const mutationTypes = {
  setUser: 'setUser',
  setUsers: 'setUsers',
  clearUser: 'clearUser',
  removeUser: 'removeUser',
  setIsLoading: 'setIsLoading',
  setUserLogged: 'setUserLogged',
  clearUserLogged: 'clearUserLogged'
}

export const state = {
  createUserData: {
    userName: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    role: '',
    isSuperUser: false,
    isActive: false,
    isSmileGuide: false,
    isSalesSpecialist: false
  },
  userLogged: {
    userName: '',
    firstName: '',
    fullName: '',
    JobTitle: ''
  },
  users: [],
  userRemoved: {
    userName: '',
    msg: null,
    status: null
  },
  isLoading: false
}

export const mutations = {
  [mutationTypes.setUser](state, payload) {
    state.createUserData = {
      ...state.createUserData,
      ...payload
    }
  },
  [mutationTypes.setUsers](state, payload) {
    state.users = payload
  },
  [mutationTypes.setUserLogged](state, payload) {
    state.userLogged = {
      ...state.userLogged,
      ...payload
    }
  },
  [mutationTypes.clearUser](state) {
    state.createUserData = {}
  },
  [mutationTypes.removeUser](state, payload) {
    state.userRemoved = payload
    if(payload.status === 204) {
      const index = state.users
        .findIndex(user => user.userName === payload.userName)
      state.users.splice(index, index > -1 ? 1 : 0)
    }
  },
  [mutationTypes.setIsLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.clearUserLogged](state){
    state.userLogged = {}
  }
}

// TODO: improve these getters to deliver only the information strongly required
export const getters = {
  getById: (state) => (userName) => {
    return state.users.find(p => p.userName === userName)
  },
  getUserInfo: (state, getters) => (userName) => {
    return JSON.parse(JSON.stringify(getters['getById'](userName)))
  },
  userFullName: (state) => {
    return state.userLogged.fullName
  }
}

export const actions = {
  async createUser({ dispatch, rootGetters, commit, state }, {payload, practiceIdSelected}) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      commit(mutationTypes.setUser, payload)
      return await createUser(
        rootGetters['Auth/boId'],
        rootGetters['Auth/currentPracticeId'] || practiceIdSelected,
        state.createUserData)
    } catch(error) {
      throw new ErrorException('UsersException', error)
    }
    finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },

	async requestDoctors({ rootGetters, commit }) {
    const params = {
      boId: rootGetters['Auth/boId'],
      practiceId: rootGetters['Auth/currentPracticeId']
    }
    const users = await getDoctors(params)
    users.sort((userA, userB) => userA.fullName.localeCompare(userB.fullName, ...Location.localeCompareConfiguration))
    commit(mutationTypes.setUsers, users)
  },
  async requestUsers({ rootGetters, commit }) {
    try {
      commit(mutationTypes.setIsLoading, true)
      const params = {
        boId: rootGetters['Auth/boId'],
        practiceId: rootGetters['Auth/currentPracticeId']
      }
      const users = await getAllUsers(params)
      users.sort((userA, userB) => userA.fullName.localeCompare(userB.fullName, ...Location.localeCompareConfiguration))
      commit(mutationTypes.setUsers, users)
    } catch(error) {
      throw new ErrorException('UsersException', error)
    }
    finally {
      commit(mutationTypes.setIsLoading, false)
    }
  },
  async requestUserByName({commit}, userName){
    try {
      commit(mutationTypes.setIsLoading, true)
      const user = await getUserByName(userName)
      commit(mutationTypes.setUserLogged, user)
    } catch (error) {
      throw new ErrorException('UsersException', error)
    }
    finally {
      commit(mutationTypes.setIsLoading, false)
    }
  },
  async updateUser({ dispatch, rootGetters, commit, state }, { payload, practiceIdSelected }) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      commit(mutationTypes.setUser, payload)
      return await updateUser(
        rootGetters['Auth/boId'],
        rootGetters['Auth/currentPracticeId'] || practiceIdSelected,
        state.createUserData)
    } catch(error) {
      throw new ErrorException('UsersException', error)
    }
    finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },
  async deleteUser({ dispatch, commit }, { userName, reassignTo }) {
    try {
      dispatch('HttpRequest/setLoadingStatus', true, { root: true })
      await deleteUser(userName, reassignTo)
      commit(mutationTypes.removeUser, {
        userName: userName,
        msg: i18n('OFFICE_TEAM__DELETE_TEAM_MEMBER_SUCCESS'),
        status: 204
      })
    } catch (error) {
      commit(mutationTypes.removeUser, {
        msg: i18n('ERROR__USER_NOT_DELETED', [I18nconfig.supportPhoneNumber]),
        status: error.status
      })
      throw new ErrorException('UsersException', error)
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  keysToPersist: ['userLogged']
}
