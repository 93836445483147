import { i18nConstants } from '_utils_/constants'
import { getI18nConfig } from '_api_/i18nConfig.api'
import store from '_store_'
import { mutationTypes } from '_store_/modules/locale.store'
import Location from '_utils_/Location'

// Singleton for i18n config

class I18nConfig {
  constructor() {
    if (!I18nConfig.instance) {
      this._config = {}
      I18nConfig.instance = this
    }

    return I18nConfig.instance
  }

  init() {
    this._config = JSON.parse(
      window.localStorage.getItem(i18nConstants.storageName)
    )
  }

  async load() {
    const countryCode = Location.countryCode

    if (this.getItem('countryCode') === countryCode) return

    try {
      const result = await getI18nConfig(countryCode)
      window.localStorage.setItem(
        i18nConstants.storageName,
        JSON.stringify(result)
      )
      this.init()
      store.commit(`Locale/${mutationTypes.notifyI18nLoadState}`, {
        status: true
      })
      return true
    } catch (error) {
      store.commit(`Locale/${mutationTypes.notifyI18nLoadState}`, {
        status: false
      })
      return error.response
    }
  }

  clearStorage() {
    window.localStorage.removeItem(i18nConstants.storageName)
  }

  getItem(key) {
    return this._config[key] || null
  }

  get insuranceUrl() {
    return this.getItem('insuranceUrl')
  }

  get appointments() {
    return this.getItem('appointments')
  }

  get dateFormat() {
    return this.getItem('countryDateFormat')?.displayFormat || 'll'
  }

  get weekDayDisplayFormat() {
    return (
      this.getItem('countryDateFormat')?.weekDayDisplayFormat || 'dddd, MMMM D'
    )
  }

  get timeFormat() {
    return this.getItem('countryDateFormat')?.timeFormat || 'h:mm a'
  }

  get supportPhoneNumber() {
    return this.getItem('phoneType')?.officeDirect?.[Location.languageCode]
  }

  get dateInputOrder() {
    return (
      this.getItem('countryDateFormat')?.inputOrder ||
      i18nConstants.defaultDateInputOrder
    )
  }

  get smsTermsUrl() {
    return this.getItem('smsTerms')?.url?.[Location.languageCode]
  }

  get smsTermsPrechecked() {
    return this.getItem('smsTerms')?.preChecked
  }

  get legalDocumentsUrls() {
    const legalDocuments = this.getItem('legalDocumentsUrls')
    let urls = {}

    for (let document in legalDocuments) {
      urls[document] = legalDocuments[document]?.[Location.languageCode]
    }

    return urls
  }

  get termsUrl() {
    return this.getItem('termsUrl')?.[Location.languageCode]
  }

  get hasSmilePayConditions() {
    return this.getItem('hasSmilePayConditions')
  }

  get showTermsAndConditions() {
    return this.getItem('showTermsAndConditions')
  }

  get currency() {
    const currencies = this.getItem('currencies')
    return Array.isArray(currencies) ? currencies[0].symbol : undefined
  }

  get currencyCode() {
    const currencies = this.getItem('currencies')
    return Array.isArray(currencies) ? currencies[0].currencyCode : undefined
  }

  get customFormat() {
    const currencies = this.getItem('currencies')
    return Array.isArray(currencies) ? currencies[0].customFormat : undefined
  }

  get regions() {
    return !Array.isArray(this.getItem('regions'))
      ? []
      : this.getItem('regions')
  }

  get defaultRegion() {
    return this.getItem('defaultRegion')
  }

  get hasRegions() {
    return this.regions.length > 0
  }

  get minimumAge() {
    return this.getItem('ageRestrictions')?.minimumAge
  }

  get guardianAge() {
    return this.getItem('ageRestrictions')?.ageOfConsent?.guardian
  }

  get patientLegalAge() {
    return this.getItem('ageRestrictions')?.ageOfConsent?.customer
  }

  get smilePayEnabled() {
    return this.getItem('paymentMethods')?.smilePay?.enabled
  }

  get smileNowEnabled() {
    return this.getItem('paymentMethods')?.smileNow?.enabled
  }

  get singlePayEnabled() {
    return this.getItem('paymentMethods')?.singlePay?.enabled
  }

  get notFastTrackEnabled() {
    return this.getItem('paymentMethods')?.notFastTrack?.enabled
  }

  get allianzPaymentUrl() {
    return this.getItem('allianzPaymentUrl')
  }

  get isShippingEnabled() {
    return this.getItem('shippingConfiguration')?.isShippingEnabled
  }

  get shortDateFormat() {
    return this.getItem('countryDateFormat')?.shortDateFormat || 'MM/DD/YYYY'
  }

  get shortDateTimeFormat() {
    return (
      this.getItem('countryDateFormat')?.shortDateTimeFormat ||
      'MM/DD/YYYY hh:mm A'
    )
  }

  get dateTimeFormat() {
    return (
      this.getItem('countryDateFormat')?.dateTimeFormat || 'MM-DD-YYYY hh:mm A'
    )
  }

  get dateShortTimeFormat() {
    return (
      this.getItem('countryDateFormat')?.dateShortTimeFormat ||
      'MM-DD-YYYY h:mm A'
    )
  }

  get displayHourFormat() {
    return this.getItem('countryDateFormat')?.displayHourFormat || 'HH:mm'
  }

  get hasAffiliateCode() {
    return this.getItem('hasAffiliateCode')
  }

  get patientShippingAddress() {
    return this.getItem('patientShippingAddress')
  }

  get showPracticeAddressCheckbox() {
    return (
      this.getItem('patientShippingAddress')?.showPracticeAddressCheckbox ||
      false
    )
  }

  get practiceAddressAsDefaultForNewCases() {
    return (
      this.getItem('patientShippingAddress')
        ?.practiceAddressAsDefaultForNewCases || false
    )
  }

  get practiceAddressAsDefaultForResumedCase() {
    return (
      this.getItem('patientShippingAddress')
        ?.practiceAddressAsDefaultForResumedCase || false
    )
  }

  showTermsAndConsentsByPaymentMethod(paymentMethod) {
    return this.getItem('paymentMethods')?.[paymentMethod]?.showTermsAndConsents
  }

  isPaymentInformationRequiredForMethod(paymentMethod) {
    return this.getItem('paymentMethods')?.[paymentMethod]
      ?.paymentInformationRequired
  }

  get hideCancelCaseCreationButtonOnSteps() {
    return this.getItem('hideCancelCaseCreationButtonOnSteps')
  }

  get showPassPractitionerNotification() {
    return (
      this.getItem('notificationModalConfiguration')
        ?.showPassPractitionerNotification || false
    )
  }

  get showPassReceptionistNotification() {
    return (
      this.getItem('notificationModalConfiguration')
        ?.showPassReceptionistNotification || false
    )
  }

  get showSendPatientImpressionNotification() {
    return (
      this.getItem('notificationModalConfiguration')
        ?.showSendPatientImpressionNotification || false
    )
  }

  get workflowOrder() {
    return this.getItem('workflowOrder')
  }

  get newWorkflowOrder() {
    return this.getItem('newWorkflowOrder')
  }

  get shouldShowCreateCasePreferenceStep() {
    return this.workflowOrder.some((item) => item === 'CreateCasePreference')
  }

  get waiverText() {
    return (
      this.getItem('signatureConfigurations')?.confidentialityWaiver
        ?.signatureTranslationKeys.termsText || null
    )
  }

  get insuranceText() {
    return (
      this.getItem('signatureConfigurations')?.insuranceConsentForm
        ?.signatureTranslationKeys.termsText || null
    )
  }

  get waiverSubtitle() {
    return (
      this.getItem('signatureConfigurations')?.confidentialityWaiver
        ?.signatureTranslationKeys.subtitle || null
    )
  }

  get insuranceSubtitle() {
    return (
      this.getItem('signatureConfigurations')?.insuranceConsentForm
        ?.signatureTranslationKeys.subtitle || null
    )
  }

  get waiverConsentFormEnabled() {
    return (
      this.getItem('signatureConfigurations')?.confidentialityWaiver?.enabled ||
      false
    )
  }

  get insuranceConsentFormEnabled() {
    return (
      this.getItem('signatureConfigurations')?.insuranceConsentForm?.enabled ||
      false
    )
  }

  get isPaymentBrokerEnabled() {
    return this.getItem('isPaymentBrokerEnabled')
  }

  get locatedRolesAllowedInCurrentCountry() {
    return this.getItem('LocatedRolesAllowedInCurrentCountry')
  }

  get additionalFilesAllowedTypes() {
    return this.getItem('additionalFilesAllowedTypes') || []
  }

  get enableCaseHistorySection() {
    return this.getItem('enableCaseHistorySection') || false
  }

  get isNewCheckoutEnabled() {
    return this.getItem('isNewCheckoutEnabled') || false
  }

  get showEsignConsent() {
    return this.getItem('showEsignConsent') || false
  }
}

const instance = new I18nConfig()

export default instance
