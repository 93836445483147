import { i18n } from '_utils_/i18n'
import { APPOINTMENT_NOTE_TYPES } from '@/utils/constants.js'

const photosRequiredText = i18n('CASE_DETAILS__APPOINTMENT_NOTE__BULLET_PHOTOS')
const scanRequiredText = i18n('CASE_DETAILS__APPOINTMENT_NOTE__BULLET_SCAN')

const APPOINTMENT_NOTE_TYPES_WITH_BULLETS = {
  MCC: {
    bullets: [photosRequiredText, scanRequiredText],
    ...APPOINTMENT_NOTE_TYPES.MCC
  },
  REF: {
    bullets: [photosRequiredText, scanRequiredText],
    ...APPOINTMENT_NOTE_TYPES.REF
  },
  PhotoVisit: {
    bullets: [photosRequiredText],
    ...APPOINTMENT_NOTE_TYPES.PhotoVisit
  },
  NonPhotoVisit: {
    ...APPOINTMENT_NOTE_TYPES.NonPhotoVisit
  }
}

export { APPOINTMENT_NOTE_TYPES_WITH_BULLETS }
