<template>
  <section class="main-template">
    <div class="main-template__content">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainTemplate'
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, "tablet");
$desktop: map-get($sdc-breakpoints, "desktop");
$margin-side: 20px;

.main-template {
  height: 100vh;
  background-color: $white;
  display: flex;
  justify-content: center;

  @media screen and (min-width: $tablet) {
    background: $white url('../../../assets/login-background.png') center center no-repeat;
    background-size: cover;
  }

  &__content {
    width: 480px;

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin-left: $margin-side;
      margin-right: $margin-side;
    }
  }
}
</style>
