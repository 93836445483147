<template>
  <section class="medical-information">
    <div
      :class="[
        'is-flex',
        'is-flex-direction-column',
        'is-flex-grow-1'
      ]"
    >
      <SdcTitle level="4">
        {{ i18n('MEDICAL_INFORMATION_TITLE') }}
      </SdcTitle>
      <SdcTabs>
        <SdcTabItem
          :label="i18n('MEDICAL_INFORMATION__TREATMENT_PLANS_TITLE')"
          :value="'treatment-plans-tab'"
        >
          <TreatmentPlan
            v-for="(plan, index) in sortedTreatmentPlans"
            :id="`treatment-plan-${index}`"
            :key="index"
            :open="open == index"
            v-bind="plan"
            @open="open = index"
          />
        </SdcTabItem>
      </SdcTabs>
    </div>
  </section>
</template>

<script>
import { i18n } from '@/utils/i18n'
import SdcTitle from '@/components/atoms/SdcTitle'
import { SdcTabs, SdcTabItem } from '@/components/atoms/SdcTabs'
import TreatmentPlan from './TreatmentPlan.vue'

export default {
  name: 'MedicalInformation',
  components: {
    SdcTitle,
    SdcTabs,
    SdcTabItem,
    TreatmentPlan
  },
  props: {
    treatmentPlans: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: -1
    }
  },
  computed: {
    sortedTreatmentPlans() {
      return [...this.treatmentPlans]
        .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        .reverse()
    }
  },
  methods: {
    i18n
  }
}
</script>
<style lang="scss" scoped>
.treatment-plan:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
