import { triggerUpdateApp } from '_api_/interceptors/checkAppVersion'

export const mutationTypes = {
  markAsUpdated: 'markAsUpdated',
  setLastUserInteraction: 'setLastUserInteraction',
  setInactivityTime: 'setInactivityTime',
  setInteractionDelayerId: 'setInteractionDelayerId',
  setInactivityTimerId: 'setInactivityTimerId',
}

export const state = {
  appHasNewVersion: false,
  lastUserInteraction: new Date(),
  inactivityTime: 0,
  interactionDelayerId: 0,
  inactivityTimerId: 0,
}

export const mutations = {
  [mutationTypes.markAsUpdated](state) {
    state.appHasNewVersion = true
  },
  [mutationTypes.setLastUserInteraction](state) {
    state.lastUserInteraction = new Date()
  },
  [mutationTypes.setInactivityTime](state, payload) {
    state.inactivityTime = payload
    if (state.inactivityTime >= parseInt(process.env.VUE_APP_MIN_INACTIVE_TIME_TO_UPDATE_IN_MINUTES)) {
      triggerUpdateApp()
    }
  },
  [mutationTypes.setInteractionDelayerId](state, payload) {
    state.interactionDelayerId = payload
  },
  [mutationTypes.setInactivityTimerId](state, payload) {
    state.inactivityTimerId = payload
  },
}

export const actions = {
  resetLastInteraction({ commit, state, dispatch }) {
    if (!state.interactionDelayerId) {
      const id = setTimeout(() => {
        commit(mutationTypes.setLastUserInteraction)
        commit(mutationTypes.setInteractionDelayerId, 0)
        clearTimeout(state.interactionDelayerId)
        dispatch('handleInactivity', 0)
      }, 1000)
      commit(mutationTypes.setInteractionDelayerId, id)
    }
  },
  handleInactivity({ commit, state, dispatch }, payload) {
    if (!payload) payload = 1 // number in minutes
    clearTimeout(state.inactivityTimerId)
    const nextStop = (payload > 1 ? payload / 2 : 1) * 60000
    const id = setTimeout(() => {
      commit(mutationTypes.setInactivityTime, payload)
      dispatch('handleInactivity', payload * 2)
    }, nextStop)
    commit(mutationTypes.setInactivityTimerId, id)
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
}
