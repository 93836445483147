import { getTreatmentPlans } from '@/api/treatmentPlans.api'

export const mutationTypes = {
  setTreatmentPlans: 'setTreatmentPlans'
}

export const state = {
  treatmentPlans: []
}

export const getters = {
  treatmentPlans: (state) => state.treatmentPlans
}

export const mutations = {
  [mutationTypes.setTreatmentPlans](state, treatmentPlans) {
    state.treatmentPlans = treatmentPlans
  }
}

export const actions = {
  async loadTreatmentPlans({ commit, rootGetters }, caseId) {
    const isSuperuser = rootGetters['Auth/isSuperuser']
    const isDSODoctor = rootGetters['Auth/isDSODoctor']
    const isDentalStaff = rootGetters['Auth/isDentalStaff']
    if (isSuperuser || isDSODoctor || isDentalStaff) {
      const treatmentPlans = await getTreatmentPlans(caseId)
      commit(mutationTypes.setTreatmentPlans, treatmentPlans)
    }
  },
  clearTreatmentPlans({ commit }) {
    commit(mutationTypes.setTreatmentPlans, [])
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
