<template>
  <component
    :is="titleLevel"
    :class="titleClasses"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'SdcTitle',
  props: {
    level: {
      type: String,
      default: '1',
      validator(value) {
        return ['1', '2', '3', '4', '5', '6'].includes(value)
      }
    },
    marginLevel: {
      type: String,
      default: '',
      validator(value) {
        return ['', '1', '2', '3', '4', '5'].includes(value)
      }
    }
  },
  computed: {
    titleLevel() {
      return `h${this.level}`
    },
    titleClasses() {
      return `sdc-title sofia-pro-black-font`
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.sdc-title {
  color: $black;

  @at-root h4#{&} {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
</style>
