<template>
  <div class="address-detail">
    <div>{{ streetAddress }}</div>
    <div>{{ fullZipCode }}</div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
      validator: function (val) {
        return typeof val.streetAddress1 === 'string'
          && typeof val.city === 'string'
          && typeof val.state === 'string'
          && typeof val.zipCode === 'string'
      }
    },
  },
  computed: {
    streetAddress() {
      let { streetAddress1, streetAddress2 } = this.address
      streetAddress2 = (streetAddress2?.length > 0) ? `, ${streetAddress2}` : ''
      return `${streetAddress1}${streetAddress2}`
    },
    fullZipCode() {
      const { city, state, zipCode } = this.address
      return `${city}, ${state} ${zipCode}`
    }
  }
}
</script>
