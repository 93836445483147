<template>
  <div
    id="office-team-item"
    class="office-team-item"
  >
    <div class="office-team-item__fullname">
      {{ fullName }}
    </div>
    <div class="office-team-item__jobtitle-role">
      {{ getJobtitleRole(jobTitle, roleNames) }}
    </div>
    <div class="office-team-item__username">
      {{ userName }}
    </div>
    <div class="office-team-item__username">
      {{ getUserPracticeName(practices) }}
    </div>
    <div class="office-team-item__buttons">
      <sdc-button
        type="link"
        class="office-team-item__action-button"
        @click="showModal"
      >
        {{ i18n("OFFICE_TEAM__EDIT") }}
      </sdc-button>
      <sdc-button
        type="link"
        class="office-team-item__action-button"
        :disabled="!loadingCases"
        @click="showRemoveModal"
      >
        {{ i18n("OFFICE_TEAM__REMOVE") }}
      </sdc-button>
    </div>
    <team-member-modal
      ref="teamMemberModal"
      :user-name="userName"
    />
    <remove-team-member-modal
      ref="removeTeamMemberModal"
      :user-name="userName"
      :practices="practices"
    />
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { mapGetters, mapState } from 'vuex'
import TeamMemberModal from '_molecules_/TeamMemberModal'
import RemoveTeamMemberModal from '_molecules_/RemoveTeamMemberModal'
import { authConstants, snackbarDefaultSettings } from '@/utils/constants'
import SdcButton from '_atoms_/SdcButton'

export default {
  name: 'OfficeTeamItem',
  components: {
    TeamMemberModal,
    RemoveTeamMemberModal,
    SdcButton
  },
  props: {
    fullName: { type: String, required: true },
    userName: { type: String, required: true },
    jobTitle: { type: String, default: '' },
    roleNames: { type: Array, required: true },
    practices: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('Auth', ['canEditTeamMember', 'canRemoveTeamMember']),
    ...mapState('Cases', ['loadingCases']),
    ...mapState('Auth', ['authInfo']),
    userCanRemoveTeamMember() {
      return (
        this.canRemoveTeamMember &&
        this.authInfo.roles.some(
          role =>
            role === authConstants.roles.superuser ||
            role === authConstants.roles.ddvcStaff ||
            this.roleNames.includes(role) ||
            this.roleNames.includes(authConstants.roles.dentalStaff) ||
            this.roleNames.includes(authConstants.roles.ddvcStaff)
        )
      )
    }
  },
  methods: {
    i18n,
    getJobtitleRole(jobTitle, roleNames){
      const roleName = i18n(`ROLE_${roleNames[0]}__LABEL`) || roleNames[0]
      return `${jobTitle || ''} / ${roleName || ''}`
    },
    getUserPracticeName(practices) {
      return practices[0]?.name
    },
    showModal() {
      this.canEditTeamMember
        ? this.$refs.teamMemberModal.open()
        : this.toast({
            msg: i18n('OFFICE_TEAM__EDIT_USER_NOT_ALLOWED'),
            title: i18n('OFFICE_TEAM__TITLE')
          })
    },
    showRemoveModal() {
      this.userCanRemoveTeamMember
        ? this.$refs.removeTeamMemberModal.open()
        : this.toast({
            msg: i18n('OFFICE_TEAM__REMOVE_USER_NOT_ALLOWED'),
            title: i18n('OFFICE_TEAM__TITLE')
          })
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.btn:focus {
  box-shadow: none;
}

.btn-link:focus {
  text-decoration: none;
}

.office-team-item {
  border: 2px dashed $gray-88;
  border-style: solid;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  word-wrap: break-word;

  &__fullname {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
  }

  &__jobtitle-role {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    margin-bottom: 12px;
  }

  &__username {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    color: $gray-600;
  }

  &__buttons {
     margin-top: 10px;
     justify-content: left;
     display: flex;
  }

  &__action-button {
    margin-right: 14px;
    border-radius: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-family: "Sofia Pro Bold";
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    line-height: 1.125rem;
    color: $indigo;
    display: block;
    outline: none;
    &:hover {
     text-decoration: none;
    }
  }
}

</style>
