<template>
  <b-modal
    :id="componentName"
    :ref="componentName"
    :name="componentName"
    :active.sync="isActive"
    :can-cancel="canCancel"
    :width="modalWidth"
    :trap-focus="false"
    class="sdc-modal"
    auto-focus
  >
    <div class="modal-card">
      <header
        v-if="!hideHeader"
        class="modal-card-head"
      >
        <sdc-title
          v-if="titleProps"
          level="3"
        >
          {{ i18n(titleProps.text) }}
        </sdc-title>
        <button
          v-if="showCloseButton"
          class="modal-close-button"
          @click="close"
        >
          <smile-icon icon="x" />
        </button>
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer
        v-if="!hideFooter"
        class="modal-card-foot"
      >
        <sdc-button
          type="primary"
          class="sdc-modal-new__footer__button"
          size="large"
          outlined
          :disabled="loading"
          @click="onCancel"
        >
          {{ i18n(cancelButtonText) }}
        </sdc-button>
        <sdc-button
          type="primary"
          class="sdc-modal-new__footer__button"
          size="large"
          :disabled="loading"
          @click="onConfirm"
        >
          {{ i18n(confirmButtonText) }}
        </sdc-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import { SIZES } from './constants.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'SdcModal',
  components: {
    SdcButton,
    SdcTitle
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    },
    titleProps: {
      type: Object,
      default: null
    },
    buttonProps: { // it contains confirmProps & cancelProps: (text, other props)
      type: Object,
      default: null
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: false,
      modalWidth: SIZES[this.size]
    }
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    componentName() {
      return `sdc-modal-${this.name}`
    },
    confirmButtonText() {
      return this.buttonProps?.confirmProps?.text || 'LABEL__YES'
    },
    cancelButtonText() {
      return this.buttonProps?.cancelProps?.text || 'LABEL__NO'
    }
  },
  methods: {
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    i18n,
    onConfirm() {
      this.$emit('onConfirm', this.close)
    },
    onCancel() {
      this.close()
      this.$emit('onCancel')
    },
    open() {
      this.setLoadingStatus(false)
      this.isActive = true
    },
    close() {
      this.isActive = false
    }
  }
}
</script>
<style lang="scss">
@import '_theme_/_variables';

.sdc-modal {
  margin: 0 !important;
  & .modal-content {
    width: 95% !important;
    max-height: 95vh !important;
    overflow: hidden;
  }
}

.modal-card-head {
  display: flex;
  padding: 40px 40px 0px 40px !important;

  .modal-close-button {
    margin-left: auto;
    background-color: $white;
    border: 0;
    padding: 0;
    color: $primary;
    cursor: pointer;
  }
}
.modal-card-body {
  padding: 40px !important;
}
.modal-background {
  background-color: $black-40 !important;
}
.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
