<template>
  <b-navbar-dropdown
    id="dropdown-menu"
    right
  >
    <div v-if="!onlySignOutOption">
      <b-navbar-item tag="div">
        <span class="dropdown-menu__user">
          {{ userLogged.fullName }}
        </span>
      </b-navbar-item>
      
      <b-navbar-item tag="div">
        <span class="dropdown-menu__help">
          {{ userData.login }}
        </span>
      </b-navbar-item>

      <b-navbar-item tag="div">
        <span class="dropdown-menu__help">
          {{ role }}
        </span>
      </b-navbar-item>

      <hr class="overlay-menu-separator">

      <b-navbar-item
        v-if="canViewCaseDetails"
        class="dropdown-menu__item"
        tag="router-link"
        :to="{ path: '/home' }"
      >
        {{ i18n('MENU__CASES') }}
      </b-navbar-item>
      <b-navbar-item
        v-if="isAppointmentsAvailable"
        class="dropdown-menu__item"
        tag="router-link"
        :to="{ path: '/appointments' }"
      >
        {{ i18n('MENU__APPOINTMENTS') }}
      </b-navbar-item>
      <b-navbar-item
        v-if="isInsuranceUrlAvailable"
        class="dropdown-menu__item"
        tag="a"
        :href="insuranceUrl"
        target="_blank"
      >
        {{ i18n('MENU__INSURANCE') }}
      </b-navbar-item>
      <b-navbar-item
        class="dropdown-menu__item"
        tag="router-link"
        :to="{ path: '/account' }"
      >
        {{ i18n('MENU__ACCOUNT') }}
      </b-navbar-item>
    </div>
    <b-navbar-item
      class="dropdown-menu__item"
      tag="router-link"
      :to="{ path: '/logout' }"
    >
      {{ i18n('MENU__SIGNOUT') }}
    </b-navbar-item>

    <hr class="overlay-menu-separator">

    <b-navbar-item tag="div">
      <span class="dropdown-menu__help">
        {{ i18n('MENU__HELP') }}
      </span>
    </b-navbar-item>
    <b-navbar-item tag="div">
      <span class="dropdown-menu__support-number">
        {{ supportPhoneNumber }}
      </span>
    </b-navbar-item>
  </b-navbar-dropdown>
</template>

<script>
import { externalUrls } from '_utils_/constants'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DropdownMenu',
  props: {
    signOut: {
      type: Function,
      default: () => {}
    },
    onlySignOutOption: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      urls: externalUrls,
      insuranceUrl: I18nConfig.insuranceUrl,
      appointments: I18nConfig.appointments,
      phoneNumber: I18nConfig.supportPhoneNumber
    }
  },
  computed:{
    ...mapGetters('Auth', ['canViewCaseDetails','userData', 'roles']),
    ...mapState('Locale', ['currentLocale', 'i18nConfigLoaded']),
    ...mapState('Users', ['userLogged'] ),
    supportPhoneNumber(){
      return this.phoneNumber
    },
    isInsuranceUrlAvailable() {
      return this.insuranceUrl && this.insuranceUrl !== null
    },
    isAppointmentsAvailable() {
      return this.appointments
    },
    role() {
      return this.roles ? i18n(`ROLE_${this.roles[0]}__LABEL`) : ''
    }
  },
  watch: {
    i18nConfigLoaded() {
      this.insuranceUrl = I18nConfig.insuranceUrl
      this.phoneNumber = I18nConfig.supportPhoneNumber
      this.appointments = I18nConfig.appointments
    }
  },
  methods: {
    i18n
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';

.dropdown-menu {
  &__help {
    font-weight: $font-weight-bold;
    line-height: 18px;
  }

  &__support-number {
    color: $primary;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
  }

  &__item {
    color: $primary !important;
    font-family: $font-family-bold;
    font-size: $font-size-md;
    font-weight: $font-weight-normal;

    &:hover,
    &:active  {
      color: $indigo !important;
      text-decoration: none;
      border-radius: 4px;
      background-color: $extra-light-gray;
    }
  }

  &__user {
    font-weight: bold;
    font-family: $font-family-bold;
    font-size: $font-size-md;
  }
}
</style>
