import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ComponentLibrary from 'sdc-component-library'
import VueTelInput from 'vue-tel-input'
import VueSignaturePad from 'vue-signature-pad'
import { GlobalException } from '_utils_/Exceptions'

import './plugins/index'

Vue.config.errorHandler = GlobalException.getVueErrorHandler()
Vue.config.productionTip = false

Vue.use(ComponentLibrary)

import './theme/theme.scss'

Vue.use(VueTelInput)
Vue.use(VueSignaturePad)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
