<template>
  <div
    id="submit-appointment-summary"
    class="submit-appointment-summary"
  >
    <sdc-title
      class="submit-appointment-summary__title"
      level="4"
    >
      {{ title }}
    </sdc-title>
    <sdc-textarea
      v-model="dataValue"
      name="submit-appointment-summary"
      :placeholder="placeholder"
      :rules="sdcTextareaRules"
    />
  </div>
</template>

<script>
import SdcTextarea from '_atoms_/SdcTextarea'
import SdcTitle from '_atoms_/SdcTitle'

export default {
  name: 'SubmitAppointmentSummary',
  components: {
    SdcTextarea,
    SdcTitle
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dataValue: this.$attrs.value
    }
  },
  computed: {
    sdcTextareaRules() {
      return 'required|minCustom:10'
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.submit-appointment-summary {
  width: 100%;
  @media screen and (min-width: $mobile) {
    width: 49%;
  }
  margin-top: 40px;

  &__title {
    margin-bottom: 18px;
  }
}
</style>
