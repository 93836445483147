import ErrorException from './ErrorException'

class PracticesException extends ErrorException {
  constructor({ message, stack }) {
    super('PracticesException', message, stack)

    this.throwError()
  }
}

export default PracticesException
