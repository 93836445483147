import store from '_store_'
import I18nConfig from '_utils_/I18nConfig'
import Location from '_utils_/Location'
import { i18n } from '_utils_/i18n'
import { getMaintenanceStatus } from '_api_/utils.api'

store.dispatch('Auth/registerEvents')

const authGuard = async (to, from, next) => {
  const allowedPages = ['login', 'sent-email', 'reset-password']
  const isLoggedIn = await store.dispatch('Auth/isUserLoggedIn')

  if (isLoggedIn) {
    await Location.loadCountries()
    await store.dispatch('Features/loadFeatures')
    await I18nConfig.load()
    await store.dispatch('Auth/loadState')
  }

  if (to.name === 'login' && Location.redirectToLocaleDomain()) return

  if (!allowedPages.includes(to.name) && !isLoggedIn) {
    next({ name: 'login' })
    return
  }

  if(to.name === 'login' && isLoggedIn){
      next({ name: 'home' })
      return
  }

  if (to.name === 'home' && isLoggedIn) {
    const userHome = getHomeRoute()
    if (userHome !== 'home') {
      next({ name: userHome })
      return
    }
  }

  if (isLoggedIn && !userHasRoutePermissions(to.name)) {
    next({ name: 'error-forbidden', replace: true })
    return
  }

  if (to.name === 'appointments' && !I18nConfig.appointments) {
    next({ name: 'error-not-found', replace: true })
    return
  }

  if (await getMaintenanceStatus() && !userHasRoutePermissionsMaintenance(to.name)) {
    next({ name: 'error-maintenance', replace: true })
    return
  }

  store.commit('ErrorStatus/setNotFoundStatus', to.name === 'error-not-found')
  store.commit('ErrorStatus/setForbiddenStatus', to.name === 'error-forbidden')

  next()
}

const getHomeRoute = () => {
  if (!store.getters['Auth/hasRoles']) {
    return 'error-forbidden'
  }

  const needsToBeSigned = store.getters['Auth/needsToBeSigned']

  if (needsToBeSigned && store.getters['Auth/isPracticeDoctor']) {
    return 'consent-terms'
  }

  if (needsToBeSigned && store.getters['Auth/isDentalStaff']) {
    return 'staff-terms'
  }

  return store.getters['Auth/canViewCaseDetails'] ? 'home' : 'account'
}

const userHasRoutePermissions = (route) => {

  const canBypassSignValidation = store.getters['Auth/canBypassSignValidation']

  const routes = {
    'home': store.getters['Auth/canViewCaseDetails'] && canBypassSignValidation,
    'case-details': store.getters['Auth/canViewCaseDetails'] && canBypassSignValidation,
    'appointment-note': store.getters['Auth/canViewCaseDetails'] && canBypassSignValidation,
    'create-case': store.getters['Auth/canCreateCases'] && canBypassSignValidation,
    'create-correction': store.getters['Auth/canCreateCases'] && canBypassSignValidation,
    'create-refinement': store.getters['Auth/canCreateCases'] && canBypassSignValidation,
    'account': canBypassSignValidation
  }
  const result = routes[route]
  return (typeof result === 'boolean') ? result : true
}

const userHasRoutePermissionsMaintenance = (route) => {
  const routes = {
    'home': false,
    'case-details': false,
    'create-case': false,
    'create-refinement': false,
    'create-correction': false,
    'account': false,
    'appointments': false,
    'appointment-note': false
  }
  const result = routes[route]
  return (typeof result === 'boolean') ? result : true
}

const setMetaTitles = ((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  // Translate the title and set it
  if (nearestWithTitle) document.title = i18n(nearestWithTitle.meta.title)

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export {
  authGuard,
  setMetaTitles
}
