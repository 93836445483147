import { getSmilePayTerms, getSmilePayTermsPdf, createNewSmilePayTerms } from '_api_/terms.api'
import ErrorException from '@/utils/Exceptions/ErrorException'

export const mutationTypes = {
  clearState: 'clearState',
  setSmilePayTermsPDF: 'setSmilePayTermsPDF',
  setGeneratedTerms: 'setGeneratedTerms'
}

export const state = {
  smilePayTermsPDF: '',
  wasGeneratedTerms: false
}

export const mutations = {
  [mutationTypes.setSmilePayTermsPDF](state, payload) {
    state.smilePayTermsPDF = payload
  },
  [mutationTypes.setGeneratedTerms](state, payload) {
    state.wasGeneratedTerms = payload
  },
  [mutationTypes.clearState](state) {
    state.smilePayTermsPDF = ''
    state.wasGeneratedTerms = false
  },
}

export const actions = {
  async getSmilePayTerms({ dispatch, commit, state }, payload) {

    let createSmilePayResult

    dispatch('HttpRequest/setLoadingStatus', true, { root: true })

    try {
      payload.patientData.wasGeneratedTerms = state.wasGeneratedTerms
      const smilePayTermsId = await getSmilePayTerms(JSON.stringify(payload.patientData))
      const smilePayTermsPdf = await getSmilePayTermsPdf(smilePayTermsId.id)

      commit(mutationTypes.clearState)
      commit(mutationTypes.setSmilePayTermsPDF, smilePayTermsPdf)
      commit(mutationTypes.setGeneratedTerms, true)

      createSmilePayResult = { id: smilePayTermsId.id }
    } catch (error) {
      createSmilePayResult = false
    }

    dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    return createSmilePayResult
  },

  async setNewSmilePayTerms({ dispatch, commit }, payload) {
    dispatch('HttpRequest/setLoadingStatus', true, { root: true })

    try {
      const smilePayTermsPdf = await createNewSmilePayTerms(payload.applicationId)

      commit(mutationTypes.clearState)
      commit(mutationTypes.setSmilePayTermsPDF, smilePayTermsPdf)
      commit(mutationTypes.setGeneratedTerms, true)

    } catch (error) {
      throw new ErrorException('TermsException', error)
    } finally {
      dispatch('HttpRequest/setLoadingStatus', false, { root: true })
    }
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
