<template>
  <div>
    <sdc-button
      class="appointment_status_case_button__open"
      type="primary"
      @click="openModal"
    >
      {{ i18n(`APPOINTMENTS_STATUS__${statusId}`) }}
    </sdc-button>

    <sdc-modal
      ref="appointment-status-case-button-modal"
      name="appointment-status-case-button-modal"
      size="small"
      hide-header
      hide-footer
    >
      <appointment-status-create-case
        :email="email"
        :full-name="fullName"
        :case-uuid="caseUuid"
        @onCancel="onCancel"
        @onContinue="onContinue"
      />
    </sdc-modal>
  </div>
</template>

<script>
import SdcButton from '_atoms_/SdcButton'
import { i18n } from '_utils_/i18n'
import SdcModal from '_molecules_/SdcModal/index.vue'
import AppointmentStatusCreateCase from '_molecules_/AppointmentStatusCreateCase'

export default {
  name: 'AppointmentStatusCaseButton',
  components: {
    SdcModal,
    SdcButton,
    AppointmentStatusCreateCase
  },
  props: {
    statusId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    caseUuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    modalRef() {
      return this.$refs['appointment-status-case-button-modal']
    },
  },
  methods: {
    i18n,
    openModal() {
      this.modalRef.open()
    },
    onCancel() {
      this.modalRef.close()
    },
    onContinue() {
      this.modalRef.close()
    }
  }
}

</script>


<style lang="scss" scoped>
@import '_theme_/_variables';

.appointment_status_case_button {
  &__open {
    margin: 0;
    padding: 5px 8px 7px !important;
    font-weight: $font-weight-light;
    font-size: $font-size-sm * 0.75;
    line-height: 1;
    border-radius: 8px !important;
    border: 0;
  }
}

</style>
