<template>
  <div
    id="emailnotifications"
    class="emailnotifications"
  >
    <section class="section-panel-rounded">
      <div class="emailnotifications__header">
        <sdc-title level="3">
          {{ i18n('EMAIL_NOTIFICATIONS__TITLE') }}
        </sdc-title>
        <sdc-button
          type="primary"
          outlined
          class="emailnotifications__header__add-button"
          @click="showModal"
        >
          <smile-icon icon="plus" />
          <span>{{ i18n('EMAIL_NOTIFICATIONS__ADD_NEW') }}</span>
        </sdc-button>
      </div>
      <div
        v-if="!totalNotifications"
        class="emailnotifications__not-found"
      >
        {{ i18n('TEXT__NO_RESULTS_FOUND') }}
      </div>
      <div class="emailnotifications__list">
        <div
          v-for="emailNotificationItem in emailNotificationItemsPaginated"
          :key="emailNotificationItem.id"
          class="emailnotifications__list__item"
        >
          <email-notification-item
            :email-address="emailNotificationItem.email"
            :settings="emailNotificationItem.settings"
          />
        </div>
      </div>
      <div class="emailnotifications__pagination-container">
        <div>
          <paginator
            :total-items="totalNotifications"
            :items-per-page="pageSize"
            :current-page="currentPage"
            @pageChanged="onPageChanged"
          />
        </div>
      </div>
      <email-notification-modal
        ref="email-notification-modal"
      />
    </section>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import Paginator from '_molecules_/Paginator'
import EmailNotificationItem from '_molecules_/EmailNotificationItem'
import EmailNotificationModal from '_molecules_/EmailNotificationsModal'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EmailNotifications',
  components: {
    SdcTitle,
    SdcButton,
    Paginator,
    EmailNotificationItem,
    EmailNotificationModal
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      queryFilter: '',
      startItem: 0,
      finalItem: 10
    }
  },
  computed: {
    ...mapState('EmailNotification', ['emailNotificationItems']),
		emailNotificationItemsPaginated() {
      return Object.values(this.emailNotificationItems).slice(this.startItem, this.finalItem)
    },
    totalNotifications() {
      return Object.keys(this.emailNotificationItems).length
    },
    emailNotificationModal() {
      return this.$refs['email-notification-modal']
    }
  },
  async mounted() {
    await this.requestEmailNotifications()
  },
  methods: {
    i18n,
    ...mapActions('EmailNotification', ['requestEmailNotifications']),
    showModal() {
      this.emailNotificationModal.open()
    },
    hasSettings(emailNotificationItem) {
      return Object.keys(emailNotificationItem.settings).length > 0
    },
    onPageChanged(page) {
      this.currentPage = page
      this.startItem = (page - 1) * this.pageSize
			this.finalItem = this.startItem + this.pageSize
    }
  },
}
</script>

<style lang="scss" scoped>
@import "_theme_/_variables.scss";

$tablet: map-get($sdc-breakpoints, "tablet");
$mobile: map-get($sdc-breakpoints, "mobile");

.emailnotifications {
  display: flex;
  justify-content: center;

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &__add-button {
      height: 42px;
      margin-top: 8px;
      border-color: $gray-88;
      padding: 0px 20px 0px 20px;
      font-weight: $font-weight-bolder;

      @media screen and (max-width: $mobile) {
        margin-bottom: 12px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 100%;
      @media screen and (min-width: $tablet) {
        width: 49%;
      }
    }
  }

  &__not-found {
    text-align: center;
    padding: 0rem 0.75rem 0.8rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 12px;
  }
}
</style>
