import { InformationException } from '_utils_/Exceptions'
import { checkExistingEmail } from '_api_/utils.api'
import { getOrCreateCustomer } from '@/api/commerce.api'
import {
  getPatientInfo,
  getPatientEmailAvailableForMidcourseCorrectionOrRefinement
} from '_api_/patient.api'
import { getFormattedDate } from '_utils_/dateHelpers'
import * as payment from '@/store/modules/payment.store'
import createCaseMutationTypes from '@/store/modules/CreateCase/helper/mutationTypes'
import moment from 'moment'
import I18nConfig from '@/utils/I18nConfig'
import { countryDialCodes } from '@/utils/countryDialCodes'

const formatPhoneNumber = (patient) => {
  if (!patient.phoneNumber.startsWith('+') && patient.shippingAddress) {
    const countryData = countryDialCodes.find(
      (country) => country.isoCode3 === patient.shippingAddress.countryCode
    )
    if (countryData) {
      patient.phoneNumber = patient.phoneNumber.startsWith(countryData.dialCode)
        ? `+${patient.phoneNumber}`
        : `+${countryData.dialCode + patient.phoneNumber}`
    }
  }
}

const formatDateOfBirth = (dateOfBirth) => {
  const date = moment(dateOfBirth)
  const maxPatientAgeAllowed = 100
  const age = parseInt(moment(date, 'MMDDYYYY').fromNow(), 10)

  if (age > maxPatientAgeAllowed) {
    return { month: '', day: '', year: '' }
  }

  return {
    month: moment(dateOfBirth).format('MM'),
    day: moment(dateOfBirth).format('DD'),
    year: moment(dateOfBirth).format('YYYY')
  }
}

export const mutationTypes = {
  resetPatientInfo: 'resetPatientInfo',
  setPatientInfo: 'setPatientInfo',
  setPatientGuardianInfo: 'setPatientGuardianInfo',
  setGuardianStatus: 'setGuardianStatus',
  setExistingEmailStatus: 'setExistingEmailStatus',
  setInvalidEmailStatus: 'setInvalidEmailStatus',
  setEmailToValidate: 'setEmailToValidate',
  setCanPatientBeResumed: 'setCanPatientBeResumed',
  setCanGuardianBeResumed: 'setCanGuardianBeResumed',
  setPatientUuid: 'setPatientUuid',
  setErrorCodeEmailValidation: 'setErrorCodeEmailValidation',
  clearErrorCodeEmailValidation: 'clearErrorCodeEmailValidation',
  setShowPracticeAddressAsShippingAddressCheckbox:
    'setShowPracticeAddressAsShippingAddressCheckbox',
  setUsePracticeAddressAsShippingAddress:
    'setUsePracticeAddressAsShippingAddress',
  clearInfo: 'clearInfo'
}

export const state = {
  emailToValidate: '',
  patientInfo: {},
  patientGuardianInfo: {},
  isPatientGuardian: false,
  isExistingEmail: false,
  isInvalidEmail: false,
  canPatientBeResumed: true,
  canGuardianBeResumed: false,
  patientUuid: '',
  errorCodeEmailValidation: '',
  showPracticeAddressAsShippingAddressCheckbox: false,
  usePracticeAddressAsShippingAddress: false
}

export const mutations = {
  [mutationTypes.setPatientInfo](state, payload) {
    state.patientInfo = payload
  },
  [mutationTypes.resetPatientInfo](state, payload) {
    state.patientInfo.firstName = payload?.firstName || ''
    state.patientInfo.lastName = payload?.lastName || ''
    state.patientInfo.emailAddress = payload?.emailAddress || ''
    state.patientInfo.phoneNumber = payload?.phoneNumber || ''
    if (!state.patientInfo.dateOfBirth) {
      state.patientInfo.dateOfBirth = {}
    }

    state.patientInfo.dateOfBirth.day = payload?.dateOfBirth?.day || ''
    state.patientInfo.dateOfBirth.month = payload?.dateOfBirth?.month || ''
    state.patientInfo.dateOfBirth.year = payload?.dateOfBirth?.year || ''
  },
  [mutationTypes.setPatientGuardianInfo](state, payload) {
    state.patientGuardianInfo = payload
  },
  [mutationTypes.setGuardianStatus](state, payload) {
    state.isPatientGuardian = payload
  },
  [mutationTypes.setExistingEmailStatus](state, payload) {
    state.isExistingEmail = payload
  },
  [mutationTypes.setInvalidEmailStatus](state, payload) {
    state.isInvalidEmail = payload
  },
  [mutationTypes.setEmailToValidate](state, payload) {
    state.emailToValidate = payload.toLowerCase()
  },
  [mutationTypes.setCanPatientBeResumed](state, payload) {
    state.canPatientBeResumed = payload
  },
  [mutationTypes.setCanGuardianBeResumed](state, payload) {
    state.canGuardianBeResumed = payload
  },
  [mutationTypes.setPatientUuid](state, payload) {
    state.patientUuid = payload
  },
  [mutationTypes.setShowPracticeAddressAsShippingAddressCheckbox](
    state,
    payload
  ) {
    state.showPracticeAddressAsShippingAddressCheckbox = payload
  },
  [mutationTypes.setUsePracticeAddressAsShippingAddress](state, payload) {
    state.usePracticeAddressAsShippingAddress = payload
  },
  [mutationTypes.clearInfo](state) {
    state.emailToValidate = ''
    
    state.patientInfo.firstName = ''
    state.patientInfo.lastName = ''
    state.patientInfo.emailAddress = ''
    state.patientInfo.phoneNumber = ''
    if (!state.patientInfo.dateOfBirth) {
      state.patientInfo.dateOfBirth = {}
    }

    state.patientInfo.dateOfBirth.day = ''
    state.patientInfo.dateOfBirth.month = ''
    state.patientInfo.dateOfBirth.year = ''

    state.patientGuardianInfo = {}
    state.isPatientGuardian = false
    state.isExistingEmail = false
    state.isInvalidEmail = false
    state.canPatientBeResumed = true
    state.canGuardianBeResumed = false
    state.patientUuid = ''
    state.errorCodeEmailValidation = ''
  },
  [mutationTypes.setErrorCodeEmailValidation](state, payload) {
    state.errorCodeEmailValidation = payload
  },
  [mutationTypes.clearErrorCodeEmailValidation](state) {
    state.errorCodeEmailValidation = ''
  }
}

export const getters = {
  usePracticeAddressAsShippingAddress: (state) => {
    return state.usePracticeAddressAsShippingAddress
  },
  isCaseResumed: (state) => {
    return (
      !!state.patientUuid &&
      !state.isInvalidEmail &&
      (!state.isExistingEmail || state.canPatientBeResumed)
    )
  }
}

export const actions = {
  clearFinanceAgreementId({ commit }) {
    commit('CreateCase/clearFinanceAgreementId', null, { root: true })
  },
  async checkExistingEmail({ commit, state, dispatch }) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })
    try {
      let existingEmail = false
      const { patientInfo, patientGuardianInfo, emailToValidate } = state
      let requestData = {
        emailAddress: patientInfo.emailAddress.toLowerCase()
      }

      if (patientInfo.emailAddress === patientGuardianInfo.emailAddress) {
        commit('HttpRequest/setLoadingStatus', false, { root: true })
        return
      }
      if (emailToValidate !== requestData.emailAddress) {
        dispatch('CreateCase/resetCreateCaseFlow', null, { root: true })
        commit(mutationTypes.resetPatientInfo, requestData)
        commit(
          'CreateCase/resetPersonalInfo',
          {
            data: {
              emailAddress: requestData.emailAddress
            }
          },
          { root: true }
        )

        const {
          caseCanBeResumed,
          caseUuid,
          discountCode,
          financeAgreementId,
          hasAffiliateCode,
          patientUuid,
          photoAssessmentUuid,
          userExists
        } = await checkExistingEmail(requestData)

        commit(mutationTypes.setPatientUuid, patientUuid)
        commit(mutationTypes.setCanPatientBeResumed, caseCanBeResumed)
        commit(
          'CreateCase/setResumeCaseInfo',
          {
            caseId: caseUuid,
            assessmentId: photoAssessmentUuid,
            patientId: patientUuid,
            hasAffiliateCode,
            discountCode
          },
          { root: true }
        )

        commit('CreateCase/setFinanceAgreementId', financeAgreementId, {
          root: true
        })

        if (userExists && !caseCanBeResumed) {
          existingEmail = true
        } else {
          commit('Terms/clearState', {}, { root: true })
          commit(mutationTypes.setEmailToValidate, requestData.emailAddress)
        }

        commit(mutationTypes.setExistingEmailStatus, existingEmail)
      }
    } catch (error) {
      commit(mutationTypes.setInvalidEmailStatus, true)
    } finally {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },
  async checkExistingGuardianEmail({ commit, state }) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })
    try {
      let existingEmail = false
      const { patientInfo, patientGuardianInfo } = state
      let requestData = {
        emailAddress: patientInfo.emailAddress.toLowerCase()
      }

      if (state.isPatientGuardian) {
        requestData = {
          emailAddress: patientGuardianInfo.emailAddress.toLowerCase(),
          firstName: patientInfo.firstName,
          lastName: patientInfo.lastName,
          dateOfBirth: getFormattedDate(patientInfo.dateOfBirth)
        }
      }
      if (patientInfo.emailAddress === patientGuardianInfo.emailAddress) {
        commit('HttpRequest/setLoadingStatus', false, { root: true })
        return
      }
      if (state.emailToValidate !== requestData.emailAddress) {
        const { userExists } = await checkExistingEmail(requestData)

        if (userExists) {
          existingEmail = true
        } else {
          commit('Terms/clearState', {}, { root: true })
          commit(mutationTypes.setEmailToValidate, requestData.emailAddress)
        }

        commit(mutationTypes.setExistingEmailStatus, existingEmail)
      }

      commit('HttpRequest/setLoadingStatus', false, { root: true })
    } catch (error) {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
      commit(mutationTypes.setInvalidEmailStatus, true)
    }
  },
  async checkPatientEmailForMidcourseCorrection({ dispatch }) {
    await dispatch('checkPatientEmail')
  },
  async checkPatientEmailForRefinement({ dispatch }) {
    await dispatch('checkPatientEmail')
  },
  async checkPatientEmail({ commit, state, dispatch }) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })
    try {
      let existingEmail = false
      const { patientInfo } = state
      const emailAddress = patientInfo.emailAddress.toLowerCase()

      const emailResult =
        await getPatientEmailAvailableForMidcourseCorrectionOrRefinement(
          emailAddress
        )

      const { available, caseId, patient, error } = emailResult
      dispatch('CreateCase/resetCreateCaseFlow', null, { root: true })

      if (available) {
        commit(mutationTypes.setPatientUuid, patient.id)
        commit(mutationTypes.setCanPatientBeResumed, true)

        commit(
          'CreateCase/setResumeCaseInfo',
          {
            caseId: caseId,
            assessmentId: null,
            patientId: patient.id
          },
          { root: true }
        )
      } else {
        commit('Terms/clearState', {}, { root: true })
        commit(mutationTypes.setInvalidEmailStatus, true)
        commit(mutationTypes.setCanPatientBeResumed, false)
        commit(mutationTypes.setEmailToValidate, emailAddress)
        commit(mutationTypes.setErrorCodeEmailValidation, error)
      }

      commit(mutationTypes.setExistingEmailStatus, existingEmail)
    } catch (error) {
      commit(mutationTypes.setInvalidEmailStatus, true)
    } finally {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },
  async getPatientInfo({ commit }, patientUuid) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })
    try {
      const patientInfo = await getPatientInfo(patientUuid)
      commit('HttpRequest/setLoadingStatus', false, { root: true })
      return patientInfo
    } catch (error) {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
      throw new InformationException(error)
    }
  },
  async savePatientInformation({ state, commit, rootGetters }) {
    commit('HttpRequest/setLoadingStatus', true, { root: true })
    try {
      const isSuperUser = rootGetters['Auth/isSuperuser']
      const localPractices = rootGetters['Practices/getLocalPractices']
      let setSmsConsentPayload = {}
      if (!isSuperUser && !!localPractices.length) {
        const practice = localPractices[0]
        if (practice.countryCode === 'US' && practice.state === 'FL') {
          setSmsConsentPayload = { prechecked: false }
        }
      }

      commit(
        `CreateCase/${createCaseMutationTypes.setSmsConsent}`,
        setSmsConsentPayload,
        { root: true }
      )

      if (state.canPatientBeResumed) {
        const patient = await getPatientInfo(state.patientUuid)

        patient.emailAddress =
          patient.emailAddress || patient.guardian.emailAddress
        patient.phoneNumber =
          patient.phoneNumber === null
            ? patient.guardian.phoneNumber
            : patient.phoneNumber

        formatPhoneNumber(patient)

        const date = formatDateOfBirth(patient.dateOfBirth)

        const patientInfo = {
          firstName: patient.firstName,
          lastName: patient.lastName,
          emailAddress: patient.emailAddress,
          phoneNumber: patient.phoneNumber,
          dateOfBirth: {
            day: date.day,
            month: date.month,
            year: date.year
          }
        }

        commit(
          `CreateCase/${createCaseMutationTypes.setPersonalInfo}`,
          { data: patientInfo },
          { root: true }
        )

        if (patient.shippingAddress) {
          const { streetAddress1, streetAddress2, city, state, zipCode } =
            patient.shippingAddress

          if (
            streetAddress1.length > 0 &&
            city.length > 0 &&
            zipCode.length > 0 &&
            ((I18nConfig.hasRegions && state.length > 0) ||
              !I18nConfig.hasRegions)
          ) {
            commit(
              `CreateCase/${createCaseMutationTypes.setShippingAddress}`,
              {
                data: {
                  streetAddress1,
                  streetAddress2,
                  city,
                  state,
                  zipCode
                }
              },
              { root: true }
            )
          }
        }

        if (patient.guardian) {
          commit(mutationTypes.setCanGuardianBeResumed, true)

          const date = formatDateOfBirth(patient.guardian.dateOfBirth)

          const guardianInfo = {
            firstName: patient.guardian.firstName,
            lastName: patient.guardian.lastName,
            emailAddress: patient.guardian.emailAddress,
            phoneNumber: patient.guardian.phoneNumber,
            dateOfBirth: {
              day: date.day,
              month: date.month,
              year: date.year
            }
          }

          commit(
            `CreateCase/${createCaseMutationTypes.setGuardianInfo}`,
            { data: guardianInfo },
            { root: true }
          )
        }
      }

      const practiceAddressAsShippingAddress =
        !isSuperUser &&
        (state.canPatientBeResumed
          ? I18nConfig.practiceAddressAsDefaultForResumedCase
          : I18nConfig.practiceAddressAsDefaultForNewCases)

      commit(
        mutationTypes.setUsePracticeAddressAsShippingAddress,
        practiceAddressAsShippingAddress
      )
      commit(
        mutationTypes.setShowPracticeAddressAsShippingAddressCheckbox,
        practiceAddressAsShippingAddress
      )
    } catch (error) {
      throw new InformationException(error)
    } finally {
      commit('HttpRequest/setLoadingStatus', false, { root: true })
    }
  },
  async loadOrCreateCommerceCustomer({ commit, rootState }) {
    const email =
      rootState.CreateCase.guardianInfo?.emailAddress ||
      rootState.CreateCase.personalInfo?.emailAddress
    const { customerId, hasPurchased, paymentBrokerAccountId } =
      await getOrCreateCustomer({ email })

    commit(
      `Payment/${payment.mutationTypes.setPaymentBrokerAccountId}`,
      paymentBrokerAccountId,
      { root: true }
    )
    commit(
      `Payment/${payment.mutationTypes.setCommerceCustomerId}`,
      customerId,
      { root: true }
    )
    commit('CreateCase/setHasPurchased', hasPurchased, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  keysToPersist: '*'
}
