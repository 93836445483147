<template>
  <div class="resend-password">
    <p class="resend-password__text">
      {{ i18n('SENT_EMAIL_QUESTION_RESEND') }}
    </p>
    <sdc-button
      type="primary"
      outlined
      class="resend-password__button"
      @click="sendEmail"
    >
      {{ i18n('SENT_EMAIL_RESEND') }}
    </sdc-button>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import { recoverPassword } from '_api_/utils.api.js'
import SdcButton from '_atoms_/SdcButton'
import { snackbarDefaultSettings } from '@/utils/constants'

export default {
  name: 'ResendPassword',
  components: {
    SdcButton
  },
  data() {
    return {
      emailAddress: ''
    }
  },
  mounted() {
    if(this.$route.params.emailAddress) {
      this.emailAddress = this.$route.params.emailAddress
    } else {
      this.$router.push({ name: 'reset-password' })
    }
  },
  methods: {
    i18n,
    toast() {
      this.$sdcToast.open({title: i18n('TEXT__SUCCESS'), content: i18n('SENT_EMAIL_SUCCESS')})
    },
    async sendEmail() {
      await recoverPassword(this.emailAddress)
      this.toast()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '_theme_/_variables';

.resend-password {
  &__text {
    font-weight: 900;
    line-height: 20px;
    letter-spacing: -0.25px;
  }

  &__button {
    font-weight: $font-weight-bold;
  }
}
</style>
