<script>
export default {
    open(params, library) {
      if (typeof params === 'string') {
        params = {
          content: params
        };
      }

      const title = typeof params.title === 'string' ? `<div class="sdc-toast__title">${params.title}</div>` : ''
      const content = typeof params.content === 'string' ? `<div class="sdc-toast__content">${params.content}</div>` : ''

      this.type = `sdc-toast ${(typeof params.type === 'string' ? params.type : "is-white")}`;
      this.message = title + content;
      this.duration = typeof params.duration === 'number' ? params.duration : 5000;
      this.position = typeof params.position === 'string' ? params.position : 'is-top-right';
      this.queue = !!params.queue || false;
      this.show = true
      this.indefinite = params.indefinite

      library.snackbar.open({
        message: this.message,
        type: this.type,
        position: this.position,
        actionText: this.actionText,
        onAction: this.finish,
        duration: this.duration,
        indefinite: this.indefinite,
        queue: this.queue
      });
    },
    finish(value) {
      this.show = value
    }
};
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-toast {
  font-family: $font-family-base,serif;
  box-shadow: 0 0.25rem 0.75rem rgba($gray-900, 0) !important;

  &__title {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    padding: 0.75rem;
  }

  &__content {
    font-size: $font-size-sm;
    padding: 0.75rem;
  }
}

</style>
