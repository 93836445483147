<template>
  <div
    :key="updateKey"
    class="container"
    name="creation-container"
  >
    <sdc-loading
      v-if="submitingCase"
      :title="i18n('LOADING__CREATE_CASE_TITLE')"
    />
    <section class="creation-container--header">
      <sdc-title level="2">
        {{ i18n('CREATE_REFINEMENT_CASE__TITLE') }}
      </sdc-title>
      <sdc-button
        type="link"
        :class="sdcButtonClass"
        @click="handleCancel"
      >
        {{ i18n('LABEL__CANCEL') }}
      </sdc-button>
      <no-doctors-modal ref="noDoctorsModal" />
    </section>
    <purpose-of-visit />
    <section class="section-panel-rounded">
      <stepper
        v-bind="stepperProps"
        @setCurrentStep="cleanAppointmentNote"
      />
    </section>
  </div>
</template>

<script>
// TODO: REFACTOR WITH OTHER CONTAINERS
import {
  CASE_RESPONSE_CODES,
  createCaseConstants,
  IMPRESSION_MODES
} from '@/utils/constants'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import PurposeOfVisit from '_molecules_/PurposeOfVisit'
import Stepper from '_molecules_/Stepper'
import RefinementQuestion from '_organisms_/RefinementQuestion'
import RefinementPhotos from '_organisms_/RefinementPhotos'
import RefinementSubmit from '_organisms_/RefinementSubmit'
import RefinementEmail from '_organisms_/RefinementEmail'
import SdcLoading from '_atoms_/SdcLoading'
import SdcButton from '_atoms_/SdcButton'
import SdcTitle from '_atoms_/SdcTitle'
import RefinementInformation from '_organisms_/RefinementInformation'
import NoDoctorsModal from '_molecules_/NoDoctorsModal'

const getToastMessagekeys = (caseResult, supportPhoneNumber, impressionMode) =>  {
  if (caseResult[CASE_RESPONSE_CODES.error]) {
    return [
      supportPhoneNumber ? 'CREATE_CASE__SUBMIT_ERROR' : 'CREATE_CASE__SUBMIT__DEFAULT_ERROR'
    ]
  }
  const messages = []
  if (caseResult[CASE_RESPONSE_CODES.warning]) {
    messages.unshift(supportPhoneNumber ? 'CREATE_CASE__SUBMIT_WARNING' : 'CREATE_CASE__SUBMIT__DEFAULT_WARNING')
  } else {
    messages.unshift('CREATE_CASE__SUBMIT_SUCCESS')
  }
  if (I18nConfig.showSendPatientImpressionNotification && impressionMode === IMPRESSION_MODES.manual) messages.unshift('CREATE_CASE__SUBMIT__SEND_PATIENT_IMPRESSION')
  return messages
}

export default {
  name: 'RefinementContainer',
  components: {
    SdcButton,
    Stepper,
    SdcLoading,
    SdcTitle,
    NoDoctorsModal,
    PurposeOfVisit
  },
  data() {
    return {
      sdcButtonClass: 'creation-container-header--cancel text-decoration-none',
      submitingCase: false,
      updateKey: 0
    }
  },
  computed: {
    ...mapState('CreateCase', ['practiceId', 'submitInfo']),
    ...mapGetters('CreateCase', ['getDoctorsForCase']),
    ...mapGetters('CaseDetails', ['hasAppointmentNote', 'appointmentNoteByCase']),
    stepperProps() {
      return {
        handleSubmit: this.handleSubmit,
        steps: this.loadSteps()
      }
    },
    isRedirectedFromCaseDetails() {
      return !!this.$route.params.redirectFromCaseDetails
    },
    isRedirectFromAppointmentStatus() {
      return !!this.$route.params.redirectFromAppointmentStatus
    }
  },
  async mounted() {
    this.setFlowType(createCaseConstants.flowType.REFINEMENT)

    this.init().then(() => {
      // If the case has a practice id, but the list of doctors is empty, warn the user to verify the team
      if (this.practiceId !== null && this.getDoctorsForCase.length === 0) {
        this.$refs.noDoctorsModal.open()
      }
    })

    if (this.isRedirectedFromCaseDetails) {
      this.setPatientInfo({
        emailAddress: this.appointmentNoteByCase.email
      })
      await this.checkPatientEmailForRefinement()
      await this.savePatientInformation()
      this.updateKey += 1
    }
    if (this.isRedirectFromAppointmentStatus) {
      this.updateKey += 1
    }
  },
  destroyed() {
    this.clearData()
    this.setAppointmentNoteByCase(null)
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['clearState', 'setFlowType','clearBoId', 'clearPracticeId']),
    ...mapMutations('InformationForm', ['clearInfo', 'setPatientInfo']),
    ...mapMutations('Photos', ['clearPatientPhotos']),
    ...mapMutations('CaseDetails', ['setAppointmentSummary', 'setAppointmentNoteByCase']),
    ...mapActions('CreateCase', ['init','updateCaseMidcourseCorrectionOrRefinement', 'resetCreateCaseFlow']),
    ...mapActions('InformationForm', ['checkPatientEmailForRefinement', 'savePatientInformation']),
    ...mapActions('CaseDetails', ['createAppointmentNoteByCase']),
    validateStep({ model, next }) {
      if (model.validate) {
        model.validate(next)
      }
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
    clearData() {
      this.clearState()
      this.clearBoId()
      this.clearPracticeId()
      this.clearInfo()
      this.clearPatientPhotos()
    },
    async handleSubmit() {

      if (this.submitingCase) {
        return
      }

      this.submitingCase = true

      const caseResult = await this.updateCaseMidcourseCorrectionOrRefinement()
      const supportPhoneNumber = I18nConfig.supportPhoneNumber
      getToastMessagekeys(caseResult, supportPhoneNumber, this.submitInfo?.order.type).forEach(message => {
        this.toast({
          msg: i18n(message, [supportPhoneNumber]),
          title: i18n('CREATE_REFINEMENT_CORRECTION__TITLE')
        })
      })

      if (caseResult.error) return false
      await this.setAppointmentSummary(this.submitInfo.caseNotes)
      await this.createAppointmentNoteByCase(caseResult.id)
      this.resetCreateCaseFlow()

      this.$router.push({
        name: 'case-details',
        params: {
          id: caseResult.id,
          safeToLeaveRoute: true
        }
      })

      this.submitingCase = false
    },
    handleCancel() {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    loadSteps() {
      const steps = [
        {
          name: 'RefinementEmail',
          component: RefinementEmail,
          title: i18n('CREATE_REFINEMENT_CASE__STEP_ONE'),
          validate: this.validateStep
        },
        {
          name: 'RefinementInformation',
          component: RefinementInformation,
          title: i18n('CREATE_REFINEMENT_CASE__STEP_TWO'),
          validate: this.validateStep
        },
        {
          name: 'RefinementQuestion',
          component: RefinementQuestion,
          title: i18n('CREATE_REFINEMENT_CASE__STEP_THREE'),
          validate: this.validateStep
        },
        {
          name: 'RefinementPhotos',
          component: RefinementPhotos,
          title: i18n('CREATE_REFINEMENT_CASE__STEP_FOUR'),
          validate: this.validateStep
        },
        {
          name: 'RefinementSubmit',
          component: RefinementSubmit,
          title: i18n('LABEL__SUBMIT'),
          validate: this.validateStep
        }
      ]

      if (this.hasAppointmentNote && this.isRedirectedFromCaseDetails) {
        steps.splice(0, 1)
      }

      return steps
    },
    cleanAppointmentNote(currentStep) {
      if (currentStep === 'RefinementEmail') {
        this.setAppointmentNoteByCase(null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/components/_sdc-modal';

.creation-container {
  &--header {
    display: flex;
    justify-content: space-between;

    &--cancel {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
