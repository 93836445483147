import { createCaseConstants } from '_utils_/constants'

export default {
  getDoctorsForCase: (state, _, __, rootGetters) => {
    if (!state.boId || !state.practiceId) {
      return []
    }
    return rootGetters['Doctors/listByPractice']({
      boId: state.boId,
      practiceId: state.practiceId
    })
  },
  smilePayApplicationPayload(state) {
    const {
      caseId,
      personalInfo,
      questionInfo,
      guardianInfo,
      shippingAddress
    } = state
    const payload = {
      applicationId: questionInfo.financeAgreementId || '',
      patient: {
        ...personalInfo,
        dateOfBirth: formatDate(personalInfo.dateOfBirth),
        guardian: validatedGuardian(guardianInfo),
        shippingAddress: {
          ...shippingAddress
        }
      }
    }

    if (!!caseId) {
      payload.caseId = caseId
    }

    return payload
  },
  getSubmittingDoctorUserName: (state) => {
    return state.submitInfo?.submittingDoctor?.userName
  },
  isMidcourseCorrectionOrRefinement: (state) => {
    return [
      createCaseConstants.flowType.MIDCOURSE_CORRECTION,
      createCaseConstants.flowType.REFINEMENT
    ].includes(state.flowType)
  },
  isMidcourseCorrection: (state) => {
    return state.flowType === createCaseConstants.flowType.MIDCOURSE_CORRECTION
  },
  isRefinement: (state) => {
    return state.flowType === createCaseConstants.flowType.REFINEMENT
  },
  practiceId: (state) => {
    return state.practiceId
  }
}

/**
 *
 * @param {{
 *  firstName: String,
 *  lastName: String,
 *  dateOfBirth: String,
 *  emailAddress: String,
 *  phoneNumber: String
 * }} guardian
 * @returns {{
 *  firstName: String,
 *  lastName: String,
 *  dateOfBirth: String,
 *  emailAddress: String,
 *  phoneNumber: String
 * }}
 */
const validatedGuardian = ({
  firstName,
  lastName,
  dateOfBirth,
  emailAddress,
  phoneNumber
}) => {
  return !!firstName
    ? {
        firstName,
        lastName,
        dateOfBirth: formatDate(dateOfBirth),
        emailAddress,
        phoneNumber
      }
    : null
}

const formatDate = (date) => {
  if (date) {
    const { day, month, year } = date
    return `${year}-${month}-${day}`
  }
  
  return ''
}
