<template>
  <b-field>
    <b-tag class="sdc-badge">
      <slot />
    </b-tag>
  </b-field>
</template>

<script>
export default {
  name: 'SdcBadge'
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

.sdc-badge {
  padding: 5px 8px 7px;
  font-weight: $font-weight-light;
  color: $black;
  background-color: $extra-light-gray;
  white-space: normal;
  text-align: left;
  font-size: 75%;
  line-height: 1;
  display: table;
}
</style>
