<template>
  <div id="submit">
    <create-case-step-form ref="submitForm">
      <submit-initial-impression
        v-model="submitInfo.order.type"
      />
      <div class="submit-container">
        <submit-practice-info
          v-if="isSuperuser"
          class="submit-container__item"
        />
        <submit-doctor-info class="submit-container__item" />
      </div>
      <submit-additional-info
        v-if="!hasAppointmentNote"
        v-model="submitInfo.caseNotes"
        :placeholder="i18n('SUBMIT__ADDITIONAL_NOTES_TEXTBOX_PLACEHOLDER')"
        :title="i18n('SUBMIT__ADDITIONAL_NOTES_TITLE')"
      />
      <submit-appointment-summary
        v-if="hasAppointmentNote"
        v-model="submitInfo.caseNotes"
        class="submit-appointment-summary"
        :placeholder="i18n('SUBMIT__APPOINTMENT_SUMMARY_PLACEHOLDER')"
        :title="i18n('SUBMIT__APPOINTMENT_SUMMARY_TITLE')"
      />
      <fast-track-aligner-preference v-if="isMidcourseCorrectionOrRefinement && enabledForCountry" />
    </create-case-step-form>
    <notification-modal
      ref="notificationModal"
      v-bind="notificationModalProps"
    />
    <sdc-modal
      v-if="isMidcourseCorrectionOrRefinement"
      ref="confirmationModal"
      name="submit-confirmation-modal"
      v-bind="confirmationModalProps"
    >
      {{ i18n('SUBMIT__CONFIRMATION_MODAL__BODY') }}
    </sdc-modal>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import SubmitDoctorInfo from '_molecules_/SubmitDoctorInfo'
import SubmitPracticeInfo from '_molecules_/SubmitPracticeInfo'
import SubmitInitialImpression from '_molecules_/SubmitInitialImpression'
import SubmitAdditionalInfo from '_molecules_/SubmitAdditionalInfo'
import SubmitAppointmentSummary from '_molecules_/SubmitAppointmentSummary'
import NotificationModal from '_molecules_/NotificationModal'
import FastTrackAlignerPreference from '_molecules_/FastTrackAlignerPreference'
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import Location from '_utils_/Location'
import { localeConstants } from '_utils_/constants'
import SdcModal from '_molecules_/SdcModal'

export default {
  name: 'Submit',
  components: {
    CreateCaseStepForm,
    SubmitInitialImpression,
    SubmitDoctorInfo,
    SubmitPracticeInfo,
    SubmitAdditionalInfo,
    SubmitAppointmentSummary,
    NotificationModal,
    FastTrackAlignerPreference,
    SdcModal
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('Auth', ['isSuperuser']),
    ...mapState('CreateCase', ['submitInfo']),
    ...mapGetters('CreateCase', ['isMidcourseCorrectionOrRefinement']),
    ...mapGetters('CaseDetails', ['appointmentNoteByCase', 'hasAppointmentNote']),
    enabledForCountry() {
      return Location.countryCode === localeConstants.defaultCountryCode
    },
    notificationModalProps(){
        return {
          titleProps: {
            text: 'CREATE_CASE__TITLE_PASS_RECEPTIONIST_NOTIFICATION_MODAL',
            level: '3'
          }
        }
    },
    confirmationModalProps() {
      return {
        titleProps: {
          text: 'SUBMIT__CONFIRMATION_MODAL__TITLE'
        },
        buttonProps: {
          confirmProps: {
            text: 'SUBMIT__CONFIRMATION_MODAL__CONFIRM_BUTTON'
          },
          cancelProps: {
            text: 'LABEL__CANCEL'
          }
        }
      }
    }
  },
  beforeMount() {
    this.registerModel()
  },
  methods: {
    i18n,
    ...mapMutations('CreateCase', ['setSubmitInfo']),
    registerModel() {
      this.model.validate = this.validateForm
    },
    async validateForm(next) {
      const formResult = await this.$refs.submitForm.validateForm()

      if (!formResult) {
        return
      }

      this.setSubmitInfo({ data: this.submitInfo })
      if(I18nConfig.showPassReceptionistNotification) {
        const modal = this.$refs.notificationModal
        modal.open()
        modal.$on('onAccept', async () => await next())
        return
      }

      if (this.isMidcourseCorrectionOrRefinement) {
        const modal = this.$refs.confirmationModal
        modal.open()
        modal.$on('onConfirm', async () => {
          modal.close()
          await next()
        })
        return
      }

      await next()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';
$mobile: map-get($sdc-breakpoints, 'mobile');

.submit-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  &__item {
    width: 100%;
    @media screen and (min-width: $mobile) {
      width: 49%;
    }
  }
}
</style>
