<template>
  <div id="email">
    <create-case-step-form ref="emailForm">
      <email-form
        id="email-info"
        v-model="personalInfo"
        name="email-info"
        :title="title"
        :label="label"
        :email-field-rules="emailFieldRules"
      >
        <template slot="Message">
          <slot name="Message" />
        </template>
      </email-form>
    </create-case-step-form>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import CreateCaseStepForm from '_templates_/CreateCaseStepForm'
import EmailForm from '_molecules_/EmailForm'

export default {
  name: 'Email',
  components: {
    EmailForm,
    CreateCaseStepForm
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    checkEmail: {
      type: Function,
      default: () => {}
    },
    emailFieldRules: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localEmailAddress: ''
    }
  },
  computed: {
    ...mapGetters('Auth', ['isSuperuser']),
    ...mapGetters('CreateCase', ['isMidcourseCorrectionOrRefinement']),
    ...mapGetters('CaseDetails', ['hasAppointmentNote']),
    ...mapState('InformationForm', [
      'patientInfo',
      'isExistingEmail',
      'isInvalidEmail',
      'canPatientBeResumed'
    ]),
    ...mapGetters('InformationForm', ['isCaseResumed']),
    ...mapState('CreateCase', ['personalInfo', 'guardianInfo', 'shippingAddress', 'caseId']),
  },
  watch: {
    model(newVal) {
      this.$emit('input', newVal)
    }
  },
  beforeMount() {
    this.registerModel()
    this.setPatientGuardianInfo(this.guardianInfo)
    this.setPatientInfo(this.personalInfo)
    this.setCanGuardianBeResumed(false)
    this.resetEmailValidation()
  },
  methods: {
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    ...mapMutations('InformationForm', [
      'setPatientInfo',
      'setPatientGuardianInfo',
      'setExistingEmailStatus',
      'setInvalidEmailStatus',
      'setCanGuardianBeResumed'
    ]),
    ...mapActions('InformationForm', ['savePatientInformation']),
    ...mapActions('CaseDetails', ['getAppointmentNoteByCase']),
    resetEmailValidation() {
      this.setExistingEmailStatus(false)
      this.setInvalidEmailStatus(false)
    },
    registerModel() {
      this.model.validate = this.validateForm
    },
    async validateForm(next) {
      const emailForm = this.$refs.emailForm
      const formResult = await emailForm.validateForm()
      if (!formResult) {
        return
      }

      await this.checkEmail()

      if (
        (this.isExistingEmail && !this.canPatientBeResumed) ||
        this.isInvalidEmail
      ) {
        return
      }

      await this.savePatientInformation()

      if (!this.hasAppointmentNote && this.isMidcourseCorrectionOrRefinement) {
        await this.getAppointmentNoteByCase(this.caseId)
      }

      next()
    }
  }
}
</script>
