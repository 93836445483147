import { sdcInstanceProvider as api } from './sdc.config.api'
import Logger from '@/utils/Logger'

const getRoles = async () => {
  try {
    const response = await api.get('/roles')
    return response.data
  } catch (error) {
    Logger.error('Roles API - getRoles', error)
    throw error
  }
}

export {
  getRoles
}
