var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sdc-radio-card-content',
    'card card-content-padding',
    'justify-center',
    'mt-5',
    'has-border',
    {
      'selected': _vm.selected,
    },
  ]},[_c('smile-media-card',{staticClass:"sdc-radio-card",scopedSlots:_vm._u([{key:"card-content",fn:function(){return [_c('div',{class:[
          'is-flex',
          'is-align-items-center',
          'is-justify-content-space-between'
        ]},[_vm._t("default")],2)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }