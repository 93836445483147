<template>
  <div class="appointment-status-create-case">
    <div class="appointment-status-create-case__row">
      {{ i18n('APPOINTMENT_STATUS_CREATE_CASE__DISCLAIMER', [ fullName ]) }}
    </div>
    <div class="appointment-status-create-case__footer">
      <sdc-button
        class="appointment-status-create-case__footer__button"
        type="primary"
        outlined
        data-test="cancel-button"
        @click="onCancel"
      >
        {{ i18n('APPOINTMENT_STATUS_CREATE_CASE__CANCEL_BUTTON') }}
      </sdc-button>
      <sdc-button
        class="appointment-status-create-case__footer__button practice-form__footer__button"
        type="primary"
        :disabled="loading"
        data-test="continue-button"
        @click="onContinue"
      >
        {{ i18n('APPOINTMENT_STATUS_CREATE_CASE__CONTINUE_BUTTON') }}
      </sdc-button>
    </div>
  </div>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcButton from '_atoms_/SdcButton'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { APPOINTMENT_NOTE_TYPES } from '@/utils/constants.js'
export default {
  name: 'AppointmentStatusCreateCase',
  components: {
    SdcButton
  },
  props: {
    email     : { type: String, required: true },
    fullName  : { type: String, required: true },
    caseUuid  : { type: String, required: true },
  },
  computed: {
    ...mapState('HttpRequest', ['loading']),
    ...mapState('CreateCase', ['personalInfo', 'guardianInfo','caseId']),
    ...mapGetters('InformationForm', ['isCaseResumed']),
    ...mapGetters('CaseDetails', ['hasAppointmentNote', 'appointmentNoteByCase']),
  },
  methods: {
    i18n,
    ...mapActions('InformationForm', ['checkExistingEmail','savePatientInformation']),
    ...mapActions('CaseDetails', ['getAppointmentNoteByCase']),
    ...mapMutations('CreateCase', ['setPersonalInfo']),
    ...mapMutations('CaseDetails', ['setCaseId']),
    ...mapMutations('InformationForm', ['setPatientInfo','setPatientGuardianInfo']),
    async onContinue() {
      this.setPersonalInfo({ data: { ...this.personalInfo, emailAddress: this.email }})
      this.setPatientGuardianInfo(this.guardianInfo)
      this.setPatientInfo(this.personalInfo)
      this.setCaseId(this.caseUuid)

      await this.checkExistingEmail()
      await this.getAppointmentNoteByCase(this.caseUuid)

      this.$emit('onContinue')

      if (this.isCaseResumed) {
        await this.savePatientInformation()
      }

      const redirectPathName = (this.hasAppointmentNote) ?
        APPOINTMENT_NOTE_TYPES[this.appointmentNoteByCase.type].redirectPathName :
        'create-case'

      await this.$router.push({
        name: redirectPathName,
        params: {
          redirectFromAppointmentStatus: true,
          safeToLeaveRoute: true,
          id: this.caseUuid
        }
      })
    },
    onCancel() {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.appointment-status-create-case {
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__input {
      width: 100%;

      @media screen and (min-width: $mobile) {
        width: 49%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-light;
      font-family: $font-family-bold;
      margin: 8px;
      padding: 15px 30px;
    }
  }
}

</style>
