import ErrorException from './ErrorException'

class InformationException extends ErrorException {
  constructor({ message, stack }) {
    super('InformationException', message, stack)

    this.throwError()
  }
}

export default InformationException
