<template>
  <div class="scan-intake-upload-file-container">
    <sdc-title
      level="5"
      margin-level="3"
    >
      <span>{{ i18n(`SCAN_INTAKE_SECTION__UPLOAD__${category.toUpperCase()}__TITLE`) }}</span>
    </sdc-title>
    <sdc-file-upload
      v-slot="{ triggerSelectFile }"
      :name="`${category}-file`"
      :max-size="MAX_SIZE"
      :accept="ACCEPT_TYPES"
      :handle-upload="handleAddFile"
      :show-error-messages="false"
      @error="handleError"
    >
      <scan-intake-add-file
        class="scan-intake-upload-file-container__add-file"
        :name="category"
        :on-trigger-select-file="triggerSelectFile"
        :file="file"
      />
    </sdc-file-upload>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { addScanFile } from '_api_/scanIntake.api'
import SdcTitle from '_atoms_/SdcTitle'
import SdcFileUpload from '_molecules_/SdcFileUpload'
import { i18n } from '_utils_/i18n'
import { MAX_SIZE, ACCEPT_TYPES } from '_utils_/scanIntakeConstants'
import ScanIntakeAddFile from '_molecules_/ScanIntakeAddFile'

export default {
  name: 'ScanIntakeUploadFileContainer',
  components: {
    SdcTitle,
    SdcFileUpload,
    ScanIntakeAddFile
  },
  props: {
    category: {
      type: String,
      required: true
    },
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('CaseDetails', ['details'])
  },
  created() {
    this.MAX_SIZE = MAX_SIZE
    this.ACCEPT_TYPES = ACCEPT_TYPES
    this.mounted = true
  },
  beforeDestroy() {
    this.mounted = false
  },
  methods: {
    ...mapMutations('ScanIntake', ['setAddedFile']),
    i18n,
    handleError(errorCode) {
      this.$emit('error', errorCode)
    },
    async handleAddFile(file) {
      const { caseNumber } = this.details
      try {
        const response =  await addScanFile({
          file,
          category: this.category,
          caseNumber
        })
        if (!this.mounted) return
        this.setAddedFile({
          file: {
            name: response.name,
            size: response.size
          },
          category: this.category
        })
      } catch(error) {
        this.setAddedFile({
          file: {},
          category: this.category
        })
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scan-intake-upload-file-container {
  &__add-file {
    margin: 40px;
  }
}
</style>
