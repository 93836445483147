<template>
  <validation-observer ref="sendShippingLabelForm">
    <form>
      <div class="send-shipping-label-form__email">
        <div class="send-shipping-label-form__email__control">
          <validated-input
            v-model="email"
            name="email"
            type="email"
            rules="required|email"
            :label="i18n('LOGIN__LABEL_EMAIL')"
          />
        </div>
      </div>
      <div class="send-shipping-label-form__footer">
        <sdc-button
          id="shipping-label-send-form-cancel"
          class="send-shipping-label-form__footer__button"
          type="primary"
          outlined
          @click="onCancel"
        >
          {{ i18n('LABEL__CANCEL') }}
        </sdc-button>
        <sdc-button
          id="shipping-label-send-form-submit"
          class="send-shipping-label-form__footer__button"
          type="primary"
          @click="onSubmit"
        >
          {{ i18n('LABEL__SEND') }}
        </sdc-button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { i18n } from '_utils_/i18n'
import I18nConfig from '_utils_/I18nConfig'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import SdcButton from '_atoms_/SdcButton'
import ValidatedInput from '_atoms_/ValidatedInput'
import Location from '_utils_/Location'
import { snackbarDefaultSettings } from '@/utils/constants'

export default {
  name: 'ShippingLabelSendForm',
  components: {
    SdcButton,
    ValidatedInput,
    ValidationObserver,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      email: '',
      practice: {
        id: '',
        name: '',
      },
    }
  },
  computed: {
    ...mapGetters('Auth', ['currentPracticeId']),
    ...mapGetters('Practices', ['getById']),
    languageCode() {
      return Location.languageCode
    },
  },
  created() {
    this.requestPractices().then(() => {
      this.practice = { ...this.getById(this.currentPracticeId) }
    })
  },
  methods: {
    i18n,
    ...mapActions('Practices', ['requestPractices']),
    ...mapActions('Shipment', ['sendShipmentEmail']),
    ...mapMutations('HttpRequest', ['setLoadingStatus']),
    async onSubmit() {
      const form = this.$refs.sendShippingLabelForm

      const success = await form.validate()
      if (!success) return
      const { id, name } = this.practice
      const caseId = this.$route.params.id
      const payload = {
        practiceId: id,
        practiceName: name,
        caseId,
        email: this.email,
      }

      const response = await this.sendShipmentEmail(payload)

      this.toast({
        msg: this.getResultMessage(response),
        title: this.getResultTitle(response),
      })

      if (!response.error) {
        this.onCancel()
      }
    },
    getResultMessage(result) {
      if (result.error) {
        return this.getErrorMessage(result.error.type)
      }
      return i18n('SHIPPING_LABEL_TOAST__SUCCESS', [this.email])
    },
    getResultTitle(result) {
      if (result.error) {
        return i18n('ERROR__SHIPPING_LABEL__EMAIL__TITLE')
      }
      return i18n('SHIPPING_LABEL_TOAST__TITLE')
    },
    getErrorMessage() {
      return i18n('ERROR__SHIPPING_LABEL__EMAIL__DEFAULT', [
        I18nConfig.supportPhoneNumber,
      ])
    },
    onCancel() {
      this.closeModal()
    },
    toast({ msg, title }) {
      this.$sdcToast.open({title, content: msg})
    },
  },
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$mobile: map-get($sdc-breakpoints, 'mobile');

.send-shipping-label-form {
  &__email,
  &__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__email {
    justify-content: space-between;
    &__control {
      width: 100%;
      &--phone {
        display: block;
      }
      @media screen and (min-width: $mobile) {
        &--full {
          width: 100%;
        }
      }
    }
  }

  &__footer {
    // TODO: Refactor all modal buttons to use a shared style

    justify-content: flex-end;

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
    }

    &__button {
      font-weight: $font-weight-normal;
      font-family: 'Sofia Pro Bold';
      margin: 8px;
      padding: 15px 30px;
    }
  }
}
</style>
