<template>
  <section
    id="appointments-list"
    class="view-margin"
  >
    <appointments-list />
  </section>
</template>

<script>
import AppointmentsList from '_organisms_/AppointmentsList'

export default {
  name: 'Appointments',
  components: {
    AppointmentsList
  }
}
</script>
