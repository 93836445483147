<template>
  <validation-provider
    :id="inputId"
    v-slot="{ errors, valid }"
    :ref="name"
    :disabled="disableValidation"
    :name="name"
    :rules="rules"
    class="validated-input"
  >
    <b-field
      :label="label"
      :type="isValid(valid, errors)"
      :message="getErrorMessage(errors)"
      :label-position="labelPosition"
    >
      <b-input
        ref="b-input"
        :value="dataValue"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        v-bind="attrs"
        :autocomplete="autocomplete"
        :maxlength="maxLength"
        @blur="onBlur"
        @keyup.native="onKeyup"
        @input.native="formatInputValue"
      />

      {{ sendInputReference(errors) }}
    </b-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ValidatedInput',
  components: {
    ValidationProvider
  },
  props: {
    attrs: { type: Object, default: () => ({}) },
    autocomplete: { type: String, default: 'nope' },
    disabled: { type: Boolean, default: false },
    disableValidation: { type: Boolean, default: false },
    errorMsgValidator: { type: Function, default: () => true },
    formatter: { type: Function, default: null },
    label: { type: String, default: '' },
    labelPosition: { type: String, default: '' },
    maxLength: { type: Number, default: 250 },
    name: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    rules: { type: String, default: 'required' },
    type: { type: String, default: 'text' },
    value: { type: String, default: '' },
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  computed: {
    inputId() {
      return `validated-input-${this.name}`
    }
  },
  watch: {
    value(newVal) {
      this.dataValue = newVal
    },
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  },
  async mounted() {
    if (this.value !== '') {
      await this.$refs[this.name].validate()
    }
  },
  methods: {
    isValid(valid, errors) {
      if (!valid && this.shouldRenderErrorMessage(errors)) {
        return 'is-danger'
      }

      return ''
    },
    getErrorMessage(errors) {
      if (this.shouldRenderErrorMessage(errors)) {
        return errors[0]
      }

      return ''
    },
    shouldRenderErrorMessage(errors) {
      return errors[0] && this.errorMsgValidator(errors[0])
    },
    onBlur() {
      this.$refs['b-input'].computedValue = this.dataValue = this.dataValue.trim()
      this.$emit('blur', this.$refs[this.name])
    },
    onKeyup(event) {
      this.$emit('keyup', event)
    },
    sendInputReference(errors) {
      this.$emit('catch', {
        name: this.name,
        errors
      })
    },
    formatInputValue({ target }) {
      let formattedValue = target.value

      if (this.formatter !== null) {
        formattedValue = this.formatter(target.value)
      }

      this.dataValue = formattedValue
      this.$refs['b-input'].computedValue = formattedValue
    }
  }
}
</script>

