export default class dateFieldsProcessor {
  constructor(fieldGenericId, customRules) {
    this.inputId = fieldGenericId
    this.fieldRules = {
      day: '',
      month: '',
      year: ''
    }

    this.setCustomRules(customRules)
  }

  getFieldsNames() {
    return {
      day: `${this.inputId}-day`,
      month: `${this.inputId}-month`,
      year: `${this.inputId}-year`,
    }
  }

  getFieldsRules() {
    return {...this.fieldRules}
  }

  setCustomRules(customRules) {
    Object.keys(this.fieldRules).forEach(field => {
      this.fieldRules[field] = this.getCustomRules(customRules[field], field)
    })
  }

  getCustomRules(customRules, type) {
    const { day, month, year } = this.getFieldsNames()
    const isValidDateRule = `isValidDate:@${day},@${month},@${year}`
    const isOneHundredYearsOldOrLess = `isOneHundredYearsOldOrLess:@${day},@${month},@${year}`
    const BASE_RULES = {
      day: `required|integer|min:1|max:2|${isValidDateRule}|${isOneHundredYearsOldOrLess}`,
      month: `required|integer|min:1|max:2|${isValidDateRule}|${isOneHundredYearsOldOrLess}`,
      year: `required|integer|digits:4|${isValidDateRule}|${isOneHundredYearsOldOrLess}`
    }
    const customRule = (customRules && customRules.length !== 0) ?
      `|${this.processRules(customRules)}` : ''

    return `${BASE_RULES[type]}${customRule}`
  }

  processRules(rules) {
    const { day, month, year } = this.getFieldsNames()
    const processedRules = rules.split('|').map(rule => {
      let [ruleName, fields] = rule.split(':')
      let replacedFields = ''

      if (fields) {
        replacedFields = ':' + fields.replace('@day', `@${day}`)
          .replace('@month', `@${month}`)
          .replace('@year', `@${year}`)
      }

      return `${ruleName}${replacedFields}`
    })

    return processedRules.join('|')
  }
}
