<template>
  <email
    :label="i18n('INFORMATION_FORM__LABEL_EMAIL')"
    :title="i18n('CREATE_CASE__CUSTOMER_INFO_TITLE')"
    :check-email="checkExistingEmail"
    :email-field-rules="emailFieldRules"
    :model="model"
  />
</template>

<script>
import Email from '_organisms_/Email'
import { mapActions, mapState } from 'vuex'
import { i18n } from '_utils_/i18n'

export default {
  name: 'CreateCaseEmail',
  components: {
      Email
  },
  props: {
    model: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      customRules: {
        emailAddress: 'required|email'
      }
    }
  },
  computed: {
    ...mapState('InformationForm', ['isExistingEmail', 'isInvalidEmail']),
    emailFieldRules() {
        const isExistingEmailRule = this.isExistingEmail ? '|isExistingEmail' : ''
        const isInvalidEmailRule = this.isInvalidEmail ? '|isInvalidEmail' : ''
        return `${this.customRules.emailAddress}${isExistingEmailRule}${isInvalidEmailRule}`
    }
  },
  methods: {
    ...mapActions('InformationForm', ['checkExistingEmail']),
    i18n
  }
}
</script>
