export const MAX_SIZE = 200000000

export const ACCEPT_TYPES = ['stl', 'ply']

export const CATEGORIES = {
  upper: 'upper',
  lower: 'lower'
}

export const SCAN_INTAKE_ERRORS_CODE = {
  uploadFiles: 'UPLOAD_FILES'
}
export const AUTHORIZATION_SCOPES = {
  private: 'private',
  public: 'public'
}

export const ASSET_TYPES = {
  scan3d: '3DSCAN'
}

export const HISTORY_DATE_STRING_FORMAT = 'YYYY-MM-DD-HHmmSS'