<template>
  <validation-provider
    :id="name"
    v-slot="{ errors, valid }"
    ref="sdcTextarea"
    :name="name"
    :rules="rules"
  >
    <b-field
      :type="isValid(errors, valid)"
      :message="getErrorMessage(errors)"
    >
      <b-input
        v-model="dataValue"
        type="textarea"
        no-resize
        :name="name"
        :class="`sdc-textarea ${classes.join(' ')}`"
        :placeholder="placeholder"
        :maxlength="maxLength"
      />
    </b-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'SdcTextarea',
  components: {
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 250
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    classes: {
      type: Array,
      default: () => []
    },
    errorMsgValidator: {
      type: Function,
      default: () => true
    },
  },
  data() {
    return {
      dataValue: this.value
    }
  },
  watch: {
    dataValue(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    isValid(errors, valid) {
      if (!valid && !!errors.length) {
        return 'is-danger'
      }

      return ''
    },
    getErrorMessage(errors) {
      if (this.shouldRenderErrorMessage(errors)) {
        return errors[0]
      }

      return ''
    },
    shouldRenderErrorMessage(errors) {
      return errors[0] && this.errorMsgValidator(errors[0])
    }
  }
}
</script>
