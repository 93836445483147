<template>
  <div>
    <fast-track-care-experience
      v-if="showCareExperience"
      v-model="fastTrackInfo.careLevel"
    />
    <fast-track-aligner-preference v-model="fastTrackInfo.alignerPreference" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import FastTrackAlignerPreference from '_molecules_/FastTrackAlignerPreference'
import FastTrackCareExperience from '_molecules_/FastTrackCareExperience/index.vue'

export default {
  name: 'CreateCasePreference',
  components: {
    FastTrackCareExperience,
    FastTrackAlignerPreference
  },
  props: {
    model: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('Auth', ['isSuperuser']),
    ...mapGetters('Practices', ['getLocalPractices']),
    ...mapGetters('Payment', ['isPaymentFeatureEnabled']),
    ...mapState('CreateCase', ['fastTrackInfo']),
    isCarePlusEnabled() {
      return !!(
        !this.isSuperuser &&
        this.getLocalPractices.length &&
        this.getLocalPractices[0].carePlusEnabled
      )
    },
    hasPurchased() {
      return this.fastTrackInfo.hasPurchased
    },
    showCareExperience() {
      return !this.hasPurchased && this.isCarePlusEnabled && !this.isPaymentFeatureEnabled
    }
  },
  mounted() {
    if(this.hasPurchased) {
      this.setFastTrackInfo({ data: { careLevel: null } })
    }
  },
  beforeMount() {
    this.model.validate = next => next()
  },
  methods: {
    ...mapMutations('CreateCase', ['setFastTrackInfo']),
  }
}
</script>

<style></style>
