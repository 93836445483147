<template>
  <section
    :id="`${name}-dynamic-table`"
    :name="`${name}-dynamic-table`"
    class="dynamic-table"
  >
    <header class="dynamic-table__header">
      <div class="dynamic-table__header__title">
        <sdc-title level="2">
          {{ i18n(title) }}
        </sdc-title>
      </div>
      <div class="dynamic-table__header__actions">
        <!--search-->
        <div>
          <sdc-search-box
            v-if="!searchProps.visible"
            :disabled="isSearchDisabled"
            :name="`${name}-dynamic-table-search-box`"
            :placeholder="i18n(searchProps.placeholder)"
            :max-length-on-overlap="searchProps.maxLengthOnOverlap"
            :search-text="searchProps.searchText"
            @search="onSearch"
            @clearSearch="onClearSearch"
          />
        </div>
        <!--Filters-->
        <template v-for="(filter, index) in filters">
          <div
            v-if="filter.visible"
            :key="index"
          >
            <sdc-select
              v-model="filter.selectedValue"
              :options="filter.options"
              :name="`${name}-${index}-dynamic-table-filter-select`"
              :disabled="isFilterDisabled(filter)"
              @onSelect="filter.onSelect"
            />
          </div>
        </template>
      </div>
    </header>
    <div
      v-if="showClearFiltersButton"
      class="dynamic-table__middle"
    >
      <sdc-button
        type="link"
        class="dynamic-table__middle__button"
        @click="onClearFilters"
      >
        {{ i18n('CASES_LIST_CLEAR_FILTERS') }}
      </sdc-button>
    </div>
    <main :class="getMainClass()">
      <sdc-table
        :name="`${name}-dynamic-table-sdc-table`"
        :loading="loading"
        v-bind="tableProps"
        @onSort="onSort"
        @onRowSelected="onRowSelected"
      >
        <template
          v-for="slot in scopedSlots"
          #[slot]="propsToSlots"
        >
          <slot
            v-bind="propsToSlots"
            :name="slot"
          />
        </template>
      </sdc-table>
      <!-- paginator -->
      <paginator
        v-if="!paginatorProps.disabled"
        :name="`${name}-dynamic-table-paginator`"
        :total-items="paginatorProps.totalItems"
        :items-per-page="paginatorProps.itemsPerPage"
        :current-page="paginatorProps.currentPage"
        @pageChanged="onPageChanged"
      />
    </main>
  </section>
</template>

<script>
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import SdcSearchBox from '_atoms_/SdcSearchBox'
import SdcSelect from '_atoms_/SdcSelect'
import SdcButton from '_atoms_/SdcButton'
import SdcTable from '_molecules_/SdcTable'
import Paginator from '_molecules_/Paginator'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DynamicTable',
  components: {
    SdcSelect,
    SdcSearchBox,
    SdcButton,
    SdcTable,
    SdcTitle,
    Paginator
  },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showClearFilters:{
      type: Boolean,
      default: false
    },
    searchProps: {
      type: Object,
      required: true,
      validator(prop) {
        return typeof prop.placeholder !== 'undefined' ||
          typeof prop.maxLengthOnOverlap !== 'undefined' ||
          typeof prop.disabled !== 'undefined' ||
          typeof prop.visible !== 'undefined'
      }
    },
    filters: {
      type: Array,
      required: true
    },
    tableProps: {
      type: Object,
      required: true,
      validator(prop) {
        return (typeof prop.items !== 'undefined') ||
          typeof prop.fields !== 'undefined' ||
          typeof prop.sortBy !== 'undefined' ||
          typeof prop.sortDesc !== 'undefined' ||
          typeof prop.enableSelection !== 'undefined'
      }
    },
    paginatorProps: {
      type: Object,
      required: true,
      validator(prop) {
        return typeof prop.totalItems !== 'undefined' ||
          typeof prop.itemsPerPage !== 'undefined' ||
          typeof prop.currentPage !== 'undefined' ||
          typeof prop.disabled !== 'undefined'
      }
    }
  },
  computed: {
    ...mapState('AppProcess', ['loading']),
    scopedSlots() {
      return Object.keys(this.$scopedSlots)
    },
    isSearchDisabled() {
      return this.searchProps.disabled || this.loading
    },
    showClearFiltersButton(){
      return this.showClearFilters && this.filters.some(filter => filter.selectedValue) || this.searchProps.searchText
    },
  },
  destroyed() {
    this.setLoadingStatus(false)
  },
  methods: {
    ...mapActions('AppProcess', ['setLoadingStatus']),
    i18n,
    onSearch(searchQuery) {
      this.$emit('onSearch', searchQuery)
    },
    onClearSearch() {
      this.$emit('onClearSearch')
    },
    onClearFilters(){
      this.searchProps.searchText = ''
      this.$emit('onClearFilters')
    },
    onSort({ sortBy, sortDesc }) {
      this.$emit('onSort', { sortBy, sortDesc })
    },
    onRowSelected(selectedRow) {
      this.$emit('onRowSelected', selectedRow)
    },
    onPageChanged(page) {
      this.$emit('onPageChanged', page)
    },
    getMainClass() {
      return {
        'dynamic-table__main': true,
        'dynamic-table__main--loading': this.loading
      }
    },
    isFilterDisabled(filter) {
      return filter.disabled || this.loading
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$desktop: map-get($sdc-breakpoints, 'desktop');

.dynamic-table {
  padding: 0 20px 30px 20px;
  margin: auto;
  height: 100%;
  max-width: $desktop;

  &__header {
    display: flex;
    flex-direction: column;

    &__actions {
      & > div {
        width: 100%;
      }

      @media screen and (min-width: $tablet) {
        display: flex;
        justify-content: flex-end;
        width: 90%;

        & > div {
          margin-left: 10px;
          width: 240px;
        }
      }
    }

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__middle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  &__main {
    background-color: $white;
    border-radius: 8px;
    padding: 0 20px 20px;
    margin-top: 10px;
  }
}
</style>
