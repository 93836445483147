import Vue from 'vue'
import store from '_store_'
import VueRouter from 'vue-router'
import { authGuard, setMetaTitles } from '_utils_/routeGuards'
import Account from '_pages_/Account'
import AppointmentNote from '_pages_/AppointmentNote'
import Appointments from '_pages_/Appointments'
import CaseDetails from '_pages_/CaseDetails'
import ConsentTerms from '_pages_/ConsentTerms'
import CreateCase from '_pages_/CreateCase'
import CreateCorrection from '_pages_/CreateCorrection'
import CreateRefinement from '_pages_/CreateRefinement'
import ErrorAppointments from '_pages_/ErrorAppointments'
import ErrorForbidden from '_pages_/ErrorForbidden'
import ErrorMaintenance from '_pages_/ErrorMaintenance'
import ErrorNotFound from '_pages_/ErrorNotFound'
import Home from '_pages_/Home'
import Login from '_pages_/Login'
import ResetPassword from '_pages_/ResetPassword'
import SentEmail from '_pages_/SentEmail'
import StaffTerms from '_pages_/StaffTerms'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'login' },
    meta: {
      title: 'ROUTER__LOGIN_TITLE'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ROUTER__SIGNIN_TITLE'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter() {
      store.commit('Stepper/resetCurrentStep')
      store.dispatch('Auth/logOut')
      store.commit('Users/clearUserLogged')
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: 'ROUTER__RESET_PASSWORD_TITLE'
    }
  },
  {
    path: '/consent-terms',
    name: 'consent-terms',
    component: ConsentTerms,
    meta: {
      title: 'ROUTER__CONSENT_TERMS_TITLE'
    }
  },
  {
    path: '/staff-terms',
    name: 'staff-terms',
    component: StaffTerms,
    meta: {
      title: 'ROUTER__STAFF_TERMS_TITLE'
    }
  },
  {
    path: '/sent-email',
    name: 'sent-email',
    component: SentEmail,
    meta: {
      title: 'ROUTER__SENT_EMAIL_TITLE'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: 'ROUTER__CASES_TITLE'
    }
  },
  {
    path: '/case-details/:id',
    name: 'case-details',
    component: CaseDetails,
    meta: {
      title: 'ROUTER__CASES_DETAIL_TITLE'
    }
  },
  {
    path: '/create-case',
    name: 'create-case',
    component: CreateCase,
    meta: {
      title: 'ROUTER__CREATE_CASE_TITLE'
    }
  },
  {
    path: '/create-correction',
    name: 'create-correction',
    component: CreateCorrection,
    meta: {
      title: 'ROUTER__CORRECT_CASE_TITLE'
    }
  },
  {
    path: '/create-refinement',
    name: 'create-refinement',
    component: CreateRefinement,
    meta: {
      title: 'ROUTER__REFINEMENT_CASE_TITLE'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'ROUTER__ACCOUNT_TITLE'
    }
  },
  {
    path: '/case-details/:id/appointment',
    name: 'appointment-note',
    component: AppointmentNote,
    meta: {
      title: 'ROUTER__APPOINTMENT_TITLE'
    }
  },
  {
    path: '/appointments',
    name: 'appointments',
    component: Appointments,
    meta: {
      title: 'ROUTER__APPOINTMENTS_TITLE'
    }
  },
  {
    path: '/appointments',
    name: 'error-appointments',
    component: ErrorAppointments,
    meta: {
      title: 'ROUTER__APPOINTMENTS_TITLE'
    }
  },
  {
    path: '*',
    name: 'error-not-found',
    component: ErrorNotFound,
    meta: {
      title: 'ROUTER__ERROR_404_TITLE'
    }
  },
  {
    path: '/forbidden',
    name: 'error-forbidden',
    component: ErrorForbidden,
    meta: {
      title: 'ROUTER__ERROR_403_TITLE'
    }
  },
  {
    path: '/maintenance',
    name: 'error-maintenance',
    component: ErrorMaintenance,
    meta: {
      title: 'ROUTER__MAINTENANCE'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(authGuard)
router.beforeEach(setMetaTitles)

export default router
