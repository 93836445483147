<template>
  <div
    v-if="!isDdvcStaff"
    id="dental-service-organization"
    class="dental-service-organization"
  >
    <section class="section-panel-rounded">
      <div class="dental-service-organization__header">
        <sdc-title level="3">
          {{ i18n('DENTAL_SERVICE_ORGANIZATION__TITLE') }}
        </sdc-title>
        <sdc-button
          type="primary"
          outlined
          class="dental-service-organization__header__add-button"
          @click="showModal"
        >
          <smile-icon icon="plus" />
          <span>{{ i18n('DENTAL_SERVICE_ORGANIZATION__ADD_BUTTON') }}</span>
        </sdc-button>
      </div>
      <div class="dental-service-organization__search_section">
        <sdc-search-box
          :placeholder="i18n('DENTAL_SERVICE_ORGANIZATION__SEARCH_DSO')"
          :max-length-on-overlap="24"
          @search="onSearch"
          @clearSearch="onClearSearch"
        />
      </div>
      <business-organization-modal ref="businessOrganizationModal" />
      <div
        v-if="!totalDso"
        class="dental-service-organization__not-found"
      >
        {{ i18n('TEXT__NO_RESULTS_FOUND') }}
      </div>
      <div class="dental-service-organization__list">
        <div
          v-for="organization in dsoPaginated"
          :key="organization.id"
          class="dental-service-organization__list__item"
        >
          <dental-service-organization-item
            :id="organization.id"
            :dso-name="organization.name"
          />
        </div>
      </div>
      <div class="dental-service-organization__pagination-container">
        <paginator
          :total-items="totalDso"
          :items-per-page="pageSize"
          :current-page="currentPage"
          @pageChanged="onPageChanged"
        />
      </div>
    </section>
  </div>
</template>

<script>
//TODO: This component may be doing the same as Office Team component
// Check if needs refactor
import { i18n } from '_utils_/i18n'
import SdcTitle from '_atoms_/SdcTitle'
import SdcButton from '_atoms_/SdcButton'
import { mapActions, mapGetters } from 'vuex'
import DentalServiceOrganizationItem from '_molecules_/DentalServiceOrganizationItem'
import BusinessOrganizationModal from '_molecules_/BusinessOrganizationModal'
import Paginator from '_molecules_/Paginator'
import SdcSearchBox from '_atoms_/SdcSearchBox'
import Location from '_utils_/Location'

export default {
  name: 'DentalServiceOrganization',
  components: {
    SdcTitle,
    SdcButton,
    DentalServiceOrganizationItem,
    BusinessOrganizationModal,
    Paginator,
    SdcSearchBox
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      queryFilter: ''
    }
  },
  computed: {
    ...mapGetters('BusinessOrganizations', ['getDSOBusinessOrganizations']),
    ...mapGetters('Auth', ['isDdvcStaff']),
    dsoSorted() {
      return [...this.dsoFiltered].sort((dsoA, dsoB) =>
        dsoA.name.localeCompare(
          dsoB.name,
          ...Location.localeCompareConfiguration
        )
      )
    },
    dsoPaginated() {
      return this.dsoSorted
        .filter((_, dsoIndex) => dsoIndex >= this.pageSize * (this.currentPage - 1)) //Skip n
        .filter((_, dsoIndex) => dsoIndex < this.pageSize) //Take n
    },
    dsoFiltered() {
      return this.queryFilter
      ? this.getDSOBusinessOrganizations.filter((dso) => dso.name.toUpperCase().includes(this.queryFilter))
      : this.getDSOBusinessOrganizations
    },
    totalDso() {
      return this.dsoFiltered.length || 0
    }
  },
  mounted() {
    this.requestBusinessOrganizations()
  },
  methods: {
    i18n,
    ...mapActions('BusinessOrganizations', ['requestBusinessOrganizations']),
    showModal(){
      this.$refs.businessOrganizationModal.open()
    },
    onPageChanged(page) {
      this.currentPage = page
    },
    onSearch(queryFilter) {
      if (
        typeof queryFilter !== 'undefined' &&
        queryFilter !== this.queryFilter
      ) {
        this.queryFilter = queryFilter.toUpperCase()
        this.currentPage = 1
      }
    },
    onClearSearch() {
      this.queryFilter = ''
      this.currentPage = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_theme_/_variables';

$tablet: map-get($sdc-breakpoints, 'tablet');
$mobile: map-get($sdc-breakpoints, 'mobile');

.dental-service-organization {
  display: flex;
  justify-content: center;

  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &__add-button {
      height: 42px;
      margin-top: 8px;
      border-color: $gray-88;
      padding: 0px 20px 0px 20px;
      font-weight: $font-weight-bolder;

      @media screen and (max-width: $mobile) {
        margin-bottom: 12px;
      }
    }
  }

  &__search_section {
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 100%;
      @media screen and (min-width: $tablet) {
        width: 49%;
      }
    }
  }

  &__not-found {
    text-align: center;
    padding: 0rem 0.75rem 0.8rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 12px;
  }

  &__paginator-container {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
